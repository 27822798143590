import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import API from 'utils/axios';
import { ODModal, ODSelect } from 'shared';
import { LOAD_SOURCE } from 'constants/network';
import { updateNetworkView } from 'redux/actions';
import { RootState } from 'redux/reducers';
import { REPORT_STATUS } from 'constants/network';

const Download = () => {
    const [donwloadDataType, setDownloaDataType] = useState(LOAD_SOURCE.ALL);
    const { base_run }: any = useParams();
    const dispatch = useDispatch();

    const { allBidFiles } = useSelector((state: RootState) => state.BiddingReducer);
    const { baseReport, showDownloadModal } = useSelector(
        (state: RootState) => state.NetworkReducer
    );
    const { appliedBidFilesIds } = useSelector((state: RootState) => state.ConfigurationReducer);

    const [currAppliedBids, setCurrAppliedBids] = useState([]);

    const handleDownloadSlideout = (bool: boolean) => {
        dispatch(updateNetworkView({ showDownloadModal: bool }));
    };

    const handleDownload = async () => {
        dispatch(updateNetworkView({ isReportBeingDownloaded: true }));

        try {
            const data = await API({
                method: 'get',
                url: `reports/report-runs/${base_run}/download-raw-data/?data_type=${donwloadDataType}`,
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${baseReport?.name}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            if (data) dispatch(updateNetworkView({ isReportBeingDownloaded: false }));
            handleDownloadSlideout(false);
        } catch (e) {
            toast.error(`Unable to download the raw data file(s).`, {
                position: 'bottom-right'
            });
            dispatch(updateNetworkView({ isReportBeingDownloaded: false }));
            handleDownloadSlideout(false);
        }
    };

    useEffect(() => {
        let setArr = allBidFiles?.filter(
            (config): any =>
                config.status === REPORT_STATUS.COMPLETED && appliedBidFilesIds?.includes(config.id)
        );
        setCurrAppliedBids(setArr as any);
    }, [allBidFiles, appliedBidFilesIds]);

    const isBidApplied = currAppliedBids?.length > 0;

    return (
        <ODModal
            show={showDownloadModal}
            heading={'Download'}
            handleClose={() => handleDownloadSlideout(false)}
            actionButtonText={'Download'}
            closeButtonText={'Close'}
            handleAction={handleDownload}
            aria-describedby="dialog-for-download-report"
            children={
                <ODSelect
                    sx={{ marginTop: '16px' }}
                    dataTestId="download-data-type"
                    title="Select Data Type"
                    name="Select Download Data Type"
                    displayEmpty
                    value={donwloadDataType}
                    onChange={(e) => setDownloaDataType(e.target.value as any)}
                    itemList={[
                        { value: LOAD_SOURCE.ALL, name: 'All Data Type' },
                        { value: LOAD_SOURCE.NETWORK, name: 'Network Data' },
                        {
                            value: LOAD_SOURCE.BID,
                            name: 'Bid Data',
                            disabled: !isBidApplied
                        }
                    ]}
                />
            }
        />
    );
};

export default Download;
