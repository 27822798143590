import React from 'react';
import draftStore from 'utils/draftStore.util';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { CommonHeaderContainer } from 'shared';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from 'theme';
import { makeStyles } from '@mui/styles/';

const useStyles = makeStyles((theme) => ({
    backIcon: {
        backgroundColor: theme.palette.white,
        cursor: 'pointer'
    }
}));

export const ReportTitleHeader = () => {
    const classes = useStyles();
    const history = useHistory();

    const prevLocation = draftStore.get('prevLocation');
    const disableBack = !draftStore.get(prevLocation);

    return (
        <CommonHeaderContainer
            dark={false}
            isNetworkManager={true}
            justify={'space-between'}
            headerIcon={
                disableBack ? (
                    <FolderSharpIcon
                        sx={{
                            color: theme.palette.black
                        }}
                    />
                ) : (
                    <Avatar onClick={() => history.goBack()} className={classes.backIcon}>
                        <ArrowBackIcon
                            sx={{
                                color: theme.palette.black
                            }}
                        />
                    </Avatar>
                )
            }
            header={disableBack ? `Network Manager` : `Back`}
        />
    );
};
