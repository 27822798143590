import { Avatar } from '@mui/material';
import theme from 'theme';

interface ODAvatarProps {
    children: JSX.Element;
    props?: any;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}
const ODAvatar = ({ children, ...props }: ODAvatarProps) => {
    return (
        <Avatar
            sx={{
                width: '32px',
                height: '32px',
                margin: 'auto',
                color: theme.palette.neutral.black,
                backgroundColor: theme.palette.neutral.search,
                '&:hover': {
                    backgroundColor: theme.palette.neutral.neutral2
                },
                cursor: 'pointer'
            }}
            {...props}>
            {children}
        </Avatar>
    );
};

export default ODAvatar;
