import { Divider } from '@mui/material';
import theme from 'theme';

const NavbarDivider = ({ light }: { light: boolean }) => (
    <Divider
        orientation="vertical"
        sx={{
            borderColor: light
                ? `${theme.palette.neutral.neutral2}`
                : `${theme.palette.ODBlue.odBlue8}`,
            margin: '8px',
            height: '40px'
        }}
    />
);

export default NavbarDivider;
