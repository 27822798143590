import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useParamsProps } from 'types/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { HISTORY_CHANGES_TYPES } from 'constants/network';
import { DECISION } from 'constants/bidding';
import { DataRow, CellEdit } from 'shared/ODTable/ODTableTypes';
import {
    editBidAnalysis,
    updateBidAnalysis,
    getBidAnalysisLanes,
    getReportAppliedBids
} from 'redux/actions';
import { getModifiedPartialCopy } from 'shared/ODTable/ODTableUtils';
import { mapColumnIdActionType } from 'utils/common.util';
import { getLaneOriginDestinationString } from 'utils/common.util';
import {
    findLane,
    getOriginalLaneDecision,
    restoreBidAnalysisAction
} from 'utils/bidAnalysis.util';
import { BidAnalysisAction } from 'types/bidAnalysis';
import { useAuth0 } from '@auth0/auth0-react';
import { REPORT_SUB_TYPE } from 'constants/reports';

const useBidAnalysisHook = () => {
    const { configuration, error } = useSelector((state: RootState) => state.ConfigurationReducer);
    const {
        actionsAwaitingConfigurationCreation,
        bidGoals,
        currBidAnalysisData,
        currBidAnalysisLanes
    } = useSelector((state: RootState) => state.BidAnalysisReducer);
    const { bidConfigLocations } = useSelector((state: RootState) => state.BiddingReducer);
    const { reportLocations } = useSelector((state: RootState) => state.ReportKPIReducer);
    const dispatch = useDispatch();
    const { user } = useAuth0();
    const { analysis_id, config_id }: useParamsProps = useParams();
    const history = useHistory();

    const isFinalizedBid = Boolean(
        currBidAnalysisData?.file_sub_type === REPORT_SUB_TYPE.FINALIZED
    );

    const handleBidAnalysisActionAndTableRestore = async (
        e: any,
        restoreData: any,
        restoreAll: boolean = false
    ) => {
        const simulationId = config_id;
        const tableRestoreEditData: any[] = [];
        const actionData: any[] = [];
        if (simulationId) {
            if (restoreAll && restoreData.length) {
                restoreData.forEach((action: any) => {
                    actionData.push({
                        bid_lane_id: action.bid_lane_id,
                        type: 'restore',
                        item_type: action.item_type,
                        changed_by: user?.name
                    });
                });
                dispatch(getBidAnalysisLanes({ analysis_id, bidConfigLocations, reportLocations }));
            } else if (Object.keys(restoreData).length) {
                restoreBidAnalysisAction(
                    actionData,
                    restoreData,
                    restoreData.item_type,
                    currBidAnalysisLanes,
                    tableRestoreEditData
                );
            }
            dispatch(
                editBidAnalysis({
                    config_id,
                    cellEdits: tableRestoreEditData,
                    actions: actionData,
                    navigateToUrl: (url: string) => history.push(url),
                    changedBy: user?.name
                })
            );
        }
        await dispatch(getReportAppliedBids(simulationId));
    };

    const applyTableEditsAndActions = async (cellEdits: CellEdit[]) => {
        dispatch(
            editBidAnalysis({
                config_id,
                cellEdits,
                navigateToUrl: (url: string) => history.push(url),
                changedBy: user?.name
            })
        );
    };

    // TODO Roll back edits and clear actions array if the configuration creation fails. Think about best way to detect this. Could be checking that creatingConfiguration = false and no configuration.id exists. Make sure this does not introduce bugs when loading an existing base or draft config.

    const setLaneDecision = async ({ lane, decision }: { lane: DataRow; decision: DECISION }) => {
        const cellEdit: CellEdit = {
            rowId: lane.id as number,
            columnId: 'recommendation',
            newValue: decision
        };
        dispatch(
            editBidAnalysis({
                config_id,
                cellEdits: [cellEdit],
                navigateToUrl: (url: string) => history.push(url),
                changedBy: user?.name
            })
        );
    };

    const setMultipleLanesDecisions = (lanesToModify: DataRow[], decision: DECISION) => {
        const cellEdits: CellEdit[] = [];
        lanesToModify.forEach((lane: DataRow) => {
            const cellEdit = {
                rowId: Number(lane.id),
                columnId: 'recommendation',
                newValue: decision
            };
            cellEdits.push(cellEdit);
        });
        applyTableEditsAndActions(cellEdits);
    };

    const applyCellEditsToTable = (cellEdits: CellEdit[]) => {
        const modifiedDataRows = getModifiedPartialCopy(cellEdits, currBidAnalysisLanes);
        dispatch(updateBidAnalysis({ currBidAnalysisLanes: modifiedDataRows }));
    };

    const isLatest = currBidAnalysisData?.latest_base?.id === currBidAnalysisData.parent_id;

    const triggerNoMappingInfoAlert = () =>
        dispatch(updateBidAnalysis({ showNomappingAlert: true }));

    const getOriginalValue = (row: DataRow, columnId: string) => {
        const actionType = mapColumnIdActionType(columnId);
        switch (actionType) {
            case HISTORY_CHANGES_TYPES.VOLUME:
                return row?.original_volume_to_accept;
            case HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE:
                return row?.original_rate_per_mile;
            case HISTORY_CHANGES_TYPES.CHANGE_BID_FLAT_RATE:
                return row?.original_flat_rate;
        }
    };
    const handleUndoCell = (row: DataRow, columnId: string, cellValue: any) => {
        const originalValue = getOriginalValue(row, columnId);
        const actionData = {
            bid_lane_id: row?.bid_id,
            item_type: mapColumnIdActionType(columnId),
            lane: getLaneOriginDestinationString(row),
            original: originalValue,
            change: Number(cellValue),
            changed_by: user?.name
        };
        if (actionData?.original !== actionData?.change) {
            handleBidAnalysisActionAndTableRestore(undefined, actionData, false);
        }
        return originalValue;
    };

    useEffect(() => {
        const undoInitialEdits = () => {
            const editsToRestoreToInitialState: CellEdit[] = [];
            if (actionsAwaitingConfigurationCreation.length > 0) {
                actionsAwaitingConfigurationCreation.forEach((action: BidAnalysisAction) => {
                    const rowId = action.value.bid_lane_id;
                    const originalValue = action.value.original;
                    const columnId = mapColumnIdActionType(action.item_type, true);
                    if (columnId)
                        editsToRestoreToInitialState.push({
                            rowId: rowId,
                            columnId: columnId,
                            newValue: originalValue
                        });
                });
                applyCellEditsToTable(editsToRestoreToInitialState);
                dispatch(updateBidAnalysis({ actionsAwaitingConfigurationCreation: [] }));
            }
        };
        if (error) {
            undoInitialEdits();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionsAwaitingConfigurationCreation, error]);

    const navigateToBidAnalysisPage = () => {
        const path = `/bid-analysis/${currBidAnalysisData.id}${
            configuration?.id ? '/config/' + configuration.id : ''
        }`;
        history.push(path);
    };

    const handleViewBaseReport = () => {
        const baseReportId = currBidAnalysisData.latest_base.id;
        const bucketId = currBidAnalysisData.bucket_id;
        history.push({
            pathname: `/reports/bucket/${bucketId}/${baseReportId}/network/shipper/ `,
            state: { networkImpactView: true }
        });
    };

    const bidGoalApplied = Object?.values(bidGoals)?.some((value) => value !== null);

    return {
        isFinalizedBid,
        applyCellEditsToTable,
        applyTableEditsAndActions,
        bidGoalApplied,
        findLane,
        getOriginalLaneDecision,
        handleBidAnalysisActionAndTableRestore,
        handleUndoCell,
        handleViewBaseReport,
        navigateToBidAnalysisPage,
        restoreBidAnalysisAction,
        setLaneDecision,
        setMultipleLanesDecisions,
        triggerNoMappingInfoAlert,
        isLatest
    };
};

export default useBidAnalysisHook;
