export const buildQueryString = (keyValuePairs: [string, string | object][]) => {
    let queryString = '';
    const nonEmptyPairs = Object.entries(keyValuePairs).filter(
        ([key, value]) => Object.keys(value).length > 0
    );
    const stringify = (value: string | object) =>
        typeof value === 'string' ? value : JSON.stringify(value);

    if (nonEmptyPairs.length > 0) {
        const [firstKey, firstValue] = nonEmptyPairs[0];
        queryString += `?${firstKey}=${stringify(firstValue)}`;
        nonEmptyPairs.slice(1).forEach(([key, value]) => {
            const queryParam = `&${key}=${stringify(value)}`;
            queryString += queryParam;
        });
    }
    return queryString;
};
