import {
    Accordion,
    Box,
    ToggleButton,
    Typography,
    AccordionSummary,
    RadioGroup
} from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'theme';

export const CustomizedToggleButton = styled(ToggleButton)(() => ({
    width: '196px',
    color: theme.palette.neutral.black,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.neutral.neutral1,
    border: 'none',
    height: '40px',
    borderRadius: '4px !important',
    '&.Mui-selected': {
        color: 'white',
        borderRadius: '4px',
        backgroundColor: theme.palette.semantic.focusedBlue
    },
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: theme.palette.semantic.focusedBlue
    }
}));

export const SettingsAccordion = styled(Accordion)(() => ({
    '&:before': {
        backgroundColor: 'transparent'
    }
}));

export const CustomizedSubsectionTitle = styled(Box)(() => ({
    fontSize: '12px',
    color: theme.palette.neutral.neutral6,
    marginTop: '16px'
}));

export const AccordionHeaderText = styled(Typography)(() => ({
    color: 'black',
    fontSize: '14px',
    fontWeight: 600
}));

export const CustomAccordionSummary = styled(AccordionSummary)(() => ({
    backgroundColor: theme.palette.neutral.neutral1,
    borderRadius: '4px',
    marginTop: '2px'
}));

export const CustomRadioGroup = styled(RadioGroup)(() => ({
    marginBottom: '16px',
    marginTop: '4px',
    '& .MuiFormControlLabel-root': {
        height: '32px',
        margin: 0,
        '&:hover': {
            borderRadius: '4px',
            backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue2
        },
        '.MuiFormControlLabel-label.Mui-disabled': {
            color: theme.palette.neutral.neutral6
        }
    },
    '& .MuiRadio-root': {
        height: '40px',
        width: '40px',
        padding: '0px',
        color: theme.palette.neutral.neutral6,
        '&.Mui-checked': {
            color: theme.palette.semantic.focusedBlue
        },
        '&.Mui-disabled': {
            color: theme.palette.neutral.neutral6
        },
        '& .MuiSvgIcon-root': {
            fontSize: '18px'
        },
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    '& .MuiTypography-body1': {
        fontSize: '14px'
    }
}));
