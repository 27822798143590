export const DRIVER_TITLES = {
    DOMICILE: 'domicileName',
    TOTAL_DRIVER: 'total_drivers',
    TRUE_PROFIT: 'true_profit',
    EMPTY_MILES: 'empty_miles_percentage',
    HOURS_AT_HOME_WK: 'time_at_home_events',
    DRIVER_TYPE: 'id',
    AVG_LOH: 'average_loh',
    LOAD_COUNT: 'load_count',
    AVG_MILES_WK: 'miles_per_week',
    LOAD_ID: 'move_id',
    START_MOVE_TYPE: 'load_type',
    END_MOVE_TYPE: 'move_type',
    START_MOVE_DATE_N_TIME: 'pickup_date_time',
    END_MOVE_DATE_N_TIME: 'delivery_date_time',
    NAME: 'name',
    CHECKBOX: 'checkbox'
};

export enum DRIVER_MODE {
    DEFAULT = 'default',
    DOMICILE = 'domicile',
    DRIVER_TYPES = 'driverTypes',
    DRIVER_MOMENTS = 'driverMoments',
    DRIVER_ID = 'driverId'
}

export enum DRIVER_TABLE_TYPE {
    DOMICILE = 'domicile',
    DRIVER_TYPES = 'driverTypes',
    DRIVER_ID = 'driverId',
    DRIVER_MOMENTS = 'driverMoments'
}

export const EXPANDED_DRIVER_COLS = [
    'true_profit',
    'compare_true_profit',
    'compare_true_profit_amount',
    'compare_miles_per_week',
    'compare_empty_miles_percentage',
    'compare_time_at_home_events',
    'compare_home_on_time',
    'true_profit_amount',
    'max_miles_per_week',
    'pickup_date_time',
    'delivery_date_time',
    'load_count',
    'average_loh',
    'min_miles_per_week',
    'empty_miles_percentage',
    'time_at_home_events',
    'total_time_at_home',
    'home_on_time',
    'load_type',
    'move_type',
    'move_id'
];
