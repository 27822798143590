import { CellContext, ColumnDef, HeaderContext, Row, Table } from '@tanstack/react-table';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { Box, Tooltip, Typography } from '@mui/material';
import theme from 'theme';
import { VALIDATION_RULE } from 'utils/common.util';
import { minMaxFilterV8, textFilterV8 } from 'shared/Table/tableFilters';
import { FILTER_TYPE } from 'shared/Table/tableFilters/FilterConstants';
import { BIDDING_NAMES, DECISION } from 'constants/bidding';
import { renderRecommendationDropdown } from '../RecommendationDropdown';
import { dollarStyle, UnspecifiedDataPlaceholder } from 'shared/ODTable/ODTableStyledComponents';
import { getBalanceColor } from '../../../../utils/bidAnalysis.util';
import { FLOW_DIRECTION } from 'types/bidAnalysis';
import { BID_ANALYSIS_TOOLTIPS } from 'constants/bidding';
import { PREFERRED_RATE_TYPE, TIME_FRAME } from 'constants/settings';
import { getTimeUnits } from 'utils/time.util';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';

import palette from 'theme/palette';
import { ColumnFormat, DonutData } from './types';
import { StyledSpan, CityEllipsis } from './BidAnalysisTableColumns.styles';
import {
    decisionValuesToLabelsMap,
    columnHelper,
    cellFormat,
    getFilterType
} from './BidAnalysisTableColumns.utils';

const bidAnalysisColumnsInitiate = (
    openBidAnalysisLaneScoreSlideout: (id: any) => void,
    textFieldMatchingStrategy: string,
    setLaneDecision: ({ lane, decision }: { lane: DataRow; decision: DECISION }) => void,
    handleLocationClick: (laneId: number, direction: FLOW_DIRECTION, table: Table<DataRow>) => void,
    handleMKTBalanceClick: (lane: DataRow, market: 'origin' | 'destination') => void,
    drilledIn: boolean,
    hideQuickCopyActionsOnBidAnalysisTable: boolean,
    locationVisualization: 'city-state' | 'zip-code',
    bidAnalysisAndFlowsTableColumns: boolean,
    salesRanking: boolean,
    column_specific_time_aggregation: { [key: string]: TIME_FRAME },
    time_aggregation: TIME_FRAME | null,
    triggerNoMappingInfoAlert: () => any,
    handleSwapRateColumn: (option: string, table: Table<DataRow>) => void,
    rateType: PREFERRED_RATE_TYPE,
    flatRatesFlag: boolean,
    odpt3965MarketBalanceColumns: boolean,
    odpt3830NameConsistency: boolean,
    deltaValue: any,
    hasAggregated?: boolean,
    showProfitColumns?: boolean
) => {
    const globalTimeUnits = time_aggregation ? getTimeUnits(time_aggregation) : '';

    const generateColDef = ({
        accessor,
        label,
        format,
        showRating = false,
        donut = null,
        size = 148,
        description,
        validationRule,
        required = false,
        timeAggDependent = false
    }: {
        accessor: string;
        label: string;
        format: ColumnFormat;
        showRating?: boolean;
        donut?: DonutData;
        size?: number;
        description?: string;
        validationRule?: VALIDATION_RULE;
        required?: boolean;
        timeAggDependent?: boolean;
    }) => {
        const colDef = columnHelper.accessor(accessor, {
            id: accessor,
            header: label,
            cell: cellFormat(
                format,
                showRating,
                donut,
                size,
                timeAggDependent,
                deltaValue,
                globalTimeUnits,
                triggerNoMappingInfoAlert,
                openBidAnalysisLaneScoreSlideout
            ),
            footer: (info: HeaderContext<DataRow, string | number | object | null>) =>
                info.column.id, // TODO what is this field for?
            size: size ? size : 148,
            filterFn: getFilterFn(format),
            meta: {
                filterType: getFilterType(format, showRating),
                name: label,
                visibleByDefault: true,
                infoMessage: description,
                validationRule,
                required: required
            }
            // abbreviated: ['number', 'dollar'].includes(colMeta.format),
            // sticky: stickyColumns.includes(colMeta.accessor) ? 'left' : null
        });
        return colDef;
    };

    const textMatchingFilterFnWrapper = (row: Row<DataRow>, columnId: string, value: any) => {
        return textFilterV8(row, columnId, value, textFieldMatchingStrategy);
    };

    const getFilterFn = (format: ColumnFormat) => {
        switch (format) {
            case 'percentage':
            case 'dollar':
            case 'count':
            case 'float':
            case 'interactive_count':
                return minMaxFilterV8;
            case 'text':
                return textMatchingFilterFnWrapper;
            default:
                break;
        }
    };

    //TODO to be changed after odpt3830NameConsistency flag gets removed.
    const cityStateCol = (locationType: 'origin' | 'destination') => {
        const headerText = locationType === 'origin' ? 'Origin' : 'Destination';
        const id = locationType === 'origin' ? 'originCityState' : 'destCityState';
        const tooltipColumnId =
            locationType === 'origin' ? 'originCityStateZip' : 'destCityStateZip';
        return columnHelper.accessor(id, {
            id: id,
            header: () => headerText,
            cell: (info) => {
                let displayedVal = info.getValue() as string | null;
                if (displayedVal) {
                    const city = displayedVal.split(',')[0];
                    const state = displayedVal.split(',')[1];
                    return (
                        <Tooltip title={(info.row.original[tooltipColumnId] ?? '') as any}>
                            <StyledSpan
                                sx={{ display: 'flex' }}
                                selectable={!drilledIn}
                                onClick={() =>
                                    !drilledIn &&
                                    handleLocationClick(
                                        Number(info.row.original.id),
                                        locationType === 'origin'
                                            ? FLOW_DIRECTION.IN
                                            : FLOW_DIRECTION.OUT,
                                        info.table
                                    )
                                }>
                                <CityEllipsis>{city}</CityEllipsis>
                                {state && ','}
                                {state}
                            </StyledSpan>
                        </Tooltip>
                    );
                } else return <UnspecifiedDataPlaceholder />;
            },
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: headerText as any,
                sticky: 'left',
                //infoMessage: PLACEHOLDER_INFO_DESCRIPTION,
                required: true
            }
        });
    };

    const zipCol = (locationType: 'origin' | 'destination') => {
        const headerText = locationType === 'origin' ? 'Origin' : 'Destination';
        const id = locationType === 'origin' ? 'origin' : 'destination';
        const tooltipColumnId =
            locationType === 'origin' ? 'originCityStateZip' : 'destCityStateZip';
        return columnHelper.accessor(id, {
            id: id,
            header: () => headerText,
            cell: (info) => {
                let displayedVal = info.getValue() as string | null;
                if (displayedVal) {
                    return (
                        <Tooltip title={(info.row.original[tooltipColumnId] ?? '') as any}>
                            <StyledSpan
                                sx={{ display: 'flex' }}
                                selectable={!drilledIn}
                                onClick={() =>
                                    !drilledIn &&
                                    handleLocationClick(
                                        Number(info.row.original.id),
                                        locationType === 'origin'
                                            ? FLOW_DIRECTION.IN
                                            : FLOW_DIRECTION.OUT,
                                        info.table
                                    )
                                }>
                                {info.getValue() as any}
                            </StyledSpan>
                        </Tooltip>
                    );
                } else return <UnspecifiedDataPlaceholder />;
            },
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: headerText,
                sticky: 'left',
                //infoMessage: PLACEHOLDER_INFO_DESCRIPTION,
                required: true
            }
        });
    };

    const marketColumnDef = (market: 'origin' | 'destination') => {
        const marketAcronym = market === 'origin' ? 'OMKT' : 'DMKT';
        let units = '';
        units = getTimeUnits(column_specific_time_aggregation?.[`${marketAcronym}_balance`]);

        return columnHelper.accessor(
            odpt3965MarketBalanceColumns
                ? `new_${marketAcronym}_balance_change`
                : `${marketAcronym}_balance_after_change`,
            {
                id: odpt3965MarketBalanceColumns
                    ? `new_${marketAcronym}_balance_change`
                    : `${marketAcronym}_balance_after_change`,
                header: () => `${marketAcronym} Balance${units}`,
                cell: (info: CellContext<DataRow, any>) => {
                    const resultingBalance = Math.ceil(
                        Number(
                            odpt3965MarketBalanceColumns
                                ? Number(info.row.original[`${marketAcronym}_balance_change`]) +
                                      Number(
                                          info.row.original[`new_${marketAcronym}_balance_change`]
                                      )
                                : info.row.original[`${marketAcronym}_balance_change`]
                        )
                    );

                    const rowId = info?.row?.original?.id?.toString() as any;
                    const hasDelta = Boolean(deltaValue);
                    const column = info?.cell?.column?.columnDef?.id as any;
                    let delta = null;
                    let hasDeltaNumber = false;

                    let prefix = <></>;

                    if (hasDelta) {
                        delta = deltaValue[rowId]?.[column] as any;
                        hasDeltaNumber = typeof deltaValue[rowId]?.[column] === 'number';

                        if (delta === 0) {
                            prefix = <></>;
                        } else {
                            prefix = delta < 0 ? <></> : <>+</>;
                        }
                    }

                    const valueBeforeChange = Math.ceil(Number(info.getValue()));
                    if (
                        resultingBalance !== null &&
                        valueBeforeChange !== null &&
                        !isNaN(resultingBalance) &&
                        !isNaN(valueBeforeChange)
                    ) {
                        return (
                            <>
                                {odpt3965MarketBalanceColumns ? (
                                    <Box
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        onClick={() =>
                                            handleMKTBalanceClick(info.row.original, market)
                                        }>
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                color: theme.palette.black
                                            }}>
                                            {`${
                                                valueBeforeChange > 0 ? '+' : ''
                                            }${valueBeforeChange}${units}`}
                                        </span>
                                        <ArrowForwardSharpIcon
                                            sx={{
                                                marginLeft: '2px',
                                                marginRight: '2px',
                                                color: getBalanceColor(
                                                    resultingBalance,
                                                    valueBeforeChange,
                                                    odpt3965MarketBalanceColumns
                                                )
                                            }}
                                        />
                                        <span
                                            style={{
                                                color: getBalanceColor(
                                                    resultingBalance,
                                                    valueBeforeChange,
                                                    odpt3965MarketBalanceColumns
                                                ),
                                                textDecoration: odpt3965MarketBalanceColumns
                                                    ? 'underline'
                                                    : 'none',
                                                cursor: odpt3965MarketBalanceColumns
                                                    ? 'pointer'
                                                    : 'default'
                                            }}>
                                            {`${
                                                resultingBalance > 0 ? '+' : ''
                                            }${resultingBalance}${units}`}
                                        </span>

                                        {hasDeltaNumber && (
                                            <>
                                                <Box color={palette.neutral.neutral6} marginX="2px">
                                                    /
                                                </Box>
                                                <Typography
                                                    display="flex"
                                                    alignItems="center"
                                                    color={palette.neutral.neutral6}>
                                                    {prefix}
                                                    {`${Math.ceil(Number(delta))}${units}`}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <>
                                        {`${info.getValue() >= 0 ? '+' : ''}${Math.ceil(
                                            Number(info.getValue())
                                        )}${units}`}
                                        <span
                                            style={{
                                                color: getBalanceColor(
                                                    resultingBalance,
                                                    valueBeforeChange,
                                                    false
                                                )
                                            }}>
                                            {`\u00A0(${
                                                resultingBalance >= 0 ? '+' : ''
                                            }${resultingBalance})`}
                                        </span>

                                        {hasDeltaNumber && (
                                            <>
                                                <Box color={palette.neutral.neutral6} marginX="2px">
                                                    /
                                                </Box>
                                                <Typography
                                                    display="flex"
                                                    alignItems="center"
                                                    color={palette.neutral.neutral6}>
                                                    {prefix}
                                                    {`${Math.ceil(Number(delta))}${units}`}
                                                </Typography>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        );
                    } else return <UnspecifiedDataPlaceholder />;
                },
                footer: (info) => info.column.id,
                size: 228,
                filterFn: minMaxFilterV8,
                meta: {
                    filterType: FILTER_TYPE.NUMBER_RANGE,
                    visibleByDefault: true,
                    name: `${marketAcronym} Balance`,
                    infoMessage:
                        market === 'origin'
                            ? BID_ANALYSIS_TOOLTIPS.OMKT_BALANCE
                            : BID_ANALYSIS_TOOLTIPS.DMKT_BALANCE,
                    timeAggregationDropdown: bidAnalysisAndFlowsTableColumns,
                    timeAggGroupKey: `${marketAcronym}_balance`
                }
            }
        );
    };

    const columns = [
        columnHelper.accessor('id', {
            id: 'id',
            header: () => 'id',
            cell: (info) => info.getValue(),
            meta: { notDisplayed: true }
        }),
        columnHelper.group({
            id: 'review-results',
            header: () => <Box>Review Bid Analysis Results</Box>,
            meta: { sticky: 'left' },
            enableSorting: false,
            columns: [
                ...(locationVisualization === 'city-state'
                    ? [cityStateCol('origin')]
                    : [zipCol('origin')]),
                ...(locationVisualization === 'city-state'
                    ? [cityStateCol('destination')]
                    : [zipCol('destination')])
            ]
        }),
        columnHelper.group({
            id: 'placeholder',
            header: () => '',
            columns: [
                generateColDef({
                    accessor: 'lane_score',
                    label: 'Lane Score',
                    format: 'interactive_count',
                    showRating: true,
                    donut: null,
                    size: 200,
                    required: true,
                    description: BID_ANALYSIS_TOOLTIPS.LANE_SCORE
                }),
                ...(salesRanking
                    ? [
                          generateColDef({
                              accessor: 'sales_ranking',
                              label: 'Sales Ranking',
                              format: 'interactive_count',
                              showRating: true,
                              donut: null,
                              size: 200
                          })
                      ]
                    : []),
                generateColDef({
                    accessor: 'covered',
                    label: 'Covered',
                    format: 'count',
                    timeAggDependent: true,
                    donut: {
                        displayedNumberKey: 'covered',
                        remainderNumberKey: 'uncovered',
                        color: theme.palette.success.main
                    },
                    description: BID_ANALYSIS_TOOLTIPS.COVERED
                }),
                generateColDef({
                    accessor: 'uncovered',
                    label: 'Uncovered',
                    format: 'count',
                    timeAggDependent: true,
                    donut: {
                        displayedNumberKey: 'uncovered',
                        remainderNumberKey: 'covered',
                        color: theme.palette.semantic.semanticRed
                    },
                    description: BID_ANALYSIS_TOOLTIPS.UNCOVERED
                }),
                generateColDef({
                    accessor: 'total_volume_available',
                    label: odpt3830NameConsistency ? BIDDING_NAMES.LOAD_VOLUME : 'Total Loads',
                    format: 'count',
                    timeAggDependent: true,
                    description: BID_ANALYSIS_TOOLTIPS.LOAD_VOLUME,
                    size: 178
                }),
                generateColDef({
                    accessor: 'partial_loads',
                    label: 'Partial Loads',
                    format: 'count',
                    timeAggDependent: true,
                    size: 188
                }),
                ...(odpt3830NameConsistency
                    ? [
                          generateColDef({
                              accessor: 'customer_bid_id',
                              label: BIDDING_NAMES.CUSTOMER_LANE_ID,
                              format: 'text',
                              size: 188
                          })
                      ]
                    : []),
                generateColDef({
                    accessor: 'equipment_type',
                    label: BIDDING_NAMES.EQUIPMENT_TYPE,
                    format: 'text',
                    size: 220
                }),
                generateColDef({
                    accessor: 'origin_load_method',
                    label: BIDDING_NAMES.ORIGIN_LOAD_METHOD,
                    format: 'text',
                    size: 220
                }),
                generateColDef({
                    accessor: 'dest_unload_method',
                    label: BIDDING_NAMES.DESTINATION_UNLOAD_METHOD,
                    format: 'text',
                    size: 220
                }),
                generateColDef({
                    accessor: 'top_shipper_id',
                    label: 'Shipper',
                    format: 'text',
                    size: 220
                }),
                generateColDef({
                    accessor: 'service_coverage',
                    label: 'Service Coverage',
                    format: 'percentage',
                    size: 178
                }),
                generateColDef({
                    accessor: 'revenue',
                    label: 'Total Revenue',
                    format: 'dollar'
                }),
                generateColDef({
                    accessor: 'revenue_per_mile',
                    label: 'Revenue Per Mile',
                    format: 'dollar',
                    size: 180
                }),
                ...(showProfitColumns
                    ? [
                          generateColDef({
                              accessor: 'profit',
                              label: 'Total Profit',
                              format: 'dollar',
                              timeAggDependent: true,
                              size: hasAggregated ? 200 : 148
                          }),

                          generateColDef({
                              accessor: 'profit_per_mile',
                              label: 'Profit Per Mile',
                              format: 'dollar',
                              size: 160
                          })
                      ]
                    : []),
                generateColDef({
                    accessor: 'cost_per_mile',
                    label: 'Cost Per Mile',
                    format: 'dollar',
                    size: 158
                }),
                generateColDef({
                    accessor: 'loaded_miles',
                    label: 'Avg. Loaded Miles',
                    format: 'float',
                    size: 178
                }),
                generateColDef({
                    accessor: 'origin_market',
                    label: 'Origin Market',
                    format: 'text',
                    size: 178
                }),
                generateColDef({
                    accessor: 'destination_market',
                    label: 'Destination Market',
                    format: 'text',
                    size: 195
                }),
                generateColDef({
                    accessor: 'empty_mileage_percentage',
                    label: 'Empty Mileage %',
                    format: 'percentage',
                    size: 178
                }),
                ...(showProfitColumns
                    ? [
                          generateColDef({
                              accessor: 'profit_per_load',
                              label: 'Avg. Profit Per Load',
                              format: 'dollar',
                              size: 178
                          })
                      ]
                    : []),
                ...(odpt3830NameConsistency
                    ? [
                          generateColDef({
                              accessor: 'fuel_surcharge',
                              label: BIDDING_NAMES.FUEL_SURCHARGE,
                              format: 'dollar',
                              size: 178,
                              validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT
                          }),
                          generateColDef({
                              accessor: 'load_volume_type',
                              label: BIDDING_NAMES.LOAD_FREQUENCY,
                              format: 'text',
                              size: 178
                          })
                      ]
                    : []),

                // If we add more columns with a resultingBalance value and change value, use generateColDef here instead of this boilerplate and make generateColDef more flexible to accommodate such 'difference' columns.
                marketColumnDef('origin'),
                marketColumnDef('destination'),
                generateColDef({
                    accessor: 'hist_vol_inflow_perc',
                    label: 'Hist. Vol Inflow %',
                    format: 'percentage',
                    description: BID_ANALYSIS_TOOLTIPS.HIST_VOL_INFLOW_PERC,
                    size: 198
                }),
                generateColDef({
                    accessor: 'hist_vol_outflow_perc',
                    label: 'Hist. Vol Outflow %',
                    format: 'percentage',
                    description: BID_ANALYSIS_TOOLTIPS.HIST_VOL_OUTFLOW_PERC,
                    size: 208
                }),
                ...(bidAnalysisAndFlowsTableColumns
                    ? [
                          generateColDef({
                              accessor: 'avg_inbound_cost',
                              label: 'Avg. Inbound Cost',
                              format: 'dollar',
                              size: 193,
                              description: BID_ANALYSIS_TOOLTIPS.AVG_INBOUND_COST
                          }),
                          generateColDef({
                              accessor: 'avg_outbound_cost',
                              label: 'Avg. Outbound Cost',
                              format: 'dollar',
                              size: 198,
                              description: BID_ANALYSIS_TOOLTIPS.AVG_OUTBOUND_COST
                          }),
                          generateColDef({
                              accessor: 'avg_empty_miles_inbound',
                              label: 'Inbound Avg. Empty Miles',
                              format: 'count',
                              size: 242,
                              description: BID_ANALYSIS_TOOLTIPS.AVG_EMPTY_MILES_INBOUND
                          }),
                          generateColDef({
                              accessor: 'avg_empty_miles_outbound',
                              label: 'Outbound Avg. Empty Miles',
                              format: 'count',
                              size: 250,
                              description: BID_ANALYSIS_TOOLTIPS.AVG_EMPTY_MILES_OUTBOUND
                          }),
                          generateColDef({
                              accessor: 'avg_empty_miles_percentage_inbound',
                              label: 'In Avg. Empty - Loaded Miles %',
                              format: 'percentage',
                              size: 280,
                              description: BID_ANALYSIS_TOOLTIPS.AVG_EMPTY_MILES_PCT_INBOUND
                          }),
                          generateColDef({
                              accessor: 'avg_empty_miles_percentage_outbound',
                              label: 'Out Avg. Empty - Loaded Miles %',
                              format: 'percentage',
                              size: 285,
                              description: BID_ANALYSIS_TOOLTIPS.AVG_EMPTY_MILES_PCT_OUTBOUND
                          }),
                          generateColDef({
                              accessor: 'on_lane_cost',
                              label: 'On Lane Cost',
                              format: 'count',
                              size: 178,
                              description: BID_ANALYSIS_TOOLTIPS.ON_LANE_COST
                          }),
                          generateColDef({
                              accessor: 'mileage',
                              label: BIDDING_NAMES.MILEAGE,
                              format: 'count',
                              size: 188,
                              description: BID_ANALYSIS_TOOLTIPS.MILEAGE
                          }),
                          ...(showProfitColumns
                              ? [
                                    generateColDef({
                                        accessor: 'profit_per_load_and_dh_cost_inbound',
                                        label: 'In Profit Per Load + DH Cost',
                                        format: 'dollar',
                                        size: 258,
                                        description:
                                            BID_ANALYSIS_TOOLTIPS.INBOUND_PROFIT_PER_LOAD_PLUST_DH_COST
                                    }),
                                    generateColDef({
                                        accessor: 'profit_per_load_and_dh_cost_outbound',
                                        label: 'Out Profit Per Load + DH Cost',
                                        format: 'dollar',
                                        size: 258,
                                        description:
                                            BID_ANALYSIS_TOOLTIPS.OUTBOUND_PROFIT_PER_LOAD_PLUST_DH_COST
                                    })
                                ]
                              : [])
                      ]
                    : [])
            ]
        }),
        columnHelper.group({
            id: 'review-or-adjust-recommendations',
            header: () => <Box>Review or Adjust Decisions</Box>,
            enableSorting: false,
            meta: { sticky: 'right' },
            columns: [
                columnHelper.accessor('recommendation', {
                    id: 'recommendation',
                    header: () => 'Decision',
                    cell: (info) => renderRecommendationDropdown(info, setLaneDecision),
                    footer: (info) => info.column.id,
                    size: 160,
                    filterFn: (row, columnId, value) =>
                        textFilterV8(
                            row,
                            columnId,
                            value,
                            textFieldMatchingStrategy,
                            decisionValuesToLabelsMap
                        ),
                    meta: {
                        filterType: FILTER_TYPE.DROPDOWN,
                        visibleByDefault: true,
                        required: true,
                        hideQuickValueCopyingActions: hideQuickCopyActionsOnBidAnalysisTable,
                        name: 'Decision',
                        sticky: 'right',
                        dropdownValuesToLabelsMap: decisionValuesToLabelsMap,
                        infoMessage: BID_ANALYSIS_TOOLTIPS.DECISION
                    }
                }),
                columnHelper.accessor('volume_to_accept', {
                    id: 'volume_to_accept',
                    cell: (info) => <Box>{info.getValue()}</Box>,
                    footer: (info) => info.column.id,
                    size: 188,
                    filterFn: minMaxFilterV8,
                    header: () => 'Volume Accepted',
                    sortingFn: (rowA: any, rowB: any, columnId: any): number =>
                        Number(rowA.getValue(columnId)) < Number(rowB.getValue(columnId)) ? 1 : -1,
                    meta: {
                        filterType: FILTER_TYPE.NUMBER_RANGE,
                        visibleByDefault: true,
                        required: true,
                        validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT,
                        format: 'integer',
                        maxColumnId: 'total_volume_available',
                        displayableTargets: ['volume', 'revenue'],
                        hideQuickValueCopyingActions: hideQuickCopyActionsOnBidAnalysisTable,
                        name: 'Volume Accepted',
                        sticky: 'right',
                        infoMessage: BID_ANALYSIS_TOOLTIPS.VOL_TO_ACCEPT,
                        timeUnits: globalTimeUnits
                    }
                }),
                ...(flatRatesFlag && rateType === PREFERRED_RATE_TYPE.FLAT_RATE
                    ? [
                          columnHelper.accessor('flat_rate', {
                              id: 'flat_rate',
                              cell: (info) => <Box sx={dollarStyle}>{info.getValue() as any}</Box>,
                              footer: (info) => info.column.id,
                              size: 200,
                              filterFn: minMaxFilterV8,
                              header: () => BIDDING_NAMES.FLAT_RATE,
                              sortingFn: (rowA: any, rowB: any, columnId: any): number =>
                                  Number(rowA.getValue(columnId)) < Number(rowB.getValue(columnId))
                                      ? 1
                                      : -1,
                              meta: {
                                  filterType: FILTER_TYPE.NUMBER_RANGE,
                                  visibleByDefault: true,
                                  required: true,
                                  validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT,
                                  format: 'dollars',
                                  cellStyle: dollarStyle,
                                  hideQuickValueCopyingActions:
                                      hideQuickCopyActionsOnBidAnalysisTable,
                                  name: BIDDING_NAMES.FLAT_RATE,
                                  sticky: 'right',
                                  infoMessage: BID_ANALYSIS_TOOLTIPS.FLAT_RATE,
                                  ...(flatRatesFlag
                                      ? {
                                            swapColumns: {
                                                category: 'rate',
                                                tooltip: 'Swap Rate Type',
                                                options: [
                                                    {
                                                        value: 'flat_rate',
                                                        label: BIDDING_NAMES.FLAT_RATE
                                                    },
                                                    {
                                                        value: 'rate_per_mile',
                                                        label: BIDDING_NAMES.RATE_PER_MILE
                                                    }
                                                ],
                                                callback: (option: string, table: Table<DataRow>) =>
                                                    handleSwapRateColumn(option, table)
                                            }
                                        }
                                      : {})
                              }
                          })
                      ]
                    : [
                          columnHelper.accessor('rate_per_mile', {
                              id: 'rate_per_mile',
                              cell: (info) => <Box sx={dollarStyle}>{info.getValue() as any}</Box>,
                              footer: (info) => info.column.id,
                              size: 200,
                              filterFn: minMaxFilterV8,
                              header: () => BIDDING_NAMES.RATE_PER_MILE,
                              sortingFn: (rowA: any, rowB: any, columnId: any): number =>
                                  Number(rowA.getValue(columnId)) < Number(rowB.getValue(columnId))
                                      ? 1
                                      : -1,
                              meta: {
                                  filterType: FILTER_TYPE.NUMBER_RANGE,
                                  visibleByDefault: true,
                                  required: true,
                                  validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT,
                                  format: 'dollars',
                                  cellStyle: dollarStyle,
                                  hideQuickValueCopyingActions:
                                      hideQuickCopyActionsOnBidAnalysisTable,
                                  name: BIDDING_NAMES.RATE_PER_MILE,
                                  sticky: 'right',
                                  infoMessage: BID_ANALYSIS_TOOLTIPS.RATE_PER_MILE,
                                  ...(flatRatesFlag
                                      ? {
                                            swapColumns: {
                                                category: 'rate',
                                                tooltip: 'Swap Rate Type',
                                                options: [
                                                    {
                                                        value: 'flat_rate',
                                                        label: BIDDING_NAMES.FLAT_RATE
                                                    },
                                                    {
                                                        value: 'rate_per_mile',
                                                        label: BIDDING_NAMES.RATE_PER_MILE
                                                    }
                                                ],
                                                callback: (option: string, table: Table<DataRow>) =>
                                                    handleSwapRateColumn(option, table)
                                            }
                                        }
                                      : {})
                              }
                          })
                      ])
            ]
        })
    ];

    return columns as ColumnDef<DataRow, string | number | object | null>[];
};

export default bidAnalysisColumnsInitiate;
