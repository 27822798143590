import ODSnackbar, { ODSNACKBAR_TYPE } from 'shared/Snackbar';

const InvalidBidConfigRateSnackbar = ({
    open,
    onCloseHandler
}: {
    open: boolean;
    onCloseHandler: () => void;
}) => {
    return (
        <ODSnackbar
            text={`Your bid file contains lanes with invalid rates. To be able to apply your bid file to an analysis enter a rate per mile for each lane, or remove the lanes that are not needed for your bid file. Once each lane has a rate per mile you can and apply it to an analysis in network view.`}
            open={open}
            onCloseHandler={onCloseHandler}
            type={ODSNACKBAR_TYPE.ERROR}
        />
    );
};

export default InvalidBidConfigRateSnackbar;
