/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { intersection } from 'lodash';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'redux/reducers';
import { NOTIFICATION_STATUS } from 'constants/reports';
import { REPORT_STATUS } from 'constants/network';
import {
    updateNetworkView,
    UpdateNewNotifications,
    GetNewReportNotifications
} from 'redux/actions';
import { isFailedReportStatus } from 'utils/network.util';
import useNetworkManagerHook from './networkManagerHook';

// Notifying the user with modal for completed reports and an error bar for failed reports
// Once notified the notification status is considered read and will only show in the alert slideout unless it's deleted

const useNotificationHook = () => {
    const { getNetworkManagerReports } = useNetworkManagerHook();
    const { newNotificationFlow } = useFlags();
    const dispatch = useDispatch();
    const alertsContext = useAlerts();
    const { notificationDetails, reports } = useSelector(
        (state: RootState) => state.ReportsReducers
    );
    const { isCompletedReport } = useSelector((state: RootState) => state.NetworkReducer);
    const [allReportRuns, setAllReportsRuns] = useState([]);

    function findMatchingValues(arr1: any[], arr2: any[]) {
        const matchingIds = intersection(
            arr1.map((el: any) => el.id),
            arr2.map((el: any) => el.report_run_id)
        );
        const matchingValues = arr2
            .filter((el) => matchingIds.includes(el.report_run_id))
            .map((el) => {
                const { report_run_id } = el;
                const { name, report_status } = arr1.find((el) => el.id === report_run_id);
                return { ...el, name, report_status };
            });
        return matchingValues;
    }
    useEffect(() => {
        setAllReportsRuns(
            reports.filter((report: any) => report.report_status !== REPORT_STATUS.DRAFT)
        );
    }, [reports]);
    useEffect(() => {
        if (notificationDetails.length > 0) getNetworkManagerReports();
    }, [notificationDetails]);
    useEffect(() => {
        if (newNotificationFlow) {
            const matchingValues = findMatchingValues(allReportRuns, notificationDetails);
            matchingValues.forEach(async (report) => {
                const notifStatus = report?.status;
                if (notifStatus === NOTIFICATION_STATUS.UNREAD) {
                    if (
                        [REPORT_STATUS.COMPLETED, REPORT_STATUS.VFA_COMPLETED].includes(
                            report.report_status
                        ) &&
                        !isCompletedReport.show
                    ) {
                        await dispatch(
                            updateNetworkView({ isCompletedReport: { show: true, report } })
                        );
                        return;
                    } else if (isFailedReportStatus(report.report_status)) {
                        alertsContext?.addAlert('report_failed', report.name);
                        dispatch(
                            UpdateNewNotifications({
                                notification: {
                                    id: report?.id,
                                    status: NOTIFICATION_STATUS.READ
                                }
                            })
                        );
                    }
                }
            });
        }
    }, [allReportRuns, notificationDetails]);

    //Set an interval to retrieve notification every 3 mins
    useEffect(() => {
        if (newNotificationFlow) {
            const interval = setInterval(async () => {
                await dispatch(GetNewReportNotifications());
            }, 28000);

            return () => clearInterval(interval);
        }
    }, []);
};

export { useNotificationHook };
