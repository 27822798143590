import {
    Title,
    LaneContainer
} from 'components/BidAnalysis/LaneScoreSlideout/LaneScoreSlideout.styles';
import { DriverLanesIcon } from 'assets/images';
import LocationsInfo from 'components/NetworkView/LocationsInfo';
import { convertTo5Zip } from 'utils/number.util';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import theme from 'theme';

interface LaneOriginDestinationRibbonProps {
    origin: number;
    destination: number;
}
const LaneOriginDestinationRibbon = ({ origin, destination }: LaneOriginDestinationRibbonProps) => {
    const originLocation = <LocationsInfo value={convertTo5Zip(origin)} />;
    const destinationLocation = <LocationsInfo value={convertTo5Zip(destination)} />;
    return (
        <LaneContainer>
            <Title
                sx={{
                    backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
                    fontSize: '14px'
                }}>
                <DriverLanesIcon
                    style={{
                        fill: theme.palette.black,
                        marginRight: '16px'
                    }}
                />
                {originLocation}
                <ArrowForwardOutlinedIcon
                    fontSize="small"
                    sx={{ color: theme.palette.neutral.black, margin: '0 16px' }}
                />
                {destinationLocation}
            </Title>
        </LaneContainer>
    );
};

export default LaneOriginDestinationRibbon;
