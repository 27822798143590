import { reduce } from 'lodash';
import theme from 'theme';

const options = {
    fontSize: 11,
    backgroundColor: 'transparent',
    legend: 'none',
    pieSliceText: 'none',
    slices: [
        {},
        { color: theme.palette.barColors.focusedBlue },
        { color: theme.palette.barColors.semanticYellow },
        { color: theme.palette.barColors.accessibleBrown },
        { color: theme.palette.barColors.accessibleLightBlue },
        { color: theme.palette.barColors.accessibleOrange },
        { color: theme.palette.barColors.accessibleGreen },
        { color: theme.palette.barColors.accessibleBlue },
        { color: theme.palette.barColors.accessibleMagenta },
        { color: theme.palette.barColors.accessibleGray },
        { color: theme.palette.barColors.accessiblePurple },
        { color: theme.palette.barColors.accessiblePurple },
        { color: theme.palette.barColors.accessibleDarkGray },
        { color: theme.palette.barColors.accessibleOlive },
        { color: theme.palette.barColors.accessibleTeal },
        { color: theme.palette.barColors.accessibleLightGreen },
        { color: theme.palette.barColors.accessibleDarkOlive },
        { color: theme.palette.barColors.accessibleBeige },
        { color: theme.palette.barColors.accessibleDarkBlue },
        { color: theme.palette.barColors.accessibleLightGray },
        { color: theme.palette.barColors.white }
    ]
};

window.google.load('visualization', '1', { packages: ['corechart'] });
window.google.charts.setOnLoadCallback(() => {
    console.info('setOnLoadCallback');
});

const ChartMarker = (markerData, configAction, indicatorColor, allDriverTypes) => {
    options.pieSliceBorderColor = indicatorColor;
    return new Promise((resolve) => {
        try {
            let total = 0;
            const { dataObj } = reduce(
                markerData,
                (store, value, key) => {
                    let { count, index } = value;
                    store.idx = allDriverTypes?.driver_types.indexOf(key) + 1;
                    const isConfig = configAction && configAction[key];
                    total = total + count + (isConfig ? configAction[key] : 0);
                    count += isConfig ? configAction[key] : 0;
                    store.dataObj[index || store.idx] = [key, count];
                    return store;
                },
                {
                    idx: 0,
                    dataObj: [...Array(20).keys()].map((key) => [key.toString(), 0])
                }
            );
            const newDataObj = dataObj.filter((item) => item);

            const data = window.google.visualization.arrayToDataTable([
                    ['Type', 'Count'],
                    ...newDataObj
                ]),
                div = document.createElement('div'),
                chart = new window.google.visualization.PieChart(div),
                size = parseInt(30 + total * 0.2);

            window.google.visualization.events.addListener(chart, 'ready', () => {
                resolve(chart.getImageURI());
            });
            chart.draw(data, { ...options, width: size, height: size });
        } catch (e) {
            console.error(e);
        }
    });
};

export default ChartMarker;
