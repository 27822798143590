import { UPDATE_LOADING_STATE } from 'redux/constants/actionTypes';

interface LoadingState {
    showLoading: boolean;
    loadingCounter: number;
    text: string;
}
interface LoadingAction {
    type: string;
    payload: {
        showLoading: boolean;
        loadingCounter: number;
        text?: string;
    };
    error?: any;
}
const initialState: LoadingState = {
    showLoading: false,
    loadingCounter: 0,
    text: 'Loading...'
};

const updateState = (state: LoadingState, action: LoadingAction) => {
    return {
        ...state,
        ...action.payload
    };
};

const LoadingReducer = (state = initialState, action: LoadingAction) => {
    switch (action.type) {
        case UPDATE_LOADING_STATE: {
            return updateState(state, action);
        }

        default:
            return state;
    }
};

export default LoadingReducer;
