/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import ODTableColumnSelectionsSlideout from 'shared/ODTable/ODTableColumnSelectionSlideout';
import { HeaderTopBar, ODTable, HelperMessage, ODButton } from 'shared';
import { Box, Modal, Typography } from '@mui/material';
import SaveBidDialog from './SaveBidDialog';
import { updateBiddingState, updateNetworkView } from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import editBidConfigColumnsInitiate, { separatedColumns } from './EditBidConfigTableColumns';
import BidConfigFooter from './EditBidConfigFooter';
import { ConfigurationIcon } from 'assets/images';
import theme from 'theme';
import SaveNewBidDialog from './SaveNewBidDialog';
import RightNavbar from 'components/Layout/RightNavbar';
import { RootState } from 'types/redux';
import {
    BUSINESS_TYPE,
    BIDDING_TABLE_NAME,
    BIDDING_NAMES,
    BID_CONFIG_INFO_MESSAGES
} from 'constants/bidding';
import { ODTableProvider } from 'shared/ODTable/ODTableContext';
import ODLoader from 'shared/ODLoader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PREFERRED_RATE_TYPE } from 'constants/settings';
import InvalidBidConfigRateSnackbar from './InvalidBidConfigRateSnackbar';
import * as FS from '@fullstory/browser';
import BidConfigSettingsSlideout from 'components/Slideouts/BidConfigSettingsSlideout';
import HistorySlideOut from 'components/Slideouts/HistorySlideout';
import DuplicateBidFilesModal from './DuplicateBidFilesDialog';
import RunBidAnalysesSlideout from './RunBidAnalysesSlideout/RunBidAnalysesSlideout';
import RequestQuoteSharpIcon from '@mui/icons-material/RequestQuoteSharp';
import { BUTTON_TYPE } from 'constants/colors';
import SaveAltSharpIcon from '@mui/icons-material/SaveAltSharp';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import SaveBidFileChangeDialog from './SaveBidFileChangeDialog';
import BidConfigBreadcrumbs from './BidConfigBreadcrumbs';
import {
    BreadcrumbsSection,
    NewBidConfigHeaderToolbar,
    TableFooterContainer,
    StyledBidConfigBodyContainer,
    BidConfigRightContent,
    BidConfigsLeftContent,
    BidConfigPage4185,
    BidConfigPage,
    TabsHeaderSection
} from './styles/EditBidConfigTable.styles';
import HeaderTabs from './HeaderTabs';
import { TABLE_TYPE } from 'constants/network';
import useBidConfigHook from 'utils/hooks/useBidConfigHook';

interface BidConfigProps {
    open: boolean;
    setOpenBidConfig: (open: boolean) => void;
    bidConfigId: number;
}

const BidConfig = ({ open = true, setOpenBidConfig, bidConfigId }: BidConfigProps) => {
    const {
        displayBidConfigErrors,
        showBidConfigMarketRate,
        flatRates,
        salesRanking,
        lineHaulRevPerMile,
        odpt3830NameConsistency,
        odpt407934Zip,
        odpt4185BidConfigUpdate,
        odpt2646BidConfigPaginationWithApi
    } = useFlags();
    const dispatch = useDispatch();
    const {
        loading,
        lanes,
        rowsToDelete,
        setRowsToDelete,
        checkedRows,
        setCheckedRows,
        configId,
        configInfo,
        openBidAnalysesSlideout,
        closeBidAnalysesSlideout,
        rateColumnRef,
        numberOfValidLanes,
        laneData,
        handleCellEdits,
        applyHistoricRatesCallback,
        handleSwapRateColumn,
        prepareLanesForDelete,
        restoreLanes,
        saveBidLanes,
        saveNewBidConfig,
        closeBidConfigSetting,
        handleOpenSettings,
        setPageSizeInHook,
        setPageIndexInHook,
        totalItems
    } = useBidConfigHook({ bidConfigId });
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showSaveNewBidModal, setShowSaveNewBidModal] = useState(false);
    const {
        bidConfigColumnFormatSelections,
        showTableDataErrorAlert,
        showTableSettings,
        bidConfigTableDataErrors,
        //Todo: To use for unsaved changes in history slideout
        // actionsAwaitingConfigurationCreation,
        openBidConfigSettingSlideout,
        openDupBidFile,
        openRunBidAnalysesSlideout,
        columnFilters,
        sortingState
    } = useSelector((state: RootState) => state.BiddingReducer);
    const { showHistorySlideout } = useSelector((state: RootState) => state.NetworkReducer);
    const { configuration, configurationActions, prevConfigurationActions } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );
    const flatRatesFlag = flatRates;
    const lineHaulRevPerMileFlag = lineHaulRevPerMile;
    const displayBidConfigErrorsFlag = displayBidConfigErrors;
    const interactiveColumns = [
        'rate_per_mile',
        'flat_rate',
        'other_revenue',
        'other_revenue_per_mile'
    ];

    const sourceColumns = [];
    if (showBidConfigMarketRate) {
        sourceColumns.push({
            columnId: 'market_rate',
            columnName: 'Market Rate'
        });
    }
    sourceColumns.push({
        columnId: 'historic_rate',
        columnName: 'Historic Rate'
    });
    const crossCopyColumns = [
        {
            destColumnHeaderData: {
                columnId: 'rate_per_mile',
                columnName: 'Proposed Rate / Mile'
            },
            sourceColumns: sourceColumns
        },
        {
            destColumnHeaderData: {
                columnId: 'flat_rate',
                columnName: BIDDING_NAMES.FLAT_RATE
            },
            sourceColumns: sourceColumns
        }
    ];

    const { hiddenColumnsArrays } = useSelector((state: RootState) => state.TableLayoutReducer);

    const minRate = lanes.reduce((min: any, obj: any) => {
        return obj.rate_per_mile < min ? obj.rate_per_mile : min;
    }, lanes[0]?.rate_per_mile);

    return (
        <>
            <ODLoader open={loading} text="Loading..." />
            {odpt4185BidConfigUpdate ? (
                <>
                    <RightNavbar bidConfigView={true} />
                    <BidConfigPage4185>
                        <BreadcrumbsSection>
                            <BidConfigBreadcrumbs currConfigName={configInfo?.fileName} />
                        </BreadcrumbsSection>
                        <NewBidConfigHeaderToolbar>
                            <Box display="flex">
                                <RequestQuoteSharpIcon sx={{ mx: '16px' }} />
                                <Typography fontWeight={600}>{configInfo.fileName}</Typography>
                            </Box>
                            <Box display="flex">
                                {/* TODO disable until the download feature is ready */}
                                {/* <ODButton
                                    sx={{ marginRight: '16px' }}
                                    buttonText="Download Bid File"
                                    endIcon={<DownloadSharpIcon />}
                                    onClick={() => downloadBidConfigFile()}
                                /> */}
                                <ODButton
                                    sx={{ marginRight: '16px' }}
                                    buttonType={BUTTON_TYPE.BLUE}
                                    buttonText="Save Bid File Changes"
                                    endIcon={<SaveAltSharpIcon />}
                                    onClick={() => setShowSaveModal(true)}
                                />
                                {!openRunBidAnalysesSlideout && (
                                    <ODButton
                                        buttonType={BUTTON_TYPE.BLUE}
                                        buttonText="Run Bid Analysis"
                                        endIcon={<ArrowForwardSharpIcon />}
                                        onClick={() => openBidAnalysesSlideout()}
                                        sx={{ marginRight: '16px' }}
                                    />
                                )}
                            </Box>
                        </NewBidConfigHeaderToolbar>
                        <Box
                            sx={{
                                height: 'calc(100% - 98px)', // TODO may need to rectify when adding pagination
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden'
                            }}>
                            <StyledBidConfigBodyContainer>
                                <BidConfigsLeftContent
                                    odpt4185BidConfigUpdate={odpt4185BidConfigUpdate}
                                    openRunBidAnalysesSlideout={openRunBidAnalysesSlideout}>
                                    <TabsHeaderSection>
                                        <HeaderTabs lanesLength={numberOfValidLanes} />
                                    </TabsHeaderSection>
                                    <ODTableProvider
                                        checkedRows={checkedRows}
                                        crossCopyColumns={crossCopyColumns}
                                        separatedColumns={separatedColumns}
                                        hiddenColumnIds={[
                                            ...hiddenColumnsArrays[
                                                BIDDING_TABLE_NAME.BID_CONFIGURATION
                                            ]
                                        ]}
                                        data={laneData}
                                        downloadOption={false}
                                        editOption={false}
                                        addPagination={true}
                                        interactiveTable={true}
                                        cellEditsCallback={handleCellEdits}
                                        handleClickSettingsIcon={() => {
                                            FS.event(
                                                'Bid Config_Table Settings_Click Table Settings Icon',
                                                {}
                                            );
                                            dispatch(
                                                updateBiddingState({ showTableSettings: true })
                                            );
                                        }}
                                        interactiveColumns={interactiveColumns}
                                        columnDefs={editBidConfigColumnsInitiate(
                                            'inclusion',
                                            showBidConfigMarketRate,
                                            handleSwapRateColumn,
                                            bidConfigColumnFormatSelections.rate as PREFERRED_RATE_TYPE,
                                            flatRatesFlag,
                                            lineHaulRevPerMileFlag,
                                            salesRanking,
                                            odpt3830NameConsistency
                                        )}
                                        columnFormatSelections={bidConfigColumnFormatSelections}
                                        removeOption={true}
                                        rowsToDelete={rowsToDelete}
                                        setCheckedRows={setCheckedRows}
                                        setRowsToDelete={setRowsToDelete}
                                        columnFilterOptions={{
                                            value: columnFilters,
                                            setter: (newColFilters) =>
                                                dispatch(
                                                    updateBiddingState({
                                                        columnFilters: newColFilters
                                                    })
                                                )
                                        }}
                                        sortOptions={{
                                            value: sortingState,
                                            setter: (newSorting) =>
                                                dispatch(
                                                    updateBiddingState({ sortingState: newSorting })
                                                )
                                        }}
                                        tableDataErrors={
                                            displayBidConfigErrors
                                                ? bidConfigTableDataErrors
                                                : undefined
                                        }>
                                        <>
                                            <ODTable
                                                rateColumnRef={rateColumnRef}
                                                setPageIndexInHook={
                                                    odpt2646BidConfigPaginationWithApi
                                                        ? setPageIndexInHook
                                                        : undefined
                                                }
                                                setPageSizeInHook={
                                                    odpt2646BidConfigPaginationWithApi
                                                        ? setPageSizeInHook
                                                        : undefined
                                                }
                                                manualPagination={
                                                    odpt2646BidConfigPaginationWithApi
                                                }
                                                bottomActionBar={true}
                                                totalItems={totalItems}
                                                tableKey={TABLE_TYPE.BID_CONFIG_TABLE}
                                                data-testid="bid-config-table"
                                            />

                                            <ODTableColumnSelectionsSlideout
                                                tableName={BIDDING_TABLE_NAME.BID_CONFIGURATION}
                                                onClose={() =>
                                                    dispatch(
                                                        updateBiddingState({
                                                            showTableSettings: false
                                                        })
                                                    )
                                                }
                                                open={showTableSettings}
                                            />
                                        </>
                                    </ODTableProvider>

                                    <TableFooterContainer
                                        openRunBidAnalysesSlideout={openRunBidAnalysesSlideout}>
                                        {checkedRows?.length > 0 && (
                                            <BidConfigFooter
                                                disabled={false}
                                                saveBidButtonCallback={() => setShowSaveModal(true)}
                                                saveNewBidButtonCallback={() =>
                                                    setShowSaveNewBidModal(true)
                                                }
                                                applyHistoricRatesCallback={
                                                    applyHistoricRatesCallback
                                                }
                                                removeLanesButtonCallback={prepareLanesForDelete}
                                                lanes={lanes}
                                                checkedRows={checkedRows}
                                                bidConfigTitle={'2023 Bid'}
                                                restoreRemovedLanesCallback={restoreLanes}
                                                runBidAnalysesCallback={openBidAnalysesSlideout}
                                                someRowsDeleted={rowsToDelete.length !== 0}
                                            />
                                        )}
                                    </TableFooterContainer>
                                </BidConfigsLeftContent>

                                {openRunBidAnalysesSlideout && (
                                    <BidConfigRightContent>
                                        <RunBidAnalysesSlideout
                                            configId={configId}
                                            minRate={minRate}
                                            onClose={() => closeBidAnalysesSlideout()}
                                        />
                                    </BidConfigRightContent>
                                )}
                            </StyledBidConfigBodyContainer>
                        </Box>
                    </BidConfigPage4185>
                    <SaveBidFileChangeDialog
                        title={configInfo.fileName}
                        onClose={() => {
                            setShowSaveModal(false);
                        }}
                        open={showSaveModal}
                        onSave={() => {
                            saveBidLanes().finally(() => {
                                setShowSaveModal(false);
                            });
                        }}
                    />
                    <DuplicateBidFilesModal
                        onClose={() => dispatch(updateBiddingState({ openDupBidFile: false }))}
                        open={openDupBidFile}
                        onSave={(fileName) => {
                            if (fileName) {
                                saveNewBidConfig(fileName);
                            }
                        }}
                    />
                    {displayBidConfigErrorsFlag && (
                        <InvalidBidConfigRateSnackbar
                            open={showTableDataErrorAlert}
                            onCloseHandler={() =>
                                dispatch(updateBiddingState({ showTableDataErrorAlert: false }))
                            }
                        />
                    )}
                    <BidConfigSettingsSlideout
                        show={openBidConfigSettingSlideout}
                        onClose={closeBidConfigSetting}
                    />
                    <HistorySlideOut
                        show={showHistorySlideout}
                        onClose={() => dispatch(updateNetworkView({ showHistorySlideout: false }))}
                        configuration={{
                            ...configuration,
                            actions: configurationActions,
                            prevActions: prevConfigurationActions
                        }}
                        selectedRunType={configId as any}
                        bidConfigView={true}
                    />
                </>
            ) : (
                <Modal
                    sx={{ zIndex: 1300 }}
                    open={open}
                    onClose={() => setOpenBidConfig(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    /* hides the blue outline in Chrome.
                See here: https://github.com/mui/material-ui/issues/11504#issuecomment-390506409 */
                    disableAutoFocus={true}>
                    <>
                        <BidConfigPage>
                            <HeaderTopBar
                                onClose={() => setOpenBidConfig(false)}
                                handleOpenSettings={odpt407934Zip ? handleOpenSettings : undefined}
                                fileTypeName="Bid File"
                                fileTypeIcon={
                                    <ConfigurationIcon fill={theme.palette.neutral.black} />
                                }
                                fileDetailItems={[
                                    {
                                        name: 'Bid File Name',
                                        value: configInfo.fileName,
                                        hide: false
                                    },
                                    {
                                        name: 'Business Type',
                                        value: configInfo.businessType,
                                        hide: false
                                    },
                                    {
                                        name: 'Shipper Profile',
                                        value: configInfo.referenceShipperId,
                                        hide: false
                                    },
                                    {
                                        name: 'New Shipper ID Name',
                                        value: configInfo.shipperId,
                                        hide: configInfo.businessType === BUSINESS_TYPE.EXISTING
                                    }
                                ]}
                            />
                            <Box
                                sx={{
                                    margin: '16px',
                                    position: 'relative',
                                    height: '80%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                <HelperMessage
                                    message={BID_CONFIG_INFO_MESSAGES.BID_CONFIG_INSTRUCTIONS}
                                />

                                <Box marginTop="16px">
                                    <BidConfigFooter
                                        disabled={false}
                                        saveBidButtonCallback={() => setShowSaveModal(true)}
                                        saveNewBidButtonCallback={() =>
                                            setShowSaveNewBidModal(true)
                                        }
                                        applyHistoricRatesCallback={applyHistoricRatesCallback}
                                        removeLanesButtonCallback={prepareLanesForDelete}
                                        lanes={laneData}
                                        checkedRows={checkedRows}
                                        bidConfigTitle={'2023 Bid'}
                                        restoreRemovedLanesCallback={restoreLanes}
                                        runBidAnalysesCallback={openBidAnalysesSlideout}
                                        someRowsDeleted={rowsToDelete.length !== 0}
                                    />
                                </Box>

                                <ODTableProvider
                                    checkedRows={checkedRows}
                                    crossCopyColumns={crossCopyColumns}
                                    separatedColumns={separatedColumns}
                                    hiddenColumnIds={[
                                        ...hiddenColumnsArrays[BIDDING_TABLE_NAME.BID_CONFIGURATION]
                                    ]}
                                    data={laneData}
                                    downloadOption={false}
                                    editOption={false}
                                    interactiveTable={true}
                                    cellEditsCallback={handleCellEdits}
                                    handleClickSettingsIcon={() => {
                                        FS.event(
                                            'Bid Config_Table Settings_Click Table Settings Icon',
                                            {}
                                        );
                                        dispatch(updateBiddingState({ showTableSettings: true }));
                                    }}
                                    interactiveColumns={interactiveColumns}
                                    columnDefs={editBidConfigColumnsInitiate(
                                        'inclusion',
                                        showBidConfigMarketRate,
                                        handleSwapRateColumn,
                                        bidConfigColumnFormatSelections.rate as PREFERRED_RATE_TYPE,
                                        flatRatesFlag,
                                        lineHaulRevPerMileFlag,
                                        salesRanking,
                                        odpt3830NameConsistency
                                    )}
                                    columnFormatSelections={bidConfigColumnFormatSelections}
                                    removeOption={true}
                                    rowsToDelete={rowsToDelete}
                                    setCheckedRows={setCheckedRows}
                                    setRowsToDelete={setRowsToDelete}
                                    tableDataErrors={
                                        displayBidConfigErrors
                                            ? bidConfigTableDataErrors
                                            : undefined
                                    }>
                                    <>
                                        <ODTable tableKey={'bid-config-table'} />
                                        <ODTableColumnSelectionsSlideout
                                            tableName={BIDDING_TABLE_NAME.BID_CONFIGURATION}
                                            onClose={() =>
                                                dispatch(
                                                    updateBiddingState({ showTableSettings: false })
                                                )
                                            }
                                            open={showTableSettings}
                                        />
                                    </>
                                </ODTableProvider>
                            </Box>
                        </BidConfigPage>
                        <SaveBidDialog
                            title={configInfo.fileName}
                            onClose={() => {
                                setShowSaveModal(false);
                            }}
                            open={showSaveModal}
                            onSave={() => {
                                saveBidLanes();
                                setOpenBidConfig(false);
                            }}
                        />
                        <SaveNewBidDialog
                            onClose={() => {
                                setShowSaveNewBidModal(false);
                            }}
                            open={showSaveNewBidModal}
                            onSave={(fileName) => {
                                if (fileName) {
                                    saveNewBidConfig(fileName);
                                    setOpenBidConfig(false);
                                }
                            }}
                        />
                        {displayBidConfigErrorsFlag && (
                            <InvalidBidConfigRateSnackbar
                                open={showTableDataErrorAlert}
                                onCloseHandler={() =>
                                    dispatch(updateBiddingState({ showTableDataErrorAlert: false }))
                                }
                            />
                        )}
                    </>
                </Modal>
            )}
        </>
    );
};

export default BidConfig;
