import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { ODDrawer, ODButton } from 'shared';
import theme from 'theme';
import { RootState } from 'redux/reducers';
import { BUTTON_TYPE } from 'constants/colors';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useSimulationHook, useLoadingHook } from 'utils/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { isDraftReportUtil } from 'utils/network.util';
import { getConfigurationActions, onSetConfigurationSuccess } from 'redux/actions';
import { useParams } from 'react-router-dom';
import { useParamsProps } from '../../../types/hooks';
import AddDriverTypeAccordion from './AddDriverTypeAccordion';
import DriverTypeAccordion from './DriverTypeAccordion';
import ODMultiSelectCore from 'shared/ODMultiSelect/ODMultiSelectCore';
import LocationsInfo from 'components/NetworkView/LocationsInfo';
import { addDomicileConfig } from './drivers.utils';

//TODO consolidate add new and edit slideout

const EditDriverSlideout = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();
    const { base_run, compare_run }: useParamsProps = useParams();
    const currReportId = compare_run || base_run;

    /*hooks/redux*/
    const { newSimulationFlow, newDomicileSlideout } = useFlags();
    const { createSimulationOnEdit } = useSimulationHook();
    const { showLoader } = useLoadingHook();
    const { reportFilters } = useSelector((state: RootState) => state.ReportsReducers);
    const { configuration, configurationActions } = useSelector(
        ({ ConfigurationReducer }: RootState) => ConfigurationReducer
    );
    const { aggregationLevel } = useSelector(({ settingsReducer }: RootState) => settingsReducer);
    const { slideout } = useSelector((state: RootState) => state.NetworkReducer);
    const { editDriver, driverTypesColors } = useSelector(
        (state: RootState) => state.DriversReducer
    );

    /**state */
    const [driverTypes, setDriverTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [currUpdatedTypes, setCurrUpdatedTypes] = useState([]);
    const [openDrawer, setOpenDrawer] = useState({
        domicile: true,
        addDriverTypes: true
    });

    const driverTypesOptions = driverTypes?.map((type: any) => type?.id);
    const currDriverTypes = editDriver?.driver_types?.map((type: any) => type?.driver_type);
    const selectedDriverTypeOptions = selectedTypes?.map((type: any) => type?.id);
    const filteredNewDriverTypes = driverTypesOptions?.filter(
        (type: any) => !currDriverTypes?.includes(type)
    );
    const filteredDriverTypeList = driverTypes?.filter((type: any) =>
        filteredNewDriverTypes?.includes(type?.id)
    );

    const associatedActionsByDomicileId = configurationActions?.filter((action: any) => {
        return action?.value?.domicile === editDriver?.domicile_id;
    });

    const resetDriverTypes = () => {
        const driverTypes = (reportFilters?.driver_types || [])?.map(({ id }: { id: string }) => ({
            id,
            value: id,
            name: id,
            total: 0
        }));

        setDriverTypes(driverTypes);
    };

    const handleClose = () => {
        onClose();
        setSelectedTypes([]);
        setCurrUpdatedTypes([]);
        resetDriverTypes();
    };

    const updateTotal = (type: { id: any }, total: any): any => {
        const _selectedTypes = selectedTypes?.map((driverType: any) => {
            if (driverType.id === type.id) driverType.total = total;
            return driverType;
        }) as any;

        setSelectedTypes(_selectedTypes);
    };

    const updateTotalForCurrTypes = (type: any, currActionCount: any) => {
        const cloned = JSON.parse(JSON.stringify(currUpdatedTypes));
        const objToUpdate = cloned?.find(
            (item: any): any => item?.driver_type === type?.driver_type
        ) as any;

        if (objToUpdate) {
            objToUpdate.actionCount = currActionCount;
            setCurrUpdatedTypes(cloned);
        }
    };

    const allowAddDrivers = () => {
        if (!isDraftReportUtil(configuration)) {
            dispatch(onSetConfigurationSuccess({ showTopAlert: true }));
            return false;
        } else return true;
    };

    const getConfigActions = (simulationId: string) => {
        dispatch(getConfigurationActions(currReportId, simulationId));
    };

    const handleSubmit = async () => {
        handleClose();
        showLoader(true);
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;

        await Promise.all([
            selectedTypes?.map((driverType: { total: number; name: string }) => {
                //TODO handle backend if the user decides to go from 4 to 0. BE needs to remove the driver_type from action.
                if (driverType.total === 0) return true;
                return addDomicileConfig(
                    {
                        location: editDriver.domicileName,
                        isNew: false,
                        domicile_id: editDriver.domicile_id,
                        driver_type: driverType.name,
                        quantity: driverType.total
                    },
                    simulationId,
                    newSimulationFlow,
                    allowAddDrivers(),
                    dispatch,
                    currReportId
                );
            }),
            currUpdatedTypes?.map(
                (driverType: { count: number; driver_type: string; actionCount?: number }) => {
                    if (!driverType.actionCount || driverType.actionCount === 0) return true;
                    return addDomicileConfig(
                        {
                            location: editDriver.domicileName,
                            isNew: false,
                            domicile_id: editDriver.domicile_id,
                            driver_type: driverType.driver_type,
                            quantity: driverType.actionCount
                        },
                        simulationId,
                        newSimulationFlow,
                        allowAddDrivers(),
                        dispatch,
                        currReportId
                    );
                }
            )
        ]);
        getConfigActions(simulationId);
        showLoader(false);
    };

    const handleOnChangeDriverType = (e: any) => {
        let selected: React.SetStateAction<never[]> = [];
        if (e.includes('all')) {
            const filterdEventList = e.filter(
                (ent: any) => ![null, undefined, '', 'all'].includes(ent)
            );
            selected =
                filterdEventList?.length === filteredDriverTypeList?.length
                    ? []
                    : filteredDriverTypeList;
        } else {
            selected = driverTypes.filter((type: any) => {
                return e.includes(type?.id);
            });
        }
        setSelectedTypes(selected);
    };

    const getSelectedTypeTotalCount = (type: string) => {
        let associatedActionsByDomicileId = configurationActions?.filter((action: any) => {
            return action?.value?.domicile === editDriver?.domicile_id;
        });
        let configured = associatedActionsByDomicileId.filter((action: any) => {
            return action?.value?.driver_type === type;
        });
        return configured[0]?.value?.quantity || 0;
    };

    useEffect(() => {
        resetDriverTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportFilters?.driver_types]);

    useEffect(() => {
        if (associatedActionsByDomicileId.length > 0) {
            const currTypeListNames = currUpdatedTypes?.map((item: { driver_type: string }) => {
                return item?.driver_type;
            });
            const types = associatedActionsByDomicileId?.filter(
                (item: any) => !currTypeListNames?.includes(item?.value?.driver_type)
            );
            const transformed = types?.map((item: any) => {
                return {
                    id: item?.value.driver_type,
                    name: item?.value.driver_type,
                    total: item?.value.quantity,
                    value: item?.value.driver_type
                };
            });
            setSelectedTypes(transformed);
        } else {
            setSelectedTypes([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editDriver, configurationActions, currUpdatedTypes]);

    useEffect(() => {
        const changedDriverType = editDriver?.driver_types?.map((item: any) => {
            return item?.driver_type;
        });
        const actionsAssociatedWithCurrTypes = associatedActionsByDomicileId?.filter(
            (type: any) => {
                return changedDriverType?.includes(type?.value?.driver_type);
            }
        );
        const transformed = actionsAssociatedWithCurrTypes.map((item: any) => {
            let actualCount = editDriver?.driver_types?.find(
                (type: { id: string; driver_type: string }) => type.id === item?.driver_type
            )?.count;
            return {
                driver_type: item?.value?.driver_type,
                count: actualCount,
                actionCount: item?.value?.quantity
            };
        });
        const result = transformed.length > 0 ? transformed : editDriver?.driver_types;
        setCurrUpdatedTypes(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationActions, editDriver]);

    return (
        <ODDrawer
            disableOutsideClickClose={true}
            open={newDomicileSlideout && slideout?.editCurrDriver}
            title={'Edit'}
            anchor="right"
            onClose={handleClose}
            enableCustomizedButton={true}
            children={
                <>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
                            borderRadius: '4px',
                            padding: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 600
                        }}>
                        <LocationOnIcon sx={{ marginRight: '16px' }} />
                        <LocationsInfo
                            value={
                                editDriver?.domicile_id === 'all' && aggregationLevel === 0
                                    ? 'All'
                                    : editDriver?.domicile_id
                            }
                        />
                    </Box>

                    <Box
                        sx={{
                            marginTop: '16px'
                        }}>
                        {currUpdatedTypes?.map((type: any, i: number) => {
                            return (
                                <DriverTypeAccordion
                                    updateTotalForCurrTypes={updateTotalForCurrTypes}
                                    key={i}
                                    type={type}
                                    driverTypesColors={driverTypesColors}
                                />
                            );
                        })}
                    </Box>
                    <Divider sx={{ color: theme.palette.neutral.neutral2, margin: '16px 0' }} />

                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`min-load-count-accordion`}
                        expanded={openDrawer?.addDriverTypes}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            setOpenDrawer({
                                ...openDrawer,
                                addDriverTypes: !openDrawer?.addDriverTypes
                            });
                        }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
                                borderRadius: '4px',
                                marginTop: '2px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Box sx={{ display: 'flex', color: theme.palette.black }}>
                                <Box
                                    sx={{
                                        marginRight: '16px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <PersonAddAlt1Icon />
                                </Box>

                                <Typography
                                    style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}>
                                    Add Driver Types
                                </Typography>
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={openDrawer?.addDriverTypes}
                        timeout="auto"
                        unmountOnExit
                        key={`select-min-load-count-collapse`}>
                        <Box sx={{ marginTop: '16px' }}>
                            <ODMultiSelectCore
                                fieldName="drivers"
                                label={'Select Driver Type'}
                                options={filteredNewDriverTypes}
                                handleChange={(e) => handleOnChangeDriverType(e)}
                                clearSelectedOptions={() => setSelectedTypes([])}
                                selectedOptions={selectedDriverTypeOptions}
                                getObjectValueFromId={(option) => option}
                            />
                        </Box>
                        <Box
                            sx={{
                                marginTop: '16px'
                            }}>
                            {selectedTypes?.map((type: any, i: number) => {
                                const totalAmount =
                                    getSelectedTypeTotalCount(type?.id) || type?.total;

                                return (
                                    <AddDriverTypeAccordion
                                        currTypeName={type?.name}
                                        key={i}
                                        total={totalAmount}
                                        type={type}
                                        updateTotal={updateTotal}
                                    />
                                );
                            })}
                        </Box>
                    </Collapse>

                    <Box
                        display="flex"
                        justifyContent={'space-between'}
                        alignItems="center"
                        sx={{
                            marginTop: '16px',
                            flexDirection: 'column'
                        }}>
                        <ODButton
                            buttonType={BUTTON_TYPE.BLUE}
                            type="submit"
                            width="392px"
                            buttonText={'Save'}
                            onClick={() => handleSubmit()}
                        />
                        <ODButton
                            buttonText="Cancel"
                            width="392px"
                            sx={{ marginTop: '8px' }}
                            onClick={handleClose}
                        />
                    </Box>
                </>
            }
        />
    );
};

export default memo(EditDriverSlideout);
