/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import theme from 'theme';
import {} from 'utils/hooks';
import { useSelector } from 'react-redux';
import NetworkTablesSelector from 'components/NetworkView/NetworkTable/NetworkTablesSelector';

const SidePanel = ({ currReport }) => {
    const { expandPanel, expandTopNavbar } = useSelector((state) => state.NetworkReducer);

    return (
        <Box
            sx={{
                borderRadius: '4px',
                backgroundColor: ' #FDFDFD',
                position: 'absolute',
                top: !expandTopNavbar ? '4.6rem' : 'inherit',
                right: '56px',
                width: '695px',
                maxHeight: 'calc(100% - 5rem)',
                overflow: 'hidden',
                color: theme.palette.black,
                zIndex: 100,
                margin: '16px'
            }}>
            <Collapse in={expandPanel}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.white,
                        borderRadius: '4px'
                    }}>
                    <NetworkTablesSelector currReport={currReport} />
                </Box>
            </Collapse>
        </Box>
    );
};

export default SidePanel;
