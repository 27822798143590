import { createColumnHelper } from '@tanstack/react-table';
import { Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import ReportStatusInfo from 'components/NetworkManager/ReportStatusInfo';
import { UnspecifiedDataPlaceholder } from 'shared/ODTable/ODTableStyledComponents';

const ellipsisStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
};

const StyledSpan = styled('span')({
    textDecoration: 'underline',
    ...ellipsisStyle
});

const columnHelper = createColumnHelper();

const associatedReportsCol = [
    columnHelper.accessor('id', {
        id: 'id',
        header: () => 'id',
        cell: (info) => info.getValue()
    }),
    columnHelper.accessor('report_name', {
        id: 'report_name',
        header: () => 'Report Name',
        cell: (info) =>
            info.getValue() ? (
                <Tooltip disableInteractive title={info.getValue()}>
                    <StyledSpan>{info.getValue()}</StyledSpan>
                </Tooltip>
            ) : (
                <UnspecifiedDataPlaceholder />
            ),
        size: 105,
        meta: { visibleByDefault: true, name: 'Report Name' }
    }),
    columnHelper.accessor((row) => row.bucket.bucket_name, {
        id: 'group',
        header: () => 'Group',
        cell: (info) =>
            info.getValue() ? (
                <Tooltip disableInteractive title={info.getValue()}>
                    <span style={ellipsisStyle}>{info.getValue()}</span>
                </Tooltip>
            ) : (
                <UnspecifiedDataPlaceholder />
            ),
        footer: (info) => info.column.id,
        size: 105,
        meta: { visibleByDefault: true, name: 'Group' }
    }),
    columnHelper.accessor('status', {
        id: 'status',
        header: () => 'Status',
        cell: (info) =>
            info.getValue() ? (
                <span>{<ReportStatusInfo status={info.getValue()} darkIcon={true} />}</span>
            ) : (
                <UnspecifiedDataPlaceholder />
            ),
        footer: (info) => info.column.id,
        size: 105,
        meta: { visibleByDefault: true, name: 'Status' }
    })
];

export default associatedReportsCol;
