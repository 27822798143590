import React from 'react';
import { Box } from '@mui/material';
import { Close, LocalShipping, SubdirectoryArrowRight } from '@mui/icons-material';
import { AnyObject } from 'yup/lib/types';
import palette from 'theme/palette';

import { ODCard, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import StarsIcon from '@mui/icons-material/Stars';
import { StyledPaper, TextContent } from './HistorySlideout.styles';
import { formatZipcodeToCityState } from 'utils/common.util';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

interface historyHandleBonusProps {
    key: string;
    type: string;
    handleRestore: (e: any, data: AnyObject) => any;
    id: string;
    value: any;
    readOnly?: boolean;
}

const HistoryHandleBonusCard = ({
    id,
    key,
    value,
    handleRestore,
    readOnly
}: historyHandleBonusProps) => {
    let isShipper = value?.shipper;

    const { locationVisualization } = useSelector((state: RootState) => state.settingsReducer);
    const { reportLocations } = useSelector((state: RootState) => state.ReportKPIReducer);
    const { bidConfigLocations } = useSelector((state: RootState) => state.BiddingReducer);

    return (
        <StyledPaper readOnly={readOnly} key={key}>
            <ODCard sx={{ justifyContent: 'space-between' }}>
                <Box display={'flex'} alignItems={'center'}>
                    <LocalShipping />
                    <TextContent id={`shipper-id-${isShipper}`}>
                        <b style={{ marginRight: '4px' }}>Shipper ID:</b> {isShipper}
                    </TextContent>
                </Box>

                {!readOnly && (
                    <ODIconButton
                        disableHover={true}
                        sx={{ padding: 0 }}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        icon={<Close sx={{ color: palette.neutral.black }} />}
                        onClick={(e) => handleRestore(e, { id, ...value })}
                    />
                )}
            </ODCard>

            {value?.lane && (
                <ODCard>
                    <Box display={'flex'} alignItems={'center'}>
                        <SubdirectoryArrowRight />
                        <TextContent id={`lane-id-${value?.lane}`}>
                            <b style={{ marginRight: '4px' }}>Lane ID:</b>
                            {formatZipcodeToCityState(
                                value?.lane,
                                reportLocations,
                                bidConfigLocations,
                                locationVisualization
                            )}
                        </TextContent>
                    </Box>
                </ODCard>
            )}

            <ODCard>
                <Box display={'flex'} alignItems={'center'}>
                    <StarsIcon />
                    <Box>
                        <TextContent id={`bonus-id-${value?.value}-for-${id}`}>
                            <b style={{ marginRight: '4px' }}>Freight Bonus:</b>
                            {value?.value ? 'Applied' : 'Removed'}
                        </TextContent>
                    </Box>
                </Box>
            </ODCard>
        </StyledPaper>
    );
};

export default HistoryHandleBonusCard;
