/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useAlerts } from './Alerts/AlertsContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNetworkViewHook } from 'utils/hooks';
import { useNotificationHook } from 'utils/hooks/notificationHooks';
import { RootState } from 'redux/reducers';
import theme from 'theme';
import { updateNetworkView } from 'redux/actions';
import { resetDriverChecked } from 'utils/drivers.util';
import useNetworkViewEffects from 'utils/hooks/useNetworkViewEffects';
/* constants */
import { NETWORK_TABLE_TYPE } from 'constants/network';
import { LOAD_TYPES } from 'constants/shippers';
/* outer layout components */
import NetworkTopNavbar from './NetworkTopNavbar';
import RightNavbar from '../Layout/RightNavbar';
import TopNavbar from 'components/NetworkView/TopNavbar';
import AlertsContainer from './Alerts/AlertsContainer';
import ODLoader from 'shared/ODLoader';
import NetworkViewBreadcrumbs from './NetworkBreadcrumbs';
/* modals */
import ParametersModal from 'components/Modals/ParametersModal';
import DownloadReportModal from 'components/Modals/DownloadReportModal';
import FreightBonusModal from 'components/Modals/FreightBonusModal';
import RunReportConfirmationModal from './RunReportConfirmationModal';
import SaveDraftReportModal from 'components/Modals/SaveDraftReportModal';
import { AlertModal } from './AlertModal';
import CompletedModal from 'components/Modals/CompletedModal';
/* slideouts */
import AssociatedReportsSlideout from 'components/Slideouts/AssociatedReportsSlideout';
import EditShipperLaneSlideout from './Shipper/EditShipperLaneSlideout';
import DriverDetailSlideOut from './Driver/DriverDetailSlideOut';
import ReportSelection from 'components/NetworkView/ReportSelection';
import HistorySlideout from 'components/Slideouts/HistorySlideout';
import AddNewDriverSlideout from 'components/Slideouts/DriverSlideouts/AddNewDriverSlideout';
import EditDriverSlideout from 'components/Slideouts/DriverSlideouts/EditDriverSlideout';
/* components */
import ListView from './ListView';
import MapView from './MapView/MapView';

const NetworkView = () => {
    const { freightBonus } = useFlags();
    const dispatch = useDispatch();
    const {
        useGetFlowDataEffect,
        useResetWhenUnmountStateEffect,
        useRemoveAggAlertStateEffect,
        useResetShipperLaneStateEffect,
        useGetActionsByConfigIdEffect,
        useLayoutResetDriverStateEffect,
        useUpdateKPIForDriverEffect,
        useLayoutGetDomicilesByIdEffect,
        useSetReportsStateEffect,
        useInitialEffect,
        useUpdateCurrentSimulationStateEffect,
        useResetDriverStateEffect,
        useLayoutGetLocationByReportIdEffect,
        useGetReportsOrDriverTypesById,
        useUpdateOutdatedBidEffect,
        useLayoutGetTableDataByIdAndFilterEffect,
        useGetAppliedBidsByConfigIdEffect,
        useLayoutResetTableDataPreserveEffect,
        useUpdateKPIForShipperLaneEffect,
        useRefreshTableOnlyEffect
    } = useNetworkViewEffects();
    const {
        currReport,
        currReportId,
        closeAddNewDomicileSlideout,
        closeEditDriverSlideout,
        closeAlertModal
    } = useNetworkViewHook();
    useNotificationHook();
    const alertsContext = useAlerts() as any;
    const [action, setAction] = useState('');

    /*states*/
    const { configuration, configurationActions, prevConfigurationActions, openManageSimulation } =
        useSelector((state: RootState) => state.ConfigurationReducer);
    const { checked, reportDomiciles } = useSelector((state: RootState) => state.DriversReducer);
    const { aggregationLevel, maxAggregationLevel } = useSelector(
        ({ settingsReducer }: RootState) => settingsReducer
    );
    const { reportFilters } = useSelector((state: RootState) => state.ReportsReducers);
    const [loadingStart, setLoadingStart] = useState(true);

    const {
        isListView,
        baseReport,
        compareReport,
        currTab,
        enableEditRow,
        isRunSimulation,
        appliedBidFiles,
        showAlertModal,
        showDriveMoveDetails,
        showFreightBonusModal,
        showHistorySlideout,
        loadingFlowData,
        loadingActions,
        loadingResetDriverState,
        loadingKPIForDriver,
        loadingGetDomicilesById,
        loadingFetchingMapboxKey,
        loadingFetchingReports,
        loadingGetLocationsByReportId,
        loadingGetReportsByBucketOrId,
        loadingGetDriverTypesById,
        loadingGetReportByReportId,
        loadingGetTableDataByIdAndFilters,
        loadingGetReportAppliedBids,
        fetchingKPIbyReportId,
        loadingGetAllGroups,
        loadingGetDrafts,
        loadingGetAllReports,
        loadingGetArchived,
        fetchingBuckets,
        loadingGetBidGroups
    } = useSelector((state: RootState) => state.NetworkReducer);

    const isShipperTab = useMemo(() => currTab === NETWORK_TABLE_TYPE.SHIPPERS, []);
    const isAggregated = useMemo(() => aggregationLevel !== maxAggregationLevel, []);
    const load_type = useMemo(() => LOAD_TYPES.fleet, []);
    const { shippers, lanes } = useMemo(
        () => reportFilters,
        [reportFilters?.shippers, reportFilters?.lanes]
    );
    const resetDriverUseRef = useRef(true);
    const getDomicilesByIdUseRef = useRef(true);
    const getTableDataByIdAndFilterUseRef = useRef(true);
    const resetTableDataPreserveUseRef = useRef(true);

    useEffect(() => {
        setLoadingStart(false);
    }, []);

    const areResponseeInProgress = [
        loadingStart,
        loadingFlowData,
        loadingActions,
        loadingResetDriverState,
        loadingKPIForDriver,
        loadingGetDomicilesById,
        loadingFetchingMapboxKey,
        loadingFetchingReports,
        loadingGetLocationsByReportId,
        loadingGetReportsByBucketOrId,
        loadingGetDriverTypesById,
        loadingGetReportByReportId,
        loadingGetTableDataByIdAndFilters,
        loadingGetReportAppliedBids,
        fetchingKPIbyReportId,
        loadingGetAllGroups,
        loadingGetDrafts,
        loadingGetAllReports,
        loadingGetArchived,
        fetchingBuckets,
        loadingGetBidGroups
    ].some((value) => value);

    //! endpoints
    useGetFlowDataEffect(lanes, shippers, checked, load_type);
    useGetActionsByConfigIdEffect();
    useLayoutResetDriverStateEffect(resetDriverUseRef);
    useUpdateKPIForDriverEffect(currTab);
    useLayoutGetDomicilesByIdEffect(getDomicilesByIdUseRef);
    useSetReportsStateEffect(setAction);
    useInitialEffect(baseReport, alertsContext); //set filters endpoint commented out
    useLayoutGetLocationByReportIdEffect();
    useGetReportsOrDriverTypesById();
    useLayoutGetTableDataByIdAndFilterEffect(getTableDataByIdAndFilterUseRef);
    useGetAppliedBidsByConfigIdEffect(baseReport);
    useLayoutResetTableDataPreserveEffect(resetTableDataPreserveUseRef, baseReport, compareReport);
    useUpdateKPIForShipperLaneEffect(); //remove reportFilters dep
    useRefreshTableOnlyEffect();

    //! states
    useRemoveAggAlertStateEffect(isAggregated, alertsContext);
    useResetShipperLaneStateEffect(baseReport, isShipperTab);
    useUpdateCurrentSimulationStateEffect();
    useResetDriverStateEffect(resetDriverChecked, currTab); //remove base_run dep //combined with  useResetAllStateEffect
    useUpdateOutdatedBidEffect(baseReport, compareReport, alertsContext);

    //! leaving the page
    useResetWhenUnmountStateEffect();

    return (
        <Stack
            sx={{
                height: '100vh',
                backgroundColor: theme.palette.white
            }}>
            <ODLoader open={areResponseeInProgress} text="Loading..." />
            {showAlertModal && <AlertModal onAction={closeAlertModal} />}
            {freightBonus && showFreightBonusModal && <FreightBonusModal />}
            {/* //todo add the logic back when design is to add the modal
            {showCompletedReportModal && <CompletedReportSimulationModal />} */}
            {openManageSimulation && <AssociatedReportsSlideout />}
            {isRunSimulation && (
                <RunReportConfirmationModal
                    appliedBidFiles={appliedBidFiles}
                    isBidApplied={baseReport?.is_bid_file_outdated}
                    currReport={currReport}
                />
            )}
            <CompletedModal />
            <SaveDraftReportModal />
            <EditShipperLaneSlideout open={enableEditRow} />
            {showDriveMoveDetails?.show && <DriverDetailSlideOut />}
            <Box marginRight="56px">
                <NetworkViewBreadcrumbs />
                <NetworkTopNavbar setAction={setAction} />
                <TopNavbar setAction={setAction} />
                {isListView ? <ListView /> : <MapView />}
            </Box>
            <HistorySlideout
                show={showHistorySlideout}
                onClose={() => dispatch(updateNetworkView({ showHistorySlideout: false }))}
                configuration={{
                    ...configuration,
                    actions: configurationActions,
                    prevActions: prevConfigurationActions
                }}
                domiciles={reportDomiciles}
                selectedRunType={currReportId as any}
                networkManagerView={true}
            />
            <DownloadReportModal />
            <ParametersModal />
            <ReportSelection
                setAction={setAction}
                show={['SelectReport', 'CompareReport'].includes(action)}
            />
            <AddNewDriverSlideout onClose={closeAddNewDomicileSlideout} />
            <EditDriverSlideout onClose={closeEditDriverSlideout} />
            <RightNavbar networkManagerView={true} />
            <AlertsContainer alerts={alertsContext?.alerts} />
        </Stack>
    );
};

export default NetworkView;
