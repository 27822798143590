import React from 'react';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import theme from 'theme';
import { makeStyles } from '@mui/styles/';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from '@mui/icons-material/History';
import { REPORT_STATUS } from 'constants/network';

export const ShowAdvisories = ({
    status = REPORT_STATUS.COMPLETED,
    onEdit,
    id = '',
    onDelete,
    onHistory = null,
    advisories,
    styles = {},
    toggleAdvisories,
    latestBaseReportId,
    disabled
}) => {
    const useStyles = makeStyles((theme) => ({
        iconsGroup: {
            display: 'flex',
            '& > *': {
                margin: '4px'
            }
        },
        iconSlideActions: {
            borderRadius: '4px',
            width: '32px',
            height: '32px',
            backgroundColor: theme.palette.neutral.neutral1,
            '&:hover': {
                backgroundColor: theme.palette.neutral.neutral2
            }
        },
        iconActions: {
            borderRadius: '4px',
            width: '32px',
            height: '32px',
            marginRight: 16,
            backgroundColor: theme.palette.neutral.neutral1,
            '&:hover': {
                backgroundColor: theme.palette.neutral.neutral1
            }
        }
    }));
    const classes = useStyles();

    if (advisories)
        return (
            <div
                className={classes.iconsGroup}
                style={{
                    float: 'right',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: disabled ? theme.palette.neutral.neutral6 : theme.palette.black,
                    ...styles
                }}>
                {[REPORT_STATUS.COMPLETED, REPORT_STATUS.VFA_COMPLETED].includes(status) &&
                    onHistory && (
                        <Avatar
                            className={classes.iconSlideActions}
                            onClick={() => {
                                onHistory();
                                toggleAdvisories(false);
                            }}>
                            <IconButton color="inherit" size="large">
                                <HistoryIcon
                                    style={{
                                        height: 20,
                                        color: theme.palette.black
                                    }}
                                />
                            </IconButton>
                        </Avatar>
                    )}
                {latestBaseReportId !== id && (
                    <Avatar
                        id={`${id}-delete-button`}
                        className={classes.iconSlideActions}
                        onClick={() => {
                            onDelete();
                            toggleAdvisories(false);
                        }}>
                        <IconButton color="inherit" size="large">
                            <DeleteSharpIcon
                                sx={{
                                    color: theme.palette.black
                                }}
                            />
                        </IconButton>
                    </Avatar>
                )}
                <Avatar
                    id={`${id}-edit-button`}
                    className={classes.iconSlideActions}
                    onClick={() => {
                        onEdit();
                        toggleAdvisories(false);
                    }}>
                    <IconButton color="inherit" size="large">
                        <EditSharpIcon
                            sx={{
                                color: theme.palette.black
                            }}
                        />
                    </IconButton>
                </Avatar>
                {status === 'failed' && (
                    <Avatar
                        className={classes.iconSlideActions}
                        onClick={() => {
                            toggleAdvisories(false);
                        }}
                        style={{ backgroundColor: theme.palette.semantic.semanticRed }}>
                        <IconButton color="inherit" size="large">
                            <VisibilitySharpIcon style={{ color: theme.palette.neutral.white }} />
                        </IconButton>
                    </Avatar>
                )}
                <Avatar
                    className={classes.iconActions}
                    onClick={() => toggleAdvisories(false)}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: theme.palette.ODBlue.odBlue8,
                        color: theme.palette.neutral.black
                    }}>
                    <IconButton color="inherit" size="large">
                        <ClearIcon
                            sx={{
                                color: theme.palette.white
                            }}
                        />
                    </IconButton>
                </Avatar>
            </div>
        );

    return (
        <div
            className={classes.iconsGroup}
            style={{ float: 'right', position: 'absolute', top: 0, right: 0 }}>
            <Avatar
                className={classes.iconActions}
                onClick={() => !disabled && toggleAdvisories(true)}
                style={{
                    backgroundColor:
                        status === 'failed' ? theme.palette.semantic.semanticRed : 'transparent'
                }}>
                <Tooltip title="Advisories">
                    <IconButton color="inherit" size="large">
                        <MoreVertIcon
                            sx={{
                                color:
                                    status === 'failed'
                                        ? theme.palette.white
                                        : disabled
                                        ? theme.palette.neutral.neutral6
                                        : theme.palette.black
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Avatar>
        </div>
    );
};
