import {
    CONFIGURATIONS_START,
    CONFIGURATIONS_SUCCESS,
    CONFIGURATIONS_FAIL,
    CONFIGURATIONS_CLEAR,
    SET_CONFIGURATION_SUCCESS,
    CONFIGURATION_ACTION_SUCCESS,
    SET_INITIAL_ACTION_SUCCESS,
    UPDATE_CONFIGURATION_STATE
} from '../constants/actionTypes';

const initialState = {
    creatingConfiguration: false,
    configurations: [],
    error: null,
    loading: false,
    configuration: {},
    configurationActions: [],
    prevConfigurationActions: [],
    openManageSimulation: false,
    openCreateSimulation: false,
    showSelectSimulationTopAlert: true,
    appliedBidFilesIds: [],
    allBidFiles: []
};

const configurationStart = (state, action) => {
    return { ...state, error: null, loading: true };
};

const configurationSuccess = (state, action, type) => {
    return {
        ...state,
        configurations: action.configurations,
        loading: false,
        error: null
    };
};

const configurationFail = (state, action) => {
    return {
        ...state,
        configurations: [],
        loading: false,
        error: action.error
    };
};

const configurationClear = (state, action) => {
    return { ...state, ...initialState };
};

const setConfigurationSuccess = (state, action) => {
    return { ...state, ...action, creatingConfiguration: false };
};

const updateConfigurationState = (state, action) => {
    return {
        ...state,
        ...action.payload
    };
};

const configurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIGURATIONS_START:
            return configurationStart(state, action);
        case CONFIGURATIONS_SUCCESS:
            return configurationSuccess(state, action, action.type);
        case CONFIGURATIONS_FAIL:
            return configurationFail(state, action);
        case CONFIGURATIONS_CLEAR:
            return configurationClear(state, action);
        case SET_CONFIGURATION_SUCCESS:
            return setConfigurationSuccess(state, action);
        case CONFIGURATION_ACTION_SUCCESS:
            return setConfigurationSuccess(state, action);
        case SET_INITIAL_ACTION_SUCCESS:
            return setConfigurationSuccess(state, action);
        case UPDATE_CONFIGURATION_STATE:
            return updateConfigurationState(state, action);
        default:
            return state;
    }
};

export default configurationReducer;
