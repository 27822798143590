import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Grid, Avatar, Tooltip, Box, Typography, Stack } from '@mui/material';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import theme from 'theme';
import { BucketItems } from './BucketItems';
import { HANDLE_GROUP_SLIDEOUT } from 'constants/reports';
import { ODButton } from 'shared';
import HistoryIcon from '@mui/icons-material/History';
import { useDispatch, useSelector } from 'react-redux';
import { updateNetworkView } from 'redux/actions';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import { BUTTON_TYPE } from 'constants/colors';

export const GroupDetails = ({
    filteredReports,
    buckets,
    onRowChecked,
    onAction,
    editBucket,
    getBuckets
}) => {
    const dispatch = useDispatch();
    const { odpt3963Network, historicalBaseFiles } = useFlags();
    const { tempOpenHistory } = useSelector((state) => state.NetworkReducer);
    const selectedBuckets = buckets.reduce((store, { checked, id }) => {
        checked && store.push(id);
        return store;
    }, []);
    const isAllBucketsChecked = buckets.length === selectedBuckets.length;
    const selectedRecords = filteredReports.filter(({ isChecked }) => isChecked);
    const hasSelectedRows = selectedRecords.length > 0;
    const TopHeaderIcon = odpt3963Network ? FolderSharpIcon : ListAltSharpIcon;

    return (
        <Grid
            item
            xs={2}
            sx={{
                borderRight: `1px solid ${theme.palette.neutral.neutral1}`
            }}>
            <Box margin="16px">
                <Box
                    sx={{
                        padding: '0px 16px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                        backgroundColor: theme.palette.neutral.neutral1,
                        borderRadius: '4px'
                    }}>
                    <Box display="flex" alignItems="center">
                        <TopHeaderIcon
                            sx={{
                                color: theme.palette.black,
                                marginRight: '16px'
                            }}
                        />
                        <Typography
                            sx={{
                                color: theme.palette.black,
                                fontSize: '14px',
                                fontWeight: 600
                            }}>
                            {odpt3963Network ? 'Add Folders' : 'Groups'}
                        </Typography>
                    </Box>

                    <Avatar
                        sx={{
                            borderRadius: '4px',
                            cursor: 'pointer',
                            backgroundColor: 'transparent'
                        }}
                        onClick={() => onAction(HANDLE_GROUP_SLIDEOUT.ADD_NEW_BUCKET)}>
                        <Tooltip title="Add new">
                            <AddSharpIcon
                                sx={{
                                    color: theme.palette.black
                                }}
                            />
                        </Tooltip>
                    </Avatar>
                </Box>
            </Box>

            <>
                <div style={{ padding: '0px 16px' }}>
                    {buckets.length > 0 && (
                        <BucketItems
                            bucket={{
                                id: 'all',
                                name: 'All Reports',
                                checked: isAllBucketsChecked
                            }}
                            key={'all'}
                            onRowChecked={onRowChecked}
                        />
                    )}
                </div>
                <div
                    style={{
                        overflow: 'auto',
                        padding: '0px 16px',
                        maxHeight: `calc(100vh - ${310 + (hasSelectedRows ? 35 : 0)}px)`,
                        overflowY: 'auto'
                    }}>
                    {buckets.map((bucket, index) => (
                        <BucketItems
                            bucket={bucket}
                            index={index}
                            key={index}
                            isAllChecked={isAllBucketsChecked}
                            showAdvisories={true}
                            editBucket={editBucket}
                            onRowChecked={onRowChecked}
                            getBuckets={getBuckets}
                        />
                    ))}
                </div>
            </>

            {historicalBaseFiles && (
                <Box borderRadius="4px" margin="16px">
                    <ODButton
                        buttonType={tempOpenHistory ? BUTTON_TYPE?.DARK : BUTTON_TYPE?.LIGHT}
                        onClick={() =>
                            dispatch(updateNetworkView({ tempOpenHistory: !tempOpenHistory }))
                        }
                        sx={{ width: '100%' }}
                        buttonText={
                            <Stack flexDirection="row" justifyContent="center" alignItems="center">
                                <HistoryIcon sx={{ marginRight: '16px' }} />
                                <Typography fontWeight={600}>Historical Files</Typography>
                            </Stack>
                        }
                    />
                </Box>
            )}
        </Grid>
    );
};
