import React from 'react';
import CustomFlowMap from './CustomFlowMap';
import * as d3 from 'd3-scale-chromatic';
import theme from 'theme';

const tooltipStyle = {
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 1,
    background: 'rgb(51, 45, 60)',
    color: '#fff',
    fontSize: 9,
    borderRadius: 4,
    padding: 5,
    maxWidth: 300,
    maxHeight: 300,
    overflow: 'hidden'
};

export default class ReportFlowMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltip: undefined
        };
        this.initialViewState = {
            longitude: -84.148715,
            latitude: 37.677939,
            zoom: 3
        };
        this.handleViewStateChange = this.handleViewStateChange.bind(this);
        this.handleHighlight = this.handleHighlight.bind(this);
    }

    handleViewStateChange(viewState) {
        const { onViewStateChange } = this.props;
        if (onViewStateChange) {
            onViewStateChange(viewState);
        }
        const { tooltip } = this.state;
        if (tooltip) {
            this.setState({ tooltip: undefined });
        }
    }

    handleHighlight(info = undefined) {
        if (!info) {
            this.setState({ tooltip: undefined });
        }
        this.setState({
            tooltip: info
        });
    }

    renderTooltip() {
        const { tooltip } = this.state;
        if (!tooltip) {
            return null;
        }
        const { object, x, y } = tooltip;
        if (!object) {
            return null;
        }
        return (
            <pre
                style={{
                    ...tooltipStyle,
                    left: x,
                    top: y
                }}>
                {JSON.stringify(object, null, 2)}
            </pre>
        );
    }

    getFlowColor(f) {
        if (f.count < 0) return '#ef162e';
        return null;
    }

    render() {
        const {
            flows,
            locations,
            getLocationId,
            getLocationCentroid,
            getFlowMagnitude,
            mapboxAccessToken,
            isAnimated,
            type,
            driversConfig,
            onSave,
            isAdmin
        } = this.props;

        const LIGHT_THEME_COLORS = {
            darkMode: false,
            flows: {
                //this uses gradients of color, so had to add it double times here.
                //note: didn't add it to theme as it is only being used in this mapbox.
                scheme:
                    this.props.loadType === 'rejected'
                        ? d3.schemeReds[d3.schemeReds.length - 1]
                        : ['#238E53', '#238E53', '#238E53', '#238E53']
            },
            locationAreas: {
                normal: theme.palette.black
            },
            outlineColor: theme.palette.semantic.semanticGreen
        };

        return (
            <>
                <CustomFlowMap
                    odpt4932ShowDriverIconMap={this.props.odpt4932ShowDriverIconMap}
                    isReportDraft={this.props.isReportDraft}
                    createSimulationOnEdit={this.props.createSimulationOnEdit}
                    colors={LIGHT_THEME_COLORS}
                    getFlowColor={this.getFlowColor}
                    pickable={true}
                    minPickableFlowThickness={20}
                    maxFlowThickness={5}
                    maxLocationCircleSize={5}
                    animate={isAnimated}
                    mapStyle={'mapbox://styles/edusilva0111/cl94nde05000j14nwxdjdgpvw'}
                    initialViewState={this.initialViewState}
                    showTotals={false}
                    showLocationAreas={false}
                    myflows={flows}
                    type={type}
                    isAdmin={isAdmin}
                    mylocations={locations}
                    mapboxAccessToken={mapboxAccessToken}
                    getLocationId={getLocationId}
                    getLocationCentroid={getLocationCentroid}
                    getFlowMagnitude={getFlowMagnitude}
                    getFlowOriginId={this.props.getFlowOriginId}
                    getFlowDestId={this.props.getFlowDestId}
                    onViewStateChange={this.handleViewStateChange}
                    markerData={this.props.markerData || []}
                    classes={this.props.classes}
                    onAddDomicile={this.props.onAddDomicile}
                    clearMap={this.props.clearMap}
                    otherLayers={this.props.otherLayers}
                    domicile_length={this.props.domicile_length}
                    driversConfig={driversConfig}
                    onSave={onSave}
                    isReadOnly={this.props.isReadOnly}
                    isAggregated={this.props.isAggregated}
                    showSidebar={this.props.showSidebar}
                    multiDriverToursToggle={this.props.multiDriverToursToggle}
                    toggleDriverTours={this.props.toggleDriverTours}
                    openEditDriverSlideout={this.props.openEditDriverSlideout}
                    newDomicileSlideout={this.props.newDomicileSlideout}
                />
                {this.renderTooltip()}
            </>
        );
    }
}
