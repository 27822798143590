import React from 'react';
import { FormControl, InputLabel, OutlinedInput, Box, InputAdornment } from '@mui/material';
import theme from 'theme';
import { Remove, Add } from '@mui/icons-material';
import MaskedInput from './MaskedInput';

const ParamsInput = ({
    label,
    driverType,
    htmlFor,
    disabled,
    onChange,
    updateValue,
    onRightSide,
    value,
    type,
    id
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '32px',
                width: '50%'
            }}>
            <InputLabel htmlFor={htmlFor} sx={{ fontSize: '12px', fontWeight: 400 }}>
                {label}
            </InputLabel>
            <FormControl>
                <OutlinedInput
                    type="number"
                    disableUnderline={true}
                    sx={{
                        minWidth: '426px',
                        height: '40px',
                        marginRight: onRightSide ? 0 : '32px',
                        '& .MuiFilledInput-root': {
                            border: 'none',
                            overflow: 'hidden',
                            borderRadius: 4,
                            backgroundColor: theme.palette.neutral.neutral1,
                            transition: theme.transitions.create([
                                'border-color',
                                'background-color',
                                'box-shadow'
                            ])
                        },

                        input: {
                            '&[type=number]': {
                                '-moz-appearance': 'textfield'
                            },
                            '&::-webkit-outer-spin-button': {
                                '-webkit-appearance': 'none',
                                margin: 0
                            },
                            '&::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                                margin: 0
                            }
                        }
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <Remove
                                disabled={disabled}
                                style={{ cursor: 'pointer' }}
                                onClick={() => !disabled && updateValue(driverType, id, 'subtract')}
                            />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="start">
                            <Add
                                disabled={disabled}
                                style={{ cursor: 'pointer' }}
                                onClick={() => !disabled && updateValue(driverType, id, 'add')}
                            />
                        </InputAdornment>
                    }
                    onChange={(value) => {
                        if (/^\d+(\.\d{0,2})?$/.test(value?.value)) {
                            !disabled && onChange(value?.value, driverType, id);
                        }
                    }}
                    id={type}
                    inputComponent={MaskedInput}
                    value={value}
                    inputProps={{
                        maxLength: 5,
                        step: '1',
                        style: {
                            padding: '10px',
                            textAlign: 'center'
                        }
                    }}
                    disabled={disabled}
                />
            </FormControl>
        </Box>
    );
};
export default ParamsInput;
