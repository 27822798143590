import React from 'react';
import { Box } from '@mui/material';
import { LocalShipping, Close } from '@mui/icons-material';
import { AnyObject } from 'yup/lib/types';
import palette from 'theme/palette';
import { ODCard, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { DriverLanesIcon } from 'assets/images';
import { StyledPaper } from './HistorySlideout.styles';
import theme from 'theme';

interface historyHandleRemoveCardProps {
    key: string;
    handleRestore: (e: any, data: AnyObject) => any;
    id: string;
    value: { shipper: string; lane: string };
    readOnly?: boolean;
}
const HistoryHandleRemoveCard = ({
    id,
    key,
    value,
    handleRestore,
    readOnly
}: historyHandleRemoveCardProps) => {
    return (
        <StyledPaper readOnly={readOnly} key={key}>
            <ODCard sx={{ justifyContent: 'space-between' }}>
                <Box display={'flex'}>
                    {value.shipper ? (
                        <LocalShipping />
                    ) : (
                        <DriverLanesIcon
                            style={{
                                margin: '4px 6px 0 0',
                                fill: theme.palette.black
                            }}
                        />
                    )}

                    <Box sx={{ display: 'flex', marginLeft: '16px' }}>
                        <b style={{ marginRight: '4px' }}>
                            {`${value.shipper ? 'Shipper' : 'Lane'} ID: `}
                        </b>
                        <span style={{ textDecoration: 'line-through' }}>
                            {value.shipper || value.lane}
                        </span>
                    </Box>
                </Box>

                {!readOnly && (
                    <ODIconButton
                        disableHover={true}
                        sx={{ padding: 0 }}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        icon={<Close sx={{ color: palette.neutral.black }} />}
                        onClick={(e) => handleRestore(e, { id, ...value })}
                    />
                )}
            </ODCard>
        </StyledPaper>
    );
};

export default HistoryHandleRemoveCard;
