import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useParamsProps } from 'types/hooks';
import { useParams } from 'react-router-dom';
import { DataRow, CellEdit, CellError } from 'shared/ODTable/ODTableTypes';
import API from 'utils/axios';
import {
    updateBiddingState,
    getAllBidFiles,
    updateBidConfigTableErrors,
    addBidConfigTableErrors
} from 'redux/actions';
import { convertDateTimeObjIntoUSFormatWithDash } from 'utils/date.util';
import { getModifiedPartialCopy } from 'shared/ODTable/ODTableUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ColumnFilter, ColumnSort, Table } from '@tanstack/react-table';
import { produce, Draft } from 'immer';
import { LOCATION_VIEW, PREFERRED_RATE_TYPE } from 'constants/settings';
import { processLocationData } from 'utils/common.util';
import { getBaseAndNetworkReports } from 'redux/actions/biddingActions';
import { isEmpty } from 'lodash';
import { BUSINESS_TYPE } from 'constants/bidding';
interface BidConfigHookProps {
    bidConfigId?: number;
}

const useBidConfigHook = ({ bidConfigId }: BidConfigHookProps) => {
    const dispatch = useDispatch();
    const {
        odpt407934Zip,
        odpt4185BidConfigUpdate,
        flatRates,
        odpt2646BidConfigPaginationWithApi
    } = useFlags();
    const { bid_config_id }: useParamsProps = useParams();
    const {
        bidConfigColumnFormatSelections,
        bidConfigSetting
        //Todo: To use for unsaved changes in history slideout
        // actionsAwaitingConfigurationCreation,
    } = useSelector((state: RootState) => state.BiddingReducer);
    const [loading, setLoader] = useState(false);
    const [lanes, setLanes] = useState<DataRow[]>([]);
    const [allLanes, setAllLanes] = useState<DataRow[]>([]);
    const [editedLanes, setEditedLanes] = useState<any[]>([]);
    const [rowsToDelete, setRowsToDelete] = useState<number[]>([]);
    const [checkedRows, setCheckedRows] = useState<number[]>([]);
    const [configId, setConfigId] = useState(odpt4185BidConfigUpdate ? bid_config_id : bidConfigId);
    const [previouslyDeleted, setPreviouslyDeleted] = useState<number[]>([]);
    const [configInfo, setConfigInfo] = useState({
        fileName: null,
        shipperId: null,
        businessType: null,
        referenceShipperId: null,
        defaultRateType: PREFERRED_RATE_TYPE.PER_MILE
    });
    const [pageIndexInHook, setPageIndexInHook] = useState(0);
    const [pageSizeInHook, setPageSizeInHook] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const initializeDeletedLanes = (lanes: DataRow[]) => {
        const previouslyDeleted: number[] = [];
        lanes.forEach((lane: DataRow) => {
            if (lane.deleted_at) {
                previouslyDeleted.push(Number(lane.id));
            }
        });
        setRowsToDelete(previouslyDeleted);
        setPreviouslyDeleted(previouslyDeleted);
    };

    const processDataPostFetch = async (lanes: DataRow[]) => {
        let locations: any = [];
        if (
            !isEmpty(lanes) &&
            !lanes?.[0]?.origin_city &&
            !lanes?.[0]?.origin_state &&
            !lanes?.[0]?.destination_city &&
            !lanes?.[0]?.destination_state
        ) {
            const {
                data: { results }
            } = await API.get(`/bidding/configurations/${configId}/bid-locations/`);
            locations = [...results];
        }

        const numericLanes = [
            'load_volume',
            'mileage',
            'other_revenue',
            'other_revenue_per_mile',
            'historic_rate',
            'market_rate',
            'rate_per_mile'
        ];
        const formattedLanes = lanes.map((l: DataRow) => {
            let formattedLane = { ...l };
            numericLanes.forEach((id) => {
                formattedLane[id] = Number(formattedLane[id]);
            });
            formattedLane.flat_rate =
                Number(formattedLane.rate_per_mile) * Number(formattedLane.mileage);

            const formattedLocations = locations.reduce((acc: any, lane: any) => {
                acc[lane.origin_zip] = {
                    code: lane.origin_zip,
                    city: lane.origin_city,
                    state: lane.origin_state
                };
                acc[lane.destination_zip] = {
                    code: lane.destination_zip,
                    city: lane.destination_city,
                    state: lane.destination_state
                };
                return acc;
            }, {});

            processLocationData(formattedLane, bidConfigSetting, odpt407934Zip, formattedLocations);

            return formattedLane;
        });
        return formattedLanes;
    };
    const initializeErrors = (lanes: DataRow[]) => {
        const tableErrors: CellError[] = [];
        lanes.forEach((lane) => {
            if (Number(lane.rate_per_mile) === 0) {
                tableErrors.push(
                    { rowId: lane.id as number, columnId: 'rate_per_mile' },
                    { rowId: lane.id as number, columnId: 'flat_rate' }
                );
            }
        });
        dispatch(addBidConfigTableErrors(tableErrors));
    };
    const fetchBidLanes = useCallback(async () => {
        setLoader(true);
        try {
            const {
                data: { results }
            } = await API.get(`/bidding/configurations/${configId}/bid-lanes/`);

            const _lanes = await processDataPostFetch(results);
            setLanes(_lanes);
            initializeErrors(_lanes);
            initializeDeletedLanes(results);
        } catch (error) {
            console.warn('could not fetch lanes for bid config id ' + configId);
        } finally {
            setLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configId]);
    const fetchPaginatedBidLanes = useCallback(
        async () => {
            setLoader(true);
            try {
                if (
                    allLanes.length === 0 ||
                    allLanes.length < (pageIndexInHook + 1) * pageSizeInHook
                ) {
                    const {
                        data: { results, count }
                    } = await API.get(`/bidding/configurations/${configId}/bid-lanes/`, {
                        params: { limit: pageSizeInHook, offset: pageIndexInHook * pageSizeInHook }
                    });
                    if (totalItems === 0 && count) setTotalItems(count);
                    if (pageIndexInHook >= 0 && pageSizeInHook > 0) {
                        const _lanes = await processDataPostFetch(results);
                        setLanes(_lanes);
                        initializeErrors(_lanes);
                        initializeDeletedLanes(results);
                        setAllLanes((prevLanes) => [...prevLanes, ..._lanes]);
                    }
                } else {
                    const startIndex = pageIndexInHook * pageSizeInHook;
                    const endIndex = startIndex + pageSizeInHook;
                    setLanes(allLanes.slice(startIndex, endIndex));
                }
            } catch (error) {
                console.warn('could not fetch lanes for bid config id ' + configId);
            } finally {
                setLoader(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [configId, pageIndexInHook, pageSizeInHook]
    );

    useEffect(() => {
        const processData = async () => {
            setLoader(true);
            try {
                const _lanes = await processDataPostFetch(lanes);
                setLanes(_lanes);
                initializeErrors(_lanes);
            } catch (error) {
                console.warn('could not process data' + configId);
            } finally {
                setLoader(false);
            }
        };
        processData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bidConfigSetting]);

    const fetchBidFileData = useCallback(async () => {
        try {
            const { data } = await API.get(`/bidding/configurations/${configId}/`);
            if (data.detail) {
                console.warn('could not fetch bid config file data for bid config id ' + configId);
            } else {
                const {
                    file_name,
                    reference_shipper_id,
                    shipper_id,
                    business_type,
                    default_rate_type
                } = data;

                //! ideally we should handle this from API side (this logic is also in bidding Actions), but, we are following EM's suggestion for now: https://optimaldynamics.slack.com/archives/C04M5NLB9TQ/p1698424545968199
                const transformedBizType =
                    business_type === 'new' ? BUSINESS_TYPE.NEW : (BUSINESS_TYPE.EXISTING as any);

                setConfigInfo({
                    businessType: transformedBizType,
                    fileName: file_name,
                    shipperId: shipper_id,
                    referenceShipperId: reference_shipper_id,
                    defaultRateType: default_rate_type
                });
            }
        } catch (error) {
            console.warn('could not fetch bid file data for bid config id ' + configId);
        }
    }, [configId]);

    const updateBidLanes = async (lanesToUpdate: DataRow[]) => {
        try {
            const {
                data: { success, result }
            } = await API.put(`/bidding/configurations/${configId}/bid-lanes/`, {
                bid_lanes: lanesToUpdate
            });

            if (success) {
                const _lanes = await processDataPostFetch(result);
                setLanes(_lanes);
                initializeErrors(_lanes);
                initializeDeletedLanes(result);
            }
        } catch (e) {
            console.warn('Error updating bid file: ' + e);
        }
    };
    const restoreLanes = () => {
        setRowsToDelete([]);
        setCheckedRows([]);
    };
    const rateColumnRef = useRef<HTMLDivElement>(null);
    const openBidAnalysesSlideout = async () => {
        await dispatch(updateBiddingState({ openRunBidAnalysesSlideout: true }));
        if (rateColumnRef.current) {
            rateColumnRef.current.scrollLeft = rateColumnRef.current.scrollWidth;
        }
    };
    const closeBidAnalysesSlideout = async () => {
        await dispatch(updateBiddingState({ openRunBidAnalysesSlideout: false }));
        if (rateColumnRef.current) {
            rateColumnRef.current.scrollLeft = 0;
        }
    };
    const applyHistoricRatesCallback = () => {
        const cellEdits: CellEdit[] = [];
        lanes.forEach((lane: DataRow) => {
            if (checkedRows.includes(Number(lane.id)) && Number(lane.historic_rate) > 0)
                cellEdits.push({
                    rowId: lane.id as number,
                    columnId: 'rate_per_mile',
                    newValue: lane.historic_rate
                });
        });
        handleCellEdits(cellEdits);
    };

    useEffect(() => {
        if (odpt2646BidConfigPaginationWithApi) fetchPaginatedBidLanes().catch(console.error);
        else fetchBidLanes().catch(console.error);
        fetchBidFileData().catch(console.error);
        dispatch(updateBiddingState({ showTableSettings: false }));
        return () => {
            dispatch(
                updateBiddingState({ bidConfigTableDataErrors: [], showTableDataErrorAlert: false })
            );
        };
    }, [
        configId,
        fetchBidLanes,
        fetchPaginatedBidLanes,
        fetchBidFileData,
        dispatch,
        odpt2646BidConfigPaginationWithApi
    ]);

    useEffect(() => {
        if (odpt4185BidConfigUpdate) dispatch(getBaseAndNetworkReports());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configId]);

    // Reset Bid Config Slideout settings and locations data
    useEffect(() => {
        return () => {
            dispatch(
                updateBiddingState({
                    bidConfigSetting: {
                        ...bidConfigSetting,
                        time_aggregation: LOCATION_VIEW.CITY_STATE,
                        zip_code_aggregation: 5
                    },
                    bidConfigLocations: []
                })
            );
            closeBidAnalysesSlideout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const deleteBidLanes = async (configurationId = configId) => {
        try {
            const { data } = await API.delete(
                `/bidding/configurations/${configurationId}/bid-lanes/`,
                {
                    data: {
                        ids: rowsToDelete.filter((rowId) => !previouslyDeleted.includes(rowId))
                    }
                }
            );
            if (data.success) {
                await fetchBidLanes();
            }
        } catch (e) {
            console.warn('Error deleting bid lanes: ' + e);
        }
    };

    const saveBidLanes = async () => {
        const _lanes = produce(lanes, (_draft) => {});

        if (rowsToDelete.filter((id) => !previouslyDeleted.includes(id)).length > 0) {
            await deleteBidLanes();

            setRowsToDelete([]);
        }

        const lanesNotDeleted = _lanes.filter((lane) => !rowsToDelete.includes(lane.id as number));
        await updateBidLanes(lanesNotDeleted);
        dispatch(getAllBidFiles());
        await fetchBidLanes().catch(console.error);
    };

    const saveNewBidConfig = async (newBidFileName: string) => {
        const _lanes = produce(editedLanes, (_draft) => {});
        const formattedDateTime = convertDateTimeObjIntoUSFormatWithDash(new Date());

        const editedRows = _lanes.map((row) => {
            return {
                id: row?.rowId,
                rate_per_mile: row?.newValue
            };
        });

        const deletedRows = rowsToDelete?.map((row) => {
            return {
                id: row,
                deleted_at: formattedDateTime
            };
        });

        const requestedRows = [...editedRows, ...deletedRows];

        try {
            const {
                data: { success, result }
            } = await API.post(`/bidding/copy-configurations/`, {
                bid_config_id: configId,
                bid_lanes: requestedRows,
                file_name: newBidFileName
            });
            if (success) {
                setConfigId(result.bid_config.id);
                dispatch(getAllBidFiles());
                setEditedLanes([]);
                setRowsToDelete([]);
            } else {
                console.warn('copy configurations request failed');
                setEditedLanes([]);
                setRowsToDelete([]);
            }
        } catch (e) {
            console.warn('Error saving new bid file');
            setEditedLanes([]);
            setRowsToDelete([]);
        }
    };

    const prepareLanesForDelete = () => {
        setRowsToDelete([...rowsToDelete, ...checkedRows]);
        setCheckedRows([]);
    };

    const updateEditedRows = (rows: any, cellEdits: any) => {
        const results = rows;
        cellEdits.forEach((cellEdit: any) => {
            const index = rows.findIndex((row: any) => {
                return cellEdit.rowId === row.rowId;
            });

            if (index !== -1) {
                results[index] = cellEdit;
            } else {
                results.push(cellEdit);
            }
        });
        return results;
    };

    const findRow = (lanes: DataRow[], id: number) => {
        return lanes.find((lane) => lane.id === id);
    };
    const closeBidConfigSetting = () => {
        dispatch(updateBiddingState({ openBidConfigSettingSlideout: false }));
    };

    // TODO This is inefficient. We should explore storing row data as a map rather than an array.
    // Alternatively, update ODTableUtils.getModifiedPartialCopy or create a new similar function that runs through the edits and only looks up the lane once.
    const copyRateEdits = (cellEdits: CellEdit[]) => {
        let ret: CellEdit[] = [];
        cellEdits.forEach((cellEdit) => {
            ret.push(cellEdit);
            if (cellEdit.columnId === 'rate_per_mile') {
                const lane = findRow(lanes, cellEdit.rowId);
                if (!lane) return;
                ret.push({
                    rowId: cellEdit.rowId,
                    columnId: 'flat_rate',
                    newValue: cellEdit.newValue * (lane.mileage as number)
                });
            } else if (cellEdit.columnId === 'flat_rate') {
                const lane = findRow(lanes, cellEdit.rowId);
                if (!lane) return;
                const newVal =
                    Math.round(100 * (cellEdit.newValue / (lane.mileage as number))) / 100;
                ret.push({
                    rowId: cellEdit.rowId,
                    columnId: 'rate_per_mile',
                    newValue: newVal
                });
            }
        });
        return ret;
    };
    const handleCellEdits = (cellEdits: CellEdit[]) => {
        const updatedCellEdits = copyRateEdits(cellEdits);
        const updatedRows = updateEditedRows(editedLanes, updatedCellEdits);
        updateErrors(updatedCellEdits);
        setEditedLanes(updatedRows);
        setLanes(getModifiedPartialCopy(updatedCellEdits, lanes));
    };

    const updateErrors = (cellEdits: CellEdit[]) => {
        const tableErrorUpdates: { lane: DataRow; cellEdit: CellEdit }[] = [];
        cellEdits.forEach((cellEdit) => {
            const lane = lanes.find((lane: DataRow) => lane.id === cellEdit.rowId);
            if (lane) {
                tableErrorUpdates.push({ lane, cellEdit });
            }
        });
        dispatch(updateBidConfigTableErrors(tableErrorUpdates));
    };

    const clearAnyFilterOnRateColumns = (table: Table<DataRow>) => {
        table.setColumnFilters(
            table
                .getState()
                .columnFilters.filter(
                    (colFilter: ColumnFilter) =>
                        colFilter.id !== 'rate_per_mile' && colFilter.id !== 'flat_rate'
                )
        );
    };
    const maintainSortingOnRateColumns = (table: Table<DataRow>) => {
        const sortingState = table.getState().sorting;

        const ratePerMileSort = sortingState.find(
            (columnSort: ColumnSort) => columnSort.id === 'rate_per_mile'
        );
        const flatRateSort = sortingState.find(
            (columnSort: ColumnSort) => columnSort.id === 'flat_rate'
        );

        if (ratePerMileSort || flatRateSort) {
            const newSorting: ColumnSort[] = [];

            if (ratePerMileSort) {
                newSorting.push({ id: 'flat_rate', desc: ratePerMileSort.desc });
            }

            if (flatRateSort) {
                newSorting.push({ id: 'rate_per_mile', desc: flatRateSort.desc });
            }

            table.setSorting(newSorting);
        }
    };
    const handleSwapRateColumn = (option: string, table: Table<DataRow>) => {
        const modifiedColumnFormatSelections = produce(
            bidConfigColumnFormatSelections,
            (draft: Draft<{ [key: string]: string }>) => {
                draft.rate = option;
            }
        );
        clearAnyFilterOnRateColumns(table);
        maintainSortingOnRateColumns(table);
        dispatch(
            updateBiddingState({ bidConfigColumnFormatSelections: modifiedColumnFormatSelections })
        );
    };

    // This is to set the rate column to the default rate type that the user chose for this bid config during the upload process
    const flatRatesFlag = flatRates;
    useEffect(() => {
        if (flatRatesFlag) {
            const modifiedColumnFormatSelections = produce(
                bidConfigColumnFormatSelections,
                (draft: Draft<{ [key: string]: string }>) => {
                    draft.rate = configInfo.defaultRateType;
                }
            );

            dispatch(
                updateBiddingState({
                    bidConfigColumnFormatSelections: modifiedColumnFormatSelections
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configInfo.defaultRateType]);

    const handleOpenSettings = () => {
        dispatch(
            updateBiddingState({
                openBidConfigSettingSlideout: true
            })
        );
    };

    //TODO when the download feature gets added
    // const downloadBidConfigFile = (id: string) => {
    //     downloadBidConfigFiles([id]).then((res) => {
    //         let fileName = res?.headers['content-disposition']?.split('/tmp/')[1];

    //         convertBlobToDownload(res?.data, fileName, 'txt');
    //     });
    // };

    const laneData = useMemo(
        () => lanes.map((l: any) => ({ ...l, shipper_id: configInfo?.shipperId })),
        [lanes, configInfo]
    );

    const _lanes = useMemo(() => produce(lanes, (_draft) => {}), [lanes]);
    const numberOfValidLanes = useMemo(
        () => _lanes.filter((lane) => !rowsToDelete.includes(lane.id as number)).length,
        [_lanes, rowsToDelete]
    );

    const handleBidConfigActionAndTableRestore = async (
        e: any,
        restoreData: any,
        restoreAll: boolean = false
    ) => {};

    const applyBidConfigTableEditsAndActions = async (cellEdits: CellEdit[]) => {};

    const applyCellEditsToBidConfigTable = (cellEdits: CellEdit[]) => {};

    const handleUndoBidConfigTableCell = (row: DataRow, columnId: string, cellValue: any) => {};

    useEffect(() => {}, []);

    const findLane = () => {};
    const restoreBidConfigAction = () => {};

    return {
        loading,
        setLoader,
        lanes,
        setLanes,
        rowsToDelete,
        setRowsToDelete,
        checkedRows,
        setCheckedRows,
        configId,
        setConfigId,
        configInfo,
        setConfigInfo,
        openBidAnalysesSlideout,
        closeBidAnalysesSlideout,
        rateColumnRef,
        numberOfValidLanes,
        laneData,
        handleCellEdits,
        applyHistoricRatesCallback,
        handleSwapRateColumn,
        prepareLanesForDelete,
        restoreLanes,
        saveBidLanes,
        saveNewBidConfig,
        closeBidConfigSetting,
        handleOpenSettings,
        applyCellEditsToBidConfigTable,
        applyBidConfigTableEditsAndActions,
        findLane,
        handleBidConfigActionAndTableRestore,
        handleUndoBidConfigTableCell,
        restoreBidConfigAction,
        setPageIndexInHook,
        setPageSizeInHook,
        totalItems
    };
};

export default useBidConfigHook;
