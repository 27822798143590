import { useState } from 'react';
import {
    Drawer,
    Box,
    IconButton,
    Button,
    Accordion,
    AccordionSummary,
    Typography,
    Collapse
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/reducers';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DropdownColumnOption from 'shared/ODTable/DropdownColumnOption';
import { Column, ColumnDef } from '@tanstack/react-table';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { updateHiddenColumns, resetHiddenColumns } from 'redux/actions';
import { isHidden, getUpdatedHiddenColumns } from 'shared/ODTable/ODTableUtils';
import * as FS from '@fullstory/browser';

import {
    useODTable,
    ODTableContextInterface,
    ODTableProviderProps
} from 'shared/ODTable/ODTableContext';
const SlideoutContainer = styled(Box)(() => ({
    width: '392px'
}));
const StyledHeader = styled(Box)(() => ({
    height: '112px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));
const StyledBody = styled(Box)(() => ({
    paddingTop: '16px'
}));
const StyledHeaderActions = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));
const StyledInstructions = styled(Box)(() => ({
    color: theme.palette.neutral.neutral6,
    fontSize: '12px',
    marginBottom: '8px'
}));

const ODTableColumnSelectionSlideout: any = ({
    tableName,
    onClose,
    open
}: {
    tableName: string;
    onClose: () => void;
    open: boolean;
}) => {
    const { hiddenColumnsArrays } = useSelector((state: RootState) => state.TableLayoutReducer);
    const hiddenColumns = hiddenColumnsArrays[tableName];
    const { table } = useODTable() as ODTableContextInterface & ODTableProviderProps;
    const dispatch = useDispatch();
    const [expanded, toggleExpand] = useState(true);

    const handleCheckbox = (
        e: React.ChangeEvent<HTMLInputElement>,
        column: ColumnDef<DataRow, any>
    ) => {
        const updatedHiddenColumns = getUpdatedHiddenColumns(hiddenColumns, e, column);
        dispatch(updateHiddenColumns(tableName, updatedHiddenColumns));
    };

    const resetColumnSelections = () => {
        dispatch(resetHiddenColumns(tableName));
    };

    const sortByColName = (a: Column<DataRow, any>, b: Column<DataRow, any>) => {
        const nameA = a.columnDef.meta?.name;
        const nameB = b.columnDef.meta?.name;
        if (nameA && nameB) {
            return nameA.localeCompare(nameB);
        }
        return 0;
    };

    const renderOptionsList = () => {
        const allColumns = table
            .getAllLeafColumns()
            .filter((c) => !c.columnDef.meta?.notDisplayed)
            .sort(sortByColName);
        const lockedColumns = allColumns.filter((c) => c.columnDef.meta?.required);
        const unlockedColumns = allColumns.filter((c) => !c.columnDef.meta?.required);

        const visibleColumns = unlockedColumns.filter((c) => !isHidden(c.columnDef, hiddenColumns));
        const invisibleColumns = unlockedColumns.filter((c) =>
            isHidden(c.columnDef, hiddenColumns)
        );
        const sortedColumns = [...lockedColumns, ...visibleColumns, ...invisibleColumns];
        return sortedColumns.map((c) => (
            <DropdownColumnOption
                key={`dropdown-option-${c.columnDef.id}`}
                handleCheckbox={(e) => {
                    FS.event(`table_settings_user_clicks_${c.columnDef.header}`, {
                        columnName: c.columnDef.header,
                        checked: e.target.checked
                    });
                    handleCheckbox(e, c.columnDef);
                }}
                selected={!isHidden(c.columnDef, hiddenColumns)}
                columnDef={c.columnDef}
            />
        ));
    };
    return (
        <Drawer
            anchor={'right'}
            open={open}
            style={{ zIndex: 1300 }}
            onClose={onClose}
            sx={{ width: '336px' }}>
            <SlideoutContainer>
                <StyledHeader sx={{ padding: '0px 32px' }}>
                    <Box sx={{ fontSize: '18px' }}>Table Settings</Box>
                    <StyledHeaderActions>
                        <Box>
                            <Button
                                onClick={resetColumnSelections}
                                sx={{
                                    textDecoration: 'underline',
                                    textTransform: 'none',
                                    color: theme.palette.neutral.neutral6
                                }}>
                                Reset
                            </Button>
                        </Box>
                        <Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon sx={{ color: theme.palette.neutral.black }} />
                            </IconButton>
                        </Box>
                    </StyledHeaderActions>
                </StyledHeader>
                <StyledBody
                    sx={{
                        padding: '0px 32px',
                        overflowY: 'auto',
                        maxHeight: '86vh'
                    }}>
                    <Accordion
                        key={`table-columns-list`}
                        expanded={expanded}
                        style={{ fontSize: 14 }}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e, expanded) => toggleExpand(expanded)}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharpIcon
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography style={{ fontWeight: 600, color: 'black' }}>
                                Column Headers
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse in={expanded} timeout="auto" unmountOnExit key={`report-selected`}>
                        <Box sx={{ marginTop: '16px' }}>
                            <StyledInstructions>Select Table Column Headers</StyledInstructions>
                            {renderOptionsList()}
                        </Box>
                    </Collapse>
                </StyledBody>
            </SlideoutContainer>
        </Drawer>
    );
};

export default ODTableColumnSelectionSlideout;
