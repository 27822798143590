import { Box, Divider, Stack, Typography } from '@mui/material';
import { ODModal } from 'shared';
import theme from 'theme';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { styled } from '@mui/material/styles';
import { getTimeUnits } from 'utils/time.util';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { getBalanceColor } from 'utils/bidAnalysis.util';
import DeltaBarChart from 'shared/DeltaBarChart';
import LaneOriginDestinationRibbon from 'shared/LaneOriginDestinationRibbon';
import { getValueWithSignAndUnits } from 'utils/number.util';

const StyledBreakdown = styled(Stack)(() => ({
    borderRadius: '4px',
    border: `1px solid ${theme.palette.neutral.neutral1}`
}));

const StyledDetailRow = styled(Box)(() => ({
    padding: '10px 16px 10px 16px',
    display: 'flex',
    justifyContent: 'space-between'
}));

const Description = styled(Box)(() => ({
    fontSize: '12px',
    color: theme.palette.neutral.neutral6,
    marginBottom: '4px'
}));

const StyledResultingBalanceContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '8px',
    marginRight: '8px'
}));

const MKTBalanceModal = ({
    lane,
    market,
    handleClose
}: {
    lane: DataRow;
    market: 'origin' | 'destination';
    handleClose: () => void;
}) => {
    const marketAcronym = market === 'origin' ? 'OMKT' : 'DMKT';

    const { bidAnalysisSetting } = useSelector((state: RootState) => state.BidAnalysisReducer);

    let units = '';
    units = getTimeUnits(
        bidAnalysisSetting.column_specific_time_aggregation?.[`${marketAcronym}_balance`]
    );
    const originalBalance = Math.ceil(Number(lane[`${marketAcronym}_original_balance`]));
    const resultingBalance = Math.ceil(
        Number(lane[`${marketAcronym}_balance_change`]) +
            Number(lane[`new_${marketAcronym}_balance_change`])
    );
    const change = resultingBalance - originalBalance;
    const displayedOriginalBalance = getValueWithSignAndUnits(originalBalance, units);
    const displayedResultingBalance = getValueWithSignAndUnits(resultingBalance, units);
    const displayedChange = getValueWithSignAndUnits(change, units);
    return (
        <ODModal
            show={true}
            heading={`Resulting ${marketAcronym} Balance`}
            handleClose={handleClose}
            aria-describedby="modal-for-completed-report"
            enableCustomizedButton={true}>
            <Box sx={{ marginBottom: '8px' }}>
                <LaneOriginDestinationRibbon
                    origin={lane.origin as number}
                    destination={lane.destination as number}
                />
            </Box>
            <Description>{`${marketAcronym} Balance Breakdown`}</Description>
            <StyledBreakdown
                divider={<Divider sx={{ borderColor: theme.palette.neutral.neutral1 }} flexItem />}>
                <StyledDetailRow>
                    <Typography>{`Current ${marketAcronym}  Balance`}</Typography>
                    <Typography>{displayedOriginalBalance}</Typography>
                </StyledDetailRow>
                <StyledDetailRow>
                    <Typography>Bid Lane Contribution</Typography>
                    <Typography>{displayedChange}</Typography>
                </StyledDetailRow>
                <Box
                    sx={{
                        backgroundColor: theme.palette.neutral.search,
                        padding: '10px 8px 23px 8px'
                    }}>
                    <Stack
                        spacing={'8px'}
                        divider={
                            <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{
                                    borderColor: theme.palette.neutral.neutral1
                                }}
                            />
                        }>
                        <StyledResultingBalanceContainer>
                            <Typography
                                sx={{
                                    fontWeight: 600
                                }}>{`Resulting ${marketAcronym}  Balance`}</Typography>
                            <Typography
                                sx={{
                                    color: getBalanceColor(resultingBalance, originalBalance, true),
                                    fontWeight: 600
                                }}>
                                {displayedResultingBalance}
                            </Typography>
                        </StyledResultingBalanceContainer>
                        <DeltaBarChart
                            originalValue={originalBalance}
                            finalValue={resultingBalance}
                            units={units}
                        />
                    </Stack>
                </Box>
            </StyledBreakdown>
        </ODModal>
    );
};

export default MKTBalanceModal;
