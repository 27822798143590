import React from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { groupBy } from 'lodash';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LibraryAddCheckSharpIcon from '@mui/icons-material/LibraryAddCheckSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BUTTON_TYPE } from 'constants/colors';

import theme from 'theme';
import { GetReportDetailById, setSelectedReports } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { REPORT_STATUS } from 'constants/network';
import { REPORT_FILE_TYPE } from 'constants/reports';
import { ODButton, ODFooterActionBar } from 'shared';

export const ReportFooter = ({ record, onAction, editReport, deleteReport, selectedRecords }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const isMultipleRecords = selectedRecords.length > 1;
    const isSameBucket = Object.keys(groupBy(selectedRecords, 'bucket')).length === 1;

    const hasCompare =
        selectedRecords.filter(({ report_status }) =>
            [REPORT_STATUS.VFA_COMPLETED, REPORT_STATUS.COMPLETED, REPORT_STATUS.DRAFT].includes(
                report_status
            )
        ).length === selectedRecords.length && selectedRecords.length === 2;

    const twoReports = isMultipleRecords && selectedRecords.length === 2;
    const reportName = selectedRecords.reduce((store, { name }) => {
        if (store.length !== 0) {
            store.push(<> + {name}</>);
        } else store.push(name);
        return store;
    }, []);

    const viewReport = (item, sidebar = null) => {
        if (
            [REPORT_STATUS.COMPLETED, REPORT_STATUS.DRAFT, REPORT_STATUS.VFA_COMPLETED].includes(
                item.report_status
            )
        ) {
            dispatch(GetReportDetailById(item.id));

            dispatch(
                setSelectedReports({
                    selectedBaseReport: { id: item.id, enabled: true },
                    selectedCompareReport: {}
                })
            );
            const redirectUrl = `/reports/${
                item.bucket ? `bucket/${item.bucket}/` : `report-run/`
            }${item.id}${item.config_id ? `/config/${item.config_id}` : ''}/network/shipper${
                sidebar ? `/${sidebar}/` : '/'
            }`;
            history.push(redirectUrl, {
                report_run_id: item.id
            });
        }
    };

    const compareReport = (item, runs, isSameBucket = true, sidebar = null) => {
        if (!isSameBucket) onAction('comparingDisabled');
        dispatch(
            setSelectedReports({
                selectedBaseReport: { id: runs[0].id, enabled: true },
                selectedCompareReport: { id: runs[1].id, enabled: true }
            })
        );
        const reportIds = runs.map(({ id }) => id).join('/');
        const redirectUrl = `/reports/bucket/${item.bucket}/${reportIds}/network/shipper${
            sidebar ? `/${sidebar}/` : '/'
        }`;
        history.push(redirectUrl, {
            report_run_id: item.id
        });
    };

    const latestBaseReport = selectedRecords?.filter(
        (item) => item?.file_type === REPORT_FILE_TYPE.BASE && item?.latest_base?.id === item?.id
    );

    const hasLatestBaseReport = latestBaseReport.length > 0;

    return (
        <ODFooterActionBar
            sx={{
                margin: '16px 24px 6px 16px',
                width: 'calc(100% - 40px)',
                minHeight: '40px'
            }}
            ribbonIcon={
                isMultipleRecords && !twoReports ? (
                    <LibraryAddCheckSharpIcon sx={{ color: theme.palette.neutral.white }} />
                ) : (
                    <FolderSharpIcon sx={{ color: theme.palette.neutral.white }} />
                )
            }
            ribbonText={
                isMultipleRecords && !twoReports
                    ? `(${selectedRecords.length}) Reports Selected`
                    : reportName.map((name) => name)
            }
            buttonsOverride={
                <Box
                    sx={{
                        display: 'flex',
                        height: '40px',
                        '& > div:not(:last-child)': { marginRight: '16px' }
                    }}>
                    {(isMultipleRecords
                        ? hasLatestBaseReport.length !== selectedRecords.length
                        : !hasLatestBaseReport) && (
                        <ODButton
                            transparent={!(isMultipleRecords && !hasCompare)}
                            buttonText={`Delete Report${isMultipleRecords ? 's' : ''}`}
                            onClick={() => deleteReport()}
                            startIcon={
                                <DeleteSharpIcon sx={{ color: theme.palette.neutral.white }} />
                            }
                            buttonType={BUTTON_TYPE.BLUE}
                        />
                    )}
                    {hasCompare && (
                        <ODButton
                            buttonText={`Compare Reports`}
                            onClick={() => compareReport(record, selectedRecords, isSameBucket)}
                            startIcon={
                                <CompareArrowsIcon sx={{ color: theme.palette.neutral.white }} />
                            }
                            buttonType={BUTTON_TYPE.BLUE}
                        />
                    )}
                    {!isMultipleRecords && (
                        <ODButton
                            buttonText={`Edit Report Details`}
                            onClick={() => editReport(record)}
                            endIcon={<EditSharpIcon sx={{ color: theme.palette.neutral.white }} />}
                            buttonType={BUTTON_TYPE.BLUE}
                        />
                    )}
                    {[REPORT_STATUS.VFA_COMPLETED, REPORT_STATUS.COMPLETED].includes(
                        record.report_status
                    ) &&
                        !isMultipleRecords && (
                            <ODButton
                                buttonText={`View Report`}
                                onClick={() => viewReport(record)}
                                endIcon={
                                    <ArrowForwardIcon sx={{ color: theme.palette.neutral.white }} />
                                }
                                buttonType={BUTTON_TYPE.BLUE}
                            />
                        )}
                </Box>
            }
        />
    );
};
