/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CommonButtonComponent, CommonHeaderContainer } from 'shared';
import SlideOutContainer from 'components/NetworkView/SlideOutContainer';
import { useSelector } from 'react-redux';
import { useSimulationHook } from 'utils/hooks';
import theme from 'theme';
import AddTotalDriverType from './AddTotalDriverType';
import { AutoCompleteComponent } from './AutoCompleteComponent';
import clsx from 'clsx';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.neutral.neutral6,
        marginBottom: 16
    },
    label: {
        fontSize: 12,
        marginBottom: 4
    },
    inputField: {
        width: '100%',
        '& .MuiInput-input': {
            fontSize: 14,
            padding: '10px 16px',
            color: theme.palette.neutral.neutral6
        },
        '& .MuiInput-root::before': {
            borderBottom: `1px solid ${theme.palette.neutral.neutral2}`
        }
    }
}));

export const InputContainer = ({
    style = {},
    label = ' ',
    className = 'group-name-basic',
    id = 'group-name-basic',
    ...restProps
}) => {
    const classes = useStyles();

    return (
        <div class="form-control" className={classes.root} style={style}>
            <label for="my-input" className={classes.label}>
                {label}
            </label>
            <TextField
                id={id}
                variant="standard"
                className={clsx(classes.inputField, className)}
                placeholder={label}
                {...restProps}
            />
        </div>
    );
};

const AddDrivers = ({ show = true, onClose, onSave, onRefresh, domicile = {} }) => {
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [driverTypes, setDriverTypes] = useState([]);
    const { createSimulationOnEdit } = useSimulationHook();

    const { locationVisualization } = useSelector(({ settingsReducer }) => settingsReducer);
    const { reportFilters } = useSelector((state) => state.ReportsReducers);

    useEffect(() => {
        const driverTypes = (reportFilters?.driver_types || []).map(({ id }) => ({
            id,
            value: id,
            name: id,
            total: 0
        }));
        setDriverTypes(driverTypes);
    }, [reportFilters?.driver_types]);

    const submitGroup = async () => {
        const simulationId = await createSimulationOnEdit();
        onClose(true);
        if (!simulationId) return;
        await Promise.all(
            selectedTypes.map((driverType) => {
                if (driverType.total === 0) return true;
                return onSave(
                    {
                        location: domicile.location,
                        isNew: domicile.newDomicile,
                        domicile_id: domicile.name,
                        driver_type: driverType.name,
                        quantity: driverType.total
                    },
                    simulationId
                );
            })
        );
        onRefresh(simulationId);
    };

    const onChange = (_event, selectedList) => {
        setSelectedTypes(selectedList);
    };

    const updateTotal = (type, total) => {
        const _driverTypes = driverTypes.map((driverType) => {
            if (driverType.id === type.id) driverType.total = total;
            return driverType;
        });
        setDriverTypes(_driverTypes);
    };

    const getDomicileName = () =>
        locationVisualization === 'city-state'
            ? `${domicile?.location?.place}, ${domicile?.location?.region}`
            : domicile?.name;

    if (!show) return <></>;
    return (
        <SlideOutContainer
            dark={false}
            open={true}
            anchor={'right'}
            title={`Add Drivers`}
            endIcon={<ClearIcon sx={{ color: theme.palette.neutral.black }} />}
            headerStyle={{
                height: 112,
                paddingLeft: 32,
                fontWeight: 'normal',
                fontSize: 18
            }}
            headerOptions={{
                noBorder: true
            }}
            onClose={() => onClose()}>
            <Grid container style={{ overflowY: 'scroll' }}>
                <Grid item xs={12} style={{ margin: '16px 32px' }}>
                    <CommonHeaderContainer
                        dark={false}
                        noPadding={true}
                        noBorder={true}
                        header={'Domicile'}
                        headerStyle={{ marginBottom: 16 }}
                    />
                    <InputContainer
                        label={'Select Domicile'}
                        value={getDomicileName()}
                        readonly={true}
                        id={'select-domicile'}
                        className={'select-domicile'}
                    />
                    <CommonHeaderContainer
                        dark={false}
                        noPadding={true}
                        noBorder={true}
                        header={'Add Drivers'}
                        headerStyle={{ marginBottom: 16 }}
                    />
                    <AutoCompleteComponent
                        multiple={true}
                        id={'group-select-report-runs'}
                        className={'group-select-report-runs'}
                        label={`Select Driver Type`}
                        options={driverTypes}
                        onChange={onChange}
                        value={selectedTypes}
                    />
                    <AddTotalDriverTypes selectedTypes={selectedTypes} updateTotal={updateTotal} />

                    <CommonButtonComponent
                        disabled={selectedTypes.length === 0}
                        buttonText={'Save'}
                        onClick={submitGroup}
                        color="primary"
                        endIcon={<ArrowForwardIcon sx={{ color: theme.palette.white }} />}
                        className={'create-group-btn'}
                        style={{
                            display: 'flex',
                            marginBottom: 8,
                            width: '100%',
                            padding: '14px 24px',
                            justifyContent: 'space-between'
                        }}
                    />
                    <CommonButtonComponent
                        buttonText={'Cancel'}
                        onClick={() => onClose()}
                        color="primary"
                        endIcon={<ClearIcon sx={{ color: theme.palette.black }} />}
                        className={'cancel-group-btn'}
                        dark={false}
                        style={{
                            display: 'flex',
                            marginBottom: 8,
                            width: '100%',
                            padding: '14px 24px',
                            justifyContent: 'space-between'
                        }}
                    />
                </Grid>
            </Grid>
        </SlideOutContainer>
    );
};

const AddTotalDriverTypes = ({ selectedTypes, updateTotal }) =>
    selectedTypes.map((type) => <AddTotalDriverType type={type} updateTotal={updateTotal} />);

export default AddDrivers;
