/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    getDriverTypesByDomicileId,
    getKPIbyReportId,
    updateNetworkView,
    getDrivers,
    setLoadFilters,
    updateSelectedDomicile,
    getDriverMoments,
    updateDriverReducer,
    updateEditDriver,
    createDriverActions,
    updateAddDriversCount,
    onSetConfigurationSuccess,
    settingsUpdate
} from 'redux/actions';
import { getLocation, handleFiltered } from 'utils/common.util';
import { isDraftReportUtil } from 'utils/network.util';
import { map, groupBy, flatten } from 'lodash';
import { DRIVER_MODE, DRIVER_TABLE_TYPE } from 'constants/drivers';
import { Box } from '@mui/material';
import { domicileCol, driverTypeCol, driverMovesCol, driverIdCol } from './driverColumns';
import Modal from 'components/NetworkView/TopNavbar/components/Modal';
import NewDriverTable from './NewDriverTable';
import LocationsInfo from '../LocationsInfo';
import { getDelta, convertNumberToCommas, abbreviateNumber } from 'utils/number.util';
import {
    filterDriverTypes,
    createActions,
    setDefaultSortType,
    resetDriverChecked
} from 'utils/drivers.util';
import { useLoadingHook } from 'utils/hooks';
import { useSimulationHook } from 'utils/hooks';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import theme from 'theme';
import { NetworkTable } from '../NetworkTable';
import { getKeyByObjectValue } from 'utils/common.util';

import { STATE_ABBREVIATIONS_NAME_TABLE } from 'utils/data/locationNamesData';

//TODO This component needs to be refactored and DRY code.
const driverTablesLockedColumnsIds = ['pickUp', 'dropOff', 'id', 'domicileName', 'name', 'move_id'];

const Driver = ({ currReport }) => {
    const {
        allowEditOnAggregatedDrivers,
        movementsTableView,
        newSimulationFlow,
        newDomicileSlideout,
        v2KpIs,
        hideTrueProfit,
        driverViewDataFromKpiAnalytics
    } = useFlags();
    const { createSimulationOnEdit } = useSimulationHook();
    const { showLoader } = useLoadingHook();
    const { base_run, compare_run, config_id } = useParams();
    const [addDriversMode, toggleAddDriversMode] = useState(false);
    const [isDraftReport, setIsDraftReport] = useState(null);
    const dispatch = useDispatch();
    const { networkBreadcrumbs } = useSelector((state) => state.NetworkReducer);
    const reportLocations = useSelector((state) => state.ReportKPIReducer.reportLocations);
    const { bidConfigLocations } = useSelector((state) => state.BiddingReducer);
    const { organization } = useSelector((state) => state.SignInReducer);
    const { isListView, driverMode } = useSelector((state) => state.NetworkReducer);
    const { reportFilters, loadFilters, reportDriverTypes } = useSelector(
        (state) => state.ReportsReducers
    );
    const { configuration, configurationActions } = useSelector(
        ({ ConfigurationReducer }) => ConfigurationReducer
    );

    const isOrgTypeShipper = organization?.org_type === 'shipper';
    const { locationVisualization, aggregationLevel, maxAggregationLevel, isAggregated } =
        useSelector(({ settingsReducer }) => settingsReducer);
    const { hiddenColumnsArrays } = useSelector((state) => state.TableLayoutReducer);
    const {
        reportDomiciles,
        domicile,
        drivers,
        driverType,
        driverTypes,
        driverMoments,
        checked,
        editDriver,
        addDriversCount,
        driverTypesColors
    } = useSelector((state) => state.DriversReducer);
    const alertsContext = useAlerts();
    let baseFilter = compare_run ? { compare_run_id: compare_run } : {};
    const driverDomQueryParam = 'driver_domiciles'; // remove this boilerplate when we remove the v2KpIs feature flag

    // reset driver table to default if it's in aggregation mode
    useEffect(() => {
        if (!allowEditOnAggregatedDrivers && isAggregated) {
            dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DEFAULT }));
            dispatch(updateDriverReducer({ checked: resetDriverChecked }));
            alertsContext.addAlert('zip_aggregation');
            dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
            dispatch(
                getKPIbyReportId({
                    runId: base_run,
                    filter: baseFilter,
                    v2KpIsFlag: v2KpIs
                })
            );
        }
    }, [isAggregated]);

    // TODO move this logic to where settings are changed
    useEffect(() => {
        const aggregated =
            locationVisualization !== 'city-state' && aggregationLevel !== maxAggregationLevel;
        dispatch(settingsUpdate({ isAggregated: aggregated }));
    }, [aggregationLevel, maxAggregationLevel, locationVisualization]);

    useEffect(() => {
        setIsDraftReport(isDraftReportUtil(configuration));
    }, [configuration]);

    const handleCloseModal = () => {
        toggleAddDriversMode(false);
        dispatch(updateAddDriversCount({}));
        dispatch(updateEditDriver(null));
    };

    const handleAddDrivers = async () => {
        if (!newSimulationFlow && !allowEditOnAggregatedDrivers && isAggregated) {
            dispatch(
                onSetConfigurationSuccess({
                    openManageSimulation: false
                })
            );
            alertsContext.addAlert('zip_aggregation');
            handleCloseModal();
            return;
        }
        if (compare_run) {
            handleCloseModal();
            dispatch(updateNetworkView({ showAlertModal: true }));
            return;
        }
        if (!newSimulationFlow && !isDraftReport && !currReport?.read_only) {
            handleCloseModal();
            alertsContext.addAlert('accept_override');
            return;
        } else if (!newSimulationFlow && !isDraftReport) {
            return;
        }
        if (!addDriversMode) {
            toggleAddDriversMode(true);
        } else {
            const actionDict = filterDriverTypes(addDriversCount, editDriver);
            const actionsList = createActions(actionDict, editDriver);
            if (actionsList.length > 0) {
                const simulationId = await createSimulationOnEdit();
                if (!simulationId) return;
                dispatch(createDriverActions(base_run, simulationId, actionsList));
            }
            handleCloseModal();
        }
    };

    const decodeArray = (arr) => {
        return arr.map((item) => {
            return encodeURIComponent(item);
        });
    };

    const handleCheckbox = (e, id, mode) => {
        let filtered = [];
        let filterObj = {};
        let prevFilterObj = {};
        let isOpen = false;

        if (mode === DRIVER_MODE.DOMICILE) {
            filtered = [...checked.selectedDomicile];
            handleFiltered(e, id, filtered, checked.allDomiciles);
            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: decodeArray(filtered).join(',')
            };
            prevFilterObj = { ...baseFilter };

            dispatch(
                updateDriverReducer({
                    checked: {
                        ...checked,
                        allDomiciles: filtered.length === reportDomiciles.length,
                        selectedDomicile: filtered
                    }
                })
            );
            isOpen = filtered.length === 1;
        }

        if (mode === DRIVER_MODE.DRIVER_TYPES) {
            filtered = [...checked.selectedDriverType];
            handleFiltered(e, id, filtered, checked.allDriverTypes);
            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: decodeArray(filtered).join(',')
            };
            prevFilterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id
            };

            dispatch(
                updateDriverReducer({
                    checked: {
                        ...checked,
                        allDriverTypes: filtered.length === driverTypes.length,
                        selectedDriverType: filtered
                    }
                })
            );
            isOpen = filtered.length === 1;
        }

        if (mode === DRIVER_MODE.DRIVER_ID) {
            filtered = [...checked.selectedDriverId];
            handleFiltered(e, id, filtered, checked.allDriverIds);
            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: driverType?.id,
                driver_ids: decodeArray(filtered).join(',')
            };
            prevFilterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: driverType?.id
            };

            dispatch(
                updateDriverReducer({
                    checked: {
                        ...checked,
                        allDriverIds: filtered.length === drivers.length, // TODO domicile.driver_types.length is the size of the entire table. Instead, use the number of rows on the page.
                        selectedDriverId: filtered
                    }
                })
            );
        }
        dispatch(
            getKPIbyReportId({
                runId: base_run,
                filter:
                    filtered.length === 0
                        ? { ...prevFilterObj, aggregation_level: aggregationLevel }
                        : {
                              ...filterObj,
                              aggregation_level: aggregationLevel
                          },
                v2KpIsFlag: v2KpIs
            })
        );

        dispatch(
            updateNetworkView({
                showDriverOptionBar: isOpen
            })
        );
    };

    const updateDriverTypesColors = () => {
        let i = 0;
        const _driverTypesColors = reportDriverTypes?.driver_types?.reduce((acc, type) => {
            if (!acc[type]) {
                acc[type] = Object.values(theme.palette.barColors)[i];
                i++;
            }
            return acc;
        }, {});
        dispatch(
            updateDriverReducer({
                driverTypesColors: { ..._driverTypesColors }
            })
        );
    };

    useEffect(() => {
        updateDriverTypesColors();
    }, [reportDriverTypes]);

    const handleAllCheckbox = (e, rows) => {
        let filtered = [];
        let filterObj = {};
        let prevFilterObj = {};

        if (driverMode === DRIVER_MODE.DEFAULT) {
            if (e.target.checked) {
                filtered = rows.map((x) => x?.original?.domicile_id);

                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDomicile: filtered,
                            allDomiciles: true
                        }
                    })
                );
            } else
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDomicile: [],
                            allDomiciles: false
                        }
                    })
                );

            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: filtered.join(',')
            };
            prevFilterObj = { ...baseFilter };
        }

        if (driverMode === DRIVER_MODE.DOMICILE) {
            if (e.target.checked) {
                filtered = rows.map((x) => encodeURIComponent(x?.original?.id));

                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverType: filtered,
                            allDriverTypes: true
                        }
                    })
                );
            } else
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverType: [],
                            allDriverTypes: false
                        }
                    })
                );

            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: decodeArray(filtered).join(',')
            };
            prevFilterObj = { [driverDomQueryParam]: domicile?.domicile_id, ...baseFilter };
        }

        if (driverMode === DRIVER_MODE.DRIVER_TYPES) {
            if (e.target.checked) {
                filtered = rows.map((x) => encodeURIComponent(x?.original?.name));

                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverId: filtered,
                            allDriverIds: true
                        }
                    })
                );
            } else
                dispatch(
                    updateDriverReducer({
                        checked: {
                            ...checked,
                            selectedDriverId: [],
                            allDriverIds: false
                        }
                    })
                );

            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: driverType?.id,
                driver_ids: decodeArray(filtered).join(',')
            };
            prevFilterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: driverType?.id
            };
        }

        dispatch(
            getKPIbyReportId({
                runId: base_run,
                filter:
                    filtered.length === 0
                        ? { ...prevFilterObj, aggregation_level: aggregationLevel }
                        : {
                              ...filterObj,
                              aggregation_level: aggregationLevel
                          },
                v2KpIsFlag: v2KpIs
            })
        );
    };

    const handleClickRow = async (_filter, row, mode) => {
        showLoader(true);
        if (!allowEditOnAggregatedDrivers && isAggregated) {
            alertsContext.addAlert('zip_aggregation');
            return false;
        }

        await dispatch(
            updateNetworkView({
                driverMode: mode,
                showDriverOptionBar: false,
                networkBreadcrumbs: {
                    ...networkBreadcrumbs,
                    [mode]: _filter || row?.name
                }
            })
        );
        let filterObj = {};
        let filtered = [];

        let _checked = checked;

        if (mode === DRIVER_MODE.DOMICILE) {
            _checked = {
                ...checked,
                allDriverTypes: false,
                selectedDriverType: []
            };
        } else if (mode === DRIVER_MODE.DRIVER_TYPES) {
            _checked = {
                ...checked,
                allDriverIds: false,
                selectedDriverId: []
            };
        }

        if (driverMode === DRIVER_MODE.DEFAULT) {
            filtered.push(row?.domicile_id);
            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: row?.domicile_id
            };
            await dispatch(
                updateDriverReducer({
                    checked: {
                        ..._checked,
                        selectedDomicile: filtered
                    }
                })
            );
        } else if (driverMode === DRIVER_MODE.DOMICILE) {
            filtered.push(row?.id);
            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: row?.id
            };
            await dispatch(
                updateDriverReducer({
                    checked: {
                        ..._checked,
                        selectedDriverType: filtered
                    }
                })
            );
        } else if (driverMode === DRIVER_MODE.DRIVER_TYPES) {
            filtered.push(row?.name);
            filterObj = {
                ...baseFilter,
                [driverDomQueryParam]: domicile?.domicile_id,
                driver_types: driverType?.id,
                driver_ids: row?.name
            };

            await dispatch(
                updateDriverReducer({
                    checked: {
                        ...checked,
                        selectedDriverId: filtered,
                        selectedDriverInfo: row
                    }
                })
            );
        }

        await dispatch(
            getKPIbyReportId({
                runId: base_run,
                filter:
                    Object.keys(filterObj).length === 0
                        ? { ...baseFilter, aggregation_level: aggregationLevel }
                        : { ...baseFilter, ...filterObj, aggregation_level: aggregationLevel },
                v2KpIsFlag: v2KpIs
            })
        );

        if (mode === DRIVER_MODE.DOMICILE) {
            await dispatch(updateSelectedDomicile(row));
            let defaultDriverTypes = [];
            if (row.isNew) defaultDriverTypes = row?.driver_types;
            await dispatch(
                getDriverTypesByDomicileId(
                    base_run,
                    row?.domicile_id,
                    baseFilter,
                    defaultDriverTypes
                )
            );
            await dispatch(setLoadFilters({ ...loadFilters, isFilterSet: true }));
        } else if (mode === DRIVER_MODE.DRIVER_TYPES) {
            //base_run when it's not in compared mode
            await dispatch(getDrivers(compare_run || base_run, domicile?.domicile_id, row));
            await dispatch(setLoadFilters({ ...loadFilters, isFilterSet: true }));
        } else if (mode === DRIVER_MODE.DRIVER_ID) {
            //base_run when it's not in compared mode
            await dispatch(getDriverMoments(compare_run || base_run, row));
            await dispatch(setLoadFilters({ ...loadFilters, isFilterSet: true }));
        } else return;
        showLoader(false);
    };

    const setParams = (type) => {
        let params = {
            loadFilters: loadFilters,
            reportFilters: reportFilters,
            checked,
            readOnly: currReport?.read_only || compare_run,
            handleCheckbox,
            handleAllCheckbox,
            isListView: isListView,
            compare_run: compare_run,
            isOrgTypeShipper: isOrgTypeShipper,
            movementsTableView: movementsTableView,
            hideTrueProfit,
            driverViewDataFromKpiAnalytics
        };

        if (type === 'dynamic') {
            return { ...params, disableRow: loadFilters?.isFilterSet };
        } else return params;
    };

    const dynamicParams = setParams('dynamic');
    const headerParams = setParams('header');

    const triggerSlideOut = (row) => {
        dispatch(
            updateNetworkView({
                showDriveMoveDetails: {
                    show: true,
                    currData: row
                }
            })
        );
    };

    const topTableCol = () => {
        if (!loadFilters?.isFilterSet) {
            return domicileCol({
                ...dynamicParams,
                isAggregated: isAggregated,
                isTopTable: false,
                compare_run: compare_run,
                aggregationLevel: aggregationLevel,
                driverTypesColors: driverTypesColors,
                hideTrueProfit,
                driverViewDataFromKpiAnalytics
            });
        }
        if (driverMode === DRIVER_MODE.DOMICILE)
            return domicileCol({
                ...dynamicParams,
                isAggregated: isAggregated,
                isTopTable: true,
                compare_run: compare_run,
                aggregationLevel: aggregationLevel,
                driverTypesColors: driverTypesColors,
                hideTrueProfit,
                driverViewDataFromKpiAnalytics
            });
        if (driverMode === DRIVER_MODE.DRIVER_TYPES)
            return driverTypeCol({
                ...dynamicParams,
                isTopTable: true,
                compare_run: compare_run,
                hideTrueProfit,
                driverViewDataFromKpiAnalytics
            });
        else if (driverMode === DRIVER_MODE.DRIVER_ID)
            return driverIdCol({
                ...dynamicParams,
                isTopTable: true,
                compare_run: compare_run,
                movementsTableView: movementsTableView,
                hideTrueProfit,
                driverViewDataFromKpiAnalytics
            });
        else
            return domicileCol({
                ...dynamicParams,
                isAggregated: isAggregated,
                isTopTable: true,
                compare_run: compare_run,
                aggregationLevel: aggregationLevel,
                driverTypesColors: driverTypesColors,
                hideTrueProfit,
                driverViewDataFromKpiAnalytics
            });
    };

    const columns = useMemo(
        () => topTableCol(),
        [driverMode, driverTypesColors, compare_run, base_run, loadFilters?.isFilterSet, checked]
    );
    const childDriverTypesCol = useMemo(
        () => driverTypeCol({ ...headerParams }),
        [checked, domicile?.domicile_id]
    );
    const childDriverIdsCol = useMemo(
        () => driverIdCol({ ...headerParams }),
        [driverType?.id, checked]
    );
    const childDriverMovesCol = useMemo(
        () => driverMovesCol({ ...headerParams, handleAction: triggerSlideOut }),
        []
    );

    const dataByCurrMode = () => {
        let currTableData = [];

        if (domicile && driverMode === DRIVER_MODE.DOMICILE) {
            currTableData = [];
            currTableData.push(domicile);
        }
        if (driverType && driverMode === DRIVER_MODE.DRIVER_TYPES) {
            currTableData = [];
            currTableData.push(driverType);
        }
        if (driverType && driverMode === DRIVER_MODE.DRIVER_ID) {
            currTableData = [];
            let driverData = driverMoments?.driver ||
                checked.selectedDriverInfo || { name: '', miles_per_week: 0 };
            currTableData.push(driverData);
        }
        return currTableData;
    };

    const getAllDomiciles = () => {
        let domiciles = reportDomiciles?.map((domicile) => {
            const {
                total_drivers,
                compare_empty_miles_percentage,
                compare_home_on_time,
                compare_miles_per_week,
                compare_time_at_home_events,
                compare_total_drivers,
                compare_true_profit,
                compare_avg_revenue_driver_per_week,
                true_profit,
                avg_revenue_driver_per_week,
                miles_per_week,
                empty_miles_percentage,
                time_at_home_events,
                home_on_time
            } = domicile;

            //default value;
            let totalDrivers = total_drivers;
            let deltaTotalDrivers = null;
            let trueProfit = true_profit;
            let deltaTrueProfit = null;
            let avgRevDriverPerWeek = avg_revenue_driver_per_week
            let deltaAvgRevDriverPerWeek = null
            let avgMilesPerWeek = miles_per_week;
            let deltaAvgMilesPerWeek = null;
            let emptyMiles = Number(empty_miles_percentage?.toFixed(1));
            let deltaEmptyMiles = null;
            let timeAtHome = time_at_home_events;
            let deltaTimeAtHome = null;
            let hoursOnTime = Number(home_on_time?.toFixed(1));

            if (compare_run) {
                deltaTotalDrivers = compare_total_drivers - total_drivers;
                deltaTrueProfit = compare_true_profit - true_profit;
                deltaAvgRevDriverPerWeek = compare_avg_revenue_driver_per_week - avg_revenue_driver_per_week;
                deltaAvgMilesPerWeek = compare_miles_per_week - miles_per_week;
                deltaEmptyMiles = compare_empty_miles_percentage - empty_miles_percentage;

                deltaTimeAtHome = compare_time_at_home_events - time_at_home_events;
                let deltaHoursOnTime = getDelta(
                    compare_home_on_time,
                    home_on_time,
                    'percentage',
                    null
                );
                totalDrivers = `${compare_total_drivers}`;
                trueProfit = `$${abbreviateNumber(Number(compare_true_profit))} `;
                avgRevDriverPerWeek = `$${abbreviateNumber(Number(compare_avg_revenue_driver_per_week))} `;
                avgMilesPerWeek = `${convertNumberToCommas(compare_miles_per_week)}`;
                emptyMiles = `${compare_empty_miles_percentage?.toFixed(1)}%`;
                timeAtHome = `${convertNumberToCommas(compare_time_at_home_events)}`;
                hoursOnTime = `${compare_home_on_time}% ${deltaHoursOnTime}`;
            }

            const domicileName = getLocation(domicile.name, reportLocations, locationVisualization);
            const domicileCityStateZip = getLocation(
                domicile.name,
                reportLocations,
                'city-state-zip',
                bidConfigLocations
            );

            return {
                ...domicile,
                domicileName,
                domicileCityStateZip,
                totalDrivers,
                deltaTotalDrivers,
                trueProfit,
                deltaTrueProfit,
                avgRevDriverPerWeek,
                deltaAvgRevDriverPerWeek,
                avgMilesPerWeek,
                deltaAvgMilesPerWeek,
                emptyMiles,
                deltaEmptyMiles,
                timeAtHome,
                deltaTimeAtHome,
                hoursOnTime
            };
        });

        let _newDomiciles = [];

        if (config_id && (allowEditOnAggregatedDrivers || !isAggregated)) {
            const newDomiciles = configurationActions
                .filter(
                    (config) =>
                        config.type === 'add' && config.item_type === 'driver' && config.value.isNew
                )
                .reduce((store, item) => {
                    let domicileName = item.value.location;

                    if (newDomicileSlideout) {
                        domicileName =
                            locationVisualization === 'city-state'
                                ? domicileName
                                : String(item?.value?.domicile)?.slice(0, aggregationLevel);
                    } else {
                        let city = domicileName?.place;
                        let state = getKeyByObjectValue(
                            STATE_ABBREVIATIONS_NAME_TABLE,
                            domicileName?.region
                        );

                        domicileName = `${city}, ${state}`;
                    }

                    const domicile = store[item.value.domicile] || {
                        driver_types: [],
                        total_drivers: 0,
                        totalDrivers: 0,
                        name: item.value.domicile,
                        domicile_id: item.value.domicile,
                        domicileName,
                        true_profit: 0,
                        miles_per_week: 0,
                        empty_miles_percentage: 0,
                        time_at_home_events: 0,
                        home_on_time: 0,
                        ...item.value
                    };

                    const count = item.value.quantity || 0;

                    domicile.driver_types.push({
                        id: item.value.driver_type,
                        index: domicile.driver_types.length + 1,
                        driver_type: item.value.driver_type,
                        domicileId: item.value.domicile,
                        actionCount: 0,
                        count
                    });

                    domicile.total_drivers += count;
                    store[item.value.domicile] = domicile;
                    return store;
                }, {});

            _newDomiciles = map(newDomiciles, (domicile) => domicile);
        }

        return [..._newDomiciles, ...domiciles];
    };

    const topTableData =
        loadFilters?.isFilterSet && (allowEditOnAggregatedDrivers || !isAggregated)
            ? dataByCurrMode()
            : getAllDomiciles();

    const data = useMemo(
        () => topTableData || [],
        [
            topTableData,
            base_run,
            checked,
            compare_run,
            reportDomiciles,
            isAggregated,
            configurationActions
        ]
    );

    //driver_types data
    let driverTypeData = driverTypes?.map((type) => {
        const {
            id,
            domicileId,
            total_drivers,
            compare_empty_miles_percentage,
            compare_home_on_time,
            compare_miles_per_week,
            compare_time_at_home_events,
            compare_total_drivers,
            compare_true_profit_amount,
            compare_avg_revenue_driver_per_week,
            avg_revenue_driver_per_week,
            true_profit_amount,
            miles_per_week,
            empty_miles_percentage,
            time_at_home_events,
            home_on_time
        } = type;

        //default value;
        let totalDrivers = total_drivers;
        let deltaTotalDrivers = null;
        let trueProfit = true_profit_amount;
        let deltaTrueProfit = null;
        let avgRevDriverPerWeek = avg_revenue_driver_per_week;
        let deltaAvgRevDriverPerWeek = null;
        let avgMilesPerWeek = miles_per_week;
        let deltaAvgMilesPerWeek = null;
        let emptyMiles = Number(empty_miles_percentage?.toFixed(1));
        let deltaEmptyMiles = null;
        let timeAtHome = time_at_home_events;
        let deltaTimeAtHome = null;
        let hoursOnTime = Number(home_on_time?.toFixed(1));

        if (compare_run) {
            deltaTotalDrivers = compare_total_drivers - total_drivers;
            deltaTrueProfit = compare_true_profit_amount - (true_profit_amount ?? 0);
            deltaAvgRevDriverPerWeek = compare_avg_revenue_driver_per_week - (avg_revenue_driver_per_week ?? 0);
            deltaAvgMilesPerWeek = compare_miles_per_week - (miles_per_week ?? 0);
            deltaEmptyMiles = compare_empty_miles_percentage - (empty_miles_percentage ?? 0);
            deltaTimeAtHome = compare_time_at_home_events - (time_at_home_events ?? 0);
            let deltaHoursOnTime = getDelta(compare_home_on_time, home_on_time, 'percentage', null);

            totalDrivers = `${compare_total_drivers}`;
            trueProfit = `$${abbreviateNumber(Number(compare_true_profit_amount))}`;
            deltaAvgRevDriverPerWeek = `$${abbreviateNumber(Number(compare_avg_revenue_driver_per_week))}`;
            avgMilesPerWeek = `${convertNumberToCommas(compare_miles_per_week)} `;
            emptyMiles = `${compare_empty_miles_percentage?.toFixed(1)}%`;
            timeAtHome = `${convertNumberToCommas(compare_time_at_home_events)} `;
            hoursOnTime = `${compare_home_on_time}% ${deltaHoursOnTime}`;
        }

        const driverTypeActions = configurationActions.find(
            (action) =>
                action?.item_type === 'driver' &&
                action?.value?.domicile === domicileId &&
                action?.value?.driver_type === id
        );

        const driverCount = driverTypeActions?.value?.quantity || 0;

        return {
            ...type,
            totalDrivers,
            deltaTotalDrivers,
            trueProfit,
            deltaTrueProfit,
            avgRevDriverPerWeek,
            deltaAvgRevDriverPerWeek,
            avgMilesPerWeek,
            deltaAvgMilesPerWeek,
            emptyMiles,
            deltaEmptyMiles,
            timeAtHome,
            deltaTimeAtHome,
            hoursOnTime,
            actionCount: driverCount
        };
    });

    const driverTypesData = useMemo(() => driverTypeData || [], [driverTypeData]);

    let driverData = useMemo(
        () =>
            // Adds var names so that reusable column definitions can work on the drivers table columns
            drivers.map((d) => ({
                ...d,
                emptyMiles: d.empty_miles_percentage,
                trueProfit: d.true_profit_amount,
                avgRevDriverPerWeek: d.avg_revenue_driver_per_week,
                timeAtHome: d.total_time_at_home,
                avgMilesPerWeek: d.miles_per_week
            })) || [],
        [drivers]
    );

    const filteredMoveData = (driverMoments?.moments || []).filter(
        ({ load_pickup_location, load_drop_off_location }) =>
            load_pickup_location && load_drop_off_location
    );
    const movesById = groupBy(filteredMoveData, 'move_id');
    let arrangedMovesData = Object.keys(movesById).map((data) => {
        return movesById[data].map((item, i) => {
            return item;
        });
    });

    const movesData = useMemo(
        () => flatten(arrangedMovesData),
        [driverMoments?.moments, domicile?.domicile_id]
    );

    const getHiddenColumnsArray = (driverMode) => {
        switch (driverMode) {
            case DRIVER_TABLE_TYPE.DOMICILE:
                return compare_run
                    ? [...hiddenColumnsArrays?.comparison_domiciles_table]
                    : [...hiddenColumnsArrays?.domiciles_table];
            case DRIVER_TABLE_TYPE.DRIVER_TYPES:
                return compare_run
                    ? [...hiddenColumnsArrays?.comparison_driver_types_table]
                    : [...hiddenColumnsArrays?.driver_types_table];
            case DRIVER_TABLE_TYPE.DRIVER_ID:
                return [...hiddenColumnsArrays?.driver_ids_table];
            case DRIVER_TABLE_TYPE.DRIVER_MOMENTS:
                return [...hiddenColumnsArrays?.motions_table];
            default:
                throw Error('Could not find hidden columns array for driverMode ' + driverMode);
        }
    };

    const childTable = (mode) => {
        if (mode === DRIVER_MODE.DOMICILE) {
            return (
                <NetworkTable
                    key="driver-types-child-table"
                    isListView={isListView}
                    enableCursorRow={true}
                    enableFilter={true}
                    sortColumn={defaultSortType}
                    onSetFilter={handleClickRow}
                    handleAlLCheckbox={handleAllCheckbox}
                    loadFilters={loadFilters}
                    drilledIn={true}
                    topTable={false}
                    hiddenColumns={getHiddenColumnsArray(DRIVER_MODE.DRIVER_TYPES)}
                    lockedColumnsIds={driverTablesLockedColumnsIds}
                    tableDataType={DRIVER_TABLE_TYPE.DRIVER_TYPES}
                    columns={childDriverTypesCol}
                    data={driverTypesData}
                />
            );
        } else if (mode === DRIVER_MODE.DRIVER_TYPES) {
            return (
                <NetworkTable
                    key="driver-ids-child-table"
                    isListView={isListView}
                    enableCursorRow={movementsTableView}
                    enableFilter={true}
                    sortColumn={defaultSortType}
                    onSetFilter={handleClickRow}
                    handleAlLCheckbox={handleAllCheckbox}
                    loadFilters={loadFilters}
                    drilledIn={true}
                    topTable={false}
                    hiddenColumns={getHiddenColumnsArray(DRIVER_MODE.DRIVER_ID)}
                    lockedColumnsIds={driverTablesLockedColumnsIds}
                    tableDataType={DRIVER_TABLE_TYPE.DRIVER_ID}
                    columns={childDriverIdsCol}
                    data={driverData}
                />
            );
        } else return;
    };

    let defaultSortType = setDefaultSortType(compare_run, driverMode);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}>
            <Modal
                show={editDriver && !newDomicileSlideout}
                heading={
                    editDriver ? (
                        <LocationsInfo
                            value={
                                editDriver.domicile_id === 'all' && aggregationLevel === 0
                                    ? 'All'
                                    : editDriver.domicile_id
                            }
                        />
                    ) : null
                }
                handleClose={handleCloseModal}
                actionButtonText={!addDriversMode ? 'Edit Driver Amount' : 'Save'}
                handleAction={handleAddDrivers}
                children={editDriver ? <NewDriverTable addDriversMode={addDriversMode} /> : null}
                type={isDraftReport || !currReport?.read_only ? 'dark' : 'disable'}
                closeType="dark"
            />
            {loadFilters?.isFilterSet ? (
                <>
                    <NetworkTable
                        isListView={isListView}
                        sortColumn={defaultSortType}
                        columns={columns}
                        enableCursorRow={!loadFilters?.isFilterSet}
                        data={data}
                        onSetFilter={handleClickRow}
                        enableFilter={!loadFilters?.isFilterSet}
                        handleAllCheckbox={handleAllCheckbox}
                        loadFilters={loadFilters}
                        tableDataType={driverMode}
                        hiddenColumns={getHiddenColumnsArray(driverMode)}
                        lockedColumnsIds={driverTablesLockedColumnsIds}
                        topTable={loadFilters?.isFilterSet}
                    />
                    {childTable(driverMode)}
                </>
            ) : (
                <NetworkTable
                    isListView={isListView}
                    sortColumn={defaultSortType}
                    columns={columns}
                    enableCursorRow={!loadFilters?.isFilterSet}
                    data={data}
                    onSetFilter={handleClickRow}
                    enableFilter={!loadFilters?.isFilterSet}
                    handleAllCheckbox={handleAllCheckbox}
                    loadFilters={loadFilters}
                    tableDataType={DRIVER_TABLE_TYPE.DOMICILE}
                    hiddenColumns={getHiddenColumnsArray(DRIVER_TABLE_TYPE.DOMICILE)}
                    lockedColumnsIds={driverTablesLockedColumnsIds}
                    topTable={loadFilters?.isFilterSet}
                />
            )}

            {driverMode === DRIVER_MODE.DRIVER_ID && loadFilters?.isFilterSet && (
                <NetworkTable
                    isListView={isListView}
                    sortColumn={null} // TODO We can add default sorting to movements table
                    columns={childDriverMovesCol}
                    enableCursorRow={false}
                    data={movesData}
                    onSetFilter={handleClickRow}
                    enableFilter={false}
                    handleAllCheckbox={handleAllCheckbox}
                    loadFilters={loadFilters}
                    tableDataType={DRIVER_MODE.DRIVER_MOMENTS}
                    hiddenColumns={getHiddenColumnsArray(DRIVER_TABLE_TYPE.DRIVER_MOMENTS)}
                    lockedColumnsIds={driverTablesLockedColumnsIds}
                    topTable={false}
                    drilledIn={true}
                />
            )}
        </Box>
    );
};

export default Driver;
