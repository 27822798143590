import { Box, Tooltip } from '@mui/material';
import { LOAD_TYPES } from 'constants/shippers';
import ShipperDonut from './ShipperDonut';
import theme from 'theme';
import styled from '@emotion/styled';
import Advisories from './ShipperAdvisories';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import StarsIcon from '@mui/icons-material/Stars';
import {
    convertNumberToCommas,
    compareNumericString,
    abbreviateNumber,
    formatDelta,
    formatTwoDecimals
} from 'utils/number.util';
import { DropdownFilter, NumberRangeFilter, minMaxFilter } from 'shared/Table/tableFilters';
import ODCheckbox from 'shared/Checkbox';
import { formatCityState } from 'utils/common.util';
import { CompareHeaderText } from 'utils/table.util';

const ellipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
};

const StyledSpan = styled('span')(({ hasPointer }) => ({
    textDecoration: 'underline',
    cursor: hasPointer ? 'pointer' : 'auto',
    display: 'flex',
    ...ellipsisStyle
}));

const formatLaneLocation = ({ value, tooltip }) => {
    return (
        <Tooltip placement="right" title={tooltip}>
            <Box
                sx={{
                    paddingRight: '1rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'flex'
                }}>
                {formatCityState(value, '84px', !isNaN(value))}
            </Box>
        </Tooltip>
    );
};

/* Guide to where each of these parameters are used...
 * Header:
    1. The column header text
    2. The Header value is also used as the column label in the column selection dropdown of the table settings slideout.
 * identifier:
    1. Referenced for conditionally styling columns. For example, calculating column width and padding of all columns and for special exceptions such as giving the settings column a different header containing the button for expanding the settings slideout.
    3. Referenced when calculating left offsets of sticky columns
    4. Referenced when determining whether the column of the cell that a user clicked is one that should make the click trigger a drill down into that row.
 * visibility_key:
    1. Controls column visibility. Works with the column selection dropdown in the table settings slideout.
 * accessor:
    1. react table uses this internally to match table data to the column. If no Header or Cell values are specified for a column definition, then the table will display data[row][<the accessor value>] as the cell content
    2. The accessor is also the name of the column from the dataset that react table passes into the filter function
 * Cell:
    1. cell content
 * applyGlobalFilter:
    1. If true, the global search filter will apply to the column.
 * filter
    1. The function that filters the rows based by the filterValue
 * Filter
    1. A React component containing the filter input fields
 */

//REFACTOR DRY Code

const sharedColumns = (
    compare_run,
    isOrgTypeShipper,
    disableRow,
    readOnly,
    isFilterSet,
    reportFilters,
    hideTrueProfit,
    odpt4098HideOnTimeKpi,
    odpt4173HideCoverageKpi
) => [
    {
        Header: 'Fleet',
        accessor: compare_run ? 'compare_load_count' : 'load_count',
        identifier: 'fleet',
        visibilityKey: 'fleet',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        sortType: compareNumericString,
        sortMethod: (a, b) => {
            return a > b ? 1 : -1;
        },
        Cell: ({ value, cell: { row } }) => {
            const displayedVal = compare_run
                ? row?.original?.compare_load_count
                : row?.original?.load_count;
            const displayTotal = compare_run
                ? row?.original?.compare_total_load_count
                : row?.original?.total_load_count;
            const underlineVal = displayedVal;
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                    <ShipperDonut
                        value={displayedVal || 0}
                        row={row}
                        type={LOAD_TYPES.fleet}
                        index={`${row?.id}${row?.original?.id}`}
                        total={displayTotal || 0}
                    />
                    {compare_run ? (
                        row?.original?.compare_load_count
                    ) : !disableRow && !compare_run ? (
                        <>
                            <StyledSpan hasPointer={true}>{underlineVal}</StyledSpan>
                            {row?.original?.fleet.split(' ')[1] &&
                                '\xa0' + row?.original?.fleet.split(' ')[1]}{' '}
                        </>
                    ) : (
                        row?.original?.fleet
                    )}
                </Box>
            );
        }
    },
    ...(compare_run
        ? [
              {
                  Header: <CompareHeaderText text="Fleet" />,
                  accessor: 'delta_load_count',
                  identifier: 'fleetDelta',
                  visibilityKey: 'fleet',
                  isDelta: true,
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  sortType: compareNumericString,
                  Cell: ({ value }) => {
                      return (
                          <Box
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                              }}>
                              <Box>{formatDelta(value)}</Box>
                          </Box>
                      );
                  }
              }
          ]
        : []),
    {
        Header: 'External',
        accessor: 'external',
        identifier: 'external',
        visibilityKey: 'external',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        sortType: compareNumericString,
        Cell: ({ value, cell: { row } }) => {
            let displayTotal = compare_run
                ? row?.original?.compare_total_load_count
                : row?.original?.total_load_count;
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                    <ShipperDonut
                        value={value || 0}
                        row={row}
                        type={LOAD_TYPES.external}
                        index={`${row?.id}${row?.original?.id}`}
                        total={displayTotal || 0}
                    />
                    <Box>{value}</Box>
                </Box>
            );
        }
    },
    ...(compare_run
        ? [
              {
                  Header: <CompareHeaderText text="External" />,
                  accessor: 'externalDelta',
                  identifier: 'externalDelta',
                  visibilityKey: 'external',
                  isDelta: true,
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  sortType: compareNumericString,
                  Cell: ({ value }) => {
                      return (
                          <Box
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                              }}>
                              <Box>{formatDelta(value)}</Box>
                          </Box>
                      );
                  }
              }
          ]
        : []),

    ...(odpt4173HideCoverageKpi
        ? []
        : [
              {
                  Header: isOrgTypeShipper ? '% Covered By Fleet' : 'Service Coverage',
                  accessor: compare_run ? 'compare_service_coverage' : 'service_coverage',
                  identifier: 'serviceCoverage',
                  visibilityKey: 'serviceCoverage',
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  formattingFunction: formatTwoDecimals,
                  sortType: compareNumericString,
                  columnWidth: '148px',
                  Cell: ({ row }) => <Box>{row?.original.serviceCoverage}</Box>
              }
          ]),
    ...(compare_run
        ? odpt4173HideCoverageKpi
            ? []
            : [
                  {
                      Header: (
                          <CompareHeaderText
                              text={isOrgTypeShipper ? '% Covered By Fleet' : 'Service Coverage'}
                          />
                      ),
                      accessor: 'delta_service_coverage',
                      identifier: 'serviceCoverageDelta',
                      visibilityKey: 'serviceCoverage',
                      columnWidth: '160px',
                      isDelta: true,
                      Filter: NumberRangeFilter,
                      filter: minMaxFilter,
                      formattingFunction: formatTwoDecimals,
                      sortType: compareNumericString,
                      Cell: ({ row }) => <Box>{row?.original?.serviceCoverageDelta}</Box>
                  }
              ]
        : []),
    ...(hideTrueProfit
        ? []
        : [
              {
                  Header: isOrgTypeShipper ? 'True Savings ($)' : 'True Profit ($)',
                  accessor: compare_run ? 'compare_true_profit_amount' : 'true_profit_amount',
                  identifier: 'true_profit_amount',
                  visibilityKey: 'true_profit_amount',
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  formattingFunction: formatTwoDecimals,
                  sortType: compareNumericString,
                  abbreviated: true,
                  Cell: ({ value, row }) => (
                      <Box>
                          {compare_run
                              ? `$${abbreviateNumber(value)}`
                              : `$${abbreviateNumber(row?.original?.trueProfit)}`}
                      </Box>
                  )
              }
          ]),
    ...(compare_run
        ? hideTrueProfit
            ? []
            : [
                  {
                      Header: (
                          <CompareHeaderText
                              text={isOrgTypeShipper ? 'True Savings ($)' : 'True Profit ($)'}
                          />
                      ),
                      accessor: 'delta_true_profit_amount',
                      identifier: 'trueProfitDelta',
                      visibilityKey: 'true_profit_amount',
                      columnWidth: '140px',
                      isDelta: true,
                      Filter: NumberRangeFilter,
                      filter: minMaxFilter,
                      formattingFunction: formatTwoDecimals,
                      sortType: compareNumericString,
                      abbreviated: true,
                      Cell: ({ row }) => <Box>{row?.original?.trueProfitDelta}</Box>
                  }
              ]
        : []),
    ...(hideTrueProfit
        ? []
        : [
              {
                  Header: isOrgTypeShipper ? 'True Savings/Mile' : 'True Profit/Mile',
                  accessor: compare_run ? 'compare_true_profit_per_mile' : 'true_profit_per_mile',
                  identifier: 'true_profit_per_mile',
                  visibilityKey: 'true_profit_per_mile',
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  formattingFunction: formatTwoDecimals,
                  abbreviated: true,
                  columnWidth: '140px',
                  sortType: compareNumericString,
                  Cell: ({ value, row }) => (
                      <Box>
                          {compare_run
                              ? row?.original?.trueProfitPerMile
                              : `$${convertNumberToCommas(row?.original?.trueProfitPerMile)}`}
                      </Box>
                  )
              }
          ]),
    ...(compare_run
        ? hideTrueProfit
            ? []
            : [
                  {
                      Header: (
                          <CompareHeaderText
                              text={isOrgTypeShipper ? 'True Savings/Mile' : 'True Profit/Mile'}
                          />
                      ),
                      accessor: 'delta_true_profit_per_mile',
                      identifier: 'trueProfitPerMileDelta',
                      visibilityKey: 'true_profit_per_mile',
                      columnWidth: '170px',
                      isDelta: true,
                      Filter: NumberRangeFilter,
                      filter: minMaxFilter,
                      formattingFunction: formatTwoDecimals,
                      sortType: compareNumericString,
                      abbreviated: true,
                      Cell: ({ row }) => <Box>{row?.original?.trueProfitPerMileDelta}</Box>
                  }
              ]
        : []),
    {
        Header: isOrgTypeShipper ? 'External Cost/Mile' : 'Revenue/Mile',
        accessor: compare_run ? 'compare_revenue_per_mile' : 'revenue_per_mile',
        identifier: 'revenue_per_mile',
        visibilityKey: 'revenue_per_mile',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        sortType: compareNumericString,
        columnWidth: '148px',
        Cell: ({ row }) => {
            if (row?.original?.revPerMileRateChange) {
                const displayVal = row?.original?.revPerMileRateChange;
                return !disableRow ? (
                    <>
                        <StyledSpan hasPointer={true}>{displayVal.split(' ')[0]}</StyledSpan>
                        {displayVal.split(' ')[1] && '\xa0' + displayVal.split(' ')[1]}
                    </>
                ) : (
                    displayVal
                );
            } else {
                return !disableRow && !compare_run ? (
                    <StyledSpan hasPointer={true}>{row?.original?.revPerMile}</StyledSpan>
                ) : (
                    row?.original?.revPerMile
                );
            }
        }
    },
    ...(compare_run
        ? [
              {
                  Header: (
                      <CompareHeaderText
                          text={isOrgTypeShipper ? 'External Cost/Mile' : 'Revenue/Mile'}
                      />
                  ),
                  accessor: 'delta_revenue_per_mile',
                  identifier: 'revPerMileDelta',
                  visibilityKey: 'revenue_per_mile',
                  isDelta: true,
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  formattingFunction: formatTwoDecimals,
                  sortType: compareNumericString,
                  Cell: ({ row }) => <Box>{row?.original?.revPerMileDelta}</Box>
              }
          ]
        : []),
    {
        Header: 'Loaded Miles',
        accessor: compare_run ? 'compare_loaded_miles' : 'loaded_miles',
        identifier: 'loadedMiles',
        visibilityKey: 'loadedMiles',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        sortType: compareNumericString,
        Cell: ({ row }) => <Box>{row?.original?.loadedMiles}</Box>
    },
    ...(compare_run
        ? [
              {
                  Header: <CompareHeaderText text={'Loaded Miles'} />,
                  accessor: 'delta_loaded_miles',
                  identifier: 'loadedMilesDelta',
                  visibilityKey: 'loadedMiles',
                  columnWidth: '140px',
                  isDelta: true,
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  formattingFunction: formatTwoDecimals,
                  sortType: compareNumericString,
                  abbreviated: true,
                  Cell: ({ row }) => <Box>{row?.original?.loadedMilesDelta}</Box>
              }
          ]
        : []),
    ...(odpt4098HideOnTimeKpi
        ? []
        : [
              {
                  Header: 'On Time',
                  accessor: compare_run ? 'compare_on_time' : 'on_time',
                  identifier: 'onTime',
                  visibilityKey: 'onTime',
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  formattingFunction: formatTwoDecimals,
                  sortType: compareNumericString,
                  Cell: ({ row }) => <Box>{row?.original?.onTime}</Box>
              }
          ]),
    ...(compare_run
        ? odpt4098HideOnTimeKpi
            ? []
            : [
                  {
                      Header: <CompareHeaderText text={'On Time'} />,
                      accessor: 'delta_on_time',
                      identifier: 'onTimeDelta',
                      visibilityKey: 'onTime',
                      isDelta: true,
                      Filter: NumberRangeFilter,
                      filter: minMaxFilter,
                      formattingFunction: formatTwoDecimals,
                      sortType: compareNumericString,
                      Cell: ({ row }) => <Box>{row?.original?.onTimeDelta}</Box>
                  }
              ]
        : []),
    {
        Header: 'Average LOH',
        accessor: compare_run ? 'compare_average_loh' : 'average_loh',
        identifier: 'averageLoh',
        visibilityKey: 'averageLoh',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        sortType: compareNumericString,
        Cell: ({ row }) => <Box>{row?.original?.averageLoh}</Box>
    },
    ...(compare_run
        ? [
              {
                  Header: <CompareHeaderText text={'Average LOH'} />,
                  accessor: 'delta_average_loh',
                  identifier: 'averageLohDelta',
                  visibilityKey: 'averageLoh',
                  isDelta: true,
                  Filter: NumberRangeFilter,
                  filter: minMaxFilter,
                  formattingFunction: formatTwoDecimals,
                  sortType: compareNumericString,
                  Cell: ({ row }) => <Box>{row?.original?.averageLohDelta}</Box>
              }
          ]
        : []),
    {
        Header: 'Settings',
        accessor: 'etc',
        identifier: 'etc',
        visibilityKey: 'etc',
        sticky: 'right',
        columnWidth: '58px',
        disableFilters: true,
        Cell: ({ cell: { row } }) => (
            <Advisories
                readOnly={readOnly || isFilterSet}
                row={row}
                reportFilters={reportFilters}
            />
        ),
        disableSortBy: true
    }
];

export const shipperHeader = ({
    reportFilters,
    checked,
    handleCheckbox,
    readOnly,
    isFilterSet,
    handleAllCheckbox,
    disableRow,
    compare_run,
    isOrgTypeShipper,
    loadFilters,
    hideTrueProfit,
    odpt4098HideOnTimeKpi,
    odpt4173HideCoverageKpi
}) => {
    return [
        {
            Header: (cell) => {
                return (
                    <ODCheckbox
                        lightMode={true}
                        onClick={(e) => handleAllCheckbox(e, cell?.page, 'shipper')}
                        checked={checked.allShippers || disableRow}
                        indeterminate={
                            checked.selectedShippers.length > 0 &&
                            checked.selectedShippers.length !== reportFilters?.shippers?.length
                        }
                        disabled={disableRow}
                    />
                );
            },

            accessor: 'checkbox',
            identifier: 'checkbox',
            visibilityKey: 'checkbox',
            sticky: 'left',
            columnWidth: '34px',
            disableFilters: true,
            Cell: (cell) =>
                cell.row.original.isRemoved ? (
                    <RemoveSharpIcon
                        sx={{
                            color: theme.palette.neutral.neutral3,
                            padding: '0px'
                        }}
                    />
                ) : (
                    <ODCheckbox
                        lightMode={true}
                        sx={{ padding: 0 }}
                        onClick={(e) =>
                            handleCheckbox(e, cell.row.original.id, 'shipper', cell.filteredRows)
                        }
                        inputProps={{
                            'aria-label': `selected ${cell.row.name}`,
                            sx: { padding: 0 }
                        }}
                        disabled={disableRow}
                        checked={checked.selectedShippers?.includes(cell.row.original.id)}
                    />
                ),
            disableSortBy: true
        },
        {
            Header: isOrgTypeShipper ? 'Customer ID' : 'Shipper ID',
            accessor: 'id',
            identifier: 'id',
            visibilityKey: 'id',
            applyGlobalFilter: true,
            sticky: 'left',
            Filter: DropdownFilter,
            filter: 'text',
            Cell: ({ value, cell: { row } }) => {
                const isBonusApplied = row?.original?.isBonusApplied?.value?.value;

                return (
                    <>
                        {!loadFilters.isFilterSet ? (
                            <StyledSpan hasPointer={true}>
                                {isBonusApplied && (
                                    <Tooltip title="Bonus Applied">
                                        <StarsIcon sx={{ marginRight: '8px' }} />
                                    </Tooltip>
                                )}

                                <Tooltip placement="right" title={value}>
                                    <span>{value}</span>
                                </Tooltip>
                            </StyledSpan>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                {isBonusApplied && (
                                    <Tooltip title="Bonus Applied">
                                        <StarsIcon sx={{ marginRight: '8px' }} />
                                    </Tooltip>
                                )}
                                <Tooltip placement="right" title={value}>
                                    <span>{value}</span>
                                </Tooltip>
                            </Box>
                        )}
                    </>
                );
            }
        },
        ...sharedColumns(
            compare_run,
            isOrgTypeShipper,
            disableRow,
            readOnly,
            isFilterSet,
            reportFilters,
            hideTrueProfit,
            odpt4098HideOnTimeKpi,
            odpt4173HideCoverageKpi
        )
    ];
};

export const laneHeader = ({
    reportFilters,
    checked,
    handleCheckbox,
    readOnly,
    handleAllCheckbox,
    disableRow,
    compare_run,
    isOrgTypeShipper,
    isFilterSet,
    loadFilters,
    hideTrueProfit,
    odpt4098HideOnTimeKpi,
    odpt4173HideCoverageKpi
}) => {
    return [
        {
            Header: (cell) => {
                return (
                    <ODCheckbox
                        lightMode={true}
                        onClick={(e) => handleAllCheckbox(e, cell?.page, 'lane')}
                        checked={checked.allLanes || disableRow}
                        indeterminate={
                            checked.selectedLanes.length > 0 &&
                            checked.selectedLanes.length !== reportFilters?.lanes?.length
                        }
                        disabled={disableRow}
                    />
                );
            },
            accessor: 'checkbox',
            identifier: 'checkbox',
            visibilityKey: 'checkbox',
            disableFilters: true,
            sticky: 'left',
            columnWidth: '34px',
            Cell: (cell) =>
                cell.row.original.isRemoved ? (
                    <RemoveSharpIcon
                        sx={{
                            color: theme.palette.neutral.neutral3,
                            padding: '0px'
                        }}
                    />
                ) : (
                    <ODCheckbox
                        lightMode={true}
                        sx={{ padding: 0 }}
                        onClick={(e) =>
                            handleCheckbox(e, cell.row.original.id, 'lane', cell.filteredRows)
                        }
                        disabled={disableRow}
                        checked={checked.selectedLanes?.includes(cell.row.original.id)}
                    />
                ),
            disableSortBy: true
        },
        {
            Header: 'Pick Up',
            accessor: 'pickUp',
            applyGlobalFilter: true,
            identifier: 'pickUp',
            visibilityKey: 'pickUp',
            sticky: 'left',
            Filter: DropdownFilter,
            filter: 'text',
            sortType: 'basic',
            Cell: ({ value, cell: { row } }) => {
                const isBonusApplied = row?.original?.isBonusApplied?.value?.value;

                return (
                    <>
                        {!loadFilters.isFilterSet ? (
                            <StyledSpan hasPointer={true}>
                                {isBonusApplied && (
                                    <Tooltip title="Bonus Applied">
                                        <StarsIcon sx={{ marginRight: '8px' }} />
                                    </Tooltip>
                                )}
                                {formatLaneLocation({
                                    value,
                                    tooltip: row.original.pickUpCityStateZip
                                })}
                            </StyledSpan>
                        ) : (
                            <>
                                {isBonusApplied && (
                                    <Tooltip title="Bonus Applied">
                                        <StarsIcon sx={{ marginRight: '8px' }} />
                                    </Tooltip>
                                )}
                                {formatLaneLocation({
                                    value,
                                    tooltip: row.original.pickUpCityStateZip
                                })}
                            </>
                        )}
                    </>
                );
            }
        },
        {
            Header: 'Drop Off',
            accessor: 'dropOff',
            identifier: 'dropOff',
            visibilityKey: 'dropOff',
            applyGlobalFilter: true,
            sticky: 'left',
            Filter: DropdownFilter,
            filter: 'text',
            sortType: 'basic',
            Cell: ({ value, cell: { row } }) =>
                !loadFilters.isFilterSet ? (
                    <StyledSpan hasPointer={true}>
                        {formatLaneLocation({
                            value,
                            tooltip: row.original.dropOffCityStateZip
                        })}
                    </StyledSpan>
                ) : (
                    formatLaneLocation({ value, tooltip: row.original.dropOffCityStateZip })
                )
        },
        ...sharedColumns(
            compare_run,
            isOrgTypeShipper,
            disableRow,
            readOnly,
            isFilterSet,
            reportFilters,
            hideTrueProfit,
            odpt4098HideOnTimeKpi,
            odpt4173HideCoverageKpi
        )
    ];
};
