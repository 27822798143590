import { updateConfiguration } from 'redux/actions';

export const handleIncrement = (type: any, onUpdate: any, total?: number) => {
    const currTotal = total || total === 0 ? total : type?.total;
    let count = Number(currTotal);
    count += 1;
    onUpdate(count, type);
};

export const handleDecrement = (type: any, onUpdate: any, minLimit = 1, total?: number) => {
    const currTotal = total || total === 0 ? total : type?.total;
    if (currTotal <= -minLimit) {
        return;
    } else {
        let count = Number(currTotal);
        count -= 1;
        onUpdate(count, type);
    }
};

export const addDomicileConfig = async (
    config: { location: any; isNew: any; domicile_id: any; driver_type: any; quantity: any },
    simulationId: any,
    newSimulationFlow: boolean,
    allowAddDrivers: boolean,
    dispatch: any,
    currReportId: any
) => {
    if (!newSimulationFlow && !allowAddDrivers) return;
    let actions = [];
    if (config) {
        actions.push({
            type: 'add',
            item_type: 'driver',
            value: {
                quantity: config.quantity,
                location: config.location,
                isNew: config.isNew || false,
                domicile: config.domicile_id,
                driver_type: config.driver_type
            }
        });
    }

    await dispatch(updateConfiguration(currReportId, simulationId, actions));
};
