import React from 'react';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material/';
import theme from 'theme';

const ProgressStatus = ({ value }) => {
    let floatedVal = parseFloat(value);

    if (floatedVal === 0) return;
    if (floatedVal > 0) {
        return (
            <ArrowUpward
                style={{
                    color: theme.palette.semantic.semanticGreen,
                    position: 'absolute',
                    paddingTop: '4px',
                    marginLeft: '-25px'
                }}
            />
        );
    } else {
        return (
            <ArrowDownward
                style={{
                    color: theme.palette.semantic.semanticRed,
                    position: 'absolute',
                    paddingTop: '6px',
                    marginLeft: '-25px'
                }}
            />
        );
    }
};

export default ProgressStatus;
