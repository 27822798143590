/* eslint-disable no-sparse-arrays */
import React from 'react';
import { Box, IconButton, Tooltip, capitalize } from '@mui/material';
import ODDonutChart from 'shared/ODDonutChart';
import theme from 'theme';
import styled from '@emotion/styled';
import Moment from 'react-moment';
import DriverAdvisories from './DriverAdvisories';
import CircleIcon from '@mui/icons-material/Circle';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import SubjectIcon from '@mui/icons-material/Subject';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DriverHomeIcon } from 'assets/images';
import {
    convertNumberToCommas,
    compareNumericString,
    abbreviateNumber,
    formatDelta,
    formatTwoDecimals
} from 'utils/number.util';
import AdditionalDriversDom from './AdditionalDriversDom';
import AdditionalDriversDriverType from './AdditionalDriversDriverType';
import DriverDonut from './DriverDonut';
import { DRIVER_MODE } from 'constants/drivers';
import { formatCityState } from 'utils/common.util';
import ODCheckbox from 'shared/Checkbox';
import {
    DropdownFilter,
    DateTimeFilter,
    minMaxFilter,
    dateTimeFilter,
    NumberRangeFilter
} from 'shared/Table/tableFilters';
import { CompareHeaderText } from 'utils/table.util';

const settingsProps = {
    Header: 'Settings',
    accessor: 'etc',
    identifier: 'etc',
    visibilityKey: 'etc',
    sticky: 'right',
    columnWidth: '58px',
    disableSortBy: true
};
const formatMoveTypeLocation = (value) => {
    return (
        <Tooltip title={value} placement="right">
            <Box
                sx={{
                    display: 'flex',
                    width: '100%'
                }}>
                {formatCityState(value, 'calc(100% - 50px)')}
            </Box>
        </Tooltip>
    );
};

const StyledBox = styled('Box')(({ hasPointer }) => ({
    textDecoration: 'underline',
    cursor: hasPointer ? 'pointer' : 'auto',
    display: 'flex'
}));

const checkboxColumnDef = (
    checked,
    disableRow,
    handleAllCheckbox,
    handleCheckbox,
    allVarName,
    selectedVarName,
    itemIdName,
    mode
) => ({
    Header: (cell) => (
        <ODCheckbox
            lightMode={true}
            onClick={(e) => handleAllCheckbox(e, cell?.column.filteredRows)}
            checked={
                checked[allVarName] ||
                checked[selectedVarName].length === cell?.filteredRows.length ||
                disableRow
            }
            indeterminate={
                checked[selectedVarName].length > 0 &&
                checked[selectedVarName].length !== cell?.filteredRows.length
            }
            disabled={disableRow}
        />
    ),
    accessor: 'checkbox',
    identifier: 'checkbox',
    visibilityKey: 'checkbox',
    sticky: 'left',
    columnWidth: '34px',
    disableFilters: true,
    Cell: ({ cell: { row } }) =>
        row.original.isRemoved ? (
            <RemoveSharpIcon
                sx={{
                    color: theme.palette.neutral.white,
                    padding: 0
                }}
            />
        ) : (
            <ODCheckbox
                lightMode={true}
                sx={{ padding: 0 }}
                onClick={(e) => handleCheckbox(e, row.original[itemIdName], mode)}
                inputProps={{
                    'aria-label': `selected ${row.name}`,
                    sx: { padding: 0 }
                }}
                checked={
                    checked[allVarName] ||
                    checked[selectedVarName]?.includes(row.original[itemIdName])
                }
                disabled={disableRow}
            />
        ),
    disableSortBy: true
});

const totalDriversColumn = (props) => {
    const { compare_run, isTopTable, driverTypesColors } = props;
    return {
        Header: 'Total Drivers',
        accessor: compare_run ? 'compare_total_drivers' : 'total_drivers',
        identifier: 'total_drivers',
        visibilityKey: 'total_drivers',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        sortType: compareNumericString,
        Cell: ({ cell: { row } }) => {
            //TODO refactor
            // Donut chart logic
            const {
                total_drivers,
                compare_total_drivers,
                domicile_id,
                driver_types,
                compare_driver_types,
                index
            } = row?.original;
            const _driverTypes = compare_run ? compare_driver_types : driver_types;
            const colors = [];
            let percentages = [];
            let currTotal = compare_run ? compare_total_drivers : total_drivers;

            _driverTypes?.forEach((driverType) => {
                const type = driverType?.driver_type;
                if (driverTypesColors[type]) colors.push(driverTypesColors[type]);
            });

            _driverTypes?.map((driver_type) =>
                percentages.push((driver_type.count / currTotal) * 100)
            );

            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                    <ODDonutChart
                        percentages={percentages}
                        colors={colors}
                        light={true}
                        index={`driver-count-${domicile_id}`}
                    />
                    {!isTopTable && !compare_run ? (
                        <StyledBox hasPointer={true}> {row?.original?.totalDrivers}</StyledBox>
                    ) : (
                        row?.original?.totalDrivers
                    )}
                    <AdditionalDriversDom domicile_id={domicile_id} index={index} />
                </Box>
            );
        }
    };
};
const totalDriversDeltaCol = (props) => {
    return {
        Header: <CompareHeaderText text={'Total Drivers'} />,
        accessor: 'deltaTotalDrivers',
        identifier: 'deltaTotalDrivers',
        visibilityKey: 'total_drivers',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        isDelta: true,
        sortType: compareNumericString,
        Cell: ({ value }) => {
            return formatDelta(value, null, null, null);
        }
    };
};
const trueProfitColumn = (props) => {
    const { isOrgTypeShipper, compare_run, accessor } = props;
    return {
        Header: isOrgTypeShipper ? 'True Savings ($)' : 'True Profit ($)',
        sortType: compareNumericString,
        accessor: accessor,
        identifier: 'true_profit',
        visibilityKey: 'true_profit',
        abbreviated: true,
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        Cell: ({ row }) => {
            return (
                <Box>
                    {!compare_run
                        ? `$${abbreviateNumber(row?.original?.trueProfit)}`
                        : row?.original?.trueProfit}
                </Box>
            );
        }
    };
};
const revDriverPerWeekColumn = (props) => {
    const { compare_run, accessor } = props;
    return {
        Header: 'Revenue/Driver/Week',
        sortType: compareNumericString,
        accessor: accessor,
        identifier: 'avg_revenue_driver_per_week',
        visibilityKey: 'avg_revenue_driver_per_week',
        abbreviated: true,
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        Cell: ({ row }) => {
            return (
                <Box>
                    {!compare_run
                        ? `$${abbreviateNumber(row?.original?.avgRevDriverPerWeek)}`
                        : row?.original?.avgRevDriverPerWeek}
                </Box>
            );
        }
    };
};
const trueProfitDeltaColumn = (props) => {
    const { isOrgTypeShipper } = props;
    return {
        Header: (
            <CompareHeaderText text={isOrgTypeShipper ? 'True Savings ($)' : 'True Profit ($)'} />
        ),
        accessor: 'deltaTrueProfit',
        identifier: 'deltaTrueProfit',
        visibilityKey: 'true_profit',
        abbreviated: true,
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        isDelta: true,
        columnWidth: '136px',
        sortType: compareNumericString,
        Cell: ({ row, value }) => {
            return formatDelta(value, 'dollar', true, null);
        }
    };
};

const revDriverPerWeekDeltaColumn = (props) => {
    return {
        Header: (
            <CompareHeaderText text='Revenue/Driver/Week' />
        ),
        accessor: 'deltaAvgRevDriverPerWeek',
        identifier: 'deltaAvgRevDriverPerWeek',
        visibilityKey: 'avg_revenue_driver_per_week',
        abbreviated: true,
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        isDelta: true,
        columnWidth: '136px',
        sortType: compareNumericString,
        Cell: ({ row, value }) => {
            return formatDelta(value, 'dollar', true, null);
        }
    };
};
const milesPerWeekColumn = (props) => {
    const { compare_run, accessor } = props;
    return {
        Header: 'Avg. Miles /Week',
        sortType: compareNumericString,
        accessor: accessor,
        identifier: 'miles_per_week',
        visibilityKey: 'miles_per_week',
        columnWidth: '132px',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        Cell: ({ value, row }) => {
            return (
                <Box>
                    {!compare_run
                        ? `${convertNumberToCommas(row?.original?.avgMilesPerWeek)}`
                        : row?.original?.avgMilesPerWeek}
                </Box>
            );
        }
    };
};
const milesPerWeekDeltaColumn = (props) => {
    return {
        Header: <CompareHeaderText text={'Avg. Miles /Week'} />,
        accessor: 'deltaAvgMilesPerWeek',
        identifier: 'deltaAvgMilesPerWeek',
        visibilityKey: 'miles_per_week',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        isDelta: true,
        columnWidth: '158px',
        sortType: compareNumericString,
        Cell: ({ value }) => {
            return formatDelta(value, null, null, null);
        }
    };
};
const emptyMilesColumn = (props) => {
    const { compare_run, accessor } = props;
    return {
        Header: 'Empty Miles (%)',
        sortType: compareNumericString,
        accessor: accessor,
        identifier: 'empty_miles_percentage',
        visibilityKey: 'empty_miles_percentage',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        Cell: ({ value, row }) => {
            return (
                <Box>
                    {!compare_run
                        ? `${convertNumberToCommas(row?.original?.emptyMiles)}%`
                        : row?.original?.emptyMiles}
                </Box>
            );
        }
    };
};
const emptyMilesDeltaColumn = (props) => {
    return {
        Header: <CompareHeaderText text={'Empty Miles (%)'} />,
        accessor: 'deltaEmptyMiles',
        identifier: 'deltaEmptyMiles',
        visibilityKey: 'empty_miles_percentage',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        isDelta: true,
        columnWidth: '158px',
        sortType: compareNumericString,
        Cell: ({ value }) => {
            return formatDelta(value, 'percentage', null, null);
        }
    };
};
const hoursAtHomeColumn = (props) => {
    const { compare_run, accessor } = props;
    return {
        Header: 'Hours at Home/Week',
        sortType: compareNumericString,
        accessor: accessor,
        identifier: 'time_at_home_events',
        visibilityKey: 'time_at_home_events',
        columnWidth: '160px',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        Cell: ({ value, row }) => {
            return (
                <Box>
                    {!compare_run
                        ? `${convertNumberToCommas(row?.original?.timeAtHome)}`
                        : row?.original?.timeAtHome}
                </Box>
            );
        }
    };
};
const deltaHoursAtHomeColumn = (props) => {
    return {
        Header: <CompareHeaderText text={'Hours at Home/Week'} />,
        accessor: 'deltaTimeAtHome',
        identifier: 'deltaTimeAtHome',
        visibilityKey: 'time_at_home_events',
        Filter: NumberRangeFilter,
        filter: minMaxFilter,
        formattingFunction: formatTwoDecimals,
        isDelta: true,
        columnWidth: '186px',
        sortType: compareNumericString,
        Cell: ({ value }) => {
            return formatDelta(value, null, null, 2);
        }
    };
};
const settingsColumn = (props) => {
    const { isAggregated = false, readOnly, isTopTable } = props;
    return {
        ...settingsProps,
        Cell: ({ cell: { row } }) => (
            <DriverAdvisories
                isAggregated={isAggregated}
                readOnly={readOnly || isTopTable}
                row={row}
            />
        )
    };
};
export const domicileCol = (props) => {
    const {
        checked,
        handleCheckbox,
        isTopTable,
        disableRow,
        compare_run,
        aggregationLevel,
        handleAllCheckbox,
        hideTrueProfit,
        driverViewDataFromKpiAnalytics
    } = props;
    return [
        checkboxColumnDef(
            checked,
            disableRow,
            handleAllCheckbox,
            handleCheckbox,
            'allDomiciles',
            'selectedDomicile',
            'domicile_id',
            DRIVER_MODE.DOMICILE
        ),
        {
            Header: 'Domicile',
            accessor: 'domicileName',
            identifier: 'domicileName',
            visibilityKey: 'domicileName',
            applyGlobalFilter: true,
            sticky: 'left',
            Filter: DropdownFilter,
            filter: 'text',
            Cell: ({ row, value }) => {
                return !isTopTable ? (
                    <StyledBox hasPointer={true}>
                        {value === 'all' && aggregationLevel === 0 ? (
                            'All'
                        ) : (
                            <Tooltip title={row.original.domicileCityStateZip ?? ''}>
                                <Box>{formatCityState(value, '84px', !isNaN(value))}</Box>
                            </Tooltip>
                        )}
                    </StyledBox>
                ) : (
                    <Box display="flex">
                        {value === 'all' && aggregationLevel === 0 ? (
                            'All'
                        ) : (
                            <Tooltip title={row.original.domicileCityStateZip ?? ''}>
                                <Box>{formatCityState(value, '84px', !isNaN(value))}</Box>
                            </Tooltip>
                        )}
                    </Box>
                );
            }
        },
        totalDriversColumn(props), // TODO should be able to use this in driver types table as well. It's possible we only need to modify the color logic so that the shared col def works for both the domiciles and driver types tables.
        ...(compare_run ? [totalDriversDeltaCol(props)] : []),
        ...(hideTrueProfit
            ? []
            : [
                  trueProfitColumn({
                      ...props,
                      accessor: compare_run ? 'compare_true_profit' : 'true_profit'
                  })
              ]),
        ...(compare_run ? (hideTrueProfit ? [] : [trueProfitDeltaColumn(props)]) : []),
        ...(driverViewDataFromKpiAnalytics
            ? [
                revDriverPerWeekColumn({
                      ...props,
                      accessor: compare_run ? 'compare_avg_revenue_driver_per_week' : 'avg_revenue_driver_per_week'
                  })
              ]
            : []),
        ...(compare_run ? (driverViewDataFromKpiAnalytics ? [revDriverPerWeekDeltaColumn(props)] : []) : []),
        milesPerWeekColumn({
            ...props,
            accessor: compare_run ? 'compare_miles_per_week' : 'miles_per_week'
        }),
        ...(compare_run ? [milesPerWeekDeltaColumn(props)] : []),
        emptyMilesColumn({
            ...props,
            accessor: compare_run ? 'compare_empty_miles_percentage' : 'empty_miles_percentage'
        }),
        ...(compare_run ? [emptyMilesDeltaColumn(props)] : []),
        hoursAtHomeColumn({
            ...props,
            accessor: compare_run ? 'compare_time_at_home_events' : 'time_at_home_events'
        }),
        ...(compare_run ? [deltaHoursAtHomeColumn(props)] : []),
        settingsColumn(props)
    ];
};
export const driverTypeCol = (props) => {
    const {
        checked,
        handleCheckbox,
        isTopTable,
        disableRow,
        compare_run,
        handleAllCheckbox,
        hideTrueProfit,
        driverViewDataFromKpiAnalytics
    } = props;
    return [
        checkboxColumnDef(
            checked,
            disableRow,
            handleAllCheckbox,
            handleCheckbox,
            'allDriverTypes',
            'selectedDriverType',
            'id',
            DRIVER_MODE.DRIVER_TYPES
        ),
        {
            Header: 'Driver Type',
            accessor: 'id',
            identifier: 'id',
            visibilityKey: 'id',
            applyGlobalFilter: true,
            sticky: 'left',
            Filter: DropdownFilter,
            filter: 'text',
            Cell: ({ value }) => {
                return !isTopTable ? (
                    <StyledBox hasPointer={true}>{value}</StyledBox>
                ) : (
                    <Box>{value}</Box>
                );
            }
        },
        {
            Header: 'Total Drivers',
            accessor: compare_run ? 'compare_total_drivers' : 'total_drivers',
            identifier: 'total_drivers',
            visibilityKey: 'total_drivers',
            Filter: NumberRangeFilter,
            filter: minMaxFilter,
            sortType: compareNumericString,
            Cell: ({ value, cell: { row } }) => {
                const { actionCount, totalDrivers, index } = row?.original;
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                        }}>
                        <DriverDonut row={row} compare_run={compare_run} />
                        {!isTopTable && !compare_run ? (
                            <StyledBox hasPointer={true}> {totalDrivers}</StyledBox>
                        ) : (
                            totalDrivers
                        )}
                        <AdditionalDriversDriverType actionCount={actionCount} index={index} />
                    </Box>
                );
            }
        },
        ...(compare_run ? [totalDriversDeltaCol(props)] : []),
        ...(hideTrueProfit
            ? []
            : [
                  trueProfitColumn({
                      ...props,
                      accessor: compare_run ? 'compare_true_profit_amount' : 'true_profit_amount'
                  })
              ]),
        ...(compare_run ? (hideTrueProfit ? [] : [trueProfitDeltaColumn(props)]) : []),
        ...(driverViewDataFromKpiAnalytics
            ? [
                  revDriverPerWeekColumn({
                      ...props,
                      accessor: compare_run ? 'compare_avg_revenue_driver_per_week' : 'avg_revenue_driver_per_week'
                  })
              ]
            : []),
        ...(compare_run ? (driverViewDataFromKpiAnalytics ? [revDriverPerWeekDeltaColumn(props)] : []) : []),
        milesPerWeekColumn({
            ...props,
            accessor: compare_run ? 'compare_miles_per_week' : 'miles_per_week'
        }),
        ...(compare_run ? [milesPerWeekDeltaColumn(props)] : []),
        emptyMilesColumn({
            ...props,
            accessor: compare_run ? 'compare_empty_miles_percentage' : 'empty_miles_percentage'
        }),
        ...(compare_run ? [emptyMilesDeltaColumn(props)] : []),
        hoursAtHomeColumn({
            ...props,
            accessor: compare_run ? 'compare_time_at_home_events' : 'time_at_home_events'
        }),
        ...(compare_run ? [deltaHoursAtHomeColumn(props)] : []),
        settingsColumn(props)
    ];
};
export const driverIdCol = (props) => {
    const {
        checked,
        handleCheckbox,
        isTopTable,
        disableRow,
        movementsTableView,
        handleAllCheckbox,
        hideTrueProfit,
        driverViewDataFromKpiAnalytics
    } = props;
    return [
        checkboxColumnDef(
            checked,
            disableRow,
            handleAllCheckbox,
            handleCheckbox,
            'allDriverIds',
            'selectedDriverId',
            'name',
            DRIVER_MODE.DRIVER_ID
        ),
        {
            Header: 'Driver',
            accessor: 'name',
            identifier: 'name',
            visibilityKey: 'name',
            applyGlobalFilter: true,
            sticky: 'left',
            Filter: DropdownFilter,
            filter: 'text',
            Cell: ({ value }) => {
                return movementsTableView && !isTopTable ? (
                    <StyledBox hasPointer={true}>{value}</StyledBox>
                ) : (
                    <Box>{value}</Box>
                );
            }
        },
        {
            Header: 'Load Count',
            accessor: 'load_count',
            identifier: 'load_count',
            visibilityKey: 'load_count',
            Filter: NumberRangeFilter,
            filter: minMaxFilter,
            sortType: compareNumericString,
            Cell: ({ value }) => {
                return <Box>{value}</Box>;
            }
        },

        ...(hideTrueProfit ? [] : [trueProfitColumn({ ...props, accessor: 'true_profit_amount' })]),

        ...(driverViewDataFromKpiAnalytics ? [revDriverPerWeekColumn({ ...props, accessor: 'avg_revenue_driver_per_week' })] : []),

        {
            Header: 'Avg. LOH',
            accessor: 'average_loh',
            identifier: 'average_loh',
            visibilityKey: 'average_loh',
            Filter: NumberRangeFilter,
            filter: minMaxFilter,
            formattingFunction: formatTwoDecimals,
            sortType: compareNumericString,
            Cell: ({ value }) => {
                return <Box>{value}</Box>;
            }
        },
        milesPerWeekColumn({ ...props, accessor: 'miles_per_week' }),
        emptyMilesColumn({ ...props, accessor: 'empty_miles_percentage' }),
        hoursAtHomeColumn({ ...props, accessor: 'total_time_at_home' }),
        // {
        //   Header: "Home on Time (%)",
        //   accessor: "home_on_time",
        //   Cell: ({ value, row }) => {
        //     return <Box sx={{ width: "140px" }}>{value}%</Box>;
        //   },
        // },
        settingsProps
    ];
};
export const driverMovesCol = ({ handleAction, isListView }) => {
    return [
        {
            Header: 'Load ID',
            accessor: 'move_id',
            identifier: 'move_id',
            visibilityKey: 'move_id',
            Filter: DropdownFilter,
            filter: 'text',
            columnWidth: '180px',
            Cell: ({ value }) => {
                return <Box>{value}</Box>;
            }
        },
        {
            Header: 'Start Move Type',
            accessor: 'load_type',
            identifier: 'load_type',
            visibilityKey: 'load_type',
            Filter: DropdownFilter,
            filter: 'text',
            Cell: ({ value, row }) => {
                return (
                    <Box
                        sx={{
                            display: 'flex'
                        }}>
                        <Tooltip title={capitalize(value)}>
                            <CircleIcon
                                sx={{
                                    color:
                                        row?.original.load_type === 'empty'
                                            ? theme.palette.neutral.neutral4
                                            : theme.palette.semantic.semanticGreen,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '9.5px'
                                }}
                            />
                        </Tooltip>

                        {capitalize(value)}
                    </Box>
                );
            }
        },
        {
            Header: (
                <>
                    {!isListView ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <Box sx={{ marginLeft: '0.5rem' }}>Pick Up</Box>
                            <ArrowForwardIcon
                                sx={{
                                    color: theme.palette.ODBlue.odBlue7
                                }}
                            />
                        </Box>
                    ) : (
                        <Box>Start Move Location</Box>
                    )}
                </>
            ),
            accessor: 'load_pickup_location',
            headerText: !isListView ? 'Pick Up' : 'Start Move Location',
            identifier: 'load_pickup_location',
            visibilityKey: 'load_pickup_location',
            Filter: DropdownFilter,
            filter: 'text',
            columnWidth: '180px',
            Cell: ({ value, cell: { row } }) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                            {!isListView && (
                                <Tooltip title={capitalize(row?.original.load_type)}>
                                    <CircleIcon
                                        sx={{
                                            color:
                                                row?.original.load_type === 'empty'
                                                    ? theme.palette.neutral.neutral4
                                                    : theme.palette.semantic.semanticGreen,
                                            width: '15px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: '9.5px'
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {formatMoveTypeLocation(value, isListView)}
                        </Box>

                        {!isListView && (
                            <ArrowForwardIcon
                                fontSize="small"
                                sx={{
                                    color: theme.palette.ODBlue.odBlue7
                                }}
                            />
                        )}
                    </Box>
                );
            }
        },
        {
            Header: 'Start Move Date & Time',
            accessor: 'pickup_date_time',
            identifier: 'pickup_date_time',
            visibilityKey: 'pickup_date_time',
            Filter: DateTimeFilter,
            filter: dateTimeFilter,
            columnWidth: '245px',
            Cell: ({ value }) => {
                return (
                    <Box>
                        <Moment format="MM/DD">{value}</Moment>{' '}
                        <Moment format="hh:mm a">{value}</Moment>
                    </Box>
                );
            }
        },
        {
            Header: 'End Move Type',
            accessor: 'move_type',
            identifier: 'move_type',
            visibilityKey: 'move_type',
            Filter: DropdownFilter,
            filter: 'text',
            columnWidth: '130px',
            Cell: ({ value, row }) => {
                const isEmpty = row?.original?.move_type === 'Empty';
                const isHome = !isEmpty && row?.original?.is_thru_home === true;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%'
                        }}>
                        {isHome ? (
                            <Box
                                sx={{
                                    width: '15px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '9.5px'
                                }}>
                                <HomeSharpIcon
                                    sx={{
                                        color: isEmpty
                                            ? theme.palette.neutral.neutral4
                                            : theme.palette.semantic.semanticGreen
                                    }}
                                />
                            </Box>
                        ) : (
                            <CircleIcon
                                sx={{
                                    color: isEmpty
                                        ? theme.palette.neutral.neutral4
                                        : theme.palette.semantic.semanticGreen,
                                    width: '15px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '9.5px'
                                }}
                            />
                        )}
                        <Tooltip title={value} placement="right">
                            <Box
                                sx={{
                                    width: '82px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                {value}
                            </Box>
                        </Tooltip>
                    </Box>
                );
            }
        },

        {
            Header: !isListView ? 'Drop Off' : 'End Move Location',
            disableSortBy: true,
            accessor: 'load_drop_off_location',
            identifier: 'load_drop_off_location',
            visibilityKey: 'load_drop_off_location',
            Filter: DropdownFilter,
            filter: 'text',
            columnWidth: '180px',
            Cell: ({ value, cell: { row } }) => {
                let isHome = row?.original.is_home;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                        {!isListView && (
                            <Tooltip title={capitalize(row?.original.load_type)} placement="left">
                                {isHome ? (
                                    <DriverHomeIcon />
                                ) : (
                                    <CircleIcon
                                        sx={{
                                            color:
                                                row?.original.load_type === 'empty'
                                                    ? theme.palette.neutral.neutral4
                                                    : theme.palette.semantic.semanticGreen,
                                            width: '15px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: '9.5px'
                                        }}
                                    />
                                )}
                            </Tooltip>
                        )}

                        {formatMoveTypeLocation(value, isListView)}
                    </Box>
                );
            }
        },
        {
            Header: 'End Move Date & Time',
            accessor: 'delivery_date_time',
            identifier: 'delivery_date_time',
            visibilityKey: 'delivery_date_time',
            Filter: DateTimeFilter,
            filter: dateTimeFilter,
            columnWidth: '245px',
            Cell: ({ value }) => {
                return (
                    <Box>
                        <Moment format="MM/DD">{value}</Moment>{' '}
                        <Moment format="hh:mm a">{value}</Moment>
                    </Box>
                );
            }
        },
        {
            ...settingsProps,
            disableFilters: true,
            Cell: ({ cell: { row } }) => (
                <IconButton
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: theme.palette.neutral.neutral1,
                        minWidth: 0,
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        left: '1px',
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral1
                        }
                    }}
                    onClick={() => handleAction(row?.original)}>
                    <SubjectIcon
                        style={{
                            fill: theme.palette.black
                        }}
                    />
                </IconButton>
            )
        }
    ];
};
