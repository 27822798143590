import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import theme from 'theme';

const useStyles = () =>
    makeStyles((_theme) => {
        return {
            verticalAlignCenter: {
                display: 'flex',
                alignItems: 'center'
            },
            headerTitle: {
                // paddingBottom: 5,
            }
        };
    });

const CommonHeaderContainer = ({
    header = '',
    headerIcon = '',
    endComponent = '',
    children = '',
    noPadding = false,
    headerStyle = {},
    justifyContent = 'space-between',
    onHover,
    noBorder,
    isNetworkManager = false,
    ...props
}) => {
    const classes = useStyles({
        noBorder,
        noPadding
    })();

    return (
        <div
            style={{
                height: 56,
                paddingRight: noPadding ? 0 : 16,
                paddingLeft: noPadding ? 0 : 16,
                fontWeight: 600,
                fontSize: 14,
                backgroundColor: theme.palette.white,
                color: theme.palette.black,
                borderBottom: noBorder
                    ? null
                    : isNetworkManager
                    ? `1px solid ${theme.palette.neutral.neutral1}`
                    : `1px solid ${theme.palette.neutral.neutral1}`,
                ...headerStyle
            }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent={justifyContent}
                {...props}
                style={{ height: '100%', width: '100%' }}>
                {header && (
                    <Grid item className={classes.verticalAlignCenter}>
                        {headerIcon && (
                            <span
                                style={{
                                    display: 'flex',
                                    paddingRight: 16,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                {headerIcon}
                            </span>
                        )}
                        <span className={classes.headerTitle}> {header}</span>
                    </Grid>
                )}
                {endComponent && (
                    <Grid item className={classes.verticalAlignCenter}>
                        {endComponent}
                    </Grid>
                )}
                {children}
            </Grid>
        </div>
    );
};
export default CommonHeaderContainer;
