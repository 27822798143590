import { ODInputWithAdornments } from 'shared';
import React, { useState } from 'react';
import { Accordion, AccordionSummary, Box, Collapse, Typography } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import theme from 'theme';
import CircleIcon from '@mui/icons-material/Circle';
import { handleIncrement, handleDecrement } from './drivers.utils';

const DriverTypeAccordion = ({ type, driverTypesColors, updateTotalForCurrTypes, key }: any) => {
    const [openDriverTypeDrawer, setOpenDriverTypeDrawer] = useState({} as any);
    const onUpdate = (newActionCount: any, type?: any) => {
        let total = 0;
        if (newActionCount || newActionCount.length > 0) {
            total = parseInt(newActionCount);
        }
        updateTotalForCurrTypes(type, total);
    };

    const sum = (type?.actionCount || 0) + (type?.count || 0);

    return (
        <>
            <Accordion
                sx={{
                    '&:before': {
                        backgroundColor: 'transparent'
                    }
                }}
                key={`min-load-count-accordion-${key}`}
                expanded={openDriverTypeDrawer[type.id]}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e) => {
                    let updated = { ...openDriverTypeDrawer };
                    updated[type.id] = !openDriverTypeDrawer[type.id];

                    setOpenDriverTypeDrawer(updated);
                }}>
                <AccordionSummary
                    sx={{
                        backgroundColor: theme.palette.neutral.neutral1,
                        borderRadius: '4px',
                        marginTop: '1px'
                    }}
                    expandIcon={
                        <KeyboardArrowDownSharp sx={{ color: theme.palette.neutral.black }} />
                    }
                    aria-controls="accordion-content"
                    id={`accordion`}>
                    <Box sx={{ display: 'flex', color: theme.palette.black }}>
                        <Box
                            sx={{
                                marginRight: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <CircleIcon sx={{ color: driverTypesColors[type?.driver_type] }} />
                        </Box>

                        <Box display={'flex'} alignItems="center">
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 600
                                }}>
                                Current {type?.driver_type} Count:
                            </Typography>

                            <Box sx={{ fontWeight: 400, marginLeft: '4px' }}>
                                {type?.count || 0}
                                {type?.actionCount ? (
                                    <>
                                        {' '}
                                        ({`${type?.actionCount >= 0 ? '+ ' : ''}`}{' '}
                                        {type?.actionCount ?? 0})
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </AccordionSummary>
            </Accordion>
            <Collapse
                in={openDriverTypeDrawer[type.id]}
                timeout="auto"
                unmountOnExit
                key={`select-min-load-count-collapse`}>
                <Box sx={{ margin: '16px 0 ' }}>
                    <Box>
                        <ODInputWithAdornments
                            inputLabel={`Enter ${type?.driver_type} Count`}
                            handleRemoveOnClick={(e) => {
                                handleDecrement(type, onUpdate, type.count, type?.actionCount);
                            }}
                            handleAddOnClick={(e) => {
                                handleIncrement(type, onUpdate, type?.actionCount);
                            }}
                            onChange={({ target: { value } }: any) => onUpdate(value)}
                            disabled={false}
                            value={sum}
                        />
                    </Box>
                </Box>
            </Collapse>
        </>
    );
};

export default DriverTypeAccordion;
