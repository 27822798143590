import { LOAD_SOURCE } from 'constants/network';
import {
    REPORT_TYPE_SUCCESS,
    REPORT_TYPE_FAILURE,
    CREATE_REPORT_SUCCESS,
    CREATE_REPORT_FAILURE,
    RESET_REPORT_ID,
    REPORT_PARAM_VERSION_FAILURE,
    REPORT_PARAM_VERSION_SUCCESS,
    REPORT_DETAIL_BY_ID_FAILURE,
    REPORT_DETAIL_BY_ID_SUCCESS,
    REPORT_NOTIFICATION_SUCCESS,
    REPORT_PARAMETER_SUCCESS,
    UPDATE_REPORTS_REDUCER,
    REPORT_DRIVER_TYPES_SUCCESS,
    REPORT_FILTER_SUCCESS,
    UPDATE_LOAD_SOURCE,
    SET_SELECTED_DRIVER_TYPES,
    SET_SELECTED_PICKUP_LOCATIONS,
    SET_SELECTED_DROPOFF_LOCATIONS,
    RESET_FILTERS,
    LOAD_FILTER_SUCCESS,
    UPDATE_FREIGHT_BONUS_APPLIED,
    UPDATE_APPLIED_FILTERS_COUNT,
    CLEAR_REPORT_DATA
} from '../constants/actionTypes';

const initialState = {
    reports: [],
    count: 0,
    reportTypes: [],
    reportParamVersions: [],
    reportError: false,
    reportId: 0,
    reportDetail: null,
    notificationDetails: [],
    parametersData: {
        data: { driver_types: [] }
    },
    loadFilters: {},
    filterBy: {},
    isFilterSet: false,
    reportFilters: [],
    loadSource: LOAD_SOURCE.ALL,
    selectedDriverTypes: [],
    selectedPickupLocations: [],
    selectedDropoffLocations: [],
    isFreightBonusApplied: 'all',
    appliedFiltersCount: 0
};

const updateLoadSource = (state, action) => {
    return {
        ...state,
        loadSource: action.loadSource
    };
};

const setSelectedDriverTypes = (state, action) => {
    return {
        ...state,
        selectedDriverTypes: action.selectedDriverTypes
    };
};

const setSelectedPickupLocations = (state, action) => {
    return {
        ...state,
        selectedPickupLocations: action.selectedPickupLocations
    };
};

const setSelectedDropoffLocations = (state, action) => {
    return {
        ...state,
        selectedDropoffLocations: action.selectedDropoffLocations
    };
};

const resetFilters = (state, action) => {
    return {
        ...state,
        selectedDriverTypes: initialState.selectedDriverTypes,
        selectedPickupLocations: initialState.selectedPickupLocations,
        selectedDropoffLocations: initialState.selectedDropoffLocations,
        loadSource: initialState.loadSource
    };
};
const updateAppliedFiltersCount = (state, action) => {
    return {
        ...state,
        appliedFiltersCount: action.appliedFiltersCount
    };
};

const updateFreightBonusApplied = (state, action) => {
    return {
        ...state,
        isFreightBonusApplied: action.isFreightBonusApplied
    };
};

const ReportTypeSuccess = (state, action) => {
    return {
        ...state,
        reportTypes: action.reportTypes
    };
};
const ReportTypeFailure = (state, action) => {
    return {
        ...state,
        reportTypes: [],
        error: action.error
    };
};

const CreateReportSuccess = (state, action) => {
    return {
        ...state,
        reportId: action.reportId
    };
};

const CreateReportFailure = (state, action) => {
    return {
        ...state,
        reportId: 0,
        error: action.error
    };
};
const ResetReportId = (state, action) => {
    return {
        ...state,
        reportId: 0,
        error: null
    };
};

const ParamVersionSuccess = (state, action) => {
    return {
        ...state,
        reportParamVersions: action.reportParamVersions
    };
};
const ParamVersionFailure = (state, action) => {
    return {
        ...state,
        reportParamVersions: [],
        error: action.error
    };
};

const ReportDetailByIdSuccess = (state, action) => {
    return {
        ...state,
        reportDetail: action.reportDetail
    };
};
const ReportDetailByIdFailure = (state, action) => {
    return {
        ...state,
        reportDetail: null,
        error: action.error
    };
};

const ReportSuccess = (state, action) => {
    return {
        ...state,
        reportError: false,
        ...action
    };
};

const clearReportData = () => initialState;

const ReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_TYPE_SUCCESS:
            return ReportTypeSuccess(state, action);
        case REPORT_TYPE_FAILURE:
            return ReportTypeFailure(state, action);
        case CREATE_REPORT_SUCCESS:
            return CreateReportSuccess(state, action);
        case CREATE_REPORT_FAILURE:
            return CreateReportFailure(state, action);
        case RESET_REPORT_ID:
            return ResetReportId(state, action);
        case REPORT_PARAM_VERSION_SUCCESS:
            return ParamVersionSuccess(state, action);
        case REPORT_PARAM_VERSION_FAILURE:
            return ParamVersionFailure(state, action);
        case REPORT_DETAIL_BY_ID_SUCCESS:
            return ReportDetailByIdSuccess(state, action);
        case REPORT_DETAIL_BY_ID_FAILURE:
            return ReportDetailByIdFailure(state, action);
        case REPORT_NOTIFICATION_SUCCESS:
            return ReportSuccess(state, action);
        case REPORT_PARAMETER_SUCCESS:
            return ReportSuccess(state, action);
        case REPORT_DRIVER_TYPES_SUCCESS:
            return ReportSuccess(state, action);
        case REPORT_FILTER_SUCCESS:
            return ReportSuccess(state, action);
        case LOAD_FILTER_SUCCESS:
            return ReportSuccess(state, action);
        case UPDATE_REPORTS_REDUCER:
            return ReportSuccess(state, action);
        case CLEAR_REPORT_DATA:
            return clearReportData();
        case UPDATE_LOAD_SOURCE:
            return updateLoadSource(state, action);
        case SET_SELECTED_PICKUP_LOCATIONS:
            return setSelectedPickupLocations(state, action);
        case SET_SELECTED_DROPOFF_LOCATIONS:
            return setSelectedDropoffLocations(state, action);
        case SET_SELECTED_DRIVER_TYPES:
            return setSelectedDriverTypes(state, action);
        case RESET_FILTERS:
            return resetFilters(state, action);
        case UPDATE_FREIGHT_BONUS_APPLIED:
            return updateFreightBonusApplied(state, action);
        case UPDATE_APPLIED_FILTERS_COUNT:
            return updateAppliedFiltersCount(state, action);
        default:
            return state;
    }
};

export default ReportsReducer;
