import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import theme from 'theme';

export const RightSideHeaderContent = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const RefreshHelperText = styled(Typography)(() => ({
    fontWeight: 400,
    lineHeight: '20px',
    margin: '16px 0'
}));

export const ButtonWrapper = styled(Box)(() => ({
    display: 'flex',
    marginTop: '32px',
    justifyContent: 'space-between'
}));

export const FileNameCard = styled(Box)(() => ({
    display: 'flex',
    height: '20px',
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px',
    margin: '2px 0',
    borderRadius: '4px',
    backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1
}));

export const ExportHelperText = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '16px',
    lineHeight: '20px'
}));

export const BidAnalysisHeaderContainer = styled(Box)(() => ({
    height: '56px',
    flex: '0 0 auto',
    display: 'flex',
    padding: '0 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.neutral.neutral1}`,
    backgroundColor: theme.palette.white
}));

export const DateRangeWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.neutral.neutral6,
    margin: '0 8px'
}));
