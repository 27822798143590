/* eslint-disable import/no-anonymous-default-export */
import Cookies from 'universal-cookie';
import EnvConfig from 'config/config';

const cookies = new Cookies();

const domain =
    window.location.hostname === 'localhost'
        ? window.location.hostname
        : EnvConfig.getConfig().domain;

const cookieKey = (key: string) => `${EnvConfig.getConfig().env || ''}${key}`;

const cookieOptions = {
    path: '/',
    domain
};

const set = (key: string, value: string) => {
    cookies.set(cookieKey(key), value, cookieOptions);
};

const get = (key: string) => cookies.get(cookieKey(key));

const remove = (key: string) => {
    return cookies.remove(cookieKey(key), cookieOptions);
};

export default { set, get, remove };
