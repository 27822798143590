import { styled, Box } from '@mui/material';
import palette from 'theme/palette';

export const StyledSpan = styled('span')(({ selectable }: { selectable?: boolean }) => ({
    textOverflow: 'ellipsis',
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textDecoration: selectable ? 'underline' : '',
    cursor: selectable ? 'pointer' : 'auto'
}));

export const CityEllipsis = styled(Box)(() => ({
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

export const CellContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
}));

export const StyledTextBox = styled(Box)(({ size }: { size: number }) => ({
    textOverflow: 'ellipsis',
    maxWidth: size - 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}));

export const StyledTextDeltaBox = styled(Box)(({ size }: { size: number }) => ({
    textOverflow: 'ellipsis',
    maxWidth: size - 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '4px',
    display: 'flex',
    alignItems: 'center',
    color: palette.neutral.neutral6
}));
