import { DRIVER_MODE, DRIVER_TABLE_TYPE } from 'constants/drivers';

export const createActions = (obj, editDriver) => {
    return Object.keys(obj).map((key) => {
        return {
            type: 'add',
            item_type: 'driver',
            value: {
                isNew: false,
                domicile: editDriver.domicile_id,
                driver_type: key,
                total_drivers: editDriver.total_drivers || editDriver.totalDrivers,
                quantity: parseInt(obj[key])
            }
        };
    });
};

export const filterDriverTypes = (obj, editDriver) => {
    return Object.keys(obj).reduce((acc, val) => {
        const currentDriver = editDriver.driver_types.filter((ind) => ind.driver_type === val);
        const driverCurrentQuantity = currentDriver[0].actionCount || 0;
        const filterZeros = obj[val] === 0 && driverCurrentQuantity === 0;
        const filterNoChange = obj[val] === driverCurrentQuantity.toString();
        return filterZeros || filterNoChange
            ? acc
            : {
                  ...acc,
                  [val]: obj[val]
              };
    }, {});
};

export const setDefaultSortType = (compare_run, driverMode) => {
    if (compare_run) {
        switch (driverMode) {
            case DRIVER_MODE.DEFAULT:
                return 'compare_true_profit';
            case DRIVER_MODE.DOMICILE:
                return 'compare_true_profit_amount';
            case DRIVER_MODE.DRIVER_TYPES:
                return 'load_count';
            default:
                break;
        }
    } else {
        if ([DRIVER_MODE.DEFAULT, DRIVER_MODE.DOMICILE].includes(driverMode)) {
            return 'total_drivers';
        } else if (driverMode === DRIVER_MODE.DRIVER_TYPES) {
            return 'load_count';
        } else {
            return null;
        }
    }
};

export const handleDriverRowFilter = (
    i,
    row,
    enableFilter,
    onSetFilter,
    movementsTableView,
    tableType
) => {
    const cells = row.cells;
    if (i !== 0 && row?.cells?.length - 1 !== i) {
        if (enableFilter) {
            if (tableType === DRIVER_TABLE_TYPE.DOMICILE) {
                onSetFilter(
                    cells[1]?.column['Header'] === 'Domicile'
                        ? cells[1]?.value
                        : cells[1]?.value + '-' + cells[2]?.value,
                    row?.original,
                    DRIVER_MODE.DOMICILE
                );
            } else if (tableType === DRIVER_TABLE_TYPE.DRIVER_TYPES) {
                //to the 3rd table
                let setValue = cells[1]?.column['Header'] === 'Driver Type' && cells[1]?.value;

                onSetFilter(setValue, row?.original, DRIVER_MODE.DRIVER_TYPES);
            } else if (tableType === DRIVER_TABLE_TYPE.DRIVER_ID && movementsTableView) {
                //to the 4th table
                let setValue = cells[1]?.column['Header'] === 'Driver ID' && cells[1]?.value;
                onSetFilter(setValue, row?.original, DRIVER_MODE.DRIVER_ID);
            }
        }
        return;
    }
};

export const resetDriverChecked = {
    allDomiciles: false,
    allDriverTypes: false,
    allDriverIds: false,
    selectedDomicile: [],
    selectedDriverType: [],
    selectedDriverId: [],
    selectedDriverInfo: null
};
