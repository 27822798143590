import ODDonutChart from 'shared/ODDonutChart';
import { LOAD_TYPES } from 'constants/shippers';
import theme from 'theme';
import { trimId } from 'utils/dom.util';

const ShipperDonut = (props) => {
    const { value, type, index, total } = props;

    const removeChangeValues = (value) => {
        return parseInt(value.toString().replace(/ \([^()]*\)/, ''));
    };
    let donutValue = removeChangeValues(value) || 0;
    let percentage = [(donutValue / total) * 100];
    let colors = [
        type === LOAD_TYPES.fleet
            ? theme.palette.semantic.semanticGreen
            : theme.palette.semantic.semanticRed
    ];
    return (
        <ODDonutChart
            percentages={percentage}
            colors={colors}
            light={true}
            index={trimId(`driver-count-${index}-${type}`)}
        />
    );
};
export default ShipperDonut;
