import {
    SIGNIN_START,
    SIGNIN_SUCCESS,
    SIGNIN_FAIL,
    USER_ROLES,
    SIGNOUT,
    SIGNIN_CLEAR,
    TOKEN_EXPIRY,
    ORGANIZATION,
    LAUNCH_DARKLY_USER_IDENTIFIED
} from '../constants/actionTypes';

const initialState = {
    roles: [],
    token: null,
    error: null,
    loading: false,
    type: null,
    tokenExpireyError: null,
    organization: null,
    launchDarklyUserIdentified: false
};

const signInStart = (state, action) => {
    return { ...state, error: null, loading: true };
};

const signInSuccess = (state, action, type) => {
    return {
        ...state,
        token: action.token,
        loading: false,
        error: null,
        type: type
    };
};

const signInFail = (state, action) => {
    return {
        ...state,
        token: null,
        loading: false,
        error: action.error
    };
};

const signInClear = (state, action) => {
    return { ...state, token: null, loading: false, error: null, type: null };
};

const onTokenExpiry = (state, action) => {
    return {
        ...state,
        token: null,
        loading: false,
        tokenExpireyError: 'Your login session expired'
    };
};

const userRoles = (state, { roles }) => {
    return {
        ...state,
        roles
    };
};

const organization = (state, organization) => {
    return {
        ...state,
        organization
    };
};

const launchDarklyUserIdentified = (state, action) => ({
    ...state,
    launchDarklyUserIdentified: action.isIdentified
});

const SignInReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNIN_START:
            return signInStart(state, action);
        case SIGNIN_SUCCESS:
            return signInSuccess(state, action, action.type);
        case SIGNIN_FAIL:
            return signInFail(state, action);
        case SIGNOUT:
            return signInClear(state, action);
        case SIGNIN_CLEAR:
            return signInClear(state, action);
        case TOKEN_EXPIRY:
            return onTokenExpiry(state, action);
        case USER_ROLES:
            return userRoles(state, action);
        case ORGANIZATION:
            return organization(state, action);
        case LAUNCH_DARKLY_USER_IDENTIFIED:
            return launchDarklyUserIdentified(state, action);
        default:
            return state;
    }
};

export default SignInReducer;
