//Known Issue: https://github.com/TanStack/table/issues/2870. This should be fixed with updated V7
import { useLayoutEffect, useRef, useState } from 'react';
import theme from 'theme';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    TableContainer,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    ArrowUpward,
    ArrowDownward,
    ChevronLeftSharp as ChevronLeftSharpIcon,
    ChevronRightSharp as ChevronRightSharpIcon
} from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

//components
import { NetworkTableSettingsSlideout } from 'components/NetworkView/NetworkTable';
import TableSettingsListButton from '../TableSettingsListButton';
import { determineIndicatorLines } from 'components/NetworkView/IndicatorLines';
import ShipperActionBar from '../Shipper/ShipperActionBar';
import DriverActionBar from '../Driver/DriverActionBar';

//utils
import { networkTableHeightById, defaultColumnWidth } from 'utils/table.util';
import { getAncestorIds } from 'utils/dom.util';
import { Filter } from 'shared/Table/tableFilters';
import { indicatorLinesWidth } from 'constants/network';
import { useShipperHook } from 'utils/hooks';
import { DRIVER_TABLE_TYPE } from 'constants/drivers';
import { getLastStickyColumn, leftOffset } from 'utils/network.util';
import {
    CustomizedHeaderCell,
    IndicatorLinesCell,
    IndicatorLinesHeaderCell,
    EmptyTableSettingsColumnHeader,
    EmptyTableBody,
    CustomizedCell,
    PaginationContainer,
    PaginationButton,
    TableBodyRow,
    networkTableUseStyles,
    bottomTableHeaderHeight
} from './NetworkTable.styles';
import { NETWORK_TABLE_TYPE } from 'constants/network';

const ActionBarContainer = styled(Box)(({ paddingBottom }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: theme.palette.neutral.neutral25,
    paddingBottom
}));

const StyledTableHead = styled(TableHead)(({ top }) => ({
    position: 'sticky',
    top,
    zIndex: 2
}));

const NetworkTable = ({
    columns,
    data,
    onSetFilter,
    sortColumn,
    enableFilter,
    enableCursorRow,
    isListView,
    drilledIn,
    loadFilters,
    topTable,
    tableDataType,
    hiddenColumns,
    lockedColumnsIds
}) => {
    const ITEMS_PER_PAGE = data?.length > 0 ? (data.length < 50 ? data?.length : 50) : 100;
    const { fullWidthMapViewTables, bidding } = useFlags();
    const classes = networkTableUseStyles();
    const {
        filterTypes,
        defaultColumn,
        handleOnClickFilterSetShipperLane,
        isSelected,
        resetColumnHeaderSelections,
        updateColumnHeaders
    } = useShipperHook();
    const networkReducer = useSelector(({ NetworkReducer }) => NetworkReducer);
    const { checked, currTab } = networkReducer;
    const { compare_run } = useParams();
    const [showTableSettingsSlideout, setShowTableSettingsSlideout] = useState(false);
    const { currShipperActionBar, showDriverOptionBar, expandPanel, rowHover } = useSelector(
        (state) => state.NetworkReducer
    );
    const ref = useRef(null);
    const [actionBarHeight, setActionBarHeight] = useState(null);

    useLayoutEffect(() => {
        const visibleTableContentsStripToHide = 0.5;
        if (ref?.current?.offsetHeight > 0)
            setActionBarHeight(`${ref?.current?.offsetHeight - visibleTableContentsStripToHide}px`);
        else setActionBarHeight(0);
    }, [ref?.current?.offsetHeight]);

    const globalFilter = (rows, filterValue) => {
        const globalFilterColumnIds = columns
            .filter((column) => column.applyGlobalFilter)
            .map((column) => column.accessor);
        return rows?.filter((row) =>
            globalFilterColumnIds?.some((columnId) =>
                row?.values[columnId]?.toLowerCase().includes(String(filterValue)?.toLowerCase())
            )
        );
    };

    const table = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            disableSortRemove: true,
            autoResetGlobalFilter: false,
            autoResetFilters: false,
            autoResetSortBy: false,
            autoResetPage: false,
            initialState: {
                pageIndex: 0,
                //! known issue:https://github.com/AdeleD/react-paginate/issues/152
                pageSize: 50,
                sortBy: topTable
                    ? []
                    : [
                          {
                              id: sortColumn,
                              desc: true
                          }
                      ]
            },
            globalFilter
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        allColumns,
        filteredRows
    } = table;

    const ITEMS_COUNTS = rows?.length;

    const pageRange = {
        from: pageIndex * ITEMS_PER_PAGE + 1,
        to:
            pageIndex * ITEMS_PER_PAGE + ITEMS_PER_PAGE < ITEMS_COUNTS
                ? pageIndex * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                : ITEMS_COUNTS
    };

    const allVisibleColumns = allColumns.filter((c) => c.isVisible);
    const lastLeftStickyColumn = getLastStickyColumn(allVisibleColumns, columns)?.identifier;

    // TODO can we eliminate the need for this code?
    const actionBarBottomPadding =
        (currShipperActionBar &&
            (checked?.selectedShippers?.length > 0 || checked?.selectedLanes?.length > 0)) ||
        (showDriverOptionBar && !topTable)
            ? '16px'
            : 0;

    return (
        <>
            {bidding && (
                <NetworkTableSettingsSlideout
                    columns={allColumns}
                    lockedColumnsIds={lockedColumnsIds}
                    showTableSettingsSlideout={showTableSettingsSlideout}
                    setShowTableSettingsSlideout={setShowTableSettingsSlideout}
                    updateColumnHeaderSelections={updateColumnHeaders}
                    resetColumnHeaderSelections={resetColumnHeaderSelections}
                    columnLimit={null}
                    hiddenColumns={hiddenColumns}
                    tableDataType={tableDataType}
                />
            )}
            <Box
                sx={{
                    padding: fullWidthMapViewTables && !isListView ? '0 16px' : 0,
                    marginTop: '16px'
                }}>
                <Paper
                    sx={{
                        backgroundColor: theme.palette.neutral.neutral25,
                        width: 'initial',
                        overflow: 'hidden',
                        margin: enableFilter
                            ? !fullWidthMapViewTables && !isListView
                                ? '0 8px 0 16px'
                                : topTable
                                ? '0'
                                : '0 -8px 0 0'
                            : !fullWidthMapViewTables && !isListView
                            ? '0 16px'
                            : '0',
                        borderRadius: 0,
                        flex: topTable ? 'initial' : 1,
                        position: 'relative'
                    }}>
                    <TableContainer
                        sx={{
                            height: networkTableHeightById(
                                isListView,
                                drilledIn,
                                loadFilters?.isFilterSet,
                                enableFilter,
                                fullWidthMapViewTables,
                                currTab !== NETWORK_TABLE_TYPE.DRIVERS
                            ),
                            '&::-webkit-scrollbar': {
                                backgroundColor: theme.palette.search
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-corner': {
                                backgroundColor: theme.palette.search
                            },
                            /* scrollbar handle */
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.neutral.neutral4,
                                '&:hover': {
                                    backgroundColor: theme.palette.neutral.neutral6
                                }
                            }
                        }}>
                        {/* TODO Make action bars logic more DRY}*/}
                        {!topTable && !(fullWidthMapViewTables && !isListView && !expandPanel) && (
                            <ActionBarContainer ref={ref} paddingBottom={actionBarBottomPadding}>
                                {currShipperActionBar ? (
                                    <ShipperActionBar filteredRows={filteredRows} />
                                ) : (
                                    showDriverOptionBar && <DriverActionBar />
                                )}
                            </ActionBarContainer>
                        )}
                        <Table
                            {...getTableProps()}
                            aria-label="sticky network view table header"
                            size="small">
                            <StyledTableHead top={actionBarHeight}>
                                {headerGroups.map((headerGroup, headIndex) => (
                                    <TableRow
                                        hover
                                        {...headerGroup.getHeaderGroupProps({
                                            style: {
                                                padding: '0',
                                                display: 'flex',
                                                backgroundColor: theme.palette.neutral.search,
                                                justifyContent: 'space-between',
                                                height: topTable ? '40px' : bottomTableHeaderHeight
                                            }
                                        })}
                                        key={headIndex}>
                                        <IndicatorLinesHeaderCell
                                            key={'indicator-lines-header-cell'}
                                            className={clsx(classes.sticky)}
                                            sx={{
                                                width: `${indicatorLinesWidth}px`
                                            }}
                                        />
                                        {headerGroup.headers.map((column, i) => {
                                            let title = column?.Header;

                                            if (title === 'Settings') {
                                                if (bidding) {
                                                    return (
                                                        <CustomizedHeaderCell
                                                            column={column}
                                                            lastLeftStickyColumn={
                                                                lastLeftStickyColumn
                                                            }
                                                            key={`${headIndex}-${i}`}
                                                            className={clsx(classes.sticky)}>
                                                            <TableSettingsListButton
                                                                handleClickedGear={() =>
                                                                    setShowTableSettingsSlideout(
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                        </CustomizedHeaderCell>
                                                    );
                                                } else {
                                                    return (
                                                        <Box
                                                            sx={{
                                                                //TODO: hidden until notifs are on (design required)
                                                                visibility: 'hidden',
                                                                borderRadius: '50%',
                                                                backgroundColor:
                                                                    theme.palette.neutral.neutral2
                                                                        .odBlue8,
                                                                width: column.columnWidth
                                                                    ? column.columnWidth
                                                                    : defaultColumnWidth,
                                                                height: '32px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                marginRight: '16px'
                                                            }}>
                                                            0
                                                        </Box>
                                                    );
                                                }
                                            }

                                            return (
                                                <CustomizedHeaderCell
                                                    column={column}
                                                    leftOffset={leftOffset(
                                                        column,
                                                        allVisibleColumns,
                                                        indicatorLinesWidth,
                                                        table.columns
                                                            .map((c) => c.id)
                                                            .includes('checkbox')
                                                    )}
                                                    lastLeftStickyColumn={lastLeftStickyColumn}
                                                    isFirstDataColumn={i === 1}
                                                    {...column.getHeaderProps()}
                                                    {...column.getSortByToggleProps()}
                                                    onClick={(e) => {
                                                        const ancestorIds = getAncestorIds(
                                                            e.target
                                                        );
                                                        if (!ancestorIds.includes('filter')) {
                                                            if (column?.toggleSortBy)
                                                                column.toggleSortBy();
                                                        }
                                                    }}
                                                    key={column.width + i}
                                                    className={
                                                        column.sticky
                                                            ? clsx(
                                                                  classes.sticky,
                                                                  classes.stickyHeader
                                                              )
                                                            : {}
                                                    }
                                                    sx={{
                                                        flex:
                                                            column.identifier === 'checkbox'
                                                                ? '0 0 auto'
                                                                : '1 0 auto',
                                                        display:
                                                            hiddenColumns.includes(
                                                                column.visibilityKey
                                                            ) ||
                                                            (column.isDelta &&
                                                                !Boolean(compare_run))
                                                                ? 'none'
                                                                : 'flex',
                                                        flexDirection: 'column'
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-start',
                                                            height: '100%'
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row'
                                                            }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    lineHeight: '20px'
                                                                }}>
                                                                {title === 'Settings' ? (
                                                                    bidding ? (
                                                                        <TableSettingsListButton
                                                                            handleClickedGear={() =>
                                                                                setShowTableSettingsSlideout(
                                                                                    true
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <EmptyTableSettingsColumnHeader>
                                                                            0
                                                                        </EmptyTableSettingsColumnHeader>
                                                                    )
                                                                ) : (
                                                                    column.render('Header')
                                                                )}
                                                            </Box>

                                                            <Box>
                                                                <span className="sortIcons">
                                                                    {column.isSorted ? (
                                                                        column.isSortedDesc ? (
                                                                            <ArrowDownward />
                                                                        ) : (
                                                                            <ArrowUpward />
                                                                        )
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </span>
                                                            </Box>
                                                        </Box>

                                                        {!topTable && (
                                                            <Box>
                                                                {column.disableFilters ? (
                                                                    <Box
                                                                        sx={{
                                                                            height: '1.4375em',
                                                                            marginTop: '8px'
                                                                        }}></Box>
                                                                ) : (
                                                                    <Filter column={column} />
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </CustomizedHeaderCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </StyledTableHead>
                            {rows.length !== 0 && (
                                <TableBody
                                    sx={{
                                        overflowX: 'hidden',
                                        overflowY: 'scroll',
                                        minHeight: 0,
                                        flexGrow: 1
                                    }}
                                    {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);

                                        return (
                                            <TableBodyRow
                                                enableCursorRow={enableCursorRow}
                                                isSelected={isSelected(row, tableDataType)}
                                                id={`${tableDataType}-row-${i}`}
                                                {...row.getRowProps()}
                                                hover={
                                                    [DRIVER_TABLE_TYPE].includes(tableDataType) &&
                                                    rowHover
                                                }
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={i}>
                                                <IndicatorLinesCell
                                                    className={clsx(classes.sticky)}
                                                    sx={{ left: 0, padding: 0 }}>
                                                    {determineIndicatorLines(row)}
                                                </IndicatorLinesCell>
                                                {row.cells.map((cell, i) => {
                                                    const isRemoved = cell.row?.original?.isRemoved;
                                                    const renderValue = isRemoved ? (
                                                        <del style={{ overflow: 'hidden' }}>
                                                            {cell.render('Cell')}
                                                        </del>
                                                    ) : (
                                                        cell.render('Cell')
                                                    );
                                                    return (
                                                        <CustomizedCell
                                                            hiddenColumns={hiddenColumns}
                                                            cell={cell}
                                                            index={i}
                                                            compare_run={compare_run}
                                                            leftOffset={leftOffset(
                                                                cell.column,
                                                                allVisibleColumns,
                                                                indicatorLinesWidth,
                                                                table.columns
                                                                    .map((c) => c.id)
                                                                    .includes('checkbox')
                                                            )}
                                                            lastLeftStickyColumn={
                                                                lastLeftStickyColumn
                                                            }
                                                            {...cell.getCellProps()}
                                                            key={i}
                                                            align="left"
                                                            onClick={() => {
                                                                handleOnClickFilterSetShipperLane(
                                                                    cell,
                                                                    row,
                                                                    i,
                                                                    tableDataType,
                                                                    enableFilter,
                                                                    onSetFilter
                                                                );
                                                            }}
                                                            className={
                                                                cell.column.sticky
                                                                    ? clsx(classes.sticky)
                                                                    : {}
                                                            }>
                                                            {renderValue}
                                                        </CustomizedCell>
                                                    );
                                                })}
                                            </TableBodyRow>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                        {rows.length === 0 && (
                            <EmptyTableBody isListView={isListView}>
                                <Typography sx={{ fontSize: '14px' }}>No Data Available</Typography>
                            </EmptyTableBody>
                        )}
                    </TableContainer>
                </Paper>
            </Box>
            {rows.length > 0 && !topTable && (
                <PaginationContainer isListView={isListView}>
                    <Box component="section" alignItems="center" margin="0 16px">
                        Rows Per Page:
                    </Box>
                    <Box
                        display="flex"
                        component="section"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            margin: !isListView ? '0 8px' : '0 16px',
                            border: `1px solid ${theme.palette.neutral.neutral1}`,
                            borderRadius: '100%',
                            width: '40px',
                            height: '40px'
                        }}>
                        {ITEMS_PER_PAGE}
                    </Box>
                    <Box>
                        {pageRange?.from} - {pageRange?.to} of {ITEMS_COUNTS}
                    </Box>

                    <Box display="flex" justifyContent="flex-start" marginLeft="16px">
                        <PaginationButton
                            sx={{
                                margin: '0 8px'
                            }}
                            aria-label="previous page"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            size="small">
                            <ChevronLeftSharpIcon />
                        </PaginationButton>
                        <PaginationButton
                            sx={{
                                marginLeft: '8px'
                            }}
                            aria-label="next page"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            size="small">
                            <ChevronRightSharpIcon />
                        </PaginationButton>
                    </Box>
                </PaginationContainer>
            )}
        </>
    );
};

export default NetworkTable;
