/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { ODTableProvider } from 'shared/ODTable/ODTableContext';
import ODTable from 'shared/ODTable/ODTable';
import bidAnalysisColumnsInitiate from './BidAnalysisTableColumns/BidAnalysisTableColumns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import theme from 'theme';
import { BIDDING_TABLE_NAME } from 'constants/bidding';
import ODTableColumnSelectionsSlideout from 'shared/ODTable/ODTableColumnSelectionSlideout';
import ConfirmDecisionModal from 'components/Modals/BidAnalysisDecisionModal';
import MKTBalanceModal from 'components/Modals/MKTBalanceModal';
import { useDispatch, useSelector } from 'react-redux';
import { getLaneScoreMetricsByLaneId, loadBidAnalysis, updateBidAnalysis } from 'redux/actions';
import { RootState } from 'types/redux';
import OutboundSharp from '@mui/icons-material/OutboundSharp';
import { ActionButton, DataRow } from 'shared/ODTable/ODTableTypes';
import { FLOW_DIRECTION } from 'types/bidAnalysis';
import { TABLE_TYPE } from 'constants/network';
import { useBidAnalysisHook } from 'utils/hooks';
import { useParams } from 'react-router';
import { produce, Draft } from 'immer';
import { TIME_FRAME } from 'constants/settings';
import { ColumnFilter, ColumnSort, SortingState } from '@tanstack/react-table';
import { Table } from '@tanstack/react-table';
import BidAnalysisActionBar from '../BidAnalysisActionBar';
import * as FS from '@fullstory/browser';
import { getFinalizedBidDelta } from 'redux/actions/bidAnalysisActions';

const BidAnalysisTable = () => {
    const {
        applyTableEditsAndActions,
        triggerNoMappingInfoAlert,
        bidGoalApplied,
        handleUndoCell,
        setLaneDecision,
        isFinalizedBid
    } = useBidAnalysisHook();
    const { hiddenColumnsArrays } = useSelector((state: RootState) => state.TableLayoutReducer);
    const { locationVisualization } = useSelector((state: RootState) => state.settingsReducer);
    const dispatch = useDispatch();
    const { rateStudyCol } = useFlags();

    const [rowsToDelete, setRowsToDelete] = useState<number[]>([]);
    const [laneIsSelected, setLaneIsSelected] = useState<boolean>(false);
    const [showMKTBalanceModal, setShowMKTBalanceModal] = useState<boolean>(false);
    const [selectedLane, setSelectedLane] = useState<DataRow | null>(null);
    const [selectedMarket, setSelectedMarket] = useState<'origin' | 'destination'>('origin');
    const { time_aggregation } = useSelector(
        (state: RootState) => state.BidAnalysisReducer.bidAnalysisSetting
    );

    const hasAggregated = useMemo(
        () => [TIME_FRAME.WEEKLY, TIME_FRAME.ANNUALIZED].includes(time_aggregation),
        [time_aggregation]
    );

    const {
        bidGoals,
        canMeetBidGoalWithQualifyingLanes,
        checkedRows,
        currBidAnalysisLanes,
        displayAllLanes,
        lanesToMeetTarget,
        selectedLaneId,
        confirmRecommendationModalDetails,
        showBidAnalysisTableSettings,
        bidAnalysisSetting,
        userUpdatedSelectedLanes,
        bidAnalysisTableDataErrors,
        bidAnalysisColumnFormatSelections,
        columnFilters,
        sortingState,
        bidAnalysisDeltaValues
    } = useSelector((state: RootState) => state.BidAnalysisReducer);

    const {
        flatRates,
        hideQuickCopyActionsOnBidAnalysisTable,
        bidAnalysisAndFlowsTableColumns,
        salesRanking,
        odpt3965MarketBalanceColumns,
        odpt3830NameConsistency,
        refreshBid,
        showProfitColumns
    } = useFlags();
    const flatRatesFlag = flatRates;
    const { analysis_id, config_id } = useParams() as {
        analysis_id: string | number;
        config_id?: string;
    };

    useEffect(() => {
        setLaneIsSelected(selectedLaneId !== null);
        setRowsToDelete([]);
    }, [selectedLaneId]);

    const setCheckedRows = (rowIds: number[]) =>
        dispatch(updateBidAnalysis({ checkedRows: rowIds, userUpdatedSelectedLanes: true }));

    const drillIntoLane = ({
        laneId,
        flowDirection,
        table
    }: {
        laneId: number;
        flowDirection: FLOW_DIRECTION;
        table: Table<DataRow>;
    }) => {
        dispatch(
            updateBidAnalysis({
                selectedLaneId: laneId,
                selectedFlowDirection: flowDirection
            })
        );
        table.setPageIndex(0);
    };
    const customActionButtons: ActionButton[] = [
        {
            icon: <OutboundSharp sx={{ transform: 'rotate(90deg)', fill: theme.palette.black }} />,
            description: 'inflow',
            callback: (laneId: number, table: Table<DataRow>) => {
                FS.event('BidAnalysis_Click_Ellipsis_For_Infow', {});
                drillIntoLane({ laneId, flowDirection: FLOW_DIRECTION.IN, table });
            }
        },
        {
            icon: <OutboundSharp sx={{ transform: 'rotate(15deg)', fill: theme.palette.black }} />,
            description: 'outflow',
            callback: (laneId: number, table: Table<DataRow>) => {
                FS.event('BidAnalysis_Click_Ellipsis_For_Outflow', {});
                drillIntoLane({ laneId, flowDirection: FLOW_DIRECTION.OUT, table });
            }
        }
    ];

    const openBidAnalysisLaneScoreSlideout = (bidLaneId: any) => {
        FS.event('Bid Analysis_Click Lane Score', {});
        dispatch(getLaneScoreMetricsByLaneId(analysis_id, bidLaneId));
        dispatch(
            updateBidAnalysis({
                openLaneScoreSlideout: true,
                selectedLaneScoreMetaData: {},
                selectedLaneScoreId: bidLaneId
            })
        );
    };

    const handleLocationClick = (
        laneId: number,
        direction: FLOW_DIRECTION,
        table: Table<DataRow>
    ) => {
        drillIntoLane({ laneId, flowDirection: direction, table });
    };

    const handleMKTBalanceClick = (lane: DataRow, market: 'origin' | 'destination') =>
        openMKTBalanceModal(lane, market);

    const openMKTBalanceModal = (lane: DataRow, market: 'origin' | 'destination') => {
        setShowMKTBalanceModal(true);
        setSelectedLane(lane);
        setSelectedMarket(market);
    };

    const clearAnyFilterOnRateColumns = (table: Table<DataRow>) => {
        const newColFilters = table
            .getState()
            .columnFilters.filter(
                (colFilter: ColumnFilter) =>
                    colFilter.id !== 'rate_per_mile' && colFilter.id !== 'flat_rate'
            );
        dispatch(updateBidAnalysis({ columnFilters: newColFilters }));
        table.setColumnFilters(newColFilters);
    };
    const maintainSortingOnRateColumns = (table: Table<DataRow>) => {
        const sortingState = table.getState().sorting;

        const ratePerMileSort = sortingState.find(
            (columnSort: ColumnSort) => columnSort.id === 'rate_per_mile'
        );
        const flatRateSort = sortingState.find(
            (columnSort: ColumnSort) => columnSort.id === 'flat_rate'
        );

        if (ratePerMileSort || flatRateSort) {
            const newSorting: ColumnSort[] = [];

            if (ratePerMileSort) {
                newSorting.push({ id: 'flat_rate', desc: ratePerMileSort.desc });
            }

            if (flatRateSort) {
                newSorting.push({ id: 'rate_per_mile', desc: flatRateSort.desc });
            }

            dispatch(updateBidAnalysis({ sortingState: newSorting }));
            table.setSorting(newSorting);
        }
    };
    const handleSwapRateColumn = (option: string, table: Table<DataRow>) => {
        const modifiedColumnFormatSelections = produce(
            bidAnalysisColumnFormatSelections,
            (draft: Draft<{ [key: string]: string }>) => {
                draft.rate = option;
            }
        );
        clearAnyFilterOnRateColumns(table);
        maintainSortingOnRateColumns(table);
        dispatch(
            updateBidAnalysis({ bidAnalysisColumnFormatSelections: modifiedColumnFormatSelections })
        );
    };

    useEffect(() => {
        (async () => {
            if (isFinalizedBid && analysis_id && refreshBid) {
                const { data } = await getFinalizedBidDelta({ analysis_id });
                await dispatch(
                    updateBidAnalysis({
                        bidAnalysisDeltaValues: data
                    })
                );
            }
        })();
    }, [isFinalizedBid, analysis_id]);

    const handleColumnLevelTimeAggregationSelection = (columnId: string, timeScale: TIME_FRAME) => {
        const modifiedTimeAggSelections = produce(
            bidAnalysisSetting.column_specific_time_aggregation,
            (draft: Draft<{ [key: string]: TIME_FRAME }>) => {
                draft[columnId] = timeScale;
            }
        );
        dispatch(
            loadBidAnalysis({
                analysis_id,
                config_id,
                settings: {
                    column_specific_time_aggregation: modifiedTimeAggSelections
                },
                isFinalizedBid
            })
        );
    };

    const sortingWhenGoalIsSet: SortingState = [{ id: 'lane_score', desc: true }];

    //TODO turn on when refresh is ready 'flat_rate', 'rate_per_mile',
    const enableRateStudyEdit = rateStudyCol
        ? ['flat_rate', 'rate_per_mile', 'volume_to_accept']
        : ['volume_to_accept'];

    return (
        <ODTableProvider
            accommodatesUndos={true}
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
            columnDefs={bidAnalysisColumnsInitiate(
                openBidAnalysisLaneScoreSlideout,
                'inclusion',
                setLaneDecision,
                handleLocationClick,
                handleMKTBalanceClick,
                laneIsSelected,
                hideQuickCopyActionsOnBidAnalysisTable,
                locationVisualization,
                bidAnalysisAndFlowsTableColumns,
                salesRanking,
                bidAnalysisSetting.column_specific_time_aggregation,
                bidAnalysisSetting.time_aggregation,
                triggerNoMappingInfoAlert,
                handleSwapRateColumn,
                bidAnalysisColumnFormatSelections.rate,
                flatRatesFlag,
                odpt3965MarketBalanceColumns,
                odpt3830NameConsistency,
                refreshBid ? bidAnalysisDeltaValues : null,
                hasAggregated,
                showProfitColumns
            )}
            columnFormatSelections={bidAnalysisColumnFormatSelections}
            customActionButtons={customActionButtons}
            defaultSortingState={[{ id: 'lane_score', desc: true }]}
            disableFilters={laneIsSelected}
            disableSorting={laneIsSelected}
            handleClickSettingsIcon={() => {
                FS.event('Bid Analysis_Table Settings_Click Table Settings Icon', {});
                dispatch(updateBidAnalysis({ showBidAnalysisTableSettings: true }));
            }}
            handleColumnLevelTimeAggregationSelection={handleColumnLevelTimeAggregationSelection}
            handleUndoCell={handleUndoCell}
            hiddenColumnIds={hiddenColumnsArrays[BIDDING_TABLE_NAME.BID_ANALYSIS]}
            backgroundColorForSelectedRows={
                bidGoalApplied && !userUpdatedSelectedLanes && !canMeetBidGoalWithQualifyingLanes
                    ? theme.palette.ODRed.lightRed100
                    : theme.palette.ODLightBlueNeutral.lightBlue1
            }
            separatedColumns={['recommendation', 'review-or-adjust-recommendations']}
            column_specific_time_aggregation={bidAnalysisSetting.column_specific_time_aggregation}
            data={
                laneIsSelected
                    ? currBidAnalysisLanes.filter((lane: DataRow) => lane.id === selectedLaneId)
                    : displayAllLanes
                    ? currBidAnalysisLanes
                    : currBidAnalysisLanes.filter(
                          (lane: DataRow) =>
                              lanesToMeetTarget instanceof Set && lanesToMeetTarget?.has(lane.id)
                      )
            }
            columnFilterOptions={{
                value: columnFilters,
                setter: (newColFilters) =>
                    dispatch(updateBidAnalysis({ columnFilters: newColFilters }))
            }}
            sortOptions={{
                value: sortingState,
                setter: (newSorting) => dispatch(updateBidAnalysis({ sortingState: newSorting }))
            }}
            cellEditsCallback={applyTableEditsAndActions}
            interactiveTable={true}
            interactiveColumns={enableRateStudyEdit}
            rowsToDelete={rowsToDelete}
            rightStickyColumnsGroupId="review-or-adjust-recommendations"
            setRowsToDelete={setRowsToDelete}
            showRowActions={!laneIsSelected}
            addPagination={!(selectedLaneId !== null)}
            targets={bidGoals}
            sortingWhenGoalIsSet={sortingWhenGoalIsSet}
            tableDataErrors={bidAnalysisTableDataErrors}>
            <>
                <BidAnalysisActionBar checkedRows={checkedRows} setCheckedRows={setCheckedRows} />
                <ODTable
                    key={locationVisualization}
                    lockOverflowY={selectedLaneId !== null}
                    tableKey={TABLE_TYPE.BID_ANALYSIS}
                />
                <ODTableColumnSelectionsSlideout
                    tableName={BIDDING_TABLE_NAME.BID_ANALYSIS}
                    onClose={() =>
                        dispatch(updateBidAnalysis({ showBidAnalysisTableSettings: false }))
                    }
                    open={showBidAnalysisTableSettings}
                />
                {confirmRecommendationModalDetails.show && (
                    <ConfirmDecisionModal setCheckedRows={setCheckedRows} />
                )}
                {odpt3965MarketBalanceColumns && showMKTBalanceModal && selectedLane && (
                    <MKTBalanceModal
                        handleClose={() => {
                            setShowMKTBalanceModal(false);
                            setSelectedLane(null);
                        }}
                        lane={selectedLane}
                        market={selectedMarket}
                    />
                )}
            </>
        </ODTableProvider>
    );
};

export default BidAnalysisTable;
