import React, { useState, useEffect } from 'react';
import { isNil, isNumber, isEmpty } from 'lodash';
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Numeral from 'numeral';
import ProgressStatus from './ProgressStatus';
import theme from 'theme';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const FormattedKPIValue = ({
    reportValue = {},
    isPercentage = false,
    format = '0.0a',
    prefix = '',
    isDeltaMode,
    loadCount = 0,
    loadAvailable = 0,
    type
}) => {
    const { value, isCompared } = reportValue;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        isNil(value) &&
            setTimeout(() => {
                setLoading(false);
            }, 5000);

        return () => {
            setLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const displayArrow = (num) => {
        if (num === 0) {
            return num;
        } else {
            let isNumPositive = num > 0;
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    {isNumPositive ? (
                        <ArrowUpward
                            style={{
                                color: theme.palette.semantic.semanticGreen
                            }}
                            s
                        />
                    ) : (
                        <ArrowDownward
                            style={{
                                color: theme.palette.semantic.semanticRed
                            }}
                        />
                    )}

                    {Math.abs(num)}
                </Box>
            );
        }
    };

    if (isEmpty(reportValue)) {
        if (loading) {
            return (
                <CircularProgress
                    size={18}
                    style={{
                        color: theme.palette.black
                    }}
                />
            );
        } else {
            return <Box sx={{ color: theme.palette.neutral.neutral6 }}>Not Available</Box>;
        }
    } else {
        let arrowForSingleValue = <></>;

        let kpiValue = isNumber(value || 'NA') ? Numeral(value) : (value || 'NA').replace(/-/g, '');

        if (kpiValue !== 'NA')
            kpiValue =
                format === '0/0'
                    ? kpiValue
                    : isCompared
                    ? `${kpiValue.format('0.00')}%`
                    : `${prefix}${kpiValue.format(format).toLocaleUpperCase()}${
                          isPercentage ? '%' : ''
                      }`;

        if (Boolean(isDeltaMode)) {
            if (isNumber(value)) {
                if (type === 'percentCover') {
                    if (Math.abs(value) > 2) {
                        kpiValue = 'N/A';
                    }
                }
                if (value === 0) arrowForSingleValue = '';

                arrowForSingleValue =
                    value < 0 ? (
                        <ArrowDownward
                            style={{
                                fontSize: '14px',
                                color: theme.palette.semantic.semanticRed
                            }}
                        />
                    ) : (
                        <ArrowUpward
                            style={{
                                fontSize: '14px',
                                color: theme.palette.semantic.semanticGreen
                            }}
                        />
                    );
            } else if (typeof value === 'string') {
                kpiValue = (
                    <>
                        {displayArrow(loadCount)} / {displayArrow(loadAvailable)}
                    </>
                );
            }
        }

        return (
            <Box sx={{ display: 'flex' }}>
                {value && isCompared ? <ProgressStatus value={value} /> : ''}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '4px'
                    }}>
                    {kpiValue !== 'N/A' && arrowForSingleValue}
                </Box>
                {kpiValue}
            </Box>
        );
    }
};

export default FormattedKPIValue;
