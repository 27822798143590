import { Box, Typography, Divider } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { ODIconButton } from 'shared';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import { BUTTON_TYPE } from 'constants/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarSharpIcon from '@mui/icons-material/StarSharp';
import LaneScoreMetrics from './LaneScoreMetrics';
import { AlertTriangleIcon } from 'assets/images';
import CallSplitSharpIcon from '@mui/icons-material/CallSplitSharp';
import SubdirectoryArrowRightSharpIcon from '@mui/icons-material/SubdirectoryArrowRightSharp';
import {
    Title,
    AverageHeader,
    CustomCollapse,
    CustomAccordion,
    CustomAccordionSummary
} from './LaneScoreSlideout.styles';
import * as FS from '@fullstory/browser';
import { BIDDING_NAMES } from 'constants/bidding';
import { useFlags } from 'launchdarkly-react-client-sdk';

const LaneScoreAccordion = ({
    kpi,
    label,
    status,
    setOpenDrawer,
    openDrawer,
    averageValue,
    thresholdDescription,
    title,
    metricOptions,
    metricData,
    accordionState
}: {
    kpi: any;
    label: string;
    status: string;
    setOpenDrawer: any;
    openDrawer: any;
    title: string;
    averageValue: string | number;
    thresholdDescription: any;
    metricOptions: any;
    metricData: any;
    accordionState: boolean;
}) => {
    const { odpt3830NameConsistency } = useFlags();
    let StatusIcon = <></>;
    let MetricStatus = '';
    let cardColorByStatus = theme.palette.semantic.semanticGreen;
    let cardFontColorByStatus = theme.palette.white;

    switch (status) {
        case 'Ideal':
        case 'Mostly Ideal':
            StatusIcon = (
                <CircleIcon
                    fontSize="small"
                    sx={{
                        marginRight: '16px',
                        color: theme.palette.semantic.semanticGreen
                    }}
                />
            );
            MetricStatus = status;
            cardColorByStatus = theme.palette.semantic.semanticGreen;
            cardFontColorByStatus = theme.palette.white;
            break;
        case 'Tolerable':
        case 'Mostly Tolerable':
            StatusIcon = (
                <AlertTriangleIcon
                    style={{
                        marginRight: '16px',
                        fill: theme.palette.semantic.semanticYellow
                    }}
                />
            );
            MetricStatus = status;
            cardColorByStatus = theme.palette.semantic.semanticYellow;
            cardFontColorByStatus = theme.palette.black;
            break;
        case 'Unacceptable':
        case 'Mostly Unacceptable':
            StatusIcon = (
                <CloseIcon
                    fontSize="small"
                    sx={{
                        marginRight: '16px',
                        color: theme.palette.semantic.semanticRed
                    }}
                />
            );
            MetricStatus = status;
            cardColorByStatus = theme.palette.semantic.semanticRed;
            cardFontColorByStatus = theme.palette.white;
            break;
        default:
            StatusIcon = (
                <CloseIcon
                    fontSize="small"
                    sx={{
                        marginRight: '16px',
                        color: theme.palette.semantic.semanticRed
                    }}
                />
            );
            MetricStatus = status;
            cardColorByStatus = theme.palette.semantic.semanticRed;
            cardFontColorByStatus = theme.palette.white;
    }

    return (
        <>
            <CustomAccordion
                key={`min-load-count-accordion`}
                expanded={accordionState}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e) => {
                    _e.stopPropagation();
                    FS.event('Bid Analysis_ExpandLane Score KPI Container', { kpi: label });
                    setOpenDrawer({
                        ...openDrawer,
                        [label]: !accordionState
                    });
                }}>
                <CustomAccordionSummary
                    expandIcon={
                        <KeyboardArrowDownSharp sx={{ color: theme.palette.neutral.black }} />
                    }
                    aria-controls="accordion-content"
                    id={`accordion`}>
                    <AverageHeader>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {StatusIcon}
                            <Typography
                                style={{
                                    color: 'black',
                                    fontSize: '14px'
                                }}>
                                <b>{title}</b>:{` ${MetricStatus}`}
                            </Typography>
                        </Box>
                        <ODIconButton
                            enableTooltip={true}
                            title={kpi.description}
                            disableHover={true}
                            sx={{ marginRight: '2px', cursor: 'initial' }}
                            id={`helper-${label}`}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            icon={
                                <InfoOutlinedIcon
                                    sx={{ color: theme.palette.black }}
                                    fontSize="small"
                                />
                            }
                        />
                    </AverageHeader>
                </CustomAccordionSummary>
            </CustomAccordion>
            <CustomCollapse
                in={accordionState}
                timeout="auto"
                unmountOnExit
                key={`select-min-load-count-collapse`}>
                <Box sx={{ margin: '16px' }}>
                    <Title
                        sx={{
                            backgroundColor: cardColorByStatus,
                            color: cardFontColorByStatus
                        }}>
                        <StarSharpIcon sx={{ marginRight: '16px' }} />
                        <Typography>
                            <b>{`${title} Average`}</b>: {` ${averageValue}`}
                        </Typography>
                    </Title>

                    {label === 'covered' ? (
                        <Title
                            sx={{
                                borderRadius: '0',
                                marginTop: '16px',
                                height: '80px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                backgroundColor: theme.palette.white,
                                marginBottom: '16px',
                                marginX: '-16px',
                                borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                                borderBottom: `1px solid ${theme.palette.neutral.neutral1}`
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '40px',
                                    width: '360px'
                                }}>
                                <CallSplitSharpIcon sx={{ marginRight: '16px' }} />
                                <Typography>
                                    <b>Condition</b>:{' '}
                                    {`${
                                        odpt3830NameConsistency ? 'Load volumes' : 'Total loads'
                                    } are ${kpi?.condition?.comparison}`}
                                </Typography>
                            </Box>

                            <Divider
                                sx={{
                                    width: '95%',
                                    borderColor: theme.palette.neutral.neutral1
                                }}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '40px',
                                    width: '360px'
                                }}>
                                <SubdirectoryArrowRightSharpIcon sx={{ marginRight: '16px' }} />
                                <Typography>
                                    <b>
                                        {odpt3830NameConsistency
                                            ? BIDDING_NAMES.LOAD_VOLUME
                                            : 'Total Loads'}
                                    </b>
                                    : {kpi?.condition?.comparison_values}
                                </Typography>
                            </Box>
                        </Title>
                    ) : (
                        <Divider
                            sx={{
                                borderColor: theme.palette.neutral.neutral1,
                                marginY: '16px',
                                marginX: '-16px'
                            }}
                        />
                    )}

                    <LaneScoreMetrics options={metricOptions} data={metricData} />

                    <Divider
                        sx={{
                            width: '422px',
                            margin: '0 -16px',
                            borderColor: theme.palette.neutral.neutral1
                        }}
                    />

                    <Title
                        sx={{
                            marginTop: '16px',
                            backgroundColor:
                                status === 'Mostly Ideal' || status === 'Ideal'
                                    ? theme.palette.ODLightBlueNeutral.lightBlue1
                                    : theme.palette.ODLightBlueNeutral.white
                        }}>
                        <CircleIcon
                            fontSize="small"
                            sx={{
                                marginRight: '16px',
                                color: theme.palette.semantic.semanticGreen
                            }}
                        />
                        <Typography>
                            <b>Ideal</b>: {thresholdDescription?.ideal}
                        </Typography>
                    </Title>
                    <Title
                        sx={{
                            backgroundColor:
                                status === 'Mostly Tolerable' || status === 'Tolerable'
                                    ? theme.palette.ODLightBlueNeutral.lightBlue1
                                    : theme.palette.ODLightBlueNeutral.white
                        }}>
                        <AlertTriangleIcon
                            style={{
                                marginRight: '16px',
                                fill: theme.palette.semantic.semanticYellow
                            }}
                        />
                        <Typography>
                            <b>Tolerable</b>: {thresholdDescription?.tolerable}
                        </Typography>
                    </Title>
                    <Title
                        sx={{
                            backgroundColor:
                                status === 'Mostly Unacceptable' || status === 'Unacceptable'
                                    ? theme.palette.ODLightBlueNeutral.lightBlue1
                                    : theme.palette.ODLightBlueNeutral.white
                        }}>
                        <CloseIcon
                            fontSize="small"
                            sx={{
                                marginRight: '16px',
                                color: theme.palette.semantic.semanticRed
                            }}
                        />
                        <Typography>
                            <b>Unacceptable</b>: {thresholdDescription?.unacceptable}
                        </Typography>
                    </Title>
                </Box>
            </CustomCollapse>
        </>
    );
};

export default LaneScoreAccordion;
