import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Collapse,
    Accordion,
    AccordionSummary,
    Switch,
    Box,
    Typography,
    Slider
} from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { ODDrawer, ODInputWithAdornments, ODToggleButtonGroup } from 'shared';
import { settingsUpdate } from 'redux/actions';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import { RootState } from 'redux/reducers';
import {
    AGGREGATION_LEVEL,
    LOCATION_VIEW,
    toggleButtonsInfoLocationView
} from 'constants/settings';
import { ODDrawerProps } from 'shared/ODDrawer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as FS from '@fullstory/browser';

const CustomizedSubsectionTitle = styled(Box)(() => ({
    fontSize: '12px',
    color: theme.palette.neutral.neutral6,
    marginTop: '16px'
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: theme.palette.white,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.semantic.focusedBlue
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.black,
        boxSizing: 'border-box'
    }
}));

const AppSettingsSlideout = ({ show, onClose }: ODDrawerProps) => {
    const dispatch = useDispatch();
    const {
        locationVisualization,
        maxAggregationLevel,
        clusterValue,
        clusterAuto,
        aggregationLevel,
        minLoadCount
    } = useSelector(({ settingsReducer }: RootState) => settingsReducer);
    const { zipCodeAggregator } = useFlags();
    const { compareReport } = useSelector((state: RootState) => state.NetworkReducer);

    const handleSettingsUpdate = (value: any, name: any) => {
        if (value === (undefined || null) && !['clusterAuto', 'minLoadCount'].includes(name))
            return null;
        if (name === 'locationVisualization') {
            if (value === 'city-state')
                dispatch(settingsUpdate({ aggregationLevel: Number(maxAggregationLevel) }));
        }
        dispatch(settingsUpdate({ [name]: value }));
    };

    const [openDrawer, setOpenDrawer] = useState({
        localVisualizationType: true,
        minLoadCount: true,
        groupLocationByProx: false
    });

    const handleToggle = (name: any) => {
        if (name === 'aggregationLevel' && locationVisualization !== LOCATION_VIEW.ZIP_CODE) return;
        setOpenDrawer({ ...openDrawer, [name]: !openDrawer[name as keyof typeof openDrawer] });
    };

    function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
        }
    }

    const handleIncrement = () => {
        let count = Number(minLoadCount);
        count += 1;
        handleSettingsUpdate(count, 'minLoadCount');
    };

    const handleDecrement = () => {
        if (minLoadCount <= 1) return;
        let count = Number(minLoadCount);
        count -= 1;
        handleSettingsUpdate(count, 'minLoadCount');
    };

    return (
        <ODDrawer
            open={show}
            title={'Settings'}
            anchor="right"
            onClose={onClose}
            enableCustomizedButton={true}
            children={
                <>
                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`location-visualization-type-accordion`}
                        expanded={openDrawer?.localVisualizationType}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            setOpenDrawer({
                                ...openDrawer,
                                localVisualizationType: !openDrawer?.localVisualizationType
                            });
                        }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                marginTop: '2px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography
                                style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}>
                                Location Visualization Type
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={openDrawer?.localVisualizationType}
                        timeout="auto"
                        unmountOnExit
                        key={`location-visualization-collapse`}>
                        <Box
                            sx={{
                                margin: '16px 0'
                            }}>
                            <ODToggleButtonGroup
                                toggleButtons={toggleButtonsInfoLocationView}
                                value={locationVisualization}
                                handleAction={(value) => {
                                    if (value !== null) {
                                        FS.event(
                                            'Bid Analysis Table Settings_Change Location Type',
                                            {
                                                switchTo: value
                                            }
                                        );
                                        handleSettingsUpdate(value, 'locationVisualization');
                                    }
                                }}
                            />
                            {locationVisualization === LOCATION_VIEW.ZIP_CODE && (
                                <>
                                    {!zipCodeAggregator && compareReport?.id && (
                                        <Box sx={{ paddingTop: '16px', fontSize: '14px' }}>
                                            Driver zip code aggregation not available while in
                                            compare mode.
                                        </Box>
                                    )}

                                    <>
                                        <CustomizedSubsectionTitle>
                                            Zip Code Value
                                        </CustomizedSubsectionTitle>

                                        <RadioGroup
                                            id="aggregation-level"
                                            name="aggregation-level"
                                            sx={{
                                                marginTop: '4px',
                                                '& .MuiFormControlLabel-root': {
                                                    height: '40px',
                                                    margin: 0,
                                                    '&:hover': {
                                                        borderRadius: '4px',
                                                        backgroundColor:
                                                            !zipCodeAggregator && compareReport?.id
                                                                ? null
                                                                : theme.palette.ODLightBlueNeutral
                                                                      .lightBlue2
                                                    },
                                                    '.MuiFormControlLabel-label.Mui-disabled': {
                                                        color: theme.palette.neutral.neutral6
                                                    }
                                                },
                                                '& .MuiRadio-root': {
                                                    height: '40px',
                                                    width: '40px',
                                                    padding: '0px',
                                                    color: theme.palette.neutral.neutral6,
                                                    '&.Mui-checked': {
                                                        color: theme.palette.semantic.focusedBlue
                                                    },
                                                    '&.Mui-disabled': {
                                                        color: theme.palette.neutral.neutral6
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: '18px'
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent'
                                                    }
                                                },
                                                '& .MuiTypography-body1': {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            value={aggregationLevel}
                                            onChange={({ target: { value } }) => {
                                                FS.event(
                                                    'Bid Analysis Time_Analysis Settings_Change Location View',
                                                    {
                                                        switchTo: Number(value)
                                                    }
                                                );
                                                handleSettingsUpdate(
                                                    Number(value),
                                                    'aggregationLevel'
                                                );
                                            }}>
                                            {AGGREGATION_LEVEL.filter(
                                                (level) => level <= maxAggregationLevel
                                            ).map((level) => {
                                                return (
                                                    <FormControlLabel
                                                        key={level}
                                                        disabled={
                                                            !zipCodeAggregator && compareReport?.id
                                                        }
                                                        sx={{
                                                            backgroundColor:
                                                                aggregationLevel === level
                                                                    ? !zipCodeAggregator &&
                                                                      compareReport?.id
                                                                        ? theme.palette.neutral
                                                                              .neutral1
                                                                        : theme.palette
                                                                              .ODLightBlueNeutral
                                                                              .lightBlue1
                                                                    : 'transparent'
                                                        }}
                                                        value={level}
                                                        control={<Radio />}
                                                        label={level}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </>
                                </>
                            )}
                        </Box>
                    </Collapse>

                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`min-load-count-accordion`}
                        expanded={openDrawer?.minLoadCount}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e, expanded) => {
                            setOpenDrawer({
                                ...openDrawer,
                                minLoadCount: !openDrawer?.minLoadCount
                            });
                        }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                marginTop: '2px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography
                                style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}>
                                Minimum Load Count
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={openDrawer?.minLoadCount}
                        timeout="auto"
                        unmountOnExit
                        key={`select-min-load-count-collapse`}>
                        <Box sx={{ marginTop: '16px' }}>
                            <ODInputWithAdornments
                                sx={{ marginBottom: '16px' }}
                                value={minLoadCount}
                                inputLabel={'Select Minimum Load Count'}
                                handleRemoveOnClick={handleDecrement}
                                handleAddOnClick={handleIncrement}
                                onChange={(e: any) => {
                                    let value = Number(e.target.value).toFixed(0);
                                    handleSettingsUpdate(Number(value), 'minLoadCount');
                                }}
                            />
                        </Box>
                    </Collapse>

                    <Accordion
                        id="group-location-by-proximity"
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            },
                            '& .MuiSvgIcon-root': {
                                color: clusterAuto
                                    ? theme.palette.neutral.neutral6
                                    : theme.palette.black
                            }
                        }}
                        key={`group-location-by-prox-accordion`}
                        expanded={clusterAuto ? false : openDrawer?.groupLocationByProx}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            !clusterAuto &&
                                setOpenDrawer({
                                    ...openDrawer,
                                    groupLocationByProx: !openDrawer?.groupLocationByProx
                                });
                        }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                marginTop: '2px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography
                                sx={{
                                    color: clusterAuto ? 'grey' : 'black',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                <AntSwitch
                                    sx={{
                                        marginRight: '16px',
                                        cursor: 'pointer',
                                        '& .MuiSwitch-switchBase': {
                                            transitionDuration: '100ms',
                                            '&.Mui-checked': {
                                                transform: 'translateX(12px)',
                                                '& + .MuiSwitch-track': {
                                                    backgroundColor:
                                                        theme.palette.semantic.semanticGreen
                                                },
                                                '&.Mui-disabled + .MuiSwitch-track': {
                                                    opacity: 0.5
                                                }
                                            },
                                            '&.Mui-focusVisible .MuiSwitch-thumb': {
                                                color: theme.palette.semantic.semanticGreen
                                            }
                                        },
                                        '& .MuiSwitch-thumb': {
                                            //note: need this empty object for thumb to work
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: theme.palette.neutral.neutral3,
                                            opacity: 1,
                                            transition: theme.transitions.create(
                                                ['background-color'],
                                                {
                                                    duration: 100
                                                }
                                            )
                                        }
                                    }}
                                    inputProps={{ 'aria-label': 'groupbyLocationByProx' }}
                                    checked={!clusterAuto}
                                    size="small"
                                    onChange={({ target: { checked } }) => {
                                        !openDrawer?.groupLocationByProx &&
                                            handleToggle('clustering');
                                        handleSettingsUpdate(!checked, 'clusterAuto');
                                    }}
                                    defaultChecked
                                />
                                Group Locations by Proximity
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={openDrawer?.groupLocationByProx}
                        timeout="auto"
                        unmountOnExit
                        key={`select-group-locations-collapse`}>
                        <>
                            <CustomizedSubsectionTitle>
                                Select Group Locations by Proximity
                            </CustomizedSubsectionTitle>

                            <Box sx={{ height: '40px' }}>
                                <Slider
                                    disabled={Boolean(clusterAuto)}
                                    sx={{
                                        marginTop: '4px',
                                        '& .MuiSlider-thumb': {
                                            backgroundColor: theme.palette.white,
                                            height: '10px',
                                            width: '10px'
                                        },
                                        '& .MuiSlider-track': {
                                            color: theme.palette.semantic.focusedBlue
                                        },
                                        '& .MuiSlider-rail': {
                                            height: '6px',
                                            color: theme.palette.semantic.focusedBlue
                                        }
                                    }}
                                    valueLabelDisplay="auto"
                                    min={1}
                                    max={6}
                                    aria-label="slider for group locations"
                                    value={clusterValue}
                                    onChange={(_e, value) =>
                                        handleSettingsUpdate(value, 'clusterValue')
                                    }
                                    onKeyDown={preventHorizontalKeyboardNavigation}
                                />
                            </Box>
                        </>
                    </Collapse>
                </>
            }
        />
    );
};

export default AppSettingsSlideout;
