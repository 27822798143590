import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Box } from '@mui/material';
import { OutboundSharp } from '@mui/icons-material';
import { ODButton } from 'shared';
import theme from 'theme';
import { BUTTON_TYPE } from 'constants/colors';
import { updateBidAnalysis } from 'redux/actions';
import { FLOW_DIRECTION } from 'types/bidAnalysis';
import * as FS from '@fullstory/browser';

const FlowButton = ({
    icon,
    label,
    direction
}: {
    icon: JSX.Element;
    label: string;
    direction: FLOW_DIRECTION;
}) => {
    const dispatch = useDispatch();
    const { selectedFlowDirection } = useSelector((state: RootState) => state.BidAnalysisReducer);
    return (
        <ODButton
            startIcon={icon}
            buttonType={BUTTON_TYPE.BLUE}
            unselected={selectedFlowDirection !== direction}
            buttonText={label}
            onClick={() => {
                FS.event('Bid Analysis_Inflow_Outflow_Click_Toggle', { buttonType: direction });
                dispatch(updateBidAnalysis({ selectedFlowDirection: direction }));
            }}
        />
    );
};

const InflowOutflowButtons = () => {
    const { selectedFlowDirection } = useSelector((state: RootState) => state.BidAnalysisReducer);
    return (
        <Box sx={{ marginTop: '16px', display: 'flex', flexDirection: 'row' }}>
            <FlowButton
                icon={
                    <OutboundSharp
                        sx={{
                            transform: 'rotate(90deg)',
                            color:
                                selectedFlowDirection === FLOW_DIRECTION.IN
                                    ? theme.palette.neutral.white
                                    : theme.palette.neutral.neutral6
                        }}
                    />
                }
                label="Lane Inflow"
                direction={FLOW_DIRECTION.IN}
            />
            <FlowButton
                icon={
                    <OutboundSharp
                        sx={{
                            transform: 'rotate(15deg)',
                            color:
                                selectedFlowDirection === FLOW_DIRECTION.OUT
                                    ? theme.palette.neutral.white
                                    : theme.palette.neutral.neutral6
                        }}
                    />
                }
                label="Lane Outflow"
                direction={FLOW_DIRECTION.OUT}
            />
        </Box>
    );
};

export default InflowOutflowButtons;
