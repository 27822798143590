import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import MapContainer from 'components/NetworkView/MapView/MapContainer';
import NetworkViewFullWidthPanel from 'components/NetworkView/NetworkViewFullWidthPanel';
import SidePanel from './components/SidePanel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNetworkViewHook } from 'utils/hooks';

const MapView = () => {
    const { fullWidthMapViewTables } = useFlags();
    const { expandPanel } = useSelector((state) => state.NetworkReducer);
    const { expandTopNavbar } = useSelector((state) => state.NetworkReducer);
    const { currReport } = useNetworkViewHook();

    if (fullWidthMapViewTables) {
        return (
            <Box
                sx={{
                    backgroundColor: 'black',
                    flex: 5
                }}>
                <Box sx={{ position: 'relative', height: '100%' }}>
                    <MapContainer />
                </Box>
                <Box
                    sx={{
                        width: 'calc(100% - 56px)',
                        position: 'absolute',
                        bottom: 0,
                        maxHeight: 363,
                        height: expandPanel ? 363 : 'auto'
                    }}>
                    <NetworkViewFullWidthPanel />
                </Box>
            </Box>
        );
    } else {
        return (
            <Box
                sx={{
                    backgroundColor: 'black',
                    height: !expandTopNavbar ? 'calc(100vh - 57px)' : 'calc(100vh - 160px)'
                }}>
                <SidePanel currReport={currReport} />
                <MapContainer />
            </Box>
        );
    }
};

export default MapView;
