import React from 'react';
import { Backdrop, BackdropProps, Box, CircularProgress, Typography } from '@mui/material';
import theme from 'theme';
export interface ODLoaderProps extends BackdropProps {
    text: string;
}

const ODLoader = ({ open, text, ...rest }: ODLoaderProps) => {
    return (
        <Backdrop
            style={{ backgroundColor: `${theme.palette.black}40`, zIndex: 9999 }}
            open={open}
            {...rest}>
            <Box
                sx={{
                    width: 456,
                    height: 112,
                    borderRadius: '4px',
                    backgroundColor: theme.palette.white,
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: theme.palette.white,
                        p: 4,
                        width: '100%'
                    }}>
                    <Typography sx={{ fontSize: '18px' }}>{text}</Typography>
                    <CircularProgress color="success" size="24px" />
                </Box>
            </Box>
        </Backdrop>
    );
};

export default ODLoader;
