import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '100%',
        height: '6px',
        backgroundColor: '#231f29',
        borderRadius: 3
    },
    barColorPrimary: {
        backgroundColor: '#34cb81'
    },
    colorPrimary: {
        backgroundColor: '#231f29'
    },
    barColorSecondary: {
        backgroundColor: '#ef162e'
    },
    colorSecondary: {
        backgroundColor: '#231f29'
    },
    bar: {
        borderRadius: 5
    }
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    }
});

const LinearProgressBar = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <BorderLinearProgress {...props} />
        </div>
    );
};

export default LinearProgressBar;
