import React from 'react';
import { Tooltip } from '@mui/material';
import theme from 'theme';
import SwapHorizSharpIcon from '@mui/icons-material/SwapHorizSharp';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import ClearIcon from '@mui/icons-material/Clear';
import { REPORT_STATUS_LABEL_INFO } from './constants';
import { makeStyles } from '@mui/styles/';
import { REPORT_STATUS } from 'constants/network';
import { ODCircularProgress } from 'shared';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles(() => ({
    spanStyle: {
        paddingLeft: 8,
        position: 'relative'
    },
    statusContainer: {
        height: 40,
        marginRight: 16,
        display: 'flex',
        alignItems: 'center'
    }
}));

const ReportStatusInfo = ({ status, darkIcon = false }) => {
    const classes = useStyles();

    if (status === REPORT_STATUS.VFA_COMPLETED)
        return (
            <Tooltip title={'Retrained'}>
                <div className={classes.statusContainer}>
                    <SwapHorizSharpIcon sx={{ color: theme.palette.semantic.semanticGreen }} />
                    <span className={classes.spanStyle}> Retrained</span>
                </div>
            </Tooltip>
        );
    if (
        [
            REPORT_STATUS.PREPARING,
            REPORT_STATUS.DEFAULT,
            REPORT_STATUS.RUNNING,
            REPORT_STATUS.REFRESHING
        ].includes(status)
    )
        return (
            <Tooltip title={REPORT_STATUS_LABEL_INFO[status]}>
                <div className={classes.statusContainer}>
                    <ODCircularProgress
                        color={
                            darkIcon
                                ? theme.palette.semantic.semanticGreen
                                : theme.palette.neutral.white
                        }
                    />

                    <span className={classes.spanStyle}>{REPORT_STATUS_LABEL_INFO[status]}</span>
                </div>
            </Tooltip>
        );
    if (status === REPORT_STATUS.COMPLETED)
        return (
            <Tooltip title={REPORT_STATUS_LABEL_INFO[status]}>
                <div className={classes.statusContainer}>
                    <DoneSharpIcon sx={{ color: theme.palette.semantic.semanticGreen }} />
                    <span className={classes.spanStyle}>{REPORT_STATUS_LABEL_INFO[status]}</span>
                </div>
            </Tooltip>
        );
    if (status === REPORT_STATUS.FAILED)
        return (
            <Tooltip title={REPORT_STATUS_LABEL_INFO[status]}>
                <div className={classes.statusContainer}>
                    <ClearIcon sx={{ color: theme.palette.semantic.semanticRed }} />
                    <span className={classes.spanStyle}>{REPORT_STATUS_LABEL_INFO[status]}</span>
                </div>
            </Tooltip>
        );
    if (status === REPORT_STATUS.DRAFT)
        return (
            <Tooltip title={REPORT_STATUS_LABEL_INFO[status]}>
                <>
                    <EditIcon
                        sx={{
                            color: darkIcon
                                ? theme.palette.neutral.neutral6
                                : theme.palette.neutral.neutral3
                        }}
                    />
                    <span className={classes.spanStyle}>{REPORT_STATUS_LABEL_INFO[status]}</span>
                </>
            </Tooltip>
        );

    return null;
};

export default ReportStatusInfo;
