/* This palette allows you to globally acccess the colors, keeping code DRY and maintaining a consistent theme/color across the platform */
/* color ref: https://www.figma.com/file/PKc8iAkQ7oUbfWs8PqIrql/Guides?node-id=8119%3A345 */

const white = '#FFFFFF';
const black = '#000000';
const transparent = 'transparent';
const focusedBlue = '#2D6BFF';
const semanticYellow = '#FEB900';
const semanticGreen = '#37A267';

const palette = {
    primary: { main: focusedBlue },
    success: { main: semanticGreen },
    white,
    black,
    transparent,
    semantic: {
        semanticGreen,
        semanticGreenHover: '#238E53',
        semanticRed: '#C8222C',
        semanticRedHover: '#B40E18',
        semanticYellow,
        semanticYellowHover: '#EAA500',
        focusedBlue,
        focusedBlueHover: '#2456CC'
    },
    ODRed: {
        lightRed100: '#FAE9EA',
        darkRed: '#AB1D26'
    },
    ODBlue: {
        odBlue10: '#26222D',
        odBlue9: '#332D3C',
        odBlue8: '#453D51',
        odBlue7: '#4F465D',
        odBlue6: '#635874',
        odBlue5: '#6D6080',
        odBlue4: '#77698C',
        odBlue3: '#8C7F9F',
        odBlue2: '#968BA7',
        odBlue1: '#B6AEC2',
        odBlue0: '#CBC5D3',
        odBlueSearch: '#1D1A22'
    },
    ODLightBlueNeutral: {
        lightBlue10: '#477EFF',
        lightBlue9: '#5C8DFF',
        lightBlue8: '#709BFF',
        lightBlue7: '#85A9FF',
        lightBlue6: '#99B8FF',
        lightBlue5: '#ADC6FF',
        lightBlue4: '#C2D4FF',
        lightBlue3: '#D6E2FF',
        lightBlue2: '#EBF1FF',
        lightBlue1: '#F5F8FF'
    },
    neutral: {
        black: '#000000',
        neutral9: '#292929',
        neutral8: '#4D4D4D',
        neutral7: '#666666',
        neutral6: '#808080',
        neutral5: '#999999',
        neutral4: '#B3B3B3',
        neutral3: '#CCCCCC',
        neutral25: '#FDFDFD',
        neutral2: '#E6E6E6',
        neutral1: '#F2F2F2',
        search: '#F8F8F8',
        white: '#FFFFFF'
    },
    barColors: {
        focusedBlue: focusedBlue,
        semanticYellow: semanticYellow,
        accessibleBrown: '#A05527',
        accessibleLightBlue: '#6C91EF',
        accessibleOrange: '#E4780C',
        accessibleGreen: '#498221',
        accessibleBlue: '#186DB9',
        accessibleMagenta: '#D0396B',
        accessibleGray: '#8F90A3',
        accessiblePurple: '#9B48A8',
        accessiblePink: '#E9639B',
        accessibleDarkGray: '#6C677F',
        accessibleOlive: '#998700',
        accessibleTeal: '#169C85',
        accessibleLightGreen: '#aaffc3',
        accessibleDarkOlive: '#808000',
        accessibleBeige: '#ffd8b1',
        accessibleDarkBlue: '#000075',
        accessibleLightGray: '#a9a9a9',
        darkGreen: '#308b58',
        white: white
    }
};

export default palette;
