import { FLOW_DIRECTION, TARGET } from 'types/bidAnalysis';
import {
    ADD_TABLE_ERROR,
    CLEAR_TABLE_ERROR,
    UPDATE_BID_ANALYSIS_REDUCER,
    CLEAR_BID_ANALYSIS,
    CLEAR_BID_GOALS,
    SET_BID_ANALYSIS_APPLIED_BID_AND_DEFAULT_RATE_TYPE
} from '../constants/actionTypes';
import { CellError, DataRow, TableErrors } from 'shared/ODTable/ODTableTypes';
import { CellEdit } from 'shared/ODTable/ODTableTypes';
import { PREFERRED_RATE_TYPE, TIME_FRAME } from 'constants/settings';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
//TODO temp reducer/subject to change

interface BiddingAnalysisState {
    actionsAwaitingConfigurationCreation: CellEdit[];
    bidAnalysisSetting: {
        column_specific_time_aggregation: { [key: string]: TIME_FRAME };
        locationType: string;
        time_aggregation: string;
        zip_code_aggregation: number;
    };
    bidAnalysisColumnFormatSelections: {
        [dataCategory: string]: string;
    };
    bidAnalysisLoading: boolean;
    bidGoals: { lanes: number | null; volume: number | null; revenue: number | null };
    bidGoalType: TARGET | null;
    canMeetBidGoalWithQualifyingLanes: boolean;
    kpiPreviews: { lanes: number | null; volume: number | null; revenue: number | null };
    checkedRows: number[];
    currBidAnalysisData: any;
    currBidAnalysisLanes: DataRow[];
    displayAllLanes: boolean;
    openBidAnalysisSettingSlideout: boolean;
    openLaneScoreSlideout: boolean;
    refreshErr: boolean;
    lanesToMeetTarget: Set<number>;
    selectedFlowDirection: FLOW_DIRECTION | null;
    selectedLaneBreadcrumb: number | string | null;
    selectedLaneId: number | null;
    selectedLaneScoreId: string;
    selectedLaneScoreMetaData: any;
    showBidAnalysisTableSettings: boolean;
    confirmRecommendationModalDetails: { show: boolean; lanes?: DataRow[]; decision?: string };
    showFlowLanesTableSettings: boolean;
    userUpdatedSelectedLanes: boolean;
    showNomappingAlert: boolean;
    showTableDataErrorAlert: boolean;
    bidAnalysisTableDataErrors: TableErrors;
    showOutdatedReportAlert: boolean;
    appliedBidFiles: any;
    columnFilters: ColumnFiltersState;
    sortingState: SortingState;
    bidAnalysisDeltaValues: any;
}
interface BiddingAnalysisAction {
    type: string;
    payload: any;
    error?: any;
}
const initialState: BiddingAnalysisState = {
    actionsAwaitingConfigurationCreation: [],
    bidAnalysisColumnFormatSelections: {
        rate: PREFERRED_RATE_TYPE.PER_MILE
    },
    bidAnalysisLoading: false,
    bidAnalysisTableDataErrors: {},
    bidGoals: { lanes: null, volume: null, revenue: null },
    bidGoalType: null,
    canMeetBidGoalWithQualifyingLanes: true,
    checkedRows: [],
    currBidAnalysisData: {},
    currBidAnalysisLanes: [],
    displayAllLanes: true,
    kpiPreviews: { lanes: null, volume: null, revenue: null },
    lanesToMeetTarget: new Set([]),
    openBidAnalysisSettingSlideout: false,
    openLaneScoreSlideout: false,
    refreshErr: false,
    selectedFlowDirection: null,
    selectedLaneBreadcrumb: null,
    selectedLaneId: null,
    selectedLaneScoreId: '',
    selectedLaneScoreMetaData: {},
    showBidAnalysisTableSettings: false,
    userUpdatedSelectedLanes: false,
    confirmRecommendationModalDetails: { show: false },
    bidAnalysisSetting: {
        time_aggregation: 'report',
        column_specific_time_aggregation: {
            OMKT_balance: TIME_FRAME.REPORT,
            DMKT_balance: TIME_FRAME.REPORT
        },
        locationType: 'city-state',
        zip_code_aggregation: 5
    },
    showFlowLanesTableSettings: false,
    showNomappingAlert: false,
    showTableDataErrorAlert: false,
    showOutdatedReportAlert: false,
    appliedBidFiles: [],
    columnFilters: [],
    sortingState: [],
    bidAnalysisDeltaValues: null
};

const addTableErrors = (state: BiddingAnalysisState, action: BiddingAnalysisAction) => {
    const { errors } = action.payload;
    const updatedErrorsObject = { ...state.bidAnalysisTableDataErrors };
    errors.forEach((errorEntry: CellError) => {
        const { rowId, columnId } = errorEntry;
        if (!updatedErrorsObject[rowId]) {
            updatedErrorsObject[rowId] = [];
        }
        updatedErrorsObject[rowId].push({ rowId, columnId });
    });
    return {
        ...state,
        bidAnalysisTableDataErrors: updatedErrorsObject
    };
};

const clearTableErrors = (state: BiddingAnalysisState, action: BiddingAnalysisAction) => {
    const { errors } = action.payload;
    const updatedTableErrors = { ...state.bidAnalysisTableDataErrors };

    errors.forEach((error: CellError) => {
        const { rowId, columnId } = error;
        if (updatedTableErrors[rowId]) {
            updatedTableErrors[rowId] = updatedTableErrors[rowId].filter(
                (err) => err.columnId !== columnId
            );
            if (updatedTableErrors[rowId].length === 0) {
                delete updatedTableErrors[rowId];
            }
        }
    });

    return {
        ...state,
        bidAnalysisTableDataErrors: updatedTableErrors
    };
};

const clearBidAnalysis = (state: BiddingAnalysisState) => {
    return { ...state, ...initialState };
};

const updateBidAnalysis = (state: BiddingAnalysisState, action: BiddingAnalysisAction) => {
    return {
        ...state,
        ...action.payload
    };
};
const setAppliedBids = (state: BiddingAnalysisState, action: BiddingAnalysisAction) => {
    return {
        ...state,
        ...action.payload
    };
};

const clearBidGoals = (state: BiddingAnalysisState) => {
    return {
        ...state,
        bidGoals: { lanes: null, volume: null, revenue: null },
        bidGoalType: null,
        displayAllLanes: true,
        lanesToMeetTarget: new Set(),
        checkedRows: [],
        userUpdatedSelectedLanes: false,
        canMeetBidGoalWithQualifyingLanes: true
    };
};

const biddingReducer = (state = initialState, action: BiddingAnalysisAction) => {
    switch (action.type) {
        case UPDATE_BID_ANALYSIS_REDUCER:
            return updateBidAnalysis(state, action);
        case CLEAR_BID_ANALYSIS:
            return clearBidAnalysis(state);
        case CLEAR_BID_GOALS:
            return clearBidGoals(state);
        case ADD_TABLE_ERROR:
            return addTableErrors(state, action);
        case CLEAR_TABLE_ERROR:
            return clearTableErrors(state, action);
        case SET_BID_ANALYSIS_APPLIED_BID_AND_DEFAULT_RATE_TYPE:
            return setAppliedBids(state, action);
        default:
            return state;
    }
};

export default biddingReducer;
