/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { HeaderTopBar, ODTable } from 'shared';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { ODFooterActionBar } from 'shared';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import associatedReportsColumns from './AssociatedReportsColumns';
import SlideOutContainer from 'components/NetworkView/SlideOutContainer';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import { setSelectedReports } from 'redux/actions';
import { getAllBidAssociatedReports, updateBiddingState } from 'redux/actions';
import palette from 'theme/palette';
import { REPORT_STATUS } from 'constants/network';
import { ODTableProvider } from 'shared/ODTable/ODTableContext';

const AssocReportsPage = styled(Box)(() => ({
    backgroundColor: theme.palette.neutral.white,
    fontSize: '14px',
    width: '100%',
    margin: 0,
    padding: 0,
    overflowY: 'scroll',
    height: '90vh'
}));

const TableFooterContainer = styled(Box)(() => ({
    position: 'absolute',
    bottom: 8,
    right: 8,
    left: 8,
    margin: 0,
    padding: 0
}));

const NoAssociatedReportsBanner = styled(Box)(() => ({
    backgroundColor: theme.palette.neutral.search,
    color: theme.palette.neutral.neutral6,
    height: 40,
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const AssociatedReports = ({ open, setOpenAssocReports, bidConfigId }) => {
    const { allBidAssociatedReports } = useSelector((state) => state.BiddingReducer);
    const [reports, setReports] = useState([]);
    const [rowsToDelete, setRowsToDelete] = useState([]);
    const [checkedRow, setCheckedRow] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedReport = reports?.find((report) => report.id === checkedRow[0]);

    const viewReport = (report, sidebar = null) => {
        if (
            [REPORT_STATUS.COMPLETED, REPORT_STATUS.DRAFT, REPORT_STATUS.VFA_COMPLETED].includes(
                report.status
            )
        ) {
            dispatch(
                setSelectedReports({
                    selectedBaseReport: { id: report.id, enabled: true },
                    selectedCompareReport: {}
                })
            );
            const redirectUrl = `/reports/${
                report?.bucket?.id ? `bucket/${report.bucket.id}/` : `report-run/`
            }${report.id}${report.config_id ? `/config/${report.config_id}` : ''}/network/shipper${
                sidebar ? `/${sidebar}/` : '/'
            }`;
            history.push(redirectUrl, {
                report_run_id: report.id
            });
        }
        dispatch(updateBiddingState({ allBidAssociatedReports: [] }));
        return;
    };

    useEffect(() => {
        dispatch(getAllBidAssociatedReports(bidConfigId));
    }, [bidConfigId]);

    useEffect(() => {
        setReports(allBidAssociatedReports);
    }, [allBidAssociatedReports]);

    return (
        <>
            <SlideOutContainer
                onClose={() => setOpenAssocReports(false)}
                dark={false}
                open={open}
                anchor={'left'}
                headerStyle={{
                    height: 0,
                    fontWeight: 'normal',
                    fontSize: 18
                }}
                headerOptions={{
                    noBorder: true
                }}
                disableAutoFocus={true}>
                <AssocReportsPage>
                    <HeaderTopBar
                        visibleHeight={false}
                        onClose={() => {
                            dispatch(updateBiddingState({ allBidAssociatedReports: [] }));
                            return setOpenAssocReports(false);
                        }}
                        fileTypeName="Associated Reports"
                        fileTypeIcon={
                            <FolderSharpIcon sx={{ color: theme.palette.neutral.black }} />
                        }
                    />
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingLeft: '16px',
                            width: 'calc(100% - 32px)'
                        }}>
                        {reports.length > 0 ? (
                            <ODTableProvider
                                showTableSettingsButton={false}
                                checkedRows={checkedRow}
                                data={reports}
                                columnDefs={associatedReportsColumns}
                                rowsToDelete={rowsToDelete}
                                setCheckedRows={setCheckedRow}
                                showRowActions={false}
                                disableFilters={true}
                                radioCheckbox={true}
                                removeOption={true}>
                                <ODTable setRowsToDelete={setRowsToDelete} />
                            </ODTableProvider>
                        ) : (
                            <NoAssociatedReportsBanner>
                                No reports associated to this bid file
                            </NoAssociatedReportsBanner>
                        )}
                    </Box>
                    <TableFooterContainer>
                        {checkedRow.length > 0 && (
                            <ODFooterActionBar
                                primaryButtonStyle={{
                                    '&:disabled': {
                                        svg: { color: palette.neutral.neutral6 }
                                    }
                                }}
                                sx={{ margin: 0 }}
                                primaryButtonText="View Report"
                                primaryButtonDisabled={
                                    selectedReport?.status === 'running' ||
                                    selectedReport?.status === 'default'
                                }
                                handlePrimaryButton={() => viewReport(selectedReport)}
                                primaryButtonEndIcon={
                                    <ArrowForwardSharpIcon
                                        sx={{ color: theme.palette.neutral.white }}
                                    />
                                }
                                ribbonIcon={
                                    <FolderSharpIcon sx={{ color: theme.palette.neutral.white }} />
                                }
                                ribbonText={selectedReport?.report_name}
                            />
                        )}
                    </TableFooterContainer>
                </AssocReportsPage>
            </SlideOutContainer>
        </>
    );
};

export default AssociatedReports;
