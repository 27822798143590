import { Paper, Stack, Typography, styled } from '@mui/material';
import PageNotFoundImage from 'assets/images/page-not-found.png';
import palette from 'theme/palette';

const PageNotFoundContainer = styled(Paper)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundSize: 'auto 100%',
    backgroundImage: `url(${PageNotFoundImage})`,
    backgroundRepeat: 'no-repeat'
}));

const Section = styled(Stack)(() => ({
    color: palette.neutral.white,
    justifyContent: 'center',
    alignItems: 'center'
}));

const PageNotFound = () => {
    return (
        <PageNotFoundContainer>
            <Section aria-describedby="404 page not found text">
                <Typography fontSize={160} component="span">
                    404
                </Typography>
                <Typography textTransform="capitalize" component="span">
                    page not found
                </Typography>
            </Section>
        </PageNotFoundContainer>
    );
};

export default PageNotFound;
