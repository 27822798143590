export const REPORTS_SIDEBAR = ['settings', 'notifications', 'driver-aggregation-warning'];
export const REMOVE_BIDS_TO_EDIT_SHIPPER_TOOLTIP =
    'Remove bids to edit shipper, or edit lanes individually';

export enum REPORT_STATUS {
    COMPLETED = 'completed',
    VFA_COMPLETED = 'vfa_completed',
    FAILED = 'failed',
    RUNNING = 'running',
    INPROGRESS = 'inprogress',
    PREPARING = 'preparing',
    DRAFT = 'draft',
    DEFAULT = 'default',
    RETRAINED = 'retrained',
    REFRESHING = 'refreshing'
}

export enum SIMULATION_ACTION {
    CREATE = 'Create',
    SELECT = 'Select'
}

export enum INDICATOR_TYPE {
    CHANGED = 'changed',
    FLEETDELTA_GREATER = 'fleetDelta_>_externalDelta',
    COMPARE_TOTAL_DRIVER_GREATER = 'compareTotalDriver_>_totalDriver',
    TOTAL_DRIVER_GREATER = 'compareTotalDriver_>_totalDriver',
    EXTERNALDELTA_GREATER = 'fleetDelta_<_externalDelta',
    INCLUDES_BID_LANES = 'includes_bid_lanes',
    BID_LANE = 'bid_lane'
}
export const indicatorLinesWidth = 16;

export enum ROW_TYPE {
    DRIVER = 'driver',
    SHIPPER = 'shipper',
    LANE = 'lane'
}

export enum NETWORK_TABLE_TYPE {
    DRIVERS = 'drivers',
    SHIPPERS = 'shippers',
    LANES = 'lanes'
}

/*
 * Using an enum here helps to prevent using invalid load sources in code.
 * If we update the files that use this enum (mainly in NetworkView/) to typescript,
 * we can enforce this with a type instead of the enum.
 * Just like this: =>
 * export type LoadSource = "all" | "bid" | "network";
 */
export enum LOAD_SOURCE {
    ALL = 'all',
    BID = 'bid',
    NETWORK = 'network'
}

export enum NETWORK_TABLE_NAME {
    LANES = 'lanes_table',
    COMPARISON_LANES = 'comparison_lanes_table',
    SHIPPERS = 'shippers_table',
    COMPARISON_SHIPPERS = 'comparison_shippers_table',
    DOMICILES = 'domiciles_table',
    COMPARISON_DOMICILES = 'comparison_domiciles_table',
    DRIVER_TYPES = 'driver_types_table',
    COMPARISON_DRIVER_TYPES = 'comparison_driver_types_table',
    DRIVER_IDS = 'driver_ids_table',
    MOTIONS = 'motions_table'
}

export enum HISTORY_CHANGES_TYPES {
    DRIVER = 'driver',
    SHIPPER_LANE = 'shipper-lane',
    REMOVE = 'remove',
    BONUS = 'bonus',
    BID = 'bid',
    BID_ANALYSIS = 'bid-analysis',
    CHANGE_RATE = 'change-rate',
    CHANGE_BID_RATE_PER_MILE = 'change-bid-rate',
    CHANGE_BID_FLAT_RATE = 'change-bid-flat-rate',
    ADD = 'add',
    PREV = 'prev',
    ALL = 'all',
    DECISION = 'decision',
    VOLUME = 'volume'
}

export enum TABLE_TYPE {
    BID_ANALYSIS = 'bid-analysis',
    BID_CONFIG_TABLE = 'bid-config-table'
}

export const PANEL_TABLE_HEADER_TYPES = [
    'Shipper ID',
    'Pick Up',
    'Drop Off',
    'Customer ID',
    'Domicile',
    'Driver Type',
    'Driver',
    'Load ID'
];
export const RUN_SIMULATION_MESSAGE =
    'Running the simulation will produce an updated report & KPIs based on the current changes. After running, you can continue to make additional changes and produce additional simulations if needed.';

export const SAVE_DRAFT_MESSAGE =
    'There are unsaved changes to your report. Do you want to save your changes as a draft, or discard them?';

export const DUPLICATE_ERROR_MESSAGE = `This report name has already been used. Enter a unique name for the report.`;

export const CHARACTERS_100_ERROR_MESSAGE = `Ensure that the report name has no more than 100 characters.`;
