import React from 'react';
import { Box, Collapse } from '@mui/material';
import theme from 'theme';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles/';
import NetworkTablesSelector from './NetworkTable/NetworkTablesSelector';
import { useNetworkViewHook } from 'utils/hooks';

const useStyles = makeStyles((theme) => ({
    muiCollapseWrapper: {
        height: '100%'
    },
    muiCollapseWrapperInner: {
        height: '100%'
    }
}));

const NetworkViewFullWidthPanel = () => {
    const classes = useStyles();
    const { currReport } = useNetworkViewHook();
    const { expandPanel, isListView } = useSelector((state) => state.NetworkReducer);

    return (
        <Box
            sx={{
                padding: '0 16px',
                backgroundColor: ' #FDFDFD',
                color: theme.palette.black,
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}>
            <Collapse
                sx={{ height: expandPanel || isListView ? '100%' : 0 }}
                classes={{
                    wrapper: classes.muiCollapseWrapper,
                    wrapperInner: classes.muiCollapseWrapperInner
                }}
                in={expandPanel || isListView}>
                <NetworkTablesSelector currReport={currReport} />
            </Collapse>
        </Box>
    );
};

export default NetworkViewFullWidthPanel;
