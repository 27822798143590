import { useMemo } from 'react';
import { isEmpty, intersection } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'redux/reducers';
//utils
import { isActionAllowed, handleRowFilter, getTableName } from 'utils/network.util';
import { DefaultColumnFilter } from 'shared/Table/tableFilters';
import { PANEL_TABLE_HEADER_TYPES } from 'constants/network';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { DRIVER_TABLE_TYPE } from 'constants/drivers';
import { resetHiddenColumns, updateHiddenColumns, updateNetworkView } from 'redux/actions';
import { handleDriverRowFilter } from 'utils/drivers.util';
import { useParamsProps } from 'types/hooks';
import { useDriverHook, useNetworkViewHook } from 'utils/hooks';

const useShipperHook = () => {
    const dispatch = useDispatch();
    const { newSimulationFlow, movementsTableView, specialColumnPreferences1 } = useFlags();
    const { configuration } = useSelector((state: RootState) => state.ConfigurationReducer);
    const { filterBy } = useSelector((state: RootState) => state.ReportsReducers.loadFilters);
    const { checked } = useSelector((state: RootState) => state.NetworkReducer);
    const driverCheckedObj = useSelector((state: RootState) => state.DriversReducer.checked);
    const alertsContext = useAlerts();
    const params = useParams<useParamsProps>();
    const { compare_run } = params;
    const { currReport } = useNetworkViewHook();
    const { handleDriverEdit } = useDriverHook();

    const readOnly = currReport?.read_only || compare_run;

    const filterTypes = useMemo(
        () => ({
            text: (rows: any, id: number, filterValue: string) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return !(rowValue === undefined || rowValue === null)
                        ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter
        }),
        []
    );

    const handleShipperLaneEdit = (row: any) => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        let selectedLoad = row?.original ? row.original : row;
        if (isEmpty(selectedLoad) && !isEmpty(filterBy)) selectedLoad = filterBy;
        dispatch(
            updateNetworkView({
                enableEditRow: true,
                selectedLoad
            })
        );
    };

    const handleOnClickFilterSetShipperLane = (
        cell: any,
        row: any,
        i: number,
        tableDataType: string,
        enableFilter: boolean,
        onSetFilter: Function
    ) => {
        if (
            (tableDataType === 'shipper' &&
                (cell.column.Header === 'Shipper ID' || cell.column.Header === 'Customer ID')) ||
            (tableDataType === 'lane' &&
                (cell.column.Header === 'Pick Up' || cell.column.Header === 'Drop Off'))
        ) {
            handleRowFilter(
                i,
                row,
                enableFilter,
                onSetFilter,
                PANEL_TABLE_HEADER_TYPES,
                tableDataType
            );
        } else if (
            (tableDataType === DRIVER_TABLE_TYPE.DOMICILE && cell.column.Header === 'Domicile') ||
            (tableDataType === DRIVER_TABLE_TYPE.DRIVER_TYPES &&
                cell.column.Header === 'Driver Type') ||
            (tableDataType === DRIVER_TABLE_TYPE.DRIVER_ID && cell.column.Header === 'Driver')
        ) {
            handleDriverRowFilter(
                i,
                row,
                enableFilter,
                onSetFilter,
                movementsTableView,
                tableDataType
            );
        } else if (
            !readOnly &&
            enableFilter &&
            ['Fleet', 'External Cost/Mile', 'Revenue/Mile'].includes(cell.column.Header)
        ) {
            handleShipperLaneEdit(row);
        } else if (!readOnly && enableFilter && cell.column.Header === 'Total Drivers') {
            handleDriverEdit(row);
        }
    };

    // TODO make this handle driver types as well
    const isSelected = (row: any, tableDataType: string) => {
        switch (tableDataType) {
            case 'lane':
                return checked.selectedLanes.includes(row.original.id) | checked.allLanes;
            case 'shipper':
                return checked.selectedShippers.includes(row.original.id) | checked.allShippers;
            case DRIVER_TABLE_TYPE.DOMICILE:
                return (
                    driverCheckedObj.selectedDomicile.includes(row.original.name) |
                    driverCheckedObj.allDomiciles
                );
            case DRIVER_TABLE_TYPE.DRIVER_TYPES:
                return (
                    driverCheckedObj.selectedDriverType.includes(row.original.driverType) ||
                    driverCheckedObj.allDriverTypes
                );
            case DRIVER_TABLE_TYPE.DRIVER_ID:
                return (
                    driverCheckedObj.selectedDriverId.includes(row.original.id) ||
                    driverCheckedObj.allDriverIds
                );
            default:
                return;
        }
    };
    const resetColumnHeaderSelections = (tableDataType: string) => {
        dispatch(
            resetHiddenColumns(
                getTableName(tableDataType, Boolean(compare_run)),
                specialColumnPreferences1 ? 'specialColumnPreferences1' : null
            )
        );
    };
    const updateColumnHeaders = (hiddenColumns: string[], tableDataType: string) => {
        dispatch(
            updateHiddenColumns(getTableName(tableDataType, Boolean(compare_run)), hiddenColumns)
        );
    };

    const handleFreightBonusForSelectedRows = (
        type: string,
        selectedRows: any,
        bonusAppliedRowIds: any,
        unappliedBonusRows: any,
        checkedRowsIds: any
    ) => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        let selectedLoad = selectedRows;

        if (isEmpty(selectedLoad) && !isEmpty(filterBy)) selectedLoad = filterBy;

        if (type === 'remove') {
            if (selectedRows?.length) {
                let selectedAppliedBonusRows = intersection(bonusAppliedRowIds, checkedRowsIds);
                selectedLoad = selectedRows.filter((row: any) => {
                    return selectedAppliedBonusRows.includes(row?.id);
                });
            } else {
                if (bonusAppliedRowIds.includes(selectedRows?.id)) {
                    selectedLoad = selectedRows;
                } else selectedLoad = {};
            }
        } else {
            if (selectedRows?.length) {
                selectedLoad = selectedRows.filter((row: any) => {
                    return unappliedBonusRows.includes(row?.id);
                });
            } else {
                if (unappliedBonusRows.includes(selectedRows?.id)) {
                    selectedLoad = selectedRows;
                } else selectedLoad = {};
            }
        }

        dispatch(
            updateNetworkView({
                showFreightBonusModal: true,
                selectedLoad,
                isBonusModalForAdd: type === 'add' ? true : false
            })
        );
    };

    return {
        filterTypes,
        defaultColumn,
        handleShipperLaneEdit,
        handleOnClickFilterSetShipperLane,
        isSelected,
        resetColumnHeaderSelections,
        updateColumnHeaders,
        handleFreightBonusForSelectedRows
    };
};

export default useShipperHook;
