import { Box, Stack, Typography } from '@mui/material';
import theme from 'theme';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { getValueWithSignAndUnits } from 'utils/number.util';

const getColoredStripes = (lightColor: string, darkColor: string) => `repeating-linear-gradient(
    -45deg,
    ${darkColor},
    ${darkColor} 10px,
    ${lightColor} 10px,
    ${lightColor} 20px
  )`;
const greenStripes = getColoredStripes(
    theme.palette.barColors.darkGreen,
    theme.palette.semantic.semanticGreen
);
const redStripes = getColoredStripes(
    theme.palette.ODRed.darkRed,
    theme.palette.semantic.semanticRed
);

const CenterZeroText = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.neutral.neutral6,
    fontSize: '12px',
    position: 'absolute',
    bottom: '-20px',
    width: '100%'
}));
const TextSpace = styled(Box)(() => ({
    width: 'fit-content',
    paddingLeft: '8px',
    paddingRight: '8px'
}));
const BarsContainer = styled(Box)(() => ({
    position: 'relative'
}));
const ZeroMidline = styled(Box)(() => ({
    width: '1px',
    position: 'absolute',
    left: '50%',
    height: '100%',
    backgroundColor: theme.palette.neutral.neutral1
}));
const BarContainer = styled(Box)(() => ({
    position: 'relative',
    height: '24px'
}));
const BarLabel = styled(Typography)(() => ({
    fontSize: '12px',
    height: '24px',
    display: 'flex',
    alignItems: 'center'
}));
const OriginalBar = styled(Box)(
    ({
        originalWidth,
        isOriginalPositive
    }: {
        originalWidth: number;
        isOriginalPositive: boolean;
    }) => ({
        position: 'absolute',
        height: '100%',
        width: `${originalWidth}%`,
        backgroundColor: theme.palette.neutral.neutral2,
        left: isOriginalPositive ? '50%' : '',
        right: isOriginalPositive ? '' : '50%'
    })
);

const ChangeBar = styled(Box)(
    ({
        animatedChangeWidth,
        isOriginalPositive,
        originalValue,
        finalValue,
        originalWidth,
        valueBecameCloserToZero
    }: {
        animatedChangeWidth: number;
        isOriginalPositive: boolean;
        originalValue: number;
        finalValue: number;
        originalWidth: number;
        valueBecameCloserToZero: boolean;
    }) => ({
        position: 'absolute',
        height: '100%',
        width: `${animatedChangeWidth}%`,
        left:
            isOriginalPositive && originalValue < finalValue
                ? `calc(50% + ${originalWidth}%)`
                : !isOriginalPositive && originalValue < finalValue
                ? `calc(50% - ${originalWidth}%)`
                : '',
        right:
            isOriginalPositive && originalValue > finalValue
                ? `calc(50% - ${originalWidth}%)`
                : !isOriginalPositive && originalValue > finalValue
                ? `calc(50% + ${originalWidth}%)`
                : '',
        background: valueBecameCloserToZero ? greenStripes : redStripes,
        display: 'flex',
        justifyContent: finalValue > originalValue ? 'flex-end' : 'flex-start',
        alignItems: 'center'
    })
);
const Bar = styled(Box)(() => ({
    position: 'absolute',
    height: '100%'
}));
const FinalBar = styled(Bar)(
    ({
        changeColor,
        finalWidth,
        isFinalPositive
    }: {
        changeColor: string;
        finalWidth: number;
        isFinalPositive: boolean;
    }) => ({
        width: `${finalWidth}%`,
        backgroundColor: changeColor,
        left: isFinalPositive ? '50%' : '',
        right: isFinalPositive ? '' : '50%'
    })
);
const BarChartContainer = styled(Box)(() => ({
    border: `1px solid ${theme.palette.neutral.neutral1}`,
    borderRadius: '4px',
    marginTop: '4px',
    marginBottom: '4px',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex'
}));
interface DeltaBarChartProps {
    originalValue: number;
    finalValue: number;
    units: string;
}
const DeltaBarChart = ({ originalValue, finalValue, units }: DeltaBarChartProps) => {
    const total = Math.max(Math.abs(originalValue), Math.abs(finalValue));
    const originalWidth = ((Math.abs(originalValue) / total) * 100) / 2;
    const finalWidth = ((Math.abs(finalValue) / total) * 100) / 2;
    const isFinalPositive = finalValue >= 0;
    const isOriginalPositive = originalValue >= 0;
    const changeWidth = ((Math.abs(finalValue - originalValue) / total) * 100) / 2;
    const valueBecameCloserToZero = Math.abs(finalValue) < Math.abs(originalValue);
    const [arrowFits, setArrowFits] = useState(false);
    const [animatedChangeWidth, setAnimatedChangeWidth] = useState<number>(0);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    const displayedOriginalValue = getValueWithSignAndUnits(originalValue, units);
    const displayedResultingValue = getValueWithSignAndUnits(finalValue, units);
    const displayedChange = getValueWithSignAndUnits(finalValue - originalValue, units);
    const changeColor = valueBecameCloserToZero
        ? theme.palette.semantic.semanticGreen
        : theme.palette.semantic.semanticRed;
    useEffect(() => {
        if (animatedChangeWidth) setArrowFits(animatedChangeWidth > 10);
    }, [animatedChangeWidth]);
    useEffect(() => {
        if (changeWidth === animatedChangeWidth) clearInterval(intervalId);
    }, [intervalId, changeWidth, animatedChangeWidth]);

    useEffect(() => {
        const animationDuration = 500;
        const interval = 10;
        const steps = animationDuration / interval;
        const stepWidth = changeWidth / steps;
        const _intervalId = setInterval(() => {
            setAnimatedChangeWidth((prevWidth) => Math.min(prevWidth + stepWidth, changeWidth));
        }, interval);
        setIntervalId(_intervalId);
        return () => {
            clearInterval(_intervalId);
        };
    }, [changeWidth]);

    const renderArrow = () => {
        if (finalValue > originalValue)
            return <ArrowForward sx={{ color: 'white', marginRight: '4px' }} />;
        else if (finalValue < originalValue)
            return <ArrowBack sx={{ color: 'white', marginLeft: '4px' }} />;
    };

    return (
        <>
            <BarChartContainer position="relative" width={'100%'}>
                <TextSpace>
                    <Stack
                        direction="column"
                        gap={'2px'}
                        sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
                        <BarLabel>{originalValue <= 0 && displayedOriginalValue}</BarLabel>
                        <BarLabel>{finalValue <= 0 && displayedChange}</BarLabel>
                        <BarLabel
                            sx={{
                                color: changeColor,
                                fontWeight: 600
                            }}>
                            {finalValue <= 0 && displayedResultingValue}
                        </BarLabel>
                    </Stack>
                </TextSpace>
                <BarsContainer sx={{ flex: 1 }}>
                    <ZeroMidline />
                    <CenterZeroText>{`0${units}`}</CenterZeroText>
                    <Stack direction="column" gap={'2px'}>
                        <BarContainer sx={{ marginTop: '8px' }}>
                            <OriginalBar
                                originalWidth={originalWidth}
                                isOriginalPositive={isOriginalPositive}
                            />
                        </BarContainer>
                        <BarContainer>
                            <ChangeBar
                                animatedChangeWidth={animatedChangeWidth}
                                isOriginalPositive={isOriginalPositive}
                                originalWidth={originalWidth}
                                originalValue={originalValue}
                                finalValue={finalValue}
                                valueBecameCloserToZero={valueBecameCloserToZero}
                                id="changeBar">
                                {arrowFits && renderArrow()}
                            </ChangeBar>
                        </BarContainer>
                        <BarContainer sx={{ marginBottom: '8px' }}>
                            <FinalBar
                                finalWidth={finalWidth}
                                changeColor={changeColor}
                                isFinalPositive={isFinalPositive}
                            />
                        </BarContainer>
                    </Stack>
                </BarsContainer>
                <TextSpace>
                    <Stack
                        direction="column"
                        gap={'2px'}
                        sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
                        <BarLabel>{originalValue > 0 && displayedOriginalValue}</BarLabel>
                        <BarLabel>{finalValue > 0 && displayedChange}</BarLabel>
                        <BarLabel
                            sx={{
                                color: changeColor,
                                fontWeight: 600
                            }}>
                            {finalValue > 0 && displayedResultingValue}
                        </BarLabel>
                    </Stack>
                </TextSpace>
            </BarChartContainer>
        </>
    );
};

export default DeltaBarChart;
