import React, { useRef, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box } from '@mui/material';
import theme from 'theme';

const paperStyle = () => {
    return {
        marginTop: '2px',
        backgroundColor: theme.palette.neutral.neutral2,
        color: theme.palette.black,
        '& .MuiButtonBase-root': {
            color: theme.palette.black,
            '&.Mui-disabled': { color: theme.palette.neutral.neutral4 },
            cursor: 'pointer'
        },
        '& .MuiTypography-root': {
            color: theme.palette.black
        },
        '& .MuiPickersDay-root': {
            backgroundColor: 'transparent',
            '&.Mui-selected': {
                backgroundColor: theme.palette.semantic.focusedBlue
            }
        },
        '& .MuiCalendarPicker-root': { height: '300px' },
        '& .MuiClockPicker-root': {
            color: theme.palette.black,
            '& span': { color: 'inherit' }
        }
    };
};

const inputStyle = (isActive) => ({
    padding: '0 6px',
    '& .MuiSvgIcon-root': {
        color: isActive ? theme.palette.black : theme.palette.ODBlue.odBlue7
    },
    color: isActive ? theme.palette.black : theme.palette.ODBlue.odBlue7,
    '& .MuiInputAdornment-root': { marginRight: '6px' },
    '& .MuiInputBase-input': {
        color: isActive ? theme.palette.black : theme.palette.ODBlue.odBlue73
    }
});

export default function DateTimePickerWrapper({
    selectedValue,
    setSelectedValue,
    limit,
    unfilteredMax,
    unfilteredMin,
    inputRenderingFn
}) {
    const datePickerRef = useRef();
    const [isActive, setIsActive] = useState(false);

    const wrappedInputRenderingFn = () => {
        const inputElement = inputRenderingFn({ isActive, clickHandler: () => setIsActive(true) });
        return <Box ref={datePickerRef}>{inputElement}</Box>;
    };

    return (
        <DateTimePicker
            renderInput={wrappedInputRenderingFn}
            PopperProps={{
                anchorEl: datePickerRef.current,
                id: 'filter',
                placement: 'bottom-start'
            }}
            PaperProps={{
                sx: paperStyle()
            }}
            InputProps={{
                sx: inputStyle(isActive),
                value: selectedValue,
                disabled: true
            }}
            open={isActive}
            onClose={() => {
                setIsActive(false);
            }}
            onOpen={() => {
                setIsActive(true);
            }}
            onChange={(value) => {
                if (value instanceof Date) {
                    setSelectedValue(value);
                }
            }}
            minDateTime={unfilteredMin}
            maxDateTime={unfilteredMax}
            value={selectedValue ?? limit}
        />
    );
}
