import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import LazyLoad from 'react-lazy-load';
import { CommonCheckboxComponent } from 'shared';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import { DeleteReportGroupModal } from './DeleteGroupModal';
import { ShowAdvisories } from './ShowAdvisories';
import { makeStyles } from '@mui/styles/';
import theme from 'theme';

export const BucketItems = ({
    bucket,
    showAdvisories = false,
    isAllChecked = false,
    onRowChecked,
    index = 'all',
    editBucket,
    getBuckets
}) => {
    const useStyles = makeStyles((theme) => ({
        stickyHeader: {
            background: theme.palette.ODBlue.odBlue9,
            color: theme.palette.white,
            position: 'sticky',
            top: '0%',
            zIndex: 5,
            height: 40,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center'
        },
        bucketItem: {
            position: 'relative',
            borderRadius: '5px',
            height: 40,
            paddingRight: 16,
            display: 'flex',
            color: theme.palette.black,
            alignItems: 'center',
            '&:hover': {
                backgroundColor: theme.palette.neutral.neutral1
            }
        },
        ellipsis: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }));
    const classes = useStyles();
    const [advisories, toggleAdvisories] = useState(false);
    const [deleteRun, toggleDeleteRun] = useState(false);

    const isChecked = bucket.checked && !isAllChecked;

    return (
        <LazyLoad
            once
            overflow
            height={42}
            offset={20}
            throttle={0}
            offsetVertical={100}
            debounce={true}>
            <div
                style={{
                    marginBottom: 2,
                    fontSize: 14,
                    borderRadius: 4,
                    maxHeight: 40
                }}
                key={index}
                id={`bucket-item-${index}`}
                className={`bucket-item-${bucket.name.replace(/ /g, '-')}`}>
                <div className={bucket?.id === 'all' ? classes.stickyHeader : classes.bucketItem}>
                    <CommonCheckboxComponent
                        color={bucket?.id === 'all' ? theme.palette.white : theme.palette.black}
                        isChecked={bucket.checked || false}
                        onClick={(event) => onRowChecked(bucket, event.target.checked)}
                    />

                    <div style={{ width: '65%', display: 'inline-block' }}>
                        <Tooltip title={bucket.name}>
                            <div
                                style={{
                                    color:
                                        bucket?.id === 'all'
                                            ? theme.palette.white
                                            : theme.palette.black
                                }}
                                className={classes.ellipsis}>
                                {bucket.name}
                            </div>
                        </Tooltip>
                    </div>

                    {showAdvisories && (
                        <ShowAdvisories
                            isChecked={isChecked}
                            classes={classes}
                            advisories={advisories}
                            showNotification={false}
                            toggleAdvisories={toggleAdvisories}
                            onEdit={() => editBucket(bucket)}
                            onDelete={() => toggleDeleteRun(true)}
                        />
                    )}
                    {deleteRun && (
                        <DeleteReportGroupModal
                            type="bucket"
                            classes={classes}
                            records={[bucket]}
                            open={true}
                            icon={<ListAltSharpIcon sx={{ color: theme.palette.neutral.black }} />}
                            title={'Confirm Group Deletion'}
                            fetchData={getBuckets}
                            prefix={<b>Group Name:</b>}
                            onAction={() => toggleDeleteRun(false)}
                            onClose={() => toggleDeleteRun(false)}
                        />
                    )}
                </div>
            </div>
        </LazyLoad>
    );
};
