import React from 'react';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import ReportListTable from './ReportListTable';
import theme from 'theme';

const BucketAccordion = ({ rows, bucket, expanded, onChecked, onExpand, canSelectAll = true }) => {
    return (
        <div
            style={{ marginBottom: 2 }}
            key={`accordion-${bucket?.id}`}
            id={`bucket-accordion-${bucket?.id}`}>
            <Accordion
                key={`bucket-list-${bucket?.id}`}
                disabled={bucket?.count === 0}
                expanded={expanded === bucket?.id}
                style={{
                    opacity: expanded === bucket?.id ? 1 : 0.5
                }}
                sx={{
                    backgroundColor: theme.palette.neutral.neutral1,
                    color: `${theme.palette.neutral.black} !important`
                }}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e, expanded) => onExpand(expanded ? bucket?.id : null)}>
                <AccordionSummary
                    expandIcon={
                        <KeyboardArrowDownSharpIcon
                            sx={{
                                color: theme.palette.black
                            }}
                        />
                    }
                    aria-controls="accordion-content">
                    <ListAltSharpIcon
                        sx={{
                            color: theme.palette.black
                        }}
                    />
                    <Typography
                        style={{
                            paddingLeft: 16,
                            fontWeight: 600,
                            color: theme.palette.black
                        }}>
                        {bucket?.name}
                    </Typography>
                </AccordionSummary>
            </Accordion>
            {expanded === bucket?.id && rows.length > 0 && (
                <div key={`report-bucket-details-${bucket?.id}`}>
                    <ReportListTable
                        rows={rows}
                        id={bucket?.id}
                        onExpand={onExpand}
                        onChecked={onChecked}
                        canSelectAll={canSelectAll}
                    />
                </div>
            )}
        </div>
    );
};

export default BucketAccordion;
