/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ClearIcon from '@mui/icons-material/Clear';
import { CommonHeaderContainer } from 'shared';
import theme from 'theme';
import { FormControl, InputLabel, InputBase } from '@mui/material';

export const TextFieldContainer = (props) => {
    const {
        title = null,
        type,
        style,
        value,
        placeholder = '',
        variant = 'outlined',
        handleChange,
        autoComplete = 'off',
        endAdornment = null,
        disabled
    } = props;

    return (
        <FormControl variant={variant} style={{ width: '100%', ...style }}>
            {title && (
                <InputLabel shrink htmlFor={`${type}-native-label-placeholder`}>
                    {title}
                </InputLabel>
            )}
            <InputBase
                sx={{
                    backgroundColor: theme.palette.neutral.search,
                    color: theme.palette.ODBlue.odBlue3,
                    padding: '14px 24px',
                    borderRadius: '4px',
                    height: '100%',
                    '&.Mui-focused': {
                        backgroundColor: theme.palette.neutral.neutral1
                    }
                }}
                id={`${type}-adornment-weight`}
                value={value}
                autoComplete={autoComplete}
                disabled={disabled}
                endAdornment={endAdornment}
                placeholder={placeholder}
                onChange={handleChange}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight',
                    style: {
                        padding: 0,
                        color: theme.palette.black,
                        fontSize: 14
                    }
                }}
            />
        </FormControl>
    );
};

const SearchContainer = ({ searchText, updateSearchText, waittime = 900 }) => {
    const [search, setSearch] = useState(searchText);
    const [threshold, setThreshold] = useState(null);

    const update = (value) => {
        if (threshold) clearTimeout(threshold);
        const timerId = setTimeout(() => {
            updateSearchText(value);
        }, waittime);
        setThreshold(timerId);
    };

    const updateSearch = (value) => {
        setSearch(value);
        update(value);
    };

    return (
        <CommonHeaderContainer
            dark={false}
            justifyContent={'space-between'}
            noBorder
            headerStyle={{ marginTop: 16, marginBottom: 16, height: 48 }}>
            <TextFieldContainer
                type={'bucket_search'}
                id={'search_bucket_input'}
                placeholder={'Search Reports'}
                className={'select-report-runs'}
                multiple={true}
                value={search || ''}
                variant="filled"
                style={{
                    color: theme.palette.black,
                    fontSize: 14,
                    height: 48
                }}
                handleChange={(event) => updateSearch(event.target.value)}
                endAdornment={
                    search ? (
                        <ClearIcon
                            sx={{
                                color: theme.palette.black,
                                cursor: 'pointer'
                            }}
                            onClick={() => updateSearch(null)}
                            fill={theme.palette.black}
                        />
                    ) : (
                        <SearchSharpIcon
                            sx={{
                                color: theme.palette.black
                            }}
                        />
                    )
                }
            />
        </CommonHeaderContainer>
    );
};

export default SearchContainer;
