import {
    Avatar,
    Grid,
    IconButton,
    Button,
    InputAdornment,
    FormControl,
    OutlinedInput,
    Dialog,
    Tooltip,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Add, Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { reduce, map, cloneDeep, toNumber } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import LinearProgressBar from 'components/mapView/progressBar/LinearProgressBar';
import { updateEditDriver } from 'redux/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import theme from 'theme';
import { CommonCustomLabel } from 'shared';

const { Marker } = require('react-map-gl');
// const { Marker, Popup } = require("react-map-gl");

const useStyles = () => ({
    infoRowHead: {
        color: '#fff',
        backgroundColor: '#332d3c',
        padding: '8px 15px',
        fontSize: '14px'
    },
    closeIcon: {
        backgroundColor: '#453d51',
        height: '30px',
        width: '30px',
        '&:hover': {
            backgroundColor: '#453d51'
        }
    },
    addDriverIcon: {
        backgroundColor: '#453d51',
        height: '30px',
        width: '30px',
        '&:hover': {
            backgroundColor: '#453d51'
        }
    },
    iconButton: {
        fontSize: '14px',
        fontWeight: 'bold'
    },
    infoRowToggle: {
        backgroundColor: '#26222d',
        padding: '8px 20px',
        borderBottom: '1px solid #453d51'
    },
    groupToggle: {
        height: '32px',
        backgroundColor: '#332d3c !important',
        borderRadius: '16px !important'
    },
    selectedDriverToggle: {
        width: '162px',
        height: '32px',
        borderRadius: '16px!important',
        backgroundColor: '#453d51!important',
        color: '#ffffff!important',
        textTransform: 'capitalize',
        fontWeight: '400',
        fontSize: '14px'
    },
    defaultDriverToggle: {
        width: '162px',
        height: '32px',
        borderRadius: '16px!important',
        backgroundColor: '#332d3c!important',
        color: '#ffffff!important',
        textTransform: 'capitalize',
        fontWeight: '400',
        fontSize: '14px'
    },
    infoRowDriver: {
        backgroundColor: '#26222d',
        padding: '8px 18px',
        borderBottom: '1px solid #453d51',
        fontSize: '14px',
        fontWeight: '400',
        color: '#fff'
    },
    infoRowAdd: {
        backgroundColor: '#332d3c',
        padding: '8px 15px',
        fontSize: '14px',
        fontWeight: '400',
        color: '#fff'
    },
    infoRowDisable: {
        backgroundColor: '#B3B3B3',
        padding: '8px 15px',
        fontSize: '14px',
        fontWeight: '400',
        color: '#808080'
    },
    diableIcon: {
        backgroundColor: '#B3B3B3',
        height: '30px',
        width: '30px',
        '&:hover': {
            backgroundColor: '#B3B3B3'
        }
    },
    cancelBtn: {
        backgroundColor: '#332d3c',
        color: '#fff',
        border: '1px solid #453d51',
        fontSize: '14px',
        borderRadius: '50px',
        textTransform: 'capitalize',
        width: '100px',
        '&:hover': {
            backgroundColor: '#332d3c'
        }
    },
    saveBtn: {
        backgroundColor: '#453d51',
        color: '#fff',
        border: '1px solid #453d51',
        fontSize: '14px',
        borderRadius: '50px',
        textTransform: 'capitalize',
        width: '100px',
        '&:hover': {
            backgroundColor: '#453d51'
        }
    },
    driverAmount: {
        border: '1px solid #453d51',
        fontSize: '13px',
        color: '#fff',
        padding: '8px',
        marginLeft: '10px',
        '&:hover': {
            backgroundColor: '#453d51'
        }
    },
    barColor1: {
        backgroundColor: theme.palette.barColors.focusedBlue
    },
    barColor2: {
        backgroundColor: theme.palette.barColors.semanticYellow
    },
    barColor3: {
        backgroundColor: theme.palette.barColors.accessibleBrown
    },
    barColor4: {
        backgroundColor: theme.palette.barColors.accessibleLightBlue
    },
    barColor5: {
        backgroundColor: theme.palette.barColors.accessibleOrange
    },
    barColor6: {
        backgroundColor: theme.palette.barColors.accessibleGreen
    },
    barColor7: {
        backgroundColor: theme.palette.barColors.accessibleBlue
    },
    barColor8: {
        backgroundColor: theme.palette.barColors.accessibleMagenta
    },
    barColor9: {
        backgroundColor: theme.palette.barColors.accessibleGray
    },
    barColor10: {
        backgroundColor: theme.palette.barColors.accessiblePurple
    },
    barColor11: {
        backgroundColor: theme.palette.barColors.accessiblePurple
    },
    barColor12: {
        backgroundColor: theme.palette.barColors.accessibleDarkGray
    },
    barColor13: {
        backgroundColor: theme.palette.barColors.accessibleOlive
    },
    barColor14: {
        backgroundColor: theme.palette.barColors.accessibleTeal
    },
    barColor15: {
        backgroundColor: theme.palette.barColors.accessibleLightGreen
    },
    barColor16: {
        backgroundColor: theme.palette.barColors.accessibleDarkOlive
    },
    barColor17: {
        backgroundColor: theme.palette.barColors.accessibleBeige
    },
    barColor18: {
        backgroundColor: theme.palette.barColors.accessibleDarkBlue
    },
    barColor19: {
        backgroundColor: theme.palette.barColors.accessibleLightGray
    },
    barColor20: {
        backgroundColor: theme.palette.barColors.white
    },
    textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '8px'
    }
});
const ButtonGroup = ({ classes, driverType, handleDriverType }) => {
    return (
        <ToggleButtonGroup
            value={driverType}
            exclusive
            style={{ width: '100%' }}
            onChange={handleDriverType}
            className={classes.groupToggle}
            aria-label="text driverType">
            <ToggleButton
                value="count"
                aria-label="left aligned"
                className={
                    driverType === 'count'
                        ? classes.selectedDriverToggle
                        : classes.defaultDriverToggle
                }>
                Amount
            </ToggleButton>
            <ToggleButton
                value="percentage"
                aria-label="centered"
                className={
                    driverType === 'percentage'
                        ? classes.selectedDriverToggle
                        : classes.defaultDriverToggle
                }>
                Percentage
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

const InfoMarker = ({
    classes,
    domicile,
    onClose,
    addDomicile,
    showSidebar,
    onSave,
    isAggregated = false,
    isAdmin = false,
    isPopupOpen,
    isReadOnly,
    reportDriverTypes
}) => {
    const { allowEditOnAggregatedDrivers } = useFlags();
    const [toggleType, setToggleType] = React.useState('count');
    const [addDriver, setAddDriver] = React.useState(false);
    const driver_types = domicile?.driver_types || [];
    map(driver_types, (value, name) => {
        value.amountQuantity = (domicile.configAction || {})[name] || 0;
        value.percentageQuantity = 0;
        value.configValue = (domicile.configAction || {})[name] || 0;
    });
    const [driverType, setDriverType] = React.useState(driver_types);
    const { maxBy, total } = reduce(
        driverType,
        (store, value) => ({
            maxBy: Math.max(store.maxBy, value.count),
            total: store.total + value.count
        }),
        { maxBy: 0, total: 0 }
    );
    const handleDriverType = (_event, newDriverType) => {
        if (newDriverType !== null) setToggleType(newDriverType);
    };
    const handleSave = () => {
        map(driverType, (value, name) => {
            let qtyValue = toNumber(
                toggleType === 'percentage' ? value.percentageQuantity : value.amountQuantity
            );

            if (toggleType === 'percentage')
                qtyValue = Math.round((parseInt(value.count) / 100) * qtyValue);
            onSave({
                location: domicile?.location,
                isNew: domicile.newDomicile,
                domicile_id: domicile.name,
                driver_type: name,
                quantity: qtyValue
            });
            isPopupOpen(false);
        });
    };
    return (
        <div className={classes.infoMarker}>
            <Grid
                container
                className={classes.infoRowHead}
                alignItems="center"
                justifyContent={'space-between'}>
                <Grid item>{domicile?.domicileInfo || domicile?.name}</Grid>
                <Grid item>
                    <Avatar className={classes.closeIcon} onClick={onClose}>
                        <IconButton color="inherit" size="large">
                            <Close fill="#ffffff" className={classes.iconButton} />
                        </IconButton>
                    </Avatar>
                </Grid>
            </Grid>
            <Grid container className={classes.infoRowToggle}>
                <Grid item xs={12} container>
                    <ButtonGroup
                        classes={classes}
                        driverType={toggleType}
                        handleDriverType={handleDriverType}
                    />
                </Grid>
            </Grid>
            <DriverRow
                classes={classes}
                driver_types={driverType}
                updateDrivers={(drivers) => setDriverType(drivers)}
                format={toggleType}
                addDriver={addDriver}
                maxBy={maxBy}
                total={total}
                isPopupOpen={isPopupOpen}
                allDriverTypes={reportDriverTypes?.driver_types}
            />
            {isAdmin &&
                (addDriver ? (
                    <Grid
                        container
                        className={classes.infoRowAdd}
                        alignItems="center"
                        justifyContent={'space-between'}>
                        <Grid item>
                            <Button
                                className={classes.cancelBtn}
                                onClick={() => setAddDriver(false)}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.saveBtn} onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    !isReadOnly && (
                        <Grid
                            container
                            className={
                                !allowEditOnAggregatedDrivers && isAggregated
                                    ? classes.infoRowDisable
                                    : classes.infoRowAdd
                            }
                            onClick={() => {
                                if (!allowEditOnAggregatedDrivers && isAggregated)
                                    return showSidebar('driver-aggregation-warning');
                                addDomicile({});
                                setAddDriver(true);
                            }}
                            alignItems="center"
                            justifyContent={'space-between'}>
                            <Grid item>
                                <CommonCustomLabel
                                    flag={'carrierTrailers'}
                                    value={'Edit Trailer Amount'}
                                    defaultValue={'Edit Driver Amount'}
                                />
                            </Grid>
                            <Grid item>
                                <Avatar
                                    className={
                                        !allowEditOnAggregatedDrivers && isAggregated
                                            ? classes.diableIcon
                                            : classes.addDriverIcon
                                    }>
                                    <IconButton color="inherit" size="large">
                                        <Add fill="#ffffff" className={classes.iconButton} />
                                    </IconButton>
                                </Avatar>
                            </Grid>
                        </Grid>
                    )
                ))}
        </div>
    );
};
const DriverRow = ({
    classes,
    driver_types,
    format,
    addDriver,
    updateDrivers,
    maxBy,
    total,
    isPopupOpen,
    allDriverTypes
}) => {
    const isAmountToggle = format === 'count';
    const onSetDriverType = (name, value) => {
        let drivers = { ...driver_types };
        if (isAmountToggle) drivers[name] = { ...drivers[name], amountQuantity: value };
        else drivers[name] = { ...drivers[name], percentageQuantity: value };
        updateDrivers(drivers);
    };

    const onChange = (event, name, total) => {
        const value = event.target.value;
        let qtyValue = ['-%', '-'].includes(value) ? '-' : parseInt(value) || '';
        if (event.key === 'ArrowUp') qtyValue++;
        if (event.key === 'ArrowDown') qtyValue--;
        if (!isAmountToggle && qtyValue < -100) return;
        if (isAmountToggle && parseInt(total) + qtyValue < 0) return;
        onSetDriverType(name, qtyValue);
    };
    return (
        <>
            <Grid container className={classes.infoRowDriver} justifyContent={'space-between'}>
                <Grid item xs={5}>
                    <CommonCustomLabel
                        flag={'carrierTrailers'}
                        value={'Trailer Type'}
                        defaultValue={'Driver Type'}
                    />
                </Grid>
                {addDriver ? (
                    <Grid item xs={7}>
                        <CommonCustomLabel
                            flag={'carrierTrailers'}
                            value={'Total Trailers'}
                            defaultValue={'Total Drivers'}
                        />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={5}>
                            <CommonCustomLabel
                                flag={'carrierTrailers'}
                                value={'Total Trailers'}
                                defaultValue={'Total Drivers'}
                            />
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </>
                )}
            </Grid>
            {map(driver_types, (value, name) => {
                const idx = allDriverTypes.indexOf(name) + 1;
                const driverCount = value.amountQuantity + value.count;
                const driverTotal = value.amountQuantity + maxBy;
                const progress = (driverCount / driverTotal) * 100;
                return (
                    <Grid
                        container
                        className={classes.infoRowDriver}
                        justifyContent={'space-between'}
                        alignItems="center"
                        key={'addDriversAmount' + idx}>
                        <Grid item xs={5} className={classes.textEllipsis}>
                            <Tooltip title={name || ''}>
                                <span>{name}</span>
                            </Tooltip>
                        </Grid>
                        {addDriver ? (
                            <Grid item xs={7}>
                                <Grid
                                    container
                                    justifyContent={'space-between'}
                                    alignItems="center">
                                    <Grid item xs={1}>
                                        <div
                                            style={{
                                                width: `70%`
                                            }}>
                                            <LinearProgressBar
                                                classes={{
                                                    bar: classes[`barColor${value.index || idx}`]
                                                }}
                                                variant="determinate"
                                                value={100}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        container
                                        justifyContent={'flex-start'}
                                        style={{ fontSize: '13px', paddingLeft: '5px' }}>
                                        {isAmountToggle
                                            ? value.count
                                            : `${
                                                  total > 0
                                                      ? Math.ceil((value.count / total) * 100)
                                                      : 0
                                              }%`}
                                    </Grid>
                                    <Grid item xs={7}>
                                        <FormControl>
                                            <OutlinedInput
                                                id="driverAmount"
                                                className={classes.driverAmount}
                                                value={`${
                                                    isAmountToggle
                                                        ? value.amountQuantity
                                                        : value.percentageQuantity
                                                }${!isAmountToggle ? '%' : ''}`}
                                                onKeyDown={(e) => onChange(e, name, value.count)}
                                                onChange={(e) => onChange(e, name, value.count)}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                        style={{ marginLeft: '2px' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <ExpandLess
                                                                style={{
                                                                    color: '#fff',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onDoubleClick={() =>
                                                                    isPopupOpen(true)
                                                                }
                                                                onClick={() => {
                                                                    let qty = parseInt(
                                                                        isAmountToggle
                                                                            ? value.amountQuantity
                                                                            : value.percentageQuantity
                                                                    );

                                                                    qty += 1;
                                                                    onSetDriverType(name, qty);
                                                                }}
                                                            />
                                                            <ExpandMore
                                                                style={{
                                                                    color: '#fff',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onDoubleClick={() =>
                                                                    isPopupOpen(true)
                                                                }
                                                                onClick={() => {
                                                                    let qty = parseInt(
                                                                        isAmountToggle
                                                                            ? value.amountQuantity
                                                                            : value.percentageQuantity
                                                                    );
                                                                    if (
                                                                        isAmountToggle ||
                                                                        (!isAmountToggle &&
                                                                            qty > -100)
                                                                    )
                                                                        qty -= 1;
                                                                    if (
                                                                        isAmountToggle &&
                                                                        parseInt(value.count) +
                                                                            qty <
                                                                            0
                                                                    )
                                                                        return;
                                                                    onSetDriverType(name, qty);
                                                                }}
                                                            />
                                                        </div>
                                                    </InputAdornment>
                                                }
                                                inputProps={{
                                                    'aria-label': 'amount',
                                                    style: { padding: '0px' }
                                                }}
                                                labelWidth={0}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={4}>
                                    <div
                                        style={{
                                            width: `${progress}%`
                                        }}>
                                        <LinearProgressBar
                                            classes={{
                                                bar: classes[`barColor${value.index || idx}`]
                                            }}
                                            variant="determinate"
                                            value={100}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={3}
                                    alignItems="center"
                                    justifyContent={'flex-end'}
                                    style={{ fontSize: '12px' }}>
                                    {format === 'count'
                                        ? value.count
                                        : `${
                                              total > 0 ? Math.ceil((value.count / total) * 100) : 0
                                          }%`}
                                    {isAmountToggle &&
                                    value.amountQuantity &&
                                    value.amountQuantity !== 0
                                        ? ` (${value.amountQuantity > 0 ? '+' : ''}${
                                              value.amountQuantity
                                          })`
                                        : null}
                                </Grid>
                            </>
                        )}
                    </Grid>
                );
            })}
        </>
    );
};

class Markers extends React.Component {
    constructor() {
        super();
        this.state = {
            markerData: [],
            initialMarkerData: []
        };
        this.markerClick = this.markerClick.bind(this);
        this.onCloseMarkerInfo = this.onCloseMarkerInfo.bind(this);
    }

    markerClick(data) {
        if (this.props.disableInfo) return;
        let _markers = cloneDeep(this.props.markerData);
        _markers.forEach((marker) => {
            if (marker.name === data.name) {
                const _domicile = {
                    ...marker.domicile,
                    driver_types: marker.domicile.driverTypes
                };

                this.props.updateEditDriver(_domicile || null);
            } else marker.showInfo = marker.name === data.name;
        });
        this.props.openEditDriverSlideout();
        this.setState({ markerData: _markers });
    }

    onCloseMarkerInfo(data) {
        let markers = this.state.markerData;
        markers.forEach((marker) => {
            marker.showInfo = false;
        });
        this.setState({ markerData: markers }, () => this.props.isPopupOpen(false));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.markerData && !nextProps.showPopUp) {
            this.setState({
                markerData: nextProps.markerData,
                initialMarkerData: nextProps.markerData
            });
        }
    }

    render() {
        const {
            classes,
            addDomicile,
            onSave,
            isAdmin,
            isAggregated,
            isReadOnly,
            reportDriverTypes
        } = this.props;
        if (this.state.markerData) {
            return this.state.markerData.map((item, index) => (
                <React.Fragment key={index}>
                    <Marker key={item.name} longitude={item.longitude} latitude={item.latitude}>
                        <img
                            src={item.image}
                            alt={item.name}
                            style={{ opacity: item.opacity }}
                            onClick={() => this.markerClick(item)}
                        />
                    </Marker>
                    {item.showInfo && (
                        <Dialog
                            open={true}
                            keepMounted
                            maxWidth="xs"
                            TransitionProps={{ tabIndex: '', tabindex: '' }}
                            PaperProps={{ style: { borderRadius: '0px' } }}>
                            {/* <Popup
              latitude={item.latitude}
              longitude={item.longitude}
              style={{ zIndex: 100000 }}
              anchor="left"
            > */}
                            <InfoMarker
                                classes={classes}
                                domicile={item.domicile}
                                onClose={() => this.onCloseMarkerInfo(item)}
                                addDomicile={addDomicile}
                                onSave={onSave}
                                isAdmin={isAdmin}
                                isAggregated={isAggregated}
                                showSidebar={this.props.showSidebar}
                                isPopupOpen={this.props.isPopupOpen}
                                isReadOnly={isReadOnly}
                                reportDriverTypes={reportDriverTypes}></InfoMarker>
                            {/* </Popup> */}
                        </Dialog>
                    )}
                </React.Fragment>
            ));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        reportDriverTypes: state.ReportsReducers?.reportDriverTypes
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateEditDriver: (_domicile) => dispatch(updateEditDriver(_domicile))
});
export default withStyles(useStyles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(Markers)
);
