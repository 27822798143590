/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    Collapse,
    TextField,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';

import {
    setDraftConfigurationActions,
    updateConfiguration,
    getConfigurationActions,
    updateNetworkView,
    bulkUpdateLanesRateByShipper
} from 'redux/actions';

import { getLocation } from 'utils/common.util';
import { ODDrawer, ODInputWithAdornments } from 'shared';
import theme from 'theme';
import { useParamsProps } from 'types/hooks';
import { useLoadingHook } from 'utils/hooks';
import { useSimulationHook } from 'utils/hooks';
import { CurrencyFormatCustom } from '../TopNavbar/components/MaskedInput';
import { NETWORK_TABLE_TYPE } from 'constants/network';

export const CustomNumberInputRef = React.forwardRef((props, ref) => {
    const { onChange, value, disabled }: any = props;

    return (
        <TextField
            inputRef={ref}
            placeholder="0"
            disabled={disabled}
            sx={{ width: '100%' }}
            value={value}
            onKeyDown={(e) => {
                ['-', '_', '+'].includes(e?.key) && e?.preventDefault();
            }}
            onChange={onChange}
            name="numberformat"
            InputProps={{
                inputComponent: CurrencyFormatCustom as any,
                disableUnderline: true
            }}
            inputProps={{
                'data-testid': 'Enter-New-Revenue-Miles',
                min: 0,
                maxLength: 5,
                step: '1',
                style: { textAlign: 'center' }
            }}
            variant="standard"
        />
    );
});

const ShipperLaneDetailCard = styled(Box)(() => ({
    backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
    borderRadius: '4px',
    height: '40px',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 16px',
    alignItems: 'center',
    marginTop: '2px',
    fontSize: '14px'
}));

const EditShipperLaneSlideout = ({ open }: any) => {
    const dispatch = useDispatch();
    const { createSimulationOnEdit } = useSimulationHook();
    const { showLoader } = useLoadingHook();
    const { base_run, compare_run } = useParams<useParamsProps>();
    const [currentCount, setCurrentCount] = useState(0);
    const [revenuePerMile, setRevenuePerMile] = useState(0);
    const [selectedRow, setSelectedRow] = useState({
        type: null,
        id: null,
        load_count: null,
        revenue_per_mile: null,
        bid_id: []
    });
    const [toggleExpand, setToggleExpand] = useState({
        loadCount: true,
        revenueMiles: true
    });
    const { configurationActions, configuration } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );

    const currReportId = base_run || compare_run;
    const {
        loadFilters: { filterBy, isFilterSet = false }
    } = useSelector((state: RootState) => state.ReportsReducers);

    const actions = !configuration?.report_run ? configurationActions : [];
    const reportLocations = useSelector(
        (state: RootState) => state.ReportKPIReducer.reportLocations
    );
    const { bidConfigLocations } = useSelector((state: RootState) => state.BiddingReducer);
    const { locationVisualization } = useSelector((state: RootState) => state.settingsReducer);
    const { organization } = useSelector((state: RootState) => state.SignInReducer);
    const { currTab, selectedLoad } = useSelector((state: RootState) => state.NetworkReducer);

    const isOrgTypeShipper = organization?.org_type === 'shipper';

    const DISABLED_EDIT_BID_LANE_MSG = `Editing load counts on
bid lanes within the network view is disabled. To make any
volume edits, view the bid file and save updates.`;

    let isShipper = currTab === NETWORK_TABLE_TYPE.SHIPPERS;
    const lane =
        filterBy?.type === 'lane'
            ? filterBy?.id
            : (!isShipper || selectedRow?.type === 'lane') && selectedRow?.id;
    const laneName = lane ? lane?.split('-') : null;

    const shipperName =
        (isShipper && !isFilterSet) || (!isShipper && isFilterSet)
            ? selectedRow?.id
            : filterBy?.type === 'shipper'
            ? filterBy?.id
            : 'All';

    const EDIT_OPTIONS = [
        {
            id: 'currFleet',
            textInputLabel: 'Enter New Load Count',
            displayedValue: currentCount,
            disabled: !!selectedRow?.bid_id?.length
        },
        {
            id: 'currRevenue',
            textInputLabel: 'Enter New Revenue / Miles',
            displayedValue: revenuePerMile
        }
    ];
    const [editOptions, setEditOption] = useState(EDIT_OPTIONS);

    if (isFilterSet && currTab !== selectedRow?.type) isShipper = !isShipper;
    let selectedFilter: any[] | null = null;
    if (isFilterSet && filterBy?.type !== selectedRow?.type) selectedFilter = filterBy?.id || null;

    const shipperLaneConfig = actions?.filter(
        (x: any) =>
            x.item_type === 'shipper-lane' &&
            x.value[isShipper ? 'shipper' : 'lane'] === selectedRow?.id &&
            x.value[!isShipper ? 'shipper' : 'lane'] === selectedFilter
    );

    const configActions = shipperLaneConfig.reduce((store: any, action: any) => {
        store[action.type] = action;
        return store;
    }, {});

    useEffect(() => {
        setSelectedRow(selectedLoad);
        setCurrentCount(selectedLoad?.load_count);
        setRevenuePerMile(selectedLoad?.revenue_per_mile);
    }, [selectedLoad]);

    const handleClose = () => {
        dispatch(updateNetworkView({ enableEditRow: false }));
    };

    const handleCalculation = (
        value: number | string,
        index: any,
        calcType: string | null = null
    ) => {
        let options = [...editOptions] as any;
        if (index === 1) value = !value ? '$' : value;

        if (calcType === 'add') {
            options[index] = {
                ...options[index],
                displayedValue: Number(value) + 1
            };
        } else if (calcType === 'subtract' && Number(value) - 1 > 0) {
            options[index] = {
                ...options[index],
                displayedValue: Number(value) - 1
            };
        } else {
            options[index] = {
                ...options[index],
                displayedValue: Number(value)
            };
        }

        setEditOption(options);
    };

    const handleSave = async () => {
        handleClose();
        showLoader(true);
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;

        let rateChangeObj: any = {
            'current-rate': null,
            'rate-change': null
        };
        let actions: any = [];
        let type = '';
        let value: any = {
            shipper: null,
            lane: null,
            amount: 0,
            percentage: null
        };

        value[isShipper ? 'shipper' : 'lane'] = selectedRow?.id || null;
        if (filterBy?.type) value[filterBy?.type] = filterBy?.id || null;
        if (selectedRow?.revenue_per_mile !== Number(editOptions[1].displayedValue)) {
            type = 'change-rate';

            let calculatedAmt =
                Number(editOptions[1].displayedValue) - Number(selectedRow?.revenue_per_mile);

            const action = {
                type,
                id: configActions[type]?.id || null,
                item_type: 'shipper-lane',
                value: {
                    ...value,
                    minimum_rate: selectedRow?.revenue_per_mile,
                    amount: Number(calculatedAmt?.toFixed(2)),
                    isPositive: calculatedAmt > 0
                }
            };
            rateChangeObj['current-rate'] = selectedRow?.revenue_per_mile;
            rateChangeObj['rate-change'] = Number(calculatedAmt?.toFixed(2));

            actions.push(action);
        }

        if (selectedRow?.load_count !== Number(editOptions[0]?.displayedValue)) {
            type = 'add';
            const action = {
                type,
                id: configActions[type]?.id || null,
                item_type: 'shipper-lane',
                value: {
                    ...value,
                    load_count: selectedRow?.load_count,
                    amount: Number(editOptions[0].displayedValue) - Number(selectedRow?.load_count)
                }
            };
            actions.push(action);
        }

        await dispatch(setDraftConfigurationActions(actions));
        await dispatch(updateConfiguration(currReportId, simulationId, actions));
        if (
            isShipper &&
            !isFilterSet &&
            selectedRow?.revenue_per_mile !== Number(editOptions[1].displayedValue)
        ) {
            await dispatch(
                bulkUpdateLanesRateByShipper(
                    currReportId,
                    simulationId,
                    selectedRow?.id,
                    rateChangeObj
                )
            );
        }
        await dispatch(getConfigurationActions(currReportId, simulationId));

        showLoader(false);
    };

    useEffect(() => {
        setEditOption(EDIT_OPTIONS as any);
    }, [selectedRow]);

    return (
        <ODDrawer
            open={open}
            title={'Edit'}
            anchor="right"
            onSubmit={() => handleSave()}
            onClose={() => handleClose()}
            children={
                <>
                    {laneName ? (
                        <>
                            <ShipperLaneDetailCard>
                                <b style={{ marginRight: '4px' }}>Pick Up: </b>
                                {laneName.length > 0 ? (
                                    <Tooltip
                                        title={getLocation(
                                            laneName[0],
                                            reportLocations,
                                            'city-state-zip',
                                            bidConfigLocations
                                        )}>
                                        <Box>{`${getLocation(
                                            laneName[0],
                                            reportLocations,
                                            locationVisualization,
                                            bidConfigLocations
                                        )}`}</Box>
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </ShipperLaneDetailCard>
                            <ShipperLaneDetailCard>
                                <b style={{ marginRight: '4px' }}>Drop Off: </b>
                                {laneName.length > 0 ? (
                                    <Tooltip
                                        title={getLocation(
                                            laneName[1],
                                            reportLocations,
                                            'city-state-zip',
                                            bidConfigLocations
                                        )}>
                                        <Box>{`${getLocation(
                                            laneName[1],
                                            reportLocations,
                                            locationVisualization,
                                            bidConfigLocations
                                        )}`}</Box>
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </ShipperLaneDetailCard>
                        </>
                    ) : (
                        <ShipperLaneDetailCard>
                            <b style={{ marginRight: '4px' }}>Pick up & Drop Offs: </b> All
                        </ShipperLaneDetailCard>
                    )}

                    <ShipperLaneDetailCard>
                        <b style={{ marginRight: '4px' }}>
                            {isOrgTypeShipper ? 'Customer ID' : 'Shipper ID'}:
                        </b>
                        {shipperName}
                    </ShipperLaneDetailCard>

                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`current-fleet-load-count-accordion`}
                        expanded={toggleExpand?.loadCount}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) =>
                            setToggleExpand({
                                ...toggleExpand,
                                loadCount: !toggleExpand?.loadCount
                            })
                        }>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                marginTop: '16px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharpIcon
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography style={{ color: 'black', fontSize: '14px' }}>
                                <b style={{ marginRight: '4px' }}>Current Fleet Load Count:</b>
                                {selectedRow?.load_count}
                            </Typography>
                        </AccordionSummary>
                    </Accordion>

                    <Collapse
                        in={toggleExpand?.loadCount}
                        timeout="auto"
                        unmountOnExit
                        key={`new-load-count-collapse`}>
                        <Box
                            sx={{
                                marginTop: '16px'
                            }}>
                            {editOptions[0]?.disabled ? (
                                <Box sx={{ paddingTop: 1, paddingBottom: 2, fontSize: '14px' }}>
                                    {DISABLED_EDIT_BID_LANE_MSG}
                                </Box>
                            ) : (
                                ''
                            )}
                            <ODInputWithAdornments
                                value={editOptions[0]?.displayedValue}
                                inputLabel={'Enter New Load Count'}
                                handleRemoveOnClick={(e) => {
                                    handleCalculation(
                                        editOptions[0]?.displayedValue,
                                        0,
                                        'subtract'
                                    );
                                }}
                                handleAddOnClick={(e) => {
                                    handleCalculation(editOptions[0]?.displayedValue, 0, 'add');
                                }}
                                onChange={(e) => {
                                    handleCalculation(
                                        parseFloat(e.target.value).toFixed(0),
                                        0,
                                        null
                                    );
                                }}
                                disabled={editOptions[0]?.disabled}
                            />
                        </Box>
                    </Collapse>

                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`current-revenue-miles-accordion`}
                        expanded={toggleExpand?.revenueMiles}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) =>
                            setToggleExpand({
                                ...toggleExpand,
                                revenueMiles: !toggleExpand?.revenueMiles
                            })
                        }>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                marginTop: '16px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharpIcon
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography style={{ color: 'black', fontSize: '14px' }}>
                                <b style={{ marginRight: '4px' }}>Current Revenue / Miles:</b>
                                {selectedRow?.revenue_per_mile}
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={toggleExpand?.revenueMiles}
                        timeout="auto"
                        unmountOnExit
                        key={`new-revenue-miles-collapse`}>
                        <Box
                            sx={{
                                marginTop: '16px'
                            }}>
                            <ODInputWithAdornments
                                inputComponent={CustomNumberInputRef}
                                value={editOptions[1]?.displayedValue}
                                inputLabel={'Enter New Revenue / Miles'}
                                handleRemoveOnClick={(e) => {
                                    handleCalculation(
                                        editOptions[1]?.displayedValue,
                                        1,
                                        'subtract'
                                    );
                                }}
                                handleAddOnClick={(e) => {
                                    handleCalculation(editOptions[1]?.displayedValue, 1, 'add');
                                }}
                                onChange={(e: any) => {
                                    handleCalculation(e?.floatValue?.toFixed(2), 1, null);
                                }}
                            />
                        </Box>
                    </Collapse>
                </>
            }
        />
    );
};

export default EditShipperLaneSlideout;
