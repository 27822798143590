/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ODModal } from 'shared';
import { MODAL_TYPE, FILE_TYPE } from 'constants/bidding';
import { RootState } from 'types/redux';
import {
    deleteGroup,
    resetModalMode,
    deleteConfigFile,
    getAllBidFiles,
    deleteMultipleConfigFiles,
    resetBidFileManagerTable,
    downloadBidConfigFiles
} from 'redux/actions';
import ModalMessage from './ModalMessage';
import { convertBlobToDownload } from 'utils/common.util';

const BidModalWrapper = () => {
    const dispatch = useDispatch();
    const { modalMode } = useSelector((state: RootState) => state.BiddingReducer);

    let heading;
    let handleAction: (data: object) => any;
    let message;
    let enableCustomizedButton;
    let actionButtonText;

    switch (modalMode?.mode) {
        case MODAL_TYPE.DELETE_GROUP: {
            heading = 'Confirm group deletion';
            handleAction = (data: any): any => {
                dispatch(deleteGroup(data[0].id));
                dispatch(resetModalMode());
            };
            actionButtonText = 'Confirm';
            message = (
                <ModalMessage
                    modalData={modalMode}
                    helperText={`Deleting this group will move all associated files back to the the "All Files Group"`}
                />
            );
            break;
        }
        case MODAL_TYPE.DELETE_FILE: {
            heading = 'Confirm file deletion';
            handleAction = (data: any): any => {
                (data?.id?.length > 1
                    ? deleteMultipleConfigFiles(data?.id)
                    : deleteConfigFile(data?.id)
                ).then(() => {
                    dispatch(getAllBidFiles());
                    dispatch(resetModalMode());
                    dispatch(resetBidFileManagerTable());
                });
            };

            actionButtonText = 'Confirm';
            message = <ModalMessage modalData={modalMode} allowRemoveListItem />;
            break;
        }
        case MODAL_TYPE.DOWNLOAD_FILE: {
            heading = 'Download files';
            enableCustomizedButton = true;

            let handleAction = (data: any): any => {
                const filteredData = data?.data.filter(
                    (file: { status: FILE_TYPE }) =>
                        ![FILE_TYPE.DRAFT, FILE_TYPE.EMPTY].includes(file?.status)
                );

                const filteredCompletedFilesIds = filteredData.map(
                    (file: { id: string }) => file.id
                );

                downloadBidConfigFiles(filteredCompletedFilesIds).then((res) => {
                    let fileName = res?.headers['content-disposition']?.split('/tmp/')[1];

                    filteredCompletedFilesIds?.length > 1
                        ? convertBlobToDownload(res?.data, fileName, 'zip')
                        : convertBlobToDownload(res?.data, fileName, 'txt');
                });
                dispatch(resetModalMode());
            };
            actionButtonText = 'Download';
            message = (
                <ModalMessage
                    allowRemoveListItem
                    modalData={modalMode}
                    handleAction={handleAction}
                />
            );
            break;
        }
        case MODAL_TYPE.DOWNLOAD_GROUP_FILE: {
            heading = 'Download group files';
            enableCustomizedButton = true;

            let handleAction = (data: any): any => {
                const filteredData = data?.data.filter(
                    (file: { status: FILE_TYPE }) =>
                        ![FILE_TYPE.DRAFT, FILE_TYPE.EMPTY].includes(file?.status)
                );

                const parsedData = filteredData?.map((item: any) => item.id);

                downloadBidConfigFiles(parsedData).then((res) => {
                    let fileName = res?.headers['content-disposition']?.split('/tmp/')[1];

                    parsedData?.length > 1
                        ? convertBlobToDownload(res?.data, fileName, 'zip')
                        : convertBlobToDownload(res?.data, fileName, 'txt');
                });

                dispatch(resetModalMode());
                dispatch(resetBidFileManagerTable());
            };
            message = (
                <ModalMessage
                    allowRemoveListItem
                    modalData={modalMode}
                    handleAction={handleAction}
                />
            );
            break;
        }
        default:
            heading = '';
            handleAction = () => '';
            message = <></>;
            break;
    }

    return (
        <ODModal
            show={modalMode?.mode !== (MODAL_TYPE.NOSHOW || null || undefined)}
            heading={heading}
            handleClose={() => {
                dispatch(resetModalMode());
                dispatch(resetBidFileManagerTable());
            }}
            handleAction={() => handleAction(modalMode?.data)}
            actionButtonText={actionButtonText}
            enableCustomizedButton={enableCustomizedButton}>
            {message}
        </ODModal>
    );
};
export default BidModalWrapper;
