import React from 'react';
import { Drawer, Grid, Avatar, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CommonHeaderContainer } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDrawer-paper': {
            width: '456px',
            backgroundColor: theme.palette.white
        }
    },
    container: () => ({
        height: '40px',
        margin: '36px 0px'
    }),
    title: () => ({
        fontFamily: 'IBM Plex Sans',
        fontSize: '18px',
        fontWeight: 400,
        color: theme.palette.black
    }),
    close: () => ({
        '&.MuiAvatar-root': {
            height: '40px',
            width: '40px',
            backgroundColor: theme.palette.neutral.neutral1,
            marginLeft: '16px',
            '& svg': {
                height: '18px',
                width: '18px',
                fill: theme.palette.black,
                fontWeight: 400
            }
        }
    }),
    subContainer: () => ({
        height: '40px',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 600,
        fontSize: '14px',
        color: theme.palette.black,
        marginBottom: '16px'
    }),
    content: () => ({
        overflowY: 'auto',
        color: '#000000'
    }),
    avatarIcon: () => ({
        width: '20px',
        height: '20px',
        margin: 'auto',
        padding: 10,
        cursor: 'pointer',
        backgroundColor: theme.palette.neutral.neutral1
    })
}));

export default function SlideOut(props) {
    const {
        open = false,
        onClose,
        customHeaderContent = '',
        title,
        children,
        button,
        endIcon = null,
        endIconId = null,
        startIcon = null,
        anchor = 'right',
        headerStyle = {},
        headerOptions = {}
    } = props;
    const classes = useStyles();

    return (
        <Drawer
            anchor={anchor}
            open={open}
            style={{ zIndex: 1300 }}
            className={classes.root}
            onClose={onClose}>
            <CommonHeaderContainer
                justify={'space-between'}
                headerIcon={startIcon}
                endComponent={
                    <>
                        {customHeaderContent}
                        {endIcon && (
                            <Tooltip title="Close">
                                <Avatar
                                    className={classes.avatarIcon}
                                    onClick={onClose}
                                    id={endIconId}>
                                    {endIcon}
                                </Avatar>
                            </Tooltip>
                        )}
                    </>
                }
                headerStyle={headerStyle}
                header={title}
                {...headerOptions}
            />
            <Grid container className={classes.content}>
                {children}
            </Grid>
            {button && <Grid container>{button}</Grid>}
        </Drawer>
    );
}
