import { Box } from '@mui/material';
import BidGroupAccordion from './BidGroupAccordion';

interface BidGroupListProps {
    bids: any[];
    groups: any[];
    items: any[];
    onExpand: any;
    onChecked: any;
    expanded: number | string;
    showFooter: boolean;
    canSelectAll: boolean;
    toggleAllItemsInGroup?: (e: React.ChangeEvent<HTMLInputElement>, groupId: any) => void;
}
const BidGroupList = ({
    bids,
    groups,
    items,
    onExpand,
    onChecked,
    expanded,
    canSelectAll = true,
    toggleAllItemsInGroup = () => {}
}: BidGroupListProps) => {
    return (
        <Box>
            <BidGroupAccordion
                key={`bucket-list-acc-all`}
                group={{ id: 'all', name: 'All Bids' }}
                items={bids}
                expanded={expanded}
                onExpand={onExpand}
                onChecked={onChecked}
                canSelectAll={canSelectAll}
                toggleAllItemsInGroup={toggleAllItemsInGroup}
            />
            {groups
                // .filter(({ count }) => count > 0) // TODO include number of bid files in this group as a "count" parameter in each group of the /bidding/groups endpoint response
                .map((group, key) => (
                    <BidGroupAccordion
                        key={`bucket-list-acc-${key}`}
                        group={group}
                        items={items}
                        expanded={expanded}
                        onExpand={onExpand}
                        onChecked={onChecked}
                        canSelectAll={canSelectAll}
                        toggleAllItemsInGroup={toggleAllItemsInGroup}
                    />
                ))}
        </Box>
    );
};
export default BidGroupList;
