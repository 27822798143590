/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { getTableDataByIdAndFilters, setLoadFilters, updateNetworkView } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadingHook } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { getLocation } from 'utils/common.util';
import { formatDelta, convertNumberToCommas, abbreviateNumber } from 'utils/number.util';
import { shipperHeader, laneHeader } from '../Shipper/shipperColumns';
import { getKPIbyReportId } from 'redux/actions';
import {
    getLoadSourceQueryParam,
    getLoadsFilterObject,
    getFreightBonusQueryParam
} from 'utils/network.util';
import { Box } from '@mui/material';
import { NETWORK_TABLE_TYPE, ROW_TYPE } from 'constants/network';
import { NetworkTable } from '.';
import { useFlags } from 'launchdarkly-react-client-sdk';

const LoadsTablesContainer = ({ currReport }) => {
    const {
        v2KpIs,
        specialColumnPreferences1,
        hideTrueProfit,
        odpt4098HideOnTimeKpi,
        odpt4173HideCoverageKpi
    } = useFlags();
    const { base_run, compare_run, config_id } = useParams();
    const dispatch = useDispatch();
    const { showLoader } = useLoadingHook();

    const { isListView, currTab, checked, networkBreadcrumbs } = useSelector(
        (state) => state.NetworkReducer
    );
    const { configurationActions, configuration } = useSelector(
        (state) => state.ConfigurationReducer
    );
    const { reportFilters, loadFilters, loadSource, isFreightBonusApplied } = useSelector(
        (state) => state.ReportsReducers
    );
    const { filterBy, isFilterSet = false } = useSelector(
        (state) => state.ReportsReducers.loadFilters
    );
    const { reportLocations } = useSelector((state) => state.ReportKPIReducer);
    const { bidConfigLocations } = useSelector((state) => state.BiddingReducer);
    const { locationVisualization } = useSelector((state) => state.settingsReducer);
    const { hiddenColumnsArrays } = useSelector((state) => state.TableLayoutReducer);
    const { organization } = useSelector((state) => state.SignInReducer);
    const isOrgTypeShipper = organization?.org_type === ROW_TYPE.SHIPPER;

    const isShipperTab = currTab === NETWORK_TABLE_TYPE.SHIPPERS;
    let baseFilter = compare_run ? { compare_run_id: compare_run } : {};
    const sortColumn = compare_run ? 'delta_load_count' : 'load_count';

    const actions = configurationActions || [];

    const handleTableData = (data, type) => {
        return data?.map((row) => {
            const {
                id,
                load_count,
                total_load_count,
                true_profit_amount,
                true_profit_per_mile,
                revenue_per_mile,
                loaded_miles,
                average_loh,
                service_coverage,
                on_time,
                load_bid_count
            } = row;

            const isShipper = type === ROW_TYPE.SHIPPER;
            const splitLane = id.split('-');

            //default values
            let isRemoved = '';
            let isBonusApplied = false;
            let isChanged = { isChanged: false, numType: 0 };
            let fleet = `${load_count}`;
            let external = Number(total_load_count - load_count);
            let delta = '';
            let trueProfit = Number(true_profit_amount);
            let trueProfitDelta = null;
            let trueProfitPerMile = true_profit_per_mile;
            let trueProfitPerMileDelta = null;
            let revPerMile = `$${revenue_per_mile}`;
            let revPerMileRateChange = null;
            let revPerMileDelta = null;
            let loadedMiles = `${loaded_miles}%`;
            let loadedMilesDelta = null;
            let averageLoh = average_loh;
            let averageLohDelta = null;
            let serviceCoverage = `${service_coverage}%`;
            let serviceCoverageDelta = null;
            let onTime = `${on_time}%`;
            let onTimeDelta = null;
            let bidLanes = load_bid_count ?? Number(load_bid_count);
            let externalDelta = null;
            let selectedFilter = null;

            if (isFilterSet && filterBy?.type !== type) selectedFilter = filterBy?.id || null;

            const rate = !configuration?.report_run
                ? actions?.filter(
                      (x) =>
                          (x.type === 'change-rate' || x.type === 'add') &&
                          x.item_type === 'shipper-lane' &&
                          x.value[isShipper ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE] === id &&
                          x.value[!isShipper ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE] === selectedFilter
                  )
                : null;

            isRemoved = !configuration?.report_run
                ? actions?.find(
                      (x) =>
                          x.type === 'remove' &&
                          x.item_type === 'shipper-lane' &&
                          x.value[!isShipper ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE] ===
                              selectedFilter &&
                          x.value[isShipper ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE] === row?.id
                  )
                : null;

            isBonusApplied = actions?.find(
                (x) =>
                    x.type === 'bonus' &&
                    x.item_type === 'shipper-lane' &&
                    x.value.value === true &&
                    x.value[isShipper ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE] === row?.id
            );

            //change actions
            if (rate) {
                rate?.forEach((item) => {
                    let value = item?.value?.amount || 0;
                    delta = value.toString();
                    isChanged = { isChanged: true, numType: 0 };

                    if (value) value = value - load_count;
                    else if (value > 0) {
                        isChanged = { isChanged: true, numType: 'pos' };
                    } else if (value < 0) {
                        isChanged = { isChanged: true, numType: 'neg' };
                    }
                    if (item?.type === 'change-rate') {
                        revPerMileRateChange = `$${revenue_per_mile} (${
                            item?.value?.isPositive > 0 ? '+' : '-'
                        }$${Math.abs(delta)})`;
                    } else {
                        fleet = `${load_count} (${delta > 0 ? '+' : ''}${delta})`;
                    }
                });
            }

            if (compare_run) {
                const {
                    compare_load_count,
                    compare_loaded_miles,
                    compare_average_loh,
                    compare_service_coverage,
                    compare_on_time,
                    compare_revenue_per_mile,
                    compare_total_load_count,
                    compare_true_profit_amount,
                    compare_true_profit_per_mile,
                    delta_load_count,
                    delta_loaded_miles,
                    delta_average_loh,
                    delta_service_coverage,
                    delta_on_time,
                    delta_revenue_per_mile,
                    delta_true_profit_amount,
                    delta_true_profit_per_mile,
                    delta_total_load_count
                } = row;

                fleet = Number(compare_load_count);
                external = Number(compare_total_load_count - compare_load_count);
                externalDelta = Number(delta_total_load_count - delta_load_count);
                trueProfit = `$${abbreviateNumber(compare_true_profit_amount)}`;
                trueProfitDelta = formatDelta(delta_true_profit_amount, 'dollar', true);
                trueProfitPerMile = `$${convertNumberToCommas(compare_true_profit_per_mile)}`;
                trueProfitPerMileDelta = `${formatDelta(delta_true_profit_per_mile, 'dollar')}`;
                revPerMile = `$${convertNumberToCommas(compare_revenue_per_mile)}`;
                revPerMileDelta = `${formatDelta(delta_revenue_per_mile, 'dollar')}`;
                loadedMiles = `${convertNumberToCommas(compare_loaded_miles)}%`;
                loadedMilesDelta = `${formatDelta(delta_loaded_miles, 'percentage')}`;
                averageLoh = `${convertNumberToCommas(compare_average_loh)} `;
                averageLohDelta = `${formatDelta(delta_average_loh)}`;
                serviceCoverage = `${convertNumberToCommas(compare_service_coverage)}%`;
                serviceCoverageDelta = `${formatDelta(delta_service_coverage, 'percentage')}`;
                onTime = `${convertNumberToCommas(compare_on_time)}%`;
                onTimeDelta = `${formatDelta(delta_on_time, 'percentage')}`;
            }
            const deltaValue = parseInt((delta || '').replace(/[{()}]/g, ''));

            return isShipper
                ? {
                      ...row,
                      id,
                      delta: deltaValue,
                      fleet,
                      external,
                      isRemoved,
                      isBonusApplied,
                      isChanged,
                      trueProfit,
                      trueProfitDelta,
                      trueProfitPerMile,
                      trueProfitPerMileDelta,
                      revPerMile,
                      revPerMileRateChange,
                      revPerMileDelta,
                      loadedMiles,
                      loadedMilesDelta,
                      averageLoh,
                      averageLohDelta,
                      serviceCoverage,
                      serviceCoverageDelta,
                      onTime,
                      onTimeDelta,
                      type,
                      bidLanes,
                      externalDelta
                  }
                : {
                      isChanged,
                      ...row,
                      id: id,
                      pickUp: getLocation(splitLane[0], reportLocations, locationVisualization),
                      pickUpCityStateZip: getLocation(
                          splitLane[0],
                          reportLocations,
                          'city-state-zip',
                          bidConfigLocations
                      ),
                      dropOff: getLocation(splitLane[1], reportLocations, locationVisualization),
                      dropOffCityStateZip: getLocation(
                          splitLane[1],
                          reportLocations,
                          'city-state-zip',
                          bidConfigLocations
                      ),
                      fleet,
                      external,
                      isRemoved,
                      isBonusApplied,
                      trueProfit,
                      trueProfitDelta,
                      trueProfitPerMile,
                      trueProfitPerMileDelta,
                      revPerMile,
                      revPerMileRateChange,
                      revPerMileDelta,
                      loadedMiles,
                      loadedMilesDelta,
                      averageLoh,
                      averageLohDelta,
                      serviceCoverage,
                      serviceCoverageDelta,
                      onTime,
                      onTimeDelta,
                      type,
                      externalDelta
                  };
        });
    };

    const shipperData = handleTableData(reportFilters?.shippers, ROW_TYPE.SHIPPER);
    const laneData = handleTableData(reportFilters?.lanes, ROW_TYPE.LANE);

    const isShipperActionBar =
        (isShipperTab && !loadFilters?.isFilterSet) || (!isShipperTab && loadFilters?.isFilterSet);

    const handleCheckbox = (e, id, type) => {
        let filteredList = [];

        if (type === ROW_TYPE.LANE) {
            filteredList = [...checked.selectedLanes];
        } else {
            filteredList = [...checked.selectedShippers];
        }

        if (!e.target.checked) {
            const index = filteredList.indexOf(id);
            if (index >= 0) filteredList.splice(index, 1);
            dispatch(
                updateNetworkView({
                    checked: {
                        ...checked,
                        [type === ROW_TYPE.SHIPPER ? 'allShippers' : 'allLanes']: false,
                        [type === ROW_TYPE.SHIPPER ? 'selectedShippers' : 'selectedLanes']:
                            filteredList
                    }
                })
            );
        } else {
            filteredList.push(id);
            dispatch(
                updateNetworkView({
                    checked: {
                        ...checked,
                        [type === ROW_TYPE.SHIPPER ? 'selectedShippers' : 'selectedLanes']:
                            filteredList
                    }
                })
            );
        }

        const originalList =
            type === ROW_TYPE.SHIPPER ? reportFilters?.shippers : reportFilters?.lanes || [];
        const areAllRowsSelected = filteredList.length === originalList.length;

        if (areAllRowsSelected) {
            dispatch(
                updateNetworkView({
                    checked: {
                        ...checked,
                        [type === ROW_TYPE.SHIPPER ? 'selectedShippers' : 'selectedLanes']:
                            filteredList,
                        [type === ROW_TYPE.SHIPPER ? 'allShippers' : 'allLanes']: true
                    }
                })
            );
        }

        const filterObj = getLoadsFilterObject(
            areAllRowsSelected,
            type === ROW_TYPE.SHIPPER,
            filteredList,
            checked,
            loadFilters?.isFilterSet,
            loadSource
        );
        dispatch(
            getKPIbyReportId({
                runId: base_run,
                filter: {
                    ...filterObj,
                    ...baseFilter
                },
                v2KpIsFlag: v2KpIs
            })
        );

        const isOptionsOpen = filteredList.length > 0;

        dispatch(
            updateNetworkView({
                currShipperActionBar: isOptionsOpen
            })
        );
    };

    const onSetFilter = async (_filter, row) => {
        showLoader(true);
        let filterObj = {};
        let selected = [];
        selected.push(row?.original.id);

        dispatch(
            updateNetworkView({
                checked: {
                    ...checked,
                    [isShipperTab ? 'selectedShippers' : 'selectedLanes']: selected
                },
                currShipperActionBar: null,
                networkBreadcrumbs: {
                    ...networkBreadcrumbs,
                    [isShipperTab ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE]: _filter
                }
            })
        );
        if (isShipperTab) {
            filterObj = {
                ...baseFilter,
                shipper_ids: row?.original.id
            };
        } else {
            filterObj = { ...baseFilter, lanes: row?.original.id };
        }

        dispatch(
            getKPIbyReportId({
                runId: base_run,
                filter: { ...filterObj },
                v2KpIsFlag: v2KpIs
            })
        );

        await dispatch(
            getTableDataByIdAndFilters(
                base_run,
                {
                    [`${isShipperTab ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE}`]: row?.original?.id,
                    ...baseFilter,
                    ...getLoadSourceQueryParam(loadSource),
                    ...getFreightBonusQueryParam(isFreightBonusApplied)
                },
                v2KpIs
            )
        );
        const filterBy = {
            type: `${isShipperTab ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE}`,
            ...row?.original
        };
        await dispatch(setLoadFilters({ ...loadFilters, filterBy, isFilterSet: true }));
        showLoader(false);
    };

    const handleAllCheckbox = (e, rows, type) => {
        const rowsSelected = rows.map((row) => row.original.id);
        if (e.target.checked && e.target.dataset.indeterminate === 'false') {
            const originalList =
                type === ROW_TYPE.SHIPPER ? reportFilters?.shippers : reportFilters?.lanes || [];
            const areAllRowsSelected = rowsSelected.length === originalList.length;
            dispatch(
                updateNetworkView({
                    checked: {
                        ...checked,
                        [type === ROW_TYPE.SHIPPER ? 'allShippers' : 'allLanes']:
                            areAllRowsSelected,
                        [type === ROW_TYPE.SHIPPER ? 'selectedShippers' : 'selectedLanes']:
                            rowsSelected
                    }
                })
            );

            const filterObj = getLoadsFilterObject(
                areAllRowsSelected,
                type === ROW_TYPE.SHIPPER,
                rowsSelected,
                checked,
                loadFilters?.isFilterSet,
                loadSource
            );
            dispatch(
                getKPIbyReportId({
                    runId: base_run,
                    filter: { ...baseFilter, ...filterObj },
                    v2KpIsFlag: v2KpIs
                })
            );

            dispatch(
                updateNetworkView({
                    currShipperActionBar: type
                })
            );
        } else {
            dispatch(
                updateNetworkView({
                    checked: {
                        ...checked,
                        [type === ROW_TYPE.SHIPPER ? 'selectedShippers' : 'selectedLanes']: [],
                        [type === ROW_TYPE.SHIPPER ? 'allShippers' : 'allLanes']: false
                    }
                })
            );
            if (loadFilters?.isFilterSet) {
                let filterObj;
                if (!isShipperActionBar) {
                    filterObj = {
                        ...baseFilter,
                        shipper_ids: checked.selectedShippers[0]
                    };
                } else {
                    filterObj = { ...baseFilter, lanes: checked.selectedLanes[0] };
                }

                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter: { ...filterObj },
                        v2KpIsFlag: v2KpIs
                    })
                );
            } else {
                const filterObj = getLoadsFilterObject(
                    false,
                    type === ROW_TYPE.SHIPPER,
                    [],
                    checked,
                    loadFilters?.isFilterSet,
                    loadSource
                );
                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter: {
                            ...filterObj,
                            ...baseFilter
                        },
                        v2KpIsFlag: v2KpIs
                    })
                );
            }
            dispatch(
                updateNetworkView({
                    currShipperActionBar: null
                })
            );
        }
    };

    const params = {
        loadFilters: loadFilters,
        reportFilters: reportFilters,
        checked,
        readOnly: currReport?.read_only,
        handleCheckbox,
        handleAllCheckbox,
        isListView: isListView,
        compare_run: compare_run,
        isOrgTypeShipper: isOrgTypeShipper,
        hideTrueProfit: hideTrueProfit,
        odpt4098HideOnTimeKpi: odpt4098HideOnTimeKpi,
        odpt4173HideCoverageKpi: odpt4173HideCoverageKpi
    };

    const columnData = isShipperTab
        ? shipperHeader({
              ...params,
              isFilterSet,
              disableRow: loadFilters?.isFilterSet
          })
        : laneHeader({
              ...params,
              isFilterSet,
              disableRow: loadFilters?.isFilterSet
          });

    const columns = useMemo(() => columnData, [columnData]);
    const loadData = isShipperTab ? shipperData : laneData;
    const loadBottomData = isShipperTab ? laneData : shipperData;

    const filterData = useMemo(
        () => loadData || [],
        [
            loadData,
            config_id,
            reportFilters?.lanes,
            reportFilters?.shippers,
            configuration,
            configurationActions
        ]
    );

    const nestedData = useMemo(
        () => loadBottomData || [],
        [
            loadBottomData,
            config_id,
            reportFilters?.lanes,
            reportFilters?.shippers,
            configuration,
            configurationActions,
            config_id
        ]
    );

    const primaryTableProps = {
        isListView: isListView,
        sortColumn: sortColumn,
        columns: columns,
        enableCursorRow: !loadFilters?.isFilterSet,
        data: filterData,
        onSetFilter: (filter, row) => onSetFilter(filter, row),
        enableFilter: !loadFilters?.isFilterSet,
        handleAllCheckbox: handleAllCheckbox,
        loadFilters: loadFilters
    };

    const drilledInTableProps = {
        isListView: isListView,
        enableCursorRow: false,
        enableFilter: true,
        sortColumn: sortColumn,
        onSetFilter: (filter) => null,
        handleAllCheckbox: handleAllCheckbox,
        loadFilters: loadFilters,
        drilledIn: true,
        topTable: false
    };

    const shipperTableProps = {
        hiddenColumns: compare_run
            ? [...hiddenColumnsArrays.comparison_shippers_table]
            : [...hiddenColumnsArrays.shippers_table],
        tableDataType: ROW_TYPE.SHIPPER,
        lockedColumnsIds:
            compare_run && specialColumnPreferences1
                ? [
                      'fleet',
                      'serviceCoverage',
                      'revenue_per_mile',
                      'loadedMiles',
                      'averageLoh',
                      'id'
                  ]
                : ['id']
    };
    const laneTableProps = {
        hiddenColumns: compare_run
            ? [...hiddenColumnsArrays.comparison_lanes_table]
            : [...hiddenColumnsArrays.lanes_table],
        tableDataType: ROW_TYPE.LANE,
        lockedColumnsIds:
            compare_run && specialColumnPreferences1
                ? [
                      'pickUp',
                      'dropOff',
                      'fleet',
                      'serviceCoverage',
                      'revenue_per_mile',
                      'loadedMiles',
                      'averageLoh'
                  ]
                : ['pickUp', 'dropOff']
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}>
            {loadFilters?.isFilterSet ? (
                <>
                    {isShipperTab ? (
                        <>
                            <NetworkTable
                                {...shipperTableProps}
                                {...primaryTableProps}
                                topTable={true}
                            />
                            <NetworkTable
                                {...drilledInTableProps}
                                {...laneTableProps}
                                columns={laneHeader(params)}
                                data={nestedData}
                            />
                        </>
                    ) : (
                        <>
                            <NetworkTable
                                {...shipperTableProps}
                                {...primaryTableProps}
                                topTable={true}
                            />
                            <NetworkTable
                                {...laneTableProps}
                                {...drilledInTableProps}
                                columns={shipperHeader(params)}
                                data={nestedData}
                            />
                        </>
                    )}
                </>
            ) : isShipperTab ? (
                <NetworkTable {...shipperTableProps} {...primaryTableProps} topTable={false} />
            ) : (
                <NetworkTable {...laneTableProps} {...primaryTableProps} topTable={false} />
            )}
        </Box>
    );
};

export default LoadsTablesContainer;
