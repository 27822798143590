import { useState } from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import { ODAvatar } from 'shared';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
    CrossColumnCopyDetails,
    ColumnHeaderData,
    CrossColumnCopyOptionsButtonProps
} from './ODTableTypes';
import { Header } from '@tanstack/react-table';
import { DataRow } from './ODTableTypes';

const CrossColumnCopyOptionsDropdown = styled(Box)(() => ({
    position: 'absolute',
    top: '40px',
    right: 100,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.neutral.neutral2,
    zIndex: 2,
    borderRadius: '2px'
}));
interface CrossColumnCopyHeaderCellProps {
    crossColumnCopyDetails: CrossColumnCopyDetails;
    copyValueBetweenColumns: (sourceColumnId: string, destColumnId: string) => void;
    resetFillDown: () => void;
    header: Header<DataRow, any>;
}

const CrossColumnCopyOptionsButton = ({
    text,
    ...props
}: CrossColumnCopyOptionsButtonProps & ButtonProps) => (
    <Button
        sx={{
            textTransform: 'none',
            color: theme.palette.neutral.black,
            '&:hover': {
                backgroundColor: theme.palette.neutral.neutral3
            }
        }}
        {...props}>
        {text}
    </Button>
);

const CrossColumnCopyHeaderCell = ({
    crossColumnCopyDetails,
    copyValueBetweenColumns,
    resetFillDown
}: CrossColumnCopyHeaderCellProps) => {
    const [showCrossColumnCopyOptionsDropdown, setShowCrossColumnCopyOptionsDropdown] =
        useState<boolean>(false);
    const { destColumnHeaderData, sourceColumns } = crossColumnCopyDetails;
    return (
        <Box
            sx={{
                height: 'fit-content',
                zIndex: 1
            }}
            id="header-action">
            <ODAvatar
                onClick={() =>
                    setShowCrossColumnCopyOptionsDropdown(!showCrossColumnCopyOptionsDropdown)
                }>
                <AddCircleOutlineOutlinedIcon fill={theme.palette.neutral.black} />
            </ODAvatar>
            {showCrossColumnCopyOptionsDropdown && (
                <CrossColumnCopyOptionsDropdown>
                    {sourceColumns.map((sourceCol: ColumnHeaderData) => (
                        <CrossColumnCopyOptionsButton
                            key={`${sourceCol.columnId}-cross-column-copy-option`}
                            onClick={() => {
                                copyValueBetweenColumns(
                                    sourceCol.columnId,
                                    destColumnHeaderData.columnId
                                );
                                resetFillDown();
                                setShowCrossColumnCopyOptionsDropdown(false);
                            }}
                            text={`Insert ${sourceCol.columnName}`}
                        />
                    ))}
                </CrossColumnCopyOptionsDropdown>
            )}
        </Box>
    );
};

export default CrossColumnCopyHeaderCell;
