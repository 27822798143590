import React from 'react';
import theme from 'theme';
import FolderCopySharpIcon from '@mui/icons-material/FolderCopySharp';
import EditIcon from '@mui/icons-material/Edit';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import ClearIcon from '@mui/icons-material/Clear';
import { REPORT_STATUS } from 'constants/network';
import { ODCircularProgress } from 'shared';

export const REPORT_RUNS_STATUS = [
    {
        status: 'all',
        icon: <FolderCopySharpIcon sx={{ color: theme.palette.neutral.white }} />,
        filter: [
            REPORT_STATUS.DRAFT,
            REPORT_STATUS.FAILED,
            REPORT_STATUS.RUNNING,
            REPORT_STATUS.PREPARING,
            REPORT_STATUS.INPROGRESS,
            REPORT_STATUS.REFRESHING,
            REPORT_STATUS.DEFAULT,
            REPORT_STATUS.COMPLETED,
            REPORT_STATUS.VFA_COMPLETED
        ],
        showCount: false
    },
    {
        status: REPORT_STATUS.RUNNING,
        icon: <ODCircularProgress sx={{ color: theme.palette.white }} />,
        filter: [
            REPORT_STATUS.RUNNING,
            REPORT_STATUS.PREPARING,
            REPORT_STATUS.INPROGRESS,
            REPORT_STATUS.REFRESHING
        ]
    },
    {
        status: REPORT_STATUS.COMPLETED,
        icon: <DoneSharpIcon sx={{ color: theme.palette.semantic.semanticGreen }} />,
        filter: [REPORT_STATUS.COMPLETED, REPORT_STATUS.VFA_COMPLETED]
    },
    {
        status: REPORT_STATUS.FAILED,
        icon: <ClearIcon sx={{ color: theme.palette.semantic.semanticRed }} />,
        filter: [REPORT_STATUS.FAILED],
        bgColor: theme.palette.semantic.semanticRed
    },
    {
        status: REPORT_STATUS.DRAFT,
        icon: <EditIcon style={{ color: theme.palette.neutral.neutral3 }} />,
        filter: [REPORT_STATUS.DRAFT]
    }
];

export const REPORT_RUNS_STATUS_LIGHT = [
    {
        status: 'all',
        icon: <FolderCopySharpIcon sx={{ color: theme.palette.neutral.white }} />,
        filter: [
            REPORT_STATUS.DRAFT,
            REPORT_STATUS.FAILED,
            REPORT_STATUS.RUNNING,
            REPORT_STATUS.DEFAULT,
            REPORT_STATUS.COMPLETED,
            REPORT_STATUS.VFA_COMPLETED
        ],
        showCount: false
    },
    {
        status: REPORT_STATUS.RUNNING,
        icon: <ODCircularProgress sx={{ color: theme.palette.semantic.semanticGreen }} />,
        filter: [REPORT_STATUS.RUNNING]
    },
    {
        status: REPORT_STATUS.COMPLETED,
        icon: <DoneSharpIcon sx={{ color: theme.palette.semantic.semanticGreen }} />,
        filter: [REPORT_STATUS.COMPLETED, REPORT_STATUS.VFA_COMPLETED]
    },
    {
        status: REPORT_STATUS.FAILED,
        icon: <ClearIcon sx={{ color: theme.palette.semantic.semanticRed }} />,
        filter: [REPORT_STATUS.FAILED],
        bgColor: theme.palette.semantic.semanticRed
    },
    {
        status: REPORT_STATUS.DRAFT,
        icon: <EditIcon style={{ color: theme.palette.neutral.neutral3 }} />,
        filter: [REPORT_STATUS.DRAFT]
    }
];

export const REPORT_STATUS_LABEL_INFO = {
    all: 'All Reports',
    running: 'In Progress',
    preparing: 'In Progress',
    refreshing: 'In Progress',
    completed: 'Completed',
    failed: 'Failed',
    draft: 'Draft'
};

export const setTableHeaderByFlagType = (
    showStatus,
    bidAnalysis,
    odpt3963Network,
    networkManagerRateStudyColumn
) => {
    const fileType = {
        label: 'Network File Type'
    };

    const parent = { label: 'Parent Analysis' };
    const rateStudy = { label: 'Rate Study' };
    const status = { label: 'Status' };

    return [
        {
            label: `${odpt3963Network ? 'Analysis' : 'Report'} Name`,
            showCheckBox: true,
            style: { marginLeft: 16 }
        },
        ...(bidAnalysis ? [fileType] : []),
        ...(bidAnalysis && odpt3963Network ? [parent] : []),
        ...(bidAnalysis && odpt3963Network && networkManagerRateStudyColumn ? [rateStudy] : []),
        {
            label: 'Date Created',
            style: { textAlign: 'center' }
        },
        { label: `${odpt3963Network ? 'Folders' : 'Groups'}` },
        ...(!showStatus ? [status] : []),
        { label: 'Created By' },
        { label: '', style: { width: '5%' } }
    ].filter(Boolean); // This is optional since we're using spread operator to conditionally add items
};
