import React from 'react';
import { ConfigurationIcon, HistoricRateIcon } from 'assets/images';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import LibraryAddCheckSharpIcon from '@mui/icons-material/LibraryAddCheckSharp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CommonSvgIcon, ODFooterActionBar } from 'shared';
import theme from 'theme';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { StyledButtonsStack } from 'shared/ODFooterActionBar/ODFooterActionBar';
import { useFlags } from 'launchdarkly-react-client-sdk';

const POPULATE_RATE_VALUES_TOOLTIP =
    'Populate all proposed rate values before saving the configuration.';
interface BidConfigFooterProps {
    applyHistoricRatesCallback: () => void;
    bidConfigTitle: string;
    lanes: DataRow[];
    checkedRows: number[];
    saveBidButtonCallback: () => void;
    saveNewBidButtonCallback: () => void;
    removeLanesButtonCallback: () => void;
    restoreRemovedLanesCallback: () => void;
    runBidAnalysesCallback?: () => void;
    disabled: boolean;
    someRowsDeleted: boolean;
    openRunBidAnalysesSlideout?: boolean;
}
const BidConfigFooter = ({
    applyHistoricRatesCallback,
    bidConfigTitle,
    checkedRows,
    saveBidButtonCallback,
    saveNewBidButtonCallback,
    removeLanesButtonCallback,
    restoreRemovedLanesCallback,
    lanes,
    disabled,
    someRowsDeleted
}: BidConfigFooterProps) => {
    const { bulkApplyHistoricRatesButton, odpt4185BidConfigUpdate } = useFlags();
    const historicRateIcon = (disabled: boolean) => (
        <HistoricRateIcon fill={disabled ? theme.palette.neutral.neutral6 : theme.palette.white} />
    );

    const ApplyHistoricRateButton = ({ disabled }: { disabled: boolean }) => (
        <ODButton
            buttonText="Apply Historic Rates"
            buttonType={BUTTON_TYPE.BLUE}
            disabled={disabled}
            startIcon={!odpt4185BidConfigUpdate && historicRateIcon(disabled)}
            endIcon={odpt4185BidConfigUpdate && historicRateIcon(disabled)}
            onClick={applyHistoricRatesCallback}
        />
    );
    const rowsWithHistoricRate = checkedRows?.filter((row: any) => {
        const rowLane = lanes.find((l: DataRow) => l.id === row) as any;
        return rowLane?.historic_rate > 0;
    });
    const selectedContainsHistoricRate = rowsWithHistoricRate.length > 0;
    const selectedLane = lanes.find((l: DataRow) => l.id === checkedRows[0]) as any;
    const noLaneSelected = checkedRows.length === 0;
    const oneLaneSelected = checkedRows.length === 1;
    const oneOrMoreLanesSelected = checkedRows.length >= 1;
    const ribbonText = oneLaneSelected
        ? `${selectedLane?.origin}`
        : oneOrMoreLanesSelected
        ? `(${checkedRows.length}) Selected`
        : bidConfigTitle;

    const ribbonIcon = oneLaneSelected ? (
        <CommonSvgIcon fill={theme.palette.white} />
    ) : oneOrMoreLanesSelected ? (
        <LibraryAddCheckSharpIcon />
    ) : (
        <ConfigurationIcon fill="white" />
    );

    const ribbonText2 = oneLaneSelected ? `${selectedLane?.destination}` : undefined;
    const ribbonIcon2 = oneLaneSelected ? (
        <ArrowForwardIcon sx={{ color: theme.palette.neutral.white }} />
    ) : undefined;

    return odpt4185BidConfigUpdate ? (
        <ODFooterActionBar
            odpt4185BidConfigUpdate={odpt4185BidConfigUpdate}
            ribbonIcon={ribbonIcon}
            ribbonText={ribbonText}
            ribbonText2={ribbonText2}
            ribbonIcon2={ribbonIcon2}
            additionalContent={
                <StyledButtonsStack direction="row">
                    {oneOrMoreLanesSelected && (
                        <>
                            <ODButton
                                buttonText={oneLaneSelected ? `Remove Lane` : 'Remove Lanes'}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={removeLanesButtonCallback}
                                endIcon={
                                    <RemoveCircleOutlineIcon
                                        sx={{ color: theme.palette.neutral.white }}
                                    />
                                }
                            />
                            {odpt4185BidConfigUpdate && bulkApplyHistoricRatesButton && (
                                <ApplyHistoricRateButton disabled={!selectedContainsHistoricRate} />
                            )}

                            {/*TODO to be removed once V2 is released */}
                            {!odpt4185BidConfigUpdate && bulkApplyHistoricRatesButton && (
                                <ApplyHistoricRateButton disabled={!selectedContainsHistoricRate} />
                            )}
                        </>
                    )}
                </StyledButtonsStack>
            }
        />
    ) : (
        <ODFooterActionBar
            primaryButtonDisabled={noLaneSelected ? disabled : undefined}
            primaryButtonDisabledTooltipText={
                noLaneSelected ? POPULATE_RATE_VALUES_TOOLTIP : undefined
            }
            primaryButtonText={noLaneSelected ? 'Save Bid File' : undefined}
            handlePrimaryButton={noLaneSelected ? saveBidButtonCallback : undefined}
            primaryButtonEndIcon={
                noLaneSelected ? (
                    <ArrowForwardIcon
                        sx={{
                            color: disabled
                                ? theme.palette.neutral.neutral6
                                : theme.palette.neutral.white
                        }}
                    />
                ) : undefined
            }
            secondaryButtonText={noLaneSelected ? 'Save As New Bid File' : undefined}
            handleSecondaryButton={noLaneSelected ? saveNewBidButtonCallback : undefined}
            secondaryButtonDisabled={noLaneSelected ? disabled : undefined}
            secondaryButtonDisabledTooltipText={
                noLaneSelected ? POPULATE_RATE_VALUES_TOOLTIP : undefined
            }
            ribbonIcon={ribbonIcon}
            ribbonText={ribbonText}
            ribbonText2={ribbonText2}
            ribbonIcon2={ribbonIcon2}
            additionalContent={
                <StyledButtonsStack direction="row">
                    {oneOrMoreLanesSelected && (
                        <>
                            <ODButton
                                buttonText={oneLaneSelected ? `Remove Lane` : 'Remove Lanes'}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={removeLanesButtonCallback}
                                endIcon={
                                    <RemoveCircleOutlineIcon
                                        sx={{ color: theme.palette.neutral.white }}
                                    />
                                }
                            />
                            <ODButton
                                disabled={!someRowsDeleted}
                                buttonText={'Restore removed lanes'}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={restoreRemovedLanesCallback}
                            />

                            {bulkApplyHistoricRatesButton && (
                                <ApplyHistoricRateButton disabled={!selectedContainsHistoricRate} />
                            )}
                        </>
                    )}
                    {noLaneSelected && (
                        <ODButton
                            disabled={!someRowsDeleted}
                            buttonText={'Restore removed lanes'}
                            buttonType={BUTTON_TYPE.BLUE}
                            onClick={restoreRemovedLanesCallback}
                            sx={{ marginRight: '16px' }}
                        />
                    )}
                </StyledButtonsStack>
            }
        />
    );
};

export default BidConfigFooter;
