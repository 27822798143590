import React, { HTMLAttributes, useState } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types/redux';
import * as yup from 'yup';
import { Box } from '@mui/material';
import { ODButton, ODTextField, ODSelect } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { SLIDE_OUT_TYPE } from 'constants/bidding';
import { updateBidFileDetail, getAllBidFiles } from 'redux/actions';

interface editBidFileProps {
    handleClose: () => void;
}
interface onSubmitProps extends HTMLAttributes<HTMLElement> {
    fileName?: string;
    selectGroup?: string | number;
}

const validationSchema = yup.object({
    fileName: yup.string().min(1).max(999).required('Required')
});

const EditBidFileForm = ({ handleClose }: editBidFileProps) => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { slideoutMode, allGroups } = useSelector((state: RootState) => state.BiddingReducer);

    const filteredGroups = allGroups.map((group) => {
        return { value: group?.id, name: group?.name };
    });

    const currFileName = slideoutMode?.data?.data?.file_name;
    const groupId = slideoutMode?.data?.data?.group?.id;

    const initialFormState =
        slideoutMode?.mode === SLIDE_OUT_TYPE.EDIT_FILE
            ? {
                  fileName: currFileName,
                  selectGroup: groupId
              }
            : {
                  fileName: '',
                  selectGroup: ''
              };

    const determineButtonType = (type: string) => {
        let buttonActionType = type === 'submit' ? true : false;
        setIsSubmitting(buttonActionType);
    };

    const onSubmit = ({ fileName, selectGroup }: onSubmitProps) => {
        updateBidFileDetail(slideoutMode?.data?.id, fileName, selectGroup)
            .then(() => {
                dispatch(getAllBidFiles());
            })
            .catch((err) => {
                console.warn('There was an error updating bid file');
            });
    };

    return (
        <Formik
            initialValues={initialFormState}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                if (isSubmitting) {
                    onSubmit(values);
                }

                resetForm();
                handleClose();
                setSubmitting(false);
            }}>
            {({ handleSubmit, values, handleChange, isValid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <ODTextField
                            dataTestId="enter-new-group-name"
                            label="Rename File"
                            value={values?.fileName}
                            onChange={handleChange}
                            name="fileName"
                            placeholder="Enter Name"
                        />
                        <ODSelect
                            sx={{ marginTop: '16px' }}
                            dataTestId="select-group-type"
                            title="Select group"
                            name="selectGroup"
                            displayEmpty
                            value={values.selectGroup}
                            onChange={handleChange}
                            placeholder="Select Option"
                            itemList={filteredGroups}
                        />

                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            sx={{
                                marginTop: '32px',
                                flexDirection: 'column'
                            }}>
                            <ODButton
                                buttonType={BUTTON_TYPE.BLUE}
                                type="submit"
                                width="392px"
                                buttonText="Save"
                                disabled={!isValid}
                                onClick={() => determineButtonType('submit')}
                            />
                            <ODButton
                                buttonText="Cancel"
                                width="392px"
                                sx={{ marginTop: '8px' }}
                                onClick={() => determineButtonType('cancel')}
                            />
                        </Box>
                    </form>
                );
            }}
        </Formik>
    );
};

export default EditBidFileForm;
