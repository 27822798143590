import {
    SET_SORT,
    UPDATE_SELECTED_DOMICILE,
    UPDATE_DRIVER_TYPE,
    UPDATE_REPORT_DOMICILES,
    UPDATE_DRIVERS,
    UPDATE_DRIVER_MOMENTS,
    UPDATE_DRIVER_TYPES,
    UPDATE_DRIVER_REDUCER,
    UPDATE_EDIT_DRIVER,
    UPDATE_ADD_DRIVERS_COUNT
} from '../constants/actionTypes';
import { NETWORK_TABLE_TYPE } from 'constants/network';

//TODO remove any unused
const initialState = {
    sort: {
        key: 'total',
        column: 'total',
        isAsc: false
    },
    domicile: null,
    selectedDomicile: {},
    driverMoments: null,
    driverToggleFormat: 'number',
    driverTours: {
        type: null,
        driversTours: [],
        driversMoments: [],
        driverTypeTours: []
    },
    driverType: null,
    drivers: [],
    driverTypes: [],
    multiDriverToursToggle: false,
    reportDomiciles: [],
    editDriver: null,
    type: NETWORK_TABLE_TYPE.DRIVERS,
    addDriversCount: {},
    checked: {
        allDomiciles: false,
        allDriverTypes: false,
        allDriverIds: false,
        selectedDomicile: [],
        selectedDriverType: [],
        selectedDriverId: [],
        selectedDriverInfo: null
    },
    driverTypesColors: {}
};
const updateDriverReducer = (state, action) => {
    return {
        ...state,
        ...action.payload
    };
};

const DriversReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SORT:
            return {
                ...state,
                sort: action.payload
            };
        case UPDATE_SELECTED_DOMICILE:
            return {
                ...state,
                domicile: action.payload
            };
        case UPDATE_DRIVER_TYPE:
            return {
                ...state,
                driverType: action.payload
            };
        case UPDATE_DRIVER_TYPES:
            return {
                ...state,
                driverTypes: action.payload
            };
        case UPDATE_DRIVER_MOMENTS:
            return {
                ...state,
                driverMoments: action.payload
            };
        case UPDATE_REPORT_DOMICILES:
            return {
                ...state,
                reportDomiciles: action.payload
            };
        case UPDATE_DRIVERS:
            return {
                ...state,
                drivers: action.payload
            };
        case UPDATE_DRIVER_REDUCER:
            return updateDriverReducer(state, action);
        case UPDATE_EDIT_DRIVER:
            return {
                ...state,
                editDriver: action.payload
            };
        case UPDATE_ADD_DRIVERS_COUNT:
            return {
                ...state,
                addDriversCount: action.payload
            };
        default:
            return state;
    }
};

export default DriversReducer;
