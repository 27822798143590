import { SvgIcon } from '@mui/material';
import theme from 'theme';

const CommonSvgIcon = ({ fill = theme.palette.black }) => {
    return (
        <SvgIcon viewBox={'0 0 18 18'}>
            <path
                style={{
                    stroke: 'none',
                    fillRule: 'nonzero',
                    fill: fill,
                    fillOpacity: 1
                }}
                d="M15 3h-1.5v12H15V3zM4.5 3H3v12h1.5V3zM9.75 3h-1.5v3h1.5V3zM9.75 7.5h-1.5v3h1.5v-3zM9.75 12h-1.5v3h1.5v-3z"
                fill={theme.palette.white}
            />
        </SvgIcon>
    );
};

export default CommonSvgIcon;
