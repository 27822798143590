import React from 'react';
import { Box, Stack, Theme, Tooltip, Typography } from '@mui/material';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import MapSharpIcon from '@mui/icons-material/MapSharp';
import { RootState } from 'redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useNetworkViewHook, useSimulationHook } from 'utils/hooks';
import { updateNetworkView } from 'redux/actions';
import theme from 'theme';
import { NavbarDivider, ODButton, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useMediaQuery from '@mui/material/useMediaQuery';
/* components */
import TableModeDropdown from './TableModeDropdown';
import BaseReportBtn from './BaseReportBtn';
import CompareReportBtn from './CompareReportBtn';
import ManageBidsBtn from './ManageBidsBtn';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
    NetworkTopNavbarPageHeader,
    NetworkTopNavbarWrapper,
    NetworkFlexBox
} from './NetworkTopNavbar.styles';
import { convertDateObjIntoUSFormat } from 'utils/date.util';

interface TopNetworkNavbarProps {
    setAction: React.Dispatch<React.SetStateAction<string>>;
}

const TopNetworkNavbar = ({ setAction }: TopNetworkNavbarProps) => {
    const { bidding, newSimulationFlow } = useFlags();
    const { runSimulation } = useSimulationHook();
    const { currReport, navigateBackToBidAnalysisView, networkImpactView } = useNetworkViewHook();

    const dispatch = useDispatch();
    const { isListView, appliedBidFiles } = useSelector((state: RootState) => state.NetworkReducer);
    const { configuration, configurationActions } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );

    const actions = !configuration?.report_run ? configurationActions : [];

    const handleRunReport = () => {
        if (!newSimulationFlow) runSimulation();
        else dispatch(updateNetworkView({ isRunSimulation: true }));
    };

    const isScreenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    return (
        <NetworkTopNavbarWrapper flag={true}>
            <Box display="flex">
                <NetworkTopNavbarPageHeader>
                    {networkImpactView ? (
                        <ODIconButton
                            icon={<ArrowBack />}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            sx={{
                                marginLeft: '16px',
                                width: '40px',
                                height: '40px',
                                color: theme.palette.black,
                                '&:hover': {
                                    backgroundColor: theme.palette.neutral.neutral2
                                }
                            }}
                            onClick={navigateBackToBidAnalysisView}
                        />
                    ) : null}
                    <FolderSharpIcon
                        sx={{
                            margin: '0 16px',
                            color: theme.palette.black
                        }}
                    />
                    <Typography
                        sx={{
                            color: theme.palette.black,
                            fontWeight: 600,
                            fontSize: '14px',
                            marginRight: '8px'
                        }}>
                        {networkImpactView ? 'Network Impact Mode' : 'Network View'}
                    </Typography>
                </NetworkTopNavbarPageHeader>

                <NavbarDivider light={true} />
                <NetworkFlexBox>
                    <TableModeDropdown />
                    <NavbarDivider light={true} />
                    <BaseReportBtn setAction={setAction} />
                    <CompareReportBtn iconButton={isScreenSmall} setAction={setAction} />
                    <NavbarDivider light={true} />
                    <ManageBidsBtn iconButton={isScreenSmall} />
                </NetworkFlexBox>
            </Box>

            <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Tooltip
                        title={
                            isScreenSmall ? (
                                <Box sx={{ fontSize: '14px' }}>
                                    {`${convertDateObjIntoUSFormat(
                                        currReport?.start_date
                                    )} - ${convertDateObjIntoUSFormat(currReport?.end_date)}`}
                                </Box>
                            ) : (
                                ''
                            )
                        }>
                        <CalendarTodayIcon
                            sx={{
                                marginRight: '16px',
                                color: theme.palette.neutral.neutral6,
                                fontSize: '16px'
                            }}
                        />
                    </Tooltip>
                    {isScreenSmall ? null : (
                        <Typography
                            sx={{
                                marginRight: '16px',
                                color: theme.palette.neutral.neutral6
                            }}>
                            {`${convertDateObjIntoUSFormat(
                                currReport?.start_date
                            )} - ${convertDateObjIntoUSFormat(currReport?.end_date)}`}
                        </Typography>
                    )}
                </Box>
                <NavbarDivider light={true} />
                <Box sx={{ marginLeft: '8px', marginRight: '16px' }}>
                    {isScreenSmall ? (
                        <ODIconButton
                            enableTooltip
                            title={`${isListView ? 'Map' : 'List'} View`}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            icon={isListView ? <MapSharpIcon /> : <FormatListBulletedSharpIcon />}
                            sx={{
                                width: '40px',
                                height: '40px',
                                color: theme.palette.black,
                                '&:hover': {
                                    backgroundColor: theme.palette.neutral.neutral2
                                }
                            }}
                            onClick={() => dispatch(updateNetworkView({ isListView: !isListView }))}
                        />
                    ) : (
                        <ODButton
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            startIcon={
                                isListView ? <MapSharpIcon /> : <FormatListBulletedSharpIcon />
                            }
                            sx={{
                                textTransform: 'none',
                                color: theme.palette.black,
                                '&:hover': {
                                    backgroundColor: theme.palette.neutral.neutral2
                                }
                            }}
                            onClick={() => dispatch(updateNetworkView({ isListView: !isListView }))}
                            buttonText={`${!isListView ? 'List' : 'Map'} View`}
                        />
                    )}
                </Box>

                {configuration.report_run === null &&
                    (actions.length || (bidding && appliedBidFiles?.length > 0)) && (
                        <NetworkFlexBox
                            sx={{
                                margin: '0px 16px 0px 0px'
                            }}>
                            <ODButton
                                buttonType={BUTTON_TYPE.BLUE}
                                buttonText={`Run Analysis`}
                                sx={{
                                    width: '147px'
                                }}
                                endIcon={<ArrowForward />}
                                onClick={handleRunReport}
                            />
                        </NetworkFlexBox>
                    )}
            </Stack>
        </NetworkTopNavbarWrapper>
    );
};

export default TopNetworkNavbar;
