import {
    SET_SORT,
    UPDATE_SELECTED_DOMICILE,
    UPDATE_DRIVER_TYPE,
    UPDATE_DRIVER_TYPES,
    UPDATE_REPORT_DOMICILES,
    UPDATE_DRIVERS,
    UPDATE_DRIVER_MOMENTS,
    UPDATE_DRIVER_REDUCER,
    UPDATE_EDIT_DRIVER,
    UPDATE_ADD_DRIVERS_COUNT
} from '../constants/actionTypes';
import { getConfigurationActions, updateNetworkView } from 'redux/actions';
import API from 'utils/axios';
import groupBy from 'lodash/groupBy';
import theme from 'theme';
import { settingsUpdate } from './settingsActions';

export const setSort = (newSort) => {
    return {
        type: SET_SORT,
        payload: newSort
    };
};

export const updateSelectedDomicile = (domicile) => {
    return {
        type: UPDATE_SELECTED_DOMICILE,
        payload: domicile
    };
};

export const updateDriverReducer = (payload) => {
    return {
        type: UPDATE_DRIVER_REDUCER,
        payload
    };
};

export const resetDomicile = () => {
    return (dispatch) => {
        dispatch(
            setSort({
                key: 'pickup_date_time',
                column: 'pickup_date_time',
                isAsc: true
            })
        );
        dispatch(updateSelectedDomicile(null));
    };
};

export const updateDriverType = (driverType) => {
    return {
        type: UPDATE_DRIVER_TYPE,
        payload: driverType
    };
};

export const updateDriverTypes = (driverTypes) => {
    return {
        type: UPDATE_DRIVER_TYPES,
        payload: driverTypes
    };
};

export const resetDriverType = (domicile) => {
    return (dispatch) => {
        dispatch(
            setSort({
                key: domicile ? 'count' : 'total',
                column: domicile ? 'count' : 'total',
                isAsc: false
            })
        );
        dispatch(updateDriverType(null));
    };
};

export const updateDriverMoments = (driverMoments) => {
    return {
        type: UPDATE_DRIVER_MOMENTS,
        payload: driverMoments
    };
};

export const getDriverMoments = (report_id, driver) => {
    return async (dispatch) => {
        const {
            data: { reports }
        } = await API.get(
            `/reports/report-runs/${report_id}/comparison/drivers/?driver_id=${driver.name}`
        );

        const driverMoments = { driver };
        for (let key in reports) {
            if (key === 'driver_home') driverMoments.driver_home = reports[key];
            else {
                let _moments = reports[key.toString()];
                const groupByMoveId = groupBy(
                    _moments.map(({ move_id, load_type, move_type }) => ({
                        move_id,
                        load_type,
                        move_type
                    })),
                    'move_id'
                );
                _moments = _moments.map((moment) => {
                    moment['move_transaction'] = groupByMoveId[moment.move_id];
                    return moment;
                });
                driverMoments.report = key;
                driverMoments.moments = _moments;
            }
        }
        dispatch(updateDriverMoments(driverMoments));
    };
};

export const updateReportDomiciles = (reportDomiciles) => {
    return {
        type: UPDATE_REPORT_DOMICILES,
        payload: reportDomiciles
    };
};

export const updateAddDriversCount = (newCountDict) => {
    return {
        type: UPDATE_ADD_DRIVERS_COUNT,
        payload: newCountDict
    };
};

export const updateReportDomicileIndicatorColor = (index, color) => {
    return async (dispatch, getState) => {
        const { reportDomiciles } = getState().DriversReducer;
        let _reportDomiciles = [...reportDomiciles];
        _reportDomiciles[index] = {
            ...reportDomiciles[index],
            indicatorColor: color
        };
        dispatch(updateReportDomiciles(_reportDomiciles));
    };
};

export const getReportDomiciles = (report_id, filter = {}, initialMaxAggRetrieve = false) => {
    return async (dispatch) => {
        try {
            const queryParms = [];
            for (let key in filter)
                if (filter[key] !== (undefined || null)) queryParms.push(`${key}=${filter[key]}`);

            dispatch(updateNetworkView({ loadingGetDomicilesById: true }));
            const {
                data: { domiciles, meta }
            } = await API.get(
                `/reports/report-runs/${report_id}/domiciles/?${queryParms.join('&')}`
            );

            const _domiciles =
                domiciles?.map((domicile, index) => {
                    return {
                        ...domicile,
                        name: domicile?.domicile_id,
                        indicatorColor: theme.palette.transparent,
                        index: index
                    };
                }) || [];

            if (!initialMaxAggRetrieve) {
                dispatch(resetDriversState());
                dispatch(updateReportDomiciles(_domiciles));
                dispatch(updateNetworkView({ loadingGetDomicilesById: false }));
            }

            if (filter?.aggregation_level === 5 && initialMaxAggRetrieve) {
                dispatch(
                    settingsUpdate({
                        aggregationLevel: 3,
                        maxAggregationLevel: parseInt(meta?.domicile_length || 0)
                    })
                );
                dispatch(updateNetworkView({ loadingGetDomicilesById: false }));
            }
        } catch (error) {
            dispatch(resetDriversState());
            updateReportDomiciles({});
            dispatch(updateNetworkView({ loadingGetDomicilesById: false }));
        }
    };
};

export const updateDrivers = (drivers) => {
    return {
        type: UPDATE_DRIVERS,
        payload: drivers
    };
};

export const getDriverTypesByDomicileId = (report_id, domi_id, filter, defaultDriverTypes = []) => {
    return async (dispatch, getState) => {
        const { configurationActions } = getState().ConfigurationReducer;
        const { aggregationLevel } = getState().settingsReducer;
        const queryParms = [];
        for (let key in filter) if (filter[key]) queryParms.push(`${key}=${filter[key]}`);
        if (domi_id === 'all' && aggregationLevel === 0) domi_id = 'all';
        try {
            const { data } = await API.get(
                `/reports/report-runs/${report_id}/domiciles/${domi_id}/driver-types/?${queryParms.join(
                    '&'
                )}`
            );

            const driverTypes =
                data?.driver_types.length > 0 ? data?.driver_types : defaultDriverTypes;

            const filteredResults = driverTypes?.map((type, index) => {
                const domConfigActions = configurationActions.filter((action) => {
                    return action?.item_type === 'driver' && action?.value?.domicile === domi_id;
                });
                let driverCount = 0;
                const driverTypeActions = domConfigActions.filter(
                    (action) => action.value.driver_type === type.id
                );
                driverTypeActions.forEach((action) => {
                    driverCount += action.value.quantity;
                });
                return {
                    ...type,
                    domicileId: domi_id,
                    indicatorColor: driverCount ? theme.palette.white : theme.palette.transparent,
                    actionCount: driverCount,
                    index: index,
                    total_drivers: type?.total_drivers || 0
                };
            });

            dispatch(updateDriverTypes(filteredResults));
        } catch (err) {
            dispatch(updateDriverTypes([]));
        }
    };
};

export const getDrivers = (report_id, domi_id, driver_type) => {
    return async (dispatch, getState) => {
        try {
            const { domicile } = getState().DriversReducer;

            let domicileId = domi_id || domicile?.domicile_id;
            const {
                data: { drivers }
            } = await API.get(
                `/reports/report-runs/${report_id}/domiciles/${domicileId}/drivers/?driver_type=${driver_type?.id}`
            );
            let _drivers = [];
            for (let driver in drivers) {
                drivers[driver].name = driver;
                _drivers.push(drivers[driver]);
            }
            dispatch(updateDrivers(_drivers));
            dispatch(updateDriverType(driver_type));
            dispatch(
                setSort({
                    key: 'pickup_date_time',
                    column: 'pickup_date_time',
                    isAsc: true
                })
            );
        } catch (error) {
            dispatch(updateDrivers([]));
            dispatch(updateDriverType(driver_type));
        }
    };
};

export const resetDriversState = () => {
    return async (dispatch, getState) => {
        const { domicile } = getState().DriversReducer;
        dispatch(updateDriverMoments(null));
        dispatch(resetDriverType(Boolean(domicile)));
        dispatch(resetDomicile());
    };
};

export const updateEditDriver = (driver) => {
    return {
        type: UPDATE_EDIT_DRIVER,
        payload: driver
    };
};

export const createDriverActions = (report_id, config_id, actionsList) => {
    return async (dispatch) => {
        try {
            await API.put(
                `/reports/report-runs/${report_id}/configurations/${config_id}/actions/`,
                { actions: actionsList }
            );
            dispatch(getConfigurationActions(report_id, config_id));
        } catch (err) {
            if (err && err.response) {
                console.warn(err);
            }
        }
    };
};

export const updateDriverTypeIndicatorColor = (index, color) => {
    return async (dispatch, getState) => {
        const { driverTypes } = getState().DriversReducer;
        let _driverTypes = [...driverTypes];
        _driverTypes[index] = {
            ...driverTypes[index],
            indicatorColor: color
        };
        dispatch(updateDriverTypes(_driverTypes));
    };
};
