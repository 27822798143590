import axios from 'axios';
import EnvConfig from 'config/config';
import cookies from './cookies';

//TODO consolidate into axios.util.js

const downloadAPI = axios.create({
    baseURL: EnvConfig.getConfig().apiUrl,
    timeout: 100000,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob'
});

downloadAPI.interceptors.request.use(
    (config) => {
        config.url = config.url.replace(/\/\/+/g, '/');
        if (!['/token-logout/'].includes(config.url))
            config.headers.Authorization = `Bearer ${cookies.get('token')}`;
        return config;
    },
    (error) => Promise.reject(error)
);

downloadAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) window.location.pathname = '/login';
        return Promise.reject(error);
    }
);

export default downloadAPI;
