import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import { AnyObject } from 'yup/lib/types';
import palette from 'theme/palette';
import { ODCard, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { InsertDriveFile } from '@mui/icons-material';
import { StyledPaper, TextContent } from './HistorySlideout.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

interface historyHandleBidProps {
    key: string;
    type: string;
    handleRestore: (e: any, data: AnyObject) => any;
    id: string;
    value: any;
    readOnly?: boolean;
}

const HistoryHandleBidCard = ({
    id,
    key,
    value,
    handleRestore,
    readOnly
}: historyHandleBidProps) => {
    const { allBidFiles } = useSelector((state: RootState) => state.ConfigurationReducer);

    return (
        <StyledPaper readOnly={readOnly} key={key}>
            <ODCard sx={{ justifyContent: 'space-between' }}>
                <Box display={'flex'} alignItems={'center'}>
                    <InsertDriveFile />
                    <TextContent id={`bid-${id}`}>
                        <b style={{ marginRight: '4px' }}>{`Bid ${
                            value?.status === 'added' ? 'Applied:' : 'Removed:'
                        }`}</b>{' '}
                        <Box
                            sx={{
                                width: '12rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                            <Tooltip
                                disableInteractive
                                title={
                                    allBidFiles?.find(
                                        (bid: any) => bid?.id === value?.bid_config_id
                                    )?.file_name
                                }>
                                <span>
                                    {' '}
                                    {
                                        allBidFiles?.find(
                                            (bid: any) => bid?.id === value?.bid_config_id
                                        )?.file_name
                                    }
                                </span>
                            </Tooltip>
                        </Box>
                    </TextContent>
                </Box>

                {!readOnly && (
                    <ODIconButton
                        disableHover={true}
                        sx={{ padding: 0 }}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        icon={<Close sx={{ color: palette.neutral.black }} />}
                        onClick={(e) => handleRestore(e, { id, ...value })}
                    />
                )}
            </ODCard>
        </StyledPaper>
    );
};

export default HistoryHandleBidCard;
