import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import palette from 'theme/palette';

//TODO better way to define type: endIconTooltip

interface tableBannerProps {
    startIcon?: JSX.Element;
    title: string;
    endIcon?: JSX.Element;
    endIconTooltip?: any;
    enableTooltip?: boolean;
    onClick?: any;
}

const TableBanner = ({
    startIcon,
    title,
    endIcon,
    onClick,
    endIconTooltip,
    enableTooltip = false,
    ...prop
}: tableBannerProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                borderRadius: '4px',
                backgroundColor: palette.neutral.neutral1,
                padding: '10px',
                justifyContent: 'space-between'
            }}>
            <Box
                component="section"
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                {startIcon && startIcon}
                <Typography sx={{ marginLeft: '12px', fontWeight: 600, fontSize: 14 }}>
                    {title}
                </Typography>
            </Box>
            <Tooltip disableHoverListener={enableTooltip} title={endIconTooltip}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '26px',

                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    onClick={onClick}>
                    {endIcon && endIcon}
                </Box>
            </Tooltip>
        </Box>
    );
};

export default TableBanner;
