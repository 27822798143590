/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import theme from 'theme';
import { updateDriverTypeIndicatorColor } from 'redux/actions';
import { isDraftReportUtil } from 'utils/network.util';

const AdditionalDriversDriverType = ({ actionCount, index }) => {
    // TODO refactor with logic for NewDriverTable
    const dispatch = useDispatch();
    const { configurationActions, configuration } = useSelector(
        (state) => state.ConfigurationReducer
    );
    const { compareReport } = useSelector((state) => state.NetworkReducer);

    useEffect(() => {
        if (isDraftReportUtil(configuration) || compareReport?.id) {
            if (actionCount !== 0)
                dispatch(updateDriverTypeIndicatorColor(index, theme.palette.white));
        } else {
            dispatch(updateDriverTypeIndicatorColor(index, theme.palette.transparent));
        }
    }, [configurationActions]);
    return (
        <>
            {actionCount && (isDraftReportUtil(configuration) || compareReport?.id) ? (
                <Box fontSize="14px" marginLeft="3px">
                    {`(${actionCount > 0 ? '+' : ''}${actionCount})`}
                </Box>
            ) : null}
        </>
    );
};

export default AdditionalDriversDriverType;
