import React, { useContext, createContext, useCallback, useMemo, useState, useEffect } from 'react';
import {
    Cell,
    Column,
    ColumnDef,
    ColumnFiltersState,
    getCoreRowModel,
    Row,
    SortingState,
    Table,
    useReactTable
} from '@tanstack/react-table';
import {
    ActionButton,
    CellEdit,
    CrossColumnCopyDetails,
    TableErrors,
    DataRow
} from './ODTableTypes';
import { checkboxColumnWidth, settingsColumnWidth } from './ODTableConstants';
import { isNewValueDifferent } from './ODTableUtils';
import { TIME_FRAME } from 'constants/settings';
import theme from 'theme';
export interface ODTableContextInterface {
    columnIdIsValid: (columnId: string) => boolean;
    computeLeftOffset: (column: Column<DataRow, any>) => string | number;
    computeRightOffset: (column: Column<DataRow, any>) => string | number;
    displayRighthandColumn?: boolean;
    firstRightStickyColumn?: string;
    handleClickedInteractiveCell: (target: EventTarget, cell: Cell<DataRow, any>) => void;
    isDragging: boolean;
    isInteractive: (cell: Cell<DataRow, any>) => boolean;
    lastLeftStickyColumn?: string;
    maxRowSelected: number;
    minRowSelected: number;
    resetFillDown: () => void;
    rowActionsClosed: boolean;
    selectedCell: Cell<DataRow, any> | null;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
    setMaxRowSelected: React.Dispatch<React.SetStateAction<number>>;
    setMinRowSelected: React.Dispatch<React.SetStateAction<number>>;
    setSelectedCell: React.Dispatch<React.SetStateAction<Cell<DataRow, any> | null>>;
    setTable: React.Dispatch<React.SetStateAction<Table<DataRow>>>;
    setValueToCopy: React.Dispatch<React.SetStateAction<string | number | object | null>>;
    setRowActionsClosed: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedColumn: React.Dispatch<React.SetStateAction<Column<DataRow, any> | null>>;
    updateSorting: (column: Column<DataRow, any>) => void;
    valueToCopy: string | number | object | null;
    selectedColumn: Column<DataRow, any> | null;
    table: Table<DataRow>;
}
export interface ODTableProviderProps {
    accommodatesUndos?: boolean;
    addPagination?: boolean;
    checkedRows: number[]; // array of ids of data items that have been checked. (for interactive tables)
    tableDataErrors?: TableErrors;
    columnDefs: ColumnDef<DataRow, string | number | object | null>[]; // column definitions as defined by Tanstack docs. https://tanstack.com/table/v8/docs/guide/column-defs
    crossCopyColumns?: CrossColumnCopyDetails[]; // array of objects defining which columns' values can be duplicated into other columns. See BidConfigColumns.tsx. (for interactive tables)
    customActionButtons?: ActionButton[]; // additional action buttons
    columnFormatSelections?: {
        [dataCategory: string]: string;
    };
    column_specific_time_aggregation?: { [key: string]: TIME_FRAME };
    data: DataRow[]; // array of data rows. Each row is an object where the keys must match column ids defined in the columns prop.
    deleteOption?: boolean;
    disableSorting?: boolean;
    downloadOption?: boolean;
    cellEditsCallback?: (cellEdits: CellEdit[]) => void;
    editOption?: boolean;
    defaultSortingState?: SortingState;
    disableFilters?: boolean;
    globalFilter?: string[];
    handleUndoCell?: (row: DataRow, columnId: string, cellValue: any) => any;
    hiddenColumnIds?: string[];
    backgroundColorForSelectedRows?: string;
    handleClickDeleteOption?: (rowInfo: any) => void;
    handleClickDownloadOption?: (rowInfo: any) => void;
    handleClickEditOption?: (rowInfo: any) => void;
    handleClickSettingsIcon?: () => void;
    handleColumnLevelTimeAggregationSelection?: (columnId: string, timeAgg: TIME_FRAME) => void;
    interactiveColumns?: string[]; // array of columns containing mutable data (for interactive tables)
    interactiveTable: boolean; // set to false to only display data and not accommodate updating data,
    radioCheckbox?: boolean;
    removeOption?: boolean;
    rightStickyColumnsGroupId?: string;
    rowClickHandler?: (row: Row<DataRow>) => {};
    rowsToDelete: number[]; // array of data item ids that should be deleted (for interactive tables)
    separatedColumns?: string[]; // array of column ids corresponding to columns that should have a left border
    setAction?: (cell: Cell<DataRow, any>, value: any) => void;
    setCheckedRows?: (r: number[]) => void; // should be a setState method that sets the checkedRows array (for interactive tables)
    showRowActions?: boolean;
    setRowsToDelete: (r: number[]) => void;
    showTableSettingsButton?: boolean;
    sortingWhenGoalIsSet?: SortingState;
    targets?: { [key: string]: number | null };
    columnFilterOptions?: {
        value: ColumnFiltersState;
        setter: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
    };
    sortOptions?: {
        value: SortingState;
        setter: React.Dispatch<React.SetStateAction<SortingState>>;
    };
    children: React.ReactNode;
}
export const ODTableContext = createContext<ODTableContextInterface | null>(null);
const ODTableProvider: React.FC<ODTableProviderProps> = ({
    accommodatesUndos = false,
    addPagination = false,
    hiddenColumnIds = [], // array of column ids corresponding to columns that should be hidden.
    targets = {},
    checkedRows = [],
    columnDefs,
    columnFormatSelections = {},
    column_specific_time_aggregation = {},
    crossCopyColumns = [],
    customActionButtons = [],
    data,
    defaultSortingState = [],
    deleteOption = false,
    disableSorting = false,
    downloadOption = false,
    cellEditsCallback,
    editOption = false,
    disableFilters = false,
    handleUndoCell,
    handleClickDeleteOption = (row: any) => {},
    handleClickDownloadOption = (row: any) => {},
    handleClickEditOption = (row: any) => {},
    handleClickSettingsIcon,
    handleColumnLevelTimeAggregationSelection,
    backgroundColorForSelectedRows = theme.palette.ODLightBlueNeutral.lightBlue1,
    interactiveColumns = [],
    interactiveTable = true,
    radioCheckbox = false,
    removeOption = false,
    rowClickHandler,
    rowsToDelete = [],
    rightStickyColumnsGroupId,
    separatedColumns = [],
    setAction = (cell: Cell<DataRow, any>, value: any) => {},
    setCheckedRows = () => {},
    showRowActions = true,
    sortingWhenGoalIsSet = [],
    setRowsToDelete = () => {},
    showTableSettingsButton = true,
    tableDataErrors = {},
    columnFilterOptions,
    sortOptions,
    children
}) => {
    const initialData: DataRow[] = [];
    const columns: ColumnDef<DataRow, any>[] = [];
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [minRowSelected, setMinRowSelected] = useState<number>(Infinity);
    const [maxRowSelected, setMaxRowSelected] = useState<number>(-Infinity);
    const [selectedCell, setSelectedCell] = useState<Cell<DataRow, any> | null>(null);
    const [table, setTable] = useState<Table<DataRow>>(
        useReactTable({ data: initialData, ...{ columns }, getCoreRowModel: getCoreRowModel() })
    );
    const [valueToCopy, setValueToCopy] = useState<string | number | object | null>(null);
    const [selectedColumn, setSelectedColumn] = useState<Column<DataRow, any> | null>(null);
    const [selectedCellElement, setSelectedCellElement] = useState<EventTarget | null>(null);
    const [rowActionsClosed, setRowActionsClosed] = useState<boolean>(false);
    const displayRighthandColumn = showTableSettingsButton || showRowActions;

    const isInteractive = useCallback(
        (cell: Cell<DataRow, any>) => interactiveColumns.includes(cell.column.id),
        [interactiveColumns]
    );

    const lastLeftStickyColumn = table
        .getCenterLeafHeaders()
        .filter((header) => header.column.columnDef.meta?.sticky === 'left')
        .pop()?.id;

    const firstRightStickyColumn = table
        .getCenterLeafHeaders()
        .filter((header) => header.column.columnDef.meta?.sticky === 'right')[0]?.id;

    const computeLeftOffset = useCallback(
        (column: Column<DataRow, any>) => {
            // TODO Remove this hardcoded case and figure out a more flexible way to position column groups.
            if (column.id === 'review-results')
                return `${interactiveTable ? checkboxColumnWidth : 0}px`;
            if (column.id === 'placeholder') return 0;
            const header = table.getCenterLeafHeaders().find((h) => h.id === column.id);
            let leftOffset = 0;
            if (!header) {
                console.warn(
                    'error calculating left offset. Could not find header of column ' + column.id
                );
                return leftOffset;
            }
            leftOffset += interactiveTable ? checkboxColumnWidth : 0;
            for (let i = 0; i < header.index; i++) {
                const headerToMeasure = table.getCenterLeafHeaders()[i];
                leftOffset += headerToMeasure.column.getSize();
            }
            return `${leftOffset}px`;
        },
        [interactiveTable, table]
    );

    const computeRightOffset = useCallback(
        (column: Column<DataRow, any>) => {
            if (column.id === 'review-or-adjust-recommendations') return `${settingsColumnWidth}px`;
            const header = table.getCenterLeafHeaders().find((h) => h.id === column.id);
            let rightOffset = 0;
            if (!header) {
                console.warn(
                    'error calculating right offset. Could not find header of column ' + column.id
                );
                return rightOffset;
            }
            rightOffset += displayRighthandColumn ? settingsColumnWidth : 16;
            for (let i = table.getCenterLeafHeaders().length - 1; i > header.index; i--) {
                const headerToMeasure = table.getCenterLeafHeaders()[i];
                if (headerToMeasure.column.columnDef.meta?.sticky === 'right') {
                    rightOffset += headerToMeasure.column.getSize();
                }
            }
            return `${rightOffset}px`;
        },
        [displayRighthandColumn, table]
    );

    const resetFillDown = useCallback(() => {
        setSelectedCell(null);
        setSelectedCellElement(null);
        setMinRowSelected(Infinity);
        setMaxRowSelected(-Infinity);
    }, [setSelectedCell, setMinRowSelected, setMaxRowSelected]);

    const columnIdIsValid = useCallback(
        (columnId: string) => {
            const columnIds = table.getAllLeafColumns().map((c) => c.id);
            return columnIds.includes(columnId);
        },
        [table]
    );

    const handleClickedInteractiveCell = useCallback(
        (target: EventTarget, cell: Cell<DataRow, any>): void => {
            setSelectedCell(cell);
            setSelectedCellElement(target);
            setValueToCopy(cell.getValue());
            setSelectedColumn(cell.column);
            const visualOrderIndex = table
                .getRowModel()
                .rows.findIndex((r) => r.index === cell.row.index); // cell.row.index is the index of the row in the table data, which never changes.
            setMinRowSelected(visualOrderIndex);
            setMaxRowSelected(visualOrderIndex);
        },
        [table]
    );

    useEffect(() => {
        const pasteValue = () => {
            if (minRowSelected === maxRowSelected) return;
            let cellEdits: CellEdit[] = [];
            for (let rowIndex = minRowSelected; rowIndex <= maxRowSelected; rowIndex++) {
                const dataRow = table.getRowModel().rows[rowIndex];
                const dataRowId = dataRow.original.id;
                if (selectedColumn) {
                    if (
                        columnIdIsValid(selectedColumn.id) &&
                        isNewValueDifferent(dataRow.original, selectedColumn.id, valueToCopy)
                    ) {
                        cellEdits.push({
                            rowId: dataRowId as number,
                            columnId: selectedColumn.id,
                            newValue: valueToCopy
                        });
                    }
                }
            }
            cellEditsCallback?.(cellEdits);
        };
        const handleDocumentMouseUp = (): void => {
            if (isDragging) {
                pasteValue();
                setIsDragging(false);
            }
        };
        const handleDocumentMouseClick = (e: MouseEvent): void => {
            resetFillDown();
        };
        document.addEventListener('click', handleDocumentMouseClick);
        document.addEventListener('mouseup', handleDocumentMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleDocumentMouseUp);
            document.removeEventListener('click', handleDocumentMouseClick);
        };
    }, [
        cellEditsCallback,
        data,
        columnIdIsValid,
        isDragging,
        minRowSelected,
        maxRowSelected,
        resetFillDown,
        selectedCellElement,
        setIsDragging,
        table,
        valueToCopy,
        selectedColumn
    ]);

    const updateSorting = useCallback(
        (column: Column<DataRow, any>) => {
            if (table.getState().sorting.length === 0) {
                table.setSorting([{ id: column.id, desc: true }]);
                return;
            }
            const { id, desc } = table.getState().sorting[0];
            const tableIsSortedOnThisColumn = id === column.id;
            if (!tableIsSortedOnThisColumn) table.setSorting([{ id: column.id, desc: true }]);
            else if (desc) table.setSorting([{ id: column.id, desc: false }]);
            else if (!desc) table.setSorting(defaultSortingState);
        },
        [defaultSortingState, table]
    );

    useEffect(() => {
        const targetIsSet = Object.values(targets).some((value) => value !== null);
        if (targetIsSet) {
            table.setSorting(sortingWhenGoalIsSet);
            table.resetColumnFilters();
        }
    }, [sortingWhenGoalIsSet, table, targets]);

    const value: ODTableContextInterface = useMemo(
        () => ({
            accommodatesUndos,
            addPagination,
            checkedRows,
            columnDefs,
            columnFormatSelections,
            columnIdIsValid,
            computeLeftOffset,
            computeRightOffset,
            crossCopyColumns,
            customActionButtons,
            data,
            defaultSortingState,
            deleteOption,
            disableFilters,
            disableSorting,
            displayRighthandColumn,
            downloadOption,
            cellEditsCallback,
            editOption,
            firstRightStickyColumn,
            handleUndoCell,
            handleClickedInteractiveCell,
            handleClickSettingsIcon,
            handleClickDeleteOption,
            handleClickDownloadOption,
            handleClickEditOption,
            handleColumnLevelTimeAggregationSelection,
            backgroundColorForSelectedRows,
            interactiveTable,
            isDragging,
            isInteractive,
            lastLeftStickyColumn,
            maxRowSelected,
            minRowSelected,
            radioCheckbox,
            removeOption,
            resetFillDown,
            rightStickyColumnsGroupId,
            rowActionsClosed,
            rowClickHandler,
            rowsToDelete,
            selectedCell,
            separatedColumns,
            setAction,
            setCheckedRows,
            setIsDragging,
            setMaxRowSelected,
            setMinRowSelected,
            setRowActionsClosed,
            setRowsToDelete,
            setSelectedCell,
            setTable,
            setValueToCopy,
            setSelectedColumn,
            showRowActions,
            showTableSettingsButton,
            table,
            tableDataErrors,
            targets,
            updateSorting,
            column_specific_time_aggregation,
            valueToCopy,
            selectedColumn,
            columnFilterOptions,
            sortOptions
        }),
        [
            accommodatesUndos,
            addPagination,
            checkedRows,
            columnDefs,
            columnFormatSelections,
            columnIdIsValid,
            column_specific_time_aggregation,
            computeLeftOffset,
            computeRightOffset,
            crossCopyColumns,
            customActionButtons,
            data,
            defaultSortingState,
            deleteOption,
            disableFilters,
            disableSorting,
            displayRighthandColumn,
            downloadOption,
            backgroundColorForSelectedRows,
            cellEditsCallback,
            editOption,
            firstRightStickyColumn,
            handleUndoCell,
            handleClickedInteractiveCell,
            handleClickSettingsIcon,
            handleClickDeleteOption,
            handleClickDownloadOption,
            handleClickEditOption,
            handleColumnLevelTimeAggregationSelection,
            interactiveTable,
            isDragging,
            isInteractive,
            lastLeftStickyColumn,
            maxRowSelected,
            minRowSelected,
            radioCheckbox,
            removeOption,
            resetFillDown,
            rowActionsClosed,
            rowClickHandler,
            rowsToDelete,
            rightStickyColumnsGroupId,
            selectedCell,
            separatedColumns,
            setAction,
            setCheckedRows,
            setIsDragging,
            setMaxRowSelected,
            setMinRowSelected,
            setRowActionsClosed,
            setRowsToDelete,
            setSelectedCell,
            setTable,
            setValueToCopy,
            setSelectedColumn,
            showRowActions,
            showTableSettingsButton,
            table,
            tableDataErrors,
            targets,
            updateSorting,
            valueToCopy,
            selectedColumn,
            columnFilterOptions,
            sortOptions
        ]
    );

    // Updates visible columns according to checked columns in table settings slideout
    // Always hide the id column, since it is strictly used internally (to keep track of deleted and checked rows)
    useEffect(() => {
        table.getAllLeafColumns().forEach((col) => {
            if (hiddenColumnIds.includes(col.id) || col.id === 'id') {
                col.toggleVisibility(false);
            } else {
                col.toggleVisibility(true);
            }
        });
    }, [hiddenColumnIds, table]);

    return <ODTableContext.Provider value={value}>{children}</ODTableContext.Provider>;
};

const useODTable = () => {
    const context = useContext(ODTableContext);
    if (context === undefined) {
        throw new Error('useODTable must be used within an ODTableProvider');
    }
    return context;
};
export { ODTableProvider, useODTable };
