import { Box } from '@mui/material';
import BidAnalysisTablesContainer from './Tables/BidAnalysisTablesContainer';
import BidAnalysisKPICards from './KPI';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { updateBidAnalysis } from 'redux/actions';
import LaneScoreSlideout from './LaneScoreSlideout';
import theme from 'theme';

const StyledBidAnalysisBodyContainer = styled(Box)(() => ({
    padding: '0',
    flex: '1 1 auto',
    height: 'calc(100vh - 122px)', // TODO Make it unnecessary to hardcode this height
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'space-between'
}));

const BidAnalysisLeftContent = styled(Box)(
    ({ openLaneScoreSlideout }: { openLaneScoreSlideout: boolean }) => ({
        flexDirection: 'column',
        display: 'flex',
        width: openLaneScoreSlideout ? 'calc(100vw - 586px)' : '100%',
        overflow: 'hidden',
        padding: '0 16px'
    })
);

const BidAnalysisRightContent = styled(Box)(() => ({
    width: '456px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white,
    overflowY: 'auto',
    marginTop: '-16px',
    borderBottom: `1px solid ${theme.palette.neutral.neutral1}`,
    borderLeft: `1px solid ${theme.palette.neutral.neutral1}`
}));

const BidAnalysisPageBody = () => {
    const dispatch = useDispatch();
    const { openLaneScoreSlideout, selectedLaneScoreMetaData } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );

    const closeLaneScoreSlideout = () => {
        dispatch(updateBidAnalysis({ openLaneScoreSlideout: false }));
    };

    return (
        <StyledBidAnalysisBodyContainer>
            <BidAnalysisLeftContent openLaneScoreSlideout={openLaneScoreSlideout}>
                <BidAnalysisKPICards />
                <BidAnalysisTablesContainer />
            </BidAnalysisLeftContent>

            {openLaneScoreSlideout && (
                <BidAnalysisRightContent>
                    <LaneScoreSlideout
                        key={selectedLaneScoreMetaData?.bid_id}
                        onClose={closeLaneScoreSlideout}
                    />
                </BidAnalysisRightContent>
            )}
        </StyledBidAnalysisBodyContainer>
    );
};

export default BidAnalysisPageBody;
