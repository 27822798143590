import React from 'react';
import { Button as DefaultButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import theme from 'theme';

export const ReportSelectionButton = ({
    startIcon,
    endIcon,
    buttonText,
    disable,
    title,
    className,
    ...props
}) => {
    return (
        <DefaultButton
            title={title}
            {...props}
            sx={{
                className,
                border: disable ? `1px solid ${theme.palette.semantic.focusedBlue}` : 'none',
                backgroundColor: disable
                    ? theme.palette.neutral.neutral4
                    : theme.palette.ODBlue.odBlue9,
                borderRadius: '100px',
                width: '100%',
                height: '40px',
                opacity: props?.disabled ? 0.5 : 1,
                color: disable ? theme.palette.neutral.neutral6 : theme.palette.neutral.white,
                fontSize: '14px',
                fontWeight: 400,
                marginRight: '16px',
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: disable
                        ? theme.palette.neutral.neutral4
                        : theme.palette.ODBlue.odBlue7
                }
            }}>
            {startIcon && <Box sx={{ display: 'flex', marginRight: '17.5px' }}>{startIcon}</Box>}
            {buttonText}
            {endIcon && <Box sx={{ display: 'flex', marginLeft: '17.5px' }}>{endIcon}</Box>}
        </DefaultButton>
    );
};

ReportSelectionButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object,
    className: PropTypes.object
};

export default ReportSelectionButton;
