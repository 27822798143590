import React from 'react';
import { Box, Typography, Breadcrumbs } from '@mui/material';
import theme from 'theme';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

const BreadcrumbsContainer = styled(Box)({
    padding: '9px 16px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 13,
    backgroundColor: theme.palette.white
});

const BreadcrumbsParentText = styled(Typography)({
    textTransform: 'capitalize',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.ODBlue.odBlue7
});

const BreadcrumbsText = styled(Typography)({
    color: theme.palette.black
});

const BidConfigBreadcrumbs = ({ currConfigName }: { currConfigName: string | null }) => {
    const history = useHistory();
    const handleHeaderTextClick = () => history.push('/bidding');

    return (
        <BreadcrumbsContainer>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
                <BreadcrumbsParentText onClick={handleHeaderTextClick}>
                    Bid File Manager
                </BreadcrumbsParentText>
                <BreadcrumbsText> {currConfigName}</BreadcrumbsText>
            </Breadcrumbs>
        </BreadcrumbsContainer>
    );
};

export default BidConfigBreadcrumbs;
