import React, { useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function LaneScoreMetrics({ options, data }: { options: any; data: any }) {
    useEffect(() => {
        ChartJS.register(
            ChartDataLabels,
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend,
            ...registerables
        );
    }, []);

    ChartJS.register(
        ChartDataLabels,
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ...registerables
    );

    return <Bar redraw={true} options={options} data={data} />;
}

export default LaneScoreMetrics;
