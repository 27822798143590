import React from 'react';
import { Box, TableRow, TableCell, TableSortLabel } from '@mui/material';
import theme from 'theme';
import { setTableHeaderByFlagType } from './constants';
import { CommonCheckboxComponent } from 'shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from '@mui/styles/';

const spanStyled = {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.black
};

export const ReportRunsHeader = ({ reportRun, onRowChecked, tabValue, setIsDesc, isDesc }) => {
    const { newSimulationFlow, bidAnalysis, odpt3963Network, networkManagerRateStudyColumn } =
        useFlags();

    const useStyles = makeStyles((theme) => ({
        tableRow: {
            borderLeft: 'none',
            height: 40,
            '&>th': {
                color: theme.palette.neutral.black,
                borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                borderBottom: '0px'
            },
            '&>td': {
                color: theme.palette.neutral.black,
                borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                borderBottom: '0px',
                borderLeft: 'none'
            },
            '&:hover': {
                backgroundColor: theme.palette.neutral.search
            }
        }
    }));

    const classes = useStyles();
    const selectedRecords = reportRun.filter(({ isChecked }) => isChecked);
    const selectedAll = selectedRecords.length === reportRun.length;

    const isAllStatusTabSelected = tabValue !== 'all';

    let newReportHeader = setTableHeaderByFlagType(
        isAllStatusTabSelected,
        bidAnalysis,
        odpt3963Network,
        networkManagerRateStudyColumn
    );

    // Todo: remove when removing new simulation ff
    if (!newSimulationFlow)
        newReportHeader = newReportHeader.filter((header) => header.label !== 'Base Report');

    return (
        <TableRow tabIndex={''} className={classes.tableRow}>
            {newReportHeader.map((headCell, idx) => {
                return (
                    <TableCell
                        padding="none"
                        sx={{
                            width: 200,
                            minWidth: 200,
                            backgroundColor: theme.palette.neutral.search,
                            ...headCell.style
                        }}
                        key={headCell.label + idx}
                        colSpan={headCell.colSpan}>
                        <Box display="flex">
                            {headCell.showCheckBox && (
                                <span style={{ padding: 0 }}>
                                    <CommonCheckboxComponent
                                        color={theme.palette.neutral.neutral6}
                                        isChecked={reportRun.length > 0 && selectedAll}
                                        indeterminate={selectedRecords.length > 0 && !selectedAll}
                                        onClick={(event) =>
                                            onRowChecked('all', event.target.checked)
                                        }
                                        className={`reports-checkbox-all`}
                                    />
                                </span>
                            )}

                            <TableSortLabel
                                sx={{
                                    '&.Mui-active .MuiTableSortLabel-icon': {
                                        color: theme.palette.black
                                    }
                                }}
                                disabled={'Date Created' !== headCell.label}
                                active={'Date Created' === headCell.label}
                                direction={isDesc ? 'desc' : 'asc'}
                                onClick={() => setIsDesc(!isDesc)}>
                                <span style={{ ...spanStyled }}>{headCell.label}</span>
                            </TableSortLabel>
                        </Box>
                    </TableCell>
                );
            })}
        </TableRow>
    );
};
