import { DRIVER_TABLE_TYPE } from 'constants/drivers';
import {
    REPORT_STATUS,
    LOAD_SOURCE,
    NETWORK_TABLE_NAME,
    NETWORK_TABLE_TYPE
} from 'constants/network';
import { BONUS_TABLE_FILTER_TYPE } from 'constants/reports';
import { configurationType } from 'types/report';

export const isDraftReportUtil = (configuration: configurationType) => {
    return (
        Object.keys(configuration).length > 0 && !Boolean(configuration?.report_run?.report_status)
    );
};
export const isCompletedReportUtil = (configuration: {
    report_run: { report_status: REPORT_STATUS };
}) => {
    return [REPORT_STATUS.COMPLETED, REPORT_STATUS.VFA_COMPLETED].includes(
        configuration?.report_run?.report_status
    );
};

export const isFailedReportStatus = (reportStatus: REPORT_STATUS) => {
    return [REPORT_STATUS.FAILED].includes(reportStatus);
};

export const isActionAllowed = (configuration: configurationType, alertsContext: any) => {
    if (!isDraftReportUtil(configuration)) {
        if (
            [REPORT_STATUS.COMPLETED, REPORT_STATUS.VFA_COMPLETED].includes(
                configuration?.report_run?.report_status as any
            )
        ) {
            alertsContext.addAlert('accept_override');
        }
        return false;
    } else return true;
};

export const getLoadSourceQueryParam = (loadSource: LOAD_SOURCE) => {
    let filterObj = {} as any;

    if (loadSource !== LOAD_SOURCE.ALL)
        filterObj.is_bid = loadSource === LOAD_SOURCE.BID ? true : false;

    return filterObj;
};

export const getFreightBonusQueryParam = (freightBonus: BONUS_TABLE_FILTER_TYPE) => {
    let filterObj = {} as any;

    if (freightBonus !== BONUS_TABLE_FILTER_TYPE.ALL) {
        filterObj.is_bonus = freightBonus === BONUS_TABLE_FILTER_TYPE.BONUS_APPLIED ? true : false;
    }

    return filterObj;
};

export const getLoadsFilterObject = (
    areAllRowsSelected: boolean,
    isShipperType: boolean,
    rowsSelected: any[],
    checked: { selectedShippers: any[]; selectedLanes: any[] },
    isFilterSet: boolean,
    loadSource: LOAD_SOURCE
) => {
    let filterObj = {};
    const allFilter =
        loadSource === LOAD_SOURCE.BID
            ? {
                  shipper_ids: '_all',
                  lanes: '_all',
                  is_bid: true
              }
            : loadSource === LOAD_SOURCE.NETWORK
            ? {
                  shipper_ids: '_all',
                  lanes: '_all',
                  id_bid: false
              }
            : {
                  shipper_ids: '_all',
                  lanes: '_all'
              };
    const decodedURI = isShipperType
        ? rowsSelected?.map((item: string | number | boolean) => {
              return encodeURIComponent(item);
          })
        : checked?.selectedShippers?.map((item: string | number | boolean) => {
              return encodeURIComponent(item);
          });

    if (isFilterSet) {
        filterObj = {
            shipper_ids: isShipperType
                ? areAllRowsSelected
                    ? '_all'
                    : decodedURI.join(',')
                : decodedURI.join(','),
            lanes: !isShipperType
                ? areAllRowsSelected
                    ? '_all'
                    : rowsSelected.join(',')
                : checked.selectedLanes.join(',')
        };
    } else {
        if (areAllRowsSelected || !rowsSelected.length) {
            filterObj = { ...allFilter };
        } else if (isShipperType) {
            filterObj = {
                shipper_ids: decodedURI.join(',')
            };
        } else {
            filterObj = {
                lanes: !isShipperType ? rowsSelected.join(',') : checked.selectedLanes.join(',')
            };
        }
    }
    return {
        ...filterObj
    };
};

export const handleRowFilter = (
    i: number,
    row: { cells: string | any[] },
    enableFilter: boolean,
    onSetFilter: Function,
    headerType: string | any[],
    tableDataType: string
) => {
    const cells = row.cells;
    if (i !== 0 && row?.cells?.length - 1 !== i) {
        let index = 1;
        if (enableFilter && headerType.includes(cells[index].column['Header'])) {
            let filter = ['Pick Up', 'Drop Off'].includes(cells[index]?.column['Header'])
                ? cells[1]?.value + '-' + cells[2]?.value
                : cells[index]?.value;

            ['shipper', 'lane'].includes(tableDataType)
                ? onSetFilter(filter, row)
                : onSetFilter(filter, row, tableDataType);
        } else return;
    }
};

export const getLastStickyColumn = (allVisibleColumns: any[], columns: any[]) => {
    const allVisibleColumnIds = allVisibleColumns.map((c: { id: any }) => c.id);
    const allVisibleColumnsStates = columns.filter((c: { identifier: any }) =>
        allVisibleColumnIds.includes(c.identifier)
    );
    const stickyColumns = allVisibleColumnsStates.filter(
        (c: { sticky: string }) => c.sticky === 'left'
    );
    return stickyColumns.pop();
};

export const leftOffset = (
    column: { identifier: any },
    allVisibleColumns: any[],
    indicatorLinesWidth: number,
    hasCheckbox: any
) => {
    const index = allVisibleColumns.map((c: { id: any }) => c.id).indexOf(column.identifier);
    let leftOffset = indicatorLinesWidth;
    const checkboxColumnWidth = hasCheckbox ? 34 : 0;
    const standardColumnWidth = 150;
    switch (index) {
        case 0: // checkbox column
            return leftOffset;
        case 1:
            return (leftOffset += checkboxColumnWidth);
        default:
            leftOffset += checkboxColumnWidth;
            // account for absence of left padding on column 1
            leftOffset += standardColumnWidth - 12;
            for (let i = 2; i < index; i++) {
                leftOffset += standardColumnWidth;
            }
            return leftOffset;
    }
};

export const getTableName = (
    tableDataType: string | NETWORK_TABLE_NAME,
    comparisonMode: boolean
) => {
    switch (tableDataType) {
        case 'lane':
            return comparisonMode ? NETWORK_TABLE_NAME.COMPARISON_LANES : NETWORK_TABLE_NAME.LANES;
        case 'shipper':
            return comparisonMode
                ? NETWORK_TABLE_NAME.COMPARISON_SHIPPERS
                : NETWORK_TABLE_NAME.SHIPPERS;
        case DRIVER_TABLE_TYPE.DOMICILE:
            return comparisonMode
                ? NETWORK_TABLE_NAME.COMPARISON_DOMICILES
                : NETWORK_TABLE_NAME.DOMICILES;
        case DRIVER_TABLE_TYPE.DRIVER_TYPES:
            return comparisonMode
                ? NETWORK_TABLE_NAME.COMPARISON_DRIVER_TYPES
                : NETWORK_TABLE_NAME.DRIVER_TYPES;
        case DRIVER_TABLE_TYPE.DRIVER_ID:
            return NETWORK_TABLE_NAME.DRIVER_IDS;
        case DRIVER_TABLE_TYPE.DRIVER_MOMENTS:
            return NETWORK_TABLE_NAME.MOTIONS;
        default:
            throw Error('cannot find table name for tableDataType ' + tableDataType);
    }
};

export const initialLoadsKPIFilterObj = (
    loadSource: LOAD_SOURCE,
    reportFilters: { shippers: any[]; lanes: any[] },
    isFilterSet: boolean,
    currTab: NETWORK_TABLE_TYPE
) => {
    let filterObj = {};
    if (loadSource === LOAD_SOURCE.BID) {
        filterObj = { is_bid: true };
    }
    if (loadSource === LOAD_SOURCE.ALL) {
        if (!isFilterSet)
            filterObj = {
                shipper_ids: '_all',
                lanes: '_all'
            };
        else {
            if (currTab === NETWORK_TABLE_TYPE.SHIPPERS)
                filterObj = {
                    shipper_ids:
                        reportFilters.shippers.map((item: { id: any }) => item.id).join(',') ||
                        'none'
                };
            else if (currTab === NETWORK_TABLE_TYPE.LANES) {
                filterObj = {
                    lanes:
                        reportFilters.lanes.map((item: { id: any }) => item.id).join(',') || 'none'
                };
            }
        }
    }
    if (loadSource === LOAD_SOURCE.NETWORK) {
        filterObj = { is_bid: false };
    }
    return { ...filterObj };
};
