import React from 'react';
import { Button as DefaultButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import theme from 'theme';

export const CommonButtonComponent = ({
    startIcon,
    endIcon,
    buttonText,
    title,
    className,
    transparent = false,
    dark = true,
    style = {},
    ...props
}) => {
    return (
        <DefaultButton
            {...props}
            className={className}
            style={{
                border: transparent ? `1px solid ${theme.palette.ODBlue.odBlue8}` : 'none',
                backgroundColor: transparent
                    ? 'transparent'
                    : dark
                    ? theme.palette.ODBlue.odBlue8
                    : theme.palette.neutral.neutral1,
                borderRadius: '100px',
                width: 190,
                height: '48px',
                opacity: props?.disabled ? 0.5 : 1,
                color: dark ? theme.palette.neutral.white : theme.palette.neutral.black,
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: theme.palette.ODBlue.odBlue7
                },
                ...style
            }}>
            {startIcon && <Box sx={{ display: 'flex', marginRight: '17.5px' }}>{startIcon}</Box>}
            {buttonText}
            {endIcon && <Box sx={{ display: 'flex', marginLeft: '17.5px' }}>{endIcon}</Box>}
        </DefaultButton>
    );
};

CommonButtonComponent.propTypes = {
    buttonText: PropTypes.string.isRequired,
    icon: PropTypes.object,
    className: PropTypes.object
};

export default CommonButtonComponent;
