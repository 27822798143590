import { FormControl, InputLabel, InputBase } from '@mui/material/';
import theme from 'theme';
interface TextFieldContainerProps {
    type: string;
    title?: string | null;
    disabled?: boolean;
    disableUnderline?: boolean;
    id: string;
    placeholder: string;
    className: string;
    multiple: boolean;
    value: string;
    variant: 'outlined' | 'standard' | 'filled';
    style: any;
    handleChange: any;
    endAdornment?: JSX.Element | null;
}
const TextFieldContainer = ({
    title = null,
    type,
    style,
    value,
    placeholder = '',
    variant = 'outlined',
    handleChange,
    endAdornment = null,
    disabled = false
}: TextFieldContainerProps) => {
    return (
        <FormControl variant={variant} style={{ width: '100%', ...style }}>
            {title && (
                <InputLabel shrink htmlFor={`${type}-native-label-placeholder`}>
                    {title}
                </InputLabel>
            )}
            <InputBase
                id={`${type}-adornment-weight`}
                value={value}
                disabled={disabled}
                endAdornment={endAdornment}
                placeholder={placeholder}
                onChange={handleChange}
                style={{
                    backgroundColor: theme.palette.neutral.search,
                    color: '#666',
                    padding: '5px 10px',
                    borderRadius: '4px'
                }}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight'
                }}
            />
        </FormControl>
    );
};

export default TextFieldContainer;
