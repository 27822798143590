/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import theme from 'theme';
import { updateReportDomicileIndicatorColor, updateSelectedDomicile } from 'redux/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isDraftReportUtil } from 'utils/network.util';

const AdditionalDriversDom = (props) => {
    const { allowEditOnAggregatedDrivers } = useFlags();
    const dispatch = useDispatch();
    const { config_id } = useParams();
    const { domicile_id, index } = props;
    const [showAddDrivers, toggleShowAddDrivers] = useState(false);
    const { configurationActions, configuration } = useSelector(
        (state) => state.ConfigurationReducer
    );
    const { isAggregated } = useSelector(({ settingsReducer }) => settingsReducer);
    const { domicile } = useSelector((state) => state.DriversReducer);
    const { compareReport } = useSelector((state) => state.NetworkReducer);

    let actionDrivers = 0;
    const domConfigActions = configurationActions.filter((action) => {
        return action.item_type === 'driver' && action.value.domicile === domicile_id;
    });

    if (isDraftReportUtil(configuration) || compareReport?.id)
        domConfigActions.forEach((action) => {
            if (
                action.value.domicile === domicile_id &&
                !showAddDrivers &&
                action.value.quantity !== 0
            ) {
                toggleShowAddDrivers(true);
            }
            actionDrivers += action.value.quantity;
        });
    useEffect(() => {
        let newIndicatorColor = showAddDrivers ? theme.palette.white : theme.palette.transparent;

        dispatch(updateReportDomicileIndicatorColor(index, newIndicatorColor));
        if (domicile) {
            let _domicile = {
                ...domicile,
                indicatorColor: newIndicatorColor,
                indicator: newIndicatorColor
            };
            dispatch(updateSelectedDomicile(_domicile));
        }
    }, [configurationActions, config_id, showAddDrivers]);

    useEffect(() => {
        if (actionDrivers === 0) toggleShowAddDrivers(false);
    }, [configurationActions, config_id, actionDrivers]);

    return (
        <>
            {showAddDrivers && (allowEditOnAggregatedDrivers || !isAggregated) ? (
                <Box fontSize="14px" marginLeft="3px">
                    {`(${actionDrivers > 0 ? '+' : ''}${actionDrivers})`}
                </Box>
            ) : null}
        </>
    );
};

export default AdditionalDriversDom;
