import axios from 'axios';
import EnvConfig from 'config/config';
import cookies from './cookies';
import { config } from 'types/axios';

const API = axios.create({
    baseURL: EnvConfig.getConfig().apiUrl,
    timeout: 100000,
    headers: { 'Content-Type': 'application/json' }
});

API.interceptors.request.use(
    (config): config => {
        config.url = config?.url?.replace(/\/\/+/g, '/');
        if (!['/token-logout/', '/token/'].includes(config?.url!) && config?.headers)
            config.headers.Authorization = `Bearer ${cookies.get('token')}`;
        return config;
    },
    (error) => Promise.reject(error)
);

API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) window.location.pathname = '/login';
        return Promise.reject(error);
    }
);

export default API;
