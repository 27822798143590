import { SETTINGS_UPDATE, SETTINGS_RESET } from '../constants/actionTypes';

export const settingsClear = () => {
    return {
        type: SETTINGS_RESET
    };
};

export const settingsUpdate = (setting) => {
    return {
        type: SETTINGS_UPDATE,
        setting
    };
};
