import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';

export const PaginationContainer = styled(Box)(({ theme }) => ({
    minHeight: '56px',
    height: '56px',
    backgroundColor: theme.palette.white,

    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingRight: '11px',
    marginX: 0,
    fontSize: '14px',
    color: theme.palette.ODBlue.odBlue7,
    position: 'static',
    marginTop: 'auto',
    borderTop: `1px solid ${theme.palette.neutral.neutral2}`,
    borderRadius: 'none'
}));

export const PaginationButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.ODBlue.odBlue7,

    minWidth: 0,
    width: '40px',
    height: '40px',
    margin: '0',
    '&:disabled': {
        color: theme.palette.neutral.neutral6,
        backgroundColor: theme.palette.neutral.neutral4
    }
}));
