/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, createContext, useMemo, useState, useEffect } from 'react';
import { AlertType, Alert } from './AlertTypes';
import NetworkViewAlert from './NetworkViewAlert';
import _ from 'lodash';
interface AlertsContextInterface {
    alerts: Alert[];
    setAlerts: (alerts: Alert[]) => void;
    removeAlert: (alertType: AlertType, id?: any) => void;
    addAlert: (alertType: AlertType, reportName?: any) => void;
}
export const AlertsContext = createContext<AlertsContextInterface | null>(null);
const AlertsProvider: React.FC<any> = ({ children }) => {
    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [removeInterval, setRemoveInterval] = useState<NodeJS.Timeout | null>(null);

    const removeNewSimulationAlerts = () => {
        if (alerts.find((alert) => alert.alertType === 'new_simulation_created')) {
            setAlerts((alerts) =>
                alerts.filter((alert) => alert.alertType !== 'new_simulation_created')
            );
        }
    };

    useEffect(() => {
        const interval = setInterval(removeNewSimulationAlerts, 10000); // Every 10 seconds
        setRemoveInterval(interval);
        return () => {
            if (removeInterval) {
                clearInterval(removeInterval);
            }
        };
    }, []);

    const sortByPrescribedOrder = (a: Alert, b: Alert) => {
        var sortingArr: AlertType[] = [
            'outdated_bid',
            'accept_override',
            'zip_aggregation',
            'new_simulation_created',
            'run_report',
            'report_failed',
            'draft_bid_analysis_failed',
            'new_draft_bid_analysis_created'
        ];
        return sortingArr.indexOf(a.alertType) - sortingArr.indexOf(b.alertType);
    };

    const removeAlert = useMemo(
        () => (alertType: AlertType, id?: any) => {
            setAlerts((alerts) => {
                return id
                    ? alerts.filter((alert) => alert.id !== id).sort(sortByPrescribedOrder)
                    : alerts
                          .filter((alert) => alert.alertType !== alertType)
                          .sort(sortByPrescribedOrder);
            });
        },
        []
    );

    const addAlert = useMemo(
        () => (alertType: AlertType, reportName?: any) => {
            if (!alerts.map((alert) => alert.alertType).includes(alertType)) {
                const uniqueId = `${alertType}-${_.random(0, 1000)}`;
                const newAlert = {
                    id: uniqueId,
                    alertType: alertType,
                    reportName: reportName,
                    alertComponent: (
                        <NetworkViewAlert
                            key={`key-${alertType}-${_.random(0, 1000)}`}
                            id={uniqueId}
                            alertType={alertType}
                            reportName={reportName}
                        />
                    )
                };
                setAlerts((alerts) => [...alerts, newAlert].sort(sortByPrescribedOrder));
            }
        },
        [alerts]
    );

    const resetAllAlerts = () => {
        setAlerts([]);
    };

    const value: AlertsContextInterface = useMemo(
        () => ({ alerts, setAlerts, removeAlert, addAlert, resetAllAlerts }),
        [alerts, addAlert, removeAlert]
    );
    return <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>;
};

const useAlerts = () => {
    const context = useContext(AlertsContext);
    if (context === undefined) {
        throw new Error('useAlerts must be used within an AlertsProvier');
    }
    return context;
};

export { AlertsProvider, useAlerts };
