import { useDispatch, useSelector } from 'react-redux';
import { updateLoadingState } from 'redux/actions';
import { RootState } from 'redux/reducers';

const useLoadingHook = () => {
    const dispatch = useDispatch();
    const { loadingCounter } = useSelector((state: RootState) => state.LoadingReducer);

    const showLoader = async (show: Boolean, text?: string) => {
        await dispatch(
            updateLoadingState({
                showLoading: show ? show : loadingCounter > 1,
                loadingCounter: show
                    ? 1 + loadingCounter
                    : loadingCounter > 0
                    ? loadingCounter - 1
                    : 0,
                text: text === 'export' ? 'Please wait preparing export...' : ''
            })
        );
    };
    return { showLoader };
};

export default useLoadingHook;
