import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { ODTableProvider } from 'shared/ODTable/ODTableContext';
import { ODTable } from 'shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import flowLanesColumnsInitiate from './FlowsTableColumns';
import { updateBidAnalysis } from 'redux/actions';
import { BIDDING_TABLE_NAME } from 'constants/bidding';
import ODTableColumnSelectionSlideout from 'shared/ODTable/ODTableColumnSelectionSlideout';
import API from 'utils/axios';
import { useParams } from 'react-router-dom';
import { useParamsProps } from 'types/hooks';
import { getLocation } from 'utils/common.util';
import { roundFieldsToInt } from 'utils/number.util';
import { useBidAnalysisHook } from 'utils/hooks';
import * as FS from '@fullstory/browser';

const InflowOutflowTable = () => {
    const { selectedLaneId, selectedFlowDirection, showFlowLanesTableSettings } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const { triggerNoMappingInfoAlert } = useBidAnalysisHook();

    const { hiddenColumnsArrays } = useSelector((state: RootState) => state.TableLayoutReducer);
    const rootState = useSelector((state: RootState) => state);
    const { locationVisualization } = useSelector((state: RootState) => state.settingsReducer);

    const [flowLanes, setFlowLanes] = useState<DataRow[]>([]);
    const [checkedRows, setCheckedRows] = useState<number[]>([]);
    const { bidAnalysisAndFlowsTableColumns, showProfitColumns } = useFlags();
    const dispatch = useDispatch();
    const { analysis_id }: useParamsProps = useParams();

    useEffect(() => {
        if (selectedLaneId === null) setFlowLanes([]);
        const fetchFlowLanes = async () => {
            try {
                const { data } = await API.get(
                    `/bidding/bid-analysis/${analysis_id}/?lane_data_type=${selectedFlowDirection}&bid_lane_id=${selectedLaneId}`
                );
                if (data.flow_records) {
                    data.flow_records.forEach((lane: DataRow, idx: number) => {
                        lane.id = lane.id ?? idx;
                        lane.originCityState = getLocation(
                            lane.origin as string,
                            rootState.ReportKPIReducer.reportLocations,
                            'city-state',
                            rootState.BiddingReducer.bidConfigLocations
                        );
                        lane.destCityState = getLocation(
                            lane.destination as string,
                            rootState.ReportKPIReducer.reportLocations,
                            'city-state',
                            rootState.BiddingReducer.bidConfigLocations
                        );
                        lane.originCityStateZip = getLocation(
                            lane.origin as string,
                            rootState.ReportKPIReducer.reportLocations,
                            'city-state-zip',
                            rootState.BiddingReducer.bidConfigLocations
                        );
                        lane.destCityStateZip = getLocation(
                            lane.destination as string,
                            rootState.ReportKPIReducer.reportLocations,
                            'city-state-zip',
                            rootState.BiddingReducer.bidConfigLocations
                        );
                        roundFieldsToInt(lane, ['volume']);
                    });
                    setFlowLanes(data.flow_records);
                } else
                    throw Error(
                        `Error fetching flow lanes for analysis ${analysis_id} and lane ${selectedLaneId}`
                    );
            } catch (error) {
                console.log(error);
            }
        };
        fetchFlowLanes();
    }, [analysis_id, selectedLaneId, rootState, selectedFlowDirection]);

    return (
        <ODTableProvider
            data={flowLanes}
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
            columnDefs={flowLanesColumnsInitiate({
                textFieldMatchingStrategy: 'inclusion',
                selectedFlowDirection: selectedFlowDirection,
                locationVisualization,
                bidAnalysisAndFlowsTableColumns,
                time_aggregation: null,
                triggerNoMappingInfoAlert,
                showProfitColumns
            })}
            handleClickSettingsIcon={() => {
                FS.event('Bid Analysis_Inflow_Outflow_Click Table Settings Icon', {});
                dispatch(updateBidAnalysis({ showFlowLanesTableSettings: true }));
            }}
            hiddenColumnIds={hiddenColumnsArrays[BIDDING_TABLE_NAME.FLOWS]}
            separatedColumns={['recommendation', 'review-or-adjust-recommendations']}
            interactiveTable={false}
            interactiveColumns={[]}
            setRowsToDelete={(r: number[]) => {}}
            rowsToDelete={[]}>
            <>
                <ODTable tableKey="bid-analysis" />
                <ODTableColumnSelectionSlideout
                    tableName={BIDDING_TABLE_NAME.FLOWS}
                    onClose={() =>
                        dispatch(updateBidAnalysis({ showFlowLanesTableSettings: false }))
                    }
                    open={showFlowLanesTableSettings}
                />
            </>
        </ODTableProvider>
    );
};

export default InflowOutflowTable;
