/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateBiddingState,
    getConfigsByGroupId,
    resetModalMode,
    resetBidFileManagerTable
} from 'redux/actions';

import { RootState } from 'types/redux';
import palette from 'theme/palette';
import { ODIconButton, ODButton } from 'shared';
import { ConfigurationIcon } from 'assets/images';
import { BUTTON_TYPE } from 'constants/colors';
import { MODAL_TYPE, FILE_TYPE } from 'constants/bidding';

interface ModalMessageProps {
    helperText?: string;
    allowRemoveListItem?: boolean;
    modalData: any;
    handleAction?: (data: any) => any;
}

const ModalMessage = ({
    helperText,
    allowRemoveListItem = false,
    modalData,
    handleAction = () => {}
}: ModalMessageProps) => {
    const { modalMode } = useSelector((state: RootState) => state.BiddingReducer);
    const [displayedFiles, setDisplayedFiles] = useState([]);
    const [completedFiles, setCompletedFiles] = useState([]);
    const [draftFiles, setDraftFiles] = useState([]);
    const { data, mode } = modalData;
    const dispatch = useDispatch();

    if (displayedFiles.length === 1) {
        allowRemoveListItem = false;
    }

    const handleRemoveFile = (config: any) => {
        let filteredFileDetails = displayedFiles.filter((file: any) => {
            return file.id !== config.id;
        });

        let filteredIds = filteredFileDetails.map((item: any) => item.id);
        setDisplayedFiles(filteredFileDetails);

        let filteredCompletedFiles = filteredFileDetails.filter(
            (file: any) => file?.status !== FILE_TYPE.DRAFT || file?.status !== FILE_TYPE.EMPTY
        );

        setCompletedFiles(filteredCompletedFiles);

        dispatch(
            updateBiddingState({
                bidFileManagerSelectedConfigIds: filteredIds,
                modalMode: {
                    ...modalMode,
                    data: {
                        id: filteredIds,
                        data: filteredFileDetails
                    }
                }
            })
        );
    };

    useEffect(() => {
        if (modalMode?.mode === MODAL_TYPE.DOWNLOAD_GROUP_FILE) {
            getConfigsByGroupId(modalMode?.data?.id).then((res) => {
                const configList = res?.results;

                setDisplayedFiles(configList);

                dispatch(
                    updateBiddingState({
                        modalMode: {
                            mode: MODAL_TYPE.DOWNLOAD_GROUP_FILE,
                            data: {
                                ...modalMode.data,
                                data: configList
                            }
                        }
                    })
                );
            });
        } else {
            setDisplayedFiles(data?.data || data);
        }
    }, []);

    useEffect(() => {
        let completedDisplayedFiles = displayedFiles?.filter(
            (file: { status: FILE_TYPE }) =>
                ![FILE_TYPE.DRAFT, FILE_TYPE.EMPTY].includes(file?.status)
        );

        let draftFiles = displayedFiles?.filter((file: { status: FILE_TYPE }) =>
            [FILE_TYPE.DRAFT, FILE_TYPE.EMPTY].includes(file?.status)
        );

        setDraftFiles(draftFiles);
        setCompletedFiles(completedDisplayedFiles);
    }, [displayedFiles]);

    const isDisableButton = () => {
        return (
            displayedFiles.length === 0 ||
            completedFiles.length === 0 ||
            displayedFiles.length === draftFiles.length
        );
    };

    const commonPaperStyle = {
        height: '20px',
        fontSize: '14px',
        fontWeight: 400,
        padding: '10px',
        margin: '2px 0',
        borderRadius: '4px'
    };

    return (
        <>
            <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                {displayedFiles.length === 0 &&
                modalMode?.mode === MODAL_TYPE.DOWNLOAD_GROUP_FILE ? (
                    <Box sx={{ fontSize: '14px' }}>
                        This group does not have any associated configuration files. Please upload
                        new bid files, or move existing files into this group to download.
                    </Box>
                ) : (
                    displayedFiles.map(
                        (
                            item: {
                                status: FILE_TYPE;
                                file_name: any;
                                name:
                                    | boolean
                                    | React.ReactChild
                                    | React.ReactFragment
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                            },
                            i: React.Key | null | undefined
                        ) => {
                            const enableTooltipHover = () => {
                                if (
                                    [MODAL_TYPE.DELETE_FILE, MODAL_TYPE.DELETE_GROUP].includes(
                                        modalMode.mode
                                    )
                                ) {
                                    return false;
                                } else
                                    return [FILE_TYPE.DRAFT, FILE_TYPE.EMPTY].includes(
                                        item?.status
                                    );
                            };

                            return (
                                <Tooltip
                                    key={i}
                                    disableHoverListener={!enableTooltipHover()}
                                    title={'Download available for complete bid files only.'}>
                                    <Box
                                        key={i}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={
                                            item?.status === FILE_TYPE.DRAFT ||
                                            item?.status === FILE_TYPE.EMPTY
                                                ? {
                                                      ...commonPaperStyle,
                                                      backgroundColor: palette.neutral.neutral4,
                                                      color: palette.neutral.neutral6
                                                  }
                                                : {
                                                      ...commonPaperStyle,
                                                      backgroundColor:
                                                          palette.ODLightBlueNeutral.lightBlue1
                                                  }
                                        }>
                                        <Box display="flex">
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{
                                                    marginRight: '16px'
                                                }}>
                                                {mode === MODAL_TYPE.DELETE_GROUP ? (
                                                    <ListAltSharpIcon />
                                                ) : (
                                                    <ConfigurationIcon
                                                        fill={
                                                            item?.status === FILE_TYPE.DRAFT ||
                                                            item?.status === FILE_TYPE.EMPTY
                                                                ? palette.neutral.neutral6
                                                                : palette.black
                                                        }
                                                    />
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '18rem',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>
                                                {modalMode?.mode !== MODAL_TYPE.DELETE_GROUP
                                                    ? item?.file_name
                                                    : item?.name}
                                            </Box>
                                        </Box>
                                        {allowRemoveListItem &&
                                            item?.status !== FILE_TYPE.DRAFT && (
                                                <ODIconButton
                                                    enableTooltip
                                                    disableHover={true}
                                                    id={'delete'}
                                                    onClick={() => handleRemoveFile(item)}
                                                    buttonType={BUTTON_TYPE.TRANSPARENT}
                                                    icon={
                                                        <ClearIcon
                                                            sx={{ color: palette.neutral.black }}
                                                        />
                                                    }
                                                />
                                            )}
                                    </Box>
                                </Tooltip>
                            );
                        }
                    )
                )}

                {helperText && (
                    <Typography sx={{ fontSize: '14px', fontWeight: 400, marginTop: '16px' }}>
                        {helperText}
                    </Typography>
                )}
            </Box>
            {(modalMode?.mode === MODAL_TYPE.DOWNLOAD_GROUP_FILE ||
                modalMode?.mode === MODAL_TYPE.DOWNLOAD_FILE) && (
                <Box
                    sx={{
                        marginTop: '32px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <ODButton
                        disabled={isDisableButton()}
                        buttonType={BUTTON_TYPE.BLUE}
                        type="submit"
                        width="188px"
                        buttonText={'Download'}
                        onClick={() => handleAction(modalMode?.data)}
                    />
                    <ODButton
                        buttonText="Cancel"
                        width="188px"
                        onClick={() => {
                            dispatch(resetModalMode());
                            dispatch(resetBidFileManagerTable());
                        }}
                    />
                </Box>
            )}
        </>
    );
};

export default ModalMessage;
