import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSpan = styled('span')(({ selectable }: { selectable?: boolean }) => ({
    textOverflow: 'ellipsis',
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textDecoration: selectable ? 'underline' : '',
    cursor: selectable ? 'pointer' : 'auto'
}));

export const CityEllipsis = styled(Box)(() => ({
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '2px'
}));

export const CellContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
}));

export const FlowTextValue = styled(Box)(({ size }: { size: any }) => ({
    textOverflow: 'ellipsis',
    maxWidth: size - 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}));

export const FlowMovementTypeValue = styled(Box)(({ size }: { size: any }) => ({
    cursor: 'default',
    textOverflow: 'ellipsis',
    maxWidth: size - 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}));
