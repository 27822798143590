/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSimulationHook } from 'utils/hooks';
import { MultipleFoldersIcon } from 'assets/images';
import SlideOutContainer from 'components/NetworkView/SlideOutContainer';
import theme from 'theme';
import { ODTableProvider } from 'shared/ODTable/ODTableContext';
import { HeaderTopBar, ODTable } from 'shared';
import associatedSimulationsColumns from './AssociatedSimulationsColumns';
import { styled } from '@mui/material/styles';

const ManageSimulation = () => {
    const { handleSelectedSimulation, closeSimulation, setSimulation, simulation } =
        useSimulationHook();

    const { configurations } = useSelector((state) => state.ConfigurationReducer);

    const AssocReportsPage = styled(Box)(() => ({
        backgroundColor: theme.palette.neutral.white,
        fontSize: '14px',
        width: '100%',
        margin: 0,
        padding: 0
    }));

    const openSimulation = (simulation) => {
        setSimulation(simulation);
        handleSelectedSimulation(simulation);
    };
    return (
        <SlideOutContainer
            dark={false}
            open={true}
            onClose={() => closeSimulation()}
            anchor={'left'}
            headerStyle={{ height: 0, fontWeight: 'normal', fontSize: 18 }}
            endIconId="simulation-manager-close-button">
            <AssocReportsPage>
                <HeaderTopBar
                    visibleHeight={false}
                    onClose={closeSimulation}
                    fileTypeName="Associated Reports"
                    fileTypeIcon={<MultipleFoldersIcon fill={theme.palette.black} />}
                />
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: '16px',
                        width: 'calc(100% - 32px)',
                        height: `calc(100vh - 75px)`
                    }}>
                    <ODTableProvider
                        checkedRows={simulation?.id ? [simulation?.id] : []}
                        data={configurations}
                        columnDefs={associatedSimulationsColumns}
                        setCheckedRows={(checkedRowIds) =>
                            setSimulation(
                                configurations.find((config) => config.id === checkedRowIds[0])
                            )
                        }
                        interactiveTable={false}
                        showRowActions={false}
                        disableFilters={true}
                        rowClickHandler={(row) => openSimulation(row.original)}
                        radioCheckbox={true}
                        showTableSettingsButton={false}>
                        <ODTable />
                    </ODTableProvider>
                </Box>
            </AssocReportsPage>
        </SlideOutContainer>
    );
};

export default ManageSimulation;
