import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material/';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
    menuPaper: {
        maxHeight: 350
    },
    menuMapping: {
        maxHeight: 210
    }
});

const CommonSelectContainer = ({
    style,
    type,
    options,
    title,
    value,
    error = false,
    id = 'select-id',
    valueLabel,
    optionLabel,
    handleChange,
    classes,
    variant = 'outlined',
    customMenuValue,
    customMenuLabel,
    disabled
}) => {
    return (
        <FormControl variant={variant} style={{ width: '100%', ...style }} error={error}>
            <InputLabel htmlFor={`${type}-native-label-placeholder`}>{title}</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
                label={title}
                id={id}
                inputProps={{
                    name: title,
                    id: `${title}-native-label-placeholder`
                }}
                disabled={disabled}
                MenuProps={{
                    getContentAnchorEl: null,
                    classes: {
                        paper: title === 'Select Mapping' ? classes.menuMapping : classes.menuPaper
                    }
                }}>
                {customMenuLabel && customMenuValue && (
                    <MenuItem
                        key={customMenuValue}
                        value={customMenuValue}
                        className={`${id}-${customMenuValue} ${id}-custom`}
                        style={{ paddingLeft: '5px', textTransform: 'capitalize' }}>
                        {customMenuLabel}
                    </MenuItem>
                )}
                {options &&
                    options.map((item, idx) => (
                        <MenuItem
                            key={idx}
                            className={`${id}-${(optionLabel
                                ? item[optionLabel]
                                : item.name
                            ).replace(' ', '_')} ${id}-${idx}`}
                            value={valueLabel ? item[valueLabel] : item.value}
                            style={{ paddingLeft: '5px', textTransform: 'capitalize' }}>
                            {optionLabel ? item[optionLabel] : item.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default withStyles(styles)(CommonSelectContainer);
