import React, { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { RootState } from 'types/redux';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import { Checkbox } from 'shared';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

import TableWithoutHeader from 'shared/Table/TableWithoutHeader';
import ODIconButton from 'shared/ODIconButton';
import palette from 'theme/palette';
import { updateBiddingState } from 'redux/actions';
import { BUTTON_TYPE } from 'constants/colors';
import { MODAL_TYPE, SLIDE_OUT_TYPE } from 'constants/bidding';

const colHelpers = createColumnHelper<{
    [key: string]: any;
}>();

const commonIconSX = {
    fill: palette.neutral.black
};

const BidGroupList = () => {
    const dispatch = useDispatch();
    const [hoveredRow, setHoveredRow] = useState(null);
    const { allGroups, currSelectedGroups } = useSelector(
        (state: RootState) => state.BiddingReducer
    );

    const isChecked = (id: string | number | object) => {
        return currSelectedGroups?.includes(id);
    };

    const isSelectedAll = () => {
        return currSelectedGroups?.length === allGroups?.length;
    };

    const allFiles = [
        {
            checked: isSelectedAll(),
            groupName: 'All Files',
            id: 'all'
        }
    ];

    const groups =
        allGroups?.map((group: any) => {
            return {
                checked: isChecked(group?.id),
                groupName: group?.name,
                id: group?.id
            };
        }) || [];

    const groupIds = allGroups?.map((group: any) => group?.id) || [];

    const handleCheckbox = (groupId: string) => {
        let arr = currSelectedGroups || [];
        const filteredId = groupId === 'all' ? 'all' : Number(groupId);

        if (filteredId === 'all') {
            if (arr.length > 0) {
                arr = [];
            } else {
                arr = groupIds;
            }
        } else {
            if (!arr.includes(filteredId)) {
                arr.push(filteredId);
            } else {
                arr.splice(arr.indexOf(filteredId), 1);
            }
        }

        return arr;
    };

    const columns = [
        colHelpers.accessor('checked', {
            cell: (info) => {
                return (
                    <Checkbox
                        indeterminate={
                            currSelectedGroups.length > 0 &&
                            !isSelectedAll() &&
                            info?.row?.original?.id === 'all'
                        }
                        isChecked={info?.row?.original?.checked}
                        onChecked={(e: any) => {
                            const newId = info?.row?.original?.id;
                            const filteredArr = handleCheckbox(newId);

                            dispatch(
                                updateBiddingState({
                                    currSelectedGroups: [...filteredArr]
                                })
                            );
                        }}
                    />
                );
            }
        }),
        colHelpers.accessor('groupName', {
            cell: (info) => {
                return (
                    <Box
                        sx={{
                            width: '10rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                        <Tooltip disableInteractive title={info.getValue()}>
                            <span> {info.getValue()}</span>
                        </Tooltip>
                    </Box>
                );
            }
        }),
        colHelpers.accessor('id', {
            cell: (info) => {
                return (
                    <Box sx={{ position: 'relative' }}>
                        {info?.row?.original?.id !== 'all' ? (
                            hoveredRow !== info.row.id ? (
                                <Box display={'flex'}>
                                    <ODIconButton
                                        onClick={() => setHoveredRow(info.row.id as any)}
                                        disableHover={true}
                                        id={`row-${info?.row.id}-more-options-button`}
                                        buttonType={BUTTON_TYPE.TRANSPARENT}
                                        icon={
                                            <MoreVertIcon
                                                sx={{ ...commonIconSX, marginRight: '8px' }}
                                            />
                                        }
                                    />
                                </Box>
                            ) : (
                                <Box
                                    onClick={() => setHoveredRow(null)}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        marginTop: '-16px',
                                        display: 'flex',
                                        columnGap: '0px'
                                    }}>
                                    <ODIconButton
                                        enableTooltip={true}
                                        id={`delete-${info.getValue()}`}
                                        title={'delete'}
                                        sx={{ marginRight: '8px' }}
                                        buttonType={BUTTON_TYPE.GREY}
                                        icon={<DeleteSharpIcon sx={{ ...commonIconSX }} />}
                                        onClick={() => {
                                            const groupName = info.row.original.groupName;
                                            setHoveredRow(null);
                                            dispatch(
                                                updateBiddingState({
                                                    modalMode: {
                                                        mode: MODAL_TYPE.DELETE_GROUP,
                                                        data: [
                                                            {
                                                                id: info?.getValue(),
                                                                name: groupName
                                                            }
                                                        ]
                                                    }
                                                })
                                            );
                                        }}
                                    />
                                    <ODIconButton
                                        enableTooltip={true}
                                        id={`edit-${info.getValue()}`}
                                        title={'edit'}
                                        sx={{ marginRight: '8px' }}
                                        buttonType={BUTTON_TYPE.GREY}
                                        icon={<EditIcon sx={{ ...commonIconSX }} />}
                                        onClick={() => {
                                            setHoveredRow(null);
                                            dispatch(
                                                updateBiddingState({
                                                    slideoutMode: {
                                                        mode: SLIDE_OUT_TYPE.EDIT_GROUP,
                                                        data: {
                                                            id: info?.getValue(),
                                                            data: info.row.original
                                                        }
                                                    }
                                                })
                                            );
                                        }}
                                    />
                                    <ODIconButton
                                        enableTooltip={true}
                                        id={`download-${info.getValue()}`}
                                        title={'download'}
                                        sx={{ marginRight: '8px' }}
                                        buttonType={BUTTON_TYPE.GREY}
                                        icon={<DownloadIcon sx={{ ...commonIconSX }} />}
                                        onClick={() => {
                                            dispatch(
                                                updateBiddingState({
                                                    modalMode: {
                                                        mode: MODAL_TYPE.DOWNLOAD_GROUP_FILE,
                                                        data: {
                                                            id: info?.getValue(),
                                                            data: []
                                                        }
                                                    }
                                                })
                                            );
                                            setHoveredRow(null);
                                        }}
                                    />
                                    <ODIconButton
                                        title={'close'}
                                        id={`close-${info.getValue()}`}
                                        sx={{ marginRight: '8px' }}
                                        buttonType={BUTTON_TYPE.DARK}
                                        icon={
                                            <CloseIcon
                                                sx={{
                                                    fill: palette.neutral.white
                                                }}
                                            />
                                        }
                                    />
                                </Box>
                            )
                        ) : (
                            <></>
                        )}
                    </Box>
                );
            }
        })
    ];

    return (
        <TableWithoutHeader id="bid-group-list" data={[...allFiles, ...groups]} columns={columns} />
    );
};

export default BidGroupList;
