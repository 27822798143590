import { BIDDING_NAMES } from './bidding';

export const AGGREGATION_LEVEL = [0, 1, 2, 3, 5];
export const BID_AGGREGATION_LEVEL = [3, 5];

export const ANALYSIS_TIME_AGGREGATION_TYPE = [
    { value: 'annual', label: 'Annualized' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'report', label: 'Analysis Date Range' }
];
export enum TIME_FRAME {
    ANNUALIZED = 'annual',
    WEEKLY = 'weekly',
    REPORT = 'report'
}
export enum PANEL_POSITION {
    SIDE = 'side',
    BOTTOM = 'bottom'
}

export enum LEFTNAV_OPTION {
    NETWORK_MANAGER = 'networkManager',
    BID_MANAGER = 'bidFileManager',
    FILES = 'files',
    FILE_MANAGER = 'fileManager',
    NETWORK_VIEW = 'networkView'
}

export enum PREFERRED_RATE_TYPE {
    PER_MILE = 'rate_per_mile',
    FLAT_RATE = 'flat_rate'
}

export const toggleButtonsInfoLocationView = [
    {
        value: 'city-state',
        buttonText: 'City, State'
    },
    {
        value: 'zipcode',
        buttonText: 'Zip Code '
    }
];

export enum LOCATION_VIEW {
    ZIP_CODE = 'zipcode',
    CITY_STATE = 'city-state'
}

export const toggleButtonsInfoRate = [
    {
        value: PREFERRED_RATE_TYPE.PER_MILE,
        buttonText: BIDDING_NAMES.RATE_PER_MILE
    },
    {
        value: PREFERRED_RATE_TYPE.FLAT_RATE,
        buttonText: BIDDING_NAMES.FLAT_RATE
    }
];
