import { bidFields } from 'constants/flatfile';

// See interface definition for FeatureFlags in src/constants/flatfile.ts

export const reviewStageStepHook = (importer, featureFlags) => {
    importer.registerStepHook('review', ({ headers_matched }) => {
        defaultFields(importer, headers_matched, featureFlags);
        return true;
    });
};

const defaultFields = (importer, headers_matched, featureFlags) => {
    // if each column is not in headers, add it as a field
    bidFields(featureFlags).forEach((field) => {
        if (!headers_matched.find((v) => v.matched_key === field.key)) {
            importer.addVirtualField(field);
        }
    });
};
