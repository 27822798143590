import React from 'react';
import { useSelector } from 'react-redux';
import ODDonutChart from 'shared/ODDonutChart';
import theme from 'theme';
import { trimId } from 'utils/dom.util';

const DriverDonut = (props) => {
    // To prevent this app crash error: Cannot destructure property 'num' of null as it is null
    const { total_drivers, driver_types, compare_driver_types, compare_total_drivers } =
        useSelector((state) => state.DriversReducer?.domicile) || {};
    const { row, compare_run } = props;
    const { driverTypesColors } = useSelector((state) => state.DriversReducer);
    let percentage = [''];
    let color = [];

    let currTotal = compare_run ? compare_total_drivers : total_drivers;
    const _driverTypes = compare_run ? compare_driver_types : driver_types;
    (_driverTypes || []).forEach((type) => {
        if (type?.driver_type === row?.original?.id) {
            percentage = [(type.count / currTotal) * 100];
            color = [driverTypesColors[row?.original?.id], theme.palette.neutral.neutral1];
        }
    });
    return (
        <ODDonutChart
            percentages={percentage}
            colors={color}
            light={true}
            index={trimId(`driver-count-${row?.id}-${row?.original?.id}`)}
        />
    );
};

export default DriverDonut;
