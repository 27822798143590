import {
    GET_CUSTOMER_FILES_SUCCESS,
    GET_CUSTOMER_FILES_FAILURE,
    GET_FILE_ENTITY_TYPES_SUCCESS,
    GET_FILE_ENTITY_TYPES_FAILURE,
    GET_CUSTOMER_FILES_MAPPING_SUCCESS,
    GET_CUSTOMER_FILES_MAPPING_FAILURE,
    UPDATE_CUSTOMER_FILES_SUCCESS,
    UPDATE_CUSTOMER_FILES_FAILURE
} from '../constants/actionTypes';

const initialState = {
    customerFiles: {},
    customerFileEntityTypes: [],
    customerFileMapping: [],
    isFileUpdated: false,
    error: null
};

const onGetCustomerFilesSuccess = (state, action) => {
    return {
        ...state,
        customerFiles: action.customerFiles
    };
};
const onGetCustomerFilesFailure = (state, action) => {
    return {
        ...state,
        customerFiles: {},
        error: action.error
    };
};

const onGetFileEntityTypeSuccess = (state, action) => {
    return {
        ...state,
        customerFileEntityTypes: action.customerFileEntityTypes
    };
};
const onGetFileEntityTypeFailure = (state, action) => {
    return {
        ...state,
        customerFileEntityTypes: [],
        error: action.error
    };
};

const onGetCustomerFileMappingSuccess = (state, action) => {
    return {
        ...state,
        customerFileMapping: action.customerFileMapping
    };
};
const onGetCustomerFileMappingFailure = (state, action) => {
    return {
        ...state,
        customerFileMapping: [],
        error: action.error
    };
};

const onUpdateCustomerFilesSuccess = (state, action) => {
    return {
        ...state,
        isFileUpdated: true
    };
};
const onUpdateCustomerFilesFailure = (state, action) => {
    return {
        ...state,
        isFileUpdated: false,
        error: action.error
    };
};

const FilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMER_FILES_SUCCESS:
            return onGetCustomerFilesSuccess(state, action);
        case GET_CUSTOMER_FILES_FAILURE:
            return onGetCustomerFilesFailure(state, action);
        case GET_FILE_ENTITY_TYPES_SUCCESS:
            return onGetFileEntityTypeSuccess(state, action);
        case GET_FILE_ENTITY_TYPES_FAILURE:
            return onGetFileEntityTypeFailure(state, action);
        case GET_CUSTOMER_FILES_MAPPING_SUCCESS:
            return onGetCustomerFileMappingSuccess(state, action);
        case GET_CUSTOMER_FILES_MAPPING_FAILURE:
            return onGetCustomerFileMappingFailure(state, action);
        case UPDATE_CUSTOMER_FILES_SUCCESS:
            return onUpdateCustomerFilesSuccess(state, action);
        case UPDATE_CUSTOMER_FILES_FAILURE:
            return onUpdateCustomerFilesFailure(state, action);
        default:
            return state;
    }
};

export default FilesReducer;
