/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useParamsProps } from 'types/hooks';
import { updateNetworkView } from 'redux/actions/networkActions';
import { onGetReportsSuccess } from 'redux/actions/reportActions';
import { updateConfigurationName, deleteConfiguration, settingsUpdate } from 'redux/actions/';
import Box from '@mui/material/Box';
import { ODModal, ODTextField } from 'shared';
import { styled } from '@mui/material/styles';
import { hasDuplicates } from 'utils/common.util';
import {
    SAVE_DRAFT_MESSAGE,
    DUPLICATE_ERROR_MESSAGE,
    CHARACTERS_100_ERROR_MESSAGE
} from 'constants/network';
import { LEFTNAV_OPTION } from 'constants/settings';

const SavedDraftMessageBox = styled(Box)(() => ({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    margin: '16px 0'
}));
const SaveDraftReportModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { configuration } = useSelector((state: RootState) => state.ConfigurationReducer);
    const { reports } = useSelector((state: RootState) => state.ReportsReducers);
    const { showSaveDraftModal } = useSelector((state: RootState) => state.NetworkReducer);
    const [name, setName] = useState(configuration?.name);
    const [inputError, setInputError] = useState<string | null>(null);
    const params = useParams<useParamsProps>();
    const base_run = params?.base_run;

    const handleClose = () => {
        dispatch(updateNetworkView({ showSaveDraftModal: { show: false, redirectTo: '' } }));
    };

    const handleDiscard = async () => {
        await dispatch(deleteConfiguration(base_run, configuration?.id, configuration?.name));
        history.push(showSaveDraftModal.redirectTo);
        handleClose();
    };

    const handleSaveDraftReport = async () => {
        if (!name) return;
        if (name !== configuration.name && hasDuplicates(configuration, reports, name)) {
            setInputError(DUPLICATE_ERROR_MESSAGE);
        } else if (name.length > 100) {
            setInputError(CHARACTERS_100_ERROR_MESSAGE);
        } else {
            setInputError(null);
            if (name !== configuration.name) {
                await dispatch(updateConfigurationName(base_run, configuration?.id, name.trim()));
                const newReports = reports.map((report: any) => ({
                    ...report,
                    name: report?.config_id === configuration.id ? name : report?.name
                }));
                await dispatch(onGetReportsSuccess({ reports: [...newReports] }));
            }
            history.push(showSaveDraftModal.redirectTo);
            handleClose();
        }
    };

    return (
        <ODModal
            show={showSaveDraftModal?.show}
            handleClose={() => {
                handleClose();
                dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_VIEW }));
            }}
            handleSecondAction={handleDiscard}
            handleAction={handleSaveDraftReport}
            heading="Save Draft Report"
            actionButtonText="Save"
            closeButtonText="Discard"
            runActionOnEnter={true}
            actionDisabled={!Boolean(name?.length > 0)}>
            <SavedDraftMessageBox>{SAVE_DRAFT_MESSAGE}</SavedDraftMessageBox>
            <ODTextField
                errorText={inputError}
                autoFocus={true}
                defaultValue={configuration?.name}
                dataTestId="enter-run-report-name"
                label="Name Report"
                onChange={(event) => {
                    setName(event?.target?.value);
                    if (inputError) setInputError(null);
                }}
                name="saveDraftReport"
            />
        </ODModal>
    );
};

export default SaveDraftReportModal;
