/*refere to button.tsx for guideline and types */
export enum BUTTON_TYPE {
    BLUE = 'blue',
    DARK = 'dark',
    GREEN = 'green',
    GREY = 'grey',
    LIGHT = 'light',
    MEDIUM_BLUE = 'mediumBlue',
    RED = 'red',
    TRANSPARENT = 'transparent',
    YELLOW = 'yellow',
    LIGHT_BLUE = 'lightBlue'
}
