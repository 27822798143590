import React, { useMemo } from 'react';
import { ODButton, ODModal } from 'shared';
import { Box, Tooltip } from '@mui/material';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import { ExportHelperText, FileNameCard, ButtonWrapper } from './Header.styles';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadFileByBidId } from 'redux/actions/bidAnalysisActions';
import { RootState } from 'redux/reducers';
import { useParams } from 'react-router-dom';
import { useParamsProps } from 'types/hooks';
import { useLoadingHook } from 'utils/hooks';
import { BUTTON_TYPE } from 'constants/colors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HISTORY_CHANGES_TYPES } from 'constants/network';
import AutoGraphSharpIcon from '@mui/icons-material/AutoGraphSharp';
import * as FS from '@fullstory/browser';

const DownloadBidAnalysisModal = ({
    setOpenExportModal,
    openExportModal,
    setOpenRefreshModal,
    isFinalizedBid
}: {
    setOpenExportModal: any;
    setOpenRefreshModal: any;
    openExportModal: boolean;
    isFinalizedBid: boolean;
}) => {
    const { configurationActions, prevConfigurationActions } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );
    const { bidAnalysisTableDataErrors, currBidAnalysisData } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );

    const dispatch = useDispatch();
    const { config_id }: useParamsProps = useParams();
    const { showLoader } = useLoadingHook();
    const { refreshBid, odpt4185BidConfigUpdate } = useFlags();

    const HAS_CHANGE_INFO_VERBIAGE = `Based on the changes that you have made, we recommend ${
        odpt4185BidConfigUpdate ? 'finalizing' : 'refreshing'
    }  your bid analysis. This will further optimize the results.`;
    const NO_CHANGE_INFO_VERBIAGE = `We recommend that you ${
        odpt4185BidConfigUpdate ? 'finalize' : 'refresh'
    } your bid analysis. This will further optimize the results by re-allocating unused capacity on partially covered loads that were rejected in the original analysis.`;
    const V2_CHANGE_VERBIAGE = `Based on the changes that you have made, we recommend updating your results by clicking the Finalize Bid Analysis button below.`;

    const triggerDownload = async () => {
        FS.event('Download Bid Analysis_ Click Download Button In Export Modal', {});
        await setOpenExportModal(false);
        showLoader(true, 'export');
        await dispatch(
            getDownloadFileByBidId(
                currBidAnalysisData?.id,
                currBidAnalysisData?.name,
                configuration?.id
            )
        );
        showLoader(false);
    };

    const configuration = useMemo(() => {
        return {
            ...currBidAnalysisData,
            id: config_id,
            actions: configurationActions,
            prevActions: prevConfigurationActions
        };
    }, [config_id, configurationActions, currBidAnalysisData, prevConfigurationActions]);
    //change only refers to change made here if the user undo all the changes, no change has been made. ref: 4315

    const hasBidAnalysisChange = useMemo(() => {
        return (
            configuration.actions?.filter(
                (type: any) => type?.type === HISTORY_CHANGES_TYPES.BID_ANALYSIS
            )?.length > 0
        );
    }, [configuration.actions]);

    const displayDescription = () => {
        if (hasBidAnalysisChange) {
            return odpt4185BidConfigUpdate ? V2_CHANGE_VERBIAGE : HAS_CHANGE_INFO_VERBIAGE;
        } else {
            return NO_CHANGE_INFO_VERBIAGE;
        }
    };

    return (
        <ODModal
            show={openExportModal}
            heading={`${odpt4185BidConfigUpdate ? 'Download' : 'Export'} Bid Analysis`}
            handleClose={() => {
                setOpenExportModal(false);
                FS.event('Download Bid Analysis_Close Export Modal using X Button', {});
            }}
            actionButtonText={`${odpt4185BidConfigUpdate ? 'Finalize' : 'Refresh'} Bid Analysis`}
            enableCustomizedButton={true}
            children={
                <>
                    <Tooltip title={currBidAnalysisData?.name}>
                        <FileNameCard>
                            {isFinalizedBid ? (
                                <AutoGraphSharpIcon sx={{ marginRight: '16px' }} />
                            ) : (
                                <FolderSharpIcon sx={{ marginRight: '16px' }} />
                            )}
                            <Box
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    maxWidth: 'fit-content',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}>
                                {currBidAnalysisData?.name}
                            </Box>
                        </FileNameCard>
                    </Tooltip>

                    {refreshBid && (isFinalizedBid ? hasBidAnalysisChange : true) && (
                        <ExportHelperText>{displayDescription()}</ExportHelperText>
                    )}

                    <ButtonWrapper>
                        <ODButton
                            width="188px"
                            sx={{ marginRight: '16px' }}
                            buttonType={BUTTON_TYPE.BLUE}
                            onClick={triggerDownload}
                            buttonText="Download Bid Analysis"
                        />

                        {refreshBid ? (
                            <>
                                {hasBidAnalysisChange ? (
                                    <ODButton
                                        width="188px"
                                        buttonType={BUTTON_TYPE.BLUE}
                                        buttonText={`${
                                            odpt4185BidConfigUpdate ? 'Finalize' : 'Refresh'
                                        } Bid Analysis`}
                                        disabled={
                                            Object.keys(bidAnalysisTableDataErrors).length > 0
                                        }
                                        onClick={() => {
                                            setOpenRefreshModal(true);
                                            setOpenExportModal(false);
                                            FS.event(
                                                `${
                                                    odpt4185BidConfigUpdate ? 'Finalize' : 'Refresh'
                                                } Bid Analysis_Click Refresh Button on Export Modal`,
                                                {}
                                            );
                                        }}
                                    />
                                ) : (
                                    <ODButton
                                        width="188px"
                                        onClick={() => {
                                            setOpenExportModal(false);
                                            FS.event(
                                                'Download Bid Analysis_ Close Export Modal Using Close Button',
                                                {}
                                            );
                                        }}
                                        buttonText="Close"
                                    />
                                )}
                            </>
                        ) : (
                            <ODButton
                                width="188px"
                                onClick={() => {
                                    setOpenExportModal(false);
                                    FS.event(
                                        'Download Bid Analysis_ Close Export Modal Using Close Button',
                                        {}
                                    );
                                }}
                                buttonText="Close"
                            />
                        )}
                    </ButtonWrapper>
                </>
            }
        />
    );
};

export default DownloadBidAnalysisModal;
