import React, { useEffect, useRef } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import draftStore from 'utils/draftStore.util';
import { USERTYPE } from 'constants/users';

const WaitForLDIdentify = ({ children }) => {
    const ldauth = useSelector((state) => state.SignInReducer.launchDarklyUserIdentified);
    return ldauth !== false ? children : <></>;
};
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    const location = useLocation();
    const roles = useSelector((state) => state.SignInReducer.roles);
    const prevLocation = usePrevious(location?.pathname);

    const redirectDefault = (props) => {
        props.history.push('/login');
    };

    const {
        location: { search },
        requireRoles = []
    } = rest;

    const isAuth0 = search.includes('code=');
    const isAdmin = roles.includes(USERTYPE.SUPER_ADMIN);
    let isAuthorized = requireRoles.length === 0;
    if (requireRoles.length > 0)
        isAuthorized = roles.reduce((isAuthorized, role) => {
            if (!isAuthorized) isAuthorized = requireRoles.includes(role);
            return isAuthorized;
        }, isAuthorized);

    useEffect(() => {
        draftStore.clear();
    }, []);

    useEffect(() => {
        draftStore.set('prevLocation', prevLocation);
    }, [prevLocation]);

    return (
        <Route {...rest}>
            {(props) =>
                isAuthenticated || isLoading ? (
                    <WaitForLDIdentify>
                        <Layout {...props} isAuthorized={isAuthorized}>
                            {isAuthorized ? <Component {...props} isAdmin={isAdmin} /> : <></>}
                        </Layout>
                    </WaitForLDIdentify>
                ) : (
                    !isAuth0 && redirectDefault(props)
                )
            }
        </Route>
    );
};

// Custom Hook
const usePrevious = (value = null) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

export default PrivateRoute;
