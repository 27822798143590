import {
    REPORT_TYPE_SUCCESS,
    REPORT_TYPE_FAILURE,
    CREATE_REPORT_SUCCESS,
    CREATE_REPORT_FAILURE,
    RESET_REPORT_ID,
    REPORT_PARAM_VERSION_FAILURE,
    REPORT_PARAM_VERSION_SUCCESS,
    REPORT_DETAIL_BY_ID_SUCCESS,
    REPORT_DETAIL_BY_ID_FAILURE,
    REPORT_NOTIFICATION_SUCCESS,
    REPORT_PARAMETER_SUCCESS,
    REPORT_DRIVER_TYPES_SUCCESS,
    UPDATE_REPORTS_REDUCER,
    REPORT_FILTER_SUCCESS,
    UPDATE_LOAD_SOURCE,
    SET_SELECTED_DRIVER_TYPES,
    SET_SELECTED_PICKUP_LOCATIONS,
    SET_SELECTED_DROPOFF_LOCATIONS,
    RESET_FILTERS,
    LOAD_FILTER_SUCCESS,
    UPDATE_FREIGHT_BONUS_APPLIED,
    UPDATE_APPLIED_FILTERS_COUNT
} from '../constants/actionTypes';
import { isEmpty, isEqual, isNil, sortBy } from 'lodash';
import API from 'utils/axios';
import { uniq } from 'lodash';
import { HANDLE_GROUP_SLIDEOUT } from 'constants/reports';
import { updateNetworkView } from './networkActions';

/*TODO: Remove GetParamVersion if we are going to remove add_files.js */
/*TODO: Remove GetReportTypes, ResetReportId and CreateReport if we are going to remove reports.js */

export const updateLoadSource = (loadSource) => {
    return {
        type: UPDATE_LOAD_SOURCE,
        loadSource: loadSource
    };
};
export const resetFilters = () => {
    return {
        type: RESET_FILTERS
    };
};
export const setSelectedDriverTypes = (driverTypes) => {
    return {
        type: SET_SELECTED_DRIVER_TYPES,
        selectedDriverTypes: driverTypes
    };
};
export const setSelectedPickupLocations = (locations) => {
    return {
        type: SET_SELECTED_PICKUP_LOCATIONS,
        selectedPickupLocations: locations
    };
};
export const setSelectedDropoffLocations = (locations) => {
    return {
        type: SET_SELECTED_DROPOFF_LOCATIONS,
        selectedDropoffLocations: locations
    };
};
export const updateAppliedFiltersCount = (appliedFiltersCount) => {
    return {
        type: UPDATE_APPLIED_FILTERS_COUNT,
        appliedFiltersCount: appliedFiltersCount
    };
};

export const updateFreightBonusApplied = (freightBonusApplied) => {
    return {
        type: UPDATE_FREIGHT_BONUS_APPLIED,
        isFreightBonusApplied: freightBonusApplied
    };
};

//Get Report Type
export const onReportTypeSuccess = ({ reportTypes, error }) => {
    return {
        type: REPORT_TYPE_SUCCESS,
        reportTypes,
        error
    };
};
export const onReportTypeFailure = (error) => {
    return (dispatch) => {
        dispatch({ type: REPORT_TYPE_FAILURE, error });
    };
};
export const GetReportTypes = () => {
    return async (dispatch) => {
        try {
            const res = await API.get('/report-types/');
            dispatch(onReportTypeSuccess({ reportTypes: res.data.results, error: null }));
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onReportTypeFailure({
                        error: 'Unable to get report types'
                    })
                );
            }
            dispatch(onReportTypeFailure({ error: 'Unable to get report types' }));
        }
    };
};

//Create Report
export const onCreateReportSuccess = ({ reportId, error }) => {
    return {
        type: CREATE_REPORT_SUCCESS,
        reportId,
        error
    };
};
export const onCreateReportFailure = ({ error }) => {
    return (dispatch) => {
        dispatch({ type: CREATE_REPORT_FAILURE, error });
    };
};
export const ResetReportId = () => {
    return (dispatch) => {
        dispatch({ type: RESET_REPORT_ID });
    };
};
export const CreateReport = (data) => {
    return async (dispatch) => {
        const { name, report_type } = data;
        let error = {};
        if (isEmpty(name)) error['name'] = 'Please provide the report name';
        if (isNil(report_type)) error['report_type'] = 'Please select a report type';

        if (!isEmpty(error))
            return dispatch(
                onCreateReportFailure({
                    error
                })
            );

        dispatch(onCreateReportSuccess({ error: null }));
        try {
            const res = await API.post('/reports/', data);
            dispatch(onCreateReportSuccess({ reportId: res.data.id, error: null }));
        } catch (err) {
            if (err && err.response) {
                const { error } = err.response.data;
                return dispatch(
                    onCreateReportFailure({
                        error
                    })
                );
            }
            dispatch(onCreateReportFailure({ error: 'Unable to create report' }));
        }
    };
};

//GET Param version Report
export const onGetParamVersionSuccess = (reportParamVersions) => {
    return {
        type: REPORT_PARAM_VERSION_SUCCESS,
        reportParamVersions
    };
};
export const onGetParamVersionFailure = (error) => {
    return (dispatch) => {
        dispatch({ type: REPORT_PARAM_VERSION_FAILURE, error });
    };
};
export const GetParamVersion = () => {
    return async (dispatch) => {
        try {
            const res = await API.get('/parameter-versions');
            dispatch(onGetParamVersionSuccess(res.data.results));
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onGetParamVersionFailure({
                        error: 'Unable to GetParamVersion'
                    })
                );
            }
            dispatch(onGetParamVersionFailure({ error: 'Unable toGetParamVersion' }));
        }
    };
};

//GET Report Detail
export const onGetReportDetailByIdSuccess = (reportDetail) => {
    return {
        type: REPORT_DETAIL_BY_ID_SUCCESS,
        reportDetail
    };
};
export const onGetReportDetailByIdFailure = (error) => {
    return (dispatch) => {
        dispatch({ type: REPORT_DETAIL_BY_ID_FAILURE, error });
    };
};
export const GetReportDetailById = (reportId) => {
    return async (dispatch) => {
        try {
            const res = await API.get(`/reports/report-runs/${reportId}/`);
            dispatch(onGetReportDetailByIdSuccess(res.data));
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onGetReportDetailByIdFailure({
                        error: 'Unable to GetReportDetailById'
                    })
                );
            }
            dispatch(onGetReportDetailByIdFailure({ error: 'Unable GetReportDetailById' }));
        }
    };
};

//GET and UPDATE notifications
export const onReportNotificationSuccess = ({
    notificationDetails: fetchedNotificationDetails
}) => {
    return async (_dispatch, getState) => {
        const notificationDetails = getState().ReportsReducers.notificationDetails;
        const shouldUpdate =
            fetchedNotificationDetails.length !== notificationDetails.length ||
            !isEqual(
                sortBy(fetchedNotificationDetails.map((n) => n.id)),
                sortBy(notificationDetails.map((n) => n.id))
            );
        if (shouldUpdate) {
            _dispatch({
                type: REPORT_NOTIFICATION_SUCCESS,
                notificationDetails: fetchedNotificationDetails
            });
        }
    };
};

export const GetReportNotifications = (status) => {
    return async (dispatch) => {
        try {
            let filter = [];
            if (status) filter.push(`status=${status}`);
            // Only fetch unread by default
            const res = await API.get(`/reports/notifications/?${filter.join('&')}`);

            dispatch(
                onReportNotificationSuccess({
                    notificationDetails: res.data.notifications
                })
            );
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onReportNotificationSuccess({
                        notificationDetails: []
                    })
                );
            }
        }
    };
};
export const UpdateNotifications = (payload) => {
    return async (dispatch) => {
        try {
            const res = await API.put('/reports/notifications/', payload);
            if (res.data.success) dispatch(GetReportNotifications());
        } catch (err) {
            if (err && err.response) {
            }
        }
    };
};

// GET, PUT, soft DELETE for NEW notification flow
export const GetNewReportNotifications = (status) => {
    return async (dispatch) => {
        try {
            let filter = [];
            if (status) filter.push(`status=${status}`);
            // Will fetch read and unread by default
            const res = await API.get(`/reports/new-notifications/?${filter.join('&')}`);

            dispatch(
                onReportNotificationSuccess({
                    notificationDetails: res.data.notifications
                })
            );
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onReportNotificationSuccess({
                        notificationDetails: []
                    })
                );
            }
        }
    };
};

export const UpdateNewNotifications = (payload) => {
    return async (dispatch) => {
        try {
            const res = await API.put('/reports/new-notifications/', payload);
            if (res.data.success) dispatch(GetNewReportNotifications());
        } catch (err) {
            if (err && err.response) {
                console.warn('Could not update notification ' + err);
            }
        }
    };
};

export const softDeleteNotification = (clearAll, notifID) => {
    return async (dispatch) => {
        try {
            let res;
            if (clearAll)
                res = await API.delete('/reports/new-notifications/', { clear_all: clearAll });
            else res = await API.delete(`/reports/new-notifications/${notifID}/`);
            if (res.data.success) dispatch(GetNewReportNotifications());
        } catch (err) {
            if (err && err.response) {
                console.warn('Could not delete notification ' + err);
            }
        }
    };
};

//GET Driver_Types
export const onGetDriverTypesSuccess = (reportDriverTypes) => {
    return {
        type: REPORT_DRIVER_TYPES_SUCCESS,
        ...reportDriverTypes
    };
};
export const getDriverTypesByReportId = (report_id) => {
    return async (dispatch) => {
        try {
            dispatch(updateNetworkView({ loadingGetDriverTypesById: true }));
            const res = await API.get(`reports/report-runs/${report_id}/driver-types/`);
            dispatch(
                onGetDriverTypesSuccess({
                    reportDriverTypes: res.data
                })
            );
            dispatch(updateNetworkView({ loadingGetDriverTypesById: false }));
        } catch (err) {
            if (err && err.response) {
                dispatch(updateNetworkView({ loadingGetDriverTypesById: false }));
                return dispatch(
                    onGetDriverTypesSuccess({
                        reportDriverTypes: {}
                    })
                );
            }
        }
    };
};

/*!TODO KEEP THESE BELOW */
//GET and UPDATE parameters data
export const onGetParameterSuccess = (parametersData) => {
    return {
        type: REPORT_PARAMETER_SUCCESS,
        ...parametersData
    };
};

export const updateParamsByConfigId = (id, data) => {
    return async (_dispatch) => {
        try {
            await API.post(
                `/reports/report-runs/parameters/parameter-values/?configuration_id=${id}`,
                data
            );
        } catch (err) {
            if (err && err.response) {
            }
        }
    };
};
export const getParamsByConfigId = (id) => {
    return async (dispatch) => {
        try {
            const res = await API.get(
                `reports/report-runs/parameters/parameter-values/?configuration_id=${id}`
            );
            dispatch(
                onGetParameterSuccess({
                    parametersData: res.data
                })
            );
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onGetParameterSuccess({
                        parametersData: {}
                    })
                );
            }
        }
    };
};

//GET Driver_Types
export const onGetReportFilterSuccess = (reportFilters) => {
    return {
        type: REPORT_FILTER_SUCCESS,
        ...reportFilters
    };
};

export const failedRequestTo404Page = (error) => {
    return {
        type: UPDATE_REPORTS_REDUCER,
        ...error
    };
};
export const onGetReportsSuccess = (reports) => {
    return {
        type: UPDATE_REPORTS_REDUCER,
        ...reports
    };
};

export const getTableDataByIdAndFilters = (report_id, filter, v2KpIsFlag) => {
    return async (dispatch) => {
        try {
            let reportFilter = [];

            if (!isEmpty(filter)) {
                for (let key in filter) {
                    if (filter[key] || ['is_bid', 'is_bonus'].includes(key))
                        reportFilter.push(`${key}=${filter[key]}`);
                }
            }

            dispatch(updateNetworkView({ loadingGetTableDataByIdAndFilters: true }));
            const res = await API.get(
                `/reports/report-runs/${report_id}/filters/${
                    v2KpIsFlag ? 'v2/' : ''
                }?${reportFilter.join('&')}`
            );

            dispatch(
                onGetReportFilterSuccess({
                    reportFilters: res.data
                })
            );
            dispatch(updateNetworkView({ loadingGetTableDataByIdAndFilters: false }));
        } catch (err) {
            if (err && err.response) {
                dispatch(updateNetworkView({ loadingGetTableDataByIdAndFilters: false }));
                dispatch(
                    onGetReportFilterSuccess({
                        reportFilters: []
                    })
                );
                if (report_id?.length > 0) {
                    dispatch(
                        failedRequestTo404Page({
                            reportError: true
                        })
                    );
                }
            }
        }
    };
};

export const onSetLoadFiltersSuccess = (loadFilters) => {
    return {
        type: LOAD_FILTER_SUCCESS,
        ...loadFilters
    };
};
export const setLoadFilters = (loadFilters) => {
    return (dispatch) => {
        dispatch(onSetLoadFiltersSuccess({ loadFilters }));
    };
};

export const rerunOutdatedReportByReportId = async (reportId) => {
    try {
        return await API.post(`/reports/rerun-outdated-report/${reportId}/`);
    } catch (err) {
        if (err && err.response) {
        }
    }
};

export const submitGroup = async (runIds, name, bucket, action) => {
    const body = { name, report_runs: uniq(runIds) };

    if (action === HANDLE_GROUP_SLIDEOUT.ADD_NEW_BUCKET) {
        return await API.post(`/buckets/`, body);
    } else if (bucket.id) return await API.patch(`/buckets/${bucket.id}/`, body);
};

export const getBuckets = () => {
    return async (dispatch) => {
        try {
            let {
                data: { results }
            } = await API.get(`/buckets/`);

            const buckets = [
                {
                    id: 'all',
                    name: 'All Reports'
                },
                ...results
            ];

            dispatch(updateNetworkView({ buckets: buckets }));

            return buckets;
        } catch (err) {
            if (err && err.response) {
                console.warn('getting buckets', err.response);
            }
        }
    };
};
