import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import { ODFooterActionBar, ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { getLocation } from 'utils/common.util';
import { isEmpty } from 'lodash';
import { useNetworkViewHook, useDriverHook } from 'utils/hooks';
import { updateNetworkView } from 'redux/actions';

const DriverActionBar = () => {
    const dispatch = useDispatch();
    const { handleDriverEdit } = useDriverHook();
    const { reportDomiciles, checked, domicile } = useSelector((state) => state.DriversReducer);
    const { locationVisualization, aggregationLevel } = useSelector(
        ({ settingsReducer }) => settingsReducer
    );
    const reportLocations = useSelector((state) => state.ReportKPIReducer.reportLocations);
    const { bidConfigLocations } = useSelector((state) => state.BiddingReducer);
    const { selectedDomicile, selectedDriverType } = checked;

    const { compare_run } = useParams();
    const { currReport } = useNetworkViewHook();
    const readOnly = currReport?.read_only || compare_run;
    let selectedRows = [];
    let ribbonText = '';
    let ribbonTextTooltip = '';

    const actionBarDataPopulate = (selected, allRows, isDriverType = false) => {
        if (selected?.length === 1) {
            if (!isDriverType) {
                selectedRows = allRows?.find((report) => report.domicile_id === selected[0]);
                if (selected[0] === 'all' && aggregationLevel === 0) {
                    ribbonText = 'All';
                } else {
                    ribbonText = getLocation(
                        selectedRows?.name,
                        reportLocations,
                        locationVisualization,
                        bidConfigLocations
                    );
                    ribbonTextTooltip = getLocation(
                        selectedRows?.name,
                        reportLocations,
                        'city-state-zip',
                        bidConfigLocations
                    );
                }
            } else {
                selectedRows = allRows?.find((report) => report?.driver_type === selected[0]);
                ribbonText = selected[0];
            }
        }
    };

    if (selectedDriverType?.length > 0) {
        actionBarDataPopulate(selectedDriverType, domicile?.driver_types, true);
    } else {
        actionBarDataPopulate(selectedDomicile, reportDomiciles);
    }
    useEffect(() => {
        if (isEmpty(selectedDomicile))
            dispatch(
                updateNetworkView({
                    showDriverOptionBar: false
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDomicile]);

    return (
        <ODFooterActionBar
            sx={{
                zIndex: 3
            }}
            buttonsOverride={
                <ODButton
                    disabled={readOnly}
                    buttonText={`Edit Driver ${selectedDriverType?.length > 0 ? 'type' : ''}`}
                    endIcon={<EditIcon />}
                    sx={{ marginRight: 0 }}
                    buttonType={BUTTON_TYPE.BLUE}
                    onClick={() => {
                        handleDriverEdit(
                            selectedRows,
                            selectedDriverType?.length > 0
                                ? selectedRows?.driver_type
                                : selectedRows?.id
                        );
                    }}
                />
            }
            ribbonIcon={<PersonIcon />}
            ribbonText={ribbonText}
            ribbonTextTooltip={ribbonTextTooltip}
        />
    );
};

export default DriverActionBar;
