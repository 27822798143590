import theme from 'theme';
import { RATING, BidAnalysisAction, NewBidAnalysisAction } from 'types/bidAnalysis';
import { actionDataProps } from 'components/Slideouts/HistorySlideout';
import { DECISION } from 'constants/bidding';
import { HISTORY_CHANGES_TYPES } from 'constants/network';
import { CellEdit, DataRow } from 'shared/ODTable/ODTableTypes';
import { getLaneOriginDestinationString, mapColumnIdActionType } from 'utils/common.util';
import { produce, Draft } from 'immer';

export const getBalanceColor = (
    resultingBalance: number,
    valueBeforeChange: number,
    odpt3965MarketBalanceColumns: boolean
) => {
    const original = resultingBalance - valueBeforeChange;
    const lessThanCondition = odpt3965MarketBalanceColumns
        ? Math.abs(resultingBalance) <= Math.abs(valueBeforeChange)
        : Math.abs(original) <= Math.abs(valueBeforeChange);
    const moreThanCondition = odpt3965MarketBalanceColumns
        ? Math.abs(resultingBalance) > Math.abs(valueBeforeChange)
        : Math.abs(original) > Math.abs(valueBeforeChange);
    return lessThanCondition
        ? theme.palette.semantic.semanticGreen
        : moreThanCondition
        ? theme.palette.semantic.semanticRed
        : theme.palette.neutral.black;
};

export const getLaneScoreRating = ({ value }: { value: number }) => {
    return value >= 70 ? RATING.IDEAL : value > 10 ? RATING.TOLERABLE : RATING.UNACCEPTABLE;
};
export const getSalesRankingRating = ({ value }: { value: number }) => {
    return value === 1 ? RATING.IDEAL : value > 3 ? RATING.UNACCEPTABLE : RATING.TOLERABLE;
};

export const findLane = (laneId: any, lanes: DataRow[]) => {
    return lanes.find(({ bid_id }: any) => bid_id === laneId);
};
export const getOriginalLaneDecision = (lane: DataRow) => {
    return lane.original_volume_to_accept === 0 ? DECISION.REJECT : DECISION.ACCEPT;
};
export const getUpdatedSettings = (settings: any, previousSettings: any) => {
    let previousColumnSpecificTimeAggregationSelections =
        previousSettings.column_specific_time_aggregation;
    const updatedColumnSpecificTimeAggregationSelections = {
        ...previousColumnSpecificTimeAggregationSelections,
        ...(settings?.column_specific_time_aggregation as undefined | object)
    };
    return {
        ...previousSettings,
        ...settings,
        column_specific_time_aggregation: updatedColumnSpecificTimeAggregationSelections
    };
};
export const deriveActionsFromEdits = (
    cellEdits: CellEdit[],
    lanes: DataRow[],
    bidAnalysisSetting: any,
    changedBy: string | undefined
) => {
    const { time_aggregation } = bidAnalysisSetting;
    let actions: NewBidAnalysisAction[] = [];
    for (let i = 0; i < cellEdits.length; i++) {
        const { rowId, columnId, newValue } = cellEdits[i];
        const row = lanes.find((row: DataRow) => row.id === rowId);
        if (row) {
            const originalDecision = getOriginalLaneDecision(row);
            const laneOriginDestination = getLaneOriginDestinationString(row);
            switch (columnId) {
                case 'volume_to_accept':
                    if (Number(row.volume_to_accept) !== Number(newValue)) {
                        if (Number(newValue) === Number(row.original_volume_to_accept)) {
                            restoreBidAnalysisAction(
                                actions,
                                {
                                    bid_lane_id: rowId,
                                    lane: laneOriginDestination,
                                    original: row.original_volume_to_accept as number,
                                    change: newValue
                                },
                                HISTORY_CHANGES_TYPES.VOLUME,
                                lanes
                            );
                        } else {
                            if (!time_aggregation || time_aggregation === 'report') {
                                actions.push({
                                    item_type: HISTORY_CHANGES_TYPES.VOLUME,
                                    type: 'bid-analysis',
                                    value: {
                                        bid_lane_id: rowId,
                                        lane: laneOriginDestination,
                                        change: Number(newValue),
                                        original: row.original_volume_to_accept as number,
                                        changed_by: changedBy
                                    }
                                });
                            } else {
                                actions.push({
                                    item_type: HISTORY_CHANGES_TYPES.VOLUME,
                                    type: 'bid-analysis',

                                    value: {
                                        bid_lane_id: rowId,
                                        lane: laneOriginDestination,
                                        change: Number(newValue),
                                        original: row.original_volume_to_accept as number,
                                        time_aggregation:
                                            time_aggregation === 'weekly' ? 'weekly' : 'annual',
                                        changed_by: changedBy
                                    }
                                });
                            }
                        }
                    }
                    break;
                case 'recommendation':
                    if (
                        (originalDecision === DECISION.REJECT && newValue === DECISION.REJECT) ||
                        (originalDecision === DECISION.ACCEPT && newValue === DECISION.ACCEPT)
                    ) {
                        restoreBidAnalysisAction(
                            actions,
                            {
                                bid_lane_id: rowId,
                                lane: laneOriginDestination,
                                original: originalDecision,
                                change: newValue as string
                            },
                            HISTORY_CHANGES_TYPES.DECISION,
                            lanes
                        );
                    } else {
                        actions.push({
                            item_type: HISTORY_CHANGES_TYPES.DECISION,
                            type: 'bid-analysis',

                            value: {
                                bid_lane_id: rowId,
                                lane: laneOriginDestination,
                                original: originalDecision,
                                change: newValue as string,
                                changed_by: changedBy
                            }
                        });
                        actions.push({
                            item_type: HISTORY_CHANGES_TYPES.VOLUME,
                            type: 'bid-analysis',

                            value: {
                                bid_lane_id: rowId,
                                lane: laneOriginDestination,
                                change:
                                    newValue === DECISION.REJECT
                                        ? 0
                                        : (row.total_volume_available as number),
                                original:
                                    originalDecision === DECISION.REJECT
                                        ? 0
                                        : (row.original_volume_to_accept as number),
                                changed_by: changedBy
                            }
                        });
                    }
                    break;
                case 'rate_per_mile':
                    actions.push({
                        item_type: HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE,
                        type: 'bid-analysis',

                        value: {
                            bid_lane_id: rowId,
                            lane: laneOriginDestination,
                            change: Number(newValue),
                            original: row.original_rate_per_mile as number,
                            changed_by: changedBy
                        }
                    });
                    break;
            }
        }
    }
    return actions;
};
export const applyAction = (action: BidAnalysisAction, lanes: DataRow[]) => {
    const laneToModify = lanes.find((lane: DataRow) => lane.id === action.value.bid_lane_id);
    if (!laneToModify) console.warn('no lane found matching the action');
    else {
        switch (action.item_type) {
            case HISTORY_CHANGES_TYPES.VOLUME:
                laneToModify.volume_to_accept = action.value.change;
                break;
            case HISTORY_CHANGES_TYPES.DECISION:
                laneToModify.recommendation = action.value.change;
                break;
            case HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE:
                laneToModify.rate_per_mile = action.value.change;
                laneToModify.flat_rate = Number(action.value.change) * Number(laneToModify.mileage);
                break;
        }
    }
};
export const applyBidAnalysisActions = ({
    lanes,
    actions,
    applyAction
}: {
    lanes: DataRow[];
    actions: any;
    applyAction: (action: BidAnalysisAction, lanes: DataRow[]) => void;
}) => {
    const modifiedLanes = produce(lanes, (draft: Draft<DataRow[]>) => {
        if (actions.length > 0) {
            actions.forEach((action: BidAnalysisAction) => {
                applyAction(action, draft);
            });
        }
    });
    return modifiedLanes;
};
export const restoreBidAnalysisAction = (
    actionData: actionDataProps['actionData'],
    data: actionDataProps['data'],
    itemType: string,
    currBidAnalysisLanes: DataRow[],
    tableRestoreEditData?: actionDataProps['tableRestoreEditData']
): actionDataProps => {
    const addRestoreAction = (id: any, item_type: string, data: any) => {
        let original;
        if (data.original === undefined) {
            original =
                item_type === HISTORY_CHANGES_TYPES.VOLUME
                    ? data.covered
                    : item_type === HISTORY_CHANGES_TYPES.DECISION
                    ? data.recommendation === DECISION.ACCEPT
                        ? DECISION.REJECT
                        : DECISION.ACCEPT
                    : undefined;
        }
        actionData.push({ bid_lane_id: id, type: 'restore', item_type });
        if (tableRestoreEditData) {
            if (
                item_type === HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE ||
                item_type === HISTORY_CHANGES_TYPES.CHANGE_BID_FLAT_RATE
            ) {
                tableRestoreEditData.push(
                    {
                        columnId: 'flat_rate',
                        newValue: data?.original_flat_rate,
                        rowId: data?.bid_lane_id ?? data.bid_id
                    },
                    {
                        columnId: 'rate_per_mile',
                        newValue: data?.original_rate_per_mile,
                        rowId: data?.bid_lane_id ?? data.bid_id
                    }
                );
            } else {
                tableRestoreEditData.push({
                    columnId: mapColumnIdActionType(item_type, true),
                    newValue: data?.original ?? original,
                    rowId: data?.bid_lane_id ?? data.bid_id
                });
            }
        }
    };
    if (itemType === HISTORY_CHANGES_TYPES.VOLUME) {
        if (data.original !== 0 && data.change !== 0) {
            addRestoreAction(data.bid_lane_id, itemType, data);
        } else if (data.original !== 0 && data.change === 0) {
            addRestoreAction(data.bid_lane_id, itemType, data);
            const laneData = findLane(data.bid_lane_id, currBidAnalysisLanes);
            if (laneData) {
                addRestoreAction(laneData?.bid_id, HISTORY_CHANGES_TYPES.DECISION, laneData);
            }
        } else if (data.original === 0 && data.change !== 0) {
            addRestoreAction(data.bid_lane_id, itemType, data);
            const laneData = findLane(data.bid_lane_id, currBidAnalysisLanes);
            if (laneData) {
                addRestoreAction(laneData?.bid_id, HISTORY_CHANGES_TYPES.DECISION, laneData);
            }
        }
    } else if (itemType === HISTORY_CHANGES_TYPES.DECISION) {
        addRestoreAction(data.bid_lane_id, itemType, data);
        const laneData = findLane(data.bid_lane_id, currBidAnalysisLanes);
        if (laneData) {
            addRestoreAction(laneData?.bid_id, HISTORY_CHANGES_TYPES.VOLUME, laneData);
        }
    } else if (
        itemType === HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE ||
        itemType === HISTORY_CHANGES_TYPES.CHANGE_BID_FLAT_RATE
    ) {
        const laneData = findLane(data.bid_lane_id, currBidAnalysisLanes);
        if (laneData) {
            addRestoreAction(
                data.bid_lane_id,
                HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE,
                laneData
            );
        }
    }

    return tableRestoreEditData;
};

export const captureLinkedEdits = (
    originalCellEdits: CellEdit[],
    currBidAnalysisLanes: DataRow[]
) => {
    const linkedCellEdits = [];
    for (let i = 0; i < originalCellEdits.length; i++) {
        const { rowId, columnId, newValue } = originalCellEdits[i];
        const row = currBidAnalysisLanes.find((row: DataRow) => row.id === rowId);
        if (row) {
            if (columnId === 'volume_to_accept') {
                if (Number(newValue) === 0 && row.recommendation === DECISION.ACCEPT) {
                    linkedCellEdits.push({
                        rowId: rowId,
                        columnId: 'recommendation',
                        newValue: DECISION.REJECT
                    });
                } else if (Number(newValue) !== 0 && row.recommendation === DECISION.REJECT) {
                    linkedCellEdits.push({
                        rowId: rowId,
                        columnId: 'recommendation',
                        newValue: DECISION.ACCEPT
                    });
                }
            } else if (columnId === 'recommendation') {
                linkedCellEdits.push({
                    rowId: rowId,
                    columnId: 'volume_to_accept',
                    newValue: newValue === DECISION.REJECT ? 0 : row.total_volume_available
                });
            } else if (columnId === 'flat_rate') {
                linkedCellEdits.push({
                    rowId: rowId,
                    columnId: 'rate_per_mile',
                    newValue: parseFloat((newValue / Number(row.mileage)).toFixed(2))
                });
            } else if (columnId === 'rate_per_mile') {
                linkedCellEdits.push({
                    rowId: rowId,
                    columnId: 'flat_rate',
                    newValue: newValue * Number(row.mileage)
                });
            }
        }
        linkedCellEdits.push(originalCellEdits[i]);
    }
    return linkedCellEdits;
};

export const convertTo3DigitZipCode = ({ lanes }: { lanes: DataRow[] }) => {
    lanes.forEach((lane: DataRow) => {
        lane.origin = (lane?.origin as string)?.slice(0, 3);
        lane.destination = (lane?.destination as string)?.slice(0, 3);
    });
};

export const formatBidAnalysisRuns = (bidAnalysisRuns: any, bidConfigId: number) => {
    const bidAnalysisRunsArr = [];
    for (const key in bidAnalysisRuns) {
        bidAnalysisRunsArr.push({
            id: key,
            bid_config_id: bidConfigId,
            draft_report_name: bidAnalysisRuns[key]?.bidAnalysisRunName,
            finalized_report_name: bidAnalysisRuns[key]?.finalizedBidAnalysisRunName,
            adjustments: bidAnalysisRuns[key]?.adjustments ?? null
        });
    }

    return {
        runs: bidAnalysisRunsArr,
        base_report_run_id: bidAnalysisRuns?.uploadedBidData?.selectedReport.value
    };
};
