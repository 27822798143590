import API from 'utils/axios';
import downloadAPI from 'utils/downloadAxios.util';
import {
    ADD_BID_CONFIG_TABLE_ERROR,
    CLEAR_BID_CONFIG_TABLE_ERROR,
    BID_CONFIG_SUCCESS,
    BID_CONFIG_FAIL,
    UPDATE_BID_REDUCER,
    RESET_MODAL_MODE,
    RESET_GROUP_SLIDE_OUT,
    RESET_BID_MANAGER_TABLE,
    BID_CONFIG_BASE_NETWORK_REPORTS_SUCCESS
} from 'redux/constants/actionTypes';
import { MODAL_TYPE, SLIDE_OUT_TYPE } from 'constants/bidding';
import { BUSINESS_TYPE } from 'constants/bidding';
import { PREFERRED_RATE_TYPE } from 'constants/settings';
import { toast } from 'react-toastify';

export const addBidConfigTableErrors = (errors) => ({
    type: ADD_BID_CONFIG_TABLE_ERROR,
    payload: { errors }
});

export const clearBidConfigTableErrors = (errors) => ({
    type: CLEAR_BID_CONFIG_TABLE_ERROR,
    payload: { errors }
});

export const resetModalMode = () => {
    return {
        type: RESET_MODAL_MODE,
        payload: {
            modalMode: {
                mode: MODAL_TYPE.NOSHOW,
                data: []
            }
        }
    };
};

export const resetBidFileManagerTable = () => {
    return {
        type: RESET_BID_MANAGER_TABLE,
        payload: {
            bidFileManagerSelectedConfigIds: []
        }
    };
};

export const resetGroupSlideOut = () => {
    return {
        type: RESET_GROUP_SLIDE_OUT,
        payload: {
            slideoutMode: {
                mode: SLIDE_OUT_TYPE.NOSHOW,
                data: {}
            }
        }
    };
};

export const updateBiddingState = (payload) => {
    return {
        type: UPDATE_BID_REDUCER,
        payload
    };
};

export const bidConfigSuccess = ({ bidLanes, error }) => {
    return {
        type: BID_CONFIG_SUCCESS,
        bidLanes,
        error
    };
};

export const bidConfigFail = ({ error }) => {
    return {
        type: BID_CONFIG_FAIL,
        error
    };
};

/* get */
export const getConfigsByGroupId = async (groupId) => {
    const { data } = await API.get(`/bidding/groups/${groupId}/configs`);
    return data;
};

export const getAllGroups = () => {
    return async (dispatch) => {
        try {
            const { data } = await API.get(`/bidding/groups/`);

            dispatch(
                updateBiddingState({
                    allGroups: data?.results
                })
            );
        } catch (err) {
            if (err && err.response) {
                console.warn(err);
            }
        }
    };
};

export const getAllBidFiles = () => {
    return async (dispatch) => {
        try {
            const { data } = await API.get(`/bidding/configurations/`);

            //! ideally we should handle this from API side, but, we are following EM's suggestion for now: https://optimaldynamics.slack.com/archives/C04M5NLB9TQ/p1698424545968199
            const transformed = data?.results.map((row) => {
                const { business_type } = row;
                const businessType =
                    business_type === 'new' ? BUSINESS_TYPE.NEW : BUSINESS_TYPE.EXISTING;

                return { ...row, businessType };
            });

            dispatch(updateBiddingState({ allBidFiles: transformed }));
        } catch (err) {
            if (err && err.response) {
                console.warn(err);
            }
        }
    };
};

export const getAllBidAssociatedReports = (id) => {
    return async (dispatch) => {
        try {
            const { data } = await API.get(`/bidding/configurations/${id}/reports/`);
            dispatch(updateBiddingState({ allBidAssociatedReports: data.data }));
        } catch (err) {
            if (err && err.response) {
                console.warn(err);
            }
        }
    };
};

/* post */

export const createBidConfiguration = async (configData) => {
    const {
        fileName,
        existingShipperID,
        shipperProfile,
        newShipperID,
        selectBusinessType,
        defaultRateType
    } = configData;

    const createdConfigData = {
        file_name: fileName,
        business_type: selectBusinessType === BUSINESS_TYPE.NEW ? 'new' : 'existing',
        shipper_id:
            selectBusinessType === BUSINESS_TYPE.EXISTING ? existingShipperID : newShipperID,
        reference_shipper_id:
            selectBusinessType === BUSINESS_TYPE.EXISTING ? existingShipperID : shipperProfile,
        default_rate_type: defaultRateType || PREFERRED_RATE_TYPE.PER_MILE
    };

    try {
        return await API.post(`/bidding/configurations/`, createdConfigData);
    } catch (err) {
        if (err && err.response) {
            console.warn(`There was an error creating the bid configuration.`);
            console.warn(err);
            toast.error(`There was an error creating the bid configuration.`, {
                position: 'bottom-right'
            });
        }
    }
};

export const createBidAnalysisRunsFromBidConfig = async (runsData) => {
    return async (dispatch) => {
        try {
            const res = await API.post(`/bidding/:run-bid-analysis/`, runsData);
            return res.data;
        } catch (err) {
            if (err && err.response) {
                console.warn(`There was an error creating the bid analysis run(s).`);
                console.warn(err);
                toast.error(`There was an error creating the bid analysis run(s).`, {
                    position: 'bottom-right'
                });
            }
        }
    };
};

const ratePerMileError = (lane, cellEdit) => {
    const { columnId, newValue } = cellEdit;
    return columnId === 'rate_per_mile' && newValue === 0;
};

export const updateBidConfigTableErrors = (tableEdits) => {
    return async (dispatch) => {
        const newTableErrors = [];
        const tableErrorsToClear = [];
        const addRateError = (rowId) =>
            newTableErrors.push(
                { rowId, columnId: 'flat_rate' },
                { rowId, columnId: 'rate_per_mile' }
            );
        const removeRateError = (rowId) => {
            tableErrorsToClear.push(
                { rowId, columnId: 'flat_rate' },
                { rowId, columnId: 'rate_per_mile' }
            );
        };

        const displayRateErrors = () => {
            dispatch(addBidConfigTableErrors(newTableErrors));
            dispatch(updateBiddingState({ showTableDataErrorAlert: true }));
        };
        const clearRateErrors = () => dispatch(clearBidConfigTableErrors(tableErrorsToClear));
        tableEdits.forEach((tableEdit) => {
            const { lane, cellEdit } = tableEdit;
            const { columnId, newValue } = cellEdit;
            if (columnId === 'rate_per_mile') {
                if (newValue > 0) removeRateError(lane.id);
                else if (ratePerMileError(lane, cellEdit)) {
                    addRateError(lane.id);
                }
            }
        });
        if (newTableErrors.length > 0) displayRateErrors();
        if (tableErrorsToClear.length > 0) {
            clearRateErrors();
        }
    };
};

export const createNewGroup = (groupName, selectedFiles) => {
    const newGroupInfo = {
        name: groupName
    };

    return async (dispatch) => {
        try {
            await API.post(`/bidding/groups/`, newGroupInfo).then((response) => {
                const id = response.data.data.id;

                if (id && selectedFiles.length > 0) {
                    updateBidFilesByGroupId(id, selectedFiles).then(() => {
                        dispatch(getAllGroups());
                        dispatch(getAllBidFiles());
                    });
                } else {
                    dispatch(getAllGroups());
                    dispatch(getAllBidFiles());
                }
            });
        } catch (err) {
            if (err && err.response) {
                console.warn(err);
            }
        }
    };
};

export const createFlatfile = async (currConfigId, fileData) => {
    const payload = {
        bid_lanes: [...fileData.validData]
    };

    try {
        return await API.post(`/bidding/configurations/${currConfigId}/bid-lanes/`, payload);
    } catch (err) {
        if (err && err.response) {
            console.warn(err);
        }
    }
};

/* put */

//TODO endpoint WIP
export const updateBidFilesByGroupId = async (id, files) => {
    const filteredNull = files?.filter((item) => item !== null);
    const groupBidFilesInfo = {
        bid_configs: [...filteredNull]
    };

    try {
        await API.put(`bidding/groups/${id}/configs`, groupBidFilesInfo);
    } catch (err) {
        if (err && err.response) {
            console.warn(`there was an error updating bid files by group id`);
            console.warn(err);
        }
    }
};

export const updateBidFileDetail = async (fileId, fileName, groupId) => {
    let currGroupId = Array.isArray(groupId) ? groupId[0] : groupId;

    const bidFileDetail = {
        file_name: fileName,
        group_id: currGroupId
    };

    try {
        return await API.put(`/bidding/configurations/${fileId}/`, bidFileDetail);
    } catch (err) {
        if (err && err.response) {
            console.warn(`there was an error updating bid files by group id`);
            console.warn(err);
        }
    }
};

export const updateGroupNameByGroupId = async (id, name) => {
    const groupInfo = {
        name
    };

    try {
        return await API.put(`bidding/groups/${id}`, groupInfo);
    } catch (err) {
        if (err && err.response) {
            console.warn(`there was an error updating group name by group id`);
            console.warn(err);
        }
    }
};

export const bulkUpdateBidLanes = (bidLanes) => {
    return async (_dispatch) => {
        try {
            await API.put(`/bidding/bid-lanes-bulk-update/`, { bid_lanes: bidLanes });
        } catch (err) {
            if (err && err.response) {
                console.warn(err, err.response);
            }
        }
    };
};
/* delete */

export const deleteGroup = (groupId) => {
    return async (dispatch) => {
        try {
            await API.delete(`/bidding/groups/${groupId}/`);
            dispatch(getAllGroups());
            dispatch(getAllBidFiles());
        } catch (err) {
            if (err && err.response) {
                console.warn(err);
            }
        }
    };
};

export const deleteConfigFile = async (configId) => {
    try {
        return await API.delete(`/bidding/configurations/${configId}/`);
    } catch (err) {
        if (err && err.response) {
            console.warn(err);
        }
    }
};

export const deleteMultipleConfigFiles = async (configIds) => {
    const patchRequest = {
        request: 'delete',
        resources: configIds
    };

    try {
        return await API.patch(`/bidding/configurations/`, patchRequest);
    } catch (err) {
        if (err && err.response) {
            console.warn(err);
        }
    }
};

export const bulkDeleteBidLanes = async (configId, ids) => {
    try {
        return await API.delete(`/bidding/configurations/${configId}/bid-lanes/`, {
            data: { ids: [...ids] }
        });
    } catch (err) {
        if (err && err.response) {
            console.warn(err);
        }
    }
};

export const downloadBidConfigFiles = async (bidConfigArr) => {
    let checkIfDataIsArr = (Array.isArray(bidConfigArr) ? bidConfigArr : [bidConfigArr]) || [];

    let filtered = checkIfDataIsArr
        ?.map((item) => {
            return `id=${item}`;
        })
        .join('&');

    try {
        const response = await downloadAPI.get(`/bidding/configurations/download/?${filtered}`);

        return response;
    } catch (err) {
        if (err && err.response) {
            console.warn(err);
        }
    }
};

//Get locations by configId
export const getLocationByBidConfigId = async (bidConfigId) => {
    try {
        let url = `/bidding/configurations/${bidConfigId}/bid-locations/`;

        const {
            data: { results }
        } = await API.get(url);
        return results;
    } catch (err) {
        console.warn(
            'error fetching applied bid files for bid analysis report with id ' + bidConfigId
        );
    }
};

// Get all completed base and network reports
export const getBaseAndNetworkReports = () => {
    return async (dispatch) => {
        try {
            const { data } = await API.get(`/reports/report-runs/?file_type=base&sort=created_at`);
            const results = data?.results
                .filter(
                    (report) => !['preparing', 'failed', 'draft'].includes(report?.report_status)
                )
                .filter((report) => ['base', 'network'].includes(report?.file_type));
            dispatch({
                type: BID_CONFIG_BASE_NETWORK_REPORTS_SUCCESS,
                baseAndNetworkReports: [...results]
            });
        } catch (err) {}
    };
};
