import React from 'react';

interface defaultColumnFilterProps {
    column: {
        filterValue: string;
        setFilter: any;
    };
}

function DefaultColumnFilter({ column: { filterValue, setFilter } }: defaultColumnFilterProps) {
    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`Searching records...`}
        />
    );
}

export default DefaultColumnFilter;
