import React, { useState } from 'react';
import { IconButton as DefaultIconButton, Box, Tooltip } from '@mui/material';
import palette from 'theme/palette';
import { BUTTON_TYPE } from 'constants/colors';
interface IconButtonProps {
    icon: JSX.Element;
    hoverIcon?: JSX.Element;
    id?: string;
    buttonType?: BUTTON_TYPE | null;
    disableRipple?: boolean;
    locked?: boolean;
    onClick?: React.MouseEventHandler<HTMLLabelElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLLabelElement>;
    props?: any;
    size?: string;
    sx?: any;
    disableHover?: boolean;
    enableTooltip?: boolean;
    title?: string;
    disabled?: boolean | undefined;
}

const IconButton: React.FunctionComponent<IconButtonProps> = ({
    icon,
    hoverIcon,
    id = '',
    buttonType,
    disableHover,
    disableRipple = true,
    locked,
    size = '32px',
    title,
    enableTooltip = false,
    sx,
    disabled = false,
    onClick,
    onMouseEnter,
    ...props
}) => {
    const [hovering, setHovering] = useState(false);
    let primaryColor: string;
    let hoverColor: string;

    switch (buttonType) {
        case BUTTON_TYPE.DARK:
            primaryColor = palette.ODBlue.odBlue9;
            hoverColor = palette.ODBlue.odBlue8;
            break;
        case BUTTON_TYPE.BLUE:
            primaryColor = palette.semantic.focusedBlue;
            hoverColor = palette.semantic.focusedBlueHover;
            break;
        case BUTTON_TYPE.RED:
            primaryColor = palette.semantic.semanticRed;
            hoverColor = palette.semantic.semanticRedHover;
            break;
        case BUTTON_TYPE.TRANSPARENT:
            primaryColor = palette.transparent;
            hoverColor = palette.neutral.neutral2;
            break;
        case BUTTON_TYPE.GREY:
            primaryColor = palette.neutral.neutral2;
            hoverColor = palette.neutral.neutral3;
            break;
        default:
            primaryColor = palette.neutral.neutral1;
            hoverColor = palette.neutral.neutral2;
            break;
    }

    let tooltipMessage = title ? title : id;

    return (
        <Tooltip
            disableHoverListener={!enableTooltip}
            title={<Box sx={{ fontSize: '14px' }}>{tooltipMessage}</Box>}>
            <Box component="span">
                <DefaultIconButton
                    onMouseEnter={onMouseEnter}
                    onMouseOver={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    data-testid={id}
                    onClick={onClick}
                    {...props}
                    component="label"
                    id={id}
                    aria-label={`${id}-icon-button`}
                    disableRipple={disableRipple}
                    disableFocusRipple={true}
                    disabled={disabled || locked}
                    sx={{
                        backgroundColor: primaryColor,
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: !disableHover ? hoverColor : 'none'
                        },
                        '&.Mui-disabled': {
                            backgroundColor: locked ? 'none' : palette.neutral.neutral1
                        },
                        ...sx
                    }}>
                    {hovering && Boolean(hoverIcon) ? hoverIcon : icon}
                </DefaultIconButton>
            </Box>
        </Tooltip>
    );
};

export default IconButton;
