import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/redux';
import { Box, Divider, styled, Typography } from '@mui/material';
import { ODButton, ODIconButton } from 'shared';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import { BUTTON_TYPE } from 'constants/colors';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import UploadedBidDataAccordion from './UploadedBidDataAccordion';
import RateAdjustmentStudyAccordion from './RateAdjustmentStudyAccordion';
import _ from 'lodash';
import { formatBidAnalysisRuns } from 'utils/bidAnalysis.util';
import RunBidAnalysesConfirmationModal from './RunBidAnalysesConfirmationModal';

const DrawerHeader = styled(Box)(() => ({
    height: '56px',
    padding: '0 16px',
    flex: '0 0 auto',
    borderBottom: `1px solid ${theme.palette.neutral.neutral1}`,
    display: 'flex',
    alignItems: 'center'
}));
const StyledHeaderActions = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));
const RunBidAnalysesSlideout = ({ onClose, configId, minRate }: any) => {
    const [bidAccordionNoErrors, setBidAccordionNoErrors] = useState<boolean>(false);
    const { baseAndNetworkReports } = useSelector((state: RootState) => state.BiddingReducer);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [rateAdjAccordions, setRateAdjAccordions] = useState<
        { cardNumber: number; cardID: string; noErrors: boolean }[]
    >([]);
    const [bidAnalysesRuns, setBidAnalysesRuns] = useState<{}>({});
    const handleClose = () => {
        onClose();
    };
    const [manualOpen, setManualOpen] = useState<{ value: boolean }>({ value: false });

    const handleAddRateAdjustmentCard = () => {
        setManualOpen((m) => ({ ...m, value: false }));
        const accordionKey = `rate-adjustment-${_.random(0, 1000)}`;
        setRateAdjAccordions((prevRateAdjAccordions) => [
            ...prevRateAdjAccordions,
            { cardNumber: rateAdjAccordions.length + 2, cardID: accordionKey, noErrors: false }
        ]);
    };

    const openRunBidAnalysesModal = () => {
        setOpenConfirmModal(true);
    };

    const disableButtons = useMemo(
        () => !(bidAccordionNoErrors && rateAdjAccordions.every((a: any) => a.noErrors)),
        [bidAccordionNoErrors, rateAdjAccordions]
    );

    return (
        <>
            <DrawerHeader display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <RequestPageIcon
                        style={{
                            marginRight: '16px'
                        }}
                    />
                    <b>Run Bid Analysis</b>
                </Box>

                <StyledHeaderActions>
                    <ODIconButton
                        id={`close-button`}
                        onClick={handleClose}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        icon={<CloseIcon sx={{ color: theme.palette.neutral.black }} />}
                    />
                </StyledHeaderActions>
            </DrawerHeader>

            <Box sx={{ overflow: 'auto', height: 'calc(100% - 124px)', padding: '0px !important' }}>
                <Box paddingX="16px" component={Typography}>
                    <Box sx={{ margin: '16px 0' }}>
                        <UploadedBidDataAccordion
                            numOfRateAdjustmentStudyAccordions={rateAdjAccordions.length}
                            baseAndNetworkReports={baseAndNetworkReports}
                            setBidAccordionNoErrors={setBidAccordionNoErrors}
                            setBidAnalysesRuns={setBidAnalysesRuns}
                            bidAnalysesRuns={bidAnalysesRuns}
                            manualOpenOverride={manualOpen}
                        />
                    </Box>
                </Box>
                {rateAdjAccordions.map((item) => {
                    return (
                        <RateAdjustmentStudyAccordion
                            cardNumber={item.cardNumber}
                            cardID={item.cardID}
                            key={item.cardID}
                            rateAdjAccordions={rateAdjAccordions}
                            setRateAdjAccordions={setRateAdjAccordions}
                            setBidAnalysesRuns={setBidAnalysesRuns}
                            bidAnalysesRuns={bidAnalysesRuns}
                            minRate={minRate}
                            manualOpenOverride={manualOpen}
                        />
                    );
                })}
                <Box sx={{ margin: '8px 16px' }}>
                    <ODButton
                        disabled={disableButtons}
                        tooltipText={''}
                        buttonText={'Add Rate Adjustment Study'}
                        buttonType={BUTTON_TYPE.LIGHT_BLUE}
                        onClick={handleAddRateAdjustmentCard}
                        endIcon={<AddIcon />}
                        sx={{ width: '100% ', justifyContent: 'center' }}
                    />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        zIndex: 1100,
                        borderLeft: `1px solid ${theme.palette.neutral.neutral1}`
                    }}>
                    <Divider
                        sx={{
                            width: '100%',
                            borderColor: theme.palette.neutral.neutral1
                        }}
                    />
                    <Box sx={{ margin: '8px 16px' }}>
                        <ODButton
                            disabled={disableButtons}
                            tooltipText={''}
                            buttonText={'Run Bid Analysis'}
                            buttonType={BUTTON_TYPE.BLUE}
                            onClick={openRunBidAnalysesModal}
                            endIcon={<ArrowForwardIcon />}
                            sx={{ width: '418.56px ', justifyContent: 'center' }}
                        />
                    </Box>
                </Box>
                {openConfirmModal && (
                    <RunBidAnalysesConfirmationModal
                        bidAnalysesRuns={formatBidAnalysisRuns(bidAnalysesRuns, configId)}
                        openConfirmModal={openConfirmModal}
                        setOpenConfirmModal={setOpenConfirmModal}
                        setBidAnalysesRuns={setBidAnalysesRuns}
                        closeBidAnalysisSlideout={onClose}
                    />
                )}
            </Box>
        </>
    );
};

export default RunBidAnalysesSlideout;
