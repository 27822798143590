import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Box
} from '@mui/material';
import { withStyles, makeStyles, styled } from '@mui/styles';
import theme from 'theme';
import { Checkbox } from 'shared';
import React from 'react';
import { BUSINESS_TYPE } from 'constants/bidding';

const StyledTableCell = withStyles(() => ({
    root: {},
    head: {
        padding: '0px',
        backgroundColor: theme.palette.neutral.search
    },
    body: {
        fontSize: 14,
        padding: '0px'
    }
}))(TableCell);

const StyledTableRow = styled(TableRow)(() => ({
    height: 40,
    width: 40,
    borderBottom: `1px solid ${theme.palette.neutral.neutral1}`
}));

const useStyles = makeStyles(() => ({
    ellipsis: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    rowStyle: {
        borderTop: 'none'
    },
    disabledRow: {
        borderTop: 'none',
        borderBottom: `1px solid ${theme.palette.ODBlue.odBlue9} !important`
    },
    selectedRow: {
        backgroundColor: `${theme.palette.ODLightBlueNeutral.lightBlue1} !important`,
        borderTop: 'none'
    }
}));
interface BidFileListTableProps {
    groupId: any;
    rows: any[];
    onExpand?: any;
    onChecked: any;
    style?: object;
    toggleAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    canSelectAll: boolean;
    displayGroupLabels?: boolean;
}
const BidFileListTable = ({
    groupId,
    rows = [],
    onChecked,
    style = {},
    toggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {},
    canSelectAll = false
}: BidFileListTableProps) => {
    const classes = useStyles();
    const getRowClass = ({ isChecked = false, disabled = false }) => {
        if (disabled) return classes.disabledRow;
        if (isChecked) return classes.selectedRow;
        return classes.rowStyle;
    };

    const allItemsInGroupAreChecked = () => {
        if (groupId === 'all') return rows.every((r) => r.isChecked);
        else
            return rows.every(
                (r) => (r.group?.id === groupId && r.isChecked) || r.group?.id !== groupId
            );
    };

    const someItemsInGroupAreChecked = () => {
        if (groupId === 'all') return rows.some((r) => r.isChecked);
        else return rows.some((r) => r.group?.id === groupId && r.isChecked);
    };

    return (
        <TableContainer
            component={Paper}
            key={`bucket-reports-${groupId.toString()}`}
            style={{
                borderRadius: 0,
                marginBottom: 16,
                marginTop: 16,

                background: 'transparent',
                ...style
            }}>
            <Table
                stickyHeader
                aria-label="bid selection"
                id={`bid-file-group-list-${groupId.toString()}`}>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>
                            {canSelectAll && (
                                <Checkbox
                                    isChecked={allItemsInGroupAreChecked()}
                                    indeterminate={
                                        someItemsInGroupAreChecked() && !allItemsInGroupAreChecked()
                                    }
                                    onChecked={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        toggleAll(e);
                                    }}
                                />
                            )}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 600, paddingRight: '19px' }}>
                            File Name
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 600 }}>Shipper</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 600 }}>Business Purpose</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => (
                        <StyledTableRow key={`bid-file-list-${row.file_name}-${idx}`}>
                            <StyledTableCell width={45} className={getRowClass(row)}>
                                <Checkbox
                                    isChecked={row.isChecked}
                                    onChecked={(e: Event) => {
                                        onChecked(row, e);
                                    }}
                                />
                            </StyledTableCell>
                            <StyledTableCell className={getRowClass(row)}>
                                <Tooltip title={row.file_name}>
                                    <div className={classes.ellipsis} style={{ width: '100px' }}>
                                        {row.file_name}
                                    </div>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className={getRowClass(row)}>
                                <Tooltip title={row.shipper_id}>
                                    <div className={classes.ellipsis} style={{ width: '80px' }}>
                                        {row.shipper_id}
                                    </div>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className={getRowClass(row)}>
                                <Box className={classes.ellipsis} sx={{ width: '110px' }}>
                                    {row.business_type === 'existing'
                                        ? BUSINESS_TYPE.EXISTING
                                        : BUSINESS_TYPE.NEW}
                                </Box>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default BidFileListTable;
