import {
    INITIAL_REPORT_RUN_SUCCESS,
    INITIAL_REPORT_RUN_FAILURE,
    GET_MAP_KEY,
    INITIAL_REPORT_LOAD_SUCCESS,
    INITIAL_REPORT_LOAD_FAILURE,
    REPORT_LOCATIONS_SUCCESS,
    REPORT_LOCATIONS_CLEAR,
    REPORT_CALCULATED_KPI_SUCCESS,
    REPORT_CALCULATED_KPI_FAILURE,
    REPORT_CALCULATED_KPI_CLEAR,
    GET_MAP_BOX_KEY,
    GET_REPORT_FLOW_SUCCESS,
    GET_REPORT_FLOW_FAILURE,
    CLEAR_REPORT_DATA
} from '../constants/actionTypes';

const initialState = {
    initialReportRunData: null,
    initialReportLoadData: {},
    error: null,
    mapKey: null,
    mapBoxKey: null,
    reportKPI: [],
    reportLocations: {},
    reportFlowData: null
};

const InitialReportRunSuccess = (state, action) => {
    return {
        ...state,
        initialReportRunData: action.initialReportRunData
    };
};
const InitialReportRunFailure = (state, action) => {
    return {
        ...state,
        error: action.error
    };
};

const SetMapKey = (state, action) => {
    return {
        ...state,
        mapKey: action.mapKey
    };
};
const SetMapBoxKey = (state, action) => {
    return {
        ...state,
        mapBoxKey: action.mapKey
    };
};

const InitialReportLoadDataSuccess = (state, action) => {
    return {
        ...state,
        initialReportLoadData: action.initialReportLoadData
    };
};
const InitialReportLoadDataFailure = (state, action) => {
    return {
        ...state,
        error: action.error
    };
};

const CalculatedKPISuccess = (state, action) => {
    return {
        ...state,
        reportKPI: [...state.reportKPI, action.reportKPI]
    };
};
const ClearKPIValues = (state, action) => {
    return {
        ...state,
        reportKPI: []
    };
};
const CalculatedKPIFailure = (state, action) => {
    return {
        ...state,
        error: action.error
    };
};

const onFlowDataSuccess = (state, action) => {
    return {
        ...state,
        reportFlowData: action.initialReportRunData
    };
};
const onFlowDataFailure = (state, action) => {
    return {
        ...state,
        error: action.error,
        reportFlowData: null
    };
};

const updateLocations = (state, action) => {
    return {
        ...state,
        reportLocations: action.locations
    };
};

const clearLocations = (state, action) => {
    return {
        ...state,
        reportLocations: []
    };
};

const clearReportData = (state) => ({
    ...initialState,
    mapBoxKey: state.mapBoxKey
});

const ReportKPIReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIAL_REPORT_RUN_SUCCESS:
            return InitialReportRunSuccess(state, action);
        case INITIAL_REPORT_RUN_FAILURE:
            return InitialReportRunFailure(state, action);
        case GET_MAP_KEY:
            return SetMapKey(state, action);
        case INITIAL_REPORT_LOAD_SUCCESS:
            return InitialReportLoadDataSuccess(state, action);
        case INITIAL_REPORT_LOAD_FAILURE:
            return InitialReportLoadDataFailure(state, action);
        case REPORT_CALCULATED_KPI_SUCCESS:
            return CalculatedKPISuccess(state, action);
        case REPORT_CALCULATED_KPI_FAILURE:
            return CalculatedKPIFailure(state, action);
        case REPORT_CALCULATED_KPI_CLEAR:
            return ClearKPIValues(state, action);
        case GET_MAP_BOX_KEY:
            return SetMapBoxKey(state, action);
        case GET_REPORT_FLOW_SUCCESS:
            return onFlowDataSuccess(state, action);
        case REPORT_LOCATIONS_SUCCESS:
            return updateLocations(state, action);
        case REPORT_LOCATIONS_CLEAR:
            return clearLocations(state, action);
        case GET_REPORT_FLOW_FAILURE:
            return onFlowDataFailure(state, action);
        case CLEAR_REPORT_DATA:
            return clearReportData(state);
        default:
            return state;
    }
};

export default ReportKPIReducer;
