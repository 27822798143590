import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ODModal, ODIconButton, CommonSvgIcon } from 'shared';
import { Box } from '@mui/system';
import { clearBidGoals, updateBidAnalysis } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Tooltip, Typography } from '@mui/material';
import theme from 'theme';
import { getLocation } from 'utils/common.util';
import ClearIcon from '@mui/icons-material/Clear';
import { BUTTON_TYPE } from 'constants/colors';

import { useBidAnalysisHook } from 'utils/hooks';
import _ from 'lodash';

import { SubHeader, StyledSelectedRowDetail, StyledFreightBonusBody } from './Modals.styles';
import { DECISION } from 'constants/bidding';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import * as FS from '@fullstory/browser';

interface ConfirmRecommendationModalProps {
    setCheckedRows: any;
}

const ConfirmDecisionModal = ({ setCheckedRows }: ConfirmRecommendationModalProps) => {
    const dispatch = useDispatch();
    const { bidGoalApplied, setMultipleLanesDecisions } = useBidAnalysisHook();
    const { bidAnalysisSetting, confirmRecommendationModalDetails } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const { reportLocations } = useSelector((state: RootState) => state.ReportKPIReducer);
    const { bidConfigLocations } = useSelector((state: RootState) => state.BiddingReducer);

    const [confirmedLanes, setConfirmedLanes] = useState(
        bidGoalApplied
            ? confirmRecommendationModalDetails?.lanes
            : [
                  ...confirmRecommendationModalDetails?.lanes.filter(
                      (lane: DataRow) =>
                          lane?.recommendation !== confirmRecommendationModalDetails?.decision
                  )
              ]
    );

    const acceptDecision = confirmRecommendationModalDetails?.decision === DECISION.ACCEPT;
    const getLaneOriginDestinationString = (lane: DataRow) =>
        `${getLocation(
            lane.origin as string,
            reportLocations,
            bidAnalysisSetting?.locationType,
            bidConfigLocations
        )} -
        ${getLocation(
            lane.destination as string,
            reportLocations,
            bidAnalysisSetting?.locationType,
            bidConfigLocations
        )}`;
    const getLaneOriginDestinationTooltip = (lane: DataRow) =>
        `${getLocation(
            lane.origin as string,
            reportLocations,
            'city-state-zip',
            bidConfigLocations
        )} -
            ${getLocation(
                lane.destination as string,
                reportLocations,
                'city-state-zip',
                bidConfigLocations
            )}`;

    const handleClose = () => {
        FS.event('Bid Analysis_Bulk Actions_Click Close Button in Confirmation Modal', {});
        dispatch(
            updateBidAnalysis({
                confirmRecommendationModalDetails: {
                    show: false
                }
            })
        );
    };
    const DisplaySubHeader = () => {
        let isPural = confirmedLanes?.length > 0 ? 's' : '';

        return (
            <SubHeader>{`${
                confirmedLanes?.length > 0
                    ? confirmedLanes?.length
                    : Object.keys(confirmedLanes).length > 0
                    ? 1
                    : 0
            } Lane${isPural} Selected`}</SubHeader>
        );
    };

    const DisplaySelectedRowDetail = () => {
        return (
            <>
                {confirmedLanes.map((item: any) => {
                    return (
                        <StyledSelectedRowDetail>
                            <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                                <Box sx={{ marginRight: '16px', display: 'flex' }}>
                                    <CommonSvgIcon />
                                </Box>
                                <Tooltip title={getLaneOriginDestinationTooltip(item)}>
                                    <Box>{getLaneOriginDestinationString(item)}</Box>
                                </Tooltip>
                            </Box>
                            <ODIconButton
                                sx={{ marginRight: 0, padding: '0 1px 0 0' }}
                                disableHover={true}
                                id={'delete'}
                                onClick={() => {
                                    FS.event(
                                        'Bid Analysis_Bulk Actions_Remove Lane in Confirmation Modal',
                                        {
                                            removedId: item.bid_id
                                        }
                                    );
                                    handleRemoveItem(item);
                                }}
                                buttonType={BUTTON_TYPE.TRANSPARENT}
                                icon={<ClearIcon sx={{ fill: theme.palette.neutral.black }} />}
                            />
                        </StyledSelectedRowDetail>
                    );
                })}
            </>
        );
    };

    const handleRemoveItem = (item: any) => {
        const filteredLoads = _.filter(confirmedLanes, (user) => {
            return user.bid_id !== item.bid_id;
        });

        setConfirmedLanes([...filteredLoads]);
    };

    const handleSubmit = () => {
        setMultipleLanesDecisions(confirmedLanes, confirmRecommendationModalDetails?.decision);
        if (bidGoalApplied) dispatch(clearBidGoals());
        setCheckedRows([]);
        handleClose();
    };

    const bidDecisionModalMessage = () => {
        if (bidGoalApplied) {
            return `You are about to accept the selected lanes below at max volume. Accepting these lanes may impact the stability of your network. These lanes fall outside the acceptable threshold of your Lane Score. Please review before confirming.`;
        } else {
            return `You are about to ${
                acceptDecision ? 'accept' : 'reject'
            } the selected lanes below at max volume. Please review before confirming.`;
        }
    };

    return (
        <ODModal
            show={confirmRecommendationModalDetails.show}
            heading={`Confirm ${acceptDecision ? 'Accepted' : 'Rejected'} Lanes Change`}
            handleClose={handleClose}
            actionButtonText={'confirm'}
            actionDisabled={confirmedLanes?.length <= 0 || Object.keys(confirmedLanes)?.length <= 0}
            handleAction={() => {
                FS.event(
                    'Bid Analysis_Bulk Actions_Click Confirm Button in Confirmation Modal',
                    {}
                );
                handleSubmit();
            }}
            children={
                <>
                    <Typography sx={{ fontSize: '14px' }}>{bidDecisionModalMessage()}</Typography>

                    <DisplaySubHeader />
                    <StyledFreightBonusBody>
                        <DisplaySelectedRowDetail />
                    </StyledFreightBonusBody>
                </>
            }
        />
    );
};

export default ConfirmDecisionModal;
