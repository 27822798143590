import {
    INITIAL_REPORT_RUN_FAILURE,
    INITIAL_REPORT_RUN_SUCCESS,
    INITIAL_REPORT_LOAD_SUCCESS,
    INITIAL_REPORT_LOAD_FAILURE,
    CALCULATE_REVENUE,
    REPORT_CALCULATED_KPI_SUCCESS,
    REPORT_CALCULATED_KPI_CLEAR,
    REPORT_CALCULATED_KPI_FAILURE,
    REPORT_LOCATIONS_SUCCESS,
    GET_MAP_BOX_KEY,
    GET_REPORT_FLOW_SUCCESS,
    GET_REPORT_FLOW_FAILURE
} from '../constants/actionTypes';
import { isEmpty, isNumber } from 'lodash';
import API from 'utils/axios';
import { updateNetworkView } from './networkActions';

//Get  initial run data
export const onGetInitialRunDataSuccess = (initialReportRunData) => {
    return {
        type: INITIAL_REPORT_RUN_SUCCESS,
        initialReportRunData
    };
};
export const onGetInitialRunDataFailure = (error) => {
    return (dispatch) => {
        dispatch({ type: INITIAL_REPORT_RUN_FAILURE, error });
    };
};
export const getInitialRunData = ({ reportRunId, bucketId, reportKey }) => {
    return async (dispatch) => {
        try {
            let url;
            if (reportKey) url = `/reports/${reportKey}/report-runs/`;
            else if (bucketId) url = `/reports/report-runs/bucket/${bucketId}/`;
            else if (reportRunId) url = `/reports/report-runs/${reportRunId}/`;

            if (!url) dispatch(onGetInitialRunDataSuccess([]));
            else {
                const res = await API.get(url);
                dispatch(onGetInitialRunDataSuccess(!bucketId ? [res.data] : res.data.results));
            }
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onGetInitialRunDataFailure({
                        error: 'Unable to GetInitialRunData'
                    })
                );
            }
            dispatch(onGetInitialRunDataFailure('Unable to GetInitialRunData'));
        }
    };
};

//Get  initial Load data
export const onGetInitialLoadDataSuccess = (initialReportLoadData) => {
    return {
        type: INITIAL_REPORT_LOAD_SUCCESS,
        initialReportLoadData
    };
};
export const onGetInitialLoadDataFailure = (error) => {
    return (dispatch) => {
        dispatch({ type: INITIAL_REPORT_LOAD_FAILURE, error });
    };
};
export const ClearReportKPI = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_CALCULATED_KPI_CLEAR });
    };
};
export const calculateRevenue = () => {
    return (dispatch) => {
        dispatch({ type: CALCULATE_REVENUE });
    };
};
export const getInitialLoadDataByIdsAndFilters = (reportId, run, filter) => {
    const { id } = run;
    const { selectedShippers, selectedLanes, selectedDrivers, compare_run_id, load_type } = filter;
    let filters = [];
    if (!isEmpty(selectedShippers)) filters.push(`shipper_ids=${selectedShippers.join(',')}`);
    if (!isEmpty(selectedLanes)) filters.push(`lanes=${selectedLanes.join(',')}`);
    if (!isEmpty(selectedDrivers)) filters.push(`driver_type=${selectedDrivers.join(',')}`);
    if (!isEmpty(load_type))
        filters.push(`load_type=${load_type === 'delta' ? 'accepted' : load_type}`);
    if (!isEmpty(compare_run_id) || isNumber(compare_run_id))
        filters.push(`compare_run_id=${compare_run_id}`);

    const url = `/reports/${reportId}/report-runs/${id}/map/timeline-loads/?${filters.join('&')}`;

    onGetInitialLoadDataSuccess([]);
    return async (dispatch) => {
        try {
            const res = await API.get(`${url}`);
            const loadData = res.data.results;
            dispatch(onGetInitialLoadDataSuccess(loadData));
        } catch (err) {
            dispatch(onGetInitialLoadDataFailure(err));
        }
    };
};

//Get  Calculated KPI based on report and runId data
export const onGetReportKPISuccess = (reportKPI) => {
    return {
        type: REPORT_CALCULATED_KPI_SUCCESS,
        reportKPI
    };
};
export const onGetReportKPIFailure = (error) => {
    return (dispatch) => {
        dispatch({ type: REPORT_CALCULATED_KPI_FAILURE, error });
    };
};
export const GetReportKPI = (reportId, run, filter = {}) => {
    return async (dispatch) => {
        try {
            const { id, is_base_case } = run;
            const query = [];
            delete filter.loadType;
            for (let key in filter) !isEmpty(filter[key]) && query.push(`${key}=${filter[key]}`);

            let url = `/reports/${reportId}/report-runs/${id}/kpis/?${query.join('&')}`;

            const res = await API.get(url);
            dispatch(
                onGetReportKPISuccess({
                    reportId,
                    runId: id,
                    isBaseCase: is_base_case,
                    KPI: res.data
                })
            );
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onGetReportKPIFailure({
                        error: 'Unable to GetInitialRunData'
                    })
                );
            }
            dispatch(onGetReportKPIFailure('Unable to GetReportKPI'));
        }
    };
};

//Get locations by reportId
export const GetLocationByReport = (reportId = null, reportRun = {}) => {
    return async (dispatch) => {
        try {
            let url = `/reports/${reportId}/report-runs/${reportRun?.id}/locations/`;

            const {
                data: { locations }
            } = await API.get(url);
            dispatch({ type: REPORT_LOCATIONS_SUCCESS, locations });
        } catch (err) {}
    };
};

export const getLocationByReportId = ({ runId = '', filter = {} }) => {
    return async (dispatch) => {
        try {
            dispatch(updateNetworkView({ loadingGetLocationsByReportId: true }));
            const query = [];
            for (let key in filter) query.push(`${key}=${filter[key]}`);
            let url = `/reports/report-runs/${runId}/locations/${
                query ? `?${query.join('&')}` : ''
            }`;

            const {
                data: { locations }
            } = await API.get(url);
            dispatch({ type: REPORT_LOCATIONS_SUCCESS, locations });
            dispatch(updateNetworkView({ loadingGetLocationsByReportId: false }));
        } catch (err) {
            dispatch(updateNetworkView({ loadingGetLocationsByReportId: false }));
        }
    };
};

//Get mapbox key
export const MapBoxKey = (mapKey) => {
    return {
        type: GET_MAP_BOX_KEY,
        mapKey
    };
};
export const getMapboxKey = () => {
    return async (dispatch) => {
        dispatch(updateNetworkView({ loadingFetchingMapboxKey: true }));
        const res = await API.get('../map/mapbox-key/');
        dispatch(MapBoxKey(res.data.key));
        dispatch(updateNetworkView({ loadingFetchingMapboxKey: false }));
    };
};

//Get Report flow run data
export const onGetFlowDataSuccess = (initialReportRunData) => {
    return {
        type: GET_REPORT_FLOW_SUCCESS,
        initialReportRunData
    };
};
export const onGetFlowDataFailure = (error) => {
    return (dispatch) => {
        dispatch({ type: GET_REPORT_FLOW_FAILURE, error });
    };
};
const formatLocationdata = (locations) => {
    if (!Array.isArray(locations)) {
        locations = Object.keys(locations).map((item) => locations[item]);
    }
    let features = locations.map((location, id) => {
        location.id = location.name;
        return {
            type: 'Feature',
            properties: location
        };
    });

    return {
        type: 'FeatureCollection',
        features
    };
};
export const getFlowData = (reportId, runId, filter = {}) => {
    return async (dispatch) => {
        try {
            let URL_FLOW_DATA = `/reports/${reportId}/report-runs/${runId}/map/flows/`;
            const query = [];
            delete filter.loadType;
            for (let key in filter) {
                (key === 'is_bid' || !isEmpty(filter[key]) || isNumber(filter[key])) &&
                    query.push(`${key}=${filter[key]}`);
            }

            dispatch(updateNetworkView({ loadingFlowData: true }));
            const res = await API.get(`${URL_FLOW_DATA}?${query.join('&')}`);
            const myLocations = formatLocationdata(res.data.locations);
            let myFlows = res.data.flows;
            if (!Array.isArray(myFlows)) {
                myFlows = Object.keys(myFlows).map((item) => myFlows[item]);
            }
            dispatch(
                onGetFlowDataSuccess({
                    myFlows,
                    myLocations,
                    loadType: filter.load_type
                })
            );
            dispatch(updateNetworkView({ loadingFlowData: false }));
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    onGetFlowDataFailure({
                        error: 'Unable to Flow data'
                    })
                );
            }
            dispatch(updateNetworkView({ loadingFlowData: false }));
            dispatch(onGetFlowDataFailure('Unable to get flow data'));
        }
    };
};
