import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import { Radio, FormControlLabel } from '@mui/material';
import { ODDrawer, ODToggleButtonGroup } from 'shared';
import { updateBiddingState } from 'redux/actions';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import { RootState } from 'redux/reducers';
import {
    BID_AGGREGATION_LEVEL,
    LOCATION_VIEW,
    toggleButtonsInfoLocationView
} from 'constants/settings';
import { ODDrawerProps } from 'shared/ODDrawer';
import { CustomRadioGroup } from './BidAnalysisSettingsSlideout/BidAnaysisSettingsSideout.styles';

const CustomizedSubsectionTitle = styled(Box)(() => ({
    fontSize: '12px',
    color: theme.palette.neutral.neutral6,
    marginTop: '16px'
}));

const BidConfigSettingsSlideout = ({ show, onClose }: ODDrawerProps) => {
    const dispatch = useDispatch();
    const { bidConfigSetting } = useSelector((state: RootState) => state.BiddingReducer);
    const { time_aggregation, zip_code_aggregation } = useSelector(
        (state: RootState) => state.BiddingReducer.bidConfigSetting
    );
    const { compareReport } = useSelector((state: RootState) => state.NetworkReducer);

    const handleLocationUpdate = (value: any) => {
        dispatch(
            updateBiddingState({
                bidConfigSetting: {
                    ...bidConfigSetting,
                    time_aggregation: value
                }
            })
        );
    };

    const handleZipAggUpdate = (value: number) => {
        dispatch(
            updateBiddingState({
                bidConfigSetting: {
                    ...bidConfigSetting,
                    zip_code_aggregation: value
                }
            })
        );
    };
    const resetSettings = async () => {
        dispatch(
            updateBiddingState({
                bidConfigSetting: {
                    ...bidConfigSetting,
                    time_aggregation: LOCATION_VIEW.CITY_STATE,
                    zip_code_aggregation: 5
                }
            })
        );
    };
    const [openDrawer, setOpenDrawer] = useState({
        localVisualizationType: true,
        minLoadCount: true,
        groupLocationByProx: false
    });

    return (
        <ODDrawer
            open={show}
            title={'Settings'}
            anchor="right"
            onClose={onClose}
            enableCustomizedButton={true}
            secondaryActionText="Reset"
            secondaryAction={resetSettings}
            children={
                <>
                    <Accordion
                        sx={{
                            '&:before': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        key={`location-visualization-type-accordion`}
                        expanded={openDrawer?.localVisualizationType}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            setOpenDrawer({
                                ...openDrawer,
                                localVisualizationType: !openDrawer?.localVisualizationType
                            });
                        }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                marginTop: '2px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion`}>
                            <Typography
                                style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}>
                                Location Visualization Type
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={openDrawer?.localVisualizationType}
                        timeout="auto"
                        unmountOnExit
                        key={`location-visualization-collapse`}>
                        <Box
                            sx={{
                                margin: '16px 0'
                            }}>
                            <ODToggleButtonGroup
                                toggleButtons={toggleButtonsInfoLocationView}
                                value={time_aggregation}
                                handleAction={handleLocationUpdate}
                            />
                            {time_aggregation === LOCATION_VIEW.ZIP_CODE && (
                                <>
                                    <CustomizedSubsectionTitle>
                                        Zip Code Value
                                    </CustomizedSubsectionTitle>

                                    <CustomRadioGroup
                                        id="bid-config- aggregation-level"
                                        name="bid-config- aggregation-level"
                                        value={zip_code_aggregation}
                                        onChange={({ target: { value } }) => {
                                            handleZipAggUpdate(Number(value));
                                        }}>
                                        {BID_AGGREGATION_LEVEL.map((level) => {
                                            return (
                                                <FormControlLabel
                                                    key={level}
                                                    disabled={compareReport?.id}
                                                    sx={{
                                                        backgroundColor:
                                                            zip_code_aggregation === level
                                                                ? compareReport?.id
                                                                    ? theme.palette.neutral.neutral1
                                                                    : theme.palette
                                                                          .ODLightBlueNeutral
                                                                          .lightBlue1
                                                                : 'transparent'
                                                    }}
                                                    value={level}
                                                    control={<Radio />}
                                                    label={level}
                                                />
                                            );
                                        })}
                                    </CustomRadioGroup>
                                </>
                            )}
                        </Box>
                    </Collapse>
                </>
            }
        />
    );
};

export default BidConfigSettingsSlideout;
