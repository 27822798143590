/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { TableContainer, Table, TableHead, TableBody } from '@mui/material';
import { makeStyles } from '@mui/styles/';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from 'utils/axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReportRunsHeader } from './ReportRunsHeader';
import { ReportRunRow } from './ReportRunRow';
import moment from 'moment';
import _ from 'lodash';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    tableContainer: {
        height: '100%',
        overflow: 'auto'
    }
}));

export const ReportRunsDetails = ({
    hasSelectedRows = false,
    filteredReports,
    onReportChecked,
    editReport,
    getReportRuns,
    tabValue
}) => {
    const classes = useStyles();
    const [isDesc, setIsDesc] = useState(true);
    const { newBaseReportFlow } = useFlags();

    const latestBase = filteredReports.filter(
        (report) => report?.latest_base?.id === report?.id
    )?.[0];

    const noBaseRunReports = filteredReports.filter(
        (report) => report?.id !== latestBase?.id && report?.name !== latestBase?.name
    );

    const transformedReports = noBaseRunReports?.map((item) => {
        const formattedObj = {
            ...item,
            formatted_created_at: moment(item.created_at).format('YYYY/MM/DD HH:mm')
        };

        return formattedObj;
    });
    const deleteReportRun = async (reportRun, reset = true) => {
        try {
            await API.delete(`/reports/report-runs/${reportRun?.id}/`);
            toast.success(`"${reportRun?.name}" is deleted.`, {
                position: 'bottom-right'
            });
            if (reset) getReportRuns(reset);
        } catch (e) {
            toast.error(`Unable to delete "${reportRun?.name}"`, {
                position: 'bottom-right'
            });
        }
    };

    const filteredTableData = _.orderBy(
        transformedReports,
        ['formatted_created_at'],
        [isDesc ? 'desc' : 'asc']
    );

    const filteredOutLatestBase = newBaseReportFlow
        ? filteredReports.filter((report) => report?.latest_base?.id !== report?.id)
        : filteredReports;

    const transformedOLDTableData = filteredOutLatestBase?.map((item) => {
        const formattedObj = {
            ...item,
            formatted_created_at: moment(item.created_at).format('YYYY/MM/DD HH:mm')
        };

        return formattedObj;
    });

    const sortableOldTableData = _.orderBy(
        transformedOLDTableData,
        ['formatted_created_at'],
        [isDesc ? 'desc' : 'asc']
    );
    const selectedRecords = filteredReports.filter(({ isChecked }) => isChecked);
    const baseReportSelected =
        selectedRecords?.length === 1 && selectedRecords[0]?.id === latestBase?.id;
    const isDisabled =
        selectedRecords.length === filteredReports.length ||
        (selectedRecords.length >= 1 && !baseReportSelected);

    return (
        <div
            style={{
                height: `calc(100vh - ${185 + (hasSelectedRows ? 85 : 0)}px)`,
                paddingTop: 10,
                fontSize: 14,
                position: 'relative',
                backgroundColor: theme.palette.neutral.neutral25
            }}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader style={{ padding: '0px 16px 0px 16px' }}>
                    <TableHead>
                        <ReportRunsHeader
                            isDesc={isDesc}
                            setIsDesc={setIsDesc}
                            tabValue={tabValue}
                            reportRun={filteredReports}
                            onRowChecked={onReportChecked}
                        />
                    </TableHead>
                    <TableBody>
                        <>
                            {newBaseReportFlow && latestBase && (
                                <ReportRunRow
                                    latestBaseReportId={latestBase?.id}
                                    tabValue={tabValue}
                                    key={latestBase.name + 'base_report'}
                                    idx={'base_report'}
                                    item={latestBase}
                                    editReport={editReport}
                                    onDelete={deleteReportRun}
                                    getReportRuns={getReportRuns}
                                    onRowChecked={onReportChecked}
                                    disabled={isDisabled}
                                />
                            )}
                            {(newBaseReportFlow ? filteredTableData : sortableOldTableData).map(
                                (item, idx) => {
                                    return (
                                        <ReportRunRow
                                            latestBaseReportId={latestBase?.id} //TODO to be removed
                                            tabValue={tabValue}
                                            key={item.name + idx}
                                            idx={idx}
                                            item={item}
                                            editReport={editReport}
                                            onDelete={deleteReportRun}
                                            getReportRuns={getReportRuns}
                                            onRowChecked={onReportChecked}
                                        />
                                    );
                                }
                            )}
                        </>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
