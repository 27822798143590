import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import palette from 'theme/palette';
import { BUTTON_TYPE } from 'constants/colors';
import { Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer } from '@mui/material';
import theme from 'theme';
import { ODButton, ODIconButton } from 'shared';

const StyledHeaderActions = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiDrawer-paper': {
            width: '456px',
            backgroundColor: palette.white
        }
    }
}));

interface SlideOutProps {
    props?: any;
    open?: boolean;
    onClose?: React.MouseEventHandler<HTMLLabelElement>;
    onSubmit?: React.MouseEventHandler<HTMLLabelElement>;
    title: string;
    children: React.ReactNode;
    button?: React.ReactNode;
    submitButtonText?: string;
    secondaryActionText?: string;
    secondaryAction?: any;
    enableCustomizedButton?: boolean;
    bodySX?: any;
    disableOutsideClickClose?: boolean;
    anchor?: 'left' | 'top' | 'right' | 'bottom';
}

const ODDrawer = ({
    open = false,
    onClose,
    onSubmit,
    title,
    children,
    enableCustomizedButton,
    button,
    secondaryActionText,
    secondaryAction = () => {},
    submitButtonText = 'save',
    anchor = 'right',
    disableOutsideClickClose,
    bodySX = {},
    ...props
}: SlideOutProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Drawer
            id={title}
            anchor={anchor}
            open={open}
            style={{ zIndex: 1300 }}
            className={classes.root}
            onClose={disableOutsideClickClose ? () => null : onClose}
            {...props}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ height: '112px', padding: '0 32px', flex: '0 0 auto' }}>
                <Typography
                    sx={{
                        fontSize: '18px',
                        textTransform: 'capitalize',
                        fontWeight: 400
                    }}>
                    {title}
                </Typography>
                <StyledHeaderActions>
                    {secondaryActionText && (
                        <Button
                            onClick={secondaryAction}
                            sx={{
                                textDecoration: 'underline',
                                textTransform: 'none',
                                color: theme.palette.neutral.neutral6,
                                '&:hover': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'transparent',
                                    color: theme.palette.neutral.neutral8
                                }
                            }}>
                            {secondaryActionText}
                        </Button>
                    )}

                    <Box>
                        <ODIconButton
                            id={`close-${title}`}
                            onClick={onClose}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            icon={
                                <CloseIcon sx={{ color: theme.palette.neutral.black }} />
                            }></ODIconButton>
                    </Box>
                </StyledHeaderActions>
            </Box>
            <Box
                component={Typography}
                sx={{
                    padding: '16px 32px',
                    overflowX: 'auto',
                    maxHeight: '950px',
                    ...bodySX
                }}>
                {children}
            </Box>
            {!enableCustomizedButton && (
                <Box
                    display="flex"
                    justifyContent={'space-between'}
                    alignItems="center"
                    sx={{
                        marginTop: '16px',
                        flexDirection: 'column'
                    }}>
                    <ODButton
                        buttonType={BUTTON_TYPE.BLUE}
                        type="submit"
                        width="392px"
                        buttonText={submitButtonText}
                        onClick={onSubmit}
                    />
                    <ODButton
                        buttonText="Cancel"
                        width="392px"
                        sx={{ marginTop: '8px' }}
                        onClick={onClose}
                    />
                </Box>
            )}
        </Drawer>
    );
};

export default ODDrawer;
