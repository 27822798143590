import React from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { ODButton } from 'shared';
import theme from 'theme';
import { Label } from 'shared';
import { styled } from '@mui/material/styles';
import { BUTTON_TYPE } from 'constants/colors';
import { SxProps } from '@mui/system';

const ButtonsSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
        marginRight: '16px'
    }
}));

export const StyledButtonsStack = styled(Stack)(() => ({
    '& > div:not(:last-child)': { marginRight: '16px' }
}));

// If buttonsOverride is not null, this footer bar will display whatever JSX you pass into buttonsOverride.
// Otherwise, this footer bar displays a primary button and an optional secondary button.
type FooterActionBarProps = {
    buttonsOverride?: JSX.Element;
    handlePrimaryButton?: () => void;
    primaryButtonText?: string;
    primaryButtonDisabled?: boolean;
    primaryButtonDisabledTooltipText?: string;
    primaryButtonEndIcon?: React.ReactNode;
    primaryButtonStyle?: SxProps<Theme>;
    handleSecondaryButton?: () => void;
    secondaryButtonText?: string;
    secondaryButtonDisabled?: boolean;
    secondaryButtonDisabledTooltipText?: string;
    secondaryButtonEndIcon?: React.ReactNode;
    secondaryButtonStyle?: SxProps<Theme>;
    additionalContent?: JSX.Element;
    ribbonIcon: React.ReactNode;
    ribbonText: string;
    ribbonTextTooltip?: string;
    ribbonIcon2?: React.ReactNode;
    ribbonText2?: string;
    ribbonText2Tooltip?: string;
    sx?: SxProps<Theme>;
    odpt4185BidConfigUpdate?: boolean;
};
const ODFooterActionBar: React.FC<FooterActionBarProps> = (props) => {
    return (
        <Box
            sx={{
                height: 'auto',
                borderRadius: props.odpt4185BidConfigUpdate ? 0 : '4px',
                color: theme.palette.neutral.white,
                backgroundColor: theme.palette.semantic.focusedBlue,
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: '16px',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'space-between',
                padding: '8px 16px',
                width: '100%',
                boxSizing: 'border-box',
                ...props.sx
            }}>
            <Box sx={{ display: 'flex' }}>
                <Box>
                    <Label
                        fontWeight="600"
                        startIcon={props.ribbonIcon}
                        text={props.ribbonText}
                        tooltipText={props.ribbonTextTooltip}
                    />
                </Box>
                {props.ribbonText2 && (
                    <Box sx={{ marginLeft: '14px' }}>
                        <Label
                            fontWeight="600"
                            startIcon={props.ribbonIcon2}
                            text={props.ribbonText2}
                            tooltipText={props.ribbonText2Tooltip}
                        />
                    </Box>
                )}
            </Box>
            <ButtonsSection>
                {props.buttonsOverride ? (
                    props.buttonsOverride
                ) : (
                    <>
                        {Boolean(props.additionalContent) && props.additionalContent}
                        {Boolean(props.secondaryButtonText) && (
                            <ODButton
                                disabled={props.secondaryButtonDisabled}
                                tooltipText={
                                    props.secondaryButtonDisabled
                                        ? props.secondaryButtonDisabledTooltipText
                                        : ''
                                }
                                buttonText={props.secondaryButtonText}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={props.handleSecondaryButton}
                                endIcon={props.secondaryButtonEndIcon}
                                sx={{ ...props.secondaryButtonStyle }}
                            />
                        )}
                        {Boolean(props.primaryButtonText) && (
                            <ODButton
                                disabled={props.primaryButtonDisabled}
                                tooltipText={
                                    props.primaryButtonDisabled
                                        ? props.primaryButtonDisabledTooltipText
                                        : ''
                                }
                                buttonText={props.primaryButtonText}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={props.handlePrimaryButton}
                                endIcon={props.primaryButtonEndIcon}
                                sx={{ ...props.primaryButtonStyle }}
                            />
                        )}
                    </>
                )}
            </ButtonsSection>
        </Box>
    );
};

export default ODFooterActionBar;
