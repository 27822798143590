import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Tooltip, IconButton } from '@mui/material';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SwapHorizSharpIcon from '@mui/icons-material/SwapHorizSharp';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import {
    settingsUpdate,
    updateBidAnalysis,
    updateBiddingState,
    updateNetworkView
} from 'redux/actions';
import theme from 'theme';
import { useNetworkViewHook } from 'utils/hooks';
import { MultipleFoldersIcon } from 'assets/images';
import { useParamsProps } from 'types/hooks';
import { RootState } from 'redux/reducers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { styled } from '@mui/material/styles';
import * as FS from '@fullstory/browser';
import { updateModalState } from 'redux/actions/modalsAction';
interface NetworkRightNavbarProps {
    networkManagerView?: boolean;
    bidAnalysisView?: boolean;
    bidConfigView?: boolean;
}

const CollapsedMenuIconButton = styled(IconButton)(() => ({
    borderRadius: '4px',
    minWidth: '0px',
    height: '40px',
    width: '40px',
    marginBottom: '2px',
    '&:hover': {
        backgroundColor: theme.palette.neutral.neutral2
    },
    '&:disabled': {
        backgroundColor: theme.palette.neutral.neutral1,
        svg: {
            color: theme.palette.neutral.neutral6
        }
    }
}));

const StackWrapper = styled(Box)(() => ({
    justifyContent: 'center',
    height: '100vh',
    width: '56px',
    backgroundColor: theme.palette.white,
    borderLeft: `1px solid ${theme.palette.neutral.neutral1}`
}));

const RightNavbarContainer = styled(Box)(() => ({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 120
}));

const RightNavbar = ({
    networkManagerView = false,
    bidAnalysisView = false,
    bidConfigView = false
}: NetworkRightNavbarProps) => {
    const { slideout } = useSelector((state: RootState) => state.NetworkReducer);
    const dispatch = useDispatch();
    const {
        newDomicileSlideout,
        bidAnalysisSettings,
        odpt4185BidConfigUpdate,
        forInternalUsers,
        compareShortcut
    } = useFlags();

    const {
        currReport,
        handleParametersDialog,
        openAssociatedReportSlideout,
        handleHistorySlideout,
        networkImpactView
    } = useNetworkViewHook();

    const { compare_run } = useParams() as useParamsProps;
    const location = useLocation();
    const isUneditableReport = currReport?.read_only || compare_run;

    const updateStateByMenuItem = (func: any, stateChange: any) => {
        dispatch(func(stateChange));
    };

    const customizeIcon = (Comp: any) => <Comp sx={{ color: theme.palette.black }} />;

    const renderMenuItems = (arr: any[]) => {
        return arr?.map((item, i) => {
            return item?.hidden ? null : (
                <Tooltip key={i} title={item.name} placement="right">
                    <Box sx={{ margin: '0 0 2px 0' }}>
                        <CollapsedMenuIconButton
                            disabled={!item.enabled}
                            onClick={item.onClick && item.onClick}
                            aria-label={item.name}
                            size="large">
                            {item.icon}
                        </CollapsedMenuIconButton>
                    </Box>
                </Tooltip>
            );
        });
    };

    const rightNavbarMenuItems = [
        {
            name: 'Associated Reports',
            onClick: () => {
                FS.event('rightnavbar_associated_reports', {});
                openAssociatedReportSlideout();
            },
            hidden: !forInternalUsers || bidConfigView,
            icon: <MultipleFoldersIcon fill={theme.palette.neutral.black} />,
            enabled: !networkImpactView
        },
        {
            name: 'Add Driver',
            onClick: () => {
                FS.event('rightnavbar_click_add_driver', {});
                updateStateByMenuItem(updateNetworkView, {
                    slideout: { ...slideout, addNewDriver: true }
                });
            },
            hidden: bidAnalysisView || !newDomicileSlideout || bidConfigView,
            icon: customizeIcon(PersonAddAlt1Icon),
            enabled: !networkImpactView && !isUneditableReport
        },
        {
            name: 'Change History',
            onClick: () => {
                FS.event('rightnavbar_click_change_history', {});
                handleHistorySlideout();
            },
            hidden: bidConfigView,
            icon: customizeIcon(HistorySharpIcon),
            enabled: true
        },
        {
            name: 'View Network Impact',
            onClick: () => {
                FS.event('rightnavbar_click_view_network_impact', {});
                dispatch(updateModalState({ showNetworkImpact: true }));
            },
            hidden:
                odpt4185BidConfigUpdate ||
                !compareShortcut ||
                !location.pathname.includes('bid-analysis'),
            icon: customizeIcon(SwapHorizSharpIcon),
            enabled: true
        },
        {
            name: 'Edit Parameters',
            onClick: () => {
                FS.event('rightnavbar_click_edit_parameters', {});
                handleParametersDialog(bidAnalysisView);
            },
            hidden: !forInternalUsers || bidConfigView,
            icon: customizeIcon(TuneSharpIcon),
            enabled: bidAnalysisView || (!networkImpactView && !isUneditableReport)
        },
        {
            name: 'Duplicate Bid File',
            onClick: () => {
                FS.event('rightnavbar_click_duplicate_bid_file', {});
                dispatch(
                    updateBiddingState({
                        openDupBidFile: true
                    })
                );
            },
            hidden: !bidConfigView,
            icon: customizeIcon(FileCopySharpIcon),
            enabled: true
        },
        {
            name: 'Download',
            onClick: () => {
                FS.event('rightnavbar_click_download', {});
                updateStateByMenuItem(updateNetworkView, { showDownloadModal: true });
            },
            hidden: bidAnalysisView || bidConfigView,
            icon: customizeIcon(DownloadSharpIcon),
            enabled: !networkImpactView
        },
        {
            name: 'Settings',
            onClick: () => {
                FS.event('rightnavbar_click_settings', {});
                if (networkManagerView) {
                    updateStateByMenuItem(settingsUpdate, { openSettings: true });
                } else if (bidAnalysisView) {
                    updateStateByMenuItem(updateBidAnalysis, {
                        openBidAnalysisSettingSlideout: true
                    });
                } else if (bidConfigView) {
                    updateStateByMenuItem(updateBiddingState, {
                        openBidConfigSettingSlideout: true
                    });
                }
            },

            hidden: bidAnalysisView && !bidAnalysisSettings,
            icon: customizeIcon(SettingsIcon),
            enabled: true
        }
    ];

    return (
        <RightNavbarContainer>
            <StackWrapper>
                <Stack
                    sx={{
                        padding: '8px 0',
                        width: '56px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        display: 'flex',
                        '>:not(style)+:not(style)': {
                            marginTop: 0
                        }
                    }}>
                    {renderMenuItems(rightNavbarMenuItems)}
                </Stack>
            </StackWrapper>
        </RightNavbarContainer>
    );
};

export default RightNavbar;
