export enum FLOW_DIRECTION {
    IN = 'inflow',
    OUT = 'outflow'
}

export enum RATING {
    IDEAL = 'ideal',
    TOLERABLE = 'tolerable',
    UNACCEPTABLE = 'unacceptable'
}

export enum TARGET {
    REVENUE = 'revenue',
    LANES = 'lanes',
    VOLUME = 'volume'
}
interface Value {
    isPositive?: boolean;
    lane: string;
    change: string | number;
    original: string | number;
    bid_lane_id: number;
    time_aggregation?: string;
    changed_by: string | undefined;
}
export interface BidAnalysisAction {
    configuration: number;
    created_at: string;
    id: number;
    item_type: string;
    type: string;
    value: Value;
}

export interface NewBidAnalysisAction
    extends Omit<BidAnalysisAction, 'created_at' | 'id' | 'configuration'> {}
