export const minMaxFilter = (rows, columnId, filterVal) => {
    const min = filterVal[0] ?? -Infinity;
    const max = filterVal[1] ?? Infinity;
    return rows.filter((row) => {
        let rowValue = parseFloat(row.values[columnId]);
        return rowValue >= min && rowValue <= max;
    });
};

export const isEmpty = (value) => [undefined, null, '', '—'].includes(value);

export const minMaxFilterV8 = (row, columnId, value) => {
    let cellValue = row.getValue(columnId);
    let missingCelLValue = [undefined, null].includes(cellValue);
    const [min, max] = value;
    if (isEmpty(min) && isEmpty(max)) {
        return true;
    } else {
        if (missingCelLValue) return false;
        cellValue = parseFloat(cellValue);

        if (!isEmpty(min) && isEmpty(max)) {
            return cellValue >= min;
        } else if (!isEmpty(max) && isEmpty(min)) {
            return cellValue <= max;
        } else {
            return cellValue >= min && cellValue <= max;
        }
    }
};

export const dateTimeFilter = (rows, columnId, filterVal) => {
    return rows.filter((row) => {
        if (!filterVal) return row;
        let min = filterVal[0];
        let max = filterVal[1];
        let rowVal = new Date(row.values[columnId]);
        return min !== null && max === null
            ? rowVal >= min
            : min === null && max !== null
            ? rowVal <= max
            : min !== null && max !== null
            ? rowVal >= min && rowVal <= max
            : rowVal;
    });
};

export const dateTimeFilterV8 = (row, columnId, value) => {
    if (!value) return true;
    let min = value[0];
    let max = value[1];
    let rowVal = new Date(row.getValue([columnId]));
    return min !== null && max === null
        ? rowVal >= min
        : min === null && max !== null
        ? rowVal <= max
        : min !== null && max !== null
        ? rowVal >= min && rowVal <= max
        : true;
};

export const textFilterV8 = (row, columnId, value, matchingStrategy, valuesToLabelsMap) => {
    if (!value) return true;
    if (!row.getValue([columnId])) return false;
    else {
        const lowerCaseSearchText = value.toString()?.toLowerCase();
        const rowVal = row.getValue([columnId])?.toString();
        const lowerCaseRowVal = rowVal.toLowerCase();

        // For columns with labels that differ from values
        if (valuesToLabelsMap) {
            const labels = Object.values(valuesToLabelsMap);
            let labelsMatchingSearchText;
            if (matchingStrategy === 'prefix') {
                labelsMatchingSearchText = labels.filter((label) =>
                    label.toLowerCase().startsWith(lowerCaseSearchText)
                );
            } else if (matchingStrategy === 'inclusion') {
                labelsMatchingSearchText = labels.filter((label) =>
                    label.toLowerCase().includes(lowerCaseSearchText)
                );
            }
            const doesRowMatch = labelsMatchingSearchText.includes(valuesToLabelsMap[rowVal]);
            return doesRowMatch;
        } else {
            if (matchingStrategy === 'prefix')
                return lowerCaseRowVal.startsWith(lowerCaseSearchText);
            else if (matchingStrategy === 'inclusion')
                return lowerCaseRowVal.includes(lowerCaseSearchText);
        }
    }
};
