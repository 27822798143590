import { useState } from 'react';
import { Cell, flexRender, Table } from '@tanstack/react-table';
import { DataRow, CellError } from './ODTableTypes';
import { NonInteractiveCell, useODTableStyles } from './ODTableStyledComponents';
import clsx from 'clsx';
import InteractiveCell from './InteractiveCell';
import { VALIDATION_RULE } from 'utils/common.util';
import React from 'react';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import { TableCell } from '@mui/material';
import { getSortedTableRowIndex } from './ODTableUtils';
import NumericFormat from 'react-number-format';

interface DataCellProps {
    isLastStickyColumn?: boolean;
    isFirstScrollableColumn?: boolean;
    isFirstDataColumn?: boolean;
    cell: Cell<DataRow, any>;
    cellHasError: boolean;
    separatedColumn?: boolean;
    highlightMax?: boolean;
}

const DataCell = styled(TableCell)<DataCellProps>(
    ({
        cellHasError,
        isLastStickyColumn,
        isFirstDataColumn,
        cell,
        separatedColumn,
        highlightMax
    }) => {
        const {
            interactiveTable,
            computeLeftOffset,
            computeRightOffset,
            maxRowSelected,
            minRowSelected,
            selectedColumn
        } = useODTable() as ODTableContextInterface & ODTableProviderProps;

        const isDraggedOver = (cell: Cell<DataRow, any>) => {
            const sortedTableRowIndex = getSortedTableRowIndex(cell);
            return sortedTableRowIndex >= minRowSelected && sortedTableRowIndex <= maxRowSelected;
        };

        const givenColumnId = cell?.column?.id;

        let baseStyling: any = {
            // TODO figure out how to properly type this if we care. CellStyleParams did not work.
            padding: `0px 0px 0px ${
                isFirstDataColumn ? (interactiveTable ? '0px' : '16px') : '0px'
            }`,
            boxSizing: 'border-box',
            position: 'static',
            ...(['left', undefined].includes(cell.column.columnDef.meta?.sticky)
                ? { left: computeLeftOffset(cell.column) }
                : { right: computeRightOffset(cell.column) }),
            flex: '1 0 auto',
            width: cell.column.getSize(),
            border: '1px solid transparent',
            borderBottom: `1px solid ${theme.palette.neutral.neutral2}`
        };
        if (isLastStickyColumn)
            baseStyling.borderRight = `1px solid ${theme.palette.neutral.neutral2}`;
        if (separatedColumn) baseStyling.borderLeft = `1px solid ${theme.palette.neutral.neutral2}`;
        if (highlightMax || cellHasError) {
            baseStyling.border = `1px solid ${theme.palette.semantic.semanticRed} !important`;
            baseStyling.borderBottom = `1px solid ${theme.palette.semantic.semanticRed}`;
            baseStyling.backgroundColor = theme.palette.ODRed.lightRed100;
        } else if (givenColumnId === selectedColumn?.id) {
            if (isDraggedOver(cell)) {
                baseStyling.backgroundColor = theme.palette.ODLightBlueNeutral.lightBlue1;
                baseStyling.borderLeft = `1px solid ${theme.palette.semantic.focusedBlue} !important`;
                baseStyling.borderRight = `1px solid ${theme.palette.semantic.focusedBlue}`;
            }
            if (getSortedTableRowIndex(cell) === minRowSelected) {
                baseStyling.borderTop = `1px solid ${theme.palette.semantic.focusedBlue}`;
            }
            if (getSortedTableRowIndex(cell) === maxRowSelected) {
                baseStyling.borderBottom = `1px solid ${theme.palette.semantic.focusedBlue}`;
            }
        }

        return baseStyling;
    }
);

interface ODTableCellProps {
    cells: Cell<DataRow, unknown>[];
    columnIndex: number;
    deleted: boolean;
    selected: boolean;
    rowHasErrors: boolean;
    cell: Cell<DataRow, any>;
    cellError?: CellError;
}
const ODTableCell = ({
    cells,
    cell,
    columnIndex,
    deleted,
    selected,
    cellError,
    rowHasErrors
}: ODTableCellProps) => {
    const { backgroundColorForSelectedRows, lastLeftStickyColumn, separatedColumns } =
        useODTable() as ODTableContextInterface & ODTableProviderProps;
    const classes = useODTableStyles({ rowHighlightColor: backgroundColorForSelectedRows });

    const { isInteractive } = useODTable() as ODTableContextInterface &
        ODTableProviderProps & {
            table: Table<DataRow>;
        };
    const getCellStyle = (cell: Cell<DataRow, any>) => {
        return cell.column.columnDef.meta?.cellStyle ?? {};
    };
    const getCellValidationRule = (cell: Cell<DataRow, any>) => {
        if (cell.column.columnDef.meta?.validationRule)
            return cell.column.columnDef.meta?.validationRule;
        else return VALIDATION_RULE.ANY;
    };

    const isLastStickyColumn = cell.column.id === lastLeftStickyColumn;
    const isFirstScrollableColumn = cells[columnIndex - 1]?.column.id === lastLeftStickyColumn;
    const isFirstDataColumn = columnIndex === 0;
    const separatedColumn = separatedColumns?.includes(cell.column.id);
    const [highlightMaxCellStyling, setHighlightMaxCellStyling] = useState<boolean>(false);
    const hasPresetFormat = Boolean(
        cell.column.columnDef.meta?.format === 'dollars' ||
            cell.column.columnDef.meta?.format === 'integer'
    );

    return (
        <DataCell
            key={`cell-${cell.id}`}
            id={`cell-${cell.id}`}
            {...{
                cell,
                isLastStickyColumn,
                isFirstScrollableColumn,
                isFirstDataColumn,
                separatedColumn
            }}
            className={clsx(
                classes.tablecell,
                classes.dollars,
                deleted && 'deleted',
                selected && 'selected',
                rowHasErrors && 'error',
                cell.column.columnDef.meta?.sticky && classes.sticky
            )}
            cellHasError={Boolean(cellError)}
            highlightMax={highlightMaxCellStyling}>
            {isInteractive(cell) && !deleted ? (
                <InteractiveCell
                    {...{
                        cell,
                        isLastStickyColumn,
                        isFirstScrollableColumn,
                        isFirstDataColumn,
                        separatedColumn
                    }}
                    cellStyle={getCellStyle(cell)}
                    initialCellValue={cell.getValue()}
                    validationRule={getCellValidationRule(cell)}
                    setHighlightMaxCellStyling={setHighlightMaxCellStyling}
                />
            ) : hasPresetFormat ? (
                <NonInteractiveCell>
                    <NumericFormat
                        displayType="text"
                        value={cell.getValue()}
                        thousandSeparator={true}
                        decimalScale={cell.column.columnDef.meta?.format === 'dollars' ? 2 : 0}
                        fixedDecimalScale={true}
                        prefix={cell.column.columnDef.meta?.format === 'dollars' ? '$' : ''}
                    />
                </NonInteractiveCell>
            ) : (
                <NonInteractiveCell
                    key={cell.id}
                    {...{
                        isLastStickyColumn,
                        isFirstScrollableColumn,
                        isFirstDataColumn
                    }}
                    cellHasError={Boolean(cellError)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </NonInteractiveCell>
            )}
        </DataCell>
    );
};

export default ODTableCell;
