import { UPDATE_MODAL_STATE } from 'redux/constants/actionTypes';

interface ModalsState {
    showNetworkImpact: boolean;
}
interface ModalsAction {
    type: string;
    payload: {
        showNetworkImpact: boolean;
    };
    error?: any;
}
const initialState: ModalsState = {
    showNetworkImpact: false
};

const updateState = (state: ModalsState, action: ModalsAction) => {
    return {
        ...state,
        ...action.payload
    };
};

const ModalsReducer = (state = initialState, action: ModalsAction) => {
    switch (action.type) {
        case UPDATE_MODAL_STATE: {
            return updateState(state, action);
        }
        default:
            return state;
    }
};

export default ModalsReducer;
