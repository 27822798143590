import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import theme from 'theme';
import InfoMessage from 'shared/ODTable/InfoMessage';
import { abbreviateNumber, abbreviateNumberNew } from 'utils/number.util';
import { ArrowForward, Insights } from '@mui/icons-material';
import { NumericInputWithDeleteAndConfirmButtons } from 'shared';
import { styled } from '@mui/material/styles';
import { VALIDATION_RULE } from 'utils/common.util';

const KPICardContainer = styled(Stack)(() => ({
    borderRadius: '4px',
    border: `1px solid ${theme.palette.neutral.neutral1}`,
    flexGrow: 1,
    width: '581px',
    padding: '16px 16px 12px 16px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.white
}));

const TargetsSection = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const TargetLabel = styled(Box)(() => ({
    display: 'flex'
}));

interface KPICardProps {
    denominator?: number;
    denominatorTooltip?: string;
    disableTargetInput: boolean;
    exceededTargetCallback: () => void;
    targetTooLowCallback: () => void;
    format?: 'dollars' | 'number';
    icon: JSX.Element;
    infoMessage?: string;
    kpiPreview: number | null;
    kpiPreviewTooltip?: string;
    label: string;
    setTarget: (target: number | null) => void;
    target?: number | null;
    targetName: string;
    targetTooltip?: string;
    value: number;
    valueTooltip?: string;
    setIsInputEmpty: (isEmpty: boolean) => void;
}
const KPICard = ({
    denominator,
    denominatorTooltip,
    disableTargetInput = false,
    exceededTargetCallback,
    targetTooLowCallback,
    format,
    icon,
    infoMessage,
    kpiPreview,
    kpiPreviewTooltip,
    label,
    setTarget,
    target,
    targetName,
    targetTooltip,
    value,
    valueTooltip,
    setIsInputEmpty
}: KPICardProps) => {
    const canMeetTarget = kpiPreview && target && kpiPreview >= target;

    const formatValue = (value: number | null | undefined) => {
        if (value === null || value === undefined) return null;
        return format === 'dollars' ? `$${abbreviateNumberNew(value)}` : abbreviateNumber(value);
    };
    return (
        <KPICardContainer
            gap={'14px'}
            divider={
                <Divider
                    sx={{ borderColor: theme.palette.neutral.neutral1 }}
                    orientation="horizontal"
                    flexItem
                />
            }>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>
                        <Typography sx={{ fontSize: '14px' }}>{label}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex' }}>{icon} </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 600,
                                fontSize: '20px'
                            }}>
                            <Tooltip title={valueTooltip ?? ''}>
                                <Box>{formatValue(value)}</Box>
                            </Tooltip>

                            {target !== null && (
                                <>
                                    <ArrowForward
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            color: canMeetTarget
                                                ? theme.palette.semantic.focusedBlue
                                                : theme.palette.semantic.semanticRed
                                        }}
                                    />
                                    {canMeetTarget ? null : (
                                        <Tooltip title={kpiPreviewTooltip ?? ''}>
                                            <Box sx={{ color: theme.palette.semantic.semanticRed }}>
                                                {formatValue(kpiPreview)}
                                            </Box>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={targetTooltip ?? ''}>
                                        <Box sx={{ color: theme.palette.semantic.focusedBlue }}>
                                            {`${canMeetTarget ? '' : '\u00A0/ '}${formatValue(
                                                target
                                            )}`}
                                        </Box>
                                    </Tooltip>
                                </>
                            )}
                            {denominator !== null && (
                                <Tooltip title={denominatorTooltip ?? ''}>
                                    <Box sx={{ color: theme.palette.neutral.neutral6 }}>
                                        {`\u00A0/ ${formatValue(denominator)}`}
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box>{infoMessage && <InfoMessage message={infoMessage} />}</Box>
            </Box>

            <TargetsSection>
                <TargetLabel>
                    <Box sx={{ marginRight: '16px' }}>
                        <Insights />
                    </Box>
                    <Typography>{targetName} Target</Typography>
                </TargetLabel>
                <NumericInputWithDeleteAndConfirmButtons
                    targetName={targetName}
                    format={format}
                    minAllowable={value}
                    maxAllowable={denominator}
                    belowMinCallback={targetTooLowCallback}
                    exceedsMaxCallback={exceededTargetCallback}
                    disabled={disableTargetInput}
                    updateValue={setTarget}
                    validationRule={VALIDATION_RULE.NONNEGATIVE_FLOAT}
                    setIsInputEmpty={setIsInputEmpty}
                />
            </TargetsSection>
        </KPICardContainer>
    );
};

export default KPICard;
