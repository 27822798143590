import { useEffect, useState } from 'react';
import { TableBody } from '@mui/material';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import LazyLoadCustom from 'components/NetworkView/ReportSelection/LazyLoadCustom';
import ODTableRow from './ODTableRow';

const ODTableBody = () => {
    const { table } = useODTable() as ODTableContextInterface & ODTableProviderProps;

    const [rowActionIndex, setRowActionIndex] = useState<number>();

    useEffect(() => {
        const handleClick = () => {
            setRowActionIndex(-Infinity);
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <TableBody key="table-body">
            {table.getRowModel().rows.map((row) => (
                <LazyLoadCustom>
                    <ODTableRow
                        row={row}
                        rowActionIndex={rowActionIndex}
                        setRowActionIndex={setRowActionIndex}
                    />
                </LazyLoadCustom>
            ))}
        </TableBody>
    );
};

export default ODTableBody;
