/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'redux/reducers';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    UpdateNotifications,
    softDeleteNotification,
    getOrganization,
    settingsUpdate,
    updateNetworkView
} from 'redux/actions';
import cookies from 'utils/cookies';

import { ODDrawer } from 'shared';
import LeftNavbar from 'components/Layout/LeftNavbar';
import AppSettingsSlideout from 'components/Slideouts/AppSettingsSlideout';
import AlertSlideout from 'components/Slideouts/AlertSlideout';
import { useSettingsInitializationHook } from 'utils/hooks/settingsInitializationHook';

const Layout = ({ children }: any) => {
    const { initializeUserData } = useSettingsInitializationHook();
    const [token, setToken] = useState(null);
    const { newNotificationFlow } = useFlags();
    const dispatch = useDispatch();
    const history = useHistory();
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { openSettings } = useSelector(({ settingsReducer }: RootState) => settingsReducer);
    const { reportError, notificationDetails } = useSelector(
        ({ ReportsReducers }: RootState) => ReportsReducers
    );
    const { showAlertNotification } = useSelector(
        ({ NetworkReducer }: RootState) => NetworkReducer
    );

    const toggleSettings = () => {
        dispatch(settingsUpdate({ openSettings: !openSettings }));
    };

    const handleDeleteNotifications = (data: any) => {
        if (Object.keys(data).length === 0) {
            if (newNotificationFlow) deleteNotifications(true);
            else dispatch(UpdateNotifications({ clear_all: true }));
        } else {
            if (newNotificationFlow) deleteNotifications(false, data.id);
            else dispatch(UpdateNotifications({ clear_all: false, notifications: data }));
        }
    };
    const deleteNotifications = (clearAll: boolean, id?: any) => {
        if (clearAll) {
            dispatch(softDeleteNotification(true));
        } else if (id) {
            dispatch(softDeleteNotification(false, id));
        }
    };

    const getToken = async () => {
        if (isLoading) return;
        if (!isAuthenticated) history.push(`/sign-in`);
        const token = await getAccessTokenSilently();
        cookies.set('token', token);
        setToken(token as any);
    };

    useEffect(() => {
        if (reportError) {
            history.push(`/404`);
        }
    }, [reportError]);

    useEffect(() => {
        if (!token) getToken();
    });

    useEffect(() => {
        if (token) {
            dispatch(getOrganization());
            initializeUserData();
        }
    }, [token]);

    if (!token) return <> </>;

    const handleAlertSlideout = (bool: boolean) => {
        dispatch(updateNetworkView({ showAlertNotification: bool }));
    };

    return (
        <>
            <LeftNavbar children={children} />
            <AppSettingsSlideout show={openSettings} onClose={toggleSettings} />
            <ODDrawer
                open={showAlertNotification}
                title="Notifications"
                anchor="right"
                onClose={() => handleAlertSlideout(false)}
                enableCustomizedButton={true}
                secondaryActionText={'Clear All'}
                secondaryAction={() => handleDeleteNotifications([])}
                children={
                    <AlertSlideout
                        handleDeleteNotifications={handleDeleteNotifications}
                        notificationDetails={notificationDetails}
                        history={history}
                        setShowAlertNotification={handleAlertSlideout}
                    />
                }
            />
        </>
    );
};

export default Layout;
