/* eslint-disable react-hooks/exhaustive-deps */
import { useFlags } from 'launchdarkly-react-client-sdk';
import BidAnalysisPageBody from './BidAnalysisPageBody';
import { Box } from '@mui/material';
import { useParamsProps } from 'types/hooks';
import { RootState } from 'redux/reducers';
import {
    clearBidAnalysis,
    configurationClear,
    editBidAnalysis,
    loadBidAnalysis,
    updateBidAnalysis,
    updateNetworkView,
    updateLoadingState
} from 'redux/actions';
import RightNavbar from 'components/Layout/RightNavbar';
import HistorySlideout from 'components/Slideouts/HistorySlideout';
import Header from './Header';
import ODLoader from 'shared/ODLoader';
import theme from 'theme';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BidAnalysisSettingsSlideout from 'components/Slideouts/BidAnalysisSettingsSlideout';
import BidAnalysisBreadcrumb from './BidAnalysisBreadcrumbs';
import ViewNetworkImpactModal from './ViewNetworkImpactModal';
import { useHistory, useParams } from 'react-router-dom';
import ParametersModal from 'components/Modals/ParametersModal';
import InvalidRatePerMileSnackbar from './InvalidRatePerMileSnackbar';
import { useAuth0 } from '@auth0/auth0-react';
// import AlertsContainer from 'components/NetworkView/Alerts/AlertsContainer';
// import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import OutdatedBaseReportSnackbar from './OutdatedBaseReportSnackbar';
import { useBidAnalysisHook } from 'utils/hooks';

const BidAnalysis = () => {
    const { bidAnalysis } = useFlags();
    const dispatch = useDispatch();
    // const { currReportId } = useNetworkViewHook();
    const { user } = useAuth0();
    const { showHistorySlideout } = useSelector((state: RootState) => state.NetworkReducer);
    const { reportDomiciles } = useSelector((state: RootState) => state.DriversReducer);
    const { configuration, configurationActions, prevConfigurationActions } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );
    // const alertsContext = useAlerts() as any;
    const {
        actionsAwaitingConfigurationCreation,
        openBidAnalysisSettingSlideout,
        selectedLaneScoreMetaData,
        currBidAnalysisData,
        showTableDataErrorAlert,
        showOutdatedReportAlert
    } = useSelector((state: RootState) => state.BidAnalysisReducer);
    const { showLoading, text } = useSelector((state: RootState) => state.LoadingReducer);
    const { isFinalizedBid } = useBidAnalysisHook();

    const closeBidAnalysisSetting = () => {
        dispatch(updateBidAnalysis({ openBidAnalysisSettingSlideout: false }));
    };

    const closeHistorySlideout = () => {
        dispatch(updateNetworkView({ showHistorySlideout: false }));
    };
    const { analysis_id, config_id }: useParamsProps = useParams();
    const history = useHistory();

    useEffect(() => {
        return () => {
            // Cleanup logic executed when the component is unmounted
            dispatch(configurationClear());
            dispatch(clearBidAnalysis());
            dispatch(updateLoadingState({ showLoading: false }));
        };
    }, []);

    useEffect(() => {
        dispatch(
            updateBidAnalysis({ openLaneScoreSlideout: false, selectedLaneScoreMetaData: {} })
        );
    }, [selectedLaneScoreMetaData?.id]);

    useEffect(() => {
        dispatch(loadBidAnalysis({ analysis_id, config_id, isFinalizedBid }));
    }, []);

    useEffect(() => {
        if (configuration?.id && actionsAwaitingConfigurationCreation?.length > 0) {
            dispatch(
                editBidAnalysis({
                    config_id: configuration.id,
                    cellEdits: [],
                    actions: actionsAwaitingConfigurationCreation,
                    navigateToUrl: (url: string) => history.push(url),
                    changedBy: user?.name
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuration.id, actionsAwaitingConfigurationCreation?.length]);

    return (
        bidAnalysis && (
            <Box
                sx={{
                    backgroundColor: theme.palette.white,
                    width: 'calc(100vw - 113px)',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <ODLoader open={showLoading} text={text} />
                <OutdatedBaseReportSnackbar showOutdatedBaseAlert={showOutdatedReportAlert} />
                <BidAnalysisBreadcrumb />
                <Header />
                <BidAnalysisPageBody />
                <RightNavbar bidAnalysisView={true} />
                <BidAnalysisSettingsSlideout
                    show={openBidAnalysisSettingSlideout}
                    onClose={closeBidAnalysisSetting}
                />
                {/* Uncomment if we want to display alerts when drafts fail or when the user should refresh the analysis. */}
                {/* <AlertsContainer alerts={alertsContext?.alerts} /> */}
                <HistorySlideout
                    show={showHistorySlideout}
                    onClose={closeHistorySlideout}
                    configuration={{
                        ...currBidAnalysisData,
                        id: config_id,
                        actions: configurationActions,
                        prevActions: prevConfigurationActions
                    }}
                    domiciles={reportDomiciles}
                    selectedRunType={analysis_id}
                    //Todo this is not used or needed anymore, remove it from props
                    getConfigurationActions={
                        () => {}
                        // dispatch(getConfigurationActions(currReportId, currBidAnalysisData?.id))
                    }
                    bidAnalysisView={true}
                />
                <InvalidRatePerMileSnackbar
                    open={showTableDataErrorAlert}
                    onCloseHandler={() =>
                        dispatch(updateBidAnalysis({ showTableDataErrorAlert: false }))
                    }
                />
                <ViewNetworkImpactModal />
                <ParametersModal />
            </Box>
        )
    );
};

export default BidAnalysis;
