import {
    CONFIGURATIONS_START,
    CONFIGURATIONS_FAIL,
    CONFIGURATIONS_SUCCESS,
    CONFIGURATIONS_CLEAR,
    SET_CONFIGURATION_SUCCESS,
    CONFIGURATION_ACTION_SUCCESS,
    SET_INITIAL_ACTION_SUCCESS,
    UPDATE_CONFIGURATION_STATE
} from '../constants/actionTypes';
import { toast } from 'react-toastify';
import API from 'utils/axios';
import { updateNetworkView } from './networkActions';
export const configurationStart = () => {
    return {
        type: CONFIGURATIONS_START
    };
};

export const configurationClear = () => {
    return {
        type: CONFIGURATIONS_CLEAR
    };
};

export const configurationsSuccess = ({ configurations, error }) => {
    return {
        type: CONFIGURATIONS_SUCCESS,
        configurations,
        error
    };
};

export const configurationFail = ({ error }) => {
    return {
        type: CONFIGURATIONS_FAIL,
        error
    };
};

export const GetConfigurations = (reportId, run) => {
    const { id } = run;

    return async (dispatch) => {
        dispatch(configurationsSuccess({ configurations: [], error: null }));
        try {
            const {
                data: { results }
            } = await API.get(`/reports/${reportId}/report-runs/${id}/configurations/`);
            dispatch(configurationsSuccess({ configurations: results, error: null }));
        } catch (error) {
            dispatch(configurationFail({ error: error?.message }));
        }
    };
};

export const onSetConfigurationSuccess = (configuration) => {
    return {
        type: SET_CONFIGURATION_SUCCESS,
        ...configuration
    };
};

export const updateConfigurationState = (payload) => {
    return {
        type: UPDATE_CONFIGURATION_STATE,
        payload
    };
};
export const setAppliedBids = (bidFilesIds) => {
    return {
        type: SET_CONFIGURATION_SUCCESS,
        appliedBidFilesIds: bidFilesIds
    };
};
export const setAllBids = (bidFiles) => {
    return {
        type: SET_CONFIGURATION_SUCCESS,
        allBidFiles: bidFiles
    };
};

export const getReportAppliedBids = (configurationId) => {
    return async (dispatch) => {
        try {
            dispatch(updateNetworkView({ loadingGetReportAppliedBids: true }));
            const { data } = await API.get(
                `/bidding/report-configurations/${configurationId}/bid-configurations/`
            );
            if (data.success) {
                dispatch(setAppliedBids(data.results.map((result) => result.bid_configuration.id)));
                dispatch(updateNetworkView({ loadingGetReportAppliedBids: false }));
            } else {
                dispatch(setAppliedBids([]));
                dispatch(updateNetworkView({ loadingGetReportAppliedBids: false }));
                console.warn(
                    'error fetching applied bid files for report with id ' + configurationId
                );
            }
        } catch (error) {
            dispatch(updateNetworkView({ loadingGetReportAppliedBids: false }));
        }
    };
};
export const getAllBids = () => {
    return async (dispatch) => {
        try {
            const { data } = await API.get(`/bidding/configurations/`);
            if (data.results) dispatch(setAllBids(data.results));
            else {
                dispatch(setAllBids([]));
                console.warn('error fetching all bid files');
            }
        } catch (error) {
            console.warn('Could not fetch bids ' + error);
        }
    };
};

export const setBidAnalysisConfiguration = (configuration) => {
    return async (dispatch) => {
        try {
            dispatch(onSetConfigurationSuccess({ configuration }));
        } catch (error) {
            console.warn('error setting configuration object ' + configuration.id);
        }
    };
};

export const setConfiguration = (configuration) => {
    return async (dispatch) => {
        try {
            dispatch(onSetConfigurationSuccess({ configuration }));
            if (configuration.id) {
                dispatch(getReportAppliedBids(configuration.id));
            } else dispatch(setAppliedBids([]));
        } catch (error) {
            dispatch(setAppliedBids([]));
            console.warn('error fetching applied bid files for report with id ' + configuration.id);
        }
    };
};

export const removeAppliedBids = (appliedBidFilesIds, configuration) => {
    return async (dispatch) => {
        try {
            if (appliedBidFilesIds.length > 0) {
                const deleteResponse = await API.delete(
                    `/bidding/report-configurations/${configuration.id}/bid-configurations/`,
                    {
                        data: { bid_config_ids: appliedBidFilesIds }
                    }
                );
                if (!deleteResponse.data.success)
                    console.warn(
                        'Could not remove bid files from report ' + deleteResponse.data.errors
                    );
                else dispatch(setAppliedBids([]));
            }
        } catch (error) {
            console.warn('Could not remove bid files from configuration. ' + error);
        }
    };
};

export const updateAppliedBids = (selectedBidFilesIds, simulationId) => {
    return async (dispatch, getState) => {
        const { appliedBidFilesIds } = getState().ConfigurationReducer;
        const bidFilesToRemove = appliedBidFilesIds.filter(
            (id) => !selectedBidFilesIds.includes(id)
        );
        const bidFilesToAdd = selectedBidFilesIds.filter((id) => !appliedBidFilesIds.includes(id));
        try {
            if (bidFilesToRemove.length > 0) {
                const deleteResponse = await API.delete(
                    `/bidding/report-configurations/${simulationId}/bid-configurations/`,
                    {
                        data: { bid_config_ids: bidFilesToRemove }
                    }
                );
                if (!deleteResponse.data.success)
                    console.warn(
                        'Could not remove bid files from report ' + deleteResponse.data.errors
                    );
            }
            if (bidFilesToAdd.length > 0) {
                const { data } = await API.post(
                    `/bidding/report-configurations/${simulationId}/bid-configurations/`,
                    {
                        bid_config_ids: bidFilesToAdd
                    }
                );
                if (!data.success)
                    console.warn('Could not apply bid files to report ' + data.errors);
            }
            dispatch(setAppliedBids(selectedBidFilesIds));
        } catch (error) {
            console.warn('Could not apply bid file to report ' + error);
        }
    };
};

export const onGetConfigurationActionSuccess = (configuration) => {
    return {
        type: CONFIGURATION_ACTION_SUCCESS,
        ...configuration
    };
};
export const setInitialActionSuccess = (configuration) => {
    return {
        type: SET_INITIAL_ACTION_SUCCESS,
        ...configuration
    };
};

export const getAndApplyConfigurationActions = (
    reportId,
    configurationId,
    applyActionsCallback
) => {
    return async (dispatch) => {
        const actions = await dispatch(getConfigurationActions(reportId, configurationId));
        applyActionsCallback(actions);
    };
};

export const getConfigurationActions = (reportId, configurationId, time_aggregation) => {
    return async (dispatch) => {
        try {
            const aggQueryParam =
                !time_aggregation || time_aggregation === 'report'
                    ? ''
                    : time_aggregation === 'weekly'
                    ? '?time_aggregation=weekly'
                    : '?time_aggregation=annual';
            dispatch(updateNetworkView({ loadingActions: true }));
            const {
                data: { actions }
            } = await API.get(
                `/reports/report-runs/${reportId}/configurations/${configurationId}/actions/${aggQueryParam}`
            );
            //!Todo Remove this logic after removing changeHistory ff
            if (Array.isArray(actions)) {
                dispatch(
                    onGetConfigurationActionSuccess({
                        configurationActions: actions
                    })
                );
                dispatch(updateNetworkView({ loadingActions: false }));
                return actions;
            } else {
                dispatch(
                    onGetConfigurationActionSuccess({
                        configurationActions: actions.curr,
                        prevConfigurationActions: actions.prev
                    })
                );
                dispatch(updateNetworkView({ loadingActions: false }));
                return actions.curr;
            }
        } catch (error) {
            dispatch(
                onGetConfigurationActionSuccess({
                    configurationActions: [],
                    prevConfigurationActions: []
                })
            );
            dispatch(updateNetworkView({ loadingActions: false }));
            return [];
        }
    };
};

export const setDraftConfigurationActions = (configurationActions) => {
    return (dispatch) => {
        dispatch(onGetConfigurationActionSuccess({ configurationActions }));
    };
};

export const resetConfiguration = async (configId) => {
    await API.put(`/reports/reset-configuration`, {
        id: configId
    });
};

export const deleteConfiguration = (runId, configId, name) => {
    return async (_dispatch) => {
        try {
            await API.delete(`/reports/report-runs/${runId}/configurations/${configId}/`, {
                name,
                starting_report_run: runId
            });
        } catch (err) {
            if (err && err.response) {
                console.warn('Could not delete configuration ' + err);
            }
        }
    };
};

export const getNewConfiguration = async (configId) => {
    return await API.get(`/reports/configurations/${configId}/`);
};

export const updateConfiguration = (runId, configId, actions) => {
    return async (_dispatch) => {
        try {
            await API.put(`/reports/report-runs/${runId}/configurations/${configId}/actions/`, {
                actions
            });
        } catch (err) {
            if (err && err.response) {
            }
        }
    };
};

export const updateConfigurationName = (runId, configId, name, bucket) => {
    return async (_dispatch) => {
        try {
            await API.put(`/reports/report-runs/${runId}/configurations/${configId}/`, {
                name,
                starting_report_run: runId,
                bucket
            });
        } catch (err) {
            if (err && err.response) {
                console.warn('Could not update configuration name ' + err);
            }
        }
    };
};

export const bulkUpdateLanesRateByShipper = (runId, configId, shipper, rateChangeObj) => {
    let encodedShipper = encodeURIComponent(shipper);

    return async (_dispatch) => {
        try {
            await API.put(
                `/reports/report-runs/${runId}/configurations/${configId}/bulk-update-lane-rate/?shipper=${encodedShipper}`,
                rateChangeObj
            );
        } catch (err) {
            if (err && err.response) {
                console.warn(err, err.response);
            }
        }
    };
};

export const restoreAction = (runId, configId, actions) => {
    return async (dispatch) => {
        try {
            const { data } = await API.put(
                `/reports/report-runs/${runId}/configurations/${configId}/actions/`,
                { actions: actions }
            );
            if (data.success) dispatch(getConfigurationActions(runId, configId));
        } catch (e) {
            toast.error(`You cannot update this configuration.`, {
                position: 'bottom-right'
            });
        }
    };
};

export const runConfiguration = (configId) => {
    return async (_dispatch) => {
        try {
            await API.post(`/reports/run-configuration/`, { id: configId });
        } catch (err) {
            if (err && err.response) {
            }
        }
    };
};

//NEW
export const createSimulation = async (name, reportId, initialAppliedBidFiles = []) => {
    const simulation = {
        name,
        starting_report_run: reportId
    };

    try {
        const { data } = await API.post(
            `/reports/report-runs/${reportId}/configurations/`,
            simulation
        );
        if (initialAppliedBidFiles.length > 0) {
            await API.post(`/bidding/report-configurations/${data.id}/bid-configurations/`, {
                bid_config_ids: initialAppliedBidFiles
            });
        }
        return data;
    } catch (err) {
        if (err && err.response) {
            console.error(err);
        }
    }
};
