/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, Avatar, Tab, Tabs } from '@mui/material';
import theme from 'theme';
import { REPORT_RUNS_STATUS_LIGHT, REPORT_STATUS_LABEL_INFO } from './constants';
import { makeStyles } from '@mui/styles/';
import { CommonHeaderContainer } from 'shared';
import { reduce } from 'lodash';
import { REPORT_STATUS } from 'constants/network';

export const ReportTabsList = ({ value, onChange, reportRuns }) => {
    const useStyles = makeStyles((theme) => ({
        runsCount: {
            width: '30px',
            height: '30px',
            fontSize: 12,
            fontWeight: 600,
            fontStretch: 'normal',
            backgroundColor: theme.palette.white
        }
    }));

    const classes = useStyles();

    const reportsCount = reduce(
        reportRuns,
        (store, runs, status) => {
            let key;
            switch (status) {
                case REPORT_STATUS.VFA_COMPLETED:
                    key = REPORT_STATUS.COMPLETED;
                    break;
                case REPORT_STATUS.REFRESHING:
                case REPORT_STATUS.PREPARING:
                case REPORT_STATUS.RUNNING:
                    key = REPORT_STATUS.RUNNING;
                    break;
                default:
                    key = status;
            }
            store[key] += runs.length;
            return store;
        },
        { draft: 0, failed: 0, running: 0, default: 0, completed: 0 }
    );

    //TODO temporary fix until light theme is released
    const reportTabLists = REPORT_RUNS_STATUS_LIGHT;

    return (
        <CommonHeaderContainer
            dark={false}
            noPadding
            justify={'space-between'}
            isNetworkManager={true}>
            <Tabs
                value={value}
                onChange={onChange}
                textColor="inherit"
                variant="fullWidth"
                TabIndicatorProps={{
                    style: {
                        height: '3px',
                        backgroundColor: theme.palette.semantic.focusedBlue
                    }
                }}
                indicatorColor="primary"
                style={{ height: 56, width: '100%' }}
                aria-label="secondary tabs example">
                {reportTabLists.map(
                    ({
                        status,
                        icon,
                        showCount = true,
                        bgColor = theme.palette.neutral.neutral1
                    }) => (
                        <Tab
                            key={status}
                            value={status}
                            style={{
                                borderRight: `1px solid ${theme.palette.neutral.neutral1}`,
                                textTransform: 'capitalize',
                                padding: '0px 16px',
                                height: 56
                            }}
                            label={
                                <CommonHeaderContainer
                                    dark={false}
                                    noPadding
                                    isNetworkManager={true}
                                    justify={`flex-start`}
                                    headerStyle={{ width: '100%' }}>
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{
                                            height: 56,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start'
                                        }}>
                                        <span
                                            style={{
                                                paddingRight: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            {icon}
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: 'normal',
                                                fontSize: 14
                                            }}>
                                            {REPORT_STATUS_LABEL_INFO[status]}
                                        </span>
                                    </Grid>
                                    {showCount && (
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'end'
                                            }}>
                                            <Avatar
                                                className={classes.runsCount}
                                                style={{
                                                    backgroundColor: bgColor,
                                                    color:
                                                        status === 'failed'
                                                            ? theme.palette.white
                                                            : theme.palette.black
                                                }}>
                                                {reportsCount[status] || 0}
                                            </Avatar>
                                        </Grid>
                                    )}
                                </CommonHeaderContainer>
                            }
                        />
                    )
                )}
            </Tabs>
        </CommonHeaderContainer>
    );
};
