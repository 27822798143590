import { FilterV8Props } from './FilterV8Props';
import { Autocomplete, Box, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import theme from 'theme';
import { ExpandMoreSharp } from '@mui/icons-material/';
import { useState } from 'react';
import clsx from 'clsx';
import { EndAdornmentsContainer, useFilterStyles, ClearButton, FooterLabel } from './FilterStyles';

import {
    useODTable,
    ODTableContextInterface,
    ODTableProviderProps
} from 'shared/ODTable/ODTableContext';
import { Table } from '@tanstack/react-table';
import { DataRow } from 'shared/ODTable/ODTableTypes';

const DropdownFilterV8 = ({
    column,
    sortedUniqueValues
}: FilterV8Props & {
    sortedUniqueValues: string[];
}) => {
    const { table } = useODTable() as ODTableContextInterface &
        ODTableProviderProps & { table: Table<DataRow> };

    const columnFilterValue = column.getFilterValue() as string | null;
    const filterOptions = createFilterOptions({ matchFrom: 'any' });
    const classes = useFilterStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const valuesToLabelsMap = column.columnDef.meta?.dropdownValuesToLabelsMap;

    function reverseObject(obj: Record<string, string>): Record<string, string> {
        return Object.entries(obj).reduce((acc: Record<string, string>, [key, value]) => {
            acc[value] = key;
            return acc;
        }, {});
    }

    let labelsToValuesMap: Record<string, string> = {};
    if (valuesToLabelsMap) labelsToValuesMap = reverseObject(valuesToLabelsMap);

    const updateFilterValue = (value: string | null) => {
        column.setFilterValue(value);
        table.setPageIndex(0);
    };

    const displayOption = (option: any) => {
        if (valuesToLabelsMap?.[option as keyof typeof valuesToLabelsMap]) {
            return valuesToLabelsMap?.[option as keyof typeof valuesToLabelsMap];
        }

        return option;
    };

    return (
        <Box>
            <Autocomplete
                disableClearable
                disablePortal
                freeSolo
                filterOptions={filterOptions}
                forcePopupIcon={true}
                id={`${column.id}-dropdown-filter`}
                options={sortedUniqueValues}
                getOptionLabel={(option) => displayOption(option)}
                inputValue={
                    valuesToLabelsMap?.[columnFilterValue as keyof typeof valuesToLabelsMap] ||
                    columnFilterValue ||
                    ''
                }
                onInputChange={(_event, newInputValue) => {
                    let valToSet = newInputValue;
                    if (labelsToValuesMap?.[newInputValue])
                        valToSet = labelsToValuesMap[newInputValue];
                    updateFilterValue(valToSet);
                }}
                size="small"
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                classes={{
                    popupIndicator: classes.popupIndicator,
                    popper: clsx(classes.popper, classes.lightModePopper),
                    listbox: classes.listbox
                }}
                className={clsx(
                    classes.dropdownborder,
                    isOpen || isHovered
                        ? classes.lightModeAutocompleteOpen
                        : classes.lightModeAutocompleteClosed
                )}
                popupIcon={
                    <ExpandMoreSharp
                        sx={{
                            marginBottom: '-14px', //TODO investigate why we have to do this?
                            color:
                                isOpen ||
                                isHovered ||
                                (columnFilterValue && columnFilterValue?.length > 0)
                                    ? theme.palette.black
                                    : theme.palette.neutral.neutral6
                        }}
                    />
                }
                onChange={(e, value) => {
                    updateFilterValue(value as string);
                }}
                renderInput={(params) => (
                    <TextField
                        className={clsx(classes.textbox, classes.lightModeTextbox)}
                        {...params}
                        sx={{
                            overflowX: 'hidden',
                            '& .MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: 'calc(100% - 40px)',
                                color:
                                    isOpen ||
                                    isHovered ||
                                    (columnFilterValue && columnFilterValue?.length > 0)
                                        ? theme.palette.black
                                        : theme.palette.neutral.neutral6
                            }
                        }}
                        label=""
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <EndAdornmentsContainer>
                                    {params.InputProps.endAdornment}
                                </EndAdornmentsContainer>
                            )
                        }}
                    />
                )}
            />
            <FooterLabel sx={{ display: 'flex' }}>
                <Box>Search</Box>
                {columnFilterValue && (
                    <ClearButton
                        onClick={() => {
                            updateFilterValue(null);
                        }}>
                        Clear
                    </ClearButton>
                )}
            </FooterLabel>
        </Box>
    );
};

export default DropdownFilterV8;
