import { Box, Tooltip } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { capitalize } from 'lodash';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { minMaxFilterV8, textFilterV8 } from 'shared/Table/tableFilters';
import { FILTER_TYPE } from 'shared/Table/tableFilters/FilterConstants';
import { ColumnFormat } from 'types/react-table';
import NoMappingMessage from '../NoMappingMessage';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { UnspecifiedDataPlaceholder } from 'shared/ODTable/ODTableStyledComponents';
import { FlowMovementTypeValue, FlowTextValue } from './FlowsTableColumns.styles';

export const getFilterFn = (
    format: ColumnFormat,
    textFieldMatchingStrategy: any,
    textMatchingFilterFnWrapper: any,
    valuesToLabelsMap?: {}
) => {
    switch (format) {
        case 'percentage':
        case 'dollar':
        case 'float':
        case 'count':
        case 'interactive_count':
            return minMaxFilterV8;
        case 'text':
            if (valuesToLabelsMap)
                return (row: Row<DataRow>, columnId: string, value: any) =>
                    textFilterV8(
                        row,
                        columnId,
                        value,
                        textFieldMatchingStrategy,
                        valuesToLabelsMap
                    );
            else return textMatchingFilterFnWrapper;
        default:
            break;
    }
};
export const getFilterType = (format: ColumnFormat) => {
    switch (format) {
        case 'percentage':
        case 'dollar':
        case 'float':
        case 'count':
        case 'interactive_count':
            return FILTER_TYPE.NUMBER_RANGE;
        case 'text':
            return FILTER_TYPE.DROPDOWN;
        default:
            break;
    }
};

const tooltipByMovementType = (type: any, isInflow: boolean) => {
    switch (type) {
        case 'HISTORICAL':
            return isInflow
                ? 'Fed by Historical Load'
                : 'Outbound Load is a Historical Load After Delivering a Bid Load ';
        case 'DRIVER HOME':
            return isInflow
                ? 'At Home Before Bid Load Pick Up.'
                : 'Headed Home After Bid Load Delivery. ';
        case 'BID':
            return isInflow
                ? 'Fed by Bid Load'
                : 'Outbound Load is A Historical Load after Delivering a Bid Load';
        case 'RELAY YARD':
            return isInflow
                ? 'At Relay Yard Before Bid Load Pick Up.'
                : 'Headed to Relay Yard After Bid Load Delivery';
        case 'ANALYSIS START':
            return 'No previous driver movements';
        case 'ANALYSIS END':
            return 'No Further Driver Movements';
        default:
            return '';
    }
};

export const displayTextValueByValueType = (
    displayValue: any,
    isInflow: boolean,
    size: any,
    triggerNoMappingInfoAlert: any
) => {
    let value = displayValue;

    const movementTypes = [
        'HISTORICAL',
        'DRIVER HOME',
        'BID',
        'RELAY YARD',
        'DRIVER HOME',
        'ANALYSIS END',
        'ANALYSIS START'
    ];

    if (displayValue === 'SIMULATION END') {
        displayValue = 'ANALYSIS END';
    }

    if (displayValue === 'SIMULATION START') {
        displayValue = 'ANALYSIS START';
    }

    if (movementTypes.includes(displayValue as any)) {
        value = (
            <Tooltip title={tooltipByMovementType(displayValue, isInflow)}>
                <Box display="flex">
                    <ErrorOutlineIcon sx={{ marginRight: '8px' }} />
                    <FlowMovementTypeValue size={size}>
                        {capitalize(displayValue)}
                    </FlowMovementTypeValue>
                </Box>
            </Tooltip>
        );
    } else if (displayValue === 'NOMAPPING') {
        value = (
            <NoMappingMessage
                value={displayValue}
                size={size}
                triggerFn={triggerNoMappingInfoAlert}
            />
        );
    } else {
        value =
            displayValue !== null ? (
                <Tooltip title={displayValue as string}>
                    <FlowTextValue size={size}>{displayValue}</FlowTextValue>
                </Tooltip>
            ) : (
                <UnspecifiedDataPlaceholder />
            );
    }

    return value;
};
