import API from 'utils/axios';
import { getAllGroups, getAllBidFiles, updateBiddingState, createFlatfile } from 'redux/actions';

// Generate Response
export const generateResponse = (typeOfHook, value, row = null, info = null, fieldKey = null) => {
    if (typeOfHook === 'field') {
        return [{ value, info }, row];
    } else if (typeOfHook === 'record') {
        let response = {};
        response[fieldKey] = { value, info };
        return response;
    }
};

// Zipcodes
export const getZipcodes = (values) => {
    // regex looks for one or more numbers at the end of a string
    const zipValidator = /[0-9]+$/;
    const transformedLocations = values.map((locationRow) => {
        const matchStatus = locationRow[0].match(zipValidator);
        if (matchStatus) {
            const matchedZip = matchStatus[0];
            return [matchedZip, locationRow[1]];
        } else {
            return locationRow;
        }
    });
    return transformedLocations;
};

// Find Index
export const findValuesIndex = (values, rowIndex) => {
    return values.findIndex((row) => row[1] === rowIndex);
};

// Number Validation. blank refers to if column is allowed to be blank or not
export const numberValidation = async (values, blank = false) => {
    let number_validation = await API.post('/bidding/flatfile/validate/number/', {
        values: values,
        blank: blank
    });
    return number_validation.data['numbers'];
};

export const numberRevalidation = async (fieldKey, value, blank = true) => {
    let responseValidate = {};
    let validation = await API.post('/bidding/flatfile/validate/number/', {
        values: [[value, 1]],
        blank: blank
    });
    if (validation.data['numbers'][0]) {
        responseValidate[fieldKey] = validation.data['numbers'][0][0];
        return responseValidate;
    } else {
        return {};
    }
};

// unique inputs validation
export const uniqueValidation = async (values) => {
    let unique_validation = await API.post('/bidding/flatfile/validate/unique/', {
        values: values
    });
    let dupValues = unique_validation.data['duplicates'];
    const info = [
        {
            message: 'value is duplicated',
            level: 'warning'
        }
    ];
    dupValues.forEach((dup, i) => {
        dupValues[i] = generateResponse('field', dup[0], dup[1], info);
    });
    return dupValues;
};

export const finishFlatfileUpload = async (
    dispatch,
    importer,
    configId,
    results,
    history,
    odpt4185BidConfigUpdate
) => {
    const flatfileCreated = await createFlatfile(configId, results);
    if (flatfileCreated) {
        await importer.displaySuccess('Bid file successfully uploaded!');

        if (odpt4185BidConfigUpdate) {
            const newlyCreatedBidConfigId = flatfileCreated?.data?.result[0]?.bid_config;
            history.push(`/bidding/${newlyCreatedBidConfigId}`);
        }

        dispatch(updateBiddingState({ currUploadingBidConfigId: '' }));
        dispatch(getAllGroups());
        dispatch(getAllBidFiles());
        dispatch(
            updateBiddingState({
                defaultRateType: null
            })
        );
        window.FS.event('Bid File Upload_Complete', {});
    } else {
        window.FS.event('Bid_File_Upload_Failed ', {});
        importer
            .requestCorrectionsFromUser(
                'Error uploading bid file, please try making adjustments and uploading again'
            )
            .then(async (results) => {
                await finishFlatfileUpload(
                    dispatch,
                    importer,
                    configId,
                    results,
                    history,
                    odpt4185BidConfigUpdate
                );
            });
    }
};

export const launchFlatfile = async (
    dispatch,
    importer,
    configId,
    resetFlatFileInfo = undefined,
    history,
    odpt4185BidConfigUpdate
) => {
    if (resetFlatFileInfo) resetFlatFileInfo();
    importer
        .requestDataFromUser()
        .then(async (results) => {
            await finishFlatfileUpload(
                dispatch,
                importer,
                configId,
                results,
                history,
                odpt4185BidConfigUpdate
            );
        })
        .catch(function (error) {
            console.info(error || 'error has occurred');
        });
};
