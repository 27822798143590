import { Stack, styled } from '@mui/material';
import theme from 'theme';
import BidAnalysisTable from './BidAnalysisTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import InflowOutflowButtons from './InflowOutflowButtons';
import InflowOutflowTable from './InflowOutflowTable';
import ODSnackbar, { ODSNACKBAR_TYPE } from 'shared/Snackbar';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { updateBidAnalysis } from 'redux/actions';

const TableSection = styled(Stack)(() => ({
    position: 'relative',
    flex: '1 1 auto',
    height: '100%'
}));

const BidAnalysisTableContainer = styled(Stack)(() => ({
    backgroundColor: theme.palette.neutral.white,
    fontSize: '14px',
    borderRadius: '4px',
    overflowY: 'auto',
    // maxHeight: 'calc(100% - 200px)'
    height: 'calc(100vh - 194px)'
}));

const BidAnalysisTablesContainer = () => {
    const { selectedLaneId, openLaneScoreSlideout } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const dispatch = useDispatch();

    const closeInfoSnackBar = () => {
        dispatch(updateBidAnalysis({ showNomappingAlert: false }));
    };

    const InfoSnackbar = () => {
        return (
            <ODSnackbar
                text={
                    'We were not able to provide a zip code. Please update your custom markets file or contact support for assistance.'
                }
                open={showNomappingAlert}
                backButtons={
                    <ODButton
                        onClick={() => {
                            closeInfoSnackBar();
                            window.openWidget();
                        }}
                        buttonType={BUTTON_TYPE.BLUE}
                        buttonText="GET SUPPORT"
                        sx={{ fontWeight: 600 }}
                    />
                }
                onCloseHandler={closeInfoSnackBar}
                type={ODSNACKBAR_TYPE.HELP}
            />
        );
    };

    const { showNomappingAlert } = useSelector((state: RootState) => state.BidAnalysisReducer);
    return (
        <BidAnalysisTableContainer
            width={`calc(100vw - ${openLaneScoreSlideout ? '590px' : '138px'})`}>
            {selectedLaneId !== null && <InflowOutflowButtons />}
            <TableSection>
                <BidAnalysisTable />
                {selectedLaneId !== null && <InflowOutflowTable />}
            </TableSection>
            <InfoSnackbar />
        </BidAnalysisTableContainer>
    );
};
export default BidAnalysisTablesContainer;
