import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import theme from 'theme';

const ODCircularProgress = ({
    color = theme.palette.white,
    sx,
    size = 16
}: {
    color?: any;
    sx?: any;
    size?: number;
}) => {
    return (
        <CircularProgress
            size={size}
            sx={{
                color: color,
                ...sx
            }}
        />
    );
};

export default ODCircularProgress;
