import React, { useRef, useEffect } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, Tooltip, Box } from '@mui/material';
import { makeStyles } from '@mui/styles/';
import ReportStatusInfo from './ReportStatusInfo';
import LazyLoadCustom from './LazyLoadCustom';
import CheckboxComponent from './CheckboxComponent';
import { StyledTableRow, StyledTableCell, reportListTableCommonStyle } from './CustomTable';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import { REPORT_FILE_TYPE } from 'constants/reports';

const ReportListTable = ({ id = '', rows = [], onChecked, style = {} }) => {
    const useStyles = makeStyles((theme) => ({
        ellipsis: {
            width: 100,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        rowStyle: {
            color: theme.palette.black,
            backgroundColor: `${theme.palette.white} !important`,
            borderTop: 'none',
            borderBottom: `1px solid ${theme.palette.neutral.neutral1} !important`
        },
        disabledRow: {
            color: theme.palette.ODBlue.odBlue7,
            backgroundColor: `${theme.palette.white} !important`,
            borderTop: 'none',
            borderBottom: `1px solid ${theme.palette.neutral.neutral1} !important`
        },
        selectedRow: {
            color: theme.palette.black,
            backgroundColor: `${theme.palette.neutral.neutral1} !important`,
            borderTop: 'none',
            borderBottom: `1px solid ${theme.palette.white} !important`
        }
    }));

    const classes = useStyles();

    const myRef = useRef(null);
    // Reset the lazyload when expanded
    useEffect(() => {
        setTimeout(() => {
            myRef?.current?.scrollTo(1, 1);
        }, 700);
    }, []);

    const getRowClass = ({ report_status = '', isChecked = false, disabled = false }) => {
        if (report_status === 'failed' || disabled) return classes.disabledRow;
        if (isChecked) return classes.selectedRow;
        return classes.rowStyle;
    };

    const displayTypeByReportType = (type) => {
        if (type === REPORT_FILE_TYPE.BASE) {
            return 'Base Network';
        }

        if (type === REPORT_FILE_TYPE.NETWORK) {
            return 'Network Analysis';
        }

        if (type === REPORT_FILE_TYPE.BID) {
            return 'Bid Analysis';
        } else {
            return 'N/A';
        }
    };

    return (
        <TableContainer
            ref={myRef}
            component={Paper}
            key={`bucket-reports-${id}`}
            style={{
                maxHeight: '45vh',
                borderRadius: 0,
                marginBottom: 16,
                marginTop: 16,
                overflow: 'auto',
                background: 'transparent',
                ...style
            }}>
            <Table stickyHeader aria-label="report selection" id={`report-list-${id}`}>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell
                            colSpan={3}
                            sx={{
                                ...reportListTableCommonStyle,
                                padding: '0px 16px'
                            }}>
                            Report Name
                        </StyledTableCell>
                        <StyledTableCell
                            colSpan={1}
                            sx={{
                                ...reportListTableCommonStyle,
                                padding: 0
                            }}>
                            File Type
                        </StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => {
                        return (
                            <StyledTableRow
                                key={`report-list-${row.bucket}-${idx}`}
                                component={rows.length > 20 ? LazyLoadCustom : 'tr'}>
                                <StyledTableCell width={50} className={getRowClass(row)}>
                                    <CheckboxComponent
                                        isChecked={row.isChecked}
                                        isDisabled={
                                            row?.report_status === 'failed' || row?.disabled
                                        }
                                        onChecked={(e) => {
                                            onChecked(row, e);
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell width={28} className={getRowClass(row)}>
                                    <ReportStatusInfo
                                        showLabel={false}
                                        status={row.report_status}
                                    />
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} className={getRowClass(row)}>
                                    <Tooltip title={row.name}>
                                        <div className={classes.ellipsis} style={{ width: 175 }}>
                                            {row.name}
                                        </div>
                                    </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} className={getRowClass(row)}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InsertDriveFileSharpIcon sx={{ marginRight: '4px' }} />
                                        {displayTypeByReportType(row.file_type)}
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ReportListTable;
