/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, Box } from '@mui/material';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import ClearIcon from '@mui/icons-material/Clear';
import SlideOutContainer from 'components/NetworkView/SlideOutContainer';
import FooterReportSelection from './FooterReportSelection';
import BucketReportList from './BucketReportList';
import ReportInfoAccordion from './ReportInfoAccordion';
import ReportListTable from './ReportListTable';
import SearchContainer from './SearchContainer';
import theme from 'theme';
import useReportsHook from 'utils/hooks/useReportsHook';
import { useSelector } from 'react-redux';

const ReportSelection = ({ show = true, setAction }) => {
    const {
        updateSearchText,
        searchText,
        filteredReports,
        handleSwap,
        buckets,
        expanded,
        selectedReportBucketId,
        onViewBucket,
        onCheckedSelection,
        onChecked
    } = useReportsHook();
    const { baseReportLocal, compareReportLocal } = useSelector((state) => state.NetworkReducer);
    const showFooter = Boolean(baseReportLocal);
    if (!show) return <> </>;

    return (
        <SlideOutContainer
            key={baseReportLocal?.id}
            sx={{
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.neutral.neutral4,
                    '&:hover': {
                        backgroundColor: theme.palette.neutral.neutral6
                    }
                }
            }}
            open={true}
            anchor={'left'}
            title={`Reports`}
            startIcon={<FolderSharpIcon sx={{ color: theme.palette.black }} />}
            endIcon={<ClearIcon sx={{ color: theme.palette.black }} />}
            onClose={() => setAction('')}>
            <Grid container>
                <Grid item xs={12}>
                    <SearchContainer searchText={searchText} updateSearchText={updateSearchText} />
                    <Box
                        sx={{
                            padding: '0px 14px 0px 16px',
                            height: `calc(100vh - ${showFooter ? 270 : 210}px)`,
                            overflow: 'auto'
                        }}>
                        {searchText ? (
                            <ReportListTable
                                rows={filteredReports}
                                id={`search`}
                                style={{ maxHeight: `calc(100vh - 270px)` }}
                                onExpand={onViewBucket}
                                onChecked={onChecked}
                                canSelectAll={false}
                                displayGroupLabels={true}
                            />
                        ) : (
                            <>
                                <ReportInfoAccordion
                                    onExpand={onViewBucket}
                                    onCheckedSelection={onCheckedSelection}
                                    handleSwap={handleSwap}
                                    baseReport={baseReportLocal}
                                    compareReport={compareReportLocal}
                                />
                                <BucketReportList
                                    details={filteredReports}
                                    onChecked={onChecked}
                                    list={buckets}
                                    onExpand={onViewBucket}
                                    expanded={expanded}
                                    showFooter={showFooter}
                                    selectedReportBucketId={selectedReportBucketId}
                                    canSelectAll={false}
                                />
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {showFooter && (
                <FooterReportSelection
                    baseReport={baseReportLocal}
                    compareReport={compareReportLocal}
                />
            )}
        </SlideOutContainer>
    );
};

export default ReportSelection;
