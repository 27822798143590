import React, { MouseEventHandler } from 'react';
import { Box, Button as DefaultButton, Theme, Tooltip } from '@mui/material';
import palette from 'theme/palette';
import { BUTTON_TYPE } from 'constants/colors';
import { SxProps } from '@mui/system';
/*Button guideline: https://www.figma.com/file/JHoYXsCbqgLQA31Zxa7mbT/Components?node-id=3351%3A150407 */
/*Button types: default (light 100), dark, blue, red, transparent */

interface buttonProps {
    id?: string;
    buttonText?: string;
    className?: string;
    endIcon?: React.ReactNode;
    startIcon?: React.ReactNode;
    disabled?: boolean;
    tooltipText?: string;
    width?: string;
    height?: string;
    buttonType?: string | null | undefined;
    onClick?: MouseEventHandler | undefined;
    backgroundColor?: string;
    props?: any;
    type?: any;
    sx?: SxProps<Theme>;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
    ref?: React.Ref<HTMLButtonElement>;
    buttonTextWidth?: string;
    unselected?: boolean;
}

const Button = ({
    id = '',
    buttonText = '',
    tooltipText = '',
    endIcon,
    startIcon,
    height = '40px',
    width = '*', // effectively width=auto
    backgroundColor,
    buttonType,
    type = '',
    buttonTextWidth,
    unselected, // Set to true when the button one of multiple option buttons and a different button is selected
    sx,
    ...props
}: buttonProps) => {
    let primaryColor;
    let hoverColor;
    let fontColor;

    switch (buttonType) {
        case BUTTON_TYPE.DARK:
            primaryColor = palette.ODBlue.odBlue9;
            hoverColor = palette.ODBlue.odBlue8;
            fontColor = palette.neutral.white;
            break;
        case BUTTON_TYPE.BLUE:
            primaryColor = palette.semantic.focusedBlue;
            hoverColor = palette.semantic.focusedBlueHover;
            fontColor = palette.neutral.white;
            break;
        case BUTTON_TYPE.MEDIUM_BLUE:
            primaryColor = palette.ODBlue.odBlue7;
            hoverColor = palette.ODBlue.odBlue6;
            fontColor = palette.neutral.white;
            break;
        case BUTTON_TYPE.RED:
            primaryColor = palette.semantic.semanticRed;
            hoverColor = palette.semantic.semanticRedHover;
            fontColor = palette.neutral.white;
            break;
        case BUTTON_TYPE.GREEN:
            primaryColor = palette.semantic.semanticGreen;
            hoverColor = palette.semantic.semanticGreenHover;
            fontColor = palette.neutral.white;
            break;
        case BUTTON_TYPE.YELLOW:
            primaryColor = palette.semantic.semanticYellow;
            hoverColor = palette.semantic.semanticYellowHover;
            fontColor = palette.neutral.black;
            break;
        case BUTTON_TYPE.TRANSPARENT:
            primaryColor = palette.transparent;
            hoverColor = palette.neutral.neutral2;
            fontColor = palette.neutral.black;
            break;
        case BUTTON_TYPE.LIGHT_BLUE:
            primaryColor = palette.ODLightBlueNeutral.lightBlue1;
            hoverColor = palette.neutral.neutral2;
            fontColor = palette.neutral.black;
            break;
        default:
            primaryColor = palette.neutral.neutral1;
            hoverColor = palette.neutral.neutral2;
            fontColor = palette.neutral.black;
            break;
    }
    if (unselected) {
        primaryColor = palette.neutral.neutral1;
        hoverColor = palette.neutral.neutral2;
        fontColor = palette.neutral.neutral6;
    }

    return (
        <Tooltip title={tooltipText} disableHoverListener={!tooltipText}>
            <Box>
                <DefaultButton
                    type={type}
                    id={`${id}-${buttonText}-button`}
                    {...props}
                    sx={{
                        height: height,
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        borderRadius: '4px',
                        width: width,
                        fontSize: '14px',
                        fontWeight: 400,
                        textTransform: 'capitalize',
                        color: fontColor,
                        backgroundColor: primaryColor,
                        '&:disabled': {
                            backgroundColor: palette.neutral.neutral1,
                            color: palette.neutral.neutral6
                        },
                        '&:hover': {
                            backgroundColor: hoverColor
                        },
                        ...sx
                    }}>
                    {startIcon && (
                        <Box sx={{ display: 'flex', marginRight: '16px', fontSize: '18px' }}>
                            {startIcon}
                        </Box>
                    )}
                    <Box
                        sx={{
                            width: buttonTextWidth ? buttonTextWidth : 'auto',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>
                        {buttonText}
                    </Box>

                    {endIcon && (
                        <Box
                            sx={{
                                display: 'flex',
                                marginLeft: '16px',
                                fontSize: '18px',
                                alignItems: 'center'
                            }}>
                            {endIcon}
                        </Box>
                    )}
                </DefaultButton>
            </Box>
        </Tooltip>
    );
};

export default Button;
