import React, { useState } from 'react';
import SelectedReport from './SelectedReport';
import { Collapse, Accordion, Typography, AccordionSummary, Box } from '@mui/material';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import AddIcon from '@mui/icons-material/Add';
import theme from 'theme';

const ReportInfoAccordion = ({ onCheckedSelection, handleSwap, baseReport, compareReport }) => {
    const [expanded, toggleExpand] = useState(true);

    return (
        <Box marginBottom="16px" borderBottom={`1px solid ${theme.palette.neutral.neutral1}`}>
            <Accordion
                key={`reportInfo-list-reportInfo`}
                expanded={expanded}
                style={{
                    backgroundColor: theme.palette.neutral.neutral1,
                    fontSize: 14
                }}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e, expanded) => toggleExpand(expanded)}>
                <AccordionSummary
                    expandIcon={
                        <KeyboardArrowDownSharpIcon
                            sx={{
                                color: expanded
                                    ? theme.palette.black
                                    : theme.palette.neutral.neutral8
                            }}
                        />
                    }
                    aria-controls="accordion-content"
                    id={`accordion-reportInfo`}>
                    <AddIcon
                        sx={{
                            color: theme.palette.black
                        }}
                    />
                    <Typography
                        style={{
                            paddingLeft: 16,
                            fontWeight: 600,
                            color: theme.palette.black
                        }}>
                        Selected Report(s)
                    </Typography>
                </AccordionSummary>
            </Accordion>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box
                    sx={{
                        marginTop: '16px'
                    }}>
                    <SelectedReport
                        onChecked={onCheckedSelection}
                        handleSwap={handleSwap}
                        report={baseReport}
                        label="Base Report"
                    />
                    <SelectedReport
                        onChecked={onCheckedSelection}
                        handleSwap={handleSwap}
                        report={compareReport}
                        label="Compared Report"
                    />
                </Box>
            </Collapse>
        </Box>
    );
};

export default ReportInfoAccordion;
