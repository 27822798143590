import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { FilterV8Props } from './FilterV8Props';
import { max, min } from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerWrapper from './DatePickerWrapper';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import ClearFilterButton from './ClearFilterButtton';
import { EndAdornmentsContainer } from './FilterStyles';
import clsx from 'clsx';

const Label = styled(Box)(() => ({
    fontSize: '8px',
    marginTop: '2px',
    fontWeight: '400',
    color: theme.palette.neutral.neutral8
}));

const DateRangeFilterV8 = ({
    column,
    sortedUniqueValues
}: FilterV8Props & { sortedUniqueValues: string[] }) => {
    const [facetedMin, setFacetedMin] = useState<Date>();
    const [facetedMax, setFacetedMax] = useState<Date>();
    const filterValue = column.getFilterValue() as [Date | null, Date | null];

    /* Move these to FilterStyles after we retire the networkViewLightTheme flag.
     * because it may be messy to include conditionals in the FilterStyles file
     */
    const useStyles = makeStyles(() => ({
        active: {
            border: '1px solid transparent',
            backgroundColor: theme.palette.neutral.neutral2
        },
        inactive: {
            border: `1px solid ${theme.palette.neutral.neutral2}`,
            backgroundColor: 'none',
            color: theme.palette.ODBlue.odBlue7
        },
        populated: {
            color: theme.palette.black
        }
    }));
    const classes = useStyles();

    useEffect(() => {
        column.setFilterValue([null, null]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const facetedValues = sortedUniqueValues?.map((v) => new Date(v));
        setFacetedMin(min(facetedValues));
        setFacetedMax(max(facetedValues));
    }, [sortedUniqueValues]);

    const renderFilterButton = ({
        isActive,
        clickHandler,
        limitType
    }: {
        isActive: boolean;
        clickHandler: any;
        limitType: 'min' | 'max';
    }) => (
        <ODButton
            sx={{
                height: '22px',
                padding: 0,
                paddingLeft: '8px',
                justifyContent: 'flex-start',
                borderRadius: '2px',
                fontSize: '10px',
                textTransform: 'none',
                boxSizing: 'content-box',
                backgroundColor: isActive ? theme.palette.neutral.neutral2 : 'none'
            }}
            width={'70px'}
            className={clsx(
                isActive ? classes.active : classes.inactive,
                filterValue && filterValue[limitType === 'min' ? 0 : 1] && classes.populated
            )}
            buttonType={BUTTON_TYPE.LIGHT}
            type="submit"
            buttonText={
                filterValue && filterValue[limitType === 'min' ? 0 : 1]
                    ? moment(filterValue && filterValue[limitType === 'min' ? 0 : 1]).format(
                          'MM/DD/YY'
                      )
                    : (limitType === 'min' ? facetedMin : facetedMax)
                    ? moment(limitType === 'min' ? facetedMin : facetedMax).format('MM/DD/YY')
                    : ''
            }
            onClick={clickHandler}
            endIcon={
                <EndAdornmentsContainer>
                    {filterValue && filterValue[limitType === 'min' ? 0 : 1] && (
                        <ClearFilterButton
                            clickHandler={() => {
                                column.setFilterValue(
                                    limitType === 'min'
                                        ? [null, filterValue?.[1]]
                                        : [filterValue?.[0], null]
                                );
                            }}
                            color={theme.palette.black}
                        />
                    )}
                    {}
                </EndAdornmentsContainer>
            }
        />
    );

    return (
        <Box
            id={`${column.id}-datetime-filter`}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ marginRight: '2px' }}>
                    <DatePickerWrapper
                        selectedValue={filterValue?.[0]}
                        setSelectedValue={(val: Date | null) =>
                            column.setFilterValue([val, filterValue?.[1]])
                        }
                        limit={facetedMin as Date}
                        facetedMin={facetedMin as Date}
                        facetedMax={filterValue?.[1] ?? (facetedMax as Date)}
                        inputRenderingFn={({
                            isActive,
                            clickHandler
                        }: {
                            isActive: boolean;
                            clickHandler: any;
                        }) => renderFilterButton({ isActive, clickHandler, limitType: 'min' })}
                    />
                    <Box sx={{ fontSize: '8px', marginTop: '2px', fontWeight: '400' }}>Min</Box>
                </Box>
                <Box>
                    <DatePickerWrapper
                        selectedValue={filterValue?.[1]}
                        setSelectedValue={(val: Date | null) =>
                            column.setFilterValue([filterValue?.[0], val])
                        }
                        limit={facetedMax as Date}
                        facetedMin={filterValue?.[0] ?? (facetedMin as Date)}
                        facetedMax={facetedMax as Date}
                        inputRenderingFn={({
                            isActive,
                            clickHandler
                        }: {
                            isActive: boolean;
                            clickHandler: any;
                        }) => renderFilterButton({ isActive, clickHandler, limitType: 'max' })}
                    />
                    <Label>Max</Label>
                </Box>
            </LocalizationProvider>
        </Box>
    );
};

export default DateRangeFilterV8;
