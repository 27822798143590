/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Box, Typography, IconButton, capitalize } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import theme from 'theme';
import Moment from 'react-moment';
import { useSelector, useDispatch } from 'react-redux';
import { updateNetworkView } from 'redux/actions';
import CircleIcon from '@mui/icons-material/Circle';

const useStyles = makeStyles((theme) => ({
    menuIconButton: {
        minWidth: '0px',
        height: '40px',
        width: '40px'
    },
    iconButton: {
        backgroundColor: `${theme.palette.neutral['100']} !important`,
        minWidth: '0px',
        height: '40px',
        width: '40px',
        margin: '0px !important',
        '&:hover': {
            backgroundColor: `${theme.palette.neutral['200']} !important`
        }
    },
    dialogPaper: {
        padding: '32px',
        width: '392px !important'
    }
}));

const DriverDetailSlideout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { organization } = useSelector((state) => state.SignInReducer);
    const isOrgTypeShipper = organization?.org_type === 'shipper';
    const {
        showDriveMoveDetails: { currData },
        showDriveMoveDetails
    } = useSelector((state) => state.NetworkReducer);

    const handleClose = () => {
        dispatch(
            updateNetworkView({
                showDriveMoveDetails: {
                    show: false,
                    currData: {}
                }
            })
        );
    };

    const DRIVER_MOMENTS = [
        {
            title: 'Driver Type',
            value: 'driver_type'
        },
        { title: 'Shipper ID', value: 'bill_to_coporate' },
        { title: isOrgTypeShipper ? ' Fleet Cost' : 'Hard Dollar Cost', value: 'hard_dollar_cost' },
        {
            title: isOrgTypeShipper ? 'External Cost' : 'Hard Dollar Revenue',
            value: 'hard_dollar_revenue'
        },
        { title: 'Empty Distance', value: 'empty_distance' },
        { title: 'Load Distance Total', value: 'loaded_distance' },
        {
            title: 'Current Location',
            value: 'driver_current_location'
        },
        {
            title: 'Current Available Date',
            value: 'driver_current_avail_date',
            options: { isDate: true, format: 'MM/DD' }
        },
        {
            title: 'Current Available Time',
            value: 'driver_current_avail_time',
            options: { isDate: true, format: 'HH:mm', parse: 'HH:mm' }
        },
        {
            title: 'Start Move Date',
            value: 'driver_start_move_date',
            options: { isDate: true, format: 'MM/DD' }
        },
        {
            title: 'Start Move Time',
            value: 'driver_start_move_time',
            options: { isDate: true, format: 'HH:mm', parse: 'HH:mm' }
        },
        {
            title: 'Origin Live Flag',
            value: 'origin_live_flag',
            options: { isBoolen: true }
        },
        { title: 'Load Pick Up Location', value: 'load_pickup_location' },
        {
            title: 'Actual Pick Up Date',
            value: 'pickup_date_time',
            options: { isDate: true, format: 'MM/DD' }
        },
        {
            title: 'Actual Pick Up Time',
            value: 'pickup_date_time',
            options: { isDate: true, format: 'HH:mm' }
        },
        {
            title: 'Depart Date Load Origin',
            value: 'driver_departure_date_at_load_orig',
            options: { isDate: true, format: 'MM/DD' }
        },
        {
            title: 'Depart Time Load Origin',
            value: 'driver_departure_time_at_load_orig',
            options: { isDate: true, format: 'HH:mm', parse: 'HH:mm' }
        },
        {
            title: 'Destination Drop Flag',
            value: 'destination_drop_flag',
            options: { isBoolen: true }
        },
        { title: 'Load Drop Off Location', value: 'load_drop_off_location' },
        {
            title: 'Arrival Date Load Destination',
            value: 'driver_arrival_date_at_load_dest',
            options: { isDate: true, format: 'MM/DD' }
        },
        {
            title: 'Arrival Time Load Destination',
            value: 'driver_arrival_time_at_load_dest',
            options: { isDate: true, format: 'HH:mm', parse: 'HH:mm' }
        },
        {
            title: 'Actual Delivery Date',
            value: 'delivery_date_time',
            options: { isDate: true, format: 'MM/DD' }
        },
        {
            title: 'Actual Delivery Time',
            value: 'delivery_date_time',
            options: { isDate: true, format: 'HH:mm' }
        },
        {
            title: 'TAH Start Date',
            value: null
        },
        {
            title: 'TAH Start Time',
            value: null
        },
        {
            title: 'TAH End Date',
            value: null
        },
        {
            title: 'TAH End Time',
            value: null
        },
        {
            title: 'Next Location',
            value: 'driver_next_location'
        },
        {
            title: 'Next Available Date',
            value: 'driver_next_avail_date',
            options: { isDate: true, format: 'MM/DD' }
        },
        {
            title: 'Next Available Time',
            value: 'driver_next_avail_time',
            options: { isDate: true, format: 'HH:mm', parse: 'HH:mm' }
        }
    ];

    const renderIcon = (row) => {
        return (
            <CircleIcon
                sx={{
                    color:
                        row?.value?.toLowerCase() === 'empty'
                            ? theme.palette.white
                            : theme.palette.semantic.semanticGreen,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '9.5px'
                }}
            />
        );
    };

    const topSection = [
        {
            title: 'Start Move Type',
            value: capitalize(currData?.load_type),
            showIcon: true
        },
        {
            title: 'Start Move Location',
            value: currData?.load_pickup_location
        },
        {
            title: 'End Move Type',
            value: capitalize(currData?.load_type),
            showIcon: true
        },
        {
            title: 'Start Move Location',
            value: currData?.load_drop_off_location
        }
    ];

    const MomentDetail = (value, nullValue, options) => {
        if (value === null)
            return (
                <Box component="span" sx={{ color: theme.palette.neutral.neutral6 }}>
                    {nullValue}
                </Box>
            );

        if (options.isDate) {
            return (
                <Moment format={options.format} parse={options.parse}>
                    {value}
                </Moment>
            );
        } else if (options.isBoolen) {
            return <Box component="span">{value ? 'Y' : 'N'}</Box>;
        } else {
            return <Box component="span">{value}</Box>;
        }
    };

    return (
        <Drawer
            anchor="right"
            open={showDriveMoveDetails?.show}
            style={{ zIndex: 1300 }}
            onClose={() => handleClose()}>
            <Box sx={{ width: '392px', padding: '32px' }}>
                <Box
                    component="section"
                    id="edit-heading"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '32px'
                    }}>
                    <Typography variant="h1" sx={{ fontSize: '18px' }}>
                        Details
                    </Typography>

                    <IconButton
                        className={classes.iconButton}
                        onClick={handleClose}
                        aria-label="close menu"
                        classes={{ root: classes.menuIconButton }}
                        size="large">
                        <CloseIcon sx={{ color: theme.palette.neutral.black }} />
                    </IconButton>
                </Box>

                {/*slide out detail information */}

                {topSection.map((row) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                fontSize: '14px',
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px',
                                padding: '8px 16px',
                                marginBottom: '2px'
                            }}>
                            {row?.showIcon && renderIcon(row)}
                            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                                {row.title}
                            </Typography>{' '}
                            : {row.value}
                        </Box>
                    );
                })}

                <Box sx={{ marginTop: '16px' }}>
                    {DRIVER_MOMENTS.map((row) => {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontSize: '14px',
                                    borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                                    borderRadius: '4px',
                                    padding: '8px 16px',
                                    marginBottom: '2px'
                                }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                                    {row.title}:
                                </Typography>{' '}
                                {row?.options ? (
                                    MomentDetail(currData[row?.value], 'N/A', row?.options)
                                ) : !currData[row?.value] ? (
                                    <Box sx={{ color: theme.palette.neutral.neutral6 }}>N/A</Box>
                                ) : (
                                    currData[row?.value]
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Drawer>
    );
};

export default DriverDetailSlideout;
