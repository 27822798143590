import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

import TextField from '@mui/material/TextField';

//TODO somehow when adding both prefix and suffix, the NumberFormat input lags and prevents users from typing properly. need to keep this code DRY

export const BasicNumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={onChange}
            thousandSeparator
            isNumericString
        />
    );
});

export const PercentageFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={onChange}
            thousandSeparator
            isNumericString
            suffix="%"
        />
    );
});
export const CurrencyFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={onChange}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
});

const FormattedInputs = forwardRef((props, ref) => {
    let { onChange, value, disabled, id } = props;
    if (Number(value) > 0 && id === 'percentage') value = `+${value}`;
    // Add +$ and -$ formatting to fixed rate input
    if (Number(value) > 0 && id === 'fixed') value = `+$${value}`;
    else if (Number(value) < 0 && id === 'fixed') value = `-$${value.substring(1)}`;
    return (
        <TextField
            inputRef={ref}
            placeholder="0"
            disabled={disabled}
            sx={{ width: '100%' }}
            value={value}
            onChange={onChange}
            name="numberformat"
            InputProps={{
                inputComponent: id === 'fixed' ? BasicNumberFormatCustom : PercentageFormatCustom,
                disableUnderline: true
            }}
            inputProps={{
                'data-testid': 'Enter-Rate-Adjustment',
                min: 0,
                maxLength: id === 'percentage' ? 6 : 7,
                step: '1',
                style: { textAlign: 'center' }
            }}
            variant="standard"
        />
    );
});
export default FormattedInputs;
