import { createColumnHelper, Row, Table } from '@tanstack/react-table';
import { CELL_ACTION_BUTTON, DataRow } from 'shared/ODTable/ODTableTypes';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { VALIDATION_RULE } from 'utils/common.util';
import { minMaxFilterV8, textFilterV8 } from 'shared/Table/tableFilters';
import { FILTER_TYPE } from 'shared/Table/tableFilters/FilterConstants';
import {
    dollarStyle,
    DollarBox,
    UnspecifiedDataPlaceholder
} from 'shared/ODTable/ODTableStyledComponents';
import { PREFERRED_RATE_TYPE } from 'constants/settings';
import { BIDDING_NAMES } from 'constants/bidding';

const MARKET_RATES_TOOLTIP =
    'Market rates reflect the latest market rate available for supported equipment types and/or lanes.';
const NO_AVAILABLE_HISTORIC_RATE_TOOLTIP = 'A Historic Rate is not available for this lane.';

const StyledSpan = styled('span')(() => ({
    textOverflow: 'ellipsis',
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}));

const columnHelper = createColumnHelper<DataRow>();
// Keep in mind, accessors cannot include a dot character.
const editBidConfigColumnsInitiate = (
    textFieldMatchingStrategy: string,
    showBidConfigMarketRate: boolean,
    handleSwapRateColumn: (option: string, table: Table<DataRow>) => void,
    rateType: PREFERRED_RATE_TYPE,
    flatRatesFlag: boolean,
    lineHaulRevPerMileFlag: boolean,
    salesRanking: boolean,
    odpt3830NameConsistency: boolean
) => {
    const textMatchingFilterFnWrapper = (row: Row<DataRow>, columnId: string, value: any) =>
        textFilterV8(row, columnId, value, textFieldMatchingStrategy);
    const columns = [
        columnHelper.accessor('id', {
            id: 'id',
            header: () => 'id',
            cell: (info) => info.getValue(),
            meta: { notDisplayed: true }
        }),
        columnHelper.accessor('origin', {
            id: 'origin',
            header: () => BIDDING_NAMES.ORIGIN,
            cell: (info) => {
                const tooltipText = `${info.row.original.originCityStateZip}`;
                return info.getValue() ? (
                    <Tooltip title={tooltipText}>
                        <StyledSpan>{info.getValue() as any}</StyledSpan>
                    </Tooltip>
                ) : (
                    <UnspecifiedDataPlaceholder />
                );
            },
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: BIDDING_NAMES.ORIGIN,
                sticky: 'left'
            }
        }),
        columnHelper.accessor('destination', {
            id: 'destination',
            header: () => BIDDING_NAMES.DESTINATION,
            cell: (info) => {
                const tooltipText = `${info.row.original.destinationCityStateZip}`;

                return info.getValue() ? (
                    <Tooltip title={tooltipText}>
                        <StyledSpan>{info.getValue() as any}</StyledSpan>
                    </Tooltip>
                ) : (
                    <UnspecifiedDataPlaceholder />
                );
            },
            footer: (info) => info.column.id,
            size: 220,
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: BIDDING_NAMES.DESTINATION,
                sticky: 'left'
            }
        }),
        ...(salesRanking
            ? [
                  columnHelper.accessor('sales_ranking', {
                      id: 'sales_ranking',
                      header: () => 'Sales Ranking',
                      cell: (info) =>
                          info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />,
                      footer: (info) => info.column.id,
                      size: 148,
                      filterFn: minMaxFilterV8,
                      meta: { filterType: FILTER_TYPE.NUMBER_RANGE, name: 'Sales Ranking' }
                  })
              ]
            : []),
        columnHelper.accessor('equipment_type', {
            id: 'equipment_type',
            header: () => BIDDING_NAMES.EQUIPMENT_TYPE,
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 220,
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: BIDDING_NAMES.EQUIPMENT_TYPE
            }
        }),
        columnHelper.accessor('load_volume', {
            id: 'load_volume',
            header: () => (odpt3830NameConsistency ? BIDDING_NAMES.LOAD_VOLUME : 'Available Loads'),
            cell: (info) =>
                info.getValue() ? (
                    Number(info!.getValue()!) >= 1000 ? (
                        Number(info.getValue())?.toLocaleString()
                    ) : (
                        info.getValue()
                    )
                ) : (
                    <UnspecifiedDataPlaceholder />
                ),
            footer: (info) => info.column.id,
            size: 148,
            filterFn: minMaxFilterV8,
            meta: {
                filterType: FILTER_TYPE.NUMBER_RANGE,
                visibleByDefault: true,
                name: BIDDING_NAMES.LOAD_VOLUME
            }
        }),
        columnHelper.accessor('load_volume_type', {
            id: 'load_volume_type',
            header: () => (
                <span>
                    {odpt3830NameConsistency ? BIDDING_NAMES.LOAD_FREQUENCY : 'Load Vol. Frequency'}
                </span>
            ),
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 220,
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: BIDDING_NAMES.LOAD_FREQUENCY
            }
        }),
        columnHelper.accessor('mileage', {
            id: 'mileage',
            header: () => BIDDING_NAMES.MILEAGE,
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 148,
            filterFn: minMaxFilterV8,
            meta: {
                filterType: FILTER_TYPE.NUMBER_RANGE,
                visibleByDefault: true,
                name: BIDDING_NAMES.MILEAGE
            }
        }),
        columnHelper.accessor('shipper_id', {
            id: 'shipper_id',
            header: () => 'Shipper ID',
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 220,
            filterFn: textMatchingFilterFnWrapper,
            meta: { filterType: FILTER_TYPE.DROPDOWN, name: 'Shipper ID' }
        }),
        columnHelper.accessor('other_revenue', {
            id: 'other_revenue',
            header: () => BIDDING_NAMES.FUEL_SURCHARGE,
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 220,
            meta: {
                filterType: FILTER_TYPE.NUMBER_RANGE,
                name: BIDDING_NAMES.FUEL_SURCHARGE,
                validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT,
                format: 'dollars',
                cellStyle: dollarStyle
            }
        }),
        ...(lineHaulRevPerMileFlag
            ? [
                  columnHelper.accessor('other_revenue_per_mile', {
                      id: 'other_revenue_per_mile',
                      header: () => 'Line Haul Revenue Per Mile',
                      cell: (info) =>
                          info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />,
                      footer: (info) => info.column.id,
                      size: 195,
                      filterFn: minMaxFilterV8,
                      meta: {
                          filterType: FILTER_TYPE.NUMBER_RANGE,
                          name: 'Line Haul Revenue Per Mile',
                          validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT,
                          format: 'dollars',
                          cellStyle: dollarStyle
                      }
                  })
              ]
            : []),
        columnHelper.accessor('customer_bid_id', {
            id: 'customer_bid_id',
            header: () => BIDDING_NAMES.CUSTOMER_LANE_ID,
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 220,
            filterFn: textMatchingFilterFnWrapper,
            meta: { filterType: FILTER_TYPE.DROPDOWN, name: BIDDING_NAMES.CUSTOMER_LANE_ID }
        }),
        columnHelper.accessor('origin_load_method', {
            id: 'origin_load_method',
            header: () => BIDDING_NAMES.ORIGIN_LOAD_METHOD,
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 220,
            filterFn: textMatchingFilterFnWrapper,
            meta: { filterType: FILTER_TYPE.DROPDOWN, name: BIDDING_NAMES.ORIGIN_LOAD_METHOD }
        }),
        columnHelper.accessor('destination_unload_method', {
            id: 'destination_unload_method',
            header: () => BIDDING_NAMES.DESTINATION_UNLOAD_METHOD,
            cell: (info) => (info.getValue() ? info.getValue() : <UnspecifiedDataPlaceholder />),
            footer: (info) => info.column.id,
            size: 220,
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                name: BIDDING_NAMES.DESTINATION_UNLOAD_METHOD
            }
        }),
        columnHelper.accessor('historic_rate', {
            id: 'historic_rate',
            header: () => 'Historic Rate',
            cell: (info) =>
                info.getValue() ? (
                    <DollarBox>{(info.getValue() as number).toFixed(2)}</DollarBox>
                ) : (
                    <Tooltip title={NO_AVAILABLE_HISTORIC_RATE_TOOLTIP}>
                        <div>
                            {' '}
                            <UnspecifiedDataPlaceholder />
                        </div>
                    </Tooltip>
                ),
            footer: (info) => info.column.id,
            size: 148,
            filterFn: minMaxFilterV8,
            meta: {
                filterType: FILTER_TYPE.NUMBER_RANGE,
                visibleByDefault: true,
                required: true,
                name: 'Historic Rate'
            }
        }),
        ...(showBidConfigMarketRate
            ? [
                  columnHelper.accessor('market_rate', {
                      id: 'market_rate',
                      cell: (info) =>
                          info.getValue() ? (
                              <DollarBox>{(info.getValue() as number).toFixed(2)}</DollarBox>
                          ) : (
                              <UnspecifiedDataPlaceholder />
                          ),
                      footer: (info) => info.column.id,
                      size: 160,
                      filterFn: minMaxFilterV8,
                      header: () => 'Market Rate',
                      meta: {
                          filterType: FILTER_TYPE.NUMBER_RANGE,
                          visibleByDefault: true,
                          required: true,
                          name: 'Market Rate',
                          infoMessage: MARKET_RATES_TOOLTIP
                      }
                  })
              ]
            : []),

        ...(flatRatesFlag && rateType === PREFERRED_RATE_TYPE.FLAT_RATE
            ? [
                  columnHelper.accessor('flat_rate', {
                      id: 'flat_rate',
                      cell: (info) => <DollarBox>{info.getValue() as any}</DollarBox>,
                      footer: (info) => info.column.id,
                      size: 200,
                      filterFn: minMaxFilterV8,
                      header: () => BIDDING_NAMES.FLAT_RATE,
                      sortingFn: (rowA: any, rowB: any, columnId: any): number =>
                          Number(rowA.getValue(columnId)) < Number(rowB.getValue(columnId))
                              ? 1
                              : -1,
                      meta: {
                          filterType: FILTER_TYPE.NUMBER_RANGE,
                          visibleByDefault: true,
                          required: true,
                          validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT,
                          format: 'dollars',
                          cellStyle: dollarStyle,
                          name: BIDDING_NAMES.FLAT_RATE,
                          ...(flatRatesFlag
                              ? {
                                    swapColumns: {
                                        category: 'rate',
                                        tooltip: 'Swap Rate Type',
                                        options: [
                                            { value: 'flat_rate', label: BIDDING_NAMES.FLAT_RATE },
                                            {
                                                value: 'rate_per_mile',
                                                label: BIDDING_NAMES.RATE_PER_MILE
                                            }
                                        ],
                                        callback: (option: string, table: Table<DataRow>) =>
                                            handleSwapRateColumn(option, table)
                                    }
                                }
                              : {})
                      }
                  })
              ]
            : [
                  columnHelper.accessor('rate_per_mile', {
                      id: 'rate_per_mile',
                      cell: (info) => <DollarBox>{info.getValue() as any}</DollarBox>,
                      footer: (info) => info.column.id,
                      size: 220,
                      filterFn: minMaxFilterV8,
                      header: () => BIDDING_NAMES.RATE_PER_MILE,
                      sortingFn: (rowA: any, rowB: any, columnId: any): number =>
                          Number(rowA.getValue(columnId)) < Number(rowB.getValue(columnId))
                              ? 1
                              : -1,
                      meta: {
                          filterType: FILTER_TYPE.NUMBER_RANGE,
                          visibleByDefault: true,
                          required: true,
                          validationRule: VALIDATION_RULE.NONNEGATIVE_FLOAT,
                          cellStyle: dollarStyle,
                          format: 'dollars',
                          name: BIDDING_NAMES.RATE_PER_MILE,
                          actionButtons: [CELL_ACTION_BUTTON.COPY_HISTORICAL_RATE],
                          ...(flatRatesFlag
                              ? {
                                    swapColumns: {
                                        category: 'rate',
                                        tooltip: 'Swap Rate Type',
                                        options: [
                                            { value: 'flat_rate', label: BIDDING_NAMES.FLAT_RATE },
                                            {
                                                value: 'rate_per_mile',
                                                label: BIDDING_NAMES.RATE_PER_MILE
                                            }
                                        ],
                                        callback: (option: string, table: Table<DataRow>) =>
                                            handleSwapRateColumn(option, table)
                                    }
                                }
                              : {})
                      }
                  })
              ])
    ];
    return columns;
};

const separatedColumns = ['rate_per_mile', 'flat_rate'];
export { separatedColumns };
export default editBidConfigColumnsInitiate;
