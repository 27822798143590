import React, { useEffect, useState } from 'react';
import { Box, Typography, Tooltip, Divider, Skeleton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { ODIconButton } from 'shared';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import { BUTTON_TYPE } from 'constants/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaneScoreAccordion from 'components/BidAnalysis/LaneScoreSlideout/LaneScoreAccordion';
import { AlertTriangleIcon } from 'assets/images';
import * as _ from 'lodash';
import {
    DrawerHeader,
    HeaderTitle,
    StyledHeaderActions
} from 'components/BidAnalysis/LaneScoreSlideout/LaneScoreSlideout.styles';

import { Chart, registerables } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

import { updateBidAnalysis } from 'redux/actions/bidAnalysisActions';
import { getLaneScoreRating } from '../../../utils/bidAnalysis.util';
import { RATING } from 'types/bidAnalysis';
import LaneOriginDestinationRibbon from 'shared/LaneOriginDestinationRibbon';
Chart.register(...registerables);

export interface LaneScoreKPIMetricsProps {
    title: string;
    name: string;
    ideal_region: number;
    tolerable_region: number;
    unacceptable_region: number;
    average_value: number | string;
    description: string;
    average_value_type: string;
    categorization: string;
    units: string;
    condition: null;
    tolerable_start: number;
    tolerable_end: number;
    unacceptable_below_threshold: boolean;
    observation_frequency: string;
}

const LaneScoreSlideout = ({ onClose }: any) => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState({}) as any;
    const { selectedLaneScoreMetaData } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );

    useEffect(() => {
        let initialStateVar = {} as any;
        selectedLaneScoreMetaData?.kpis?.forEach((kpi: any) => {
            initialStateVar[kpi?.name] = Boolean(false);
        });

        setOpenDrawer(initialStateVar);
    }, [selectedLaneScoreMetaData?.kpis]);

    const handleClose = () => {
        onClose();
        setOpenDrawer({});
        dispatch(
            updateBidAnalysis({
                selectedLaneScoreMetaData: {}
            })
        );
    };

    const setLaneScoreStatusIcon = (score: number) => {
        const status = getLaneScoreRating({ value: score });
        switch (status) {
            case RATING.IDEAL:
                return (
                    <CircleIcon
                        fontSize="small"
                        sx={{ marginRight: '16px', color: theme.palette.semantic.semanticGreen }}
                    />
                );
            case RATING.TOLERABLE:
                return (
                    <AlertTriangleIcon
                        style={{
                            marginRight: '16px',
                            fill: theme.palette.semantic.semanticYellow
                        }}
                    />
                );
            case RATING.UNACCEPTABLE:
                return (
                    <CloseIcon
                        fontSize="small"
                        sx={{
                            marginRight: '16px',
                            color: theme.palette.semantic.semanticRed
                        }}
                    />
                );
        }
    };

    const sortedKPIs = _.orderBy(selectedLaneScoreMetaData.kpis, ['title'], ['asc']);

    return (
        <Box sx={{ overflow: 'hidden' }}>
            {selectedLaneScoreMetaData?.bid_id ? (
                <DrawerHeader display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        {setLaneScoreStatusIcon(selectedLaneScoreMetaData?.lane_score)}
                        <HeaderTitle>
                            <b>Lane Score</b>: {Math.round(selectedLaneScoreMetaData?.lane_score)}
                        </HeaderTitle>
                    </Box>

                    <StyledHeaderActions>
                        <Tooltip
                            title={
                                <Typography>
                                    A score which estimates the overall impact of the bid lane on
                                    the network.
                                </Typography>
                            }>
                            <ODIconButton
                                disableHover={true}
                                sx={{ marginRight: '2px', cursor: 'initial' }}
                                id={`helper-button`}
                                buttonType={BUTTON_TYPE.TRANSPARENT}
                                icon={
                                    <InfoOutlinedIcon
                                        sx={{ color: theme.palette.black }}
                                        fontSize="small"
                                    />
                                }
                            />
                        </Tooltip>

                        <ODIconButton
                            id={`close-button`}
                            onClick={handleClose}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            icon={<CloseIcon sx={{ color: theme.palette.neutral.black }} />}
                        />
                    </StyledHeaderActions>
                </DrawerHeader>
            ) : (
                <Skeleton sx={{ height: '200px', margin: '16px' }} />
            )}

            {selectedLaneScoreMetaData?.origin && selectedLaneScoreMetaData?.destination ? (
                <>
                    <Box sx={{ margin: '0 16px' }}>
                        <LaneOriginDestinationRibbon
                            origin={selectedLaneScoreMetaData?.origin as number}
                            destination={selectedLaneScoreMetaData?.destination as number}
                        />
                    </Box>

                    <Divider
                        sx={{
                            width: '100%',
                            borderColor: theme.palette.neutral.neutral1
                        }}
                    />
                </>
            ) : (
                <Skeleton sx={{ height: '500px', margin: '16px' }} />
            )}

            <Box sx={{ overflowY: 'auto', height: '880px' }}>
                {selectedLaneScoreMetaData?.kpis?.length > 0 ? (
                    <Box paddingX="16px" component={Typography}>
                        <Box sx={{ margin: '16px 0' }}>
                            {sortedKPIs?.map((kpi: LaneScoreKPIMetricsProps) => {
                                let accordionState = openDrawer[kpi?.name] as any;
                                /*top*/
                                const displayedTitle = kpi?.title;
                                const statusType = kpi?.categorization;
                                const KPIAvgValue = Math.round(Number(kpi?.average_value));

                                /*percentage */
                                const idealPercentage = Math.round(kpi?.ideal_region * 100);
                                const tolerablePercentage = Math.round(kpi?.tolerable_region * 100);
                                const unacceptablePercentage = Math.round(
                                    kpi?.unacceptable_region * 100
                                );

                                /*field type*/
                                let graphLabel = [
                                    kpi?.unacceptable_below_threshold
                                        ? `${kpi?.tolerable_end} - Over`
                                        : `Under - ${kpi?.tolerable_start}`,
                                    `${kpi.tolerable_start} - ${kpi.tolerable_end}`,
                                    !kpi?.unacceptable_below_threshold
                                        ? `${kpi?.tolerable_end} - Over`
                                        : `Under - ${kpi?.tolerable_start}`
                                ];
                                let metricsOption = {
                                    responsive: true,
                                    scales: {
                                        y: {
                                            title: {
                                                display: true,
                                                text: 'Percentage',
                                                color: 'black'
                                            },
                                            max: 105,
                                            ticks: {
                                                stepSize: 50
                                            }
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: 'Thresholds',
                                                color: 'black'
                                            }
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        title: {
                                            display: false,
                                            color: 'black'
                                        },
                                        datalabels: {
                                            formatter: (value: any, context: any) => {
                                                return `${value}%`;
                                            },
                                            display: true,
                                            color: 'black',
                                            anchor: 'end',
                                            offset: -20,
                                            align: 'start'
                                        }
                                    }
                                } as any;

                                const metricsData = {
                                    labels: graphLabel,
                                    datasets: [
                                        {
                                            label: kpi?.name,
                                            data: selectedLaneScoreMetaData?.unacceptable_below_threshold
                                                ? [
                                                      Math.round(kpi?.unacceptable_region * 100),
                                                      Math.round(kpi?.tolerable_region * 100),
                                                      Math.round(kpi?.ideal_region * 100)
                                                  ]
                                                : [
                                                      Math.round(kpi?.ideal_region * 100),
                                                      Math.round(kpi?.tolerable_region * 100),
                                                      Math.round(kpi?.unacceptable_region * 100)
                                                  ],
                                            barThickness: 112,
                                            maxBarThickness: 112,
                                            backgroundColor: [
                                                theme.palette.semantic.semanticGreen,
                                                theme.palette.semantic.semanticYellow,
                                                theme.palette.semantic.semanticRed
                                            ],
                                            borderWidth: 1
                                        }
                                    ]
                                };

                                /*criteria description*/
                                let idealDesc = kpi?.unacceptable_below_threshold
                                    ? `${idealPercentage} % of ${kpi?.observation_frequency} are ${kpi?.tolerable_end} - over.`
                                    : `${idealPercentage} % of ${kpi?.observation_frequency} are under - ${kpi?.tolerable_start}`;

                                let tolerableDesc = `${tolerablePercentage} % of ${kpi?.observation_frequency} are within ${kpi.tolerable_start} - ${kpi.tolerable_end}`;

                                let unacceptableDesc = !kpi?.unacceptable_below_threshold
                                    ? `${unacceptablePercentage} % of ${kpi.observation_frequency} are ${kpi?.tolerable_end} - over`
                                    : `${unacceptablePercentage} % of ${kpi.observation_frequency} are under - ${kpi?.tolerable_start} `;

                                return (
                                    <LaneScoreAccordion
                                        kpi={kpi}
                                        label={kpi?.name}
                                        status={statusType}
                                        setOpenDrawer={setOpenDrawer}
                                        openDrawer={openDrawer}
                                        accordionState={accordionState}
                                        title={displayedTitle}
                                        averageValue={KPIAvgValue}
                                        thresholdDescription={{
                                            ideal: idealDesc,
                                            tolerable: tolerableDesc,
                                            unacceptable: unacceptableDesc
                                        }}
                                        metricOptions={metricsOption}
                                        metricData={metricsData}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                ) : (
                    <Skeleton sx={{ height: '500px', margin: '16px' }} />
                )}
            </Box>
        </Box>
    );
};

export default LaneScoreSlideout;
