import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import CloseIcon from '@mui/icons-material/Close';
import { updateNetworkView } from 'redux/actions/networkActions';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { isActionAllowed } from 'utils/network.util';
import { useDriverHook } from 'utils/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

function DriverAdvisories({ row, readOnly, isAggregated }) {
    const { allowEditOnAggregatedDrivers, newSimulationFlow } = useFlags();
    const dispatch = useDispatch();
    const { compare_run } = useParams();
    const [show, setShow] = useState(false);
    const { configuration } = useSelector((state) => state.ConfigurationReducer);
    const alertsContext = useAlerts();
    const { handleDriverEdit } = useDriverHook();

    const undoDriver = () => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        return 'undoDriver';
    };

    let actions = [
        {
            name: 'edit',
            action: () => handleDriverEdit(row),
            icon: (
                <EditSharpIcon
                    sx={{
                        color: theme.palette.black
                    }}
                />
            )
        },
        {
            name: 'notShow',
            action: () => setShow(false),
            closeIcon: true,
            icon: (
                <CloseIcon
                    style={{
                        fill: theme.palette.white
                    }}
                />
            )
        }
    ];

    const toggleHoverByMouseMove = (name, type) => {
        if (name === 'edit') dispatch(updateNetworkView({ rowHover: type !== 'onMouseEnter' }));
    };

    const actionLists = actions.map((option, i) => {
        const { name, action, icon } = option;
        const isDisabled =
            (compare_run || (!allowEditOnAggregatedDrivers && isAggregated)) && name !== 'notShow';

        return (
            <IconButton
                key={`${name}+${i}`}
                id={name}
                onMouseEnter={() => {
                    toggleHoverByMouseMove(name, 'onMouseEnter');
                }}
                onMouseLeave={() => {
                    toggleHoverByMouseMove(name, 'onMouseLeave');
                }}
                sx={{
                    borderRadius: '4px',
                    backgroundColor: option?.closeIcon
                        ? theme.palette.ODBlue.odBlue8
                        : theme.palette.neutral.neutral1,
                    minWidth: 0,
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '8px',
                    '&:hover': {
                        backgroundColor: option?.closeIcon
                            ? theme.palette.ODBlue.odBlue7
                            : theme.palette.neutral.neutral2,
                        '& > svg': {
                            color: theme.palette.black
                        }
                    }
                }}
                onClick={() => {
                    isDisabled
                        ? dispatch(
                              updateNetworkView({
                                  showAlertModal: true
                              })
                          )
                        : action();
                }}>
                {icon}
            </IconButton>
        );
    });

    return (
        <Box sx={{ position: 'relative' }}>
            {show ? (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        right: -33,
                        top: -16
                    }}
                    onClick={() => setShow(false)}>
                    {actionLists}
                </Box>
            ) : row?.original?.isRemoved ? (
                <IconButton
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: theme.palette.white,
                        minWidth: 0,
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            backgroundColor: theme.palette.ODBlue.odBlue9
                        }
                    }}
                    onClick={undoDriver}>
                    <UndoSharpIcon sx={{ color: theme.palette.neutral.white }} />
                </IconButton>
            ) : (
                <IconButton
                    disabled={readOnly}
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: theme.palette.white,
                        minWidth: 0,
                        position: 'absolute',
                        right: -33,
                        top: -16,
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            backgroundColor: theme.palette.ODBlue.odBlue8,
                            '.MuiSvgIcon-root': {
                                color: theme.palette.white
                            }
                        },
                        '&:disabled': {
                            backgroundColor: theme.palette.neutral.neutral4
                        }
                    }}
                    onClick={() => setShow(true)}>
                    <MoreVertIcon
                        sx={{
                            color: readOnly ? '#00000042' : theme.palette.black
                        }}
                    />
                </IconButton>
            )}
        </Box>
    );
}

export default DriverAdvisories;
