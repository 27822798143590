/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import FlatfileImporter from '@flatfile/adapter';
import { uniqBy } from 'lodash';

import API from 'utils/axios';
import { importerSettings, FLATFILE_KEY_URI } from 'constants/flatfile';
import { registerDataHooks } from 'components/FlatfileImporter/helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

const fetchFlatfileData = async () => {
    let { data } = await API.get(FLATFILE_KEY_URI);
    return data;
};

// See interface definition for FeatureFlags in src/constants/flatfile.ts
// To manage feature flags, update this and the interface
const featureFlags = {
    salesRanking: false,
    flatRates: false,
    flexibleLocationFlatfileFlag: false,
    zipCodeCliffordValidation: false,
    lineHaulRevPerMile: false,
    odpt407934Zip: false
};

const Flatfile = ({ setImporter, defaultRateType }) => {
    const {
        salesRanking,
        flatRates,
        flexibleLocationFlatfileFlag,
        zipCodeCliffordValidation,
        lineHaulRevPerMile,
        odpt407934Zip
    } = useFlags();
    featureFlags.salesRanking = salesRanking;
    featureFlags.flatRates = flatRates;
    featureFlags.flexibleLocationFlatfileFlag = flexibleLocationFlatfileFlag;
    featureFlags.zipCodeCliffordValidation = zipCodeCliffordValidation;
    featureFlags.lineHaulRevPerMile = lineHaulRevPerMile;
    featureFlags.odpt407934Zip = odpt407934Zip;

    const importerSettingsWithFlag = importerSettings(featureFlags, defaultRateType);
    importerSettingsWithFlag.fields = uniqBy(importerSettingsWithFlag.fields, 'label');
    const fetchData = async () => {
        if ((flatRates && defaultRateType) || !flatRates) {
            const flatfileData = await fetchFlatfileData();
            const _importer = new FlatfileImporter(flatfileData.key, importerSettingsWithFlag);
            _importer.setCustomer(flatfileData.user);
            registerDataHooks(_importer, featureFlags, defaultRateType);
            setImporter(_importer);
        }
    };
    useEffect(() => {
        fetchData();
    }, [defaultRateType]);

    return <></>;
};

export default Flatfile;
