import React from 'react';
import { useODTableStyles, StyledHeaderRow, HeaderCell } from './ODTableStyledComponents';
import { Box, IconButton, TableHead, Tooltip } from '@mui/material';
import { HeaderGroup, Header, Table } from '@tanstack/react-table';
import theme from 'theme';
import ViewListIcon from '@mui/icons-material/ViewList';
import { DataRow } from './ODTableTypes';
import clsx from 'clsx';
import { checkboxColumnWidth, settingsColumnWidth } from './ODTableConstants';
import { Checkbox } from 'shared';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import ODTableHeaderCell from './ODTableHeaderCell';

interface ODTableHeadBProps {
    tableKey?: string;
}

const ODTableHead = ({ tableKey }: ODTableHeadBProps) => {
    const {
        checkedRows,
        disableFilters,
        displayRighthandColumn,
        handleClickSettingsIcon,
        interactiveTable,
        radioCheckbox,
        rowsToDelete,
        setCheckedRows,
        showTableSettingsButton,
        table,
        addPagination
    } = useODTable() as ODTableContextInterface & ODTableProviderProps & { table: Table<DataRow> };
    const { getHeaderGroups } = table;
    const classes = useODTableStyles();

    const handleHeaderCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation(); // Prevent the click event from propagating to the Snackbar
        if (e.target.checked && e.target.dataset.indeterminate === 'false') {
            if (addPagination) {
                const allRowIdsOnPage = table
                    .getPaginationRowModel()
                    .rows.map((r) => Number(r.original.id))
                    .filter((id) => !rowsToDelete.includes(id));
                setCheckedRows?.(allRowIdsOnPage);
            } else {
                const allFilteredRowIds = table
                    .getFilteredRowModel()
                    .rows.map((r) => Number(r.original.id));
                setCheckedRows?.(allFilteredRowIds.filter((id) => !rowsToDelete.includes(id)));
            }
        } else {
            setCheckedRows?.([]);
        }
    };

    const undeletedFilteredRowIds = table
        .getFilteredRowModel()
        .rows.map((r) => Number(r.original.id))
        .filter((id) => !rowsToDelete.includes(id));

    const isIndeterminate = () => {
        if (checkedRows.length === 0) return false;
        return checkedRows.length !== undeletedFilteredRowIds.length;
    };

    const isHeaderCheckboxChecked = () => {
        return checkedRows.length === undeletedFilteredRowIds.length;
    };

    const isColumnGroupHeader = (headerGroup: HeaderGroup<DataRow>) =>
        getHeaderGroups().length > 1 && headerGroup.depth === 0;

    return (
        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 11 }}>
            {getHeaderGroups().map((headerGroup: HeaderGroup<DataRow>) => {
                if (isColumnGroupHeader(headerGroup)) {
                    return (
                        <StyledHeaderRow
                            sx={{ height: '40px' }}
                            key={headerGroup.id}
                            id={headerGroup.id}>
                            {interactiveTable && (
                                <HeaderCell
                                    className={clsx(classes.sticky)}
                                    key={'header-checkbox-placeholder'}
                                    isLastStickyColumn={false}
                                    sx={{
                                        left: 0,
                                        paddingLeft: '16px',
                                        paddingRight: 0,
                                        width: `${checkboxColumnWidth}px`
                                    }}></HeaderCell>
                            )}
                            {headerGroup.headers.map((header: Header<DataRow, any>, i) => (
                                <ODTableHeaderCell
                                    {...{ header, headerGroup, i }}
                                    isGroupHeader={true}
                                />
                            ))}
                            {displayRighthandColumn && (
                                <HeaderCell
                                    id={'placeholder-options-cell'}
                                    sx={{
                                        width: `${settingsColumnWidth}px`,
                                        boxSizing: 'border-box',
                                        right: 0,
                                        flex: '0 0 auto',
                                        borderLeft: 'none'
                                    }}
                                    className={clsx(
                                        classes.sticky,
                                        classes.stickyRight
                                    )}></HeaderCell>
                            )}
                        </StyledHeaderRow>
                    );
                } else
                    return (
                        <StyledHeaderRow
                            sx={{ height: !disableFilters ? '86px' : '40px' }}
                            key={headerGroup.id}
                            id={headerGroup.id}>
                            {interactiveTable && (
                                <HeaderCell
                                    className={clsx(classes.sticky)}
                                    key={'header-checkbox'}
                                    isLastStickyColumn={false}
                                    sx={{
                                        left: 0,
                                        paddingLeft: '16px',
                                        paddingRight: 0,
                                        width: `${checkboxColumnWidth}px`
                                    }}>
                                    {!radioCheckbox && (
                                        <>
                                            <Checkbox
                                                indeterminate={isIndeterminate()}
                                                isChecked={isHeaderCheckboxChecked()}
                                                onChecked={(e: any) => handleHeaderCheckbox(e)}
                                                sx={{ margin: 0, padding: 0 }}
                                            />
                                            {!disableFilters && (
                                                <Box
                                                    sx={{
                                                        height: '36px',
                                                        marginTop: '10px'
                                                    }}></Box>
                                            )}
                                        </>
                                    )}
                                </HeaderCell>
                            )}
                            {headerGroup.headers.map((header: Header<DataRow, any>, i) => (
                                <ODTableHeaderCell {...{ header, headerGroup, i }} />
                            ))}
                            {displayRighthandColumn && (
                                <HeaderCell
                                    id={'interactive-table-header-cell'}
                                    sx={{
                                        width: `${settingsColumnWidth}px`,
                                        boxSizing: 'border-box',
                                        right: 0,
                                        flex: '0 0 auto'
                                    }}
                                    className={clsx(classes.sticky, classes.stickyRight)}>
                                    {showTableSettingsButton && (
                                        <>
                                            <Tooltip title="Settings">
                                                <IconButton
                                                    sx={{
                                                        height: '32px',
                                                        width: '32px',
                                                        marginTop: '-6px'
                                                    }}
                                                    onClick={handleClickSettingsIcon}>
                                                    <ViewListIcon
                                                        sx={{
                                                            color: theme.palette.neutral.black
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            {!disableFilters && (
                                                <Box
                                                    sx={{
                                                        height: '36px',
                                                        marginTop: '10px'
                                                    }}></Box>
                                            )}
                                        </>
                                    )}
                                </HeaderCell>
                            )}
                        </StyledHeaderRow>
                    );
            })}
        </TableHead>
    );
};

export default ODTableHead;
