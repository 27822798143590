/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LEFTNAV_OPTION } from 'constants/settings';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
    GetRoles,
    GetReportNotifications,
    updateNetworkView,
    getBuckets,
    GetNewReportNotifications
} from 'redux/actions';
import { RootState } from 'redux/reducers';

import { Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import RequestQuoteSharpIcon from '@mui/icons-material/RequestQuoteSharp';
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import { HelpOutline, LogoutSharp } from '@mui/icons-material';
import { ChevronLeft } from '@mui/icons-material';

import { ODLogoDarkIcon } from 'assets/images';
import { isDraftReportUtil } from 'utils/network.util';
import { settingsUpdate } from 'redux/actions';

import {
    ExpandedMenu,
    ExpandedMenuHeading,
    ExpandedMenuTextHeaders,
    ExpandedMenuIconButton,
    ExpandedMenuTopDivider,
    ExpandedMenuBottomDivider,
    ExpandedMenuItems,
    ExpandedTypoSet,
    ExpandedMenuList,
    CustomizedDrawer,
    LeftNavbarIconButton,
    CollapsedMenu,
    CollapsedMenuLogo,
    CollapsedMenuStack,
    CollapsedMenuDivider,
    CollapsedMenuIconButton,
    LeftNavbarCollapsedMenuItemsContent,
    ExpandedMenuListItem,
    sharedListItemStylesProps
} from 'components/Layout/LeftNavbar.styles';
import { useNetworkManagerHook } from 'utils/hooks';

export default function LeftNavbarNew({
    children
}: {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) {
    const { base_run }: { base_run: any; config_id?: any } = useParams();
    const { fullWidthMapViewTables } = useFlags();
    const [showExpandableArr, setShowExpandableArr] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const { bidding, newNotificationFlow } = useFlags();
    const { organization } = useSelector((state: RootState) => state.SignInReducer);
    const { configuration } = useSelector((state: RootState) => state.ConfigurationReducer);
    const { currSelectedMenuItem } = useSelector((state: RootState) => state.settingsReducer);
    const { logout } = useAuth0();
    const { newSimulationFlow } = useFlags();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { allReports, getNetworkManagerReports } = useNetworkManagerHook();

    const customizeIcon = (Comp: any) => {
        return <Comp sx={{ color: theme.palette.black }} />;
    };

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
    };

    useEffect(() => {
        getNetworkManagerReports();
        dispatch(getBuckets());
    }, []);

    //TODO to be removed after API update. API needs to clean up response to have same format.
    const filtered = allReports.filter((report) => report?.latest_base);

    const latestBaseReport = filtered[0]?.latest_base;
    const viewLatestReport = async () => {
        const url = `/reports/${
            latestBaseReport?.bucket ? `bucket/${latestBaseReport?.bucket}/` : `report-run/`
        }${latestBaseReport?.id}/network/shipper/`;
        history.push(url);
    };

    const leftNavbarMenuItems = [
        {
            name: 'Network View',
            onClick: () => {
                dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_VIEW }));
                viewLatestReport();
            },
            hidden: false,
            icon: customizeIcon(HomeIcon),
            enabled: !!latestBaseReport,
            selected: currSelectedMenuItem === LEFTNAV_OPTION.NETWORK_VIEW
        },
        {
            name: 'Network Manager',
            onClick: () => {
                const redirectFromBidAnalysis =
                    history?.location?.pathname?.includes('bid-analysis');
                if (
                    newSimulationFlow &&
                    isDraftReportUtil(configuration) &&
                    !redirectFromBidAnalysis
                ) {
                    dispatch(
                        updateNetworkView({
                            showSaveDraftModal: { show: true, redirectTo: '/' }
                        })
                    );
                    dispatch(
                        settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_MANAGER })
                    );
                } else {
                    history.push('/');
                    dispatch(
                        settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_MANAGER })
                    );
                }
            },
            hidden: false,
            icon: customizeIcon(FolderSharpIcon),
            enabled: true,
            selected: currSelectedMenuItem === LEFTNAV_OPTION.NETWORK_MANAGER
        },
        {
            name: 'Bid File Manager',
            onClick: () => {
                const redirectFromBidAnalysis =
                    history?.location?.pathname?.includes('bid-analysis');
                if (
                    newSimulationFlow &&
                    isDraftReportUtil(configuration) &&
                    !redirectFromBidAnalysis
                ) {
                    dispatch(
                        updateNetworkView({
                            showSaveDraftModal: { show: true, redirectTo: '/bidding' }
                        })
                    );
                    dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.BID_MANAGER }));
                } else {
                    history.push('/bidding');
                    dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.BID_MANAGER }));
                }
            },
            hidden: !bidding,
            icon: customizeIcon(RequestQuoteSharpIcon),
            enabled: true,
            selected: currSelectedMenuItem === LEFTNAV_OPTION.BID_MANAGER
        }
    ];

    const leftNavbarFooterItems = [
        {
            name: 'Notifications',
            onClick: () => dispatch(updateNetworkView({ showAlertNotification: true })),
            hidden: false,
            icon: customizeIcon(NotificationsSharpIcon),
            enabled: true
        },
        {
            name: 'Help & Feedback',
            icon: (
                <HelpOutline
                    sx={{
                        color: theme.palette.neutral.black
                    }}
                />
            ),
            onClick: () => window.openWidget(),
            hidden: false,
            enabled: true
        }
    ];

    const leftNavbarLogout = [
        {
            name: 'Log out',
            icon: (
                <LogoutSharp
                    sx={{
                        color: theme.palette.neutral.black
                    }}
                />
            ),
            onClick: handleLogout,
            hidden: false,
            enabled: true
        }
    ];

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const sharedListItemStyles = sharedListItemStylesProps();

    const renderCollapsedMenuItems = (arr: any[]) => {
        return arr?.map((item, i) => {
            return item?.hidden ? null : (
                <Tooltip key={i} title={item.name} placement="right">
                    <Box sx={{ margin: '0', cursor: 'pointer' }}>
                        <CollapsedMenuIconButton
                            disabled={!item.enabled}
                            selected={item?.selected}
                            onClick={item.onClick && item.onClick}
                            aria-label={item.name}
                            size="large">
                            {item?.name === 'Notifications' && (
                                <Box
                                    sx={{
                                        ...sharedListItemStyles,
                                        bottom: '28px',
                                        left: '28px'
                                    }}
                                />
                            )}
                            {item.icon}
                        </CollapsedMenuIconButton>
                    </Box>
                </Tooltip>
            );
        });
    };

    const renderExpandedMenuItems = (list: any[]) => {
        return (
            <ExpandedMenuList>
                {list.map((item, i: number) => {
                    if (!item.hidden) {
                        return (
                            <ExpandedMenuListItem
                                isItemSelected={item?.selected}
                                key={`navbar-${item.name}-${i}`}
                                onClick={item.onClick}>
                                {item?.name === 'Notifications' && (
                                    <Box
                                        sx={{
                                            ...sharedListItemStyles,
                                            bottom: '35px',
                                            left: '35px'
                                        }}
                                    />
                                )}
                                {item.icon && item.icon}
                                <Typography
                                    sx={{
                                        color: item?.selected
                                            ? theme.palette.white
                                            : theme.palette.black
                                    }}>
                                    {item.name}
                                </Typography>
                            </ExpandedMenuListItem>
                        );
                    }
                })}
            </ExpandedMenuList>
        );
    };

    const delayMenuExpandBtn = (func: React.Dispatch<React.SetStateAction<boolean>>) => {
        setTimeout(() => {
            func(false);
        }, 4000);
    };

    useEffect(() => {
        if (newNotificationFlow) dispatch(GetNewReportNotifications());
        else dispatch(GetReportNotifications());
        dispatch(GetRoles());
    }, []);

    const setMaxWidth = () => {
        if (openDrawer) {
            if (currSelectedMenuItem === LEFTNAV_OPTION.NETWORK_VIEW) {
                return '100%';
            } else {
                return 'calc(100vw - 275px)';
            }
        } else return '100%';
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <LeftNavbarIconButton
                showExpanded={showExpandableArr || (showExpandableArr && base_run)}
                onMouseOver={() => setShowExpandableArr(true)}
                onMouseLeave={() => delayMenuExpandBtn(setShowExpandableArr)}
                onClick={toggleDrawer}
                aria-label="expand menu"
                size="large">
                <ChevronRightIcon
                    sx={{
                        position: 'fixed',
                        transition: '0.5s ease-in-out',
                        color: theme.palette.black,
                        left: showExpandableArr || (showExpandableArr && base_run) ? '56px' : '0px'
                    }}
                />
            </LeftNavbarIconButton>

            <CustomizedDrawer
                sx={{
                    border: 'none',
                    '&.MuiDrawer-docked': {
                        width: openDrawer ? '280px' : '56px'
                    },
                    '.MuiDrawer-paper': {
                        width: openDrawer ? '280px' : '56px',
                        backgroundColor: 'transparent',
                        borderRight: 'none'
                    }
                }}
                variant="permanent"
                anchor="left"
                open={openDrawer}
                onClose={toggleDrawer}>
                {openDrawer ? (
                    <ExpandedMenu
                        role="section"
                        onClick={toggleDrawer}
                        onKeyDown={toggleDrawer}
                        onMouseOver={() => setShowExpandableArr(true)}
                        onMouseLeave={() => delayMenuExpandBtn(setShowExpandableArr)}>
                        <ExpandedMenuHeading>
                            <ExpandedMenuTextHeaders>
                                <ODLogoDarkIcon />
                                <Box sx={{ marginLeft: '16px' }}>
                                    <ExpandedTypoSet>{organization?.name}</ExpandedTypoSet>
                                    <Typography
                                        sx={{
                                            color: theme.palette.neutral.neutral8,
                                            fontSize: '10px'
                                        }}>
                                        Powered by Optimal Dynamics
                                    </Typography>
                                </Box>
                            </ExpandedMenuTextHeaders>

                            <ExpandedMenuIconButton
                                onClick={toggleDrawer}
                                aria-label="exit out of expanded left navbar"
                                size="large">
                                <ChevronLeft
                                    sx={{
                                        position: 'fixed',
                                        color: theme.palette.black,
                                        left: '263px'
                                    }}
                                />
                            </ExpandedMenuIconButton>
                        </ExpandedMenuHeading>
                        <ExpandedMenuTopDivider />

                        <ExpandedMenuItems>
                            <>{renderExpandedMenuItems(leftNavbarMenuItems)}</>
                            <>{renderExpandedMenuItems(leftNavbarFooterItems)}</>
                        </ExpandedMenuItems>
                        <ExpandedMenuBottomDivider />
                        <>{renderExpandedMenuItems(leftNavbarLogout)}</>
                    </ExpandedMenu>
                ) : (
                    <CollapsedMenu
                        onMouseOver={() => setShowExpandableArr(true)}
                        onMouseLeave={() => delayMenuExpandBtn(setShowExpandableArr)}>
                        <CollapsedMenuStack spacing={1}>
                            <CollapsedMenuLogo>
                                <ODLogoDarkIcon />
                            </CollapsedMenuLogo>
                            <CollapsedMenuDivider />
                            <LeftNavbarCollapsedMenuItemsContent>
                                <Box>{renderCollapsedMenuItems(leftNavbarMenuItems)}</Box>
                                <Box>
                                    <Box sx={{ marginBottom: '8px' }}>
                                        {renderCollapsedMenuItems(leftNavbarFooterItems)}
                                    </Box>
                                    <CollapsedMenuDivider />
                                    {renderCollapsedMenuItems(leftNavbarLogout)}
                                </Box>
                            </LeftNavbarCollapsedMenuItemsContent>
                        </CollapsedMenuStack>
                    </CollapsedMenu>
                )}
            </CustomizedDrawer>

            <Box
                component="main"
                sx={{
                    overflow: 'auto',
                    maxWidth: setMaxWidth(),
                    flexGrow: 1,
                    height: fullWidthMapViewTables ? '100vh' : 'auto'
                }}>
                {children}
            </Box>
        </Box>
    );
}
