import React from 'react';
import { Box, SxProps, Theme, Tooltip } from '@mui/material';

type LabelProps = {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    text: string;
    fontWeight?: string | number;
    sx?: SxProps<Theme>;
    endIconAction?: () => void;
    labelSx?: any;
    tooltipText?: string;
};

const Label = ({
    startIcon,
    endIcon,
    text,
    sx,
    fontWeight = 400,
    labelSx,
    endIconAction = () => {},
    tooltipText
}: LabelProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontWeight: fontWeight,
                fontSize: '14px',
                ...sx
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                {Boolean(startIcon) && (
                    <Box sx={{ marginRight: '16px', display: 'flex', alignItems: 'center' }}>
                        {startIcon}
                    </Box>
                )}
                <Tooltip title={tooltipText ?? text}>
                    <Box sx={{ ...labelSx }}>{text}</Box>
                </Tooltip>
            </Box>

            {Boolean(endIcon) && (
                <Box onClick={endIconAction} sx={{ marginLeft: '16px' }}>
                    {endIcon}
                </Box>
            )}
        </Box>
    );
};

export default Label;
