import React from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table, TableRow, TableBody } from '@mui/material';
import { styled } from '@mui/material/styles';

import palette from 'theme/palette';

/*temporary component for ODTable - need to consolidate later */
/*first item in the table should be selecting all files - data[0] */

const CustomizedCell = styled('td')(() => ({
    padding: 0,
    fontWeight: 600,
    fontSize: '14px'
}));

const RightCornerCell = styled('td')(() => ({
    padding: 0,
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '0 4px 4px 0'
}));

const LeftCornerCell = styled('td')(() => ({
    padding: 0,
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '4px 0 0 4px'
}));

interface TableWithoutHeaderProps {
    data: any;
    columns: any;
    id?: string;
}

const TableWithoutHeader = ({ data, columns, id }: TableWithoutHeaderProps) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel()
    });

    const determineBackgroundColor = (row: { id: string; checked: boolean; groupName: string }) => {
        if (data[0].checked) {
            if (row.id !== 'all') {
                return 'transparent';
            } else {
                return palette.ODLightBlueNeutral.lightBlue1;
            }
        } else {
            if (row.checked) {
                return palette.ODLightBlueNeutral.lightBlue1;
            }
        }
    };

    const renderCellById = (id: any, cell: any) => {
        if (id === 'groupName') {
            return (
                <CustomizedCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </CustomizedCell>
            );
        } else if (id === 'id') {
            return (
                <RightCornerCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </RightCornerCell>
            );
        } else {
            return (
                <LeftCornerCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </LeftCornerCell>
            );
        }
    };

    return (
        <Table
            aria-label={`table-without-header-for-${id}`}
            id={`${id}-table`}
            sx={{
                borderCollapse: 'collapse',
                marginTop: '16px'
            }}>
            <TableBody>
                {table.getRowModel().rows.map((row: any, i) => {
                    return (
                        <TableRow
                            id={`row-for-${row.id}-${i}`}
                            hover
                            key={row.id}
                            sx={{
                                height: '40px',
                                backgroundColor: determineBackgroundColor(row.original),
                                padding: 0,
                                '&.MuiTableRow-hover:hover': {
                                    backgroundColor: palette.ODLightBlueNeutral.lightBlue1
                                },
                                marginBottom: '2px',
                                borderRadius: '4px'
                            }}>
                            {row.getVisibleCells().map((cell: any) => {
                                return renderCellById(cell.column.id, cell);
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default TableWithoutHeader;
