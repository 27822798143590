import React from 'react';
import { Checkbox } from '@mui/material';
import theme from 'theme';
import SquareSharpIcon from '@mui/icons-material/SquareSharp';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';

const CommonCheckboxComponent = ({
    isChecked = false,
    isDisabled = false,
    onChecked,
    onClick,
    color = theme.palette.neutral.white,
    checkedColor = color,
    className = `reports-checkbox`
}) => {
    return (
        <Checkbox
            icon={
                isDisabled ? (
                    <SquareSharpIcon sx={{ color: theme.palette.ODBlue.odBlue2 }} />
                ) : (
                    <CheckBoxOutlineBlankSharpIcon sx={{ color: color }} />
                )
            }
            checkedIcon={
                isDisabled ? (
                    <SquareSharpIcon sx={{ color: theme.palette.ODBlue.odBlue2 }} />
                ) : (
                    <CheckBoxSharpIcon sx={{ color: checkedColor }} />
                )
            }
            className={className}
            style={{
                paddingLeft: 16,
                paddingRight: 16,
                color: isDisabled ? theme.palette.ODBlue.odBlue2 : color
            }}
            checked={isChecked}
            onClick={onChecked || onClick}
            disabled={isDisabled}
        />
    );
};

export default CommonCheckboxComponent;
