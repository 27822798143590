/* eslint-disable import/no-anonymous-default-export */
const set = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));

const remove = (key) => sessionStorage.removeItem(key);

const get = (key) => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
};

const clear = () => sessionStorage.clear();

export default { set, remove, get, clear };
