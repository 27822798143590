/* Note that if the object input into this function contains multiple instances of the same key,
 * the function will use the value in the final instance.
 */
export const flattenObject = (obj) => {
    /* The reason for this type check is that
     * if we ever are to pass an array into this method, the function
     * will convert the array to an object which may be confusing.
     * For example, ['hello', 'world'] => {0: 'hello', 1: 'world'}
     */
    if (typeof obj !== 'object' || Array.isArray(obj)) {
        throw Error('Not an object');
    }

    const flattened = {};

    Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, flattenObject(value));
        } else {
            flattened[key] = value;
        }
    });

    return flattened;
};

export const searchToObject = (search) =>
    search
        .substring(1)
        .split('&')
        .reduce((result, value) => {
            const parts = value.split('=');
            if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
            return result;
        }, {});
