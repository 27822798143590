import React, { useState } from 'react';
import { useODTableStyles, HeaderCell } from './ODTableStyledComponents';
import { Box, Tooltip } from '@mui/material';
import { flexRender, HeaderGroup, Header, Table } from '@tanstack/react-table';
import { FilterRenderer, isNewValueDifferent } from './ODTableUtils';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { CellEdit, CrossColumnCopyDetails, DataRow } from './ODTableTypes';
import CrossColumnCopyHeaderCell from './CrossColumnCopyHeaderCell';
import InfoMessage from './InfoMessage';
import clsx from 'clsx';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import { getAncestorIds } from 'utils/dom.util';
import EditIcon from '@mui/icons-material/Edit';
import RepeatIcon from '@mui/icons-material/Repeat';
import theme from 'theme';
import { Select, MenuItem } from '@mui/material';
import { useFilterStyles } from 'shared/Table/tableFilters/FilterStyles';
import { ANALYSIS_TIME_AGGREGATION_TYPE, TIME_FRAME } from 'constants/settings';
import ODIconButton from 'shared/ODIconButton';
import { BUTTON_TYPE } from 'constants/colors';

const ODTableHeaderCell = ({
    header,
    headerGroup,
    i,
    isGroupHeader = false
}: {
    header: Header<DataRow, any>;
    headerGroup: HeaderGroup<DataRow>;
    i: number;
    isGroupHeader?: boolean;
}) => {
    const {
        cellEditsCallback,
        column_specific_time_aggregation,
        columnFormatSelections,
        columnIdIsValid,
        computeLeftOffset,
        computeRightOffset,
        crossCopyColumns,
        data,
        disableFilters,
        firstRightStickyColumn,
        handleColumnLevelTimeAggregationSelection,
        lastLeftStickyColumn,
        resetFillDown,
        rightStickyColumnsGroupId,
        separatedColumns,
        table,
        updateSorting
    } = useODTable() as ODTableContextInterface & ODTableProviderProps & { table: Table<DataRow> };
    const classes = useODTableStyles();
    const filterClasses = useFilterStyles();
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [timeAggDropdownOpen, setTimeAggDropdownOpen] = useState<boolean>(false);
    const [swapDropdownOpen, setSwapDropdownOpen] = useState<boolean>(false);
    const columnFormatSelection =
        header.column.columnDef.meta?.swapColumns?.category &&
        columnFormatSelections?.[header.column.columnDef.meta?.swapColumns?.category];
    const isCrossColumnCopy = (columnId: string) => {
        return crossCopyColumns?.map((c) => c.destColumnHeaderData.columnId).includes(columnId);
    };

    const handleHeaderClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        header: Header<DataRow, any>
    ) => {
        const ancestorIds = getAncestorIds(e.target as HTMLElement);
        const ancestorIdSet = new Set(ancestorIds);
        if (!['filter', 'header-action', 'menu-'].some((id) => ancestorIdSet.has(id))) {
            updateSorting(header.column);
            table.setPageIndex(0);
        }
    };

    const copyValueBetweenColumns = (sourceColumnId: string, destColumnId: string) => {
        if (columnIdIsValid(sourceColumnId) && columnIdIsValid(destColumnId)) {
            let edits: CellEdit[] = [];
            data.forEach((row: DataRow) => {
                let newValue = null;
                if (destColumnId === 'flat_rate') {
                    newValue = Number(row[sourceColumnId]) * Number(row.mileage);
                } else if (destColumnId === 'rate_per_mile') {
                    newValue =
                        Number(row[sourceColumnId]) > 0 ? row[sourceColumnId] : row[destColumnId];
                } else {
                    newValue = row[sourceColumnId];
                }

                if (isNewValueDifferent(row, destColumnId, newValue)) {
                    edits.push({
                        rowId: row.id as number,
                        columnId: destColumnId,
                        newValue
                    });
                }
            });
            cellEditsCallback?.(edits);
        } else {
            console.warn('Source or destination column are not valid column names');
        }
    };
    const renderSwapColumnDropdown = () => (
        <Box>
            <Select
                open={swapDropdownOpen}
                value={columnFormatSelection}
                onChange={(e) => {
                    header.column.columnDef.meta?.swapColumns?.callback(e.target.value, table);
                }}
                displayEmpty
                style={{ paddingLeft: '8px' }}
                sx={{
                    border: 'none',
                    /* Remove the blue outline */
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                    },
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                        paddingRight: 0
                    }
                }}
                MenuProps={{
                    classes: {
                        paper: clsx(filterClasses.menu, filterClasses.lowerMenu)
                    }
                }}
                inputProps={{ sx: { padding: 0 } }}
                onClick={() => setSwapDropdownOpen(!swapDropdownOpen)}
                id={`${header.column.id}-swap-column-format-dropdown`}
                IconComponent={() => null}
                renderValue={() => (
                    <Box
                        sx={{
                            '&:hover': {
                                color: theme.palette.neutral.neutral8,
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => setSwapDropdownOpen(!swapDropdownOpen)}>
                        <Tooltip title={header.column.columnDef.meta?.swapColumns?.tooltip ?? ''}>
                            <ODIconButton
                                buttonType={BUTTON_TYPE.TRANSPARENT}
                                sx={{
                                    height: '32px',
                                    width: '32px',
                                    ...(swapDropdownOpen
                                        ? { backgroundColor: theme.palette.semantic.focusedBlue }
                                        : {})
                                }}
                                icon={
                                    <RepeatIcon
                                        sx={{
                                            color: swapDropdownOpen
                                                ? theme.palette.white
                                                : theme.palette.black
                                        }}
                                    />
                                }
                            />
                        </Tooltip>
                    </Box>
                )}>
                {header.column.columnDef.meta?.swapColumns?.options.map((option) => (
                    <MenuItem
                        sx={{
                            fontSize: '14px'
                        }}
                        style={option.value === columnFormatSelection ? { display: 'none' } : {}}
                        value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
    const renderTimeAggregationDropdown = () => (
        <Box>
            <Select
                open={timeAggDropdownOpen}
                value={
                    column_specific_time_aggregation?.[
                        header.column.columnDef.meta?.timeAggGroupKey as string
                    ]
                }
                onChange={(e) => {
                    handleColumnLevelTimeAggregationSelection?.(
                        header.column.columnDef.meta?.timeAggGroupKey as string,
                        e.target.value as TIME_FRAME
                    );
                }}
                displayEmpty
                style={{ paddingLeft: '8px' }}
                sx={{
                    border: 'none',
                    /* Remove the blue outline */
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                    },
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                        paddingRight: 0
                    }
                }}
                MenuProps={{
                    classes: {
                        paper: filterClasses.menu
                    }
                }}
                inputProps={{ sx: { padding: 0 } }}
                onClick={() => setTimeAggDropdownOpen(!timeAggDropdownOpen)}
                id={`${header.column.id}-time-aggregation-filter`}
                IconComponent={() => null}
                renderValue={() => (
                    <Box
                        sx={{
                            '&:hover': {
                                color: theme.palette.neutral.neutral8,
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => setTimeAggDropdownOpen(!timeAggDropdownOpen)}>
                        <Tooltip title="Edit Time Aggregation">
                            <EditIcon style={{ height: '18px', width: '18px' }} />
                        </Tooltip>
                    </Box>
                )}>
                {ANALYSIS_TIME_AGGREGATION_TYPE.map((type) => (
                    <MenuItem sx={{ fontSize: '14px' }} value={type.value}>
                        {type.label}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );

    return (
        <HeaderCell
            separateColumn={separatedColumns?.includes(header.column.id)}
            key={header.id}
            id={header.id}
            header={header}
            {...{ isGroupHeader }}
            isLastStickyColumn={header.column.id === lastLeftStickyColumn}
            isFirstScrollableColumn={headerGroup.headers[i - 1]?.column.id === lastLeftStickyColumn}
            isFirstRightStickyColumn={
                headerGroup.headers[i]?.column.id === firstRightStickyColumn ||
                headerGroup.headers[i]?.column.id === rightStickyColumnsGroupId
            }
            isFirstDataColumn={i === 0}
            className={clsx(Boolean(header.column.columnDef.meta?.sticky) && classes.sticky)}
            sx={{
                ...(['left', undefined].includes(header.column.columnDef.meta?.sticky)
                    ? { left: computeLeftOffset(header.column) }
                    : { right: computeRightOffset(header.column) }),
                flex: '1 0 auto',
                display: 'flex',
                flexDirection: 'column'
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minHeight: '20px'
                }}>
                <Box
                    sx={{ ...(header.column.getCanSort() ? { cursor: 'pointer' } : {}) }}
                    onClick={(e) => handleHeaderClick(e, header)}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                </Box>
                {Boolean(header.column.columnDef.meta?.infoMessage) && (
                    <InfoMessage message={header.column.columnDef.meta?.infoMessage as string} />
                )}
                {Boolean(header.column.columnDef.meta?.timeAggregationDropdown) &&
                    renderTimeAggregationDropdown()}
                {Boolean(header.column.columnDef.meta?.swapColumns) && renderSwapColumnDropdown()}
                {Boolean(isCrossColumnCopy(header.column.id)) && (
                    <CrossColumnCopyHeaderCell
                        crossColumnCopyDetails={
                            crossCopyColumns?.find(
                                (c) => c.destColumnHeaderData.columnId === header.column.id
                            ) as CrossColumnCopyDetails
                        }
                        header={header}
                        copyValueBetweenColumns={copyValueBetweenColumns}
                        resetFillDown={resetFillDown}
                    />
                )}
                {header.column.getCanSort() &&
                    (Boolean(header.column.getIsSorted()) || isHovered) && (
                        <Box
                            sx={{
                                marginLeft: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: Boolean(header.column.getIsSorted())
                                    ? theme.palette.black
                                    : theme.palette.neutral.neutral6
                            }}
                            onClick={(e) => handleHeaderClick(e, header)}>
                            {header.column.getIsSorted() === 'desc' ? (
                                <ArrowDownward />
                            ) : (
                                <ArrowUpward />
                            )}
                        </Box>
                    )}
            </Box>
            {!disableFilters &&
                (header.column.columnDef.meta?.filterType ? (
                    <FilterRenderer column={header.column} />
                ) : (
                    <Box
                        sx={{
                            height: '36px',
                            marginTop: '10px'
                        }}></Box>
                ))}
        </HeaderCell>
    );
};

export default ODTableHeaderCell;
