export const addZeroIfSingleDigit = (num) => {
    return num < 10 ? '0' + num : num;
};

export const formatValue = (value) => {
    return value !== 'NA' && !Number.isInteger(value)
        ? Math.round(value * 100) / 100
        : value || 'NA';
};

export const formatNumToDollar = (num) => {
    if (typeof num !== 'number') return '-';
    let formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    return formatted.format(num);
};

export const formatTwoDecimals = (value) => {
    if (value === undefined || value === null) return '—';
    if (value !== 0) {
        return parseFloat(Number(value).toFixed(2));
    } else {
        return value;
    }
};

export const formatDelta = (delta, type, abb = null, decimals) => {
    if (!delta) return '0';
    if (typeof delta !== 'number') return '-';
    const sign = delta < 0 ? '-' : '+';

    const absDelta = Math.abs(delta);

    if (type === 'percentage') {
        return `${sign}${absDelta.toFixed(1)}%` || '0';
    } else if (type === 'dollar') {
        let formattedRate;

        if (abb) {
            formattedRate = abbreviateNumber(absDelta);
        } else {
            let rounded = absDelta?.toFixed(2);
            formattedRate = convertNumberToCommas(rounded);
        }

        return `${sign}$${formattedRate}` || '0';
    } else {
        return `${sign}${convertNumberToCommas(absDelta?.toFixed(decimals ?? 0))}` || '0';
    }
};

export const getDelta = (rate, count, type, isAbbreviated = null) => {
    rate = rate - count;
    return `(${formatDelta(rate, type, isAbbreviated, null)})`;
};

export const convertNumberToCommas = (num) => {
    let number = Number(num);
    if (Number.isNaN(number)) return 'NA';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const insertCommas = (str) => str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const addCommaSeparators = (inputString) => {
    const parts = inputString.split('.');
    const integerPart = parts[0];
    const fractionalPart = parts.length > 1 ? `.${parts[1]}` : '';
    const integerWithCommas = insertCommas(integerPart);
    const formattedString = integerWithCommas + fractionalPart;
    return formattedString;
};

//source: https://github.com/TanStack/react-table/issues/1793
//use it to sort both negative and positive numbers.
export const compareNumericString = (rowA, rowB, id, desc) => {
    let a = Number.parseFloat(rowA.values[id]);
    let b = Number.parseFloat(rowB.values[id]);
    if (Number.isNaN(a)) {
        // Blanks and non-numeric strings to bottom
        a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
        b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
};

export const abbreviateNumber = (num, decimalToShow) => {
    try {
        if (num === 0 || !num || num == null) {
            return 0;
        }

        decimalToShow = !decimalToShow || decimalToShow < 0 ? 0 : decimalToShow;
        let b = num.toPrecision(2).split('e'), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c =
                k < 1
                    ? num.toFixed(0 + decimalToShow)
                    : (num / Math.pow(10, k * 3)).toFixed(1 + decimalToShow), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            abbreviated = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return abbreviated;
    } catch (error) {
        console.error('could not abbreviate number. num =' + num);
        // ! Wrapping this method in a try catch clause is a stopgap fix for ODST-1527.
        // ! Probably a good idea to have this error handling regardless.
    }
};

export function abbreviateNumberNew(number) {
    if (isNaN(number)) return '-';
    if (number === 0) return 0;
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    let suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
    if (suffixIndex < 0) suffixIndex = 0;
    const abbreviatedNumber = number / Math.pow(1000, suffixIndex);
    let decimalDigits = 2;

    if (suffixIndex !== 0) {
        decimalDigits = Math.max(
            decimalDigits - Math.floor(Math.log10(Math.abs(abbreviatedNumber))),
            0
        );
    }

    let formattedNumber = abbreviatedNumber.toFixed(decimalDigits);
    if (suffixIndex !== 0) formattedNumber = parseFloat(formattedNumber);

    return formattedNumber + suffixes[suffixIndex];
}

export const convertTo5Zip = (zip) => (zip < 10000 ? ('0000' + zip).slice(-5) : zip);

export const roundFieldsToInt = (lane, fieldsToRound) => {
    fieldsToRound.forEach((field) => {
        if (lane[field] !== undefined && lane[field] !== null)
            lane[field] = Math.round(Number(lane[field]));
    });
};

export const getValueWithSignAndUnits = (value, units) => `${value > 0 ? '+' : ''}${value}${units}`;
