import { Box, Tooltip } from '@mui/material';
import { LocationWrapper } from 'components/Modals/Modals.styles';
import { STATE_ABBREVIATIONS_NAME_TABLE } from './data/locationNamesData';
import { HISTORY_CHANGES_TYPES } from 'constants/network';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { LOCATION_VIEW } from 'constants/settings';

export const getLocation = (
    value: string | number,
    reportLocations: any,
    locationVisualization: any,
    bidLocations?: any
) => {
    const defaultValue = '—';
    const locationValue = bidLocations?.[value] || reportLocations?.[value];
    if (locationValue) {
        if (locationVisualization === 'city-state-zip')
            return `${locationValue.city}, ${locationValue.state} | ${value}`;
        if (locationVisualization === 'city-state')
            return `${locationValue.city}, ${locationValue.state}`;
    }
    return value || defaultValue;
};

export enum VALIDATION_RULE {
    NONNEGATIVE_FLOAT = 'nonnegativeFloat',
    NONNEGATIVE_INTEGER = 'nonnegativeInteger',
    ANY = 'any'
}

export const inputValid = (validationRule: VALIDATION_RULE, value: string) => {
    switch (validationRule) {
        case VALIDATION_RULE.NONNEGATIVE_FLOAT:
            return /^\d*[.]?\d{0,2}$/.test(value);
        case VALIDATION_RULE.NONNEGATIVE_INTEGER:
            return /^\d+$/.test(value);
        case VALIDATION_RULE.ANY:
            return true;
        default:
            return true;
    }
};

export const convertBlobToDownload = (
    blob: Blob,
    fileName: string | undefined,
    fileType: string
) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${fileName}.${fileType}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const getKeyByObjectValue = (object: any, value: any) =>
    Object.keys(object).find((key) => object[key] === value);

export const formatCityState = (
    value: any,
    width: string,
    zipCode?: boolean,
    reportLocations?: any,
    locationVisualization?: any
) => {
    let city = '';
    let state = '';

    if (typeof value !== 'string') {
        city = value?.place;
        state = (getKeyByObjectValue(STATE_ABBREVIATIONS_NAME_TABLE, value?.region) || '') as any;
    } else {
        if (zipCode) {
            if (
                locationVisualization === 'city-state' &&
                reportLocations &&
                reportLocations[value]
            ) {
                city = reportLocations[value]?.city;
                state = reportLocations[value]?.state;
            } else {
                return value || 'NA';
            }
        } else {
            const valueArray = value?.split(',');
            city = valueArray[0];
            state = valueArray[1];
        }
    }

    return (
        <>
            <Box
                component="span"
                sx={{
                    maxWidth: width,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>
                {city}
            </Box>
            <Box component="span">, {state}</Box>
        </>
    );
};

export const handleFiltered = (e: any, id: string | number, filtered: (string | number)[]) => {
    if (e.target.checked) {
        filtered.push(id);
    } else {
        let index = filtered.indexOf(id);
        while (index >= 0) {
            if (index >= 0) filtered.splice(index, 1);
            index = filtered.indexOf(id);
        }
    }
};

export const hasDuplicates = (reportRun: any, allReports: any, newName: string) => {
    return allReports.some((obj: any) => obj.name === newName && obj.id !== reportRun.id);
};

export const stripBaseReportName = (name: string) => name?.slice(name?.indexOf('-') + 1).trim();

export const formatZipcodeToCityState = (
    zip: string,
    reportLocations: any[],
    bidConfigLocations: any[],
    locationVisualization: any[],
    divider?: any,
    leftWrapperSx?: any,
    rightWrapperSx?: any
) => {
    const [originZip, destZip] = zip?.split('-');
    const transformedLocation = (zip: string) =>
        formatCityState(zip, '80px', true, reportLocations, locationVisualization);

    return (
        <Box display="flex">
            <LocationWrapper
                sx={leftWrapperSx ? leftWrapperSx : { width: 'initial', paddingRight: '4px' }}>
                <Tooltip
                    title={getLocation(
                        originZip,
                        reportLocations,
                        'city-state-zip',
                        bidConfigLocations
                    )}>
                    <Box>{transformedLocation(originZip)}</Box>
                </Tooltip>
            </LocationWrapper>
            {divider ? divider : '-'}
            <LocationWrapper
                sx={rightWrapperSx ? rightWrapperSx : { width: 'initial', paddingLeft: '4px' }}>
                <Tooltip
                    title={getLocation(
                        destZip,
                        reportLocations,
                        'city-state-zip',
                        bidConfigLocations
                    )}>
                    <Box>{transformedLocation(destZip)}</Box>
                </Tooltip>
            </LocationWrapper>
        </Box>
    );
};

export const mapColumnIdActionType = (type: string, actionType = false) => {
    if (actionType) {
        switch (type) {
            case HISTORY_CHANGES_TYPES.DECISION:
                return 'recommendation';
            case HISTORY_CHANGES_TYPES.VOLUME:
                return 'volume_to_accept';
            case HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE:
                return 'rate_per_mile';
            case HISTORY_CHANGES_TYPES.CHANGE_BID_FLAT_RATE:
                return 'flat_rate';
        }
    } else {
        switch (type) {
            case 'recommendation':
                return HISTORY_CHANGES_TYPES.DECISION;
            case 'volume_to_accept':
                return HISTORY_CHANGES_TYPES.VOLUME;
            case 'rate_per_mile':
                return HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE;
            case 'flat_rate':
                return HISTORY_CHANGES_TYPES.CHANGE_BID_FLAT_RATE;
        }
    }
};

export const getLaneOriginDestinationString = (lane: DataRow) =>
    `${lane.origin}-${lane.destination}`;

export const isRateOrVolModified = (row: DataRow, columnId: string) => {
    if (columnId === 'rate_per_mile') {
        return Number(row?.rate_per_mile) !== Number(row?.original_rate_per_mile);
    } else if (columnId === 'flat_rate') {
        return Number(row?.flat_rate) !== Number(row?.original_flat_rate);
    } else if (columnId === 'volume_to_accept') {
        return Number(row?.volume_to_accept) !== Number(row?.original_volume_to_accept);
    } else return false;
};

export const processLocationData = (
    lane: DataRow,
    bidConfigSetting: any,
    odpt407934Zip: boolean,
    bidConfigLocations?: any
) => {
    if (
        lane?.origin_city &&
        lane?.origin_state &&
        lane?.destination_city &&
        lane?.destination_state
    ) {
        lane.originCityStateZip = `${lane?.origin_city}, ${lane?.origin_state} | ${lane?.origin_zip}`;

        lane.originCityState = `${lane?.origin_city}, ${lane?.origin_state}`;

        lane.destinationCityStateZip = `${lane?.destination_city}, ${lane?.destination_state} | ${lane?.destination_zip}`;

        lane.destinationCityState = `${lane?.destination_city}, ${lane?.destination_state}`;
    } else if (bidConfigLocations) {
        lane.originCityStateZip = getLocation(
            lane.origin_zip as string,
            bidConfigLocations,
            'city-state-zip'
        );

        lane.destinationCityStateZip = getLocation(
            lane.destination_zip as string,
            bidConfigLocations,
            'city-state-zip'
        );
        lane.originCityState = getLocation(
            lane.origin_zip as string,
            bidConfigLocations,
            'city-state'
        );
        lane.destinationCityState = getLocation(
            lane.destination_zip as string,
            bidConfigLocations,
            'city-state'
        );
    }
    if (odpt407934Zip) {
        lane.origin =
            bidConfigSetting.time_aggregation === LOCATION_VIEW.CITY_STATE
                ? lane.originCityState
                : bidConfigSetting.zip_code_aggregation === 5
                ? lane.origin_zip
                : (lane?.origin_zip as string)?.slice(0, 3);

        lane.destination =
            bidConfigSetting.time_aggregation === LOCATION_VIEW.CITY_STATE
                ? lane.destinationCityState
                : bidConfigSetting.zip_code_aggregation === 5
                ? lane.destination_zip
                : (lane?.destination_zip as string)?.slice(0, 3);
    } else {
        lane.origin = lane.origin_zip;
        lane.destination = lane.destination_zip;
    }
};

export const isDuplicateBidAnalysisRunName = (bidAnalysisRuns: any, runName: string) => {
    for (const run in bidAnalysisRuns) {
        const currentRun = bidAnalysisRuns[run];
        if (
            currentRun.bidAnalysisRunName.toLowerCase() === runName.toLowerCase() ||
            currentRun.finalizedBidAnalysisRunName.toLowerCase() === runName.toLowerCase()
        ) {
            return true;
        }
    }
    return false;
};

export const isUniqueBidAnalysisRunName = (bidAnalysisRuns: any, runName: string) => {
    let count = 0;
    if (!runName) return count;
    for (const run in bidAnalysisRuns) {
        const currentRun = bidAnalysisRuns[run];
        if (
            currentRun.bidAnalysisRunName.toLowerCase() === runName.toLowerCase() ||
            currentRun.finalizedBidAnalysisRunName.toLowerCase() === runName.toLowerCase()
        ) {
            count++;
        }
    }
    return count;
};

export const isNullRatePerMile = (minRate: number, adjustments: any) => {
    let minAdjRate;
    if (adjustments.type === 'percentage') {
        minAdjRate = minRate + (minRate * Number(adjustments.value)) / 100;
    } else minAdjRate = minRate + Number(adjustments.value);
    return minAdjRate <= 0;
};
