export const decodeToken = (token: string) => {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        const decoded = JSON.parse(jsonPayload);
        return {
            userId: decoded['sub'],
            userEmail: decoded['https://core-ai.io/user/email'],
            organizationId: decoded['https://core-ai.io/connection']
        };
    } catch (error) {
        throw new Error('Invalid token');
    }
};
