import {
    SIGNIN_START,
    SIGNIN_FAIL,
    SIGNIN_SUCCESS,
    SIGNOUT,
    USER_ROLES,
    SIGNIN_CLEAR,
    TOKEN_EXPIRY,
    ORGANIZATION,
    LAUNCH_DARKLY_USER_IDENTIFIED
} from '../constants/actionTypes';
import cookies from 'utils/cookies';
import API from 'utils/axios';
import { env } from 'env';

export const SignInStart = () => {
    return {
        type: SIGNIN_START
    };
};

export const SignInClear = () => {
    return {
        type: SIGNIN_CLEAR
    };
};

export const SignInSuccess = ({ token, error }) => {
    return {
        type: SIGNIN_SUCCESS,
        token,
        error
    };
};

export const SetRoles = ({ roles }) => {
    return {
        type: USER_ROLES,
        roles
    };
};

export const SetOrganization = (organization) => {
    return {
        type: ORGANIZATION,
        ...organization
    };
};

export const SignInFail = ({ error }) => {
    return {
        type: SIGNIN_FAIL,
        error
    };
};

export const SignOut = () => {
    return (dispatch) => {
        dispatch({ type: SIGNOUT });
    };
};

export const onTokenExpiry = () => {
    cookies.remove('token');
    return (dispatch) => {
        dispatch({ type: TOKEN_EXPIRY });
    };
};

export const getOrganization = () => {
    return async (dispatch) => {
        try {
            const subDomain = window.location.host.includes('localhost')
                ? env.REACT_APP_CONNECTION
                : window.location.host.split('.')[0];

            const { data } = await API.get(`/organizations/${subDomain}/`);
            dispatch(SetOrganization(data));
        } catch (err) {}
    };
};

export const GetRoles = () => {
    return async (dispatch) => {
        try {
            const {
                data: { roles }
            } = await API.get('/user-roles/');
            dispatch(SetRoles({ roles }));
        } catch (err) {}
    };
};

export const SignIn = (username, password) => {
    return async (dispatch) => {
        dispatch(SignInStart());
        try {
            const res = await API.post('/token/', { username, password });
            cookies.set('token', res.data.token);
            dispatch(SignInSuccess({ token: res.data.token, error: null }));
        } catch (err) {
            if (err && err.response) {
                return dispatch(
                    SignInFail({
                        error: 'Unable to log in with the provided credentials'
                    })
                );
            }
            dispatch(SignInFail({ error: 'Error with login endpoint' }));
        }
    };
};

export const setLaunchDarklyUserIdentified = (isIdentified) => ({
    type: LAUNCH_DARKLY_USER_IDENTIFIED,
    isIdentified
});
