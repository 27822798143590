/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'redux/reducers';
import { REPORT_FILE_TYPE } from 'constants/reports';
import { REPORT_STATUS } from 'constants/network';
import { onGetReportsSuccess } from 'redux/actions/reportActions';
import API from 'utils/axios';
import EnvConfig from 'config/config';
import { updateNetworkView } from 'redux/actions';

const useNetworkManagerHook = () => {
    const dispatch = useDispatch();
    const [allReports, setAllReports] = useState<Array<any>>([]);
    const [buckets, setBuckets] = useState<Array<any>>([]);
    const [action, setAction] = useState<string | null>(null);
    const [reportRun, setReportRun] = useState<string | null>(null);
    const [time, setTime] = useState(0);
    const { tempOpenHistory } = useSelector((state: RootState) => state.NetworkReducer);
    const { newSimulationFlow, historicalBaseFiles } = useFlags();

    const [drafts, setDrafts] = useState<Array<any>>([]);
    const [all, setAll] = useState<Array<any>>([]);
    const [archived, setArchived] = useState<Array<any>>([]);
    const [disableBucketCall, setDisabledBucketCall] = useState<boolean>(false);

    const getDrafts = async () => {
        dispatch(updateNetworkView({ loadingGetDrafts: true }));
        const {
            data: { results }
        } = await API.get(`/reports/draft-configurations/`);

        const draftReports = (results || []).map((report: any) => ({
            ...report,
            ...report.starting_report_run,
            config_id: report.id,
            name: newSimulationFlow
                ? report.name
                : report.starting_report_run.name + ' ' + report.name,
            baseReportName: report.starting_report_run.name,
            report_status: REPORT_STATUS.DRAFT,
            read_only: false,
            created_at: report.created_at,
            updated_at: report.updated_at,
            isBidAnalysis:
                report?.bid_configs?.length > 0 ||
                report?.starting_report_run?.file_type?.toLowerCase() === REPORT_FILE_TYPE.BID
        }));
        dispatch(updateNetworkView({ loadingGetDrafts: false }));
        return draftReports;
    };

    const getAllReports = async () => {
        dispatch(updateNetworkView({ loadingGetAllReports: true }));
        const { data } = await API.get(`/reports/report-runs/`);
        dispatch(updateNetworkView({ loadingGetAllReports: false }));
        return data?.results
            .filter((report: any) => report?.report_status !== 'preparing')
            .map((report: any) => ({
                ...report,
                name: report?.name,
                baseReportName: report?.parent_name
            }));
    };

    const getArchived = async () => {
        dispatch(updateNetworkView({ loadingGetArchived: true }));
        const { data: archived } = await API.get(`/reports/report-runs/?filter=archive`);
        dispatch(updateNetworkView({ loadingGetArchived: false }));
        return archived?.results.filter((report: any) => report?.report_status !== 'preparing');
    };

    const getNetworkManagerReports = async (reset = false, disableBucketCall = false) => {
        if (reset) {
            setAction(null);
            setReportRun(null);
        }

        const draftReports = await getDrafts();
        const filteredResult = await getAllReports();
        const filteredArchived = await getArchived();

        setArchived(filteredArchived);
        setAll(filteredResult);
        setDrafts(draftReports);

        setDisabledBucketCall(disableBucketCall);
    };

    const setNetworkManagerReports = (disableBucketCall = false) => {
        //TODO keep it for any changes by product
        // const baseReports = filteredResult.filter(
        //     (report: any) => report?.file_type.toLowerCase() === REPORT_FILE_TYPE.BASE
        // );
        // const latestBaseReport = baseReports?.find((report: any) => {
        //     return report?.latest_base?.id === report?.id;
        // });
        // const incompletedReports = filteredResult.filter(
        //     (report: any) =>
        //         ![
        //             REPORT_STATUS.COMPLETED,
        //             REPORT_STATUS.VFA_COMPLETED,
        //             REPORT_STATUS.RETRAINED
        //         ].includes(report?.report_status)
        // );
        // const completedBidAnalyses = filteredResult.filter(
        //     (report: any) =>
        //         [
        //             REPORT_STATUS.COMPLETED,
        //             REPORT_STATUS.VFA_COMPLETED,
        //             REPORT_STATUS.RETRAINED
        //         ].includes(report?.report_status) &&
        //         report?.file_type.toLowerCase() === REPORT_FILE_TYPE.BID
        // );

        //! show archived when historical base files FF is off (ODPT-4513)
        const reportsByHistoricalFF = historicalBaseFiles ? [] : [...archived];

        //TODO need to include newBaseReportFlow

        let networkManagerReports = tempOpenHistory
            ? sortBy([...archived], 'id')
            : sortBy([...all, ...drafts, ...reportsByHistoricalFF], 'id');

        const bucketInfo = buckets.reduce((store: any, { id, name }) => {
            store[id.toString()] = name;
            return store;
        }, {});

        const checkedRecordsId = (allReports || [])
            .filter(({ isChecked }) => isChecked)
            .map(({ id, name }) => `${id}.${name}`);

        networkManagerReports = networkManagerReports.map((report: any) => {
            if (report.bucket) report.bucketName = bucketInfo[report.bucket.toString()];
            if (allReports.length > 0)
                report.isChecked = checkedRecordsId.includes(`${report.id}.${report.name}`);
            return report;
        });

        if (!disableBucketCall) {
            setBuckets(buckets);
        }

        dispatch(onGetReportsSuccess({ reports: [...networkManagerReports] }));
        setAllReports(networkManagerReports);
    };

    useEffect(() => {
        setNetworkManagerReports(disableBucketCall);
    }, [archived, all, drafts]);

    useEffect(() => {
        if (time === 0) return;
        getNetworkManagerReports();

        const timer = setTimeout(
            () => setTime(time + 1),
            EnvConfig.getConfig().runsDetailAPIInterval
        );

        return () => clearTimeout(timer);
    }, [time]);

    useEffect(() => {
        setNetworkManagerReports(disableBucketCall);
    }, [tempOpenHistory, disableBucketCall]);

    useEffect(() => {
        if (buckets.length === 0) return;
        if (allReports.length === 0) setTime(time + 1);
    }, [allReports.length, buckets.length]);

    return {
        getNetworkManagerReports,
        buckets,
        setBuckets,
        action,
        setAction,
        reportRun,
        setReportRun,
        allReports,
        setAllReports,
        getAllReports,
        getDrafts,
        getArchived
    };
};

export default useNetworkManagerHook;
