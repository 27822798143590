import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, InputLabel, FormHelperText } from '@mui/material';

const useStyles = (props) =>
    makeStyles((theme) => ({
        label: {
            '&.MuiInputLabel-root': {
                fontFamily: 'IBM Plex Sans',
                fontSize: '12px',
                fontWeight: 400,
                color: !props.dark
                    ? theme.palette.neutral.neutral6
                    : theme.palette.neutral.neutral3,
                textAlign: 'left',
                '&.disabled': {
                    color: !props.dark
                        ? theme.palette.neutral.neutral4
                        : theme.palette.neutral.neutral3
                },
                '&.error': {
                    color: theme.palette.semantic.semanticRed
                }
            }
        },
        input: {
            marginTop: '4px',
            width: '100%',
            '& fieldset,& .MuiInput-underline:after,& .MuiInput-underline:before,& .MuiInput-underline:hover:not(.Mui-disabled):before':
                {
                    border: 0,
                    outline: 0
                },
            '& .MuiFilledInput-underline:after,& .MuiFilledInput-underline:before,& .MuiFilledInput-underline:hover:not(.Mui-disabled):before':
                {
                    border: 0,
                    outline: 0
                },
            '& input': {
                padding: 0,
                margin: 0
            },
            '& .MuiInputBase-root': {
                padding: '0px 16px',
                fontFamily: 'IBM Plex Sans',
                fontSize: '14px',
                fontWeight: 400,
                color: !props.dark
                    ? theme.palette.neutral.neutral6
                    : theme.palette.neutral.neutral3,
                height: '40px',
                borderBottom:
                    props.variant !== 'outlined' &&
                    `1px solid ${
                        !props.dark ? theme.palette.neutral.neutral2 : theme.palette.ODBlue.odBlue8
                    }`,
                border:
                    props.variant === 'outlined' &&
                    `1px solid ${
                        !props.dark ? theme.palette.neutral.neutral2 : theme.palette.ODBlue.odBlue8
                    }`,
                backgroundColor:
                    props.variant === 'filled' ? theme.palette.neutral.neutral1 : 'transparent',
                '& svg': {
                    width: '18px',
                    height: '18px',
                    fill: !props.dark
                        ? theme.palette.neutral.neutral6
                        : theme.palette.neutral.neutral3
                },
                '& .MuiInputBase-input': {
                    textAlign: props.textAlign || 'left'
                }
            },
            '& .MuiInputBase-root:hover,& .MuiInputBase-root:active,& .MuiInputBase-root.Mui-focused,& .MuiInputBase-root:focus,&.error .MuiInputBase-root':
                {
                    backgroundColor: !props.dark
                        ? theme.palette.neutral.neutral1
                        : theme.palette.ODBlue.odBlue9
                },
            '& .MuiInputBase-root:active': {
                borderColor: !props.dark ? theme.palette.black : theme.palette.white,
                color: !props.dark ? theme.palette.black : theme.palette.white,
                '& svg': {
                    fill: !props.dark ? theme.palette.black : theme.palette.white
                }
            },
            '& .MuiInputBase-root.Mui-focused,& .MuiInputBase-root:focus': {
                borderColor: theme.palette.semantic.focusedBlue,
                color: !props.dark ? theme.palette.black : theme.palette.white,
                '& svg': {
                    fill: !props.dark ? theme.palette.black : theme.palette.white
                }
            },
            '&.error .MuiInputBase-root': {
                color: theme.palette.semantic.semanticRed,
                borderColor: theme.palette.semantic.semanticRed,
                '& svg': {
                    fill: theme.palette.semantic.semanticRed
                }
            },
            '&.disabled .MuiInputBase-root': {
                color: theme.palette.neutral.neutral6,
                backgroundColor: theme.palette.neutral.neutral4,
                '& svg': {
                    fill: theme.palette.neutral.neutral6
                }
            }
        }
    }));

export default function CommonInput(props) {
    const { dark, variant, label, helperText, error, disabled, startIcon, endIcon, textAlign } =
        props;
    const classes = useStyles({ dark, variant, textAlign })();

    return (
        <React.Fragment>
            <InputLabel className={clsx(classes.label, disabled && 'disabled')}>{label}</InputLabel>
            <TextField
                {...props}
                label=""
                className={clsx(classes.input, error && 'error', disabled && 'disabled')}
                InputProps={{
                    endAdornment: endIcon,
                    startAdornment: startIcon
                }}
            />
            <FormHelperText
                className={clsx(classes.label, error && 'error', disabled && 'disabled')}>
                {helperText}
            </FormHelperText>
        </React.Fragment>
    );
}
