import { RootState } from 'types/redux';
import { useSelector } from 'react-redux';
import { NETWORK_TABLE_TYPE } from 'constants/network';
import { useParams } from 'react-router-dom';
import LoadsTablesContainer from './LoadsTablesContainer';
import Driver from '../Driver/Driver';
import { useParamsProps } from 'types/hooks';

interface NetworkTablesSelectorProps {
    currReport: any; // TODO We may want to create a type for reports
}
const NetworkTablesSelector = ({ currReport }: NetworkTablesSelectorProps) => {
    const { compare_run } = useParams<useParamsProps>();
    const { currTab } = useSelector((state: RootState) => state.NetworkReducer);

    switch (currTab) {
        case NETWORK_TABLE_TYPE.SHIPPERS:
        case NETWORK_TABLE_TYPE.LANES:
            return (
                <LoadsTablesContainer
                    /* The dynamic key parameter is important here. Toggling compare_run or switching between the shippers
                     * and lanes tab forces a key change, in effect destroying and recreating the NetworkTablesComponent.
                     * This by extension destroys and recreates all children including the react table instance.
                     * This dynamic key is necessary because without it, the table filters contain stale data and do not render properly when a user either toggles the compare mode
                     * or switches between the shippers and lanes tabs.
                     */
                    key={`${currTab}-${compare_run === undefined ? 'no' : ''}-compare`}
                    currReport={currReport}
                />
            );
        case NETWORK_TABLE_TYPE.DRIVERS:
            return <Driver currReport={currReport} />;
        default:
            return;
    }
};

export default NetworkTablesSelector;
