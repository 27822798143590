import { Box, Input } from '@mui/material';
import { FilterV8Props } from './FilterV8Props';
import { useState, useEffect } from 'react';
import { useFilterStyles, ClearButton, FooterLabel } from './FilterStyles';
import clsx from 'clsx';
import theme from 'theme';
import {
    useODTable,
    ODTableContextInterface,
    ODTableProviderProps
} from 'shared/ODTable/ODTableContext';
import { Table } from '@tanstack/react-table';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { formatTwoDecimals } from 'utils/number.util';
import { isEmpty } from './filterUtils';

const NumberRangeFilterV8 = ({ column }: FilterV8Props) => {
    const { table } = useODTable() as ODTableContextInterface &
        ODTableProviderProps & { table: Table<DataRow> };
    const [focused, setFocused] = useState('');
    const [hovered, setHovered] = useState('');
    const columnFilterValue = column.getFilterValue() as undefined | [number, number];
    const [facetedMin, setFacetedMin] = useState<number>(-Infinity);
    const [facetedMax, setFacetedMax] = useState<number>(Infinity);
    const classes = useFilterStyles();

    useEffect(() => {
        const facetedMinMax = column.getFacetedMinMaxValues();
        setFacetedMin(formatTwoDecimals(facetedMinMax?.[0]) ?? -Infinity);
        setFacetedMax(formatTwoDecimals(facetedMinMax?.[1]) ?? Infinity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column.getFacetedMinMaxValues()]);

    const onUpdate = (count: number | string | null, type: string) => {
        let newFilterValue;
        if (type === 'min') {
            if (count === '') newFilterValue = [null, columnFilterValue?.[1]];
            else newFilterValue = [Number(count), columnFilterValue?.[1]];
        } else if (type === 'max') {
            if (count === '') newFilterValue = [columnFilterValue?.[0], null];
            else newFilterValue = [columnFilterValue?.[0], Number(count)];
        }
        column.setFilterValue(newFilterValue);
        table.setPageIndex(0);
    };

    const validateInput = (value: number | undefined, type: string) => {
        if (value !== null && value !== undefined) {
            if (columnFilterValue) {
                if (
                    type === 'min' &&
                    columnFilterValue?.[1] !== null &&
                    value > columnFilterValue?.[1]
                ) {
                    onUpdate(columnFilterValue[1], type);
                }
                if (
                    type === 'max' &&
                    columnFilterValue?.[0] !== null &&
                    value < columnFilterValue?.[0]
                ) {
                    onUpdate(columnFilterValue[0], type);
                }
            }
            if (facetedMax !== null && value > facetedMax) onUpdate(facetedMax, type);
            if (facetedMin !== null && value < facetedMin) onUpdate(facetedMin, type);
        }
    };

    const minFieldEnabled = () => {
        return focused === 'min' || hovered === 'min' || columnFilterValue?.[0] !== null;
    };
    const maxFieldEnabled = () => {
        return focused === 'max' || hovered === 'max' || columnFilterValue?.[1] !== null;
    };

    return (
        <div>
            <div className="flex space-x-2">
                <Box
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    onBlur={(event) => {
                        if (!event.currentTarget.contains(event.relatedTarget)) setFocused('');
                    }}>
                    <Box sx={{ marginRight: '2px' }}>
                        <Input
                            id={`${column.id}-min-filter-input`}
                            onFocus={() => setFocused('min')}
                            onMouseEnter={() => setHovered('min')}
                            onMouseLeave={() => setHovered('')}
                            onBlur={() => {
                                validateInput(columnFilterValue?.[0], 'min');
                                setHovered('');
                            }}
                            disableUnderline
                            className={clsx(classes.numberInput, classes.lightModeNumberInput)}
                            sx={{
                                marginRight: '2px',
                                backgroundColor:
                                    focused === 'min' || hovered === 'min'
                                        ? theme.palette.neutral.neutral2
                                        : '',
                                color: minFieldEnabled()
                                    ? theme.palette.white
                                    : theme.palette.neutral.neutral3
                            }}
                            placeholder={`${
                                columnFilterValue?.[0] ?? facetedMin === -Infinity ? '' : facetedMin
                            }`}
                            value={columnFilterValue?.[0] ?? ''}
                            type="number"
                            onChange={(e) => {
                                onUpdate(e.target.value, 'min');
                            }}
                            endAdornment={undefined}
                        />
                        <FooterLabel sx={{ display: 'flex' }}>
                            <Box>Min</Box>
                            {isEmpty(columnFilterValue?.[0]) ? null : (
                                <ClearButton
                                    onClick={() => {
                                        column.setFilterValue([null, columnFilterValue?.[1]]);
                                    }}>
                                    Clear
                                </ClearButton>
                            )}
                        </FooterLabel>
                    </Box>
                    <Box sx={{ marginRight: '2px' }}>
                        <Input
                            id={`${column.id}-max-filter-input`}
                            onFocus={() => setFocused('max')}
                            onMouseEnter={() => setHovered('max')}
                            onMouseLeave={() => setHovered('')}
                            onBlur={() => {
                                validateInput(columnFilterValue?.[1], 'max');
                                setHovered('');
                            }}
                            disableUnderline
                            className={clsx(
                                classes.numberInput,
                                classes.lightModeNumberInput,
                                focused === 'max' && classes.lightModeNumberInputFocused
                            )}
                            sx={{
                                marginRight: '2px',
                                backgroundColor:
                                    focused === 'max' || hovered === 'max'
                                        ? theme.palette.neutral.neutral2
                                        : '',
                                color: maxFieldEnabled()
                                    ? theme.palette.white
                                    : theme.palette.neutral.neutral3
                            }}
                            placeholder={`${
                                columnFilterValue?.[1] ?? facetedMax === Infinity ? '' : facetedMax
                            }`}
                            value={columnFilterValue?.[1] ?? ''}
                            type="number"
                            onChange={(e) => {
                                onUpdate(e.target.value, 'max');
                            }}
                            endAdornment={undefined}
                        />
                        <FooterLabel sx={{ display: 'flex' }}>
                            <Box>Max</Box>
                            {isEmpty(columnFilterValue?.[1]) ? null : (
                                <ClearButton
                                    onClick={() => {
                                        column.setFilterValue([columnFilterValue?.[0], null]);
                                    }}>
                                    Clear
                                </ClearButton>
                            )}
                        </FooterLabel>
                    </Box>
                </Box>
            </div>
            <div className="h-1" />
        </div>
    );
};

export default NumberRangeFilterV8;
