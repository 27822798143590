/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import Modal from 'components/NetworkView/TopNavbar/components/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ODModal, ODTextField } from 'shared';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { updateNetworkView } from 'redux/actions/networkActions';
import { onGetReportsSuccess } from 'redux/actions/reportActions';
import { updateConfigurationName } from 'redux/actions/';
import { useSimulationHook } from 'utils/hooks';
import { styled } from '@mui/material/styles';
import { hasDuplicates } from 'utils/common.util';
import { Tooltip } from '@mui/material';
import ConfirmBidAnalysisRunModal from 'components/Modals/ConfirmBidAnalysisRunModal';
import {
    RUN_SIMULATION_MESSAGE,
    DUPLICATE_ERROR_MESSAGE,
    CHARACTERS_100_ERROR_MESSAGE
} from 'constants/network';

const RunSimulationMessageBox = styled(Box)(() => ({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    margin: '16px 0'
}));
const RunReportConfirmationModal = ({ currReport, isBidApplied, appliedBidFiles }) => {
    const dispatch = useDispatch();
    const { runSimulation } = useSimulationHook();
    const { newSimulationFlow } = useFlags();
    const history = useHistory();
    const { configuration } = useSelector((state) => state.ConfigurationReducer);
    const { reports } = useSelector((state) => state.ReportsReducers);
    const [name, setName] = useState(configuration?.name);
    const [inputError, setInputError] = useState(null);
    const params = useParams();
    const base_run = params?.base_run;

    const handleClose = () => {
        dispatch(updateNetworkView({ isRunSimulation: false }));
    };

    const handleRunReport = async (name) => {
        if (!name) return;
        if (name !== configuration.name && hasDuplicates(configuration, reports, name)) {
            setInputError(DUPLICATE_ERROR_MESSAGE);
        } else if (name.length > 100) {
            setInputError(CHARACTERS_100_ERROR_MESSAGE);
        } else {
            setInputError(null);
            if (name !== configuration.name) {
                await dispatch(updateConfigurationName(base_run, configuration.id, name.trim()));
                const newReports = reports.map((report) => ({
                    ...report,
                    name: report?.config_id === configuration.id ? name : report?.name
                }));
                await dispatch(onGetReportsSuccess({ reports: [...newReports] }));
            }
            await runSimulation();
            handleClose();
        }
    };

    return (
        <>
            {newSimulationFlow ? (
                appliedBidFiles?.length ? (
                    <ConfirmBidAnalysisRunModal
                        handleRunReport={(name) => handleRunReport(name)}
                        config={configuration}
                        openBidAnalysisConfirmModal={true}
                        setOpenBidAnalysisConfirmModal={handleClose}
                    />
                ) : (
                    <ODModal
                        show={true}
                        handleClose={handleClose}
                        handleAction={() => handleRunReport(name)}
                        heading="Confirm Report Run"
                        actionButtonText="Run"
                        runActionOnEnter={true}>
                        <RunSimulationMessageBox>{RUN_SIMULATION_MESSAGE}</RunSimulationMessageBox>
                        <ODTextField
                            customInputProps={{ maxLength: 100 }}
                            errorText={inputError}
                            autoFocus={true}
                            defaultValue={configuration?.name}
                            dataTestId="enter-run-report-name"
                            label="Name Report"
                            onChange={(event) => {
                                const val = event?.target?.value;

                                setName(Boolean(val) ? val : configuration?.name);
                                if (inputError) setInputError(null);
                            }}
                            name="runReportName"
                            placeholder={configuration?.name}
                        />
                        <Typography
                            marginTop="2px"
                            textAlign="right"
                            fontSize="12px"
                            color={theme.palette.neutral.neutral6}>
                            {name ? name?.length : '0'}/100 Character Limit
                        </Typography>
                    </ODModal>
                )
            ) : (
                <Modal
                    show={true}
                    heading="New Simulation Report In Progress"
                    actionButtonText="View Reports"
                    actionButtonTitle="view-reports"
                    handleClose={handleClose}
                    handleAction={() => {
                        handleClose();
                        history.push('/');
                    }}
                    aria-describedby="dialog-for-new-simulation">
                    <Box
                        sx={{
                            backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
                            fontSize: '14px',
                            color: theme.palette.neutral.black,
                            padding: '10px 16px',
                            height: '20px',
                            display: 'flex',
                            fontWeight: 600,
                            borderRadius: '4px',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                        id="simulation-information">
                        <Box sx={{ marginRight: '19px' }}>
                            <FolderSharpIcon sx={{ color: theme.palette.neutral.black }} />
                        </Box>
                        <Tooltip
                            title={
                                isBidApplied
                                    ? currReport?.name
                                    : `${currReport?.name || ''} ${configuration?.name || ''}`
                            }>
                            <Typography
                                display="flex"
                                width="500px"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap">
                                {isBidApplied
                                    ? currReport?.name
                                    : `${currReport?.name || ''} ${configuration?.name || ''}`}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Typography sx={{ fontSize: '14px', marginTop: '16px' }}>
                        To view the status of the report, click the "View Reports" button below or
                        access the report button located in the top right of the navigation.
                    </Typography>
                </Modal>
            )}
        </>
    );
};

export default RunReportConfirmationModal;
