import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { RATING } from 'constants/bidding';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { ODAlertIcon } from 'shared/ODIcons';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Tooltip, Typography } from '@mui/material';
import theme from 'theme';
import { FILTER_TYPE } from 'shared/Table/tableFilters/FilterConstants';
import { ColumnFormat, DonutData } from './types';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { abbreviateNumber, abbreviateNumberNew } from 'utils/number.util';
import palette from 'theme/palette';
import { CellContainer, StyledTextBox, StyledTextDeltaBox } from './BidAnalysisTableColumns.styles';
import { SimpleDonutWrapper } from 'shared/ODDonutChart';
import { getLaneScoreRating, getSalesRankingRating } from '../../../../utils/bidAnalysis.util';
import NoMappingMessage from '../NoMappingMessage';
import { UnspecifiedDataPlaceholder } from 'shared/ODTable/ODTableStyledComponents';
import { StyledSpan } from './BidAnalysisTableColumns.styles';

export const columnHelper = createColumnHelper<DataRow>();

export const decisionValuesToLabelsMap = {
    accept: 'Accept',
    reject: 'Reject'
};

export const getFilterType = (format: ColumnFormat, showRating: boolean) => {
    switch (format) {
        case 'percentage':
        case 'dollar':
        case 'count':
        case 'float':
        case 'interactive_count':
            if (showRating) return FILTER_TYPE.NUMBER_RANGE_WITH_THRESHOLDS;
            else return FILTER_TYPE.NUMBER_RANGE;
        case 'text':
            return FILTER_TYPE.DROPDOWN;
        default:
            break;
    }
};

export const getRatingIndicator = (rating: RATING) => {
    switch (rating) {
        case RATING.IDEAL:
            return <CircleIcon sx={{ color: theme.palette.success.main }} />;
        case RATING.TOLERABLE:
            return <ODAlertIcon sx={{ color: theme.palette.semantic.semanticYellow }} />;
        case RATING.UNACCEPTABLE:
            return <CloseIcon sx={{ color: theme.palette.semantic.semanticRed }} />;
    }
};

const slash = (
    <Box
        sx={{
            marginLeft: '4px',
            marginRight: '4px',
            color: palette.neutral.neutral6
        }}>
        /
    </Box>
);

export const cellFormat = (
    format: ColumnFormat,
    showRating: boolean,
    donut: DonutData,
    size: number,
    timeAggDependent: boolean,
    deltaValue: any,
    globalTimeUnits: any,
    triggerNoMappingInfoAlert: any,
    openBidAnalysisLaneScoreSlideout: any
) => {
    return (info: CellContext<DataRow, string | number | object | null>) => {
        const cellValue = info.getValue();
        let value = null;
        let rating = null;
        const rowId = info?.row?.original?.id?.toString() as any;
        const column = info?.cell?.column?.columnDef?.id as any;
        const hasDelta = Boolean(deltaValue?.[rowId]?.[column] != null);
        let delta = null;
        let symbol = <></>;
        let prefix = <></>;
        let hasDeltaNumber = false;

        if (hasDelta) {
            delta = deltaValue[rowId][column] as any;
            hasDeltaNumber = typeof deltaValue[rowId][column] === 'number';

            if (delta === 0) {
                symbol = <></>;
                prefix = <></>;
            } else {
                prefix = delta < 0 ? <></> : <>+</>;
                symbol =
                    delta < 0 ? (
                        <ArrowDownwardIcon sx={{ color: palette.neutral.neutral6 }} />
                    ) : (
                        <ArrowUpwardIcon sx={{ color: palette.neutral.neutral6 }} />
                    );
            }
        }

        if (cellValue === null || cellValue === undefined || cellValue === '')
            value = <UnspecifiedDataPlaceholder />;
        else {
            switch (format) {
                case 'dollar':
                    value = (
                        <>
                            <Box>
                                {`$${abbreviateNumberNew(cellValue)}${
                                    timeAggDependent ? globalTimeUnits : ''
                                }`}
                            </Box>

                            {hasDeltaNumber && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    color={palette.neutral.neutral6}>
                                    {slash}
                                    {prefix}
                                    <Tooltip
                                        title={`$${abbreviateNumberNew(delta)}${
                                            timeAggDependent ? globalTimeUnits : ''
                                        }`}>
                                        <StyledSpan>
                                            {`$${abbreviateNumberNew(delta)}${
                                                timeAggDependent ? globalTimeUnits : ''
                                            }`}{' '}
                                        </StyledSpan>
                                    </Tooltip>
                                    {symbol}
                                </Box>
                            )}
                        </>
                    );
                    break;
                case 'percentage':
                    value = (
                        <>
                            <Box>{`${abbreviateNumberNew(cellValue)}%`} </Box>

                            {hasDeltaNumber && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    color={palette.neutral.neutral6}>
                                    {slash}
                                    {prefix}
                                    <Tooltip title={`${abbreviateNumberNew(delta)}%`}>
                                        <StyledSpan>{`${abbreviateNumberNew(delta)}%`} </StyledSpan>
                                    </Tooltip>
                                    {symbol}
                                </Box>
                            )}
                        </>
                    );
                    break;
                case 'count':
                    value = (
                        <>
                            <Box>
                                {`${abbreviateNumber(cellValue)}${
                                    timeAggDependent ? globalTimeUnits : ''
                                }`}
                            </Box>

                            {hasDeltaNumber && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    color={palette.neutral.neutral6}>
                                    {slash}
                                    {prefix}
                                    <Tooltip
                                        title={`${abbreviateNumber(delta)}${
                                            timeAggDependent ? globalTimeUnits : ''
                                        }`}>
                                        <StyledSpan>
                                            {`${abbreviateNumber(delta)}${
                                                timeAggDependent ? globalTimeUnits : ''
                                            }`}{' '}
                                        </StyledSpan>
                                    </Tooltip>
                                    {symbol}
                                </Box>
                            )}
                        </>
                    );
                    break;
                case 'float':
                    value = (
                        <>
                            <Box>
                                {`${abbreviateNumberNew(cellValue)}${
                                    timeAggDependent ? globalTimeUnits : ''
                                }`}
                            </Box>
                            {hasDeltaNumber && (
                                <Tooltip
                                    title={`${abbreviateNumberNew(delta)}${
                                        timeAggDependent ? globalTimeUnits : ''
                                    }`}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        color={palette.neutral.neutral6}>
                                        {slash}
                                        {prefix}
                                        <Tooltip
                                            title={`${abbreviateNumberNew(delta)}${
                                                timeAggDependent ? globalTimeUnits : ''
                                            }`}>
                                            <StyledSpan>
                                                {`${abbreviateNumberNew(delta)}${
                                                    timeAggDependent ? globalTimeUnits : ''
                                                }`}{' '}
                                            </StyledSpan>
                                        </Tooltip>
                                        {symbol}
                                    </Box>
                                </Tooltip>
                            )}
                        </>
                    );
                    break;
                case 'interactive_count':
                    value = (
                        <>
                            <StyledSpan
                                selectable={info.column.id === 'lane_score'}
                                onClick={
                                    info.column.id === 'lane_score'
                                        ? () =>
                                              openBidAnalysisLaneScoreSlideout(
                                                  info?.cell?.row?.original?.id
                                              )
                                        : undefined
                                }>
                                {abbreviateNumber(cellValue, 2)}
                            </StyledSpan>

                            {hasDeltaNumber && (
                                <Tooltip
                                    title={`${abbreviateNumberNew(delta)}${
                                        timeAggDependent ? globalTimeUnits : ''
                                    }`}>
                                    <StyledSpan>
                                        <Typography
                                            style={{ display: 'flex' }}
                                            color={theme.palette.neutral.neutral6}>
                                            {slash}
                                            {prefix}
                                            {abbreviateNumber(delta, 2)}
                                            {symbol}
                                        </Typography>
                                    </StyledSpan>
                                </Tooltip>
                            )}
                        </>
                    );
                    break;
                case 'text':
                    if (cellValue === 'NOMAPPING') {
                        value = (
                            <NoMappingMessage
                                value={cellValue}
                                size={size}
                                triggerFn={triggerNoMappingInfoAlert}
                            />
                        );
                    } else {
                        const title = hasDelta ? `${cellValue} / ${delta}` : cellValue;
                        value = (
                            <Tooltip title={title as any}>
                                <>
                                    <StyledTextBox size={size}>
                                        {Boolean(cellValue) ? cellValue : ('-' as any)}{' '}
                                    </StyledTextBox>
                                    {hasDeltaNumber && (
                                        <StyledTextDeltaBox size={size}>
                                            {prefix}
                                            {Boolean(delta) ? delta : '0'} {symbol}
                                        </StyledTextDeltaBox>
                                    )}
                                </>
                            </Tooltip>
                        );
                    }

                    break;
                default:
                    return null;
            }
        }
        if (showRating) {
            if (typeof cellValue === 'number' && !isNaN(cellValue)) {
                rating =
                    info.column.id === 'lane_score'
                        ? getLaneScoreRating({ value: cellValue })
                        : getSalesRankingRating({ value: cellValue });
            } else {
                console.warn(
                    'cannot calculate rating from non-numeric value in column ' +
                        info.column +
                        ', row ' +
                        info.row.index
                );
            }
        }
        return (
            <CellContainer>
                {rating && (
                    <Box sx={{ display: 'flex', marginRight: '7px' }}>
                        {getRatingIndicator(rating)}
                    </Box>
                )}
                {donut && (
                    <Box>
                        <SimpleDonutWrapper
                            numerator={info.row.original[donut.displayedNumberKey] as number}
                            denominator={
                                (info.row.original[donut.displayedNumberKey] as number) +
                                (info.row.original[donut.remainderNumberKey] as number)
                            }
                            color={donut.color}
                            donutId={`${info.row.index}-${info.column.id}`}
                        />
                    </Box>
                )}
                {value}
            </CellContainer>
        );
    };
};
