import React, { useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import './App.css';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import SignIn from './components/Authentication/logIn';
import BidFileManager from 'components/Bidding/BidFileManager';
import NetworkManager from './components/NetworkManager';
import { store, persistor } from 'redux/store';
import { Provider } from 'react-redux';
import history from './utils/history';
import PrivateRoute from './components/Authentication/privateRoute';
import { ToastContainer } from 'react-toastify';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import EnvConfig from './config/config';
import theme from 'theme';
import Layout from 'components/Layout/Layout';
import NetworkView from 'components/NetworkView/NetworkView';
import BidConfig from './components/Bidding/EditBidConfigTable/EditBidConfigTable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LaunchDarklyUserConfig from 'config/LaunchDarklyUserConfig';
import API from 'utils/axios';
import { env } from './env';
import { AlertsProvider } from 'components/NetworkView/Alerts/AlertsContext';
import PageNotFound from 'components/PageNotFound';
import BidAnalysis from 'components/BidAnalysis/BidAnalysis';
import * as FS from '@fullstory/browser';

const subdomain = window.location.host.includes('localhost')
    ? env.REACT_APP_CONNECTION
    : window.location.host.split('.')[0];

const connection = () => {
    let hasConnectionString = window.location.search.includes('?conn=');

    if (hasConnectionString) {
        if (sessionStorage.getItem('ODConnection')) {
            return sessionStorage.getItem('ODConnection');
        } else {
            sessionStorage.setItem('ODConnection', window.location.search.split('=')[1]);
            return window.location.search.split('=')[1];
        }
    } else if (
        window.location.host.includes('localhost') ||
        window.location.host.includes('dev') ||
        window.location.host.includes('demo')
    ) {
        return 'optimaldynamics';
    } else {
        return window.location.host.split('.')[0];
    }
};

const Auth = (props) => {
    const connectionOption = connection();
    const { organization, errorMessages, children } = props;
    const URI = window.location.origin;

    if (!Object.keys(organization).length && !errorMessages.length) return null;

    if (organization.connection_type === 'organization') {
        return (
            <Auth0Provider
                domain={env.REACT_APP_DOMAIN}
                clientId={env.REACT_APP_CLIENT_ID}
                redirectUri={URI}
                organization={organization.id}
                connection={connectionOption}
                scope={env.REACT_APP_SCOPE}
                audience={env.REACT_APP_AUDIENCE}>
                {children}
            </Auth0Provider>
        );
    }

    return (
        <Auth0Provider
            domain={env.REACT_APP_DOMAIN}
            clientId={env.REACT_APP_CLIENT_ID}
            redirectUri={URI}
            connection={subdomain}
            scope={env.REACT_APP_SCOPE}
            audience={env.REACT_APP_AUDIENCE}>
            {children}
        </Auth0Provider>
    );
};

const App = () => {
    const { bidding, odpt4185BidConfigUpdate } = useFlags();
    const [organization, setOrganization] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);

    FS.init({ orgId: '18AJTW' });

    useEffect(() => {
        const fetchAuthType = async () => {
            const response = await API.get(`organizations/auth-type/${subdomain}/`);
            return response;
        };

        fetchAuthType()
            .then((res) => setOrganization(res.data))
            .catch((err) => setErrorMessages(err.response.data.errors));
    }, []);

    return (
        <Auth organization={organization} errorMessages={errorMessages}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <div>
                                <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>Core.ai.Plan</title>
                                </Helmet>
                                <LaunchDarklyUserConfig />
                                <Router history={history}>
                                    <AlertsProvider>
                                        <Switch>
                                            <Route
                                                exact
                                                path="/login"
                                                render={(props) => (
                                                    <SignIn
                                                        {...props}
                                                        errorMessages={errorMessages}
                                                    />
                                                )}
                                            />

                                            <PrivateRoute
                                                exact
                                                path={['/']}
                                                layout={Layout}
                                                component={NetworkManager}
                                            />
                                            <PrivateRoute
                                                exact
                                                path={[
                                                    '/bid-analysis/:analysis_id',
                                                    '/bid-analysis/:analysis_id/config/:config_id'
                                                ]}
                                                layout={Layout}
                                                component={BidAnalysis}
                                            />
                                            {bidding && (
                                                <PrivateRoute
                                                    exact
                                                    path="/bidding"
                                                    layout={Layout}
                                                    component={BidFileManager}
                                                />
                                            )}
                                            {bidding && odpt4185BidConfigUpdate && (
                                                <PrivateRoute
                                                    exact
                                                    path="/bidding/:bid_config_id"
                                                    layout={Layout}
                                                    component={BidConfig}
                                                />
                                            )}
                                            <PrivateRoute
                                                requireRoles={[]}
                                                exact
                                                path={[
                                                    '/reports',
                                                    '/reports/report-run/:base_run/:compare_run?/:filtertype(network|drivers)/:subfilter/:expanded(expanded)?/:sidebar?',
                                                    '/reports/report-run/:base_run/:compare_run?/config/:config_id/:filtertype(network|drivers)/:subfilter/:expanded(expanded)?/:sidebar?',
                                                    '/reports/bucket/:bucket_id/:base_run/:compare_run?/:filtertype(network|drivers)/:subfilter/:expanded(expanded)?/:sidebar?',
                                                    '/reports/bucket/:bucket_id/:base_run/:compare_run?/config/:config_id/:filtertype(network|drivers)/:subfilter/:expanded(expanded)?/:sidebar?'
                                                ]}
                                                layout={Layout}
                                                component={NetworkView}
                                            />
                                            <PrivateRoute
                                                exact
                                                path={['/404']}
                                                layout={Layout}
                                                component={PageNotFound}
                                            />
                                            <Redirect from="*" to="/404" />
                                        </Switch>
                                    </AlertsProvider>
                                </Router>
                            </div>
                            <ToastContainer />
                        </PersistGate>
                    </Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Auth>
    );
};

export default withLDProvider({
    clientSideID: EnvConfig.getConfig().clientSideId,
    user: {
        key: EnvConfig.getConfig().sdkKey,
        custom: { organization: subdomain }
    }
})(App);
