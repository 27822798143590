import API from 'utils/axios';
import {
    generateResponse,
    findValuesIndex,
    numberValidation,
    uniqueValidation
} from 'utils/flatfile.util';

// A mutable module global might be dangerous
let mileageValues = {};

//
// this is a preferred semantic ZipCode validator, but it has some performance issues
//
export const locationZipCodeSemanticValidationFieldhookCallback = async (values, fieldname) => {
    // Do not modify anything before sending it to the BE validation;
    // the BE will validate and correct location data.

    // location data is required in mileageValues for mileageValidationFieldhookCallback
    mileageValues[fieldname] = values;
    const responseValidate = await API.post('/bidding/flatfile/validate/location/zip-code/', {
        zip_codes: values
    });
    values.forEach((row, index) => {
        values[index] = generateResponse('field', row[0], row[1]);
    });
    if (responseValidate.data['flatfile_response'].length > 0) {
        responseValidate.data['flatfile_response'].forEach((row) => {
            const [info, value] = [row[0]['info'], row[0]['value']];
            const index = findValuesIndex(values, row[1]);
            values[index] = generateResponse('field', value, row[1], info);
        });
    }
    return values;
};

//
// this is a syntax-only ZipCode validator that avoids some performance issues
// with the semantic ZipCode validator (but it will allow non-existent zip codes)
//
export const locationZipCodeSyntaxValidationFieldhookCallback = async (values, fieldname) => {
    // Do not modify anything before sending it to the BE validation;
    // the BE will validate and correct location data.

    // location data is required in mileageValues for mileageValidationFieldhookCallback
    mileageValues[fieldname] = values;

    const responseValidate = await API.post(
        '/bidding/flatfile/validate/location/zip-code-syntax/',
        {
            zip_codes: values
        }
    );
    values.forEach((row, index) => {
        values[index] = generateResponse('field', row[0], row[1]);
    });
    if (responseValidate.data['flatfile_response'].length > 0) {
        responseValidate.data['flatfile_response'].forEach((row) => {
            const [info, value] = [row[0]['info'], row[0]['value']];
            const index = findValuesIndex(values, row[1]);
            values[index] = generateResponse('field', value, row[1], info);
        });
    }
    return values;
};

export const locationCityStateValidationFieldhookCallback = async (values, fieldname) => {
    // Do not modify anything before sending it to the BE validation;
    // the BE will validate and correct location data.

    // TODO: how can this validate mileage values in the BE?
    // location data is required in mileageValues for mileageValidationFieldhookCallback
    mileageValues[fieldname] = values;

    const responseValidate = await API.post('/bidding/flatfile/validate/location/city-state/', {
        city_state: values,
        validation_type: 'city_state'
    });
    values.forEach((row, index) => {
        values[index] = generateResponse('field', row[0], row[1]);
    });
    if (responseValidate.data['flatfile_response'].length > 0) {
        responseValidate.data['flatfile_response'].forEach((row) => {
            const [info, value] = [row[0]['info'], row[0]['value']];
            const index = findValuesIndex(values, row[1]);
            values[index] = generateResponse('field', value, row[1], info);
        });
    }
    return values;
};

export const locationCityStateZipCodeValidationFieldhookCallback = async (values, fieldname) => {
    // Do not modify anything before sending it to the BE validation;
    // the BE will validate and correct location data.

    // TODO: how can this validate mileage values in the BE?
    // location data is required in mileageValues for mileageValidationFieldhookCallback
    mileageValues[fieldname] = values;

    const responseValidate = await API.post('/bidding/flatfile/validate/location/city-state-zip/', {
        city_state_zip: values
    });
    values.forEach((row, index) => {
        values[index] = generateResponse('field', row[0], row[1]);
    });
    if (responseValidate.data['flatfile_response'].length > 0) {
        responseValidate.data['flatfile_response'].forEach((row) => {
            const [info, value] = [row[0]['info'], row[0]['value']];
            const index = findValuesIndex(values, row[1]);
            values[index] = generateResponse('field', value, row[1], info);
        });
    }
    return values;
};

export const mileageValidationFieldhookCallback = async (values) => {
    mileageValues['mileage'] = values;
    const responseValidate = await API.post('/bidding/flatfile/validate/mileage/', mileageValues);
    // reset mileageValues array
    mileageValues = {};
    return responseValidate.data['mileage'];
};

export const loadVolumeFieldhookCallback = async (values) => {
    return numberValidation(values, false);
};

export const optionalNumberColumnFieldhookCallback = async (values) => {
    return numberValidation(values, true);
};

// Validation for uniqueness is made on the FE
export const customerBidIDFieldhookCallback = async (values) => {
    return uniqueValidation(values);
};
