import { TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';
import theme from 'theme';

export const StyledTableCell = withStyles(() => ({
    root: {
        borderBottom: 'none'
    },

    body: {
        textTransform: 'capitalize',
        fontSize: 14,
        padding: '0px',
        backgroundColor: theme.palette.neutral.neutral1,
        borderBottom: `1px solid ${theme.palette.white}`,
        color: theme.palette.black
    }
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        height: 40,
        width: 40
    }
}))(TableRow);

export const reportListTableCommonStyle = {
    backgroundColor: theme.palette.neutral.neutral1,
    color: theme.palette.black,
    borderBottom: `1px solid ${theme.palette.white}`,
    fontWeight: 600
};
