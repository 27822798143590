/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import { updateEditDriver, updateAddDriversCount } from 'redux/actions';
import ODDonutChart from 'shared/ODDonutChart';

const CustomizedHeaderCell = styled('td')(() => ({
    fontWeight: 600,
    height: '43px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
}));

const CustomizedRowCell = styled('td')(({ theme }) => ({
    padding: 0,
    height: '43px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.neutral.black,
    flex: 1
}));

const DriverNumberInput = styled(Input)(({ value }) => ({
    border: `1px solid ${theme.palette.neutral.neutral1}`,
    borderRadius: '4px',
    fontSize: '14px',
    color: theme.palette.neutral.neutral6,
    paddingLeft: value > 0 ? '0px' : value < 0 ? '18px' : '24px',
    paddingRight: '8px',
    width: '68px',
    height: '32px',
    margin: '10px',
    textAlign: 'center',
    '&:hover': {
        backgroundColor: theme.palette.neutral.neutral1,
        color: theme.palette.black
    }
}));

const PlusNumberInput = styled(InputAdornment)(() => ({
    marginRight: '0px',
    paddingLeft: '16px',
    '.MuiTypography-root': {
        fontSize: '14px'
    }
}));

const DriverAmountTable = (props) => {
    const dispatch = useDispatch();
    const { addDriversMode } = props;
    const { editDriver, driverTypesColors } = useSelector((state) => state.DriversReducer);
    const columns = React.useMemo(
        () => [
            {
                Header: 'Driver Type',
                accessor: 'driver_type'
            },
            {
                Header: 'Total Drivers',
                accessor: 'count',
                Cell: ({ value, cell: { row } }) => {
                    const { addDriversCount } = useSelector((state) => state.DriversReducer);
                    const [inputValue, setInputValue] = useState(
                        addDriversCount[row.original.driver_type] || 0
                    );
                    const updateValues = (number, driver_type) => {
                        number = number ? number : '0';
                        setInputValue(number, driver_type);
                        let newCountDict = { ...addDriversCount, [driver_type]: number };
                        dispatch(updateAddDriversCount(newCountDict));
                    };
                    let originalRow = row.original;
                    let index = row.index;
                    let isCurrentDriver = value === editDriver.driver_types[index]?.count;

                    return (
                        <>
                            <CustomizedRowCell>
                                <ODDonutChart
                                    id={`driver-${index}`}
                                    percentages={[(value / editDriver.total_drivers) * 100]}
                                    colors={[
                                        driverTypesColors[row.original.driver_type],
                                        theme.palette.neutral.neutral1
                                    ]}
                                    light={true}
                                    index={`driver-${index}`}
                                />
                                {value}
                                {isCurrentDriver &&
                                originalRow['actionCount'] &&
                                !addDriversMode ? (
                                    <Box marginLeft="5px">
                                        {`(${originalRow['actionCount'] > 0 ? '+' : ''}${
                                            originalRow['actionCount']
                                        })`}
                                    </Box>
                                ) : null}
                            </CustomizedRowCell>
                            <Box sx={{ flex: 1 }}>
                                {isCurrentDriver && addDriversMode ? (
                                    <DriverNumberInput
                                        type="number"
                                        value={inputValue}
                                        onChange={(e) => {
                                            if (Number(e.target.value) >= value * -1)
                                                updateValues(
                                                    e.target.value,
                                                    originalRow.driver_type
                                                );
                                        }}
                                        startAdornment={
                                            inputValue > 0 && (
                                                <PlusNumberInput position="start">
                                                    +
                                                </PlusNumberInput>
                                            )
                                        }
                                        disableUnderline={true}
                                    />
                                ) : null}
                            </Box>
                        </>
                    );
                }
            }
        ],
        [addDriversMode]
    );
    const data = React.useMemo(() => {
        return editDriver?.driver_types;
    }, [editDriver]);
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data
    });

    const { configurationActions } = useSelector((state) => state.ConfigurationReducer);
    const domConfigActions = configurationActions.filter((action) => {
        return action.item_type === 'driver' && action.value.domicile === editDriver.domicile_id;
    });
    const getDriverTypeCount = (driver_type) => {
        let driverCount = 0;
        let driverTypeActions = domConfigActions.filter(
            (action) => action.value.driver_type === driver_type
        );
        driverTypeActions.forEach((action) => {
            driverCount += action.value.quantity;
        });
        return driverCount;
    };
    useEffect(() => {
        let _driverTypes = editDriver.driver_types.map((ind) => {
            let newCount = getDriverTypeCount(ind.driver_type);
            return { ...ind, actionCount: newCount };
        });
        dispatch(
            updateEditDriver({
                ...editDriver,
                driver_types: _driverTypes
            })
        );
    }, []);
    useEffect(() => {
        let newCountDict = {};
        editDriver.driver_types.forEach((ind) => {
            let newCount = getDriverTypeCount(ind.driver_type);
            newCountDict[ind.driver_type] = newCount.toString();
        });
        dispatch(updateAddDriversCount(newCountDict));
    }, [editDriver]);
    return (
        <Table {...getTableProps()}>
            <TableHead>
                {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        <Box
                            sx={{
                                display: 'flex',
                                backgroundColor: theme.palette.neutral.neutral1
                            }}>
                            {headerGroup.headers.map((column) => (
                                <Box sx={{ flex: 1 }}>
                                    <TableCell
                                        style={{
                                            padding: 0,
                                            paddingLeft: '20px',
                                            border: 'transparent',
                                            height: '40px',
                                            alignContent: 'center'
                                        }}
                                        {...column.getHeaderProps()}>
                                        <CustomizedHeaderCell>
                                            {column.render('Header')}
                                        </CustomizedHeaderCell>
                                    </TableCell>
                                </Box>
                            ))}
                        </Box>
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <TableRow {...row.getRowProps()}>
                            <Box sx={{ display: 'flex', width: '100%' }}>
                                {row.cells.map((cell) => {
                                    return (
                                        <CustomizedRowCell
                                            sx={{
                                                borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                                                paddingLeft: '20px'
                                            }}
                                            {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </CustomizedRowCell>
                                    );
                                })}
                            </Box>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default DriverAmountTable;
