import React from 'react';
import { Avatar, Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import theme from 'theme';
import ParamsInput from './ParamsInput';

const TabWrapper = ({
    children,
    value,
    index,
    driverTypeData,
    updateValue,
    onChangeValue,
    editParams,
    ...props
}) => {
    const {
        driver_type,
        percentage_of_load_revenue_pay,
        unit_cost_empty_rate,
        unit_cost_fixed_per_load,
        unit_cost_hourly_rate,
        unit_cost_loaded_rate
    } = driverTypeData;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-for-driver-type-${driver_type}-${index}`}
            aria-labelledby={`simple-tab-for-${driver_type}-${index}`}
            {...props}>
            {value === index && (
                <Box
                    sx={{
                        padding: '32px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <Box sx={{ minWidth: '427px', width: '50%', marginRight: '32px' }}>
                        <Box component="section" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                style={{
                                    backgroundColor: theme.palette.neutral.neutral1,
                                    color: theme.palette.neutral.black,
                                    marginRight: '16px'
                                }}>
                                <span style={{ fontSize: '14px', fontWeight: 600 }}>01</span>
                            </Avatar>
                            <h3
                                style={{
                                    fontWeight: 600,
                                    color: theme.palette.neutral.black,
                                    fontSize: '14px'
                                }}>
                                Variable Costs
                            </h3>
                        </Box>

                        <Box
                            sx={{
                                fontWeight: 400,
                                fontSize: '24px',
                                margin: '16px 0',
                                color: theme.palette.neutral.black
                            }}>
                            Consider taking one load with a driver of this type, what is the
                            variable cost of taking this load to your company (including costs of
                            moving the asset)?
                        </Box>

                        <h5
                            style={{
                                fontWeight: 300,
                                color: theme.palette.neutral.black,
                                fontSize: '12px'
                            }}>
                            <i>
                                Avoid double counting costs. For example, do not place a rate per
                                mile and an approximate rate per hour if the company or company only
                                incurs costs on a per mile basis.
                            </i>
                        </h5>
                    </Box>
                    <Box component="section" sx={{ minWidth: '885px', width: '90%' }}>
                        <Box component="header" sx={{ fontWeight: 400, fontSize: '14px' }}>
                            Enter the rates of the following 5 fields:
                        </Box>
                        <Box component="section" sx={{ display: 'flex' }}>
                            <ParamsInput
                                driverType={driver_type}
                                id="unit_cost_loaded_rate"
                                label="Cost Per Loaded Mile"
                                onChange={onChangeValue}
                                updateValue={updateValue}
                                htmlFor="unit_cost_loaded_rate"
                                value={unit_cost_loaded_rate}
                                disabled={!editParams}
                            />
                            <ParamsInput
                                driverType={driver_type}
                                id="unit_cost_empty_rate"
                                label="Cost Per Empty Mile"
                                onChange={onChangeValue}
                                updateValue={updateValue}
                                htmlFor="unit_cost_empty_rate"
                                value={unit_cost_empty_rate}
                                onRightSide
                                disabled={!editParams}
                            />
                        </Box>
                        <Box component="section" sx={{ display: 'flex' }}>
                            <ParamsInput
                                driverType={driver_type}
                                id="unit_cost_hourly_rate"
                                label="Cost Per Hour (If Applicable)"
                                onChange={onChangeValue}
                                updateValue={updateValue}
                                htmlFor="unit_cost_hourly_rate"
                                value={unit_cost_hourly_rate}
                                disabled={!editParams}
                            />
                            <ParamsInput
                                driverType={driver_type}
                                id="unit_cost_fixed_per_load"
                                label="Flat Cost Per Load Moved (If Applicable)"
                                onChange={onChangeValue}
                                updateValue={updateValue}
                                htmlFor="unit_cost_fixed_per_load"
                                value={unit_cost_fixed_per_load}
                                onRightSide
                                disabled={!editParams}
                            />
                        </Box>
                        <ParamsInput
                            type="percentage"
                            driverType={driver_type}
                            id="percentage_of_load_revenue_pay"
                            label="Percentage of Revenue Paid to Driver (If Applicable)"
                            onChange={onChangeValue}
                            updateValue={updateValue}
                            htmlFor="percentage_of_load_revenue_pay"
                            value={percentage_of_load_revenue_pay}
                            disabled={!editParams}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

TabWrapper.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export default TabWrapper;
