import { LEFTNAV_OPTION } from 'constants/settings';
import { SETTINGS_UPDATE, SETTINGS_RESET } from '../constants/actionTypes';

const initialState = {
    locationVisualization: 'city-state',
    minLoadCount: 1,
    clusterAuto: true,
    clusterValue: 3,
    aggregationLevel: 5,
    maxAggregationLevel: 5,
    isAggregated: false,
    positioning: 'side',
    openSetting: false,
    currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_VIEW,
    showExpandableArr: false
};

const settingsUpdate = (state, action) => {
    return {
        ...state,
        ...action.setting
    };
};

const settingsReset = (state, action) => {
    return { ...state, ...initialState };
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_UPDATE:
            return settingsUpdate(state, action);
        case SETTINGS_RESET:
            return settingsReset(state, action);
        default:
            return state;
    }
};

export default settingsReducer;
