import {
    NETWORK_KPI_CLEAR,
    NETWORK_KPI_SUCCESS,
    UPDATE_DOMICILE,
    UPDATE_DOMICILE_META,
    UPDATE_NETWORK_VIEW,
    INITIAL_REPORT_RUN_FAILURE,
    INITIAL_REPORT_RUN_SUCCESS,
    CLEAR_REPORT_DATA,
    CLEAR_REPORT_DATA_PRESERVE_CONTEXT
} from 'redux/constants/actionTypes';
import { getConfigurationActions, setConfiguration } from 'redux/actions';
import { configurationsSuccess, configurationFail } from './configurationActions';
import { reduce } from 'lodash';
import API from 'utils/axios';

export const resetReportData = (payload) => ({
    type: CLEAR_REPORT_DATA
});

export const resetReportDataPreserveContext = (payload) => ({
    type: CLEAR_REPORT_DATA_PRESERVE_CONTEXT
});

export const onGetNetworkKPISuccess = (payload) => ({
    type: NETWORK_KPI_SUCCESS,
    payload
});

export const onGetNetworkKPIFailure = (payload) => ({
    type: NETWORK_KPI_CLEAR,
    payload
});

export const onGetDomicile = (payload) => ({
    type: UPDATE_DOMICILE,
    payload
});

export const onGetDomicileMeta = (payload) => ({
    type: UPDATE_DOMICILE_META,
    payload
});

export const updateReportsData = (initialReportRunData) => {
    return {
        type: INITIAL_REPORT_RUN_SUCCESS,
        initialReportRunData
    };
};
export const failedReportsData = (error) => {
    return (dispatch) => {
        dispatch({ type: INITIAL_REPORT_RUN_FAILURE, error });
    };
};

//!TODO update networkview function (consolidate)
export const updateNetworkView = (payload) => {
    return {
        type: UPDATE_NETWORK_VIEW,
        payload
    };
};

/*!TODO KEEP THESE BELOW */

const fetchReportData = async (runId) => {
    let reportData = { id: null, name: null, enabled: false };

    if (runId) {
        let url = `/reports/report-runs/${runId}/`;
        const { data } = await API.get(url);

        if (data) {
            reportData = { id: data.id, name: data.name, enabled: true };
        }
    }
    return reportData;
};

export const initializeSelectedReports = ({ base_run, compare_run }) => {
    return async (dispatch) => {
        try {
            dispatch(updateNetworkView({ loadingFetchingReports: true }));
            const [baseData, compareData] = await Promise.all([
                fetchReportData(base_run),
                fetchReportData(compare_run)
            ]);
            dispatch(
                updateNetworkView({
                    selectedBaseReport: baseData,
                    selectedCompareReport: compareData
                })
            );
            dispatch(updateNetworkView({ loadingFetchingReports: false }));
        } catch (err) {
            console.warn('Unable to initialize selected reports');
            dispatch(updateNetworkView({ loadingFetchingReports: false }));
        }
    };
};

export const getKPIbyReportId = ({ runId, filter, v2KpIsFlag }) => {
    return async (dispatch) => {
        try {
            delete filter.loadType;
            const query = reduce(
                filter,
                (store, v, k) => {
                    if (v.length === 0) {
                        return store; // Exclude empty array values
                    } else {
                        return store.concat(`${k}=${v}`);
                    }
                },
                []
            ).join('&');
            const url = `/reports/report-runs/${runId}/kpis/${v2KpIsFlag ? 'v2/' : ''}?${query}`;

            dispatch(
                onGetNetworkKPISuccess({
                    kpi: {}
                })
            );

            dispatch(updateNetworkView({ fetchingKPIbyReportId: true }));
            const response = await API.get(url);
            const data = v2KpIsFlag ? response.data.data : response.data;

            //!TODO compare run this change, pass two
            const kpi = Object.keys(data || {}).reduce((store, kpi) => {
                store[kpi] = { value: data[kpi], isCompared: false };
                return store;
            }, {});
            dispatch(
                onGetNetworkKPISuccess({
                    kpi
                })
            );
            dispatch(updateNetworkView({ fetchingKPIbyReportId: false }));
        } catch (err) {
            console.warn('Unable to get KPI by report ID');
            dispatch(updateNetworkView({ fetchingKPIbyReportId: false }));
            return dispatch(
                onGetNetworkKPIFailure({
                    kpi: null
                })
            );
        }
    };
};

export const getDomicilesByReportId = (id) => {
    return async (dispatch) => {
        try {
            const url = `/reports/report-runs/${id}/domiciles`;
            const {
                data: { domiciles, meta }
            } = await API.get(url);

            //!TODO would be ideal if turn as an array with an id
            dispatch(
                onGetDomicileMeta({
                    meta
                })
            );
            dispatch(
                onGetDomicile({
                    domiciles
                })
            );
        } catch (err) {
            dispatch(
                onGetDomicile({
                    metal: {}
                })
            );
            dispatch(
                onGetDomicile({
                    domicile: {}
                })
            );
        }
    };
};

//single report information by id
export const getReportByReportId = (id, isBaseCase = true, allowConfig = false) => {
    const runId = Number(id);

    return async (dispatch) => {
        try {
            const url = `/reports/report-runs/${runId}/`;
            dispatch(updateNetworkView({ loadingGetReportByReportId: true }));
            const { data } = await API.get(url);

            isBaseCase
                ? dispatch(updateNetworkView({ baseReport: data }))
                : dispatch(updateNetworkView({ compareReport: data }));

            if (allowConfig && data?.configuration) {
                dispatch(getConfigurationActions(id, data?.configuration));
                dispatch(
                    setConfiguration({
                        ...data,
                        id: data?.configuration,
                        report_run: data
                    })
                );
                dispatch(updateNetworkView({ loadingGetReportByReportId: false }));
            }
        } catch (err) {
            console.warn('Unable to fetch data via getReportInfoById');
            dispatch(updateNetworkView({ loadingGetReportByReportId: false }));
        }
    };
};

export const getReportsByBucketOrReportId = (id, type) => {
    return async (dispatch) => {
        try {
            let URL = '';
            let isBucket = type === 'bucket';

            if (isBucket) {
                URL = `/reports/report-runs/bucket/${id}/`;
            } else {
                URL = `/reports/report-runs/${id}/`;
            }
            dispatch(updateNetworkView({ loadingGetReportsByBucketOrId: true }));
            const res = await API.get(URL);

            const result = isBucket ? res.data.results : [res.data];
            dispatch(updateReportsData(result));
            dispatch(updateNetworkView({ loadingGetReportsByBucketOrId: false }));
        } catch (err) {
            dispatch(failedReportsData('Unable to get reports by given bucket id'));
            dispatch(updateNetworkView({ loadingGetReportsByBucketOrId: false }));
        }
    };
};

export const setSelectedReports = ({ selectedBaseReport, selectedCompareReport }) => {
    return async (dispatch) => {
        try {
            if (selectedBaseReport.id) {
                let url = `/reports/report-runs/${selectedBaseReport.id}/`;
                const {
                    data: { name }
                } = await API.get(url);
                selectedBaseReport.name = name;
            } else {
                selectedBaseReport.name = null;
            }
            if (selectedCompareReport.id) {
                let url = `/reports/report-runs/${selectedCompareReport.id}/`;
                const {
                    data: { name }
                } = await API.get(url);
                selectedCompareReport.name = name;
            } else {
                selectedCompareReport.name = null;
            }
            dispatch(
                updateNetworkView({
                    selectedBaseReport: selectedBaseReport,
                    selectedCompareReport: selectedCompareReport
                })
            );
        } catch (err) {
            console.warn('failed to update selected reports');
        }
    };
};
//!TODO move to new configuration action

export const getConfigurationByRunId = (runId) => {
    return async (dispatch) => {
        dispatch(configurationsSuccess({ configurations: [], error: null }));
        try {
            const {
                data: { results }
            } = await API.get(`/reports/report-runs/${runId}/configurations/`);
            dispatch(configurationsSuccess({ configurations: results, error: null }));
        } catch (error) {
            dispatch(configurationFail({ error: error?.message }));
        }
    };
};

export const getAllBuckets = async () => {
    let {
        data: { results }
    } = await API.get(`/buckets/`);
    const buckets = [
        {
            id: 'all',
            name: 'All Reports'
        },
        ...results
    ];
    return buckets;
};
