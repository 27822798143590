import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SwapHorizSharpIcon from '@mui/icons-material/SwapHorizSharp';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import ClearIcon from '@mui/icons-material/Clear';
import theme from 'theme';
import { ODCircularProgress } from 'shared';

const REPORT_STATUS_LABEL_INFO = {
    all: { label: 'All Reports', icon: <> </> },
    vfa_completed: {
        label: 'Retrained',
        icon: <SwapHorizSharpIcon sx={{ color: theme.palette.semantic.semanticGreen }} />
    },
    running: {
        label: 'In Progress',
        icon: <ODCircularProgress />
    },
    preparing: {
        label: 'In Progress',
        icon: <ODCircularProgress />
    },
    refreshing: {
        label: 'In Progress',
        icon: <ODCircularProgress />
    },
    default: {
        label: 'Default',
        icon: <ODCircularProgress />
    },
    completed: {
        label: 'Completed',
        icon: <DoneSharpIcon sx={{ color: theme.palette.semantic.semanticGreen }} />
    },
    failed: {
        label: 'Failed',
        icon: <ClearIcon sx={{ color: theme.palette.semantic.semanticRed }} />
    },
    draft: {
        label: 'Draft',
        icon: <EditIcon sx={{ color: theme.palette.neutral.neutral3 }} />
    }
};

const ReportStatusInfo = ({ status, showLabel = true }) => (
    <div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
        {REPORT_STATUS_LABEL_INFO[status]?.icon}
        {showLabel && (
            <span style={{ paddingLeft: 8 }}>{REPORT_STATUS_LABEL_INFO[status]?.label}</span>
        )}
    </div>
);

export default ReportStatusInfo;
