import React, { forwardRef } from 'react';
import { Dialog, Slide, Box } from '@mui/material';
import { ODButton as SharedButton, ODIconButton } from 'shared';
import makeStyles from '@mui/styles/makeStyles';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import { BUTTON_TYPE } from 'constants/colors';

//!TODO - reusable component

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        padding: '32px',
        width: '392px !important'
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = ({
    handleClose,
    show,
    handleAction,
    actionButtonText,
    heading,
    children,
    type = 'dark',
    closeType = 'light',
    actionDisabled = false,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            classes={{ paper: classes.dialogPaper }}
            onClose={handleClose}
            {...props}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '4px 0px 52px 0px'
                }}>
                <Box component="header" sx={{ fontWeight: 400, fontSize: '18px' }}>
                    {heading}
                </Box>

                <ODIconButton
                    onClick={handleClose}
                    aria-label="close-menu"
                    buttonType={BUTTON_TYPE.TRANSPARENT}
                    icon={<CloseIcon sx={{ color: theme.palette.neutral.black }} />}
                />
            </Box>

            <>{children}</>
            <Box
                sx={{
                    marginTop: '32px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                <SharedButton
                    width="188px"
                    disabled={actionDisabled}
                    buttonText={actionButtonText}
                    onClick={handleAction}
                    buttonType={BUTTON_TYPE.BLUE}
                    aria-label="save-button"
                />
                <SharedButton
                    width="188px"
                    buttonText="Close"
                    onClick={handleClose}
                    aria-label="close-modal"
                />
            </Box>
        </Dialog>
    );
};

export default Modal;
