import ODSnackbar, { ODSNACKBAR_TYPE } from 'shared/Snackbar';

const InvalidRatePerMileSnackbar = ({
    open,
    onCloseHandler
}: {
    open: boolean;
    onCloseHandler: () => void;
}) => {
    return (
        <ODSnackbar
            text={
                'An error has occurred with your latest bid analysis change, a lane has been accepted on an invalid rate, please review and update with a valid rate to be able to refresh your bid analysis.'
            }
            open={open}
            onCloseHandler={onCloseHandler}
            type={ODSNACKBAR_TYPE.ERROR}
        />
    );
};

export default InvalidRatePerMileSnackbar;
