import { useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { DECISION } from 'constants/bidding';
import { Box, MenuItem, Select } from '@mui/material';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import theme from 'theme';
import { Check, Close, ExpandMoreSharp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import * as FS from '@fullstory/browser';

const SelectedValueContainer = styled(Box)(({ expanded }: { expanded: boolean }) => ({
    height: '32px',
    textDecoration: 'underline',
    fontSize: '14px',
    backgroundColor: expanded ? theme.palette.neutral.neutral1 : '',
    '& .MuiSelect-icon': {
        color: theme.palette.neutral.black
    },
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center'
}));

const StyledSelect = styled(Select)(({ open }: { open: boolean }) => ({
    height: '32px',
    textDecoration: 'underline',
    fontSize: '14px',
    backgroundColor: open ? theme.palette.neutral.neutral1 : '',
    '& .MuiSelect-icon': {
        color: theme.palette.neutral.black
    },
    '& .MuiSelect-select': {
        paddingBottom: 0,
        paddingTop: 0
    },
    '& .MuiInputBase-input': {
        paddingLeft: '10px',
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
}));

const SelectContainer = styled(Box)(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}));

const menuStylingProps = {
    PaperProps: {
        sx: {
            marginTop: '2px',
            backgroundColor: theme.palette.neutral.neutral1,
            '& .MuiList-root': {
                paddingLeft: '4px',
                paddingRight: '4px',
                '&.MuiMenu-list': { padding: 0 },
                '& .MuiMenuItem-root': {
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 0,
                    paddingLeft: '11px',
                    height: '32px',
                    borderRadius: '4px',
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1
                    }
                }
            }
        }
    }
};

const rejectionOption = (
    <>
        <Close
            sx={{
                color: theme.palette.semantic.semanticRed,
                marginRight: '8px'
            }}
        />
        <Box sx={{ width: '45px' }}>Reject</Box>
    </>
);

const acceptedOption = (
    <>
        <Check sx={{ color: theme.palette.success.main, marginRight: '8px' }} />
        <Box sx={{ width: '45px' }}>Accept</Box>
    </>
);
const RecommendationDropdown = ({
    value,
    setValue,
    id
}: {
    value: DECISION;
    setValue: (decision: DECISION) => void;
    id: string;
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    return (
        <SelectContainer>
            <StyledSelect
                open={expanded}
                renderValue={(value) => (
                    <SelectedValueContainer expanded={expanded}>
                        {value === DECISION.ACCEPT ? acceptedOption : rejectionOption}
                    </SelectedValueContainer>
                )}
                autoFocus={false}
                labelId="decision-dropdown-label"
                id={id}
                value={value}
                label="Decision"
                onClose={() => setExpanded(false)}
                onOpen={() => setExpanded(true)}
                IconComponent={ExpandMoreSharp}
                MenuProps={{
                    ...menuStylingProps
                }}
                onChange={(event) => {
                    FS.event(`BidAnalysis_Click_${event.target.value}_Dropdown`, {});
                    setValue(event.target.value as DECISION);
                }}>
                <MenuItem
                    value={DECISION.ACCEPT}
                    style={value === DECISION.ACCEPT ? { display: 'none' } : {}}>
                    {acceptedOption}
                </MenuItem>
                <MenuItem
                    value={DECISION.REJECT}
                    style={value === DECISION.REJECT ? { display: 'none' } : {}}>
                    {rejectionOption}
                </MenuItem>
            </StyledSelect>
        </SelectContainer>
    );
};

export const renderRecommendationDropdown = (
    info: CellContext<DataRow, string | number | object | null>,
    setLaneDecision: ({ lane, decision }: { lane: DataRow; decision: DECISION }) => void
) => (
    <RecommendationDropdown
        value={info.getValue() as DECISION}
        setValue={(selectedValue: DECISION) =>
            setLaneDecision({ lane: info.row.original, decision: selectedValue })
        }
        id={`${info.row.id}-recommendation-dropdown`}
    />
);

export default RecommendationDropdown;
