import React from 'react';
import { useSelector } from 'react-redux';
import { ODModal, ODIconButton, CommonSvgIcon } from 'shared';
import { Box } from '@mui/system';
import { getConfigurationActions, restoreAction, updateNetworkView } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Typography } from '@mui/material';
import theme from 'theme';
import { NETWORK_TABLE_TYPE, ROW_TYPE } from 'constants/network';
import ClearIcon from '@mui/icons-material/Clear';
import { BUTTON_TYPE } from 'constants/colors';
import LocalShippingSharpIcon from '@mui/icons-material/LocalShippingSharp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { updateConfiguration } from 'redux/actions';
import { useParams } from 'react-router-dom';
import { useSimulationHook, useLoadingHook } from 'utils/hooks';
import _ from 'lodash';
import { flattenObject } from 'utils/object.util';
import { formatZipcodeToCityState } from 'utils/common.util';
import {
    SubHeader,
    StyledSelectedRowDetail,
    StyledNoRowSelectedBox,
    StyledFreightBonusBody
} from './Modals.styles';

const FreightBonusModal = () => {
    const dispatch = useDispatch();
    const { base_run, compare_run }: any = useParams();
    const { createSimulationOnEdit } = useSimulationHook();
    const { showLoader } = useLoadingHook();

    const { showFreightBonusModal, isBonusModalForAdd, selectedLoad, currTab } = useSelector(
        (state: RootState) => state.NetworkReducer
    );
    const { configurationActions } = useSelector(
        ({ ConfigurationReducer }: RootState) => ConfigurationReducer
    );
    const { reportLocations } = useSelector((state: RootState) => state.ReportKPIReducer);
    const { bidConfigLocations } = useSelector((state: RootState) => state.BiddingReducer);
    const { locationVisualization } = useSelector((state: RootState) => state.settingsReducer);
    const { loadFilters } = useSelector((state: RootState) => state.ReportsReducers);

    const currReportId = compare_run || base_run;
    const isShipperTab = currTab === NETWORK_TABLE_TYPE.SHIPPERS;

    const determineSelectedType = (isShipperTab: boolean) => {
        let selectedRowType = ROW_TYPE.SHIPPER;

        if (isShipperTab) {
            selectedRowType = loadFilters?.isFilterSet ? ROW_TYPE.LANE : ROW_TYPE.SHIPPER;
        } else {
            selectedRowType = loadFilters?.isFilterSet ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE;
        }
        return selectedRowType;
    };

    const DisplaySubHeader = () => {
        let isPural = selectedLoad?.length > 0 ? 's' : '';
        let selectedRowType = _.capitalize(determineSelectedType(isShipperTab));

        return (
            <SubHeader>{`${
                selectedLoad?.length > 0
                    ? selectedLoad?.length
                    : Object.keys(selectedLoad).length > 0
                    ? 1
                    : 0
            } ${selectedRowType}${isPural} Selected`}</SubHeader>
        );
    };

    const DisplaySelectedRowDetail = () => {
        if (selectedLoad.length > 0) {
            return (
                <>
                    {selectedLoad.map((item: any) => {
                        return (
                            <StyledSelectedRowDetail
                                sx={{
                                    fontWeight: 600
                                }}>
                                <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                                    <Box sx={{ marginRight: '16px', display: 'flex' }}>
                                        {determineSelectedType(isShipperTab) ===
                                        ROW_TYPE.SHIPPER ? (
                                            <LocalShippingSharpIcon />
                                        ) : (
                                            <CommonSvgIcon />
                                        )}
                                    </Box>

                                    {determineSelectedType(isShipperTab) === ROW_TYPE.SHIPPER
                                        ? item?.id
                                        : formatZipcodeToCityState(
                                              item?.id,
                                              reportLocations,
                                              bidConfigLocations,
                                              locationVisualization,
                                              <ArrowForwardIcon
                                                  sx={{
                                                      color: theme.palette.neutral.neutral4,
                                                      margin: '0px 19px'
                                                  }}
                                              />,
                                              { width: '115px' },
                                              {}
                                          )}
                                </Box>

                                <ODIconButton
                                    sx={{ marginRight: 0, padding: '0 1px 0 0' }}
                                    disableHover={true}
                                    id={'delete'}
                                    onClick={() =>
                                        handleRemoveItem(item, determineSelectedType(isShipperTab))
                                    }
                                    buttonType={BUTTON_TYPE.TRANSPARENT}
                                    icon={<ClearIcon sx={{ fill: theme.palette.neutral.black }} />}
                                />
                            </StyledSelectedRowDetail>
                        );
                    })}
                </>
            );
        } else {
            return (
                <>
                    {Object.keys(selectedLoad).length > 0 ? (
                        <StyledSelectedRowDetail
                            sx={{
                                fontWeight: 400
                            }}>
                            <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                                <Box sx={{ marginRight: '16px', display: 'flex' }}>
                                    {determineSelectedType(isShipperTab) === ROW_TYPE.SHIPPER ? (
                                        <LocalShippingSharpIcon />
                                    ) : (
                                        <CommonSvgIcon />
                                    )}
                                </Box>
                                {formatZipcodeToCityState(
                                    selectedLoad.id,
                                    reportLocations,
                                    bidConfigLocations,
                                    locationVisualization
                                )}
                            </Box>
                        </StyledSelectedRowDetail>
                    ) : (
                        <StyledNoRowSelectedBox>
                            No lanes/shippers have been selected.
                        </StyledNoRowSelectedBox>
                    )}
                </>
            );
        }
    };

    const handleRemoveItem = (item: any, type: string) => {
        const filteredLoads = _.filter(selectedLoad, (user) => {
            return user.id !== item.id;
        });

        dispatch(
            updateNetworkView({
                selectedLoad: filteredLoads.length === 0 ? {} : filteredLoads
            })
        );
    };

    const handleSubmitBonus = async () => {
        await dispatch(updateNetworkView({ showFreightBonusModal: false }));
        showLoader(true);
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;
        let actions: any[] = [];

        if (isBonusModalForAdd) {
            if (selectedLoad?.length && selectedLoad?.length > 0) {
                const selected = selectedLoad.map((item: any) => {
                    return {
                        type: 'bonus',
                        item_type: 'shipper-lane',
                        value: {
                            shipper:
                                determineSelectedType(isShipperTab) === ROW_TYPE.SHIPPER
                                    ? item?.id
                                    : loadFilters?.filterBy?.id || null,
                            lane:
                                determineSelectedType(isShipperTab) === ROW_TYPE.LANE
                                    ? item?.id
                                    : loadFilters?.filterBy?.id || null,
                            type: 'bonus',
                            value: isBonusModalForAdd
                        }
                    };
                });

                actions = [...selected];
            } else {
                if (Object.keys(selectedLoad).length > 0) {
                    actions.push({
                        type: 'bonus',
                        item_type: 'shipper-lane',
                        value: {
                            shipper:
                                determineSelectedType(isShipperTab) === ROW_TYPE.SHIPPER
                                    ? selectedLoad?.id
                                    : loadFilters?.filterBy?.id || null,
                            lane:
                                determineSelectedType(isShipperTab) === ROW_TYPE.LANE
                                    ? selectedLoad?.id
                                    : loadFilters?.filterBy?.id || null,
                            type: 'bonus',
                            value: isBonusModalForAdd
                        }
                    });
                }
            }

            await dispatch(updateConfiguration(currReportId, simulationId, actions));
        } else {
            const flattenedActions = configurationActions.map((action: any) =>
                flattenObject(action)
            );

            if (selectedLoad?.length && selectedLoad?.length > 0) {
                let filteredActions = flattenedActions.filter((action: any) => {
                    return selectedLoad.some((load: any) => {
                        if (loadFilters?.isFilterSet) {
                            return load?.id === action[isShipperTab ? 'lane' : 'shipper'];
                        } else {
                            return load?.id === action[isShipperTab ? 'shipper' : 'lane'];
                        }
                    });
                });

                actions = filteredActions.map((action: any) => {
                    return {
                        id: action?.id,
                        item_type: 'shipper-lane',
                        type: 'restore',
                        value: {
                            lane: action?.lane,
                            shipper: action?.shipper
                        }
                    };
                });
            } else {
                if (Object.keys(selectedLoad).length > 0) {
                    let filteredAction = flattenedActions.filter((action: any) => {
                        if (loadFilters?.isFilterSet) {
                            return action[isShipperTab ? 'lane' : 'shipper'] === selectedLoad?.id;
                        } else {
                            return action[isShipperTab ? 'shipper' : 'lane'] === selectedLoad?.id;
                        }
                    })[0];

                    actions = [
                        {
                            id: filteredAction?.id,
                            item_type: 'shipper-lane',
                            type: 'restore',
                            value: {
                                lane: filteredAction?.lane,
                                shipper: filteredAction?.shipper
                            }
                        }
                    ];
                }
            }
            await dispatch(restoreAction(currReportId, simulationId, actions));
        }

        await dispatch(getConfigurationActions(currReportId, simulationId));
        showLoader(false);
    };

    return (
        <ODModal
            show={showFreightBonusModal}
            heading={isBonusModalForAdd ? 'Apply Bonus' : 'Remove Applied Bonus'}
            handleClose={() => {
                dispatch(updateNetworkView({ showFreightBonusModal: false }));
            }}
            actionButtonText={'save'}
            actionDisabled={selectedLoad?.length <= 0 || Object.keys(selectedLoad).length <= 0}
            handleAction={() => handleSubmitBonus()}
            children={
                <>
                    {isBonusModalForAdd && (
                        <Typography sx={{ fontSize: '14px' }}>
                            Adding a freight bonus for a lane or shipper will encourage the
                            simulation to cover more of the loads using your own fleet, without
                            affecting the revenue calculations. This can help ensure that
                            commitments are being met for key lanes or customers.
                        </Typography>
                    )}
                    <DisplaySubHeader />
                    <StyledFreightBonusBody>
                        <DisplaySelectedRowDetail />
                    </StyledFreightBonusBody>
                </>
            }
        />
    );
};

export default FreightBonusModal;
