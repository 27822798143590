import { generateResponse, numberRevalidation } from 'utils/flatfile.util';
import {
    locationCityStateValidationFieldhookCallback,
    locationCityStateZipCodeValidationFieldhookCallback,
    locationZipCodeSemanticValidationFieldhookCallback,
    locationZipCodeSyntaxValidationFieldhookCallback
} from './fieldhooks';
import API from 'utils/axios';

export const recordChangeCallback = async (
    recordRow,
    index,
    mode,
    defaultRateType,
    featureFlags
) => {
    let returnValues = {};
    // Populate each empty cell with the default_rate_type from bid config and null for sales_ranking
    let validateRateType = await rateTypeRecordhookCallback(recordRow, defaultRateType);
    let validateSalesRanking = await salesRankingRecordhookCallback(recordRow);

    if (mode === 'change') {
        // Location validations
        const originLocation = {
            origin_zip: [[recordRow.origin_zip, index]],
            origin_city_state: [[recordRow.origin_city_state, index]],
            origin_city_state_zip: [[recordRow.origin_city_state_zip, index]]
        };

        const destinationLocation = {
            destination_zip: [[recordRow.destination_zip, index]],
            destination_city_state: [[recordRow.destination_city_state, index]],
            destination_city_state_zip: [[recordRow.destination_city_state_zip, index]]
        };

        if (featureFlags.flexibleLocationFlatfileFlag) {
            originLocation.origin_zip = {
                ...(
                    await locationZipCodeSemanticValidationFieldhookCallback(
                        originLocation.origin_zip,
                        'origin_zip'
                    )
                )[0][0]
            };
            originLocation.origin_city_state_zip = {
                ...(
                    await locationCityStateZipCodeValidationFieldhookCallback(
                        originLocation.origin_city_state_zip,
                        'origin_city_state_zip'
                    )
                )[0][0]
            };
            originLocation.origin_city_state = {
                ...(
                    await locationCityStateValidationFieldhookCallback(
                        originLocation.origin_city_state,
                        'origin_city_state'
                    )
                )[0][0]
            };

            destinationLocation.destination_city_state_zip = {
                ...(
                    await locationCityStateZipCodeValidationFieldhookCallback(
                        destinationLocation.destination_city_state_zip,
                        'destination_city_state_zip'
                    )
                )[0][0]
            };
            destinationLocation.destination_city_state = {
                ...(
                    await locationCityStateValidationFieldhookCallback(
                        destinationLocation.destination_city_state,
                        'destination_city_state'
                    )
                )[0][0]
            };
            destinationLocation.destination_zip = {
                ...(
                    await locationZipCodeSemanticValidationFieldhookCallback(
                        destinationLocation.destination_zip,
                        'destination_zip'
                    )
                )[0][0]
            };
        } else {
            if (featureFlags.zipCodeCliffordValidation) {
                originLocation.origin_zip = {
                    ...(
                        await locationZipCodeSemanticValidationFieldhookCallback(
                            originLocation.origin_zip,
                            'origin_zip'
                        )
                    )[0][0]
                };
                destinationLocation.destination_zip = {
                    ...(
                        await locationZipCodeSemanticValidationFieldhookCallback(
                            destinationLocation.destination_zip,
                            'destination_zip'
                        )
                    )[0][0]
                };
            } else {
                originLocation.origin_zip = {
                    ...(
                        await locationZipCodeSyntaxValidationFieldhookCallback(
                            originLocation.origin_zip,
                            'origin_zip'
                        )
                    )[0][0]
                };
                destinationLocation.destination_zip = {
                    ...(
                        await locationZipCodeSyntaxValidationFieldhookCallback(
                            destinationLocation.destination_zip,
                            'destination_zip'
                        )
                    )[0][0]
                };
            }
        }

        //TODO: will add the new mileage formatting in ODPT-3990
        let validatedMiles = await mileageValidationRecordhookCallback(recordRow);
        let validatedLoadVolume = await numberRevalidation(
            'load_volume',
            recordRow.load_volume,
            false
        );
        let validatedProposedRatePerMile = await numberRevalidation(
            'rate_per_mile',
            recordRow.rate_per_mile
        );

        // Optional Fields
        let validatedOtherRevenue = {};
        if (recordRow.other_revenue) {
            validatedOtherRevenue = await numberRevalidation(
                'other_revenue',
                recordRow.other_revenue
            );
        }
        let validatedOtherRevenuePerMile = {};
        if (recordRow.other_revenue_per_mile) {
            validatedOtherRevenuePerMile = await numberRevalidation(
                'other_revenue_per_mile',
                recordRow.other_revenue_per_mile
            );
        }

        returnValues = {
            ...originLocation,
            ...destinationLocation,
            ...validatedMiles,
            ...validatedLoadVolume,
            ...validatedProposedRatePerMile,
            ...validatedOtherRevenue,
            ...validatedOtherRevenuePerMile
        };
    }

    return { ...returnValues, ...validateRateType, ...validateSalesRanking };
};

const mileageValidationRecordhookCallback = async (record) => {
    let mileageValues = {};
    mileageValues['origin_zip'] = [[record.origin_zip, 1]];
    mileageValues['destination_zip'] = [[record.destination_zip, 1]];

    if (!record.mileage || record.mileage === '0') {
        mileageValues['mileage'] = [['', 1]];
    } else {
        mileageValues['mileage'] = [[record.mileage, 1]];
    }

    const responseValidate = await API.post('/bidding/flatfile/validate/mileage/', mileageValues);

    if (responseValidate.data['mileage'][0]) {
        return { mileage: responseValidate.data['mileage'][0][0] };
    } else {
        return {};
    }
};

const rateTypeRecordhookCallback = async (record, defaultRateType) => {
    if (!record.preferred_rate_type) {
        return generateResponse('record', defaultRateType, null, null, 'preferred_rate_type');
    } else {
        return generateResponse(
            'record',
            record.preferred_rate_type,
            null,
            null,
            'preferred_rate_type'
        );
    }
};
const salesRankingRecordhookCallback = async (record) => {
    if (!record.sales_ranking) {
        return generateResponse('record', null, null, null, 'sales_ranking');
    } else {
        return generateResponse('record', record.sales_ranking, null, null, 'sales_ranking');
    }
};
