import { Box, Tooltip, Theme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps } from '@mui/system';

interface InfoMessageProps {
    message: string;
    sx?: SxProps<Theme>;
}
const InfoMessage = ({ message, sx }: InfoMessageProps) => {
    return (
        <Box
            id="header-action"
            sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '8px',
                ...sx
            }}>
            <Tooltip
                sx={{
                    '.MuiTooltip-tooltip': {
                        color: 'white',
                        fontSize: '14px'
                    }
                }}
                title={message}>
                <InfoOutlinedIcon />
            </Tooltip>
        </Box>
    );
};

export default InfoMessage;
