import React from 'react';
import LazyLoad from 'react-lazy-load';

export const LazyLoadCustom = ({ children, height = 42, ...rest }) => {
    return (
        <LazyLoad
            once
            overflow
            height={height}
            {...rest}
            offset={20}
            elementType="tr"
            throttle={150}
            debounce={false}>
            <> {children} </>
        </LazyLoad>
    );
};
