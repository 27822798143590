import { combineReducers } from 'redux';
import SignInReducer from './signInReducer';
import ReportsReducers from './reportsReducer';
import NetworkReducer from './networkReducer';
import ReportKPIReducer from './reportKPIReducer';
import ConfigurationReducer from './configurationReducer';
import settingsReducer from './settingsReducer';
import FilesReducer from './filesReducer';
import DriversReducer from './driversReducer';
import BiddingReducer from './biddingReducer';
import LoadingReducer from './loadingReducer';
import TableLayoutReducer from './tableLayoutReducer';
import BidAnalysisReducer from './bidAnalysisReducer';
import ModalsReducer from './modalsReducer';

const rootReducer = combineReducers({
    BiddingReducer,
    BidAnalysisReducer,
    ConfigurationReducer,
    DriversReducer,
    FilesReducer,
    NetworkReducer,
    ReportKPIReducer,
    ReportsReducers,
    settingsReducer,
    LoadingReducer,
    SignInReducer,
    TableLayoutReducer,
    ModalsReducer
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
