import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'theme';

const StyledInstructions = styled(Box)(() => ({
    color: theme.palette.neutral.neutral6,
    fontSize: '12px',
    marginBottom: '8px'
}));

export { StyledInstructions };
