import { ODDialog } from 'shared';
import { ConfigurationIcon } from 'assets/images';
import theme from 'theme';
const SAVE_BID_VERBIAGE = 'Saving your edited bid file will override the original bid file.';

type SaveBidDialogProps = {
    onClose: () => void;
    open: boolean;
    onSave: () => void;
    title: string | null;
};
const SaveBidDialog = (props: SaveBidDialogProps) => {
    const { onClose, open, onSave, title } = props;

    return (
        <ODDialog
            actionButtonText="Save"
            itemIcon={<ConfigurationIcon fill={theme.palette.neutral.black} />}
            itemTitle={title}
            dialogDescription="Save Bid File"
            message={SAVE_BID_VERBIAGE}
            onClose={onClose}
            onConfirm={() => {
                onSave();
                onClose();
            }}
            open={open}></ODDialog>
    );
};

export default SaveBidDialog;
