import { createSvgIcon, SvgIcon, SvgIconProps } from '@mui/material';

const ODAlertIconWithoutViewbox = createSvgIcon(
    <path d="M.75 15.75h16.5L9 1.5.75 15.75z" />,
    'ODAlertIcon'
);

export const ODAlertIcon = (props: SvgIconProps) => (
    <SvgIcon component={ODAlertIconWithoutViewbox} viewBox="0 0 18 18" {...props} />
);
