import { Box } from '@mui/material';
import SwapHorizSharpIcon from '@mui/icons-material/SwapHorizSharp';

export const defaultColumnWidth = '130px';

export const networkTableHeightById = (
    isListView,
    drilledIn,
    isFilterOn,
    searchBarVisible,
    fullWidthMapViewTables,
    isShipperLaneTab = true
) => {
    let height = '0px';

    if (!isListView) {
        if (!fullWidthMapViewTables) {
            if (!isFilterOn) {
                height = 'calc(100vh - 13.75rem)';
            } else {
                if (!drilledIn) {
                    height = '100%';
                } else {
                    height = searchBarVisible
                        ? isFilterOn
                            ? 'calc(100vh - 24rem)'
                            : 'calc(100vh - 25rem)'
                        : 'calc(100vh - 22.8rem)';
                }
            }
        } else {
            if (!isFilterOn) {
                height = '48.8vh'; // TODO Test this. May need calc(100vh - xx.x rem)
            } else {
                if (!drilledIn) {
                    height = '100%';
                } else {
                    if (searchBarVisible) {
                        height = '4.5vh';
                    } else {
                        height = '10.4vh';
                    }
                }
            }
        }
    } else {
        if (!isFilterOn) {
            if (isListView) {
                height = 'calc(100vh - 11.7rem)';
            } else {
                height = `calc(100vh - 11.75rem)`;
            }
        } else {
            if (!drilledIn) {
                height = '100%';
            } else {
                height = searchBarVisible
                    ? !isShipperLaneTab
                        ? `calc(100vh - 20.6rem)`
                        : `calc(100vh - 21.1rem)`
                    : 'calc(100vh - 24.2rem)';
            }
        }
    }

    return height;
};

export const CompareHeaderText = ({ text }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SwapHorizSharpIcon sx={{ marginRight: '8px' }} />
        {text}
    </Box>
);
