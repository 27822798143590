import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { PersonAdd, FiberManualRecord } from '@mui/icons-material/';
import { Tooltip, Avatar } from '@mui/material/';
const { Marker } = require('react-map-gl');

const useStyles = (theme) => ({
    markerAvatar: {
        width: 30,
        height: 30,
        left: -15,
        top: -15,
        backgroundColor: '#535353'
    }
});

class Markers extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { classes, addDriver, addDomicile } = this.props;

        return (
            <React.Fragment key={'add-new-driver'}>
                <Marker
                    key={'add-driver'}
                    latitude={addDriver.location.latitude}
                    longitude={addDriver.location.longitude}>
                    <Tooltip title={`${addDriver.location.place}, ${addDriver.location.region}`}>
                        <Avatar className={classes.markerAvatar}>
                            <div
                                className={`addDriverMarker`}
                                onClick={() => {
                                    addDomicile(addDriver);
                                }}>
                                {addDriver.flag ? <PersonAdd /> : <FiberManualRecord />}
                            </div>
                        </Avatar>
                    </Tooltip>
                </Marker>
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles, { withTheme: true })(Markers);
