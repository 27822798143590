import ODSnackbar, { ODSNACKBAR_TYPE } from 'shared/Snackbar';

export enum BID_GOAL_ALERT_TYPE {
    EXCEEDS_TOTAL_VOLUME = 'exceedsTotalVolume',
    EXCEEDS_TOTAL_LANES = 'exceedsTotalLanes',
    EXCEEDS_TOTAL_REVENUE = 'exceedsTotalRevenue',
    TARGET_SPECIFIED = 'targetSpecified',
    EXCEEDS_ACCEPTABLE_REVENUE = 'exceedsAcceptableRevenue',
    EXCEEDS_ACCEPTABLE_LANES = 'exceedsAcceptableLanes',
    EXCEEDS_ACCEPTABLE_VOLUME = 'exceedsAcceptableVolume',
    TARGET_LESS_THAN_ACCEPTED = 'targetLessThanAccepted'
}

const BidGoalAlert = ({
    alertType,
    open,
    onCloseHandler
}: {
    alertType: BID_GOAL_ALERT_TYPE | null;
    open: boolean;
    onCloseHandler: () => void;
}) => {
    let message = '';
    let type = ODSNACKBAR_TYPE.SUCCESS;

    switch (alertType) {
        case BID_GOAL_ALERT_TYPE.EXCEEDS_TOTAL_LANES:
            message = 'You have entered a target value that exceeds the available lanes to accept.';
            type = ODSNACKBAR_TYPE.ERROR;
            break;
        case BID_GOAL_ALERT_TYPE.EXCEEDS_TOTAL_VOLUME:
            message = 'You have entered a target value that exceeds the available bid volume.';
            type = ODSNACKBAR_TYPE.ERROR;
            break;
        case BID_GOAL_ALERT_TYPE.EXCEEDS_TOTAL_REVENUE:
            message =
                'You have entered a target value that exceeds the available revenue in this bid.';
            type = ODSNACKBAR_TYPE.ERROR;
            break;
        case BID_GOAL_ALERT_TYPE.TARGET_LESS_THAN_ACCEPTED:
            message = 'The target goal must be higher than the currently accepted value.';
            type = ODSNACKBAR_TYPE.ERROR;
            break;
        case BID_GOAL_ALERT_TYPE.EXCEEDS_ACCEPTABLE_LANES:
            message =
                'Your lanes target could not be hit because the remaining lanes fall below the acceptable lane score threshold. To try and get closer to your lanes target you can manually accept lanes with a lanes score below 1.';
            type = ODSNACKBAR_TYPE.ERROR;
            break;
        case BID_GOAL_ALERT_TYPE.EXCEEDS_ACCEPTABLE_VOLUME:
            message =
                'Your volume target could not be hit because the remaining lanes fall below the acceptable lane score threshold. To try and get closer to your volume target you can manually accept lanes with a lanes score below 1.';
            type = ODSNACKBAR_TYPE.ERROR;
            break;

        case BID_GOAL_ALERT_TYPE.EXCEEDS_ACCEPTABLE_REVENUE:
            message =
                'Your revenue target could not be hit because there is not enough volume in the selected accepted lanes. To try and get closer to your revenue target you can manually accept lanes with a lanes score below 1 or adjust the rate on lower profit per mile lanes.';
            type = ODSNACKBAR_TYPE.ERROR;
            break;
        case BID_GOAL_ALERT_TYPE.TARGET_SPECIFIED:
            message =
                'These lanes fall outside the acceptable threshold of your Lane Score. Accepting these lanes may impact the stability of your network. Any lanes with a lanes score below 1 will have to be entered manually, please review your decisions carefully before proceeding.';
            type = ODSNACKBAR_TYPE.WARNING;
    }
    return <ODSnackbar text={message} open={open} onCloseHandler={onCloseHandler} type={type} />;
};

export default BidGoalAlert;
