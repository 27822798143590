import {
    UPDATE_HIDDEN_COLUMNS_ARRAYS,
    RESET_HIDDEN_COLUMNS_ARRAYS,
    UPDATE_TABLE_IDS
} from '../constants/actionTypes';
import { getDefaultHiddenColumns } from './defaultTableLayoutDetails';
const initialState = {
    hiddenColumnsArrays: {
        shippers_table: [],
        comparison_shippers_table: [],
        lanes_table: [],
        comparison_lanes_table: [],
        domiciles_table: [],
        comparison_domiciles_table: [],
        driver_types_table: [],
        comparison_driver_types_table: [],
        driver_ids_table: [],
        motions_table: [],
        bid_manager_table: [],
        bid_configuration_table: [],
        bid_analysis_table: [],
        flows_table: []
    },
    hiddenColumnsTableIds: {
        shippers_table: null,
        comparison_shippers_table: null,
        lanes_table: null,
        comparison_lanes_table: null,
        domiciles_table: null,
        comparison_domiciles_table: null,
        driver_types_table: null,
        comparison_driver_types_table: null,
        driver_ids_table: null,
        motions_table: null,
        bid_manager_table: null,
        bid_configuration_table: null,
        bid_analysis_table: null,
        flows_table: null
    }
};
const resetHiddenColumns = (state, action) => {
    const newHiddenColumnsArrays = { ...state.hiddenColumnsArrays };
    newHiddenColumnsArrays[action.payload.tableName] = getDefaultHiddenColumns(
        action.payload.tableName,
        action.payload.specialCase
    );
    return {
        ...state,
        hiddenColumnsArrays: newHiddenColumnsArrays
    };
};
const updateHiddenColumnsArrays = (state, action) => {
    const newHiddenColumnsArrays = {
        ...state.hiddenColumnsArrays
    };
    newHiddenColumnsArrays[action.payload.tableName] = action.payload.hiddenColumns;
    return {
        ...state,
        hiddenColumnsArrays: newHiddenColumnsArrays
    };
};
const updateTableIds = (state, action) => {
    const newHiddenColumnsTableIds = {
        ...state.hiddenColumnsTableIds
    };
    newHiddenColumnsTableIds[action.payload.tableName] = action.payload.tableId;
    return {
        ...state,
        hiddenColumnsTableIds: newHiddenColumnsTableIds
    };
};

const tableLayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_TABLE_IDS:
            return updateTableIds(state, action);
        case UPDATE_HIDDEN_COLUMNS_ARRAYS:
            return updateHiddenColumnsArrays(state, action);
        case RESET_HIDDEN_COLUMNS_ARRAYS:
            return resetHiddenColumns(state, action);
        default:
            return state;
    }
};

export default tableLayoutReducer;
