import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/reducers';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams, useHistory } from 'react-router-dom';
import SwapHorizSharpIcon from '@mui/icons-material/SwapHorizSharp';
import { setSelectedReports } from 'redux/actions';
import theme from 'theme';
import { BUTTON_TYPE } from 'constants/colors';
import { ODButton, ODIconButton } from 'shared';
import { useParamsProps } from 'types/hooks';
import { StyledCheckbox } from './NetworkTopNavbar.styles';

import { Box } from '@mui/material';
import useReportsHook from 'utils/hooks/useReportsHook';
interface selectReportProps {
    setAction: React.Dispatch<React.SetStateAction<string>>;
    iconButton?: boolean;
}

const CompareReportBtn = ({ setAction, iconButton }: selectReportProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { compare_run, base_run }: useParamsProps = useParams();
    const { selectedCompareReport, selectedBaseReport } = useSelector(
        (state: RootState) => state.NetworkReducer
    );
    const { handleSwap, handleRemoveCompareReport } = useReportsHook();

    const handleCheckboxClick = (e: any) => {
        const _selectedBaseReport = { ...selectedBaseReport };
        const _selectedCompareReport = { ...selectedCompareReport };

        if (e.target.value === 'base') {
            _selectedBaseReport.enabled = !_selectedBaseReport.enabled;
            if (!_selectedBaseReport.enabled) {
                history.push(window.location.pathname.replace(base_run + '/', ''));
            } else {
                history.push(
                    window.location.pathname.replace(
                        base_run + '/',
                        selectedBaseReport.id + '/' + selectedCompareReport.id + '/'
                    )
                );
            }
        }
        if (e.target.value === 'compare') {
            _selectedCompareReport.enabled = !_selectedCompareReport.enabled;
            if (!_selectedCompareReport.enabled) {
                history.push(window.location.pathname.replace(compare_run + '/', ''));
            } else {
                history.push(
                    window.location.pathname.replace(
                        base_run + '/',
                        base_run + '/' + selectedCompareReport.id + '/'
                    )
                );
            }
        }
        dispatch(
            setSelectedReports({
                selectedBaseReport: _selectedBaseReport,
                selectedCompareReport: _selectedCompareReport
            })
        );
        if (!(_selectedBaseReport.enabled || _selectedCompareReport.enabled))
            setAction('SelectReport');
    };

    const removeCompareReport = () => {
        handleRemoveCompareReport();
        dispatch(
            setSelectedReports({
                selectedBaseReport: { id: selectedBaseReport.id, enabled: true },
                selectedCompareReport: {}
            })
        );

        if (compare_run) {
            history.push(window.location.pathname.replace(compare_run + '/', ''));
        } else {
            history.push(
                window.location.pathname.replace(base_run as string, selectedBaseReport.id)
            );
        }
    };

    const swapReports = async () => {
        const payload = {
            selectedBaseReport: { id: selectedCompareReport.id, enabled: true },
            selectedCompareReport: { id: selectedBaseReport.id, enabled: true }
        };
        await dispatch(setSelectedReports(payload));
        handleSwap();
        if (base_run && compare_run) {
            history.push(
                window.location.pathname
                    .replace(base_run, 'placeholder')
                    .replace(compare_run, selectedBaseReport.id)
                    .replace('placeholder', selectedCompareReport.id)
            );
        } else {
            history.push(
                window.location.pathname.replace(
                    base_run + '/',
                    selectedCompareReport.id + '/' + selectedBaseReport.id + '/'
                )
            );
        }
    };

    return (
        <Box sx={{ display: 'flex', marginX: '8px' }}>
            {selectedCompareReport.id ? (
                <>
                    {selectedBaseReport.id && selectedCompareReport.id ? (
                        <ODIconButton
                            buttonType={BUTTON_TYPE.DARK}
                            sx={{
                                borderRadius: '4px 0 0 4px',
                                width: '40px',
                                height: '40px'
                            }}
                            icon={
                                <SwapHorizSharpIcon
                                    sx={{
                                        color: selectedBaseReport.enabled
                                            ? theme.palette.white
                                            : theme.palette.black
                                    }}
                                    onClick={(e) => {
                                        swapReports();
                                    }}
                                />
                            }></ODIconButton>
                    ) : null}
                    <ODButton
                        id={'network-compare-report-name-button'}
                        sx={{
                            borderLeft: `1px solid ${theme.palette.ODBlue.odBlue7}`,
                            borderRadius: '0 4px 4px 0'
                        }}
                        buttonType={
                            !selectedCompareReport.id ? BUTTON_TYPE.LIGHT : BUTTON_TYPE.DARK
                        }
                        startIcon={
                            selectedBaseReport.id && selectedCompareReport.id ? (
                                <StyledCheckbox
                                    flag={true}
                                    disabled={!selectedBaseReport.enabled}
                                    checked={selectedCompareReport.enabled}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCheckboxClick(e);
                                    }}
                                    value="compare"
                                    inputProps={{ 'aria-label': 'compare' }}
                                    // enabled={selectedCompareReport.enabled}
                                />
                            ) : (
                                <AddIcon />
                            )
                        }
                        endIcon={
                            selectedBaseReport.id && selectedCompareReport.id ? (
                                <ClearIcon
                                    sx={{
                                        color: selectedBaseReport.enabled
                                            ? theme.palette.white
                                            : theme.palette.black
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeCompareReport();
                                    }}
                                />
                            ) : null
                        }
                        buttonText={selectedCompareReport.name || 'Compare Analyses'}
                        tooltipText={selectedCompareReport.name || 'Compare Analyses'}
                        buttonTextWidth={'103px'}
                        onClick={() => setAction('CompareReport')}
                    />
                </>
            ) : iconButton ? (
                <ODIconButton
                    buttonType={BUTTON_TYPE.TRANSPARENT}
                    enableTooltip
                    title="Compare Analyses"
                    icon={
                        <SwapHorizSharpIcon
                            sx={{
                                color: theme.palette.neutral.black
                            }}
                        />
                    }
                    sx={{
                        width: '40px',
                        height: '40px',
                        color: theme.palette.black,
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral2
                        }
                    }}
                    onClick={() => setAction('CompareReport')}
                />
            ) : (
                <ODButton
                    buttonType={BUTTON_TYPE.TRANSPARENT}
                    startIcon={<SwapHorizSharpIcon />}
                    buttonText="Compare Analyses"
                    onClick={() => setAction('CompareReport')}
                    sx={{
                        color: theme.palette.black,
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral2
                        }
                    }}
                />
            )}
        </Box>
    );
};

export default CompareReportBtn;
