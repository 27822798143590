import * as React from 'react';
import { Tabs, Tab, Box, styled } from '@mui/material';
import palette from 'theme/palette';
import { DriverLanesIcon } from 'assets/images';

export const BidConfigHeaderTab = styled(Tab)(() => ({
    marginX: '16px',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none',
    color: palette.black,
    '&.Mui-selected': { color: palette.black }
}));

export default function HeaderTabs({ lanesLength }: { lanesLength: number | null }) {
    const [currTabIndex, setCurrTabIndex] = React.useState(0);
    const handleTabIndexChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrTabIndex(newValue);
    };

    return (
        <Box width="100%">
            <Box borderBottom={1} borderColor={palette.neutral.neutral1}>
                <Tabs
                    sx={{ height: '56px' }}
                    value={currTabIndex}
                    onChange={handleTabIndexChange}
                    aria-label="bid config tabs">
                    <BidConfigHeaderTab
                        aria-label="all lanes tab"
                        disableRipple
                        label={
                            <>
                                <DriverLanesIcon
                                    style={{
                                        marginRight: 16,
                                        fill: palette.black
                                    }}
                                />
                                {`(${lanesLength}) All Lanes`}
                            </>
                        }
                    />
                </Tabs>
            </Box>
            {/* TODO when we support multiple tabs, we will wrap edit bid config component inside the tab panel*/}
            {/* {false && (
                <Box value={value} index={0}>
                    -
                </Box>
            )} */}
        </Box>
    );
}
