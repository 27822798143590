import React from 'react';
import { useDispatch } from 'react-redux';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { ODFooterActionBar, ODButton } from 'shared';
import palette from 'theme/palette';
import { ConfigurationIcon } from 'assets/images';
import { BUTTON_TYPE } from 'constants/colors';
import { updateBiddingState } from 'redux/actions';
import { MODAL_TYPE, SLIDE_OUT_TYPE } from 'constants/bidding';

interface BidFileManagerActionBarProps {
    checkedFileIds: any[];
    bids: any[];
    setOpenBidConfig: React.Dispatch<React.SetStateAction<boolean>>;
}

const BidFileManagerActionBar = ({
    checkedFileIds,
    bids,
    setOpenBidConfig
}: BidFileManagerActionBarProps) => {
    const dispatch = useDispatch();
    let isOneRowChecked = checkedFileIds?.length! === 1;
    let selectedFiles: any = [];
    let displayedActionBarName;

    if (checkedFileIds?.length === 1) {
        selectedFiles = bids.find((file: any) => file.id === checkedFileIds[0]);
        displayedActionBarName = selectedFiles?.file_name;
    } else if (checkedFileIds?.length > 1) {
        selectedFiles = bids.filter((file: any) => checkedFileIds.includes(file.id));
        displayedActionBarName = `(${selectedFiles.length}) Selected`;
    } else {
        selectedFiles = [];
        displayedActionBarName = '';
    }

    const disableDownloadBtn = () => {
        if (selectedFiles?.length > 0) {
            let draftFiles = selectedFiles?.filter((file: any) =>
                ['draft', 'empty'].includes(file?.status)
            );

            return selectedFiles?.length === draftFiles.length;
        } else {
            return selectedFiles?.disableDownload;
        }
    };

    return (
        <ODFooterActionBar
            buttonsOverride={
                <>
                    <ODButton
                        buttonText={'Delete Files'}
                        sx={{ marginRight: '16px' }}
                        endIcon={<DeleteSharpIcon />}
                        buttonType={BUTTON_TYPE.BLUE}
                        onClick={() => {
                            if (isOneRowChecked) {
                                dispatch(
                                    updateBiddingState({
                                        modalMode: {
                                            mode: MODAL_TYPE.DELETE_FILE,
                                            data: {
                                                id: [checkedFileIds],
                                                data: [selectedFiles]
                                            }
                                        }
                                    })
                                );
                            } else {
                                dispatch(
                                    updateBiddingState({
                                        modalMode: {
                                            mode: MODAL_TYPE.DELETE_FILE,
                                            data: {
                                                id: checkedFileIds,
                                                data: selectedFiles
                                            }
                                        }
                                    })
                                );
                            }
                        }}
                    />
                    {isOneRowChecked && (
                        <ODButton
                            buttonText={'Edit File Details'}
                            endIcon={<EditIcon />}
                            sx={{ marginRight: '16px' }}
                            buttonType={BUTTON_TYPE.BLUE}
                            onClick={() => {
                                dispatch(
                                    updateBiddingState({
                                        slideoutMode: {
                                            mode: SLIDE_OUT_TYPE.EDIT_FILE,
                                            data: {
                                                id: selectedFiles?.id,
                                                data: selectedFiles
                                            }
                                        }
                                    })
                                );
                            }}
                        />
                    )}
                    <ODButton
                        disabled={disableDownloadBtn()}
                        buttonText={'Download Files'}
                        sx={{ marginRight: isOneRowChecked ? '16px' : 0 }}
                        endIcon={<DownloadIcon />}
                        buttonType={isOneRowChecked ? BUTTON_TYPE.BLUE : BUTTON_TYPE.BLUE}
                        onClick={() => {
                            if (isOneRowChecked) {
                                dispatch(
                                    updateBiddingState({
                                        modalMode: {
                                            mode: MODAL_TYPE.DOWNLOAD_FILE,
                                            data: {
                                                id: [checkedFileIds],
                                                data: [selectedFiles]
                                            }
                                        }
                                    })
                                );
                            } else {
                                dispatch(
                                    updateBiddingState({
                                        modalMode: {
                                            mode: MODAL_TYPE.DOWNLOAD_FILE,
                                            data: {
                                                id: checkedFileIds,
                                                data: selectedFiles
                                            }
                                        }
                                    })
                                );
                            }
                        }}
                    />
                    {isOneRowChecked && (
                        <ODButton
                            buttonText={'View File'}
                            endIcon={<VisibilityIcon />}
                            buttonType={BUTTON_TYPE.BLUE}
                            onClick={() => setOpenBidConfig(selectedFiles)}
                        />
                    )}
                </>
            }
            ribbonIcon={<ConfigurationIcon fill={palette.white} />}
            ribbonText={displayedActionBarName}
        />
    );
};

export default BidFileManagerActionBar;
