import { Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import palette from 'theme/palette';

interface StyledPaperProps {
    readOnly?: boolean;
}

export const StyledPaper = styled(Paper)<StyledPaperProps>(({ readOnly }) => ({
    backgroundColor: readOnly ? palette.neutral.search : palette.ODLightBlueNeutral.lightBlue1,
    fontSize: '14px',
    marginBottom: '2px',
    color: readOnly ? palette.neutral.neutral6 : palette.neutral.black
}));

export const TextContent = styled(Box)(() => ({
    marginLeft: '16px',
    display: 'flex'
}));
export const StyledHistoryCard = styled(Box)(() => ({
    fontSize: '12px',
    marginTop: '16px',
    color: palette.neutral.neutral6
}));

export const StyledCardTitle = styled(Box)(() => ({
    marginBottom: '2px'
}));
