/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { AppBar, Toolbar, Box } from '@mui/material';
import theme from 'theme';
import KPIDashboard from './KPIDashboard';
import { updateNetworkView, getAllBidFiles } from 'redux/actions';
import BidFilesSlideout from '../../Slideouts/BidFilesSlideout';
import { REPORT_STATUS } from 'constants/network';

const styles = (theme) => ({
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },

    notificationIcon: {
        fontSize: '24px',
        position: 'relative',
        top: '4px',
        left: '4px'
    }
});

const TopNavbar = ({ reportConfigId }) => {
    const dispatch = useDispatch();
    const { showBidFilesSlideout } = useSelector((state) => state.NetworkReducer);
    const { expandTopNavbar, appliedBidFiles } = useSelector((state) => state.NetworkReducer);

    const { configuration, appliedBidFilesIds } = useSelector(
        (state) => state.ConfigurationReducer
    );

    const { allBidFiles } = useSelector((state) => state.BiddingReducer);

    const noSimulationSelected = () => {
        return (
            Object.keys(configuration).length === 0 ||
            configuration.report_status === REPORT_STATUS.COMPLETED
        );
    };

    useEffect(() => {
        dispatch(getAllBidFiles());
    }, []);

    useEffect(() => {
        dispatch(
            updateNetworkView({
                appliedBidFiles: allBidFiles
                    .filter((bidConfig) => bidConfig.status === REPORT_STATUS.COMPLETED)
                    .filter((bid) => appliedBidFilesIds.includes(bid.id))
            })
        );
    }, [allBidFiles, appliedBidFilesIds]);

    return (
        <>
            <AppBar
                sx={{
                    zIndex: 9,
                    boxShadow: 'none',
                    borderBottom: `1px solid ${theme.palette.neutral.neutral1}`
                }}
                position="relative">
                <Toolbar
                    styles={{
                        height: expandTopNavbar ? '56px' : '112px'
                    }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: theme.palette.white,
                        minHeight: '56px !important',
                        justifyContent: 'space-between',
                        padding: '0 !important'
                    }}>
                    {expandTopNavbar && (
                        <Box
                            component="section"
                            sx={{
                                borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                                width: '100%'
                            }}>
                            <KPIDashboard />
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
            <BidFilesSlideout
                reportConfigId={reportConfigId}
                showBidFilesSlideout={showBidFilesSlideout}
                appliedBidFiles={appliedBidFiles}
                noSimulationSelected={noSimulationSelected}
            />
        </>
    );
};

export default withStyles(styles)(TopNavbar);
