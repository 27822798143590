import {
    locationCityStateValidationFieldhookCallback,
    locationCityStateZipCodeValidationFieldhookCallback,
    locationZipCodeSyntaxValidationFieldhookCallback,
    locationZipCodeSemanticValidationFieldhookCallback,
    mileageValidationFieldhookCallback,
    loadVolumeFieldhookCallback,
    optionalNumberColumnFieldhookCallback
} from './fieldhooks';
import { recordChangeCallback } from './recordhooks';
import { reviewStageStepHook } from './stephooks';

// Register Data Hooks
export const registerDataHooks = (importer, featureFlags, defaultRateType) => {
    // See interface definition for FeatureFlags in src/constants/flatfile.ts

    reviewStageStepHook(importer, featureFlags);

    if (featureFlags.flexibleLocationFlatfileFlag) {
        //
        // these are new location fields when flexibleLocationFlatfileFlag===true
        // see field definitions in src/constants/flatfile.ts
        //
        importer.registerFieldHook('origin_city_state_zip', (values) =>
            locationCityStateZipCodeValidationFieldhookCallback(values, 'origin_city_state_zip')
        );
        importer.registerFieldHook('origin_city_state', (values) =>
            locationCityStateValidationFieldhookCallback(values, 'origin_city_state')
        );
        importer.registerFieldHook('origin_zip', (values) =>
            locationZipCodeSemanticValidationFieldhookCallback(values, 'origin_zip')
        );
        importer.registerFieldHook('destination_city_state_zip', (values) =>
            locationCityStateZipCodeValidationFieldhookCallback(
                values,
                'destination_city_state_zip'
            )
        );
        importer.registerFieldHook('destination_city_state', (values) =>
            locationCityStateValidationFieldhookCallback(values, 'destination_city_state')
        );
        importer.registerFieldHook('destination_zip', (values) =>
            locationZipCodeSemanticValidationFieldhookCallback(values, 'destination_zip')
        );
    } else {
        // these are legacy ZipCode fields when flexibleLocationFlatfileFlag===false
        // see field definitions in src/constants/flatfile.ts

        // The Clifford API should do semantic location validations as the preferred
        // way to validate any location data. But currently (Oct, 2022), there are
        // performance concerns with Clifford API for zip-code validations; so these
        // validators are controlled by the feature flag and disabled by default.

        if (featureFlags.zipCodeCliffordValidation) {
            importer.registerFieldHook('origin_zip', (values) =>
                locationZipCodeSemanticValidationFieldhookCallback(values, 'origin_zip')
            );
            importer.registerFieldHook('destination_zip', (values) =>
                locationZipCodeSemanticValidationFieldhookCallback(values, 'destination_zip')
            );
        } else {
            importer.registerFieldHook('origin_zip', (values) =>
                locationZipCodeSyntaxValidationFieldhookCallback(values, 'origin_zip')
            );
            importer.registerFieldHook('destination_zip', (values) =>
                locationZipCodeSyntaxValidationFieldhookCallback(values, 'destination_zip')
            );
        }
    }

    importer.registerFieldHook('mileage', mileageValidationFieldhookCallback);
    importer.registerFieldHook('load_volume', loadVolumeFieldhookCallback);
    importer.registerFieldHook('rate_per_mile', optionalNumberColumnFieldhookCallback);
    importer.registerFieldHook('other_revenue', optionalNumberColumnFieldhookCallback);
    importer.registerFieldHook('other_revenue_per_mile', optionalNumberColumnFieldhookCallback);
    importer.registerRecordHook((recordRow, index, mode) =>
        recordChangeCallback(recordRow, index, mode, defaultRateType, featureFlags)
    );
    return importer;
};
