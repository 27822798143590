import { env } from '../env';

class EnvConfig {
    static getConfig() {
        return {
            apiUrl: env.REACT_APP_PLAN_API_BASE_URL,
            appHeader: 'Optimal Dynamics - ',
            env: env.REACT_APP_ENV,
            domain: 'core-ai.io',
            reportDetailAPIMaxAttempt: 20,
            reportDetailAPIInterval: 30000,
            preparingFilesAPIInterval: 30000,
            runsDetailAPIInterval: 30000,
            clientSideId: env.REACT_APP_CLIENT_SIDE_ID,
            sdkKey: env.REACT_APP_SDK_KEY,
            reportRunAPIInterval: 15000,
            reportRunStatusInterval: 10000,
            driverToursThreshold: env.REACT_APP_TOURS_THRESHOLD
        };
    }
}

export default EnvConfig;
