import React from 'react';
import LazyLoad from 'react-lazy-load';

const LazyLoadCustom = ({ children, ...rest }) => {
    return (
        <LazyLoad
            once
            overflow
            height={41}
            {...rest}
            offset={20}
            elementType="tr"
            throttle={150}
            debounce={false}>
            <React.Fragment>{children}</React.Fragment>
        </LazyLoad>
    );
};
export default LazyLoadCustom;
