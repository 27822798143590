import { createColumnHelper } from '@tanstack/react-table';
import { Tooltip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import EditIcon from '@mui/icons-material/Edit';
import { FILTER_TYPE } from 'shared/Table/tableFilters/FilterConstants';
import { minMaxFilterV8, textFilterV8 } from 'shared/Table/tableFilters';
import { FILE_TYPE } from 'constants/bidding';
import { REPORT_STATUS } from 'constants/network';
import { UnspecifiedDataPlaceholder } from 'shared/ODTable/ODTableStyledComponents';

const ellipsisStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 120,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
};

const DisplayStatusInfo = (status) => {
    if (status === REPORT_STATUS.COMPLETED)
        return (
            <Tooltip title="Completed">
                <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                    <DoneSharpIcon sx={{ color: theme.palette.semantic.semanticGreen }} />
                    <span style={{ paddingLeft: 8, position: 'relative' }}>Completed</span>
                </div>
            </Tooltip>
        );
    if (status === FILE_TYPE.DRAFT)
        return (
            <Tooltip title="In Draft">
                <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                    <EditIcon sx={{ color: theme.palette.neutral.neutral6 }} />
                    <span style={{ paddingLeft: 8, position: 'relative' }}>In Draft</span>
                </div>
            </Tooltip>
        );
    if (status === FILE_TYPE.EMPTY)
        return (
            <Tooltip title="Upload Not Complete">
                <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                    <LoopSharpIcon sx={{ color: theme.palette.neutral.black }} />
                    <span style={{ paddingLeft: 8, position: 'relative' }}>
                        Upload Not Complete
                    </span>
                </div>
            </Tooltip>
        );
};

const StyledSpan = styled('span')(({ hasPointer }) => ({
    textDecoration: 'underline',
    cursor: hasPointer ? 'pointer' : 'auto',
    ...ellipsisStyle
}));

const columnHelper = createColumnHelper();

const bidFileManagerColumnsInitiate = (
    handleOnFileNameClick,
    handleOnAssocReportsClick,
    textFieldMatchingStrategy
) => {
    const statusValuesToLabelsMap = {
        completed: 'Completed',
        draft: 'In Draft',
        empty: 'Upload Not Complete'
    };
    return [
        columnHelper.accessor('id', {
            id: 'id',
            header: () => 'id',
            cell: (info) => info.getValue(),
            meta: { notDisplayed: true }
        }),
        columnHelper.accessor('file_name', {
            id: 'file_name',
            header: () => 'File Name',

            cell: (info) =>
                info.getValue() ? (
                    <Tooltip disableInteractive title={info.getValue()}>
                        <StyledSpan hasPointer={true} onClick={() => handleOnFileNameClick(info)}>
                            {info.getValue()}
                        </StyledSpan>
                    </Tooltip>
                ) : (
                    <UnspecifiedDataPlaceholder />
                ),
            size: 200,
            filterFn: (row, columnId, value) =>
                textFilterV8(row, columnId, value, textFieldMatchingStrategy),
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: 'File Name',
                sticky: 'left',
                required: true
            }
        }),
        columnHelper.accessor('groupName', {
            id: 'groupName',
            header: () => 'Group',
            cell: (info) =>
                info.getValue() ? (
                    <Tooltip title={info.getValue()}>
                        <StyledSpan hasPointer={false}>{info.getValue()}</StyledSpan>
                    </Tooltip>
                ) : (
                    <UnspecifiedDataPlaceholder />
                ),
            filterFn: (row, columnId, value) =>
                textFilterV8(row, columnId, value, textFieldMatchingStrategy),
            size: 200,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: 'Group'
            }
        }),
        columnHelper.accessor('shipper_id', {
            id: 'shipper_id',
            header: () => 'Shipper',
            cell: (info) =>
                info.getValue() ? (
                    <Tooltip title={info.getValue()}>
                        <span style={ellipsisStyle}> {info.getValue()} </span>
                    </Tooltip>
                ) : (
                    <UnspecifiedDataPlaceholder />
                ),
            footer: (info) => info.column.id,
            filterFn: (row, columnId, value) =>
                textFilterV8(row, columnId, value, textFieldMatchingStrategy),
            size: 200,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: 'Shipper'
            }
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: () => 'Status',
            cell: (info) =>
                info.getValue() ? (
                    <span>{DisplayStatusInfo(info.getValue())}</span>
                ) : (
                    <UnspecifiedDataPlaceholder />
                ),
            footer: (info) => info.column.id,
            filterFn: (row, columnId, value) =>
                textFilterV8(
                    row,
                    columnId,
                    value,
                    textFieldMatchingStrategy,
                    statusValuesToLabelsMap
                ),
            size: 200,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                dropdownValuesToLabelsMap: statusValuesToLabelsMap,
                visibleByDefault: true,
                name: 'Status'
            }
        }),
        columnHelper.accessor('businessType', {
            id: 'businessType',
            header: () => 'Business Purpose',
            cell: (info) =>
                info.getValue() ? (
                    <Box component="span">{info.getValue()}</Box>
                ) : (
                    <UnspecifiedDataPlaceholder />
                ),
            footer: (info) => info.column.id,
            filterFn: (row, columnId, value) =>
                textFilterV8(row, columnId, value, textFieldMatchingStrategy),
            size: 200,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: 'Shipper'
            }
        }),
        //TODO hold off until further notice ODPT-3768
        // columnHelper.accessor('start_date', {
        //     id: 'start_date',
        //     header: () => 'Date Range Start',
        //     cell: (info) =>
        //         info.getValue() ? (
        //             `${moment(info.getValue()).format('MM/DD/YYYY')}`
        //         ) : (
        //             <UnspecifiedDataPlaceholder />
        //         ),
        //     footer: (info) => info.column.id,
        //     size: '176',
        //     filterFn: dateTimeFilterV8,
        //     meta: {
        //         filterType: FILTER_TYPE.DATE_RANGE,
        //         visibleByDefault: true,
        //         name: 'Start Date'
        //     }
        // }),
        // columnHelper.accessor('end_date', {
        //     id: 'end_date',
        //     header: () => 'Date Range End',
        //     cell: (info) =>
        //         info.getValue() ? (
        //             `${moment(info.getValue()).format('MM/DD/YYYY')}`
        //         ) : (
        //             <UnspecifiedDataPlaceholder />
        //         ),
        //     footer: (info) => info.column.id,
        //     size: 176,
        //     filterFn: dateTimeFilterV8,
        //     meta: {
        //         filterType: FILTER_TYPE.DATE_RANGE,
        //         visibleByDefault: true,
        //         name: 'End Date'
        //     }
        // }),
        columnHelper.accessor((row) => row.associated_reports.length, {
            id: 'report_configs',
            header: () => 'Assoc. Reports',
            cell: (info) =>
                info.getValue() !== undefined ? (
                    <StyledSpan hasPointer={true} onClick={() => handleOnAssocReportsClick(info)}>
                        {info.getValue()}
                    </StyledSpan>
                ) : (
                    <UnspecifiedDataPlaceholder />
                ),
            footer: (info) => info.column.id,
            size: 200,
            filterFn: minMaxFilterV8,
            meta: {
                filterType: FILTER_TYPE.NUMBER_RANGE,
                visibleByDefault: true,
                name: 'Assoc. Reports'
            }
        })
    ];
};
export default bidFileManagerColumnsInitiate;
