/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import theme from 'theme';
import PersonIcon from '@mui/icons-material/Person';
import TabWrapper from './TabWrapper';
import { cloneDeep } from 'lodash';

//!TODO design update with bryan with max-width

const ParametersTabs = ({ setUpdatedFieldData, editParams, cancelChange, updatedFieldData }) => {
    const {
        parametersData: { data }
    } = useSelector((state) => state.ReportsReducers);

    const driver_types = data?.driver_types;
    const [currIndex, setCurrTabIndex] = useState(0);

    const handleTabChange = (_event, tabIndex) => {
        setCurrTabIndex(tabIndex);
    };

    const addOrSubtractValue = (driverType, fieldName, calculation) => {
        let objectToUpdate = updatedFieldData?.find((item) => item.driver_type === driverType);

        let indexOfObjectToUpdate = updatedFieldData?.findIndex(
            (item) => item.driver_type === driverType
        );

        let updatedValue;

        if (calculation === 'add') {
            updatedValue = Number(objectToUpdate[fieldName]) + 1;
        } else if (calculation === 'subtract') {
            if (objectToUpdate[fieldName] < 0 || objectToUpdate[fieldName] - 1 < 0) {
                return;
            } else {
                updatedValue = Number(objectToUpdate[fieldName]) - 1;
            }
        }

        let updatedObject = {
            ...objectToUpdate,
            [fieldName]: Number(updatedValue.toFixed(2))
        };

        let updatedArr = cloneDeep(updatedFieldData);
        updatedArr[indexOfObjectToUpdate] = updatedObject;

        setUpdatedFieldData(updatedArr);
    };

    const onChangeValue = (value, driverType, fieldName) => {
        const parseValue = value.replace(/[^\d.-]/g, '');

        if (parseValue < 0 || parseValue === '-0') return;

        let objectToUpdate = updatedFieldData?.find((item) => item.driver_type === driverType);

        let indexOfObjectToUpdate = updatedFieldData?.findIndex(
            (item) => item.driver_type === driverType
        );

        let updatedObject = {
            ...objectToUpdate,
            [fieldName]: parseValue
        };

        let updatedArr = cloneDeep(updatedFieldData);
        updatedArr[indexOfObjectToUpdate] = updatedObject;

        setUpdatedFieldData(updatedArr);
    };

    useEffect(() => {
        cancelChange && setUpdatedFieldData(driver_types);
    }, [cancelChange]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currIndex}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: theme.palette.semantic.focusedBlue,
                            height: '3px'
                        }
                    }}
                    textColor="inherit"
                    aria-label="parameters tabs">
                    {driver_types?.map((type, i) => {
                        const { driver_type } = type;

                        return (
                            <Tab
                                key={i}
                                sx={{
                                    borderRight: `1px solid ${theme.palette.neutral.neutral1}`,
                                    width: '234px',
                                    height: '56px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    padding: '0 32px',
                                    color: theme.palette.neutral.black,
                                    fontWeight: 400,
                                    textTransform: 'capitalize',
                                    fontSize: '14px',
                                    transition: 'all 0.25s ease-in-out',
                                    '&:hover': {
                                        backgroundColor: theme.palette.neutral.neutral2
                                    }
                                }}
                                label={
                                    <Box sx={{ display: 'flex' }}>
                                        <PersonIcon
                                            sx={{
                                                marginRight: '19px',
                                                color: theme.palette.neutral.black
                                            }}
                                        />
                                        {driver_type}
                                    </Box>
                                }
                                id={`parameters-tab-${i}`}
                            />
                        );
                    })}
                </Tabs>
            </Box>

            {updatedFieldData?.map((type, i) => {
                return (
                    <TabWrapper
                        editParams={editParams}
                        key={i}
                        updateValue={addOrSubtractValue}
                        onChangeValue={onChangeValue}
                        value={currIndex}
                        index={i}
                        id={`driver-type-${i}`}
                        driverTypeData={type}
                    />
                );
            })}
        </Box>
    );
};

export default ParametersTabs;
