import { useState } from 'react';
import { ODDialog, ODTextField } from 'shared';
import { Box } from '@mui/material';

type SaveBidDialogProps = {
    onClose: () => void;
    open: boolean;
    onSave: (fileName: string | null) => void;
};
const SaveNewBidDialog = (props: SaveBidDialogProps) => {
    const { onClose, open, onSave } = props;
    const [bidFileName, setBidFileName] = useState('');
    const handleChange = (e: any) => setBidFileName(e.target.value);

    return (
        <ODDialog
            actionButtonText="Save"
            actionButtonDisabled={bidFileName === null || bidFileName === ''}
            dialogDescription="Create New Bid File"
            onClose={() => {
                onClose();
                setBidFileName('');
            }}
            onConfirm={() => {
                onSave(bidFileName);
                setBidFileName('');
                onClose();
            }}
            open={open}
            bodyOverride={
                <Box sx={{ marginBottom: '32px', display: 'flex', alignItems: 'center' }}>
                    <ODTextField
                        placeholder="Enter Name"
                        dataTestId="enter-file-name"
                        label="Enter Bid File Name"
                        value={bidFileName}
                        onChange={handleChange}
                        name="fileName"
                    />
                </Box>
            }
            key="od-dialog"></ODDialog>
    );
};

export default SaveNewBidDialog;
