import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useParamsProps } from 'types/hooks';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import { groupBy, sortBy, concat, isEmpty, maxBy } from 'lodash';
import { StyledHistoryCard, StyledCardTitle } from './HistorySlideout.styles';

import { ODDrawer, ODSelect } from 'shared';
import HistoryDriverCard from './HistoryDriverCard';
import HistoryHandleChangeRatesCard from './HistoryHandleChangeRatesCard';
import HistoryHandleBidAnalysisCard from './HistoryHandleBidAnalysisCard';
import HistoryUnlinkShipperAndLaneCard from './HistoryHandleUnlinkCard';
import HistoryRemoveShipperOrLaneCard from './HistoryHandleRemoveCard';
import HistoryHandleBonusCard from './HistoryHandleBonusCard';
import HistoryHandleBidCard from './HistoryHandleBidCard';
import { useSimulationHook, useBidAnalysisHook } from 'utils/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    restoreAction,
    getReportAppliedBids,
    editBidAnalysis,
    loadBidAnalysis
} from 'redux/actions';
import { HISTORY_CHANGES_TYPES } from 'constants/network';
import { RootState } from 'redux/reducers';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { useAuth0 } from '@auth0/auth0-react';
import * as FS from '@fullstory/browser';
import HistoryHandleBidConfigCard from './HistoryHandleBidConfigCard';

interface historySlideOutProps {
    getConfigurationActions?: () => any;
    show: boolean;
    networkManagerView?: boolean;
    bidAnalysisView?: boolean;
    bidConfigView?: boolean;
    onClose: React.MouseEventHandler<HTMLLabelElement> | undefined;
    configuration: any;
    domiciles?: any[];
    selectedRunType: string;
}

export interface actionDataProps {
    data: {
        id?: string;
        quantity?: string | number;
        domicile?: string;
        driver_type?: string;
        lane?: any;
        shipper?: any[];
        bid_config_id?: number;
        status?: string;
        original?: string | number;
        change?: string | number;
        bid_lane_id?: string | number;
    };
    currBidAnalysisLanes: DataRow;
    actionData: any;
    tableRestoreEditData: any;
}

const HistorySlideOut = ({
    show,
    onClose,
    domiciles,
    configuration,
    selectedRunType,
    networkManagerView = false,
    bidAnalysisView = false,
    bidConfigView = false
}: historySlideOutProps) => {
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState(HISTORY_CHANGES_TYPES.ALL);
    const { user } = useAuth0();
    const { freightBonus, newSimulationFlow, changeHistory } = useFlags();
    const { createSimulationOnEdit } = useSimulationHook();
    const { restoreBidAnalysisAction, isFinalizedBid } = useBidAnalysisHook();
    const { analysis_id }: useParamsProps = useParams();
    const { bidAnalysisColumnFormatSelections, currBidAnalysisLanes } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );

    //Todo: To use for unsaved changes in history slideout
    // const { actionsAwaitingConfigurationCreation } = useSelector(
    //     (state: RootState) => state.BiddingReducer
    // );

    const restoreDriver = (
        actionData: actionDataProps['actionData'],
        data: actionDataProps['data']
    ) => {
        actionData.push({
            id: data.id,
            type: 'restore',
            item_type: HISTORY_CHANGES_TYPES.DRIVER,
            value: {
                quantity: data.quantity,
                domicile: data.domicile,
                driver_type: data.driver_type
            }
        });
        return actionData;
    };

    const restoreShipperLane = (
        actionData: actionDataProps['actionData'],
        data: actionDataProps['data']
    ): actionDataProps => {
        actionData.push({
            id: data.id,
            type: 'restore',
            item_type: HISTORY_CHANGES_TYPES.SHIPPER_LANE,
            value: {
                lane: data.lane,
                shipper: data.shipper,
                bid_config_id: data.bid_config_id,
                status: data.status
            }
        });
        return actionData;
    };

    const handleRestore = async (e: any, restoreData: any, restoreAll: boolean = false) => {
        let simulationId;
        if (!isEmpty(restoreData) && newSimulationFlow)
            simulationId = await createSimulationOnEdit();
        else simulationId = configuration?.id;
        let actionData: any[] = [];
        if (simulationId) {
            if (restoreAll && restoreData.length) {
                FS.event('Change History_Click Undo All', {
                    dataLen: restoreData.length
                });
                restoreData.forEach((action: any) => {
                    if (action.domicile) restoreDriver(actionData, action);
                    else restoreShipperLane(actionData, action);
                });
            } else if (Object.keys(restoreData).length) {
                FS.event('Change History_ Undo Single change', {
                    value: {
                        quantity: restoreData.quantity,
                        domicile: restoreData.domicile,
                        driver_type: restoreData.driver_type
                    }
                });

                if (restoreData.domicile) restoreDriver(actionData, restoreData);
                else restoreShipperLane(actionData, restoreData);
            }
            dispatch(restoreAction(selectedRunType, simulationId, actionData));
        }
        await dispatch(getReportAppliedBids(simulationId));
    };
    const handleBidAnalysisActionRestore = async (
        e: any,
        restoreData: any,
        restoreAll: boolean = false
    ) => {
        const simulationId = configuration?.id;
        const tableRestoreEditData: actionDataProps['tableRestoreEditData'] = [];
        const actionData: actionDataProps['actionData'] = [];
        if (simulationId) {
            if (restoreAll && restoreData.length) {
                restoreData.forEach((action: any) => {
                    actionData.push({
                        id: action.id,
                        type: 'restore',
                        item_type: action.item_type
                    });
                });
                dispatch(
                    loadBidAnalysis({
                        analysis_id,
                        config_id: simulationId,
                        dontFetchEdits: true,
                        isFinalizedBid
                    })
                );
            } else if (Object.keys(restoreData).length) {
                restoreBidAnalysisAction(
                    actionData,
                    restoreData,
                    restoreData.item_type,
                    currBidAnalysisLanes,
                    tableRestoreEditData
                );
            }
            dispatch(
                editBidAnalysis({
                    config_id: simulationId,
                    cellEdits: tableRestoreEditData,
                    actions: actionData,
                    changedBy: user?.name
                })
            );
        }
        await dispatch(getReportAppliedBids(simulationId));
    };

    const undoAll = () => {
        let restoreData;
        if (bidAnalysisView) {
            FS.event('Change History_Click Undo All_button', {
                from: 'bid_analysis'
            });
            restoreData = Object.keys(bidAnalysisActionsHistory).reduce((acc: any, date: any) => {
                bidAnalysisActionsHistory[date].forEach((info: any) => {
                    let action;
                    if (info.type === HISTORY_CHANGES_TYPES.BID_ANALYSIS)
                        action = {
                            id: info.id,
                            type: info.type,
                            item_type: info.item_type
                        };

                    acc.push(action);
                });
                return acc;
            }, []);
            handleBidAnalysisActionRestore(undefined, restoreData, true);
        } else {
            FS.event('Change History_Click Undo All_button', {
                from: 'network_view'
            });
            restoreData = Object.keys(configHistory).reduce((acc: any, date: any) => {
                configHistory[date].forEach((info: any) => {
                    let action;
                    if (info.item_type === HISTORY_CHANGES_TYPES.DRIVER)
                        action = {
                            id: info.id,
                            domicile: info?.name,
                            driver_type: info.driverType,
                            quantity: info.quantity
                        };
                    else if (info.item_type === HISTORY_CHANGES_TYPES.SHIPPER_LANE) {
                        if (info.type === HISTORY_CHANGES_TYPES.REMOVE)
                            action = {
                                id: info.id,
                                lane: info.value.lane,
                                shipper: info.value.shipper
                            };
                        else if (info.type === HISTORY_CHANGES_TYPES.BONUS)
                            action = {
                                id: info.id,
                                lane: info.value.lane,
                                shipper: info.value.shipper,
                                type: info.value.type,
                                value: info.value.value
                            };
                        else if (info.type === HISTORY_CHANGES_TYPES.BID)
                            action = {
                                id: info.id,
                                type: info.value.type,
                                value: info.value.value
                            };
                        else if (info.type === HISTORY_CHANGES_TYPES.CHANGE_RATE)
                            action = {
                                id: info.id,
                                lane: info.value.lane,
                                shipper: info.value.shipper,
                                percentage: info.value.percentage,
                                amount: info.value.amount,
                                isPositive: info.value.isPositive,
                                minimum_rate: info.value.minimum_rate
                            };
                        else if (info.type === HISTORY_CHANGES_TYPES.ADD)
                            action = {
                                id: info.id,
                                lane: info.value.lane,
                                shipper: info.value.shipper,
                                percentage: info.value.percentage,
                                amount: info.value.amount,
                                load_count: info.value.load_count
                            };
                    }
                    acc.push(action);
                });
                return acc;
            }, []);

            handleRestore(undefined, restoreData, true);
        }
    };

    const getDriversData = (actions: any[]) => {
        let drivers: any[] = [];
        if (actions?.length && domiciles?.length) {
            drivers = actions
                .filter(({ item_type }: any) => item_type === HISTORY_CHANGES_TYPES.DRIVER)
                .map((item: any) => {
                    const type = item.value?.driver_type;
                    const domicile: any = domiciles?.find(
                        (x: any) => x?.name === item.value?.domicile
                    );
                    const quantity = item.value?.quantity || item.value?.total;
                    let driverTypeCount = domicile?.driver_types
                        ? domicile?.driver_types.find(
                              ({ driver_type }: any) => driver_type === type
                          )?.count || 0
                        : 0;

                    if (driverTypeCount === 0) driverTypeCount = item.value?.total_drivers || 0;
                    return {
                        id: item.id,
                        name: item.value?.domicile,
                        driverType: type,
                        item_type: item.item_type,
                        count: `${driverTypeCount} (${quantity > 0 ? '+' : ''}${quantity})`,
                        quantity: item.value?.quantity || item.value?.total,
                        created_at: item.created_at
                    };
                });
        }
        return drivers;
    };

    let config: any[] = [];
    let prev: any[] = [];

    if (configuration && configuration.actions) {
        if ([HISTORY_CHANGES_TYPES.ALL, HISTORY_CHANGES_TYPES.DRIVER].includes(selectedType)) {
            config = concat(config, getDriversData(configuration.actions));
        }
        if (
            [HISTORY_CHANGES_TYPES.ALL, HISTORY_CHANGES_TYPES.SHIPPER_LANE].includes(selectedType)
        ) {
            const shipperLaneActions = configuration.actions.filter(
                ({ type, item_type }: any) => item_type === HISTORY_CHANGES_TYPES.SHIPPER_LANE
            );
            config = concat(config, shipperLaneActions);
        }
    }
    if (configuration && configuration.prevActions) {
        const shipperLaneActions = configuration.prevActions.filter(
            ({ type, item_type }: any) => item_type === HISTORY_CHANGES_TYPES.SHIPPER_LANE
        );
        if ([HISTORY_CHANGES_TYPES.ALL, HISTORY_CHANGES_TYPES.PREV].includes(selectedType)) {
            prev = concat(prev, getDriversData(configuration.prevActions));
            prev = concat(prev, shipperLaneActions);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const bidAnalysisActionsHistory =
        groupBy(
            configuration.actions.filter(
                ({ type }: any) => type === HISTORY_CHANGES_TYPES.BID_ANALYSIS
            ),
            (item) => `${moment(item.created_at).format('MM/DD HH:mm')} by ${item.value.changed_by}`
        ) || {};
    const configHistory =
        groupBy(config, ({ created_at }) => moment(created_at).format('MM/DD HH:mm')) || {};
    const prevHistory = groupBy(prev, ({ configuration }) => configuration || {});

    const getPrevChangesCardTitle = () =>
        `Previous Report Edits: ${
            configuration?.starting_report_run?.name || configuration?.report_run?.name
        }`;
    const itemList = changeHistory
        ? [
              { value: HISTORY_CHANGES_TYPES.ALL, name: 'All Types' },
              { value: HISTORY_CHANGES_TYPES.SHIPPER_LANE, name: 'Shipper/Lane' },
              { value: HISTORY_CHANGES_TYPES.DRIVER, name: 'Drivers' },
              { value: HISTORY_CHANGES_TYPES.PREV, name: 'Previous Report Edits' }
          ]
        : [
              { value: HISTORY_CHANGES_TYPES.ALL, name: 'All Types' },
              { value: HISTORY_CHANGES_TYPES.SHIPPER_LANE, name: 'Shipper/Lane' },
              { value: HISTORY_CHANGES_TYPES.DRIVER, name: 'Drivers' }
          ];
    const formatValueAsFlatRate = (value: any) => {
        const row = currBidAnalysisLanes.find((row: DataRow) => row.id === value.bid_lane_id);
        if (row)
            return {
                ...value,
                original: value.original * row.mileage,
                change: value.change * row.mileage
            };
        return value;
    };
    return (
        <ODDrawer
            title="Change History"
            secondaryActionText="Undo All"
            secondaryAction={undoAll}
            open={show}
            onClose={onClose}
            enableCustomizedButton={true}>
            {networkManagerView && (
                <ODSelect
                    sx={{ marginTop: '16px' }}
                    dataTestId="business-type"
                    title="Type of Changes"
                    name="selectBusinessType"
                    displayEmpty
                    value={selectedType}
                    onChange={({ target }: any) => setSelectedType(target.value)}
                    placeholder="Select Option"
                    itemList={itemList}
                />
            )}
            {networkManagerView &&
                sortBy(Object.keys(configHistory))
                    .reverse()
                    .map((info, i) => {
                        return (
                            <StyledHistoryCard key={'network-history-' + i}>
                                <StyledCardTitle>{info || ''}</StyledCardTitle>
                                {configHistory[info].map(
                                    (
                                        {
                                            id,
                                            type,
                                            value,
                                            item_type,
                                            name,
                                            driverType,
                                            count,
                                            quantity
                                        },
                                        idx
                                    ) => {
                                        if (item_type === HISTORY_CHANGES_TYPES.DRIVER) {
                                            return (
                                                <HistoryDriverCard
                                                    key={`${item_type}-${idx}`}
                                                    id={id}
                                                    name={name}
                                                    count={count}
                                                    driverType={driverType}
                                                    quantity={quantity}
                                                    handleRestore={handleRestore}
                                                />
                                            );
                                        } else if (
                                            item_type === HISTORY_CHANGES_TYPES.SHIPPER_LANE
                                        ) {
                                            if (
                                                type === HISTORY_CHANGES_TYPES.CHANGE_RATE ||
                                                type === HISTORY_CHANGES_TYPES.ADD
                                            ) {
                                                return (
                                                    <HistoryHandleChangeRatesCard
                                                        key={`${item_type}-${idx}`}
                                                        id={id}
                                                        type={type}
                                                        value={value}
                                                        handleRestore={handleRestore}
                                                    />
                                                );
                                            } else if (
                                                freightBonus &&
                                                type === HISTORY_CHANGES_TYPES.BONUS
                                            ) {
                                                return (
                                                    <HistoryHandleBonusCard
                                                        key={`${item_type}-${idx}`}
                                                        id={id}
                                                        type={type}
                                                        value={value}
                                                        handleRestore={handleRestore}
                                                    />
                                                );
                                            } else if (
                                                changeHistory &&
                                                type === HISTORY_CHANGES_TYPES.BID
                                            ) {
                                                return (
                                                    <HistoryHandleBidCard
                                                        key={`${item_type}-${idx}`}
                                                        id={id}
                                                        type={type}
                                                        value={value}
                                                        handleRestore={handleRestore}
                                                    />
                                                );
                                            } else {
                                                return value.shipper && value.lane ? (
                                                    <HistoryUnlinkShipperAndLaneCard
                                                        key={`${item_type}-${idx}`}
                                                        id={id}
                                                        type={type}
                                                        value={value}
                                                        handleRestore={handleRestore}
                                                    />
                                                ) : (
                                                    <HistoryRemoveShipperOrLaneCard
                                                        key={`${item_type}-${idx}`}
                                                        id={id}
                                                        value={value}
                                                        handleRestore={handleRestore}
                                                    />
                                                );
                                            }
                                        } else {
                                            return null;
                                        }
                                    }
                                )}
                            </StyledHistoryCard>
                        );
                    })}
            {changeHistory &&
                networkManagerView &&
                sortBy(Object.keys(prevHistory))
                    .reverse()
                    .map((configId, i) => {
                        return (
                            <StyledHistoryCard key={'history-prev-' + i}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box
                                        sx={{
                                            maxWidth: '19rem',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                        <Tooltip
                                            disableInteractive
                                            title={getPrevChangesCardTitle()}>
                                            <span>{getPrevChangesCardTitle()}</span>
                                        </Tooltip>
                                    </Box>
                                    {`, ${moment(
                                        maxBy(prevHistory[configId], 'created_at').created_at
                                    ).format('MM/DD HH:mm')}`}
                                </Box>

                                <Box>
                                    {prevHistory[configId].map(
                                        (
                                            {
                                                id,
                                                type,
                                                value,
                                                item_type,
                                                name,
                                                driverType,
                                                count,
                                                quantity
                                            },
                                            idx
                                        ) => {
                                            if (item_type === HISTORY_CHANGES_TYPES.DRIVER) {
                                                return (
                                                    <HistoryDriverCard
                                                        key={`${item_type}-${idx}`}
                                                        id={id}
                                                        name={name}
                                                        count={count}
                                                        driverType={driverType}
                                                        quantity={quantity}
                                                        handleRestore={handleRestore}
                                                        readOnly={true}
                                                    />
                                                );
                                            } else if (
                                                item_type === HISTORY_CHANGES_TYPES.SHIPPER_LANE
                                            ) {
                                                if (
                                                    type === HISTORY_CHANGES_TYPES.CHANGE_RATE ||
                                                    type === HISTORY_CHANGES_TYPES.ADD
                                                ) {
                                                    return (
                                                        <HistoryHandleChangeRatesCard
                                                            key={`${item_type}-${idx}`}
                                                            id={id}
                                                            type={type}
                                                            value={value}
                                                            handleRestore={handleRestore}
                                                            readOnly={true}
                                                        />
                                                    );
                                                } else if (
                                                    freightBonus &&
                                                    type === HISTORY_CHANGES_TYPES.BONUS
                                                ) {
                                                    return (
                                                        <HistoryHandleBonusCard
                                                            key={`${item_type}-${idx}`}
                                                            id={id}
                                                            type={type}
                                                            value={value}
                                                            handleRestore={handleRestore}
                                                            readOnly={true}
                                                        />
                                                    );
                                                } else if (
                                                    changeHistory &&
                                                    type === HISTORY_CHANGES_TYPES.BID
                                                ) {
                                                    return (
                                                        <HistoryHandleBidCard
                                                            key={`${item_type}-${idx}`}
                                                            id={id}
                                                            type={type}
                                                            value={value}
                                                            handleRestore={handleRestore}
                                                        />
                                                    );
                                                } else {
                                                    return value.shipper && value.lane ? (
                                                        <HistoryUnlinkShipperAndLaneCard
                                                            key={`${item_type}-${idx}`}
                                                            id={id}
                                                            type={type}
                                                            value={value}
                                                            handleRestore={handleRestore}
                                                            readOnly={true}
                                                        />
                                                    ) : (
                                                        <HistoryRemoveShipperOrLaneCard
                                                            key={`${item_type}-${idx}`}
                                                            id={id}
                                                            value={value}
                                                            handleRestore={handleRestore}
                                                            readOnly={true}
                                                        />
                                                    );
                                                }
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}
                                </Box>
                            </StyledHistoryCard>
                        );
                    })}
            {bidAnalysisView &&
                sortBy(Object.keys(bidAnalysisActionsHistory))
                    .reverse()
                    .map((info, i) => {
                        return (
                            <StyledHistoryCard key={'bid-analysis-history-' + i}>
                                <StyledCardTitle>{info || ''}</StyledCardTitle>
                                {bidAnalysisActionsHistory[info].map(
                                    ({ id, type, value, item_type }, idx) => {
                                        if (
                                            changeHistory &&
                                            type === HISTORY_CHANGES_TYPES.BID_ANALYSIS
                                        ) {
                                            if (
                                                bidAnalysisColumnFormatSelections.rate ===
                                                'flat_rate'
                                            )
                                                value = formatValueAsFlatRate(value);
                                            return (
                                                <HistoryHandleBidAnalysisCard
                                                    key={`${item_type}-${idx}`}
                                                    id={id}
                                                    type={type}
                                                    item_type={item_type}
                                                    value={value}
                                                    handleRestore={handleBidAnalysisActionRestore}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    }
                                )}
                            </StyledHistoryCard>
                        );
                    })}

            {bidConfigView && (
                <StyledHistoryCard key={'bid-config-history-1'}>
                    {/* Dummy Data Used */}
                    <StyledCardTitle>{'01/08 by jjohnson@costumeremail.com'}</StyledCardTitle>
                    <HistoryHandleBidConfigCard
                        id={1}
                        key="bid-config-card-1"
                        handleRestore={handleRestore}
                        item_type={HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE}
                        value={{ lane: '91708-08831', original: 2.6, change: 2.3 }}
                    />
                    <HistoryHandleBidConfigCard
                        id={2}
                        key="bid-config-card-2"
                        handleRestore={handleRestore}
                        item_type={HISTORY_CHANGES_TYPES.REMOVE}
                        value={{ lane: '91761-08831', original: 2.6, change: 2.3 }}
                    />
                </StyledHistoryCard>
            )}
        </ODDrawer>
    );
};

export default HistorySlideOut;
