/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import { ODModal } from 'shared';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { updateNetworkView, UpdateNewNotifications } from 'redux/actions';
import { stripBaseReportName } from 'utils/common.util';
import { StyledCompletedMessageBox, ReportName } from './Modals.styles';
import { NOTIFICATION_STATUS } from 'constants/reports';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CompletedModal = () => {
    const dispatch = useDispatch();
    const { newNotificationFlow } = useFlags();
    const history = useHistory();
    const { isCompletedReport } = useSelector((state: RootState) => state.NetworkReducer);

    const reportName = useMemo(() => {
        return stripBaseReportName(isCompletedReport?.report?.name);
    }, [isCompletedReport?.report?.name]);

    const handleClose = () => {
        dispatch(updateNetworkView({ isCompletedReport: { show: false, report: {} } }));
        dispatch(
            UpdateNewNotifications({
                notification: {
                    id: isCompletedReport?.report?.id,
                    status: NOTIFICATION_STATUS.READ
                }
            })
        );
    };

    return (
        <ODModal
            show={newNotificationFlow && isCompletedReport.show}
            heading="Completed Report"
            actionButtonText="View Reports"
            handleClose={handleClose}
            handleAction={() => {
                handleClose();
                history.push('/');
            }}
            aria-describedby="modal-for-completed-report">
            <StyledCompletedMessageBox id="completed-report">
                <Box sx={{ marginRight: '19px' }}>
                    <FolderSharpIcon sx={{ color: theme.palette.neutral.black }} />
                </Box>
                <ReportName>{reportName}</ReportName>
            </StyledCompletedMessageBox>
            <Typography sx={{ fontSize: '14px', marginTop: '16px' }}>
                Your report is completed! To view, click the button below, or view in the completed
                tab in the Network Manager.
            </Typography>
        </ODModal>
    );
};

export default CompletedModal;
