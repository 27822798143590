import {
    createColumnHelper,
    Row,
    CellContext,
    ColumnDef,
    HeaderContext
} from '@tanstack/react-table';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { Box, Tooltip } from '@mui/material';
import theme from 'theme';
import { VALIDATION_RULE } from 'utils/common.util';
import { abbreviateNumberNew, abbreviateNumber } from 'utils/number.util';
import { textFilterV8 } from 'shared/Table/tableFilters';
import { FILTER_TYPE } from 'shared/Table/tableFilters/FilterConstants';
import { SimpleDonutWrapper } from 'shared/ODDonutChart';
import { ODAlertIcon, ODCircle } from 'shared/ODIcons';
import CloseIcon from '@mui/icons-material/Close';
import { UnspecifiedDataPlaceholder } from 'shared/ODTable/ODTableStyledComponents';
import { FLOW_DIRECTION } from 'types/bidAnalysis';
import { getTimeUnits } from 'utils/time.util';
import { TIME_FRAME } from 'constants/settings';
import { RATING } from 'constants/bidding';
import { ColumnFormat } from 'types/react-table';
import { displayTextValueByValueType, getFilterFn, getFilterType } from './flowsTableColumns.util';
import { CellContainer, StyledSpan, CityEllipsis } from './FlowsTableColumns.styles';

// const PLACEHOLDER_INFO_DESCRIPTION = 'Placeholder information describing the column';

const columnHelper = createColumnHelper<DataRow>();

const flowLanesColumnsInitiate = ({
    textFieldMatchingStrategy,
    selectedFlowDirection,
    locationVisualization,
    bidAnalysisAndFlowsTableColumns,
    time_aggregation,
    triggerNoMappingInfoAlert,
    showProfitColumns
}: {
    textFieldMatchingStrategy: string;
    selectedFlowDirection: FLOW_DIRECTION;
    locationVisualization?: 'city-state' | 'zip-code';
    bidAnalysisAndFlowsTableColumns?: boolean;
    time_aggregation: TIME_FRAME | null;
    triggerNoMappingInfoAlert: any;
    showProfitColumns: boolean;
}) => {
    const textMatchingFilterFnWrapper = (row: Row<DataRow>, columnId: string, value: any) =>
        textFilterV8(row, columnId, value, textFieldMatchingStrategy);
    const isInflow = selectedFlowDirection === FLOW_DIRECTION.IN;

    type DonutData = {
        displayedNumberKey: string;
        remainderNumberKey: string;
        color: string;
    } | null;

    const getRating = ({ value, columnId }: { value: number; columnId: string }) => {
        // TODO Actually determine value's rating based on user's threshold preferences
        return value > 80 ? RATING.IDEAL : value > 40 ? RATING.TOLERABLE : RATING.UNACCEPTABLE;
    };

    const getRatingIndicator = (rating: RATING) => {
        switch (rating) {
            case RATING.IDEAL:
                return <ODCircle sx={{ color: theme.palette.success.main }} />;
            case RATING.TOLERABLE:
                return <ODAlertIcon sx={{ color: theme.palette.semantic.semanticYellow }} />;
            case RATING.UNACCEPTABLE:
                return <CloseIcon sx={{ color: theme.palette.semantic.semanticRed }} />;
        }
    };

    const globalTimeUnits = time_aggregation ? getTimeUnits(time_aggregation) : '';

    const cellFormat = (
        format: ColumnFormat,
        showRating: boolean,
        donut: DonutData,
        size: number,
        timeAggDependent?: boolean,
        unit?: string,
        valuesToLabelsMap?: { [key: string]: string }
    ) => {
        return (info: CellContext<DataRow, string | number | object | null>) => {
            const cellValue = info.getValue();
            let value = null;
            let rating = null;
            if (cellValue === null || cellValue === undefined)
                value = <UnspecifiedDataPlaceholder />;
            else {
                switch (format) {
                    case 'dollar':
                        value = (
                            <Box>
                                {`$${abbreviateNumberNew(cellValue)}${
                                    timeAggDependent ? globalTimeUnits : ''
                                }`}
                            </Box>
                        );
                        break;
                    case 'percentage':
                        value = (
                            <Box>{`${abbreviateNumber(cellValue, 2)}%${
                                timeAggDependent ? globalTimeUnits : ''
                            }`}</Box>
                        );
                        break;
                    case 'float':
                        value = (
                            <Box>
                                {`${abbreviateNumberNew(cellValue)}${unit ?? ''}${
                                    timeAggDependent ? globalTimeUnits : ''
                                }`}
                            </Box>
                        );
                        break;
                    case 'count':
                        value = (
                            <Box>
                                {`${abbreviateNumber(cellValue)}${unit ?? ''}${
                                    timeAggDependent ? globalTimeUnits : ''
                                }`}
                            </Box>
                        );
                        break;
                    case 'interactive_count':
                        value = <StyledSpan selectable={true}>{cellValue as any}</StyledSpan>;
                        break;
                    case 'text':
                        let displayValue = (
                            valuesToLabelsMap ? valuesToLabelsMap[cellValue.toString()] : cellValue
                        ) as string;

                        value = displayTextValueByValueType(
                            displayValue,
                            isInflow,
                            size,
                            triggerNoMappingInfoAlert
                        );

                        break;
                    default:
                        return null;
                }
            }
            if (showRating) {
                if (typeof cellValue === 'number' && !isNaN(cellValue))
                    rating = getRating({ value: cellValue, columnId: info.column.id });
                else {
                    console.warn(
                        'cannot calculate rating from non-numeric value in column ' +
                            info.column +
                            ', row ' +
                            info.row.index
                    );
                }
            }
            return (
                <CellContainer>
                    {rating && (
                        <Box sx={{ display: 'flex', marginRight: '7px' }}>
                            {getRatingIndicator(rating)}
                        </Box>
                    )}
                    {donut && (
                        <Box>
                            <SimpleDonutWrapper
                                numerator={info.row.original[donut.displayedNumberKey] as number}
                                denominator={
                                    (info.row.original[donut.displayedNumberKey] as number) +
                                    (info.row.original[donut.remainderNumberKey] as number)
                                }
                                color={donut.color}
                                donutId={`${info.row.index}-${info.column.id}`}
                            />
                        </Box>
                    )}
                    {value}
                </CellContainer>
            );
        };
    };

    const generateColDef = ({
        accessor,
        label,
        format,
        showRating = false,
        donut = null,
        size = 148,
        description,
        validationRule,
        unit,
        valuesToLabelsMap,
        required = false,
        timeAggDependent = false
    }: {
        accessor: string;
        label: string;
        format: ColumnFormat;
        showRating?: boolean;
        donut?: DonutData;
        size?: number;
        description?: string;
        validationRule?: VALIDATION_RULE;
        timeFrame?: string;
        unit?: string;
        valuesToLabelsMap?: {};
        required?: boolean;
        timeAggDependent?: boolean;
    }) => {
        const colDef = columnHelper.accessor(accessor, {
            id: accessor,
            header: label,
            cell: cellFormat(
                format,
                showRating,
                donut,
                size,
                timeAggDependent,
                unit,
                valuesToLabelsMap
            ),
            footer: (info: HeaderContext<DataRow, string | number | object | null>) =>
                info.column.id, // TODO what is this field for?
            size: size ? size : 148,
            filterFn: getFilterFn(
                format,
                textFieldMatchingStrategy,
                textMatchingFilterFnWrapper,
                valuesToLabelsMap
            ),
            meta: {
                filterType: getFilterType(format),
                name: label,
                visibleByDefault: true,
                infoMessage: description,
                validationRule,
                ...(valuesToLabelsMap ? { dropdownValuesToLabelsMap: valuesToLabelsMap } : {}),
                required
            }
            // abbreviated: ['number', 'dollar'].includes(colMeta.format),
            // sticky: stickyColumns.includes(colMeta.accessor) ? 'left' : null
        });
        return colDef;
    };

    const cityStateCol = (locationType: 'origin' | 'destination') => {
        const headerText = locationType === 'origin' ? 'Origin' : 'Destination';
        const id = locationType === 'origin' ? 'originCityState' : 'destCityState';
        const tooltipColumnId =
            locationType === 'origin' ? 'originCityStateZip' : 'destCityStateZip';
        return columnHelper.accessor(id, {
            id: id,
            header: () => headerText,
            cell: (info) => {
                let displayedVal = info.getValue() as string | null;
                if (displayedVal) {
                    const city = displayedVal.split(',')[0];
                    const state = displayedVal.split(',')[1];
                    return (
                        <Tooltip title={(info.row.original[tooltipColumnId] ?? '') as any}>
                            <StyledSpan sx={{ display: 'flex' }}>
                                <CityEllipsis>{city}</CityEllipsis>
                                {state && ','}
                                {state}
                            </StyledSpan>
                        </Tooltip>
                    );
                } else return <UnspecifiedDataPlaceholder />;
            },
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: headerText,
                sticky: 'left',
                required: true
            }
        });
    };
    const zipCol = (locationType: 'origin' | 'destination') => {
        const headerText = locationType === 'origin' ? 'Origin' : 'Destination';
        const id = locationType === 'origin' ? 'origin' : 'destination';
        const tooltipColumnId =
            locationType === 'origin' ? 'originCityStateZip' : 'destCityStateZip';
        return columnHelper.accessor(id, {
            id: id,
            header: () => headerText,
            cell: (info) => {
                let displayedVal = info.getValue() as string | null;
                if (displayedVal) {
                    return (
                        <Tooltip title={(info.row.original[tooltipColumnId] ?? '') as any}>
                            <span>{displayedVal}</span>
                        </Tooltip>
                    );
                } else return <UnspecifiedDataPlaceholder />;
            },
            filterFn: textMatchingFilterFnWrapper,
            meta: {
                filterType: FILTER_TYPE.DROPDOWN,
                visibleByDefault: true,
                name: headerText,
                sticky: 'left',
                required: true
            }
        });
    };

    const columns = [
        columnHelper.accessor('id', {
            id: 'id',
            header: () => 'id',
            cell: (info) => info.getValue(),
            meta: { notDisplayed: true }
        }),

        ...(locationVisualization === 'city-state' ? [cityStateCol('origin')] : [zipCol('origin')]),
        ...(locationVisualization === 'city-state'
            ? [cityStateCol('destination')]
            : [zipCol('destination')]),

        generateColDef({
            accessor: 'origin_market',
            label: 'Origin Market',
            format: 'text',
            size: 178
            //description: PLACEHOLDER_INFO_DESCRIPTION
        }),
        generateColDef({
            accessor: 'destination_market',
            label: 'Destination Market',
            format: 'text',
            size: 195
            // description: PLACEHOLDER_INFO_DESCRIPTION
        }),
        generateColDef({
            accessor: 'type',
            label: 'Movement Type',
            format: 'text',
            size: 178
            //description: PLACEHOLDER_INFO_DESCRIPTION
        }),
        generateColDef({
            accessor: 'volume',
            label: 'Avg. Volume',
            format: 'count',
            timeAggDependent: true,
            size: 178
            //description: PLACEHOLDER_INFO_DESCRIPTION
        }),
        ...(showProfitColumns
            ? [
                  generateColDef({
                      accessor: 'profit',
                      label: 'Avg. Profit',
                      format: 'dollar'
                      //description: PLACEHOLDER_INFO_DESCRIPTION
                  })
              ]
            : []),
        generateColDef({
            accessor: 'empty_mileage',
            label: 'Avg. Empty Mileage',
            format: 'float',
            unit: 'mi',
            size: 178
            // description: PLACEHOLDER_INFO_DESCRIPTION
        }),
        ...(bidAnalysisAndFlowsTableColumns
            ? [
                  ...(showProfitColumns
                      ? [
                            generateColDef({
                                accessor: `avg_${isInflow ? 'inbound' : 'outbound'}_profit`,
                                label: `Avg. ${isInflow ? 'Inbound' : 'Outbound'} Profit + DH`,
                                format: 'dollar',
                                size: 240,
                                description: `The average profit (revenue - cost of load - cost of driving empty ${
                                    isInflow ? 'to' : 'from'
                                } bid load)`
                            })
                        ]
                      : []),
                  generateColDef({
                      accessor: `${isInflow ? 'inbound' : 'outbound'}_deadhead`,
                      label: `${isInflow ? 'Inbound' : 'Outbound'} Deadhead Cost`,
                      format: 'dollar',
                      size: 250,
                      description: isInflow
                          ? 'The cost of deadhead between the previous destination and the origin of the bid lane'
                          : 'The cost of deadhead leaving the bid lane and getting into the next planned lane'
                  }),
                  generateColDef({
                      accessor: 'avg_loaded_cost',
                      label: 'Avg. Loaded Cost',
                      format: 'dollar',
                      size: 188,
                      description: isInflow
                          ? 'The average cost of transporting the previous load'
                          : 'The average cost of transporting the next load'
                  }),
                  generateColDef({
                      accessor: 'avg_loaded_miles',
                      label: 'Avg. Loaded Miles',
                      format: 'float',
                      size: 190,
                      description: `The average number of miles driven on the lane ${
                          isInflow ? 'prior to' : 'after'
                      } the bid lane`
                  }),
                  generateColDef({
                      accessor: 'avg_loh',
                      label: 'Avg. LOH',
                      format: 'float',
                      size: 158,
                      description: `The point to point mileage on the ${
                          isInflow ? 'inbound' : 'outbound'
                      } lane`
                  }),
                  generateColDef({
                      accessor: 'avg_revenue_per_load',
                      label: 'Avg. Rev. Per Load',
                      format: 'dollar',
                      size: 200,
                      description: `The average revenue per load from the ${
                          isInflow ? 'inbound' : 'outbound'
                      } lane`
                  }),
                  generateColDef({
                      accessor: 'total_revenue',
                      label: 'Total Revenue',
                      format: 'dollar',
                      timeAggDependent: true,
                      size: 190,
                      description: `The total revenue from the ${
                          isInflow ? 'inbound' : 'outbound'
                      } lane`
                  }),
                  generateColDef({
                      accessor: 'top_shipper_id',
                      label: 'Top Shipper',
                      format: 'text',
                      size: 180,
                      description: `The name of the top shipper`,
                      required: true
                  }),
                  generateColDef({
                      accessor: 'shipper_count',
                      label: 'Total Shippers',
                      format: 'count',
                      size: 170,
                      description: `The total number of shippers that are causing demand in or out of a lane`,
                      required: true
                  }),
                  generateColDef({
                      accessor: 'top_shipper_pct',
                      label: 'Top Shipper %',
                      format: 'percentage',
                      size: 170,
                      description: `The percentage of top shippers that make up a lane`,
                      required: true
                  }),
                  generateColDef({
                      accessor: 'empty_to_loaded_pct',
                      label: 'Empty to Loaded %',
                      format: 'percentage',
                      size: 200,
                      description: `The percentage of empty miles ${
                          isInflow ? 'to' : 'after'
                      } the bid lane compared to the total miles (Empty and Loaded)`
                  })
              ]
            : [])
    ];
    return columns as ColumnDef<DataRow, string | number | object | null>[];
};

export default flowLanesColumnsInitiate;
