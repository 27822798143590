import { DeckGL } from '@deck.gl/react';
import clsx from 'clsx';
import CircularIconButton from 'shared/CircularIconButton';
import withStyles from '@mui/styles/withStyles';
import { PersonAdd } from '@mui/icons-material/';
import FlowMapLayer from '@flowmap.gl/core';
import * as React from 'react';
import { capitalize } from 'lodash';
import { StaticMap } from 'react-map-gl';
import Markers from './markers';
import AddNewDriverMarkers from './AddNewDriverMarkers';
import axios from 'axios';
import theme from 'theme';
import { NETWORK_TABLE_TYPE } from 'constants/network';

const FLOW_MAP_LAYER_ID = 'flow-map-layer';
const ESC_KEY = 'Escape';

const useStyles = (theme) => ({
    markerAvatar: {
        width: 30,
        height: 30,
        bottom: 140,
        right: 480,
        zIndex: 10,
        position: 'absolute',
        color: '#fff',
        backgroundColor: '#535353'
    },
    driverTypeTours: {
        width: 30,
        height: 30,
        bottom: 185,
        right: 480,
        zIndex: 10,
        position: 'absolute',
        color: '#fff',
        backgroundColor: '#535353'
    },
    markerAvatarActive: {
        color: '#535353',
        backgroundColor: '#fff'
    },
    notificationAlert: {
        height: '55px',
        width: '100%',
        backgroundColor: 'yellow',
        zIndex: 10000000,
        position: 'absolute',
        top: '0px'
    },
    notificationPaper: {
        padding: theme.spacing(2),
        color: '#000'
    },
    notificationButton: {
        padding: 8,
        textAlign: 'center',
        color: '#000'
    },
    notificationHeaderButton: {
        background: 'rgb(0 0 0 / 10%)',
        color: '#000',
        width: '100%',
        borderRadius: 20,
        textTransform: 'capitalize'
    }
});

class FlowMap extends React.Component {
    constructor(props) {
        super(props);
        this.animationFrame = -1;
        this.state = {
            viewState: this.props.initialViewState,
            time: 0,
            domicileEdit: null,
            allowAddDrivers: false,
            addDriver: null,
            showPopUp: false,
            showSidebar: ''
        };
        this.animate = this.animate.bind(this);
        this.onClickOnMap = this.onClickOnMap.bind(this);
        this.handleFlowMapHover = this.handleFlowMapHover.bind(this);
        this.handleFlowMapClick = this.handleFlowMapClick.bind(this);
        this.handleViewStateChange = this.handleViewStateChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getTooltip = this.getTooltip.bind(this);
        this.addNewDrivers = this.addNewDrivers.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        if (props.selectedLocationIds !== state.selectedLocationIds) {
            return {
                selectedLocationIds: props.selectedLocationIds
            };
        }
        return null;
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        const { animate } = this.props;
        if (animate) {
            this.animate();
        }
        const { onViewStateChange } = this.props;
        if (onViewStateChange) {
            const { viewState } = this.state;
            if (viewState) {
                onViewStateChange(viewState);
            }
        }
    }
    componentDidUpdate(prevProps, _prevState) {
        const { animate } = this.props;
        if (animate !== prevProps.animate) {
            if (animate) {
                this.animate();
            } else {
                this.stopAnimation();
            }
        }
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        this.stopAnimation();
    }
    stopAnimation() {
        if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
        }
    }
    animate() {
        const loopLength = 2800; // unit corresponds to the timestamp in source data
        const animationSpeed = 40; // unit time per second
        const timestamp = Date.now() / 1000;
        const loopTime = loopLength / animationSpeed;
        this.setState({
            time: ((timestamp % loopTime) / loopTime) * loopLength
        });
        this.animationFrame = window.requestAnimationFrame(this.animate);
    }
    getFlowMapLayer() {
        const {
            initialViewState,
            mapboxAccessToken,
            multiselect,
            animationTailLength,
            onSelected,
            onHighlighted,
            ...flowMapLayerProps
        } = this.props;

        const { highlight, selectedLocationIds } = this.state;
        return new FlowMapLayer({
            id: FLOW_MAP_LAYER_ID,
            animationCurrentTime: this.state.time,
            flows: this.props.myflows,
            locations: this.props.mylocations,
            ...flowMapLayerProps,
            selectedLocationIds,
            animationTailLength,
            highlightedLocationId:
                highlight && highlight.type === 'location' ? highlight.locationId : undefined,
            highlightedLocationAreaId:
                highlight && highlight.type === 'location-area' ? highlight.locationId : undefined,
            highlightedFlow: highlight && highlight.type === 'flow' ? highlight.flow : undefined,
            onHover: this.handleFlowMapHover,
            onClick: this.handleFlowMapClick
        });
    }

    highlight(highlight, info) {
        this.setState({ highlight });
        const { onHighlighted } = this.props;
        if (onHighlighted) {
            onHighlighted(highlight, info);
        }
    }
    selectLocations(selectedLocationIds) {
        this.setState((state) => ({
            ...state,
            selectedLocationIds
        }));
        const { onSelected } = this.props;
        if (onSelected) {
            onSelected(selectedLocationIds);
        }
    }
    handleFlowMapHover(info) {
        const { type, object } = info;
        switch (type) {
            case 'flow': {
                if (!object) {
                    this.highlight(undefined);
                } else {
                    this.highlight(
                        {
                            type: 'flow',
                            flow: object
                        },
                        info
                    );
                }
                break;
            }
            case 'location-area':
            case 'location': {
                const { getLocationId } = this.props;
                if (!object) {
                    this.highlight(undefined);
                } else {
                    this.highlight(
                        {
                            type: type === 'location-area' ? 'location-area' : 'location',
                            locationId: (
                                getLocationId || FlowMapLayer.defaultProps.getLocationId.value
                            )(object)
                        },
                        info
                    );
                }
                break;
            }
            default:
        }
    }
    handleFlowMapClick({ type, object }) {
        switch (type) {
            case 'location-area':
            case 'location': {
                if (object) {
                    const { getLocationId, multiselect } = this.props;
                    const { selectedLocationIds } = this.state;
                    const locationId = (
                        getLocationId || FlowMapLayer.defaultProps.getLocationId.value
                    )(object);
                    const isSelected =
                        selectedLocationIds && selectedLocationIds.indexOf(locationId) >= 0;
                    let next = [] | undefined;
                    if (multiselect) {
                        if (selectedLocationIds) {
                            if (isSelected) {
                                next = selectedLocationIds.filter((id) => id !== locationId);
                            } else {
                                next = [...selectedLocationIds, locationId];
                            }
                        } else {
                            next = [locationId];
                        }
                    } else {
                        if (isSelected) {
                            next = undefined;
                        } else {
                            next = [locationId];
                        }
                    }
                    this.selectLocations(next);
                    this.highlight(undefined);
                }
                break;
            }
            default:
        }
    }
    handleViewStateChange({ viewState }) {
        const { showPopUp } = this.state;
        if (!showPopUp || (showPopUp && parseFloat(viewState.zoom) === 3.0)) {
            this.setState({
                viewState,
                highlight: undefined
            });
            const { onViewStateChange } = this.props;
            if (onViewStateChange) {
                onViewStateChange(viewState);
            }
        }
    }
    handleKeyDown(evt) {
        if (evt instanceof KeyboardEvent && evt.key === ESC_KEY) {
            this.setState({
                selectedLocationIds: undefined,
                highlight: undefined
            });
        }
    }
    getTooltip({ object }) {
        if (object && object.tooltip) {
            const { move_id, load_type, movement, load_equipment, driver_equipment } = object;
            const tooltipMessage = [`Movement ${movement}`];
            const tooltip = {
                'Movement Type': capitalize(load_type),
                'Load ID': move_id,
                "Load's Equipment": load_equipment || 'N/A',
                "Driver's Equipment": driver_equipment || 'N/A'
            };
            Object.keys(tooltip).forEach((key) => {
                tooltipMessage.push(`${key}: ${tooltip[key] || ''}`);
            });
            return tooltipMessage.join('\n');
        }
    }
    togglePopup = (value) => {
        this.setState({ showPopUp: value });
    };

    async onClickOnMap(event) {
        const { type, domicile_length } = this.props;
        const { allowAddDrivers } = this.state;
        if (type !== NETWORK_TABLE_TYPE.DRIVERS || !allowAddDrivers) return;

        const mapbox_url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.coordinate.join(
            ','
        )}.json?access_token=${this.props.mapboxAccessToken}`;

        const locationInfo = await axios.get(mapbox_url);

        const location = locationInfo.data.features.reduce(
            (store, item) => {
                store[item.place_type.toString()] = item.text;
                return store;
            },
            {
                longitude: event.coordinate[0],
                latitude: event.coordinate[1]
            }
        );

        if (!location.postcode) return this.setState({ addDriver: null, showSidebar: null });
        const short_postcode = location.postcode.slice(0, domicile_length);

        const hasDomicile = this.props.markerData.find(({ name }) => name === short_postcode);

        if (hasDomicile)
            return this.setState({
                addDriver: null,
                domicileEdit: hasDomicile.domicile,
                showSidebar: 'notification-on-add-driver'
            });

        if (this.state.addDriver?.name && this.state.addDriver?.name === short_postcode)
            return this.setState({
                addDriver: {
                    ...this.state.addDriver,
                    flag: true
                },
                showSidebar: null
            });

        const addDriver = {
            location,
            name: short_postcode,
            flag: false,
            newDomicile: true
        };
        this.setState({ addDriver, showSidebar: null });
    }

    addNewDrivers(domicile) {
        this.props.onAddDomicile(domicile);
        this.setState({
            addDriver: null,
            allowAddDrivers: false,
            showSidebar: null
        });
    }

    render() {
        const {
            mapboxAccessToken,
            mapStyle,
            markerData,
            type,
            classes,
            clearMap,
            driversConfig,
            onSave,
            isAdmin,
            isReadOnly = false,
            multiDriverToursToggle,
            openEditDriverSlideout,
            odpt4932ShowDriverIconMap
        } = this.props;

        const { addDriver, allowAddDrivers } = this.state;

        let layers = null;
        if (type !== NETWORK_TABLE_TYPE.DRIVERS) {
            layers = this.getFlowMapLayer();
        }

        if (clearMap) {
            layers = null;
        }
        if (this.props.otherLayers.length > 0) {
            layers = this.props.otherLayers;
        }

        return (
            <>
                <DeckGL
                    layers={layers}
                    viewState={this.state.viewState}
                    controller={true}
                    onClick={this.onClickOnMap}
                    getTooltip={this.getTooltip}
                    onViewStateChange={this.handleViewStateChange}
                    reuseMaps>
                    <StaticMap
                        width="100%"
                        height="100%"
                        mapboxApiAccessToken={mapboxAccessToken}
                        preventStyleDiffing={true}
                        asyncRender={true}
                        mapStyle={mapStyle}>
                        {(markerData || []) && (
                            <Markers
                                mapboxAccessToken={mapboxAccessToken}
                                openEditDriverSlideout={openEditDriverSlideout}
                                markerData={markerData}
                                classes={null}
                                domicile={this.props.domicile}
                                onClose={this.props.onClose}
                                addDomicile={this.props.onAddDomicile}
                                driversConfig={driversConfig}
                                onSave={onSave}
                                disableInfo={allowAddDrivers}
                                isAdmin={isAdmin}
                                isAggregated={this.props.isAggregated}
                                showSidebar={this.props.showSidebar}
                                isPopupOpen={this.togglePopup}
                                showPopUp={this.state.showPopUp}
                                isReadOnly={isReadOnly}></Markers>
                        )}
                        {addDriver && (
                            <AddNewDriverMarkers
                                addDriver={addDriver}
                                addDomicile={this.addNewDrivers}
                            />
                        )}
                    </StaticMap>
                </DeckGL>

                {odpt4932ShowDriverIconMap && !isReadOnly && (
                    <div>
                        <MapIcons
                            type={type}
                            isReadOnly={isReadOnly}
                            classes={classes}
                            onAddDrivers={async () => {
                                if (!this.props.isReportDraft && !isReadOnly) {
                                    const simulationId = await this.props.createSimulationOnEdit();
                                    if (!simulationId) return;
                                }

                                this.setState({
                                    allowAddDrivers: !allowAddDrivers,
                                    addDriver: null
                                });
                            }}
                            toggleDriverTours={this.props.toggleDriverTours}
                            allowAddDrivers={allowAddDrivers}
                            multiDriverToursToggle={multiDriverToursToggle}
                        />
                    </div>
                )}
            </>
        );
    }
}

const IconOnMap = ({ title = ' ', active, icon, style = {}, onClick }) => {
    return (
        <CircularIconButton
            aria-label={title}
            title={title}
            style={style}
            backgroundColor={active && theme.palette.neutral.white}
            icon={icon}
            onClick={onClick}
        />
    );
};

const MapIcons = ({ type, isReadOnly, classes, onAddDrivers, allowAddDrivers }) => {
    return (
        <>
            {type === NETWORK_TABLE_TYPE.DRIVERS && (
                <>
                    {!isReadOnly && (
                        <IconOnMap
                            className={clsx(
                                classes.markerAvatar,
                                allowAddDrivers && classes.markerAvatarActive
                            )}
                            style={{
                                width: 40,
                                right: 770,
                                bottom: 120,
                                position: 'absolute'
                            }}
                            onClick={onAddDrivers}
                            title="Add New Driver"
                            icon={
                                <PersonAdd
                                    style={{
                                        color: allowAddDrivers
                                            ? theme.palette.neutral.neutral6
                                            : theme.palette.neutral.white
                                    }}
                                />
                            }
                        />
                    )}
                </>
            )}
        </>
    );
};

export default withStyles(useStyles, { withTheme: true })(FlowMap);
