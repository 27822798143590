import { FormControl } from '@mui/material';
import { FormikProps, FieldInputProps } from 'formik';
import ODMultiSelectCore from './ODMultiSelectCore';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    formControl: { margin: 0 }
}));

//TODO somehow setFieldValue from formik doesn't accept object; couldn't find the long term solution. Short-term to render two sets of data.

//Required value format (short-term solution);
/*
{
      id: file.id,
      value: file.file_name,
  
    };
*/

interface MultiSelectProps<V = any, FormValues = any> {
    form: FormikProps<FormValues>;
    field: FieldInputProps<V>;
    options: any[];
    label: string;
    data: any;
    placeholder?: string;
    clearSelectedOptions?: () => void;
}

const ODMultiSelect = ({
    form,
    field,
    options,
    label,
    data,
    placeholder,
    clearSelectedOptions
}: MultiSelectProps) => {
    const classes = useStyles();

    let showValueLabel = (option: string | number) => {
        let selectedValueObj = data?.find((item: any) => option === item.id);
        return selectedValueObj.value;
    };

    const getSelectedOptions = () => {
        if (options) {
            return options?.filter((option: string) => field?.value.includes(option));
        } else {
            return [];
        }
    };

    const handleFormikChange = (option: any) => {
        if (option[option.length - 1] === 'all') {
            let selectedValues = getSelectedOptions().length === options.length ? [] : options;
            form.setFieldValue(field.name, selectedValues);
        } else {
            form.setFieldValue(
                field.name,
                option.map((item: string | number) => item)
            );
        }
    };

    return (
        <FormControl
            id={`${label}-form-control`}
            variant="standard"
            className={classes.formControl}
            sx={{ m: 0, width: '100%' }}>
            <ODMultiSelectCore
                clearSelectedOptions={clearSelectedOptions}
                fieldName={field.name}
                options={options}
                label={label}
                placeholder={placeholder}
                handleChange={(option: any) => handleFormikChange(option)}
                selectedOptions={getSelectedOptions()}
                getObjectValueFromId={showValueLabel}
            />
        </FormControl>
    );
};

export default ODMultiSelect;
