import React from 'react';
import BucketAccordion from './BucketAccordion';

const AccordionList = ({
    list,
    details,
    onExpand,
    onChecked,
    expanded,
    selectedReportBucketId = null,
    canSelectAll = true
}) => {
    if (!selectedReportBucketId) {
        return (
            <>
                {list
                    .filter(({ count }) => count > 0)
                    .map((item, key) => (
                        <BucketAccordion
                            key={`bucket-list-acc-${key}`}
                            bucket={item}
                            rows={details}
                            expanded={expanded}
                            onExpand={onExpand}
                            onChecked={onChecked}
                            canSelectAll={canSelectAll}
                        />
                    ))}
            </>
        );
    } else {
        const bucket = list.find((b) => b?.id === selectedReportBucketId);

        return (
            <BucketAccordion
                key={`bucket-list-acc-${bucket?.id}`}
                bucket={bucket}
                rows={details}
                expanded={expanded}
                onExpand={onExpand}
                onChecked={onChecked}
                canSelectAll={canSelectAll}
            />
        );
    }
};

export default AccordionList;
