import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CustomLabel = ({ flag = '', value = '', defaultValue = '' }) => {
    const flags = useFlags();

    return <>{flags[flag] ? value : defaultValue}</>;
};

export default CustomLabel;
