/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClusterLayer from 'components/mapBox/clustrMap.js';
import { LineLayer, IconLayer } from '@deck.gl/layers';
import { map, isEmpty, uniqBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { USERTYPE } from 'constants/users';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ChartMarker from 'components/NetworkView/MapView/MapContainer/ChartMarker';
import {
    updateConfiguration,
    getConfigurationActions,
    onSetConfigurationSuccess,
    resetDriversState,
    updateNetworkView
} from 'redux/actions/';
import AddDrivers from '../../AddDrivers';
import theme from 'theme';
import { isDraftReportUtil } from 'utils/network.util';
import { NETWORK_TABLE_TYPE } from 'constants/network';
import { useSimulationHook } from 'utils/hooks';

const MapContainer = () => {
    const {
        allowEditOnAggregatedDrivers,
        newSimulationFlow,
        newDomicileSlideout,
        odpt4932ShowDriverIconMap
    } = useFlags();

    const dispatch = useDispatch();
    const { user } = useAuth0();
    const { base_run, compare_run } = useParams();
    const { createSimulationOnEdit } = useSimulationHook();

    const clearMap = false;
    const isAnimating = false;
    const isShowSidebar = false;
    const isMultiDriverTour = false;

    const [flows, setFlows] = useState([]);
    const [isReadOnly, setReadOnly] = useState(false);
    const [type, setType] = useState([]);
    const [locations, setLocations] = useState({});
    const [markers, setMarkers] = useState([]);
    const [layers, setLayers] = useState([]);
    const [newDomiciles, setNewDomiciles] = useState(null);
    const [isAggregated, setIsAggregated] = useState(false);

    //check the roles of current user.
    const currUserRoles = user ? user[`https://core-ai.io/roles`] : [];
    const isAdmin = currUserRoles.includes(USERTYPE.SUPER_ADMIN);

    const {
        clusterAuto,
        clusterValue,
        minLoadCount,
        aggregationLevel,
        maxAggregationLevel,
        locationVisualization
    } = useSelector(({ settingsReducer }) => settingsReducer);
    const { configuration, configurationActions } = useSelector(
        ({ ConfigurationReducer }) => ConfigurationReducer
    );
    const { mapBoxKey, reportFlowData } = useSelector(({ ReportKPIReducer }) => ReportKPIReducer);
    const currReportId = useMemo(() => compare_run || base_run, [base_run, compare_run]);
    const { reportDriverTypes } = useSelector(({ ReportsReducers }) => ReportsReducers);
    const { currTab, baseReport, compareReport, slideout } = useSelector(
        ({ NetworkReducer }) => NetworkReducer
    );
    const { domicile, reportDomiciles, driverMoments } = useSelector(
        (state) => state.DriversReducer
    );
    const viewlayer = document.getElementById('view-default-view');

    const openEditDriverSlideout = () => {
        dispatch(
            updateNetworkView({
                slideout: { ...slideout, editCurrDriver: true }
            })
        );
    };

    const resetMapData = () => {
        if (viewlayer) viewlayer.childNodes[0].style.zIndex = -1;
        setFlows([]);
        setLocations({});
        setMarkers([]);
    };

    const getDriverMomentsLayers = () => {
        setType(NETWORK_TABLE_TYPE.DRIVERS);
        resetMapData();
        let layers = [];

        const {
            driver_home: { longitude, latitude }
        } = driverMoments;
        const iconOptions = {
            data: [
                {
                    coordinates: [parseFloat(longitude), parseFloat(latitude)]
                }
            ],
            getIcon: () => {
                const url = `/assets/images/home.svg`;
                return {
                    url,
                    width: 128,
                    height: 128
                };
            }
        };

        const arrowLayer = getIconLayer(iconOptions);
        layers.push(arrowLayer);

        const _layers = plotComparision(driverMoments.moments, base_run);
        layers.push(..._layers);
        setLayers(layers);
    };

    const getIconLayer = ({ key = 'icon', data, getIcon }) => {
        new IconLayer({
            id: `icon-layer-${key}`,
            data,
            pickable: false,
            sizeScale: 10,
            getIcon: getIcon,
            getAngle: ({ angle }) => angle,
            getPosition: ({ coordinates, midPoint }) => midPoint || coordinates,
            getSize: 2
        });
    };

    const getDriverLayers = async () => {
        setType(NETWORK_TABLE_TYPE.DRIVERS);
        resetMapData();
        if (viewlayer) viewlayer.childNodes[0].style.zIndex = 0;
        const newDomiciles = configurationActions
            .filter(
                (config) =>
                    config.type === 'add' && config.item_type === 'driver' && config.value.isNew
            )
            .reduce((store, item) => {
                const domicile = store[item.value.domicile] || {
                    driver_types: [],
                    total_drivers: 0,
                    name: item.value.domicile,
                    domicile_id: item.value.domicile,
                    ...item.value
                };
                const count = item.value.quantity || 0;
                domicile.driver_types.push({
                    index: domicile.driver_types.length + 1,
                    driver_type: item.value.driver_type,
                    count
                });
                domicile.total_drivers += count;
                store[item.value.domicile] = domicile;
                return store;
            }, {});

        const _newDomiciles = map(newDomiciles, (domicile) => domicile);
        const allDomiciles = [..._newDomiciles, ...reportDomiciles];

        const domiciles = await Promise.all(
            allDomiciles.map(async (reportDomicile, index) => {
                const {
                    location: { latitude = 0, longitude = 0 },
                    name,
                    configAction,
                    indicatorColor,
                    total_drivers,
                    compare_total_drivers
                } = reportDomicile;

                const indicatorCalculation = compare_total_drivers - total_drivers;
                let indicator = indicatorColor;
                if (indicatorCalculation > 0) {
                    indicator = theme.palette.semantic.semanticGreen;
                } else if (indicatorCalculation < 0) {
                    indicator = theme.palette.semantic.semanticRed;
                }

                const _driverTypes = compare_run
                    ? reportDomicile.compare_driver_types
                    : reportDomicile.driver_types;

                const driverTypes = _driverTypes?.reduce((store, driverType) => {
                    store[driverType.driver_type] = driverType;
                    return store;
                }, {});
                const image = await ChartMarker(
                    driverTypes,
                    configAction,
                    indicator,
                    reportDriverTypes
                );

                return {
                    latitude: parseFloat(latitude),
                    longitude: parseFloat(longitude),
                    opacity: !domicile || domicile.domicile_id === name ? 1 : 0.2,
                    image,
                    showInfo: false,
                    domicile: {
                        ...reportDomicile,
                        driverTypes: _driverTypes,
                        driver_types: driverTypes
                    },
                    indicator,
                    name
                };
            })
        );
        setMarkers(domiciles);
    };

    const getFlowsAndLocations = () => {
        resetMapData();
        setType('loads');
        const {
            myLocations: { features, type },
            myFlows
        } = reportFlowData;
        const _flows = myFlows.filter(({ count }) => Math.abs(count) >= minLoadCount);
        const flowLocation = _flows.reduce((store, { origin, destination }) => {
            if (!store.includes(origin)) store.push(origin);
            if (!store.includes(destination)) store.push(destination);
            return store;
        }, []);
        const _features = features.filter((feature) =>
            flowLocation.includes(feature.properties.name)
        );
        setFlows(_flows);
        setLocations({ type, features: _features });
    };

    const plotComparision = (data, key, isBaseRun = true) => {
        const linesData = data.map((line, index) => ({
            tooltip: true,
            movement: `${index + 1} of ${data.length}`,
            ...line
        }));
        let layers = [
            new LineLayer({
                id: `line-${key}`,
                data: linesData,
                opacity: 0.8,
                pickable: true,
                widthMinPixels: 5,
                widthMaxPixels: 15,
                getSourcePosition: ({ pickup_longitude, pickup_latitude }) => [
                    parseFloat(pickup_longitude),
                    parseFloat(pickup_latitude)
                ],
                getTargetPosition: ({ destination_longitude, destination_latitude }) => [
                    parseFloat(destination_longitude),
                    parseFloat(destination_latitude)
                ],
                getColor: (data) => {
                    if (data.load_type === 'empty') return [255, 255, 255]; //isBaseRun ? [71, 66, 79] : [255, 255, 255]
                    return isBaseRun ? [0, 240, 152] : [255, 213, 90];
                },
                getWidth: 1
            })
        ];

        const coordinates = data.reduce((store, load, index) => {
            const {
                load_id,
                load_type,
                pickup_longitude,
                pickup_latitude,
                destination_longitude,
                destination_latitude
            } = load;
            const start_latitude = parseFloat(pickup_latitude),
                start_longitude = parseFloat(pickup_longitude),
                stop_latitude = parseFloat(destination_latitude),
                stop_longitude = parseFloat(destination_longitude);

            const mx = stop_latitude - start_latitude,
                my = stop_longitude - start_longitude;
            const angle = (Math.atan2(mx, my) * 180) / Math.PI;

            store.push({
                index,
                coordinates: [start_longitude, start_latitude],
                angle,
                load_type,
                load_id,
                midPoint: [
                    (start_longitude + stop_longitude) / 2,
                    (start_latitude + stop_latitude) / 2
                ]
            });
            store.push({
                index,
                coordinates: [stop_latitude, start_longitude],
                load_id,
                load_type
            });

            return store;
        }, []);
        const unique = uniqBy(coordinates, 'coordinates');

        const iconOptions = {
            key,
            data: unique.filter(({ angle }) => angle),
            getIcon: ({ load_type }) => {
                let arrowColor = isBaseRun ? 'arrow' : 'arrow-yellow';
                if (load_type === 'empty') arrowColor = 'arrow-empty';
                const url = `/assets/images/${arrowColor}.png`;

                return {
                    url,
                    width: 128,
                    height: 128
                };
            }
        };
        const arrowLayer = getIconLayer(iconOptions);
        layers.push(arrowLayer);

        const all_coordinates = data.reduce(
            (
                store,
                {
                    load_type,
                    load_id,
                    is_home,
                    pickup_longitude,
                    pickup_latitude,
                    destination_longitude,
                    destination_latitude
                },
                index
            ) => {
                store.push({
                    index,
                    load_type,
                    load_id,
                    is_home,
                    coordinates: [pickup_longitude, pickup_latitude]
                });
                store.push({
                    index,
                    load_type,
                    load_id,
                    is_home,
                    coordinates: [destination_longitude, destination_latitude]
                });
                return store;
            },
            []
        );

        const activeSvg = isBaseRun ? 'active-green' : 'active-yellow';
        const iconOption = {
            key,
            data: uniqBy(all_coordinates, ({ coordinates }) => coordinates.join('-')),
            getIcon: (d) => {
                const loadId = d.load_id;
                const isHome = d.is_home || loadId.startsWith('empty-from');

                const url = `/assets/images/${
                    isHome ? 'triangle' : d.load_type === 'empty' ? 'inactive' : activeSvg
                }.svg`;

                return {
                    url,
                    width: 128,
                    height: 128
                };
            }
        };

        layers.push(getIconLayer(iconOption));
        return layers;
    };

    const allowAddDrivers = () => {
        if (!isDraftReportUtil(configuration)) {
            dispatch(onSetConfigurationSuccess({ showTopAlert: true }));
            return false;
        } else return true;
    };

    const getConfigActions = (simulationId) => {
        dispatch(getConfigurationActions(currReportId, simulationId));
    };

    const addDomicileConfig = async (config, simulationId) => {
        if (!newSimulationFlow && !allowAddDrivers) return;
        let actions = [];
        if (config) {
            actions.push({
                type: 'add',
                item_type: 'driver',
                value: {
                    quantity: config.quantity,
                    location: config.location,
                    isNew: config.isNew || false,
                    domicile: config.domicile_id,
                    driver_type: config.driver_type
                }
            });
        }

        await dispatch(updateConfiguration(currReportId, simulationId, actions));
    };

    useEffect(() => {
        const aggregated =
            locationVisualization !== 'city-state' && aggregationLevel !== maxAggregationLevel;
        setIsAggregated(aggregated);
    }, [aggregationLevel, maxAggregationLevel]);

    useEffect(() => {
        if (currTab !== NETWORK_TABLE_TYPE.DRIVERS && !isEmpty(reportFlowData)) {
            setLayers([]);
            dispatch(resetDriversState());
            getFlowsAndLocations();
        }
    }, [currTab, mapBoxKey, reportFlowData, minLoadCount]);

    useEffect(() => {
        if (currTab === NETWORK_TABLE_TYPE.DRIVERS) {
            if (!isEmpty(driverMoments)) getDriverMomentsLayers();
            else if (!isEmpty(reportDomiciles)) getDriverLayers();
        }
    }, [currTab, mapBoxKey, reportDomiciles, domicile, driverMoments, configurationActions]);

    useEffect(() => {
        const disable = compareReport?.read_only || baseReport?.read_only;

        if (disable) setReadOnly(disable);
        else setReadOnly(!allowEditOnAggregatedDrivers && isAggregated);
    }, [baseReport, compareReport, configuration, aggregationLevel]);

    const isReportDraft = Boolean(isDraftReportUtil(configuration));

    if (!mapBoxKey) return <></>;

    return (
        <>
            <ClusterLayer
                odpt4932ShowDriverIconMap={odpt4932ShowDriverIconMap}
                type={type}
                isReportDraft={isReportDraft}
                createSimulationOnEdit={createSimulationOnEdit}
                locations={locations}
                flows={flows}
                isAdmin={isAdmin}
                getLocationId={(loc) => loc.properties.abbr}
                getLocationCentroid={(loc) => loc.properties.centroid}
                getFlowOriginId={(flow) => flow.origin}
                getFlowDestId={(flow) => flow.destination}
                getFlowMagnitude={(flow) => +flow.count}
                token={mapBoxKey}
                animate={isAnimating}
                markerData={markers}
                classes={null}
                // domicile_length={this.props.domicile_length}
                domicile_length={maxAggregationLevel}
                onAddDomicile={(domicile) => setNewDomiciles(domicile)}
                clearMap={clearMap}
                otherLayers={layers}
                customZoomLevel={clusterValue}
                clusterStatus={clusterAuto}
                // driversConfig={driversConfig}
                // onSave={onSave}
                driversConfig={null}
                onSave={(domicile) => console.log(domicile)}
                loadType={reportFlowData?.loadType || ''}
                isReadOnly={isReadOnly}
                isAggregated={isAggregated}
                showSidebar={isShowSidebar}
                multiDriverToursToggle={isMultiDriverTour}
                // toggleDriverTours={this.props.toggleDriverTours}
                openEditDriverSlideout={openEditDriverSlideout}
                newDomicileSlideout={newDomicileSlideout}
            />
            {newDomiciles && !newDomicileSlideout && (
                <AddDrivers
                    show={true}
                    onSave={addDomicileConfig}
                    onRefresh={getConfigActions}
                    domicile={newDomiciles}
                    onClose={() => setNewDomiciles(null)}
                />
            )}
            {/* <MapTimeline /> */}
        </>
    );
};

export default MapContainer;
