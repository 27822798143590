import { Typography } from '@mui/material';
import { useState } from 'react';
import { ODModal, ODTextField } from 'shared';
import theme from 'theme';

const DUP_BID_VERBIAGE =
    'You are about to duplicate the file being viewed, please enter a new Bid File name in order to complete the duplication process.';

type DuplicateBidFilesDialogProps = {
    onClose: () => void;
    open: boolean;
    onSave: (fileName: string | null) => void;
};
const DuplicateBidFilesModal = (props: DuplicateBidFilesDialogProps) => {
    const { onClose, open, onSave } = props;
    const [bidConfigName, setBidConfigName] = useState('');
    const exceed50LimitsChars = Boolean(bidConfigName.length > 50);
    const emptyNameChars = Boolean(bidConfigName.length === 0);

    return (
        <ODModal
            show={open}
            heading="Duplicate Bid File"
            handleClose={() => {
                onClose();
                setBidConfigName('');
            }}
            description={DUP_BID_VERBIAGE}
            actionDisabled={exceed50LimitsChars || emptyNameChars}
            handleAction={() => {
                onSave(bidConfigName);
                setBidConfigName('');
                onClose();
            }}
            actionButtonText="Duplicate"
            enableCustomizedButton={false}>
            <ODTextField
                customInputProps={{ maxLength: 50 }}
                onChange={(e) => setBidConfigName(e.target.value)}
                placeholder="Enter Name"
                dataTestId="enter-file-name"
                label="Enter Duplicate Bid File Name*"
                value={bidConfigName}
                name="fileName"
            />
            <Typography
                sx={{
                    marginTop: '2px',
                    textAlign: 'right',
                    fontSize: '12px',
                    color: theme.palette.neutral.neutral6
                }}>
                {`${bidConfigName.length}/50 Character Limit`}
            </Typography>
        </ODModal>
    );
};

export default DuplicateBidFilesModal;
