export enum BUSINESS_TYPE {
    NEW = 'New',
    EXISTING = 'Existing'
}

export enum MODAL_TYPE {
    NOSHOW = 'noShow',
    DELETE_GROUP = 'deleteGroup',
    DELETE_FILE = 'deleteFile',
    DOWNLOAD_FILE = 'downloadFile',
    DOWNLOAD_GROUP_FILE = 'downloadGroupFile'
}

export enum SLIDE_OUT_TYPE {
    NOSHOW = 'noShow',
    CREATE_GROUP = 'createGroup',
    EDIT_GROUP = 'editGroup',
    EDIT_FILE = 'editFile'
}

export enum FILE_TYPE {
    EMPTY = 'empty',
    DRAFT = 'draft'
}

export enum BIDDING_TABLE_NAME {
    BID_MANAGER = 'bid_manager_table',
    BID_CONFIGURATION = 'bid_configuration_table',
    BID_ANALYSIS = 'bid_analysis_table',
    FLOWS = 'flows_table'
}

export enum DECISION {
    ACCEPT = 'accept',
    REJECT = 'reject'
}

export enum BID_ANALYSIS_TOOLTIPS {
    LANE_SCORE = 'A score which estimates the overall impact of the bid lane on the network',
    COVERED = 'Number of loads covered per report',
    UNCOVERED = 'Number of loads that are not covered per report',
    OMKT_BALANCE = 'The Origin Market Balance is the net difference between loads entering and leaving the origin market. Black values denote the historical market balance. A Green value signifies that the volume contributed by the Bid Lane results in a balance that is less than the historical market balance, while a Red value indicates that the volume contributed by the Bid lane resulted in a balance that is greater than the historical market balance.',
    DMKT_BALANCE = 'The Destination Market Balance is the net difference between loads entering and leaving the destination market. Black values denote the historical market balance. A Green value signifies that the volume contributed by the Bid Lane results in a balance that is less than the historical market balance, while a Red value indicates that the volume contributed by the Bid lane resulted in a balance that is greater than the historical market balance.',
    DECISION = 'The decision on whether to accept or reject a bid lane',
    VOL_TO_ACCEPT = 'The load volume to commit to on a bid lane',
    RATE_PER_MILE = 'The loaded line haul revenue per mile',
    FLAT_RATE = 'The loaded line haul revenue',
    AVG_INBOUND_COST = 'The cost of inbound deadhead',
    AVG_OUTBOUND_COST = 'The cost of outbound deadhead',
    AVG_EMPTY_MILES_INBOUND = 'The average empty mileage of inbound deadhead',
    AVG_EMPTY_MILES_OUTBOUND = 'The average empty mileage of outbound deadhead',
    AVG_EMPTY_MILES_PCT_INBOUND = 'The percentage of inbound deadhead to deadhead + driven loaded mileage',
    AVG_EMPTY_MILES_PCT_OUTBOUND = 'The percentage of outbound deadhead to deadhead + driven loaded mileage',
    ON_LANE_COST = 'The costs incurred while transporting freight on a lane',
    MILEAGE = 'The point to point mileage on the lane',
    INBOUND_PROFIT_PER_LOAD_PLUST_DH_COST = 'The profit per load including the deadhead cost inbound',
    OUTBOUND_PROFIT_PER_LOAD_PLUST_DH_COST = 'The profit per load including the deadhead cost outbound',
    HIST_VOL_OUTFLOW_PERC = 'The Percentage of moves after the bid lane that are historical lanes.',
    HIST_VOL_INFLOW_PERC = 'The Percentage of moves before the bid lane that are historical lanes.',
    SALES_RANKING = 'tooltip',
    LOAD_VOLUME = 'The Load Volume is the total available volume on the Bid Lane',
    RATE_ADJUSTMENT_TOOLTIP = 'For precise AI processing, rate adjustment values are calculated as a rate per mile. This calculation also applies to flat rate bid lanes.'
}

export enum RATING {
    IDEAL = 'ideal',
    TOLERABLE = 'tolerable',
    UNACCEPTABLE = 'unacceptable'
}

export enum BIDDING_NAMES {
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    CUSTOMER_LANE_ID = 'Customer Lane ID',
    ORIGIN_ZIP = 'Origin Zip',
    ORIGIN_CITY = 'Origin City',
    ORIGIN_STATE = 'Origin State',
    ORIGIN_CITY_STATE = 'Origin City,State',
    ORIGIN_CITY_STATE_ZIP = 'Origin City,State,Zip',
    DESTINATION_ZIP = 'Destination Zip',
    DESTINATION_CITY = 'Destination City',
    DESTINATION_STATE = 'Destination State',
    DESTINATION_CITY_STATE = 'Destination City,State',
    DESTINATION_CITY_STATE_ZIP = 'Destination City,State,Zip',
    MILEAGE = 'Mileage',
    LOAD_VOLUME = 'Load Volume',
    LOAD_FREQUENCY = 'Load Frequency',
    RATE_PER_MILE = 'Rate Per Mile',
    FLAT_RATE = 'Flat Rate',
    FUEL_SURCHARGE = 'Fuel Surcharge',
    ORIGIN_LOAD_METHOD = 'Origin Load Method',
    DESTINATION_UNLOAD_METHOD = 'Destination Unload Method',
    EQUIPMENT_TYPE = 'Equipment Type'
}

export enum BID_CONFIG_INFO_MESSAGES {
    UPLOADED_BID_DATA_MESSAGE = 'In order to run your Bid Analysis, please enter a file name in the field below.',
    FINALIZED_BID_ANALYSIS_MESSAGE = 'Selecting this option will fully optimize your results by reallocating opportunities from unacceptable lanes to high-scoring lanes. This enables you to compare your finalized results against your selected Base Network and provides a downloadable Network Impact file, both not included in the initial run. Please enter a name below before proceeding.',
    RATE_ADJUSTMENT_MESSAGE = 'By adjusting the rate you will be impacting the uploaded rate per mile by the factor applied. Please make the needed adjustments and create a name for this Bid Analysis file.',
    BID_CONFIG_INSTRUCTIONS = 'Enter a rate per mile for each lane, or remove the lanes that are not needed for your bid file. Once each lane has a rate per mile, you will be able to save your configuration and apply it to an analysis.',
    CONFIRM_BID_ANALYSES_RUNS = 'An email will be sent to you from core-ai.io once your analysis is completed'
}
