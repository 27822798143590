import React from 'react';
import { Box, AppBar, Typography } from '@mui/material';
import palette from 'theme/palette';

interface topHeaderProps {
    color?: string;
    title?: string;
    icon?: React.ReactNode;
    content?: React.ReactNode;
    backgroundColor?: string;
    position?: 'relative' | 'fixed' | 'absolute' | 'sticky' | 'static' | undefined;
}

const TopHeader = ({
    color = palette.white,
    title = '',
    icon = '',
    content = '',
    position = 'relative',
    backgroundColor = palette.ODBlue.odBlue10
}: topHeaderProps) => {
    return (
        <AppBar
            id={`${title}-top-header`}
            sx={{
                padding: '16px',
                color: color,
                fontSize: '14px',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '56px',
                zIndex: 9,
                boxShadow: 'none',
                backgroundColor: backgroundColor,
                flexDirection: 'row',
                borderBottom: `1px solid ${palette.neutral.neutral1}`
            }}
            position={position}>
            <Box
                component="section"
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Box sx={{ fontSize: '18px', display: 'flex' }}>{icon}</Box>
                <Typography sx={{ fontWeight: 600, marginLeft: '16px', fontSize: '14px' }}>
                    {title}
                </Typography>
            </Box>
            <Box>{content}</Box>
        </AppBar>
    );
};

export default TopHeader;
