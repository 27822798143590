import { ODModal, ODTextField, ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { Formik } from 'formik';
import * as yup from 'yup';
import { RefreshHelperText, ButtonWrapper } from './Header.styles';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { refreshBidAnalysis } from 'redux/actions/bidAnalysisActions';
import { RootState } from 'redux/reducers';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ODLoader from 'shared/ODLoader';
import { useFlags } from 'launchdarkly-react-client-sdk';

const refreshBidAnalysisSchema = yup.object({
    bidAnalysisName: yup.string().min(1).max(50).required('Required')
});

const RefreshBidAnalysisModal = ({
    openRefreshModal,
    setOpenRefreshModal
}: {
    openRefreshModal: boolean;
    setOpenRefreshModal: any;
}) => {
    const params = useParams() as any;
    const { odpt4185BidConfigUpdate } = useFlags();
    const { currBidAnalysisData } = useSelector((state: RootState) => state.BidAnalysisReducer);
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);

    if (showLoader) {
        return (
            <Box sx={{ height: '100vh', color: 'black' }}>
                <ODLoader open={true} text={'Loading...'} />
            </Box>
        );
    }

    return (
        <ODModal
            key={currBidAnalysisData.name}
            show={openRefreshModal}
            heading={`${odpt4185BidConfigUpdate ? 'Finalize' : 'Refresh'} Bid Analysis`}
            boldHeading={odpt4185BidConfigUpdate}
            disableDefaultClose
            handleClose={() => setOpenRefreshModal(false)}
            actionButtonText={`${odpt4185BidConfigUpdate ? 'Finalize' : 'Refresh'} Bid Analysis`}
            enableCustomizedButton={true}
            children={
                <>
                    <Formik
                        initialValues={{ bidAnalysisName: '' }}
                        validationSchema={refreshBidAnalysisSchema}
                        onSubmit={async (values, { resetForm }) => {
                            setOpenRefreshModal(false);
                            setShowLoader(true);
                            await dispatch(
                                refreshBidAnalysis(
                                    params?.config_id,
                                    params?.analysis_id,
                                    values?.bidAnalysisName
                                )
                            );
                            setShowLoader(false);
                            resetForm();
                        }}>
                        {({ handleSubmit, values, handleChange, isValid, resetForm, dirty }) => {
                            const clearAndClose = () => {
                                resetForm();
                                setOpenRefreshModal(false);
                            };

                            return (
                                <form onSubmit={handleSubmit}>
                                    <ODTextField
                                        customInputProps={{ maxLength: 50 }}
                                        sx={{ marginTop: '16px' }}
                                        dataTestId="enter-new-bid-analysis-name"
                                        label="Enter New Bid Analysis Name"
                                        hasAsterisk
                                        onChange={handleChange}
                                        name="bidAnalysisName"
                                        placeholder="Enter Name to Enable Run"
                                        value={values?.bidAnalysisName}
                                    />
                                    <Typography
                                        sx={{
                                            marginTop: '2px',
                                            textAlign: 'right',
                                            fontSize: '12px',
                                            color: theme.palette.neutral.neutral6
                                        }}>
                                        {values?.bidAnalysisName.length}/50 Character Limit
                                    </Typography>

                                    <RefreshHelperText>
                                        {odpt4185BidConfigUpdate
                                            ? 'Finalizing your analysis will update and optimize your results by reallocating opportunities from unacceptable lanes to high-scoring lanes.'
                                            : 'Refreshing your analysis will optimize and update your results based on the changes you have made.'}
                                    </RefreshHelperText>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            fontSize: '14px',
                                            height: '40px',
                                            fontWeight: 400,
                                            padding: '10px',
                                            borderRadius: '4px',
                                            backgroundColor:
                                                theme.palette.ODLightBlueNeutral.lightBlue1
                                        }}>
                                        <InfoOutlinedIcon sx={{ marginRight: '16px' }} />
                                        <Box sx={{ fontSize: '14px' }}>
                                            An email will be sent to you from core-ai.io once your
                                            analysis is completed.
                                        </Box>
                                    </Box>

                                    <ButtonWrapper>
                                        <ODButton
                                            onClick={clearAndClose}
                                            type="button"
                                            sx={{ marginRight: '16px', width: '188px' }}
                                            buttonText="Cancel"
                                        />
                                        <ODButton
                                            buttonType={BUTTON_TYPE.BLUE}
                                            type="submit"
                                            width="188px"
                                            buttonText={
                                                odpt4185BidConfigUpdate
                                                    ? 'Finalize Bid Analysis'
                                                    : 'Run'
                                            }
                                            disabled={!isValid || !dirty}
                                        />
                                    </ButtonWrapper>
                                </form>
                            );
                        }}
                    </Formik>
                </>
            }
        />
    );
};

export default RefreshBidAnalysisModal;
