import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'redux/reducers';
//utils
import { isDraftReportUtil } from 'utils/network.util';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import {
    setConfiguration,
    onSetConfigurationSuccess,
    createSimulation,
    updateConfigurationState,
    updateNetworkView,
    resetConfiguration,
    getConfigurationByRunId,
    getNewConfiguration,
    setSelectedReports,
    updateConfiguration,
    getConfigurationActions,
    runConfiguration,
    removeAppliedBids
} from 'redux/actions';
import { convertDateTimeObjIntoUSFormat } from 'utils/date.util';
import { useParamsProps } from 'types/hooks';
import { AlertType } from 'components/NetworkView/Alerts/AlertTypes';
import { useLoadingHook } from 'utils/hooks';

const useSimulationHook = () => {
    const params = useParams<useParamsProps>();
    const history = useHistory();
    const dispatch = useDispatch();
    const alertsContext = useAlerts();
    const { newSimulationFlow } = useFlags();
    const { showLoader } = useLoadingHook();

    const { bucket_id, config_id, base_run, analysis_id } = params;
    const { appliedBidFilesIds, configurations, configuration, configurationActions } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );
    const { compare_run, baseReport } = useSelector((state: RootState) => state.NetworkReducer);
    const { currBidAnalysisData } = useSelector((state: RootState) => state.BidAnalysisReducer);

    const currReportId = compare_run || base_run;

    const [simulation, setSimulation] = useState(configuration);

    const updateURL = (config_id: string | undefined) => {
        const url = `/reports/${
            bucket_id ? 'bucket/:bucket_id/' : 'report-run/'
        }:base_run/:compare_run/${
            config_id ? `config/${config_id}/` : ''
        }:filtertype/:subfilter/:expanded/:sidebar`
            .split('/')
            .map((value) => {
                if (value.includes(':'))
                    return params[value.replace(/:/g, '') as keyof useParamsProps] || '';
                return value;
            })
            .join('/')
            .replace(/\/\/+/g, '/');
        history.push(url);
    };
    const updateCurrSimulation = async (simulationRecentlyCreated: any = null) => {
        if (simulationRecentlyCreated) {
            await dispatch(setConfiguration(simulationRecentlyCreated));
            return;
        }
        showLoader(true);
        const currSimulation = configurations.find(({ id }: { id: any }) => id === simulation?.id);

        if (!currSimulation) {
            await dispatch(setConfiguration([]));
        } else {
            await dispatch(setConfiguration(currSimulation));
        }
        showLoader(false);
    };

    const handleSelectedSimulation = (simulation: any) => {
        showLoader(true);
        if (config_id !== simulation?.id) {
            updateURL(simulation?.id);
            updateCurrSimulation();
        }
        showLoader(false);
        closeSimulation();
    };

    const closeSimulation = () => {
        dispatch(
            onSetConfigurationSuccess({
                openManageSimulation: false,
                openCreateSimulation: false
            })
        );
    };

    const addNewSimulation = async (newSimName: string) => {
        let resultId;
        let reportId = parseInt(base_run as string);
        await createSimulation(newSimName, reportId, appliedBidFilesIds).then(async (result) => {
            updateURL(result?.id);
            await setSimulation({ ...result });
            await updateCurrSimulation();
            await dispatch(getConfigurationByRunId(base_run));
            await closeSimulation();
            resultId = result?.id;
        });
        return resultId;
    };
    const createSimulationOnEdit = async (createOnCompleted = false) => {
        await showLoader(true);
        if (isDraftReportUtil(configuration)) {
            showLoader(false);
            return configuration?.id;
            //todo add the logic back when design is to add the modal
            // else if (!createOnCompleted && isCompletedReportUtil(configuration)) {
            //     dispatch(
            //         updateNetworkView({
            //             showCompletedReportModal: true
            //         })
            //     );
            // }
        } else {
            let newSimName = `${baseReport?.name} - ${convertDateTimeObjIntoUSFormat(new Date())}`;
            const simulationId = await addNewSimulation(newSimName.trim());
            if (simulationId) {
                await alertsContext?.addAlert('new_simulation_created');
                showLoader(false);
                return simulationId;
            }
        }
        showLoader(false);
    };

    const addNewBidAnalysisConfiguration = async (newSimName: string) => {
        let resultId;
        let reportId = parseInt(analysis_id as string);
        dispatch(updateConfigurationState({ creatingConfiguration: true }));
        await createSimulation(newSimName, reportId).then(async (result) => {
            if (result) {
                history.push(`/bid-analysis/${analysis_id}/config/${result?.id}`);
                await setSimulation({ ...result });
                await updateCurrSimulation(result);
                await closeSimulation();
                resultId = result?.id;
            }
        });
        return resultId;
    };

    const createBidAnalysisConfigurationOnEdit = async () => {
        const isDraftBidAnalysis = Object.keys(configuration).length > 0;
        await showLoader(true);
        if (isDraftBidAnalysis) {
            showLoader(false);
            return configuration?.id;
        } else {
            let newSimName = `${currBidAnalysisData?.name} - ${convertDateTimeObjIntoUSFormat(
                new Date()
            )}`;
            const simulationId = await addNewBidAnalysisConfiguration(newSimName.trim());
            if (simulationId) {
                // Hiding the notification for now because we will be updating the alert layout goign forward
                // await alertsContext?.addAlert('new_draft_bid_analysis_created');
                showLoader(false);
                return simulationId;
            } else {
                dispatch(
                    updateConfigurationState({
                        creatingConfiguration: false,
                        error: 'Failed to create draft'
                    })
                );
                // Hiding the notification for now because we will be updating the alert layout goign forward
                // alertsContext?.addAlert('draft_bid_analysis_failed');
            }
        }
        showLoader(false);
    };
    const handleRemoveAllAppliedBidFiles = async () => {
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;

        await dispatch(removeAppliedBids(appliedBidFilesIds, configuration));
        await dispatch(getConfigurationActions(currReportId, simulationId));
    };

    const handleSelectSimulation = (alertType: AlertType) => {
        dispatch(onSetConfigurationSuccess({ openManageSimulation: true }));
        alertsContext?.removeAlert(alertType);
    };

    const handleNewSimulation = (alertType: AlertType) => {
        showLoader(true);
        dispatch(onSetConfigurationSuccess({ openManageSimulation: true }));
        alertsContext?.removeAlert(alertType);
        showLoader(false);
    };

    const handleOverrideSimulation = async (
        alertType?: AlertType | undefined,
        configId: any = configuration.id,
        bucketId: any = null,
        isBidAnalysisReport?: boolean
    ) => {
        showLoader(true);
        const updateURL = (reportId: number, configId: number) => {
            const url = `/reports/bucket/${
                bucketId ?? bucket_id
            }/${reportId}/config/${configId}/network/shipper`;
            if (isBidAnalysisReport) history.push(`/bid-analysis/${configId}`);
            else history.push(url);
        };

        await resetConfiguration(configId);
        await getNewConfiguration(configId).then((res) => {
            let newReportRunId = res?.data?.starting_report_run?.id;
            let newReportName = res?.data?.starting_report_run?.name;

            updateURL(newReportRunId, configId);
            dispatch(getConfigurationByRunId(newReportRunId));
            dispatch(
                setSelectedReports({
                    selectedBaseReport: {
                        id: newReportRunId,
                        name: newReportName,
                        enabled: true
                    },
                    selectedCompareReport: {}
                })
            );
        });

        dispatch(
            onSetConfigurationSuccess({
                openManageSimulation: false
            })
        );

        if (alertType) alertsContext?.removeAlert(alertType);
        showLoader(false);
    };

    const actions = !configuration?.report_run ? configurationActions : [];

    const runSimulation = async () => {
        showLoader(true);
        await dispatch(updateConfiguration(currReportId, configuration?.id, actions));
        await dispatch(runConfiguration(configuration?.id));
        if (newSimulationFlow) {
            await alertsContext?.removeAlert('new_simulation_created');
            await alertsContext?.addAlert('run_report');
        }
        dispatch(getConfigurationByRunId(currReportId));
        dispatch(getConfigurationActions(currReportId, configuration?.id));

        if (!newSimulationFlow) dispatch(updateNetworkView({ isRunSimulation: true }));
        showLoader(false);
    };

    return {
        handleSelectedSimulation,
        addNewSimulation,
        closeSimulation,
        createBidAnalysisConfigurationOnEdit,
        createSimulationOnEdit,
        setSimulation,
        handleSelectSimulation,
        handleNewSimulation,
        handleOverrideSimulation,
        runSimulation,
        handleRemoveAllAppliedBidFiles,
        simulation
    };
};

export default useSimulationHook;
