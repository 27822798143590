import { createColumnHelper } from '@tanstack/react-table';
import { Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import ReportStatusInfo from 'components/NetworkManager/ReportStatusInfo';
import moment from 'moment';
import { UnspecifiedDataPlaceholder } from 'shared/ODTable/ODTableStyledComponents';

const ellipsisStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 150,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
};

const StyledSpan = styled('span')({
    ...ellipsisStyle,
    textDecoration: 'underline'
});

const columnHelper = createColumnHelper();

const associatedSimulationsColumns = [
    columnHelper.accessor('id', {
        id: 'id',
        header: () => 'id',
        cell: (info) => info.getValue()
    }),
    columnHelper.accessor('name', {
        id: 'name',
        header: () => 'Report',
        cell: (info) =>
            info.getValue() ? (
                <Tooltip disableInteractive title={info.getValue()}>
                    <StyledSpan>{info.getValue()}</StyledSpan>
                </Tooltip>
            ) : (
                <UnspecifiedDataPlaceholder />
            ),
        size: 170,
        meta: { visibleByDefault: true, name: 'Report' }
    }),
    columnHelper.accessor((row) => row.report_run?.report_status, {
        id: 'status',
        header: () => 'Status',
        cell: (info) => (
            <span style={{ display: 'flex' }}>
                <ReportStatusInfo status={info.getValue() || 'draft'} darkIcon={true} />
            </span>
        ),
        footer: (info) => info.column.id,
        size: 100,
        meta: { visibleByDefault: true, name: 'Status' }
    }),
    columnHelper.accessor('created_at', {
        id: 'created_at',
        header: () => 'Date Created',
        cell: (info) => <span>{moment(info.getValue()).format('MM/DD HH:mm')}</span>,
        size: 125
    })
];

export default associatedSimulationsColumns;
