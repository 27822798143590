/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import { decodeToken } from 'utils/decoder.util';
import ODLoader from 'shared/ODLoader';
import { useDispatch } from 'react-redux';
import { setLaunchDarklyUserIdentified } from 'redux/actions';

const LaunchDarklyUserConfig = () => {
    const client = useLDClient();
    const [token, setToken] = useState(null);
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLaunchDarklyUserIdentified(false));
    }, []);

    const getToken = async () => {
        if (isLoading || !isAuthenticated || token) return;
        const _token = await getAccessTokenSilently();
        setToken(_token);
    };

    useEffect(() => {
        if (!token) getToken();
        else if (token) {
        }
    }, [token, isLoading, isAuthenticated]);

    useEffect(() => {
        if (!token) {
            return;
        }
        const { userId, organizationId, userEmail } = decodeToken(token);
        if (userId && organizationId && userEmail && client) {
            client
                .identify(
                    {
                        key: userId,
                        email: userEmail,
                        custom: { organization: organizationId }
                    },
                    null
                )
                .then(() => {
                    dispatch(setLaunchDarklyUserIdentified(true));
                })
                .catch(() => {
                    dispatch(setLaunchDarklyUserIdentified(false));
                });
        }

        // sets user with full story when that data becomes available
        if (userEmail) {
            window.FS.identify(userId, {
                email: userEmail
            });
        }
    }, [token]);

    return <ODLoader open={false} text="Loading..." />;
};

export default LaunchDarklyUserConfig;
