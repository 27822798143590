import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ODModal } from 'shared';
import { Box } from '@mui/system';
import { Tooltip, Typography } from '@mui/material';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import InsightsIcon from '@mui/icons-material/Insights';
import {
    SubHeader,
    StyledSelectedRowDetail,
    StyledFreightBonusBody
} from './UploadedBidDataAccordion.styles';
import { BID_CONFIG_INFO_MESSAGES } from 'constants/bidding';
import * as FS from '@fullstory/browser';
import API from 'utils/axios';
import ODLoader from 'shared/ODLoader';

interface ConfirmationModalProps {
    bidAnalysesRuns?: any;
    openConfirmModal: boolean;
    setOpenConfirmModal: any;
    setBidAnalysesRuns: any;
    closeBidAnalysisSlideout: any;
}

const RunBidAnalysesConfirmationModal = ({
    bidAnalysesRuns,
    openConfirmModal,
    setOpenConfirmModal,
    setBidAnalysesRuns,
    closeBidAnalysisSlideout
}: ConfirmationModalProps) => {
    const history = useHistory();
    const allRunNamesFormatted = bidAnalysesRuns?.runs.reduce((acc: any[], run: any) => {
        if (run?.draft_report_name)
            acc.push({ name: run.draft_report_name, id: run.id, finalized: false });
        if (run?.finalized_report_name)
            acc.push({ name: run.finalized_report_name, id: run.id, finalized: true });
        return acc;
    }, []);

    const [loading, setLoader] = useState(false);
    const [allRunNames, setAllRunNames] = useState(allRunNamesFormatted);

    const handleClose = () => {
        FS.event('Bid Analysis_Runs_BidConfig_Click Close Button in Confirmation Modal', {});
        setOpenConfirmModal(false);
    };
    const DisplaySubHeader = () => {
        let isPlural = allRunNames?.length > 0 ? 's' : '';

        return (
            <SubHeader>{`(${
                allRunNames?.length > 0
                    ? allRunNames?.length
                    : Object.keys(allRunNames).length > 0
                    ? 1
                    : 0
            }) Run${isPlural}`}</SubHeader>
        );
    };

    const DisplaySelectedRowDetail = () => {
        return (
            <>
                {allRunNames.map((item: any) => {
                    return (
                        <StyledSelectedRowDetail>
                            <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                                <Box sx={{ marginRight: '16px', display: 'flex' }}>
                                    {item.finalized ? <InsightsIcon /> : <RequestPageIcon />}
                                </Box>
                                <Tooltip title={item.name}>
                                    <Box>{item.name}</Box>
                                </Tooltip>
                            </Box>
                            {/* // TODO (outside of mvp) */}
                            {/* {!(item.id === 'uploadedBidData' && !item.finalized) && (
                                <ODIconButton
                                    sx={{ marginRight: 0, padding: '0 1px 0 0' }}
                                    disableHover={true}
                                    id={'delete'}
                                    onClick={() => {
                                        handleRemoveItem(item);
                                    }}
                                    buttonType={BUTTON_TYPE.TRANSPARENT}
                                    icon={<ClearIcon sx={{ fill: theme.palette.neutral.black }} />}
                                />
                            )} */}
                        </StyledSelectedRowDetail>
                    );
                })}
            </>
        );
    };
    // TODO (outside of mvp)
    // const handleRemoveItem = (item: any) => {
    //     const filteredRuns = allRunNames.filter((run: any) => {
    //         if (item.id !== run.id) return run;
    //         else if (item.id === run.id) {
    //             if (run.finalized !== item.finalized && !run.finalized) {
    //                 return run;
    //             }
    //         }
    //     });
    //     setAllRunNames(filteredRuns);
    //     // setBidAnalysesRuns?()
    // };

    const handleSubmit = async () => {
        setLoader(true);
        await API.post(`/bidding/:run-bid-analysis/`, bidAnalysesRuns);
        setAllRunNames([]);
        handleClose();
        closeBidAnalysisSlideout();
        setLoader(false);
        history.push('/');
    };

    return (
        <>
            <ODLoader open={loading} text="Loading..." />
            <ODModal
                show={openConfirmModal}
                heading={'Confirm Bid Analysis Runs'}
                handleClose={handleClose}
                actionButtonText={'confirm'}
                handleAction={() => {
                    FS.event(
                        'Bid Analysis_Bulk Actions_Click Confirm Button in Confirmation Modal',
                        {}
                    );
                    handleSubmit();
                }}
                children={
                    <>
                        <DisplaySubHeader />
                        <StyledFreightBonusBody>
                            <DisplaySelectedRowDetail />
                        </StyledFreightBonusBody>
                        <Typography sx={{ fontSize: '14px', marginTop: '16px' }}>
                            {BID_CONFIG_INFO_MESSAGES.CONFIRM_BID_ANALYSES_RUNS}
                        </Typography>
                    </>
                }
            />
        </>
    );
};

export default RunBidAnalysesConfirmationModal;
