import React from 'react';
import { Box, styled, Tooltip } from '@mui/material';
import { SubdirectoryArrowRight, Close, MonetizationOn } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AnyObject } from 'yup/lib/types';
import palette from 'theme/palette';
import { ODCard, ODIconButton, CommonSvgIcon } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { getLocation } from 'utils/common.util';
import { RootState } from 'redux/reducers';
import { BIDDING_NAMES } from 'constants/bidding';

import { HISTORY_CHANGES_TYPES } from 'constants/network';
import { StyledPaper, TextContent } from './HistorySlideout.styles';

const CityStateEllipsis = styled(Box)(() => ({
    maxWidth: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}));

interface historyHandleBidConfigCardProps {
    key: string;
    handleRestore: (e: any, data: AnyObject, restoreAll?: boolean) => any;
    id: number;
    item_type: string;
    value: any;
    readOnly?: boolean;
}
const HistoryHandleBidConfigCard = ({
    id,
    key,
    value,
    handleRestore,
    readOnly,
    item_type
}: historyHandleBidConfigCardProps) => {
    const laneName = value?.lane ? value?.lane?.split('-') : null;
    const { reportLocations } = useSelector((state: RootState) => state.ReportKPIReducer);
    const { bidConfigLocations } = useSelector((state: RootState) => state.BiddingReducer);
    const { locationVisualization } = useSelector((state: RootState) => state.settingsReducer);
    const { defaultRateType } = useSelector((state: RootState) => state.BiddingReducer);
    const originLoc = getLocation(
        laneName[0],
        reportLocations,
        locationVisualization,
        bidConfigLocations
    ) as string;
    const destLoc = getLocation(
        laneName[1],
        reportLocations,
        locationVisualization,
        bidConfigLocations
    ) as string;
    const combinationOriginCityStateZip = getLocation(
        laneName[0],
        reportLocations,
        'city-state-zip',
        bidConfigLocations
    ) as string;
    const combinationDestCityStateZip = getLocation(
        laneName[1],
        reportLocations,
        'city-state-zip',
        bidConfigLocations
    ) as string;

    const [originCity, originState] = originLoc.split(',');
    const [destCity, destState] = destLoc.split(',');

    const CityStateText = ({ city, state }: { city: string; state: string }) => {
        return (
            <>
                <CityStateEllipsis>{city}</CityStateEllipsis>,{state}
            </>
        );
    };

    const generateLocationText = ({
        combinationCityStateZip,
        city,
        state,
        zip,
        locationVisualization
    }: {
        combinationCityStateZip: string;
        city: string;
        state: string;
        zip: string;
        locationVisualization: string;
    }) => (
        <Tooltip title={combinationCityStateZip}>
            <Box sx={{ display: 'flex' }}>
                {locationVisualization === 'city-state' ? (
                    <CityStateText city={city} state={state} />
                ) : (
                    <>{zip}</>
                )}
            </Box>
        </Tooltip>
    );

    const originLocationText = generateLocationText({
        combinationCityStateZip: combinationOriginCityStateZip,
        city: originCity,
        state: originState,
        zip: originLoc,
        locationVisualization
    });
    const destLocationText = generateLocationText({
        combinationCityStateZip: combinationDestCityStateZip,
        city: destCity,
        state: destState,
        zip: destLoc,
        locationVisualization
    });

    return (
        <StyledPaper readOnly={readOnly} key={key}>
            {value?.lane && (
                <ODCard sx={{ justifyContent: 'space-between' }}>
                    <Box display="flex">
                        <CommonSvgIcon />
                        <TextContent id={`lane-id-${value?.lane}`}>
                            <b>Lane:</b>&nbsp;
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    textDecoration:
                                        item_type === HISTORY_CHANGES_TYPES.REMOVE
                                            ? 'line-through'
                                            : 'none'
                                }}>
                                {originLocationText}

                                <Box mx="2px">-</Box>
                                {destLocationText}
                            </Box>
                        </TextContent>
                    </Box>
                    {!readOnly && (
                        <ODIconButton
                            disableHover={true}
                            sx={{
                                padding: 0
                            }}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            icon={<Close sx={{ color: palette.neutral.black }} />}
                            onClick={(e) =>
                                handleRestore(e, {
                                    id,
                                    ...value,
                                    item_type
                                })
                            }
                        />
                    )}
                </ODCard>
            )}

            {item_type === HISTORY_CHANGES_TYPES.CHANGE_BID_RATE_PER_MILE && (
                <>
                    <ODCard>
                        <MonetizationOn />
                        <TextContent>
                            <b>{` ${
                                defaultRateType === 'flat_rate'
                                    ? BIDDING_NAMES.FLAT_RATE
                                    : BIDDING_NAMES.RATE_PER_MILE
                            }:`}</b>
                            &nbsp; ${(value.original || 0).toFixed(2)}
                        </TextContent>
                    </ODCard>
                    <ODCard>
                        <SubdirectoryArrowRight />
                        <TextContent>
                            <b>Change: </b>&nbsp; {'$' + parseFloat(value.change || 0).toFixed(2)}
                        </TextContent>
                    </ODCard>
                </>
            )}
            {item_type === HISTORY_CHANGES_TYPES.REMOVE && (
                <ODCard>
                    <SubdirectoryArrowRight />
                    <TextContent>
                        <b>Change: </b>&nbsp;Lane Removed
                    </TextContent>
                </ODCard>
            )}
        </StyledPaper>
    );
};

export default HistoryHandleBidConfigCard;
