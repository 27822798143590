import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

interface ClearFilterButtonProps {
    clickHandler: () => void;
    color: string;
}
const ClearFilterButton = ({ clickHandler, color }: ClearFilterButtonProps) => (
    <IconButton
        sx={{ padding: '2px' }}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            clickHandler();
        }}
        aria-label="clear-selection"
        id="clear-selection">
        <Close
            sx={{
                ...{ color },
                height: '14px',
                width: '14px'
            }}
        />
    </IconButton>
);

export default ClearFilterButton;
