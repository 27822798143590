/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { DriverLanesIcon } from 'assets/images';
import LibraryAddCheckSharpIcon from '@mui/icons-material/LibraryAddCheckSharp';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty, intersection } from 'lodash';
import { ODFooterActionBar, ODButton } from 'shared';
import { ODModal } from 'shared';
import { updateNetworkView } from 'redux/actions/networkActions';
import StarsIcon from '@mui/icons-material/Stars';
import {
    getConfigurationActions,
    setDraftConfigurationActions,
    updateConfiguration,
    bulkDeleteBidLanes,
    getKPIbyReportId
} from 'redux/actions';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { BUTTON_TYPE } from 'constants/colors';
import { getLocation } from 'utils/common.util';
import { isActionAllowed, getLoadsFilterObject } from 'utils/network.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import palette from 'theme/palette';
import { NETWORK_TABLE_TYPE, ROW_TYPE } from 'constants/network';
import { flattenObject } from 'utils/object.util';
import { useNetworkViewHook, useSimulationHook, useShipperHook } from 'utils/hooks';

const DeleteMessageBox = styled(Box)(() => ({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px'
}));

const ShipperActionBar = ({ filteredRows }) => {
    const { newSimulationFlow, freightBonus, v2KpIs } = useFlags();
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [undoAllRemovals, setUndoAllRemovals] = useState(false);
    const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);
    const [checkedRowsIds, setCheckedRowsIds] = useState([]);

    const dispatch = useDispatch();
    const { compare_run, base_run } = useParams();
    const alertsContext = useAlerts();

    const { handleShipperLaneEdit, handleFreightBonusForSelectedRows } = useShipperHook();
    const { createSimulationOnEdit } = useSimulationHook();

    const { checked, currTab, isListView } = useSelector((state) => state.NetworkReducer);
    const { selectedShippers, selectedLanes } = checked;
    const { configurationActions, configuration } = useSelector(
        (state) => state.ConfigurationReducer
    );
    const { locationVisualization } = useSelector(({ settingsReducer }) => settingsReducer);
    const reportLocations = useSelector((state) => state.ReportKPIReducer.reportLocations);
    const { bidConfigLocations } = useSelector((state) => state.BiddingReducer);
    const {
        reportFilters,
        loadFilters,
        loadFilters: { filterBy },
        loadSource
    } = useSelector((state) => state.ReportsReducers);

    const baseFilter = compare_run ? { compare_run_id: compare_run } : {};
    const { currReport, currReportId } = useNetworkViewHook();
    const readOnly = currReport?.read_only || compare_run;
    const isShipperTab = currTab === NETWORK_TABLE_TYPE.SHIPPERS;
    const isShipperActionBar =
        (isShipperTab && !loadFilters?.isFilterSet) || (!isShipperTab && loadFilters?.isFilterSet);

    const mapView = !isListView;
    const isShipperOrLaneKey = (opposite = false) => {
        return isShipperActionBar
            ? opposite
                ? ROW_TYPE.LANE
                : ROW_TYPE.SHIPPER
            : opposite
            ? ROW_TYPE.SHIPPER
            : ROW_TYPE.LANE;
    };

    const flattenedActions = configurationActions.map((action) => flattenObject(action));

    const sortByOldest = flattenedActions.sort(function (b, a) {
        return new Date(b.created_at) - new Date(a.created_at);
    });

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
    }

    const uniqueActions = getUniqueListBy(sortByOldest, isShipperOrLaneKey());

    const actionsFilteredByBonus = uniqueActions.filter((action) => {
        return action?.type === 'bonus' && action?.value === true;
    });

    const bonusAppliedRowIds = actionsFilteredByBonus.map((item) => {
        return item[isShipperOrLaneKey()];
    });

    const unappliedBonusRows = checkedRowsIds.filter((row) => {
        return !bonusAppliedRowIds.includes(row);
    });

    const displayRemoveBonusButton = intersection(bonusAppliedRowIds, checkedRowsIds).length > 0;
    const displayApplyBonusButton = unappliedBonusRows?.length > 0;

    const BULK_REMOVE_MESSAGE = `Are you sure that you want to bulk remove all ${
        checkedRowsIds.length
    } ${isShipperActionBar ? NETWORK_TABLE_TYPE.SHIPPERS : NETWORK_TABLE_TYPE.LANES} selected?`;

    let selectedRows = [];
    let displayedActionBarNameLeft = '';
    let leftNameTooltip = '';
    let displayedActionBarNameRight = '';
    let rightNameTooltip = '';
    let removeButtonText = '';
    let selectAllRowsText;

    useEffect(() => {
        setCheckedRowsIds(isShipperActionBar ? selectedShippers : selectedLanes);
    }, [selectedShippers, selectedLanes, isShipperActionBar]);

    useEffect(() => {
        if (isEmpty(selectedShippers) && isEmpty(selectedLanes))
            dispatch(
                updateNetworkView({
                    currShipperActionBar: null
                })
            );
        setAllRowsSelected(checkedRowsIds?.length === filteredRows?.length ?? false);
    }, [selectedShippers, selectedLanes, checkedRowsIds]);

    useEffect(() => {
        if (
            configurationActions.some(
                (action) =>
                    action.type === 'remove' &&
                    action.value[isShipperOrLaneKey()] &&
                    (loadFilters?.isFilterSet
                        ? action.value[isShipperOrLaneKey(true)] === filterBy.id
                        : action.value[isShipperOrLaneKey(true)] === null)
            )
        )
            setUndoAllRemovals(true);
        else setUndoAllRemovals(false);
    }, [configurationActions]);

    const actionBarDataPopulate = (checkedRowsIds) => {
        if (checkedRowsIds?.length === 1) {
            selectedRows = isShipperActionBar
                ? reportFilters?.shippers?.find((load) => load?.id === checkedRowsIds[0])
                : reportFilters?.lanes?.find((load) => load?.id === checkedRowsIds[0]) || {};
            if (isShipperActionBar) displayedActionBarNameLeft = selectedRows?.id;
            else {
                const pickUpDropOffLocations = selectedRows?.id?.split('-');
                displayedActionBarNameLeft = `${getLocation(
                    pickUpDropOffLocations?.[0],
                    reportLocations,
                    locationVisualization,
                    bidConfigLocations
                )}`;
                leftNameTooltip = getLocation(
                    pickUpDropOffLocations?.[0],
                    reportLocations,
                    'city-state-zip',
                    bidConfigLocations
                );
                displayedActionBarNameRight = `${getLocation(
                    pickUpDropOffLocations?.[1],
                    reportLocations,
                    locationVisualization,
                    bidConfigLocations
                )}`;
                rightNameTooltip = getLocation(
                    pickUpDropOffLocations?.[1],
                    reportLocations,
                    'city-state-zip',
                    bidConfigLocations
                );
            }
            removeButtonText = `Remove selected ${
                isShipperActionBar ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE
            } (1)`;
        } else if (checkedRowsIds?.length > 1) {
            selectedRows = isShipperActionBar
                ? reportFilters?.shippers?.filter((load) => checkedRowsIds.includes(load.id))
                : reportFilters?.lanes?.filter((load) => checkedRowsIds.includes(load.id)) || {};
            displayedActionBarNameLeft = `(${checkedRowsIds.length}) ${
                isShipperActionBar ? 'Shippers' : 'Lanes'
            } Selected`;
            removeButtonText = `Remove selected ${isShipperActionBar ? 'Shippers' : 'Lanes'} (${
                checkedRowsIds.length
            })`;
        }

        selectAllRowsText = `Select All ${isShipperActionBar ? 'Shippers' : 'Lanes'} (${
            filteredRows?.length
        })`;
    };

    actionBarDataPopulate(checkedRowsIds);

    const handleDelete = async () => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;
        let actions = [...configurationActions];

        checkedRowsIds.forEach(async (rowId) => {
            const action = {
                type: 'remove',
                item_type: 'shipper-lane',
                value: {
                    lane:
                        filterBy?.type === ROW_TYPE.LANE
                            ? filterBy?.id
                            : selectedLanes.length > 0
                            ? rowId
                            : null,
                    shipper:
                        filterBy?.type === ROW_TYPE.SHIPPER
                            ? filterBy?.id
                            : selectedShippers.length > 0
                            ? rowId
                            : null
                }
            };
            actions.push(action);
            // Logic for bid lanes
            if (selectedLanes?.length > 0) {
                const laneData = reportFilters?.lanes?.find((row) => row.id === rowId);
                if (laneData?.is_bid)
                    await bulkDeleteBidLanes(laneData?.bid_config_id, laneData?.bid_id);
            }
        });

        await dispatch(setDraftConfigurationActions(actions));
        await dispatch(updateConfiguration(currReportId, simulationId, actions));
        await dispatch(getConfigurationActions(currReportId, simulationId));
        dispatch(
            updateNetworkView({
                currShipperActionBar: null
            })
        );
        dispatch(
            updateNetworkView({
                checked: {
                    ...checked,
                    [isShipperActionBar ? 'selectedShippers' : 'selectedLanes']: [],
                    [isShipperActionBar ? 'allShippers' : 'allLanes']: false
                }
            })
        );
        setCheckedRowsIds([]);
    };

    const handleDeleteConfirmation = async () => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        if (checkedRowsIds.length >= 2) setConfirmDeletionOpen(true);
        else await handleDelete();
    };

    const undoRemovalAction = async () => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;
        const actions = configurationActions
            .filter(
                (action) =>
                    action.type === 'remove' &&
                    action.value[isShipperOrLaneKey()] &&
                    (loadFilters?.isFilterSet
                        ? action.value[isShipperOrLaneKey(true)] === filterBy.id
                        : action.value[isShipperOrLaneKey(true)] === null)
            )
            .map((action) => {
                return { ...action, type: 'restore' };
            });

        if (!actions) return;

        const newActions = configurationActions.filter((item) => item.type !== 'remove');
        dispatch(setDraftConfigurationActions(newActions));
        await dispatch(updateConfiguration(currReportId, simulationId, actions));
        dispatch(getConfigurationActions(currReportId, simulationId));
    };

    const handleAllRowsCheckbox = () => {
        setAllRowsSelected(true);

        const rowsSelected = filteredRows.map((row) => row.original.id);
        const areAllRowsSelected =
            rowsSelected.length ===
            (isShipperActionBar ? reportFilters?.shippers?.length : reportFilters?.lanes?.length);

        dispatch(
            updateNetworkView({
                checked: {
                    ...checked,
                    [isShipperActionBar ? 'allShippers' : 'allLanes']: areAllRowsSelected,
                    [isShipperActionBar ? 'selectedShippers' : 'selectedLanes']: rowsSelected
                }
            })
        );

        const filterObj = getLoadsFilterObject(
            areAllRowsSelected,
            isShipperActionBar,
            rowsSelected,
            checked,
            loadFilters?.isFilterSet,
            loadSource
        );
        dispatch(
            getKPIbyReportId({
                runId: base_run,
                filter: { ...baseFilter, ...filterObj },
                v2KpIsFlag: v2KpIs
            })
        );

        dispatch(
            updateNetworkView({
                currShipperActionBar: isShipperActionBar ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE
            })
        );
    };

    const buttonCount = () => {
        let count = 2;
        if (undoAllRemovals) count += 1;
        if (!allRowsSelected) count += 1;
        if (checkedRowsIds?.length === 1) count += 1;
        if (displayRemoveBonusButton && freightBonus) count += 1;
        return count;
    };

    return (
        <>
            <ODFooterActionBar
                sx={{
                    flexDirection: buttonCount() > 2 && mapView ? 'column' : 'row',
                    alignItems: buttonCount() > 2 && mapView ? 'flex-start' : 'center',
                    zIndex: 3,
                    paddingBottom: buttonCount() > 2 && mapView ? '16px' : '8px',
                    paddingTop: buttonCount() > 2 && mapView ? '16px' : '8px'
                }}
                buttonsOverride={
                    <div
                        style={{
                            display: buttonCount() > 3 && mapView ? 'grid' : 'flex',
                            gridTemplateColumns: buttonCount() > 3 && mapView ? 'auto auto ' : '',
                            gridRowGap: '8px'
                        }}>
                        {freightBonus && displayRemoveBonusButton && (
                            <ODButton
                                disabled={readOnly}
                                buttonText={`Remove Bonus`}
                                endIcon={<StarsIcon />}
                                sx={{
                                    marginRight: '8px',
                                    width: mapView
                                        ? buttonCount() > 3
                                            ? '300px'
                                            : buttonCount() === 3
                                            ? '195px'
                                            : 'auto'
                                        : 'auto',
                                    justifyContent: 'center'
                                }}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={() => {
                                    handleFreightBonusForSelectedRows(
                                        'remove',
                                        selectedRows,
                                        bonusAppliedRowIds,
                                        unappliedBonusRows,
                                        checkedRowsIds
                                    );
                                }}
                            />
                        )}
                        {freightBonus && displayApplyBonusButton && (
                            <ODButton
                                disabled={readOnly}
                                buttonText={`Apply Bonus`}
                                endIcon={<StarsIcon />}
                                sx={{
                                    marginRight: '8px',
                                    width: mapView
                                        ? buttonCount() > 3
                                            ? '300px'
                                            : buttonCount() === 3
                                            ? '195px'
                                            : 'auto'
                                        : 'auto',
                                    justifyContent: 'center'
                                }}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={() => {
                                    handleFreightBonusForSelectedRows(
                                        'add',
                                        selectedRows,
                                        bonusAppliedRowIds,
                                        unappliedBonusRows,
                                        checkedRowsIds
                                    );
                                }}
                            />
                        )}

                        {undoAllRemovals && (
                            <ODButton
                                buttonText={'Undo All Removals'}
                                sx={{
                                    marginRight: '8px',
                                    width: mapView
                                        ? buttonCount() > 3
                                            ? '300px'
                                            : buttonCount() === 3
                                            ? '195px'
                                            : 'auto'
                                        : 'auto',
                                    justifyContent: 'center'
                                }}
                                buttonType={BUTTON_TYPE.BLUE}
                                startIcon={<UndoSharpIcon />}
                                onClick={(e) => {
                                    undoRemovalAction();
                                }}
                            />
                        )}
                        {!allRowsSelected && (
                            <ODButton
                                buttonText={selectAllRowsText}
                                sx={{
                                    marginRight: '8px',
                                    width: mapView
                                        ? buttonCount() > 3
                                            ? '300px'
                                            : buttonCount() === 3
                                            ? '195px'
                                            : 'auto'
                                        : 'auto',
                                    justifyContent: 'center'
                                }}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={(e) => {
                                    handleAllRowsCheckbox();
                                }}
                            />
                        )}
                        <ODButton
                            disabled={readOnly}
                            buttonText={removeButtonText}
                            sx={{
                                marginRight: checkedRowsIds?.length === 1 ? '8px' : 0,
                                marginLeft: 0,
                                width: mapView
                                    ? buttonCount() > 3
                                        ? '300px'
                                        : buttonCount() === 3
                                        ? '202px'
                                        : 'auto'
                                    : 'auto',
                                justifyContent: 'center'
                            }}
                            endIcon={<RemoveSharpIcon />}
                            buttonType={BUTTON_TYPE.BLUE}
                            onClick={() => {
                                handleDeleteConfirmation();
                            }}
                        />
                        {checkedRowsIds?.length === 1 && (
                            <ODButton
                                disabled={readOnly}
                                buttonText={`Edit ${
                                    isShipperActionBar ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE
                                }`}
                                endIcon={<EditIcon />}
                                sx={{
                                    marginRight: 0,
                                    width: mapView
                                        ? buttonCount() > 3
                                            ? '300px'
                                            : buttonCount() === 3
                                            ? '195px'
                                            : 'auto'
                                        : 'auto',
                                    justifyContent: 'center'
                                }}
                                buttonType={BUTTON_TYPE.BLUE}
                                onClick={() => {
                                    handleShipperLaneEdit({
                                        ...selectedRows,
                                        type: isShipperActionBar ? ROW_TYPE.SHIPPER : ROW_TYPE.LANE
                                    });
                                }}
                            />
                        )}
                    </div>
                }
                ribbonIcon={
                    checkedRowsIds?.length === 1 ? (
                        isShipperActionBar ? (
                            <LocalShippingIcon />
                        ) : (
                            <DriverLanesIcon
                                style={{
                                    fill: palette.neutral.white
                                }}
                            />
                        )
                    ) : (
                        <LibraryAddCheckSharpIcon />
                    )
                }
                ribbonText={displayedActionBarNameLeft}
                ribbonTextTooltip={leftNameTooltip}
                ribbonIcon2={!isShipperActionBar ? <ArrowForwardSharpIcon /> : undefined}
                ribbonText2={displayedActionBarNameRight}
                ribbonText2Tooltip={rightNameTooltip}
            />
            <ODModal
                show={confirmDeletionOpen}
                handleClose={() => setConfirmDeletionOpen(false)}
                handleAction={() => handleDelete()}
                heading={`Confirm Bulk ${isShipperActionBar ? 'Shippers' : 'Lanes'} Removal`}
                actionButtonText="Confirm">
                <DeleteMessageBox>{BULK_REMOVE_MESSAGE}</DeleteMessageBox>
            </ODModal>
        </>
    );
};
export default ShipperActionBar;
