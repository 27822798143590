import React from 'react';
import { Box } from '@mui/material';
import palette from 'theme/palette';

const ODCard = ({
    children,
    sx,
    backgroundColor = palette.transparent
}: {
    children: any;
    sx?: any;
    backgroundColor?: string;
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '40px',
                padding: '0 16px',
                backgroundColor: backgroundColor,
                ...sx
            }}>
            {children}
        </Box>
    );
};

export default ODCard;
