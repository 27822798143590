import React, { useRef } from 'react';
import { Dialog, Box, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'shared/ODButton';
import palette from 'theme/palette';
import { BUTTON_TYPE } from 'constants/colors';

//TODO legacy code: need to verify the styling format
//TODO need to build a test for modal component

const useStyles = makeStyles((theme) => ({
    menuIconButton: {
        minWidth: '0px',
        height: '40px',
        width: '40px'
    },
    iconButton: {
        minWidth: '0px',
        height: '40px',
        width: '40px',
        borderRadius: '4px',
        margin: '0px !important'
    },
    dialogPaper: {
        padding: '32px',
        width: '392px !important'
    }
}));

interface ODModalProps {
    handleClose?: React.MouseEventHandler<HTMLButtonElement>;
    show: boolean;
    handleAction?: () => void;
    handleSecondAction?: () => void;
    actionButtonText?: string | undefined;
    closeButtonText?: string | undefined;
    heading: string;
    boldHeading?: boolean;
    children: any;
    actionDisabled?: boolean;
    enableCustomizedButton?: boolean;
    actionButtonProps?: any;
    runActionOnEnter?: boolean;
    disableDefaultClose?: boolean;
    description?: string;
}

const ODModal = ({
    handleClose,
    show,
    handleAction,
    handleSecondAction,
    actionButtonText = 'confirm',
    closeButtonText = 'cancel',
    heading,
    boldHeading,
    children,
    actionDisabled = false,
    enableCustomizedButton = false,
    actionButtonProps = {},
    runActionOnEnter,
    disableDefaultClose,
    description,
    ...props
}: ODModalProps) => {
    const classes = useStyles();
    const buttonRef = useRef<HTMLButtonElement>();
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (handleAction && runActionOnEnter) handleAction();
        }
    };

    return (
        <Dialog
            open={show}
            keepMounted
            classes={{ paper: classes.dialogPaper }}
            onClose={disableDefaultClose ? () => '' : handleClose}
            onKeyDown={handleKeyDown}
            {...props}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '36px'
                }}>
                <Box
                    component={Typography}
                    sx={{
                        fontWeight: boldHeading ? 600 : 400,
                        fontSize: '18px',
                        textTransform: 'capitalize'
                    }}>
                    {heading}
                </Box>

                <IconButton
                    onClick={handleClose}
                    className={classes.iconButton}
                    aria-label="close modal"
                    classes={{ root: classes.menuIconButton }}
                    size="large">
                    <CloseIcon sx={{ color: palette.neutral.black }} />
                </IconButton>
            </Box>
            {description && (
                <Box
                    sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        marginY: '16px',
                        lineHeight: '20px'
                    }}>
                    {description}
                </Box>
            )}

            <>{children}</>

            {!enableCustomizedButton && (
                <Box
                    sx={{
                        marginTop: '32px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                    <Button
                        buttonText={closeButtonText}
                        width="188px"
                        onClick={handleSecondAction ? handleSecondAction : handleClose}
                    />
                    <Button
                        ref={buttonRef}
                        disabled={actionDisabled}
                        buttonType={BUTTON_TYPE.BLUE}
                        type="submit"
                        width="188px"
                        buttonText={actionButtonText}
                        onClick={handleAction}
                        {...actionButtonProps}
                    />
                </Box>
            )}
        </Dialog>
    );
};

export default ODModal;
