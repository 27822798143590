import {
    CLEAR_BID_ANALYSIS,
    CLEAR_BID_GOALS,
    UPDATE_BID_ANALYSIS_REDUCER,
    ADD_TABLE_ERROR,
    CLEAR_TABLE_ERROR,
    SET_BID_ANALYSIS_APPLIED_BID_AND_DEFAULT_RATE_TYPE,
    BID_CONFIG_LOCATIONS_SUCCESS
} from 'redux/constants/actionTypes';
import API from 'utils/axios';
import { toast } from 'react-toastify';
import { CellEdit, DataRow } from 'shared/ODTable/ODTableTypes';
import { RootState } from 'redux/reducers';
import { getLocation } from 'utils/common.util';
import { roundFieldsToInt } from 'utils/number.util';
import { buildQueryString } from 'utils/api.util';
import {
    createSimulation,
    onGetConfigurationActionSuccess,
    getConfigurationActions,
    setConfiguration,
    updateConfiguration,
    updateConfigurationState
} from './configurationActions';
import { updateLoadingState } from './loadingActions';
import { DECISION } from 'constants/bidding';
import { getLocationByReportId } from './reportKPIAction';
import { getModifiedPartialCopy } from 'shared/ODTable/ODTableUtils';
import {
    applyAction,
    applyBidAnalysisActions,
    captureLinkedEdits,
    convertTo3DigitZipCode,
    deriveActionsFromEdits,
    getUpdatedSettings
} from 'utils/bidAnalysis.util';
import { convertDateTimeObjIntoUSFormat } from 'utils/date.util';
import { getLocationByBidConfigId } from './biddingActions';

export const updateBidAnalysis = (payload: any) => {
    return {
        type: UPDATE_BID_ANALYSIS_REDUCER,
        payload
    };
};

export const clearBidAnalysis = () => {
    return {
        type: CLEAR_BID_ANALYSIS
    };
};

export const clearBidGoals = () => {
    return {
        type: CLEAR_BID_GOALS
    };
};

export const addTableErrors = (errors: { rowId: number; columnId: string }[]) => ({
    type: ADD_TABLE_ERROR,
    payload: { errors }
});

export const clearTableErrors = (errors: { rowId: number; columnId: string }[]) => ({
    type: CLEAR_TABLE_ERROR,
    payload: { errors }
});

export const setAppliedBidsAndDefaultRateType = (payload: any) => {
    return {
        type: SET_BID_ANALYSIS_APPLIED_BID_AND_DEFAULT_RATE_TYPE,
        payload
    };
};

export const loadBidAnalysis = ({
    analysis_id,
    config_id,
    settings,
    dontFetchEdits,
    convertTo3Zip,
    isFinalizedBid
}: {
    analysis_id: string | number;
    config_id: string | number | undefined;
    settings?: {};
    dontFetchEdits?: boolean;
    convertTo3Zip?: boolean;
    isFinalizedBid?: boolean;
}) => {
    return async (dispatch: any, getState: () => RootState) => {
        const {
            currBidAnalysisData,
            bidAnalysisSetting,
            appliedBidFiles,
            bidAnalysisColumnFormatSelections
        } = getState().BidAnalysisReducer;
        const { bidConfigLocations } = getState().BiddingReducer;
        dispatch(updateLoadingState({ showLoading: true }));
        let _appliedBidFiles = appliedBidFiles;
        let _bidAnalysisColumnFormatSelection = bidAnalysisColumnFormatSelections;
        let _currBidAnalysisData = currBidAnalysisData;
        let shouldUpdateBidConfigInfo =
            !currBidAnalysisData?.configuration || Number(analysis_id) !== currBidAnalysisData?.id;
        let _bidConfigLocations = shouldUpdateBidConfigInfo ? [] : bidConfigLocations;
        if (Number(analysis_id) !== currBidAnalysisData?.id) {
            await dispatch(
                getLocationByReportId({
                    runId: analysis_id as string
                })
            );
        }
        if (shouldUpdateBidConfigInfo || _bidConfigLocations.length === 0) {
            _currBidAnalysisData = await getReportDataByReportId(analysis_id, config_id);
            if (_currBidAnalysisData?.configuration) {
                const appliedBidFilesData = await getBidAnalysisReportAppliedBids(
                    _currBidAnalysisData.configuration
                );
                if (appliedBidFilesData?.success) {
                    _appliedBidFiles = appliedBidFilesData.results;
                    _bidAnalysisColumnFormatSelection = {
                        rate: _appliedBidFiles[0]?.bid_configuration?.default_rate_type
                    };
                    const appliedBidConfigId = _appliedBidFiles[0]?.bid_configuration?.id;
                    if (appliedBidConfigId) {
                        _bidConfigLocations =
                            (await getLocationByBidConfigId(appliedBidConfigId)) || [];
                    } else {
                        _bidConfigLocations = [];
                    }
                } else {
                    _appliedBidFiles = [];
                }
            }
        }

        if (!currBidAnalysisData?.configuration) {
            dispatch(
                updateBidAnalysis({
                    currBidAnalysisData: _currBidAnalysisData
                })
            );
            dispatch(
                setAppliedBidsAndDefaultRateType({
                    appliedBidFiles: _appliedBidFiles,
                    bidAnalysisColumnFormatSelections: _bidAnalysisColumnFormatSelection
                })
            );
            dispatch({
                type: BID_CONFIG_LOCATIONS_SUCCESS,
                bidConfigLocations: [..._bidConfigLocations]
            });
        }

        const updatedSettings = getUpdatedSettings(settings, bidAnalysisSetting);
        const settingsQueryString = buildQueryString(updatedSettings);

        const lanes = await getBidAnalysisLanes({
            analysis_id,
            reportLocations: getState().ReportKPIReducer.reportLocations,
            settingsQueryString,
            bidConfigLocations: _bidConfigLocations
        });

        let modifiedLanes = [];

        if (convertTo3Zip) {
            convertTo3DigitZipCode({ lanes });
        }
        if (config_id && !dontFetchEdits) {
            const actions = await getBidAnalysisConfigurationActions({
                analysis_id,
                config_id,
                settingsQueryString
            });
            modifiedLanes = applyBidAnalysisActions({ lanes, actions: actions.curr, applyAction });
            if (Array.isArray(actions)) {
                dispatch(
                    onGetConfigurationActionSuccess({
                        configurationActions: actions
                    })
                );
            } else {
                dispatch(
                    onGetConfigurationActionSuccess({
                        configurationActions: actions.curr,
                        prevConfigurationActions: actions.prev
                    })
                );
            }
        } else {
            modifiedLanes = lanes;
        }

        //! only store the bid delta when the bid file type is "final"
        if (isFinalizedBid) {
            const { data } = await getFinalizedBidDelta({
                analysis_id,
                settingsQueryString
            });
            await dispatch(
                updateBidAnalysis({
                    bidAnalysisDeltaValues: data
                })
            );
        }

        await dispatch(
            updateBidAnalysis({
                currBidAnalysisLanes: modifiedLanes,
                bidAnalysisSetting: updatedSettings
            })
        );
        dispatch(updateLoadingState({ showLoading: false }));
    };
};

const getBidAnalysisConfigurationActions = async ({
    analysis_id,
    config_id,
    settingsQueryString
}: {
    analysis_id: string | number;
    config_id: string | number | undefined;
    settingsQueryString: string;
}) => {
    const {
        data: { actions }
    } = await API.get(
        `/reports/report-runs/${analysis_id}/configurations/${config_id}/actions/${settingsQueryString}`
    );
    return actions;
};

export const editBidAnalysis = ({
    config_id,
    cellEdits,
    actions,
    navigateToUrl,
    changedBy
}: {
    cellEdits: CellEdit[];
    config_id?: string;
    actions?: any[];
    navigateToUrl?: (url: string) => void;
    changedBy: string | undefined;
}) => {
    return async (dispatch: any, getState: () => RootState) => {
        const {
            bidAnalysisSetting,
            currBidAnalysisData,
            currBidAnalysisLanes,
            actionsAwaitingConfigurationCreation
        } = getState().BidAnalysisReducer;
        const { creatingConfiguration } = getState().ConfigurationReducer;
        const linkedEditsAndActionsWereAlreadyCalculated = actions && actions.length > 0;
        if (!linkedEditsAndActionsWereAlreadyCalculated) {
            cellEdits = captureLinkedEdits(cellEdits, currBidAnalysisLanes);
        }

        dispatch(applyCellEditsToTable(cellEdits));
        if (!linkedEditsAndActionsWereAlreadyCalculated) {
            actions = deriveActionsFromEdits(
                cellEdits,
                currBidAnalysisLanes,
                bidAnalysisSetting,
                changedBy
            );
        }
        const isDraft =
            currBidAnalysisData?.config_id &&
            Number(currBidAnalysisData?.config_id) === Number(config_id);
        if (isDraft) {
            try {
                await dispatch(
                    updateConfiguration(
                        currBidAnalysisData.id,
                        currBidAnalysisData.config_id,
                        actions
                    )
                );
                dispatch(updateBidAnalysis({ actionsAwaitingConfigurationCreation: [] }));
                dispatch(
                    getConfigurationActions(
                        currBidAnalysisData.id,
                        currBidAnalysisData.config_id,
                        bidAnalysisSetting.time_aggregation
                    )
                );
            } catch (err) {
                console.warn('could not update actions in BE');
                // undo the edits on lanes in redux
            }
        } else {
            if (actions?.length)
                dispatch(
                    updateBidAnalysis({
                        actionsAwaitingConfigurationCreation: [
                            ...actionsAwaitingConfigurationCreation,
                            ...actions
                        ]
                    })
                );
            if (!creatingConfiguration) {
                dispatch(updateLoadingState({ showLoading: true }));
                dispatch(updateConfigurationState({ creatingConfiguration: true }));
                const simulation = await createBidAnalysisConfiguration({ currBidAnalysisData });
                if (simulation?.id) {
                    dispatch(
                        updateBidAnalysis({
                            currBidAnalysisData: {
                                ...currBidAnalysisData,
                                config_id: simulation?.id
                            }
                        })
                    );
                    navigateToUrl?.(
                        `/bid-analysis/${currBidAnalysisData.id}/config/${simulation.id}`
                    );
                    dispatch(updateLoadingState({ showLoading: false }));
                    await dispatch(setConfiguration(simulation));
                } else {
                    dispatch(
                        updateConfigurationState({
                            creatingConfiguration: false,
                            error: 'Failed to create draft'
                        })
                    );
                    dispatch(updateLoadingState({ showLoading: false }));
                }
                // dispatch(setLoading(false))
            }
        }
        // make sure undoInitialEdits() still works when configuration creation fails.
    };
};

export const createBidAnalysisConfiguration = async ({
    currBidAnalysisData
}: {
    currBidAnalysisData: any;
}) => {
    let newSimName = `${currBidAnalysisData.name} - ${convertDateTimeObjIntoUSFormat(new Date())}`;
    const simulation = await createSimulation(newSimName.trim(), parseInt(currBidAnalysisData.id));
    return simulation;
};

const ratePerMileError = (lane: DataRow, cellEdit: CellEdit) => {
    const { columnId, newValue } = cellEdit;
    return (
        (columnId === 'recommendation' && newValue === DECISION.ACCEPT && !lane.rate_per_mile) ||
        (columnId === 'rate_per_mile' && newValue === 0 && lane.recommendation === DECISION.ACCEPT)
    );
};

const updateTableErrors = (tableEdits: { lane: DataRow; cellEdit: CellEdit }[]) => {
    return async (dispatch: any) => {
        const newTableErrors: { rowId: number; columnId: string }[] = [];
        const tableErrorsToClear: { rowId: number; columnId: string }[] = [];
        const addRateError = (rowId: number) =>
            newTableErrors.push(
                { rowId, columnId: 'rate_per_mile' },
                { rowId, columnId: 'flat_rate' }
            );
        const removeRateError = (rowId: number) =>
            tableErrorsToClear.push(
                { rowId, columnId: 'rate_per_mile' },
                { rowId, columnId: 'flat_rate' }
            );

        const displayRateErrors = () => {
            dispatch(addTableErrors(newTableErrors));
            dispatch(updateBidAnalysis({ showTableDataErrorAlert: true }));
        };
        const clearRateErrors = () => dispatch(clearTableErrors(tableErrorsToClear));
        tableEdits.forEach((tableEdit) => {
            const { lane, cellEdit } = tableEdit;
            const { columnId, newValue } = cellEdit;
            switch (columnId) {
                case 'recommendation':
                    if (ratePerMileError(lane, cellEdit)) {
                        addRateError(lane.id as number);
                    }
                    if (newValue === DECISION.REJECT) removeRateError(lane.id as number);
                    break;
                case 'rate_per_mile':
                    if (newValue > 0) removeRateError(lane.id as number);
                    else if (ratePerMileError(lane, cellEdit)) {
                        addRateError(lane.id as number);
                    }
            }
        });
        if (newTableErrors.length > 0) displayRateErrors();
        if (tableErrorsToClear.length > 0) clearRateErrors();
    };
};

const applyCellEditsToTable = (cellEdits: CellEdit[]) => {
    return async (dispatch: any, getState: () => RootState) => {
        // TODO come up with a more efficient approach than searching for each lane.
        const tableErrorUpdates: { lane: DataRow; cellEdit: CellEdit }[] = [];
        cellEdits.forEach((cellEdit) => {
            const lane = getState().BidAnalysisReducer.currBidAnalysisLanes.find(
                (lane: DataRow) => lane.id === cellEdit.rowId
            );
            tableErrorUpdates.push({ lane, cellEdit });
        });
        dispatch(updateTableErrors(tableErrorUpdates));
        const modifiedDataRows = getModifiedPartialCopy(
            cellEdits,
            getState().BidAnalysisReducer.currBidAnalysisLanes
        );
        return dispatch(updateBidAnalysis({ currBidAnalysisLanes: modifiedDataRows }));
    };
};

const getReportDataByReportId = async (
    reportId: string | number,
    config_id: string | number | undefined
) => {
    try {
        const { data } = await API.get(`/reports/report-runs/${reportId}/`);
        const trimmedData = {
            file_sub_type: data?.file_sub_type,
            file_type: data?.file_type,
            start_date: data?.start_date,
            end_date: data?.end_date,
            name: data?.name,
            parent_name: data?.parent_name,
            id: data?.id,
            parent_id: data?.parent_id,
            config_id,
            bucket_id: data?.bucket,
            latest_base: data?.latest_base,
            configuration: data?.configuration
        };

        return trimmedData;
    } catch (err) {
        console.warn('Unable to get the analysis info');
    }
};

export const getBidAnalysisReportAppliedBids = async (
    configurationId: string | number | undefined
) => {
    try {
        const { data } = await API.get(
            `/bidding/report-configurations/${configurationId}/bid-configurations/`
        );
        return data;
    } catch (error) {
        console.warn(
            'error fetching applied bid files for bid analysis report with id ' + configurationId
        );
    }
};

export const getFinalizedBidDelta = async ({
    analysis_id,
    settingsQueryString
}: {
    analysis_id: any;
    settingsQueryString?: string;
}) => {
    let url = `bidding/bid-analysis/${analysis_id}/compare/${
        settingsQueryString ? `${settingsQueryString}` : ``
    }`;
    const { data } = await API.get(url);
    return data;
};

export const getBidAnalysisLanes = async ({
    analysis_id,
    bidConfigLocations,
    reportLocations,
    settingsQueryString
}: {
    analysis_id: string | number;
    bidConfigLocations: any;
    reportLocations: any;
    settingsQueryString?: string;
}) => {
    try {
        let url = `bidding/bid-analysis/${analysis_id}/${
            settingsQueryString ? `${settingsQueryString}` : ``
        }`;
        // update the redux state
        const { data } = await API.get(url);
        const bidAnalysisDataWithMutableColumns = data;
        const bidOriginLocations = Object.fromEntries(
            bidConfigLocations.map((r: any) => [
                r.origin_zip,
                { city: r.origin_city, state: r.origin_state }
            ])
        );
        const bidDestinationLocations = Object.fromEntries(
            bidConfigLocations.map((r: any) => [
                r.destination_zip,
                { city: r.destination_city, state: r.destination_state }
            ])
        );

        bidAnalysisDataWithMutableColumns.forEach((lane: DataRow) => {
            lane.id = lane.bid_id;
            lane.original_rate_per_mile = lane.rate_per_mile;
            lane.flat_rate = Number(lane.rate_per_mile) * Number(lane.mileage);
            lane.original_flat_rate = lane.flat_rate;
            lane.lane_score = Number(Number(lane.lane_score).toFixed(0));
            lane.original_volume_to_accept = lane.volume_to_accept;
            lane.originCityStateZip = getLocation(
                lane.origin as string,
                reportLocations,
                'city-state-zip',
                bidOriginLocations
            );
            lane.destCityStateZip = getLocation(
                lane.destination as string,
                reportLocations,
                'city-state-zip',
                bidDestinationLocations
            );
            lane.originCityState = getLocation(
                lane.origin as string,
                reportLocations,
                'city-state',
                bidOriginLocations
            );
            lane.destCityState = getLocation(
                lane.destination as string,
                reportLocations,
                'city-state',
                bidDestinationLocations
            );
            roundFieldsToInt(lane, [
                'covered',
                'uncovered',
                'total_volume_available',
                'partial_loads'
            ]);
            lane.OMKT_original_balance = Number(lane.new_OMKT_balance_change);
            lane.DMKT_original_balance = Number(lane.new_DMKT_balance_change);
        });
        return bidAnalysisDataWithMutableColumns;
    } catch (err) {
        console.warn('Unable to fetch bid analysis lanes data');
    }
};

//TODO redundant, extract it from network view and utilize this function instead
export const getDownloadFileByBidId = (
    bid_id: string | number,
    bid_name: any,
    config_id?: string | number
) => {
    const params = config_id ? `&bid_updates=${config_id}` : ``;
    return async () => {
        try {
            const data = await API({
                method: 'get',
                url: `reports/report-runs/${bid_id}/download-raw-data/?data_type=bid${params}`,
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${bid_name}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            toast.error(`Unable to download the raw data file(s).`, {
                position: 'bottom-right'
            });
        }
    };
};

export const refreshBidAnalysis = (
    configId: string | number,
    reportId: string | number,
    name?: string
) => {
    return async (_dispatch: (arg0: { type: string; payload: any }) => void) => {
        try {
            await API.post(`/reports/run-configuration/`, {
                config_id: configId,
                report_id: reportId,
                report_name: name
            });
        } catch (err) {
            if (err) {
                _dispatch(updateBidAnalysis({ refreshErr: true }));
                toast.error(`Unable to finalize the bid analysis report.`, {
                    position: 'bottom-right'
                });
            }
        }
    };
};

export const getLaneScoreMetricsByLaneId = (
    report_id: string | number,
    lane_id: string | number
) => {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        try {
            const { data } = await API.get(
                `/bidding/bid-analysis/${report_id}/?bid_lane_id=${lane_id}`
            );

            dispatch(
                updateBidAnalysis({
                    selectedLaneScoreMetaData: data
                })
            );
        } catch (err) {
            if (err) {
                toast.error(`Unable to fetch the bid lane score metrics.`, {
                    position: 'bottom-right'
                });
            }
        }
    };
};

export const getDownloadNetworkImpact = (bid_id: string | number, name: string) => {
    return async () => {
        try {
            const data = await API({
                method: 'get',
                url: `/bidding/bid-analysis/${bid_id}/network-impact/`,
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${name}_Network Impact.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            toast.error(`Unable to download the raw data file(s).`, {
                position: 'bottom-right'
            });
        }
    };
};
