import { useEffect } from 'react';
import { PaginationContainer } from 'shared/ODTable/PaginationStyles';
import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import ODTablePaginationFromCommon from './ODTablePaginationFromCommon';
const ODTablePagination = ({
    setPageIndexInHook,
    setPageSizeInHook,
    manualPagination,
    totalItems
}: {
    setPageIndexInHook?: any;
    setPageSizeInHook?: any;
    manualPagination?: boolean | undefined;
    totalItems?: any;
}) => {
    const { table } = useODTable() as ODTableContextInterface & ODTableProviderProps;
    const { getState, setPageIndex, setPageSize } = table;

    const { pageIndex, pageSize } = getState().pagination;
    const rows = table.getFilteredRowModel().rows;
    const itemsCount = rows?.length;

    useEffect(() => {
        setPageSize(50);
    }, [setPageSize]);

    useEffect(() => {
        if (setPageIndexInHook) {
            setPageIndexInHook(pageIndex);
        }
    }, [pageIndex, setPageIndexInHook]);
    useEffect(() => {
        if (setPageSizeInHook) {
            setPageSizeInHook(pageSize);
        }
    }, [pageSize, setPageSizeInHook]);

    return (
        <PaginationContainer>
            <ODTablePaginationFromCommon
                selectId="table-pagination-select"
                count={manualPagination ? totalItems : itemsCount}
                page={pageIndex}
                rowsPerPage={pageSize}
                onPageChange={(e, page) => setPageIndex(page)}
                onRowsPerPageChange={(e) => setPageSize(Number(e.target.value))}
                sx={{}}
            />
        </PaginationContainer>
    );
};

export default ODTablePagination;
