import { NETWORK_TABLE_NAME } from 'constants/network';
import { BIDDING_TABLE_NAME } from 'constants/bidding';
export const getDefaultHiddenColumns = (
    tableName: NETWORK_TABLE_NAME | BIDDING_TABLE_NAME,
    specialCase?: string
) => {
    switch (tableName) {
        case NETWORK_TABLE_NAME.SHIPPERS:
        case NETWORK_TABLE_NAME.LANES:
        case NETWORK_TABLE_NAME.DOMICILES:
        case NETWORK_TABLE_NAME.DRIVER_TYPES:
        case NETWORK_TABLE_NAME.DRIVER_IDS:
        case NETWORK_TABLE_NAME.MOTIONS:
        case BIDDING_TABLE_NAME.BID_MANAGER:
        case BIDDING_TABLE_NAME.FLOWS:
            return [];
        case NETWORK_TABLE_NAME.COMPARISON_LANES:
            if (specialCase === 'specialColumnPreferences1') {
                return ['external', 'true_profit_amount', 'true_profit_per_mile', 'onTime'];
            } else {
                return [];
            }
        case NETWORK_TABLE_NAME.COMPARISON_SHIPPERS:
            if (specialCase === 'specialColumnPreferences1') {
                return ['external', 'true_profit_amount', 'true_profit_per_mile', 'onTime'];
            } else {
                return [];
            }
        case NETWORK_TABLE_NAME.COMPARISON_DOMICILES:
        case NETWORK_TABLE_NAME.COMPARISON_DRIVER_TYPES:
            if (specialCase === 'specialColumnPreferences1') {
                return ['true_profit'];
            } else {
                return [];
            }
        case BIDDING_TABLE_NAME.BID_CONFIGURATION:
            return [
                'shipper_id',
                'operational_hours_at_pickup',
                'operational_hours_at_destionation',
                'other_revenue',
                'other_revenue_per_mile',
                'customer_bid_id',
                'origin_load_method',
                'destination_unload_method'
            ];
        case BIDDING_TABLE_NAME.BID_ANALYSIS:
            return [
                'service_coverage',
                'shipper_id',
                'revenue',
                'profit',
                'loaded_miles',
                'on_time',
                'average_loh',
                'origin_market',
                'destination_market',
                'empty_mileage_percentage',
                'profit_per_load',
                'partial_loads',
                'sales_ranking',
                'orig_load_method',
                'dest_unload_method',
                'equipment_type'
            ];
    }
};
