import { Box } from '@mui/material';
import palette from 'theme/palette';
import ClearIcon from '@mui/icons-material/Clear';
import { ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { styled } from '@mui/material/styles';

const Label = styled(Box)(() => ({
    fontSize: '12px',
    color: palette.neutral.neutral6,
    marginBottom: '4px'
}));
interface SelectedListProps {
    items: any[];
    removeSelectedItem: (item: any) => void;
    showValueLabel: (option: any) => any;
    label?: string;
    itemIcon?: JSX.Element;
}
const SelectedList = ({
    items,
    removeSelectedItem,
    showValueLabel,
    label,
    itemIcon
}: SelectedListProps) => {
    return (
        <>
            <Box
                sx={{
                    marginTop: '16px',
                    maxHeight: '14rem',
                    overflowY: 'auto'
                }}>
                {label && <Label>{label}</Label>}
                <Box
                    sx={{
                        border: '1px solid #F2F2F2',
                        borderRadius: '4px',
                        padding: '8px',
                        maxHeight: '180px',
                        overflowY: 'auto'
                    }}>
                    {items.map((item: any, i: number) => {
                        if (item !== null) {
                            return (
                                <Box
                                    key={i}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        padding: '10px',
                                        margin: i === 0 ? '0' : '2px 0',
                                        borderRadius: '4px',
                                        backgroundColor: palette.ODLightBlueNeutral.lightBlue1
                                    }}>
                                    <Box
                                        display="flex"
                                        justifyContent={'center'}
                                        alignItems={'center'}>
                                        {itemIcon && (
                                            <Box sx={{ marginRight: '16px', display: 'flex' }}>
                                                {itemIcon}
                                            </Box>
                                        )}
                                        {showValueLabel(item)}
                                    </Box>

                                    <ODIconButton
                                        sx={{ marginRight: 0, padding: '0 1px 0 0' }}
                                        disableHover={true}
                                        id={'delete'}
                                        onClick={() => removeSelectedItem(item)}
                                        buttonType={BUTTON_TYPE.TRANSPARENT}
                                        icon={<ClearIcon sx={{ fill: palette.neutral.black }} />}
                                    />
                                </Box>
                            );
                        } else {
                            return null;
                        }
                    })}
                </Box>
            </Box>
        </>
    );
};

export default SelectedList;
