/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'theme';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import LibraryAddCheckSharpIcon from '@mui/icons-material/LibraryAddCheckSharp';
import CloseIcon from '@mui/icons-material/Close';
import { ODFooterActionBar, ODButton, CommonSvgIcon } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { getLocation } from 'utils/common.util';
import { updateBidAnalysis } from 'redux/actions';
import { RootState } from 'types/redux';
import { useBidAnalysisHook } from 'utils/hooks';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import { DECISION } from 'constants/bidding';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import { DoubleXCheckboxIcon } from 'assets/images';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as FS from '@fullstory/browser';
import {
    useODTable,
    ODTableContextInterface,
    ODTableProviderProps
} from 'shared/ODTable/ODTableContext';
interface BidAnalysisActionBarProps {
    checkedRows: any[];
    setCheckedRows: any;
}

const StyledButtonsStack = styled(Stack)(() => ({
    '& > div:not(:last-child)': { marginRight: '16px' }
}));

const BidAnalysisActionBar = ({ checkedRows, setCheckedRows }: BidAnalysisActionBarProps) => {
    const dispatch = useDispatch();
    const { bidGoalApplied, setLaneDecision } = useBidAnalysisHook();
    const { table } = useODTable() as ODTableContextInterface & ODTableProviderProps;
    const [showSelectAll, setShowSelectAll] = useState(false);
    const [lanesAccepted, setLanesAccepted] = useState(false);
    const [lanesRejected, setLanesRejected] = useState(false);

    const filteredRows = table.getFilteredRowModel().rows;
    const { lanesToMeetTarget, currBidAnalysisLanes, userUpdatedSelectedLanes } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const { locationVisualization } = useSelector((state: RootState) => state.settingsReducer);
    const { reportLocations } = useSelector((state: RootState) => state.ReportKPIReducer);
    const { bidConfigLocations } = useSelector((state: RootState) => state.BiddingReducer);
    const rowsDataSelected = currBidAnalysisLanes.filter((el: DataRow) =>
        checkedRows.includes(el.id)
    );

    useEffect(() => {
        setShowSelectAll(rowsDataSelected?.length === filteredRows?.length ?? false);
        if (rowsDataSelected.length > 1) {
            setLanesAccepted(
                rowsDataSelected.some((row: DataRow) => row?.recommendation === DECISION.ACCEPT)
            );
            setLanesRejected(
                rowsDataSelected.some((row: DataRow) => row?.recommendation === DECISION.REJECT)
            );
        }
    }, [currBidAnalysisLanes, rowsDataSelected]);

    let displayedActionBarNameLeft = '';
    let displayedActionBarNameRight = '';
    if (rowsDataSelected?.length === 1) {
        displayedActionBarNameLeft = `${getLocation(
            rowsDataSelected[0]?.origin,
            reportLocations,
            locationVisualization,
            bidConfigLocations
        )}`;
        displayedActionBarNameRight = `${getLocation(
            rowsDataSelected[0]?.destination,
            reportLocations,
            locationVisualization,
            bidConfigLocations
        )}`;
    } else {
        displayedActionBarNameLeft = `(${rowsDataSelected?.length}) Lanes Selected`;
    }
    //TODO Add when pagination is added
    const selectAllRowsText = `Select All (${filteredRows?.length})`;
    const handleSelectAll = () => {
        setCheckedRows(filteredRows?.map((r: any) => r?.original?.bid_id));
    };
    const handleAcceptRejectLanes = (decision: DECISION) => {
        if (rowsDataSelected.length === 1) {
            setLaneDecision({ lane: rowsDataSelected[0], decision });
            setCheckedRows([]);
        } else {
            dispatch(
                updateBidAnalysis({
                    confirmRecommendationModalDetails: {
                        show: true,
                        lanes: rowsDataSelected,
                        decision
                    }
                })
            );
        }
    };

    const rejectedRows = rowsDataSelected.filter((row: any) => row?.recommendation === 'reject');
    const acceptedRows = rowsDataSelected.filter((row: any) => row?.recommendation === 'accept');

    const handleAcceptAndMaxOutLanes = () => {
        dispatch(
            updateBidAnalysis({
                confirmRecommendationModalDetails: {
                    show: true,
                    lanes: rowsDataSelected,
                    decision: DECISION.ACCEPT
                }
            })
        );
    };

    const selectAllLanesToMeetTarget = () => {
        dispatch(updateBidAnalysis({ checkedRows: Array.from(lanesToMeetTarget) }));
    };
    const allLanesToMeetTargetAreSelected = lanesToMeetTarget.size === checkedRows.length;

    const AcceptButton = ({ buttonText }: { buttonText: string }) => (
        <ODButton
            buttonText={buttonText}
            buttonType={BUTTON_TYPE.BLUE}
            startIcon={<CheckSharpIcon />}
            onClick={(e) => {
                FS.event('Bid Analysis_Bulk Actions_Click Accept Button', {
                    buttonText: buttonText
                });
                handleAcceptAndMaxOutLanes();
            }}
        />
    );

    const SelectAllLanesToMeetTargetButton = () => (
        <ODButton
            buttonText={'Select Entered Target'}
            buttonType={BUTTON_TYPE.BLUE}
            startIcon={<LibraryAddCheckSharpIcon />}
            onClick={(e) => {
                selectAllLanesToMeetTarget();
            }}
        />
    );

    return checkedRows && checkedRows?.length > 0 ? (
        <ODFooterActionBar
            sx={{
                marginTop: '16px'
            }}
            buttonsOverride={
                <StyledButtonsStack direction="row">
                    {bidGoalApplied ? (
                        userUpdatedSelectedLanes ? (
                            <AcceptButton buttonText="Accept Selected Lanes" />
                        ) : allLanesToMeetTargetAreSelected ? (
                            <AcceptButton buttonText="Accept Entered Target" />
                        ) : (
                            <>
                                <SelectAllLanesToMeetTargetButton />
                                <AcceptButton buttonText="Accept Qualifying Target" />
                            </>
                        )
                    ) : (
                        <>
                            {showSelectAll && (
                                <ODButton
                                    buttonText={selectAllRowsText}
                                    sx={{
                                        width: 'auto',
                                        justifyContent: 'center'
                                    }}
                                    buttonType={BUTTON_TYPE.BLUE}
                                    onClick={(e) => {
                                        FS.event(
                                            'Bid Analysis Table_Select Bulk Select All Lanes',
                                            {}
                                        );
                                        handleSelectAll();
                                    }}
                                />
                            )}
                            {rowsDataSelected.length === 1 &&
                                rowsDataSelected[0].recommendation === DECISION.ACCEPT && (
                                    <ODButton
                                        disabled={false}
                                        buttonText={'Reject Lane'}
                                        startIcon={<CloseIcon />}
                                        buttonType={BUTTON_TYPE.BLUE}
                                        onClick={() => {
                                            FS.event(
                                                'Bid Analysis Actions Bar Click Reject Lane Button',
                                                {}
                                            );
                                            handleAcceptRejectLanes(DECISION.REJECT);
                                        }}
                                    />
                                )}
                            {rowsDataSelected.length === 1 &&
                                rowsDataSelected[0].recommendation === DECISION.REJECT && (
                                    <ODButton
                                        disabled={false}
                                        buttonText={'Accept Lane'}
                                        startIcon={<CheckSharpIcon />}
                                        buttonType={BUTTON_TYPE.BLUE}
                                        onClick={() => {
                                            FS.event(
                                                'Bid Analysis Actions Bar Click Accept Lane Button',
                                                {}
                                            );
                                            handleAcceptRejectLanes(DECISION.ACCEPT);
                                        }}
                                    />
                                )}
                            {lanesRejected && rowsDataSelected.length > 1 && (
                                <ODButton
                                    disabled={false}
                                    buttonText={`Accept All (${rejectedRows.length}) Lanes`}
                                    startIcon={<LibraryAddCheckSharpIcon />}
                                    buttonType={BUTTON_TYPE.BLUE}
                                    onClick={() => {
                                        FS.event('Bid Analysis Accept All Lanes', {});
                                        handleAcceptRejectLanes(DECISION.ACCEPT);
                                    }}
                                />
                            )}
                            {lanesAccepted && rowsDataSelected.length > 1 && (
                                <ODButton
                                    disabled={false}
                                    buttonText={`Reject All (${acceptedRows.length}) Lanes`}
                                    startIcon={<DoubleXCheckboxIcon />}
                                    buttonType={BUTTON_TYPE.BLUE}
                                    onClick={() => {
                                        FS.event('Bid Analysis Reject All Lanes', {});
                                        handleAcceptRejectLanes(DECISION.REJECT);
                                    }}
                                />
                            )}
                        </>
                    )}
                </StyledButtonsStack>
            }
            ribbonIcon={
                rowsDataSelected?.length === 1 ? (
                    <CommonSvgIcon fill={theme.palette.neutral.white} />
                ) : (
                    <LibraryAddCheckSharpIcon />
                )
            }
            ribbonText={displayedActionBarNameLeft}
            ribbonText2={displayedActionBarNameRight}
            ribbonIcon2={rowsDataSelected?.length > 0 ? <ArrowForwardSharpIcon /> : undefined}
        />
    ) : null;
};

export default BidAnalysisActionBar;
