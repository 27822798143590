import React from 'react';
import { CommonHeaderContainer } from 'shared';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ClearIcon from '@mui/icons-material/Clear';
import theme from 'theme';
import { FormControl, InputLabel, InputBase } from '@mui/material/';
import { makeStyles } from '@mui/styles/';

export const TextFieldContainer = (props) => {
    const {
        title = null,
        type,
        style,
        value,
        placeholder = '',
        variant = 'outlined',
        handleChange,
        autoComplete = 'off',
        endAdornment = null,
        disabled
    } = props;

    const useStyles = makeStyles((theme) => ({
        inputBase: {
            backgroundColor: theme.palette.neutral.search,
            color: theme.palette.neutral.neutral7,
            padding: '14px 24px',
            borderRadius: 5,
            height: '100%',
            '&.Mui-focused': {
                backgroundColor: theme.palette.neutral.search
            }
        }
    }));

    const classes = useStyles();

    return (
        <FormControl variant={variant} style={{ width: '100%', ...style }}>
            {title && (
                <InputLabel shrink htmlFor={`${type}-native-label-placeholder`}>
                    {title}
                </InputLabel>
            )}
            <InputBase
                id={`${type}-adornment-weight`}
                value={value}
                autoComplete={autoComplete}
                disabled={disabled}
                endAdornment={endAdornment}
                placeholder={placeholder}
                onChange={handleChange}
                className={classes.inputBase}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight',
                    style: {
                        padding: 0,
                        color: theme.palette.black,
                        fontSize: 14
                    }
                }}
            />
        </FormControl>
    );
};

export const ReportSearchContainer = ({ searchText = '', updateSearch }) => {
    return (
        <CommonHeaderContainer dark={false} justify={'space-between'} isNetworkManager={true}>
            <TextFieldContainer
                type={'bucket_search'}
                id={'search_bucket_input'}
                placeholder={'Search Reports'}
                className={'select-report-runs'}
                multiple={true}
                value={searchText || ''}
                variant="filled"
                style={{
                    color: theme.palette.black
                }}
                handleChange={(event) => updateSearch(event.target.value)}
                endAdornment={
                    searchText ? (
                        <ClearIcon
                            sx={{
                                color: theme.palette.black,
                                cursor: 'pointer'
                            }}
                            onClick={() => updateSearch(null)}
                        />
                    ) : (
                        <SearchSharpIcon
                            sx={{
                                color: theme.palette.black
                            }}
                        />
                    )
                }
            />
        </CommonHeaderContainer>
    );
};
