import { useDispatch } from 'react-redux';
import { getHiddenColumns } from 'redux/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useSettingsInitializationHook = () => {
    const dispatch = useDispatch();
    const { user } = useAuth0();
    const { specialColumnPreferences1 } = useFlags();

    const initializeUserData = () => {
        dispatch(
            getHiddenColumns(
                user?.email,
                specialColumnPreferences1 ? 'specialColumnPreferences1' : null
            )
        );
    };

    return { initializeUserData };
};
