import { useState, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box } from '@mui/material';
import { max, min } from 'lodash';
import DateTimePickerWrapper from './DateTimePickerWrapper';
import moment from 'moment';
import theme from 'theme';
import { makeStyles } from '@mui/styles';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import ClearFilterButton from './ClearFilterButtton';
import { EndAdornmentsContainer } from './FilterStyles';
import clsx from 'clsx';

const DateTimeFilter = (tableData) => {
    const { column, filteredRows, initialRows } = tableData;
    const { setFilter, filterValue } = column;
    const [minVal, setMin] = useState(null);
    const [maxVal, setMax] = useState(null);
    const [unfilteredMin, setUnfilteredMin] = useState();
    const [unfilteredMax, setUnfilteredMax] = useState();

    /* Move these to FilterStyles after we retire the networkViewLightTheme flag.
     * because it may be messy to include conditionals in the FilterStyles file
     */
    const useStyles = makeStyles(() => ({
        active: {
            border: '1px solid transparent',
            backgroundColor: theme.palette.neutral.neutral2
        },
        inactive: {
            border: `1px solid ${theme.palette.neutral.neutral2}`,
            backgroundColor: 'none',
            color: theme.palette.ODBlue.odBlue7
        },
        populated: {
            color: theme.palette.black
        }
    }));

    const classes = useStyles();
    const initialColumnValues = initialRows.map((r) => r.original[column.id]);
    const stringifiedInitialValues = JSON.stringify(initialColumnValues);
    const filteredColumnVals = filteredRows.map((r) => r.original[column.id]);
    const stringifiedFilteredVals = JSON.stringify(filteredColumnVals);

    useEffect(() => {
        setUnfilteredMin(new Date(min(initialColumnValues)));
        setUnfilteredMax(new Date(max(initialColumnValues)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedInitialValues]);

    useEffect(() => {
        setFilter([null, null]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setMin(min(filteredColumnVals));
        setMax(max(filteredColumnVals));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column.id, stringifiedFilteredVals]);

    const renderFilterButton = ({ isActive, clickHandler, limitType }) => {
        const value = filterValue && filterValue[limitType === 'min' ? 0 : 1];
        return (
            <ODButton
                sx={{
                    height: '22px',
                    padding: 0,
                    paddingLeft: 0,
                    borderRadius: '2px',
                    fontSize: '10px',
                    textTransform: 'none',
                    boxSizing: 'content-box'
                }}
                width={'120px'}
                className={clsx(
                    isActive ? classes.active : classes.inactive,
                    value && classes.populated
                )}
                buttonType={BUTTON_TYPE.LIGHT}
                type="submit"
                buttonText={
                    value
                        ? moment(value).format('MM/DD/YY hh:mm a')
                        : (limitType === 'min' ? minVal : maxVal)
                        ? moment(limitType === 'min' ? minVal : maxVal).format('MM/DD/YY hh:mm a')
                        : ''
                }
                onClick={clickHandler}
                endIcon={
                    <EndAdornmentsContainer>
                        {value && (
                            <ClearFilterButton
                                clickHandler={() => {
                                    setFilter(
                                        limitType === 'min'
                                            ? [null, filterValue[1]]
                                            : [filterValue[0], null]
                                    );
                                }}
                                color={theme.palette.black}
                            />
                        )}
                        {}
                    </EndAdornmentsContainer>
                }
            />
        );
    };
    return (
        <Box
            id={`${column.id}-datetime-filter`}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ marginRight: '2px' }}>
                    <DateTimePickerWrapper
                        selectedValue={filterValue && filterValue[0]}
                        setSelectedValue={(val) => setFilter([val, filterValue[1]])}
                        limit={minVal}
                        unfilteredMax={unfilteredMax}
                        unfilteredMin={unfilteredMin}
                        inputRenderingFn={({ isActive, clickHandler }) =>
                            renderFilterButton({ isActive, clickHandler, limitType: 'min' })
                        }
                    />
                    <Box sx={{ fontSize: '8px', marginTop: '2px', fontWeight: '400' }}>Min</Box>
                </Box>
                <Box>
                    <DateTimePickerWrapper
                        selectedValue={filterValue && filterValue[1]}
                        setSelectedValue={(val) => setFilter([filterValue[0], val])}
                        limit={maxVal}
                        unfilteredMax={unfilteredMax}
                        unfilteredMin={unfilteredMin}
                        inputRenderingFn={({ isActive, clickHandler }) =>
                            renderFilterButton({ isActive, clickHandler, limitType: 'max' })
                        }
                    />
                    <Box sx={{ fontSize: '8px', marginTop: '2px', fontWeight: '400' }}>Max</Box>
                </Box>
            </LocalizationProvider>
        </Box>
    );
};

export default DateTimeFilter;
