export enum HANDLE_GROUP_SLIDEOUT {
    ADD_NEW_BUCKET = 'addNewBucket',
    EDIT_BUCKET = 'editBucket'
}

export enum BONUS_TABLE_FILTER_TYPE {
    BONUS_APPLIED = 'bonusApplied',
    BONUS_NOT_APPLIED = 'bonusNotApplied',
    ALL = 'all'
}
export enum NOTIFICATION_STATUS {
    READ = 'read',
    UNREAD = 'unread'
}
export enum REPORT_FILE_TYPE {
    NETWORK = 'network',
    BASE = 'base',
    BID = 'bid'
}

export enum REPORT_SUB_TYPE {
    DRAFT = 'draft',
    FINALIZED = 'final'
}
