/* eslint-disable eqeqeq */
import { groupBy } from 'lodash';
import { useParams } from 'react-router-dom';

import {} from 'redux/actions';
import { useParamsProps } from 'types/hooks';
import { useNetworkManagerHook } from 'utils/hooks';

import { useState, useEffect } from 'react';
import { getAllBuckets, updateNetworkView } from 'redux/actions/networkActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

const useReportsHook = () => {
    const { base_run, compare_run, config_id = '' }: useParamsProps = useParams();
    const [buckets, setBuckets] = useState([]);
    const [expanded, onExpand] = useState('all');
    const [searchText, updateSearchText] = useState(null);
    const [selectedReportBucketId, setSelectedReportBucketId] = useState(null);
    const { getAllReports, getDrafts, getArchived } = useNetworkManagerHook();
    const { baseReportLocal, compareReportLocal, reports } = useSelector(
        (state: RootState) => state.NetworkReducer
    );
    const dispatch = useDispatch();

    const disableUnselectedReports = (reports: any) => {
        return reports.map((r: any) => {
            r.disabled = !r.isChecked;
            return r;
        });
    };

    const enableUnselectedReports = (reports: any) => {
        return reports.map((r: any) => {
            r.disabled = false;
            return r;
        });
    };

    const getReports = async (buckets: any) => {
        const [drafts, archived, all] = await Promise.all([getDrafts(), getArchived(), getAllReports()]);

        const bucketInfo = buckets.reduce((store: any, bucket: any) => {
            store[bucket?.id] = bucket.name;
            return store;
        }, {});

        /* eslint-disable eqeqeq */
        const reports = [...all, ...drafts, ...archived].map(
            ({
                id,
                name,
                bucket,
                file_type,
                file_sub_type,
                configId = null,
                report_status = 'draft'
            }) => {
                let isChecked = [base_run, compare_run].includes(id.toString());
                if (isChecked && (config_id || configId)) {
                    isChecked = configId == config_id;
                }

                return {
                    file_type,
                    file_sub_type,
                    id,
                    name,
                    bucket,
                    config_id: configId,
                    report_status,
                    isBaseRun: isChecked && base_run === id.toString(),
                    isChecked,
                    bucket_name: bucketInfo[bucket]
                };
            }
        ) as any;
        dispatch(updateNetworkView({ reports: reports }));
        if (base_run) {
            const _baseReport = reports.find((r: any) => r.id == base_run);
            dispatch(updateNetworkView({ baseReportLocal: _baseReport }));
            setSelectedReportBucketId(_baseReport?.bucket);
            onExpand(_baseReport?.bucket);
        }
        if (compare_run) {
            const compare = reports.find((r: any) => r.id == compare_run);
            dispatch(updateNetworkView({ compareReportLocal: compare }));
        }

        /* eslint-enable  eqeqeq */
    };

    const fetchData = async () => {
        const buckets = (await getAllBuckets()) as any;
        setBuckets(buckets);
        await getReports(buckets);
    };

    let filteredReports = [];

    if (searchText) {
        const search = ((searchText as any) || '').toLowerCase();
        filteredReports = reports
            ?.filter(
                ({ name, bucketName }: any) =>
                    (name || '').toLowerCase().includes(search) ||
                    (bucketName || '').toLowerCase().includes(search)
            )
            ?.filter(({ report_status }: any) =>
                ['completed', 'vfa_completed'].includes(report_status)
            )
            ?.filter(
                ({ bucket }: any) => bucket === selectedReportBucketId || !selectedReportBucketId
            );
    } else {
        filteredReports = reports?.filter(
            ({ bucket, report_status }: any) =>
                ['all', bucket].includes(expanded) &&
                ['completed', 'vfa_completed'].includes(report_status)
        );
    }

    const onViewBucket = (bucket = 'all') => {
        updateSearchText(null);
        onExpand(bucket);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base_run, compare_run]);

    useEffect(() => {
        const filteredReports = reports?.filter(({ report_status }: any) =>
            ['completed', 'vfa_completed'].includes(report_status)
        );
        const groupByBucket = groupBy(filteredReports, 'bucket');
        const _buckets = buckets.map(({ id, name }) => ({
            id,
            name,
            count: id === 'all' ? filteredReports.length : (groupByBucket[id] || []).length
        })) as any;
        setBuckets(_buckets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports]);

    useEffect(() => {
        const section = document.querySelector(`#bucket-accordion-${expanded}`);
        if (section) section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [expanded]);

    useEffect(() => {
        if (reports.length > 0) {
            if (Boolean(baseReportLocal) && Boolean(compareReportLocal)) {
                if (baseReportLocal === compareReportLocal) {
                    dispatch(updateNetworkView({ compareReportLocal: null }));
                    dispatch(updateNetworkView({ reports: enableUnselectedReports(reports) }));
                } else {
                    dispatch(updateNetworkView({ reports: disableUnselectedReports(reports) }));
                }
            } else {
                dispatch(updateNetworkView({ reports: enableUnselectedReports(reports) }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseReportLocal, compareReportLocal]);

    const handleSwap = () => {
        if (baseReportLocal && compareReportLocal) {
            const temp = { ...baseReportLocal };
            dispatch(
                updateNetworkView({ baseReportLocal: compareReportLocal, compareReportLocal: temp })
            );
        }
    };

    const deselectBaseReport = () => {
        if (!compareReportLocal) {
            setSelectedReportBucketId(null);
            onExpand('all');
            dispatch(updateNetworkView({ baseReportLocal: null, compareReportLocal: null }));
        } else {
            //TODO this isn't updating it properly
            dispatch(
                updateNetworkView({ baseReportLocal: compareReportLocal, compareReportLocal: null })
            );
        }
    };

    const deselectCompareReport = () => {
        if (!baseReportLocal) {
            setSelectedReportBucketId(null);
            onExpand('all');
        }
        dispatch(updateNetworkView({ compareReportLocal: null }));
    };

    const handleRemoveCompareReport = () => {
        deselectCompareReport();
        const _reports = reports.map((r: any) => {
            if (r.id === compareReportLocal.id) r.isChecked = false;
            return r;
        }) as any;
        dispatch(updateNetworkView({ reports: _reports }));
    };

    const handleRemoveBaseReport = () => {
        deselectBaseReport();
        const _reports = reports.map((r: any) => {
            if (r.id === baseReportLocal.id) r.isChecked = false;
            return r;
        }) as any;
        dispatch(updateNetworkView({ reports: _reports }));
    };

    const onChecked = (row: any, event: any) => {
        updateSearchText(null);
        if (event.target.checked) {
            const checkedReport = reports.find((r: any) => r.id === row.id) as any;
            if (baseReportLocal === null) {
                dispatch(updateNetworkView({ baseReportLocal: checkedReport }));
            } else if (baseReportLocal !== null && compareReportLocal === null) {
                dispatch(updateNetworkView({ compareReportLocal: checkedReport }));
            }
            onExpand(row.bucket);
            setSelectedReportBucketId(checkedReport.bucket);
        } else {
            if (row.id === compareReportLocal?.id) {
                deselectCompareReport();
            } else if (row.id === baseReportLocal?.id) {
                deselectBaseReport();
            }
        }
        const _reports = reports?.map((report: any) => {
            if (row?.id === report?.id && row?.config_id === report?.config_id) {
                report.isChecked = event.target.checked;
            }
            return report;
        }) as any;
        dispatch(updateNetworkView({ reports: _reports }));
    };

    const onCheckedSelection = (report: any, event: any) => {
        if (!event.target.checked) {
            if (baseReportLocal && baseReportLocal.id === report.id) {
                deselectBaseReport();
            } else if (compareReportLocal && compareReportLocal.id === report.id) {
                deselectCompareReport();
            }

            const _reports = reports.map((r: any) => {
                if (r.id === report.id) r.isChecked = event.target.checked;
                return r;
            }) as any;
            dispatch(updateNetworkView({ reports: _reports }));
        } else {
            setSelectedReportBucketId(report.bucket);
        }
    };

    return {
        updateSearchText,
        searchText,
        filteredReports,
        handleSwap,
        buckets,
        expanded,
        selectedReportBucketId,
        onViewBucket,
        onCheckedSelection,
        onChecked,
        deselectCompareReport,
        deselectBaseReport,
        handleRemoveCompareReport,
        handleRemoveBaseReport
    };
};

export default useReportsHook;
