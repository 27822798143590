import React from 'react';
import { Checkbox } from '@mui/material';
import theme from 'theme';
import SquareSharpIcon from '@mui/icons-material/SquareSharp';
import IndeterminateCheckBoxSharpIcon from '@mui/icons-material/IndeterminateCheckBoxSharp';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';

const CheckboxComponent = ({ isChecked = false, isDisabled = false, onChecked, ...restProps }) => {
    return (
        <Checkbox
            icon={
                isDisabled ? (
                    <SquareSharpIcon
                        sx={{
                            color: theme.palette.neutral.neutral3
                        }}
                    />
                ) : (
                    <CheckBoxOutlineBlankSharpIcon
                        sx={{
                            color: theme.palette.black
                        }}
                    />
                )
            }
            checkedIcon={
                isDisabled ? (
                    <SquareSharpIcon
                        sx={{
                            color: theme.palette.neutral.neutral3
                        }}
                    />
                ) : (
                    <CheckBoxSharpIcon
                        sx={{
                            color: theme.palette.black
                        }}
                    />
                )
            }
            indeterminateIcon={
                <IndeterminateCheckBoxSharpIcon
                    sx={{
                        color: theme.palette.black
                    }}
                />
            }
            className={`reports-checkbox`}
            style={{
                paddingLeft: 16,
                paddingRight: 16,
                color: isDisabled ? theme.palette.ODBlue.odBlue7 : theme.palette.black
            }}
            checked={isChecked}
            onClick={onChecked}
            disabled={isDisabled}
            {...restProps}
        />
    );
};

export default CheckboxComponent;
