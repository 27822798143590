import React from 'react';
import { useSelector } from 'react-redux';

const LocationsInfo = ({ value, defaultValue = 'NA' }) => {
    const { locationVisualization } = useSelector(({ settingsReducer }) => settingsReducer);
    const reportLocations = useSelector(({ ReportKPIReducer }) => ReportKPIReducer.reportLocations);
    if (locationVisualization === 'city-state' && reportLocations && reportLocations[value]) {
        return <>{`${reportLocations[value]?.city}, ${reportLocations[value]?.state}`}</>;
    }

    return <>{value || defaultValue}</>;
};
export default LocationsInfo;
