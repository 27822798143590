import {
    RESET_HIDDEN_COLUMNS_ARRAYS,
    UPDATE_HIDDEN_COLUMNS_ARRAYS,
    UPDATE_TABLE_IDS
} from '../constants/actionTypes';
import API from 'utils/axios';

import { NETWORK_TABLE_NAME } from 'constants/network';
import { BIDDING_TABLE_NAME } from 'constants/bidding';
import { getDefaultHiddenColumns } from '../reducers/defaultTableLayoutDetails';
export const updateHiddenColumnsArrays = (payload) => {
    return {
        type: UPDATE_HIDDEN_COLUMNS_ARRAYS,
        payload
    };
};
export const resetHiddenColumnsArray = (payload) => {
    return {
        type: RESET_HIDDEN_COLUMNS_ARRAYS,
        payload
    };
};

// TODO add a resetTableIDs action that resets hidden array contents to their default values.

export const updateTableIds = (payload) => ({
    type: UPDATE_TABLE_IDS,
    payload
});

export const getHiddenColumns = (email, specialCase) => {
    return async (dispatch) => {
        try {
            const { data: results } = await API.get(`/user-ui-table-settings/`);
            const tableNames = [
                NETWORK_TABLE_NAME.SHIPPERS,
                NETWORK_TABLE_NAME.COMPARISON_SHIPPERS,
                NETWORK_TABLE_NAME.LANES,
                NETWORK_TABLE_NAME.COMPARISON_LANES,
                NETWORK_TABLE_NAME.DOMICILES,
                NETWORK_TABLE_NAME.COMPARISON_DOMICILES,
                NETWORK_TABLE_NAME.DRIVER_TYPES,
                NETWORK_TABLE_NAME.COMPARISON_DRIVER_TYPES,
                NETWORK_TABLE_NAME.DRIVER_IDS,
                NETWORK_TABLE_NAME.MOTIONS,
                BIDDING_TABLE_NAME.BID_CONFIGURATION,
                BIDDING_TABLE_NAME.BID_MANAGER,
                BIDDING_TABLE_NAME.BID_ANALYSIS,
                BIDDING_TABLE_NAME.FLOWS
            ];
            for (let i = 0; i < tableNames.length; i++) {
                const tableName = tableNames[i];
                const result = results.results.find((r) => r.table_name === tableName);
                if (result) {
                    // populate the redux variables
                    dispatch(
                        updateHiddenColumnsArrays({
                            tableName: tableName,
                            hiddenColumns: result.hidden_columns
                        })
                    );
                    dispatch(updateTableIds({ tableName: tableName, tableId: result.id }));
                } else {
                    const {
                        data: { success, data }
                    } = await API.post(`user-ui-table-settings/`, {
                        table_name: tableName,
                        hidden_columns: getDefaultHiddenColumns(tableName, specialCase),
                        user_email: email
                    });
                    if (success) {
                        dispatch(
                            updateHiddenColumnsArrays({
                                tableName: tableName,
                                hiddenColumns: []
                            })
                        );
                        dispatch(
                            updateTableIds({
                                tableName: tableName,
                                tableId: data.id
                            })
                        );
                    } else
                        throw new Error('Failed to create table columns settings for ' + tableName);
                }
            }
        } catch (err) {
            console.warn('error getting column settings for network tables ' + err);
        }
    };
};

export const updateHiddenColumns = (tableName, hiddenColumns) => {
    return async (dispatch, getState) => {
        try {
            const url = `/user-ui-table-settings/${
                getState().TableLayoutReducer.hiddenColumnsTableIds[tableName]
            }/`;
            const { data } = await API.put(url, {
                hidden_columns: hiddenColumns
            });
            if (data.updated_at)
                dispatch(
                    updateHiddenColumnsArrays({
                        tableName: tableName,
                        hiddenColumns: hiddenColumns
                    })
                );
            else throw new Error('Failed to update hidden columns for table ' + tableName);
        } catch (error) {
            console.warn('error updating hidden columns for table ' + tableName + ' ' + error);
        }
    };
};

export const resetHiddenColumns = (tableName, specialCase) => {
    return async (dispatch, getState) => {
        try {
            const url = `/user-ui-table-settings/${
                getState().TableLayoutReducer.hiddenColumnsTableIds[tableName]
            }/`;
            const defaultHiddenTableColumns = getDefaultHiddenColumns(tableName, specialCase);
            const { data } = await API.put(url, {
                hidden_columns: defaultHiddenTableColumns
            });
            if (data.updated_at)
                dispatch(
                    resetHiddenColumnsArray({
                        tableName,
                        specialCase
                    })
                );
            else throw new Error('Failed to reset hidden columns for table ' + tableName);
        } catch (error) {
            console.warn('error resetting hidden columns for table ' + tableName + ' ' + error);
        }
    };
};
