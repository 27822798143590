import React from 'react';
import { ODButton, ODModal } from 'shared';
import { Box, Tooltip } from '@mui/material';
import { ExportHelperText, FileNameCard, ButtonWrapper } from './Header.styles';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadNetworkImpact } from 'redux/actions/bidAnalysisActions';
import { RootState } from 'redux/reducers';
import { useLoadingHook } from 'utils/hooks';
import { BUTTON_TYPE } from 'constants/colors';
import AutoGraphSharpIcon from '@mui/icons-material/AutoGraphSharp';
import * as FS from '@fullstory/browser';

const DownloadNetworkViewImpactModal = ({
    setOpenNetworkViewImpactModal,
    openNetworkViewImpactModal
}: {
    setOpenNetworkViewImpactModal: any;
    openNetworkViewImpactModal: boolean;
}) => {
    const { currBidAnalysisData } = useSelector((state: RootState) => state.BidAnalysisReducer);

    const dispatch = useDispatch();
    const { showLoader } = useLoadingHook();

    const triggerDownload = async () => {
        FS.event('Trigger downloading network view impact', {});
        setOpenNetworkViewImpactModal(false);
        showLoader(true, 'export');
        await dispatch(
            getDownloadNetworkImpact(currBidAnalysisData?.id, currBidAnalysisData?.name)
        );
        showLoader(false);
    };

    return (
        <ODModal
            show={openNetworkViewImpactModal}
            heading="Download Network View Impact"
            handleClose={() => {
                setOpenNetworkViewImpactModal(false);
                FS.event('Close download network view impact modal', {});
            }}
            enableCustomizedButton={true}
            children={
                <>
                    <Tooltip title={currBidAnalysisData?.name}>
                        <FileNameCard>
                            <AutoGraphSharpIcon sx={{ marginRight: '16px' }} />
                            <Box
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    maxWidth: 'fit-content',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}>
                                {currBidAnalysisData?.name}
                            </Box>
                        </FileNameCard>
                    </Tooltip>

                    <ExportHelperText>
                        This file includes data that describes how your base network could be
                        impacted by the lane and load volume decisions in this Bid Analysis.
                    </ExportHelperText>

                    <ButtonWrapper>
                        <ODButton
                            width="188px"
                            sx={{ marginRight: '16px' }}
                            onClick={() => {
                                setOpenNetworkViewImpactModal(false);
                                FS.event('Close download network view impact modal', {});
                            }}
                            buttonText="Close"
                        />
                        <ODButton
                            width="188px"
                            buttonType={BUTTON_TYPE.BLUE}
                            onClick={triggerDownload}
                            buttonText="Download"
                        />
                    </ButtonWrapper>
                </>
            }
        />
    );
};

export default DownloadNetworkViewImpactModal;
