import React from 'react';
import {
    Grid,
    Slide,
    Avatar,
    Dialog,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import theme from 'theme';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const CompareDisabled = ({ open, onAction }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    minWidth: '350px'
                }
            }}
            TransitionProps={{ tabIndex: '', tabindex: '' }}
            onClose={() => onAction(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title" sx={{ padding: '36px 36px 52px 32px' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={11}>
                        <div sx={{ color: theme.palette.black }}> Comparing Disabled </div>
                    </Grid>
                    <Grid item xs={1}>
                        <Avatar
                            style={{
                                width: 40,
                                height: 40,
                                color: theme.palette.black,
                                backgroundColor: theme.palette.neutral.neutral1,
                                '&:hover': {
                                    backgroundColor: theme.palette.neutral.neutral2
                                }
                            }}
                            onClick={() => onAction(false)}>
                            <IconButton color="inherit" size="large">
                                <ClearIcon sx={{ color: theme.palette.black, cursor: 'pointer' }} />
                            </IconButton>
                        </Avatar>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ padding: '8px 32px', paddingBottom: '32px' }}>
                <DialogContentText
                    id="alert-dialog-slide-description"
                    sx={{ color: theme.palette.black }}>
                    Comparing has been disabled. Only two selected “Reports” that are “Completed”
                    and from the same “Group” can utilize the “Compare Reports” functionality.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default CompareDisabled;
