/* eslint-disable react-hooks/exhaustive-deps */
/* ODTable
 * ------------------------------------------------------------------------------------
 * The ODTable component is a flexible table with various useful features including editable interactive cells, drag to copy functionality, optional filters and column visibility controls
 *
 * EditBidConfigTable.tsx serves as a great example of how to use the ODTable component.
 * - A component that wraps the ODTable component should handle the data, checkedRows and rowsToDelete states.
 *     - This allows the wrapper component to pass these values to the table footer component
 *       which contains buttons for deleting rows and updating rows in the backend.
 */

import React from 'react';
import { Table, TableContainer } from '@mui/material';
import {
    useReactTable,
    getCoreRowModel,
    Row,
    getFilteredRowModel,
    ColumnFiltersState,
    getFacetedMinMaxValues,
    getFacetedUniqueValues,
    getFacetedRowModel,
    SortingState,
    getSortedRowModel,
    getPaginationRowModel
} from '@tanstack/react-table';
import { DataRow, ODTableProps } from './ODTableTypes';
import ODTableBody from './ODTableBody';
import ODTableHead from './ODTableHead';

import { useODTable, ODTableContextInterface, ODTableProviderProps } from './ODTableContext';
import { TABLE_TYPE } from 'constants/network';
import ODTablePagination from './ODTablePagination';
import { useFlags } from 'launchdarkly-react-client-sdk';
const ODTable: React.FC<ODTableProps> = ({
    globalFilter = '',
    searchBarGlobalFilter = (row: Row<DataRow>, id: string, filterValue: string | number) =>
        Boolean,
    setGlobalFilter = () => {},
    tableKey,
    lockOverflowY = false,
    rateColumnRef,
    setPageSizeInHook,
    setPageIndexInHook,
    manualPagination,
    bottomActionBar,
    totalItems
}) => {
    const {
        addPagination,
        columnDefs,
        data,
        defaultSortingState,
        disableFilters,
        disableSorting,
        setTable
    } = useODTable() as ODTableContextInterface & ODTableProviderProps;

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = React.useState<SortingState>(defaultSortingState!);
    const { odpt4185BidConfigUpdate } = useFlags(); //TODO temporary fix

    const _table = useReactTable({
        columns: columnDefs,
        data: data,
        state: {
            globalFilter,
            columnFilters,
            sorting
        },
        onSortingChange: setSorting,
        enableSorting: !disableSorting,
        enableSortingRemoval: true,
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: searchBarGlobalFilter,
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        ...(addPagination &&
            !manualPagination && { getPaginationRowModel: getPaginationRowModel() }),
        autoResetPageIndex: false,
        manualPagination: manualPagination
    });
    setTable(_table);

    const sxProps = odpt4185BidConfigUpdate
        ? {
              width: 'auto',
              marginX: '16px',
              maxHeight: bottomActionBar ? 'calc(100% - 186px)' : '100%'
          }
        : {};

    return (
        <>
            <TableContainer
                ref={rateColumnRef}
                key="table-container"
                sx={{
                    ...sxProps,
                    overflowY: lockOverflowY ? 'hidden' : 'auto',
                    marginTop: '16px',
                    minHeight: disableFilters ? '121px' : '170px',
                    zIndex: 0
                }}>
                <Table
                    id={tableKey}
                    sx={{
                        borderCollapse: 'collapse',
                        position: 'static'
                    }}>
                    <ODTableHead tableKey={tableKey} />
                    <ODTableBody />
                </Table>
            </TableContainer>
            {addPagination &&
                (tableKey === TABLE_TYPE.BID_ANALYSIS ||
                    tableKey === TABLE_TYPE.BID_CONFIG_TABLE) && (
                    <ODTablePagination
                        setPageIndexInHook={setPageIndexInHook}
                        setPageSizeInHook={setPageSizeInHook}
                        manualPagination={manualPagination}
                        totalItems={totalItems}
                    />
                )}
        </>
    );
};

export default ODTable;
