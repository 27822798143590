export const getAncestorIds = (element: HTMLElement | null) => {
    let ids: string[] = [];
    while (element) {
        ids = [...ids, element.id];
        if (element.parentElement) {
        }
        element = element.parentElement;
    }
    return ids;
};

export const trimId = (id: string) => {
    return id.replace(/[^a-zA-Z0-9-]/g, '');
};
