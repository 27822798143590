import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'redux/reducers';
import { isActionAllowed } from 'utils/network.util';
import { DefaultColumnFilter } from 'shared/Table/tableFilters';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { updateEditDriver, updateNetworkView } from 'redux/actions';

const useDriverHook = () => {
    const { newSimulationFlow } = useFlags();
    const dispatch = useDispatch();

    const { slideout } = useSelector((state: RootState) => state.NetworkReducer);
    const { configuration } = useSelector((state: RootState) => state.ConfigurationReducer);
    const { domicile } = useSelector((state: RootState) => state.DriversReducer);
    const alertsContext = useAlerts();

    const filterTypes = useMemo(
        () => ({
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter
        }),
        []
    );

    const openEditDriverSlideout = () =>
        dispatch(
            updateNetworkView({
                slideout: { ...slideout, editCurrDriver: true }
            })
        );

    const handleDriverEdit = (row: any, id?: any) => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        const selectedRow = row?.original ? row?.original : row;
        if (domicile) {
            let selectedDriverType = domicile.driver_types.filter(
                (ind: any) => ind.driver_type === (id ?? selectedRow.id)
            );
            let _domicile = { ...domicile, driver_types: selectedDriverType };
            dispatch(updateEditDriver(_domicile));
            openEditDriverSlideout();
        } else {
            dispatch(updateEditDriver(selectedRow));
            openEditDriverSlideout();
        }
    };

    return {
        filterTypes,
        defaultColumn,
        handleDriverEdit
    };
};

export default useDriverHook;
