/* theme: allows you to modify and override the styling of existing MUI components. Ref: https://mui.com/material-ui/customization/palette/ */

import { createTheme } from '@mui/material/styles';
import palette from './palette';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1630,
            xl: 1920
        }
    },
    transitions: createTheme.transitions,
    palette: palette,
    typography: {
        useNextVariants: true,
        fontFamily: ['IBM Plex Sans', 'sans-serif'].join(',')
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '18px'
                }
            },
            defaultProps: {
                fontSize: 'small'
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 12,
                    backgroundColor: palette.ODBlue.odBlue6,
                    color: palette.white
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomColor: palette.neutral.neutral2
                    },
                    '&:hover:not(.Mui-disabled)::before': {
                        borderBottomColor: palette.neutral.neutral2
                    }
                }
            }
        }
    },
    shape: {
        borderRadius: 4
    }
});

export default theme;
