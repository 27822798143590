import React from 'react';
import { ODModal, ODTextField, ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ButtonWrapper } from '../BidAnalysis/Header/Header.styles';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import theme from 'theme';

//TODO right now this modal only supports bid applied report run: https://optimaldynamics.atlassian.net/browse/ODPT-2975?atlOrigin=eyJpIjoiNTE3NWU5MjJlMjljNDBjYWE3NmUyY2RhNDZkNzAzNDAiLCJwIjoiaiJ9

const confirmBidAnalysisSchema = yup.object({
    bidAnalysisName: yup.string().min(1).max(50).required('Required')
});

const ConfirmBidAnalysisRunModal = ({
    openBidAnalysisConfirmModal,
    setOpenBidAnalysisConfirmModal,
    config,
    handleRunReport
}: {
    openBidAnalysisConfirmModal: boolean;
    setOpenBidAnalysisConfirmModal: any;
    config: any;
    handleRunReport: any;
}) => {
    return (
        <ODModal
            show={openBidAnalysisConfirmModal}
            heading="Confirm Analysis Run"
            handleClose={() => setOpenBidAnalysisConfirmModal(false)}
            actionButtonText="Confirm Analysis Run"
            enableCustomizedButton={true}
            children={
                <>
                    <Formik
                        initialValues={{ bidAnalysisName: '' }}
                        validationSchema={confirmBidAnalysisSchema}
                        onSubmit={(values, { resetForm }) => {
                            handleRunReport(values?.bidAnalysisName);
                            resetForm();
                        }}>
                        {({
                            dirty,
                            handleSubmit,
                            values,
                            handleChange,
                            resetForm,
                            isValid,
                            initialValues
                        }) => {
                            const clearAndClose = () => {
                                resetForm();
                                setOpenBidAnalysisConfirmModal(false);
                            };

                            return (
                                <form onSubmit={handleSubmit}>
                                    <ODTextField
                                        customInputProps={{ maxLength: 50 }}
                                        sx={{ marginTop: '16px' }}
                                        dataTestId="enter-new-bid-analysis-name"
                                        label="Enter Analysis Name*"
                                        defaultValue={
                                            values?.bidAnalysisName ||
                                            initialValues?.bidAnalysisName
                                        }
                                        onChange={handleChange}
                                        name="bidAnalysisName"
                                    />
                                    <Typography
                                        sx={{
                                            marginTop: '2px',
                                            textAlign: 'right',
                                            fontSize: '12px',
                                            marginBottom: '4px',
                                            color: theme.palette.neutral.neutral6
                                        }}>
                                        {values?.bidAnalysisName.length}/50 Character Limit
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            fontSize: '14px',
                                            height: '40px',
                                            fontWeight: 400,
                                            padding: '10px',
                                            borderRadius: '4px',
                                            backgroundColor:
                                                theme.palette.ODLightBlueNeutral.lightBlue1
                                        }}>
                                        <InfoOutlinedIcon sx={{ marginRight: '16px' }} />
                                        <Box sx={{ fontSize: '14px' }}>
                                            An email will be sent to you from core-ai.io once your
                                            analysis is completed.
                                        </Box>
                                    </Box>

                                    <ButtonWrapper>
                                        <ODButton
                                            onClick={clearAndClose}
                                            type="cancel"
                                            sx={{ marginRight: '16px', width: '188px' }}
                                            buttonText="Cancel"></ODButton>
                                        <ODButton
                                            buttonType={BUTTON_TYPE.BLUE}
                                            type="submit"
                                            width="188px"
                                            buttonText="Run"
                                            disabled={!isValid || !dirty}
                                        />
                                    </ButtonWrapper>
                                </form>
                            );
                        }}
                    </Formik>
                </>
            }
        />
    );
};

export default ConfirmBidAnalysisRunModal;
