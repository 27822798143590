import {
    NETWORK_KPI_SUCCESS,
    UPDATE_DOMICILE,
    UPDATE_DOMICILE_META,
    UPDATE_NETWORK_VIEW,
    CLEAR_REPORT_DATA,
    CLEAR_REPORT_DATA_PRESERVE_CONTEXT
} from 'redux/constants/actionTypes';
import { DRIVER_MODE } from 'constants/drivers';
import { NETWORK_TABLE_TYPE } from 'constants/network';

//TODO we need to revisit this here to clean up redundant states here.

const initialState = {
    currReportId: 0,
    baseReport: {},
    compareReport: {},
    selectedBaseReport: { id: null, name: null, enabled: false },
    selectedCompareReport: { id: null, name: null, enabled: false },
    kpi: {},
    domiciles: {},
    meta: {},
    currTab: NETWORK_TABLE_TYPE.SHIPPERS,
    expandPanel: true,
    expandTopNavbar: true,
    isListView: true,
    enableEditRow: false,
    isRunSimulation: false,
    isCompletedReport: { show: false, report: {} },
    showSaveDraftModal: { show: false, redirectTo: '' },
    selectedLoad: {},
    showAlertModal: false,
    showFreightBonusModal: false,
    isBonusModalForAdd: false,
    showCompletedReportModal: false,
    driverMode: DRIVER_MODE.DEFAULT,
    showDriveMoveDetails: {
        show: false,
        currData: {}
    },
    checked: {
        allShippers: false,
        allLanes: false,
        selectedShippers: [],
        selectedLanes: []
    },
    slideout: {
        addNewDriver: false,
        editCurrDriver: false
    },
    showNetworkTableSettingsSlideout: false,
    showDriverOptionBar: false,
    currShipperActionBar: null,
    showAlertNotification: false,
    showDownloadModal: false,
    showParameterDialog: false,
    showHistorySlideout: false,
    appliedBidFiles: [],
    showBidFilesSlideout: false,
    isReportBeingDownloaded: false,
    networkBreadcrumbs: {
        domicile: '',
        driverTypes: '',
        driverId: '',
        driverMovement: '',
        shipper: '',
        lane: ''
    },
    buckets: [],
    rowHover: true,
    loadingFlowData: false, //TODO put these in a loading reducer line 70-83
    loadingActions: false,
    loadingResetDriverState: false,
    loadingKPIForDriver: false,
    loadingGetDomicilesById: false,
    loadingFetchingMapboxKey: false,
    loadingFetchingReports: false,
    loadingGetLocationsByReportId: false,
    loadingGetReportsByBucketOrId: false,
    loadingGetDriverTypesById: false,
    loadingGetReportByReportId: false,
    loadingGetTableDataByIdAndFilters: false,
    loadingGetReportAppliedBids: false,
    fetchingKPIbyReportId: false,
    baseReportLocal: null,
    compareReportLocal: null,
    loadingGetAllGroups: false,
    loadingGetDrafts: false,
    loadingGetAllReports: false,
    loadingGetArchived: false,
    fetchingBuckets: false,
    loadingGetBidGroups: false,
    reports: []
};

const updateNetworkView = (state, action) => ({
    ...state,
    ...action.payload
});

const clearReportData = () => initialState;
const clearReportDataPreserveContext = (state) => ({
    ...initialState,
    selectedBaseReport: { ...state.selectedBaseReport },
    selectedCompareReport: { ...state.selectedCompareReport },
    isListView: state.isListView
});

const networkReducer = (state = initialState, action) => {
    switch (action.type) {
        case NETWORK_KPI_SUCCESS:
        case UPDATE_DOMICILE:
        case UPDATE_DOMICILE_META:
        case UPDATE_NETWORK_VIEW:
            return updateNetworkView(state, action);
        case CLEAR_REPORT_DATA:
            return clearReportData();
        case CLEAR_REPORT_DATA_PRESERVE_CONTEXT:
            return clearReportDataPreserveContext(state);
        default:
            return state;
    }
};

export default networkReducer;
