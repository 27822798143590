import React from 'react';
import { Box } from '@mui/material';
import NetworkViewFullWidthPanel from './NetworkViewFullWidthPanel';
import theme from 'theme';

const ListView = () => {
    return (
        <Box
            sx={{
                flex: 1,
                width: '100%',
                margin: 0,
                backgroundColor: theme.palette.white
            }}>
            <NetworkViewFullWidthPanel />
        </Box>
    );
};

export default ListView;
