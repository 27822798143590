import React from 'react';
import { Box, Toolbar, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import ClearIcon from '@mui/icons-material/Clear';
import { Label, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import SettingsIcon from '@mui/icons-material/Settings';

interface StyledToolProps {
    visibleHeight?: boolean;
}
const StyledToolbar = styled(Toolbar)<StyledToolProps>(({ visibleHeight }) => ({
    display: 'flex',
    zIndex: 9,
    boxShadow: 'none',
    backgroundColor: theme.palette.neutral.white,
    color: theme.palette.neutral.black,
    borderBottom: `1px solid ${theme.palette.neutral.neutral1}`,
    borderRadius: '4px',
    flexDirection: 'column',
    minHeight: '56px !important',
    justifyContent: 'space-between',
    padding: '0 !important',
    width: '100%',
    height: visibleHeight ? '112px' : 0,
    paddingLeft: '16px'
}));

const StyledFileDetailsBox = styled(Box)(() => ({
    width: '100%',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.neutral.neutral1}`
}));

const StyledFileDetails = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 16px',
    width: '100%'
}));

const FileDetail = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '15rem'
}));

const PageTitleBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    height: '56px',
    alignItems: 'center',
    fontWeight: '600'
}));

const PageTitle = styled(Box)(() => ({
    marginLeft: '16px'
}));
interface FileDetailItemType {
    name: string;
    value: string | number | null;
    hide?: boolean;
}
interface HeaderTopBarProps {
    fileDetailItems?: FileDetailItemType[];
    fileTypeName: string;
    fileTypeIcon?: React.ReactNode;
    visibleHeight?: boolean;
    onClose?: () => void;
    handleOpenSettings?: () => void;
}
const HeaderTopBar = ({
    onClose,
    handleOpenSettings,
    fileDetailItems,
    fileTypeName,
    fileTypeIcon,
    visibleHeight = true
}: HeaderTopBarProps) => {
    const handleOnClose = () => onClose?.();

    const FileDetailItem = ({ name, value }: FileDetailItemType) => {
        return (
            <FileDetail>
                <Box sx={{ fontWeight: '600' }}>{name}</Box>
                <Tooltip title={value || ''}>
                    <Box
                        sx={{
                            maxWidth: '240px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}>
                        {value}
                    </Box>
                </Tooltip>
            </FileDetail>
        );
    };

    const FileDetails = () => {
        return (
            <StyledFileDetailsBox component={Typography}>
                <StyledFileDetails>
                    {fileDetailItems?.map((detailItem) =>
                        detailItem?.hide ? null : (
                            <FileDetailItem
                                key={detailItem.name}
                                name={detailItem.name}
                                value={detailItem.value}
                            />
                        )
                    )}
                </StyledFileDetails>
            </StyledFileDetailsBox>
        );
    };

    return (
        <StyledToolbar visibleHeight={visibleHeight}>
            <PageTitleBox>
                <PageTitle>
                    <Label fontWeight="600" text={fileTypeName} startIcon={fileTypeIcon} />
                </PageTitle>
                <Box sx={{ marginRight: '16px', display: 'flex', flexDirection: 'row' }}>
                    {handleOpenSettings && (
                        <ODIconButton
                            enableTooltip
                            id="Settings"
                            onClick={handleOpenSettings}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            sx={{ color: theme.palette.neutral.black, marginRight: '8px' }}
                            icon={
                                <SettingsIcon
                                    style={{
                                        fontSize: '18px',
                                        cursor: 'pointer'
                                    }}
                                />
                            }
                        />
                    )}
                    <ODIconButton
                        enableTooltip
                        id="Close"
                        onClick={handleOnClose}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        sx={{ color: theme.palette.neutral.black }}
                        icon={
                            <ClearIcon
                                style={{
                                    fontSize: '18px',
                                    cursor: 'pointer'
                                }}
                            />
                        }
                    />
                </Box>
            </PageTitleBox>
            {fileDetailItems && <FileDetails />}
        </StyledToolbar>
    );
};

export default HeaderTopBar;
