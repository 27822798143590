import theme from 'theme';
import { Box } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    emptyvalue: {
        color: theme.palette.neutral.neutral6
    }
}));
const valueIsEmpty = (value: string | number | null | object) => {
    return value === null || (typeof value === 'string' && value.length === 0);
};
type NonactiveInputCellType = {
    value: string | number | null | object;
    cellStyle: any;
};
const NonactiveInputCell = ({ value, cellStyle }: NonactiveInputCellType) => {
    const classes = useStyles();
    if (typeof value === 'number' || typeof value === 'string' || value === null) {
        return (
            <Box
                sx={{ ...cellStyle, textDecoration: 'underline' }}
                className={clsx(valueIsEmpty(value) && classes.emptyvalue)}>
                {valueIsEmpty(value) ? '0' : value}
            </Box>
        );
    } else {
        return <Box>{value as any}</Box>;
    }
};

export default NonactiveInputCell;
