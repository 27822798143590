import React from 'react';
import clsx from 'clsx';
import { Autocomplete, TextField, InputAdornment } from '@mui/material/';
import { CommonCheckboxComponent } from 'shared';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.neutral.neutral6,
        marginBottom: 16
    },
    label: {
        fontSize: 12,
        marginBottom: 4
    },
    inputField: {
        // width: "100%",
        padding: 0,
        '& .MuiInput-root': {
            padding: '10px 16px !important'
        },
        '& .MuiInput-input': {
            padding: 0,
            fontSize: 14,
            color: theme.palette.neutral.neutral6
        },
        '& .MuiInput-root::before': {
            borderBottom: `1px solid ${theme.palette.neutral.neutral2}`
        },
        '& .MuiAutocomplete-endAdornment': {
            position: 'relative'
        },
        '& .MuiButtonBase-root': {
            minWidth: 18,
            height: 18,
            width: 18
        },
        '& .MuiInputAdornment-root': {
            width: 18,
            height: 18
        },
        '& .MuiInputAdornment-root svg': {
            fill: theme.palette.neutral.neutral6
        }
    },
    tagContainer: {
        color: theme.palette.neutral.neutral6,
        paddingRight: 8
    },
    AutoCompleteOption: {
        paddingLeft: 0,
        backgroundColor: theme.palette.neutral.neutral1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        "&[aria-selected='true']": {
            background: '#E6E6E6'
        }
    }
}));

export const AutoCompleteComponent = ({
    options = [],
    style = {},
    value = [],
    label = ' ',
    multiple = false,
    variant = 'standard',
    id = 'auto-complete-component',
    className = 'auto-complete-component',
    ...restProps
}) => {
    const classes = useStyles();
    return (
        <div class="form-control" className={classes.root} style={style}>
            <label for="my-input" className={classes.label}>
                {label}
            </label>
            <Autocomplete
                multiple={multiple}
                id={id}
                variant={variant}
                options={options}
                disableCloseOnSelect={multiple}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected = false }) => (
                    <li
                        {...props}
                        id={`option-${option?.id}`}
                        key={`${option?.id}-${option.name}-option`}
                        className={clsx(classes.AutoCompleteOption, 'options')}>
                        {multiple && (
                            <CommonCheckboxComponent
                                isChecked={option.selected || selected}
                                color={`#323232`}
                            />
                        )}
                        <span style={{ paddingLeft: multiple ? 0 : 16 }}>
                            {option.name} {option.selected || selected}
                        </span>
                    </li>
                )}
                ListboxProps={{ style: { padding: 0 } }}
                renderTags={(values) => (
                    <div className={classes.tagContainer}>
                        {multiple ? `(${values.length}) Selected` : value}
                    </div>
                )}
                renderInput={(params) => {
                    let focused = false;
                    try {
                        const {
                            InputProps: {
                                endAdornment: {
                                    props: { ownerState }
                                }
                            }
                        } = params;
                        focused = ownerState?.focused;
                    } catch (_e) {}

                    return (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment>
                                        <KeyboardArrowUpSharpIcon
                                            sx={{
                                                transform: focused
                                                    ? 'rotate(0deg)'
                                                    : 'rotate(-180deg)'
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                            placeholder={
                                multiple ? value.length === 0 && `(0) Selected` : 'Select Options'
                            }
                            className={classes.inputField}
                            variant="standard"
                        />
                    );
                }}
                value={value}
                {...restProps}
            />
        </div>
    );
};
