import React from 'react';
import { Box } from '@mui/material';
import { SubdirectoryArrowRight, LocalShipping, AttachMoney, Close } from '@mui/icons-material';
import { AnyObject } from 'yup/lib/types';
import palette from 'theme/palette';
import { ODCard, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { HISTORY_CHANGES_TYPES } from 'constants/network';
import { StyledPaper, TextContent } from './HistorySlideout.styles';

interface historyHandleChangeRatesCardsProps {
    key: string;
    handleRestore: (e: any, data: AnyObject) => any;
    id: string;
    type: string;
    value: any;
    readOnly?: boolean;
}
const HistoryHandleChangeRatesCard = ({
    id,
    key,
    type,
    value,
    handleRestore,
    readOnly
}: historyHandleChangeRatesCardsProps) => {
    return (
        <StyledPaper readOnly={readOnly} key={key}>
            {value?.shipper && (
                <ODCard sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display={'flex'}>
                        <LocalShipping />
                        <TextContent id={`shipper-id-${value?.shipper}`}>
                            <b>Shipper ID:</b> {value.shipper}
                        </TextContent>
                    </Box>
                    {!readOnly && (
                        <ODIconButton
                            disableHover={true}
                            sx={{ padding: 0 }}
                            buttonType={BUTTON_TYPE.TRANSPARENT}
                            icon={<Close sx={{ color: palette.neutral.black }} />}
                            onClick={(e) =>
                                handleRestore(e, {
                                    id,
                                    ...value
                                })
                            }
                        />
                    )}
                </ODCard>
            )}

            {value?.lane && (
                <ODCard>
                    <SubdirectoryArrowRight />
                    <TextContent id={`shipper-id-${value?.shipper}`}>
                        <b>Lane ID: </b> {value.lane}
                    </TextContent>
                </ODCard>
            )}

            {type === HISTORY_CHANGES_TYPES.CHANGE_RATE && (
                <>
                    <ODCard>
                        <AttachMoney />
                        <TextContent>
                            <b>Minimum Rate / Mile:</b>{' '}
                            <s>${(value.minimum_rate || 0).toFixed(2)}</s>
                        </TextContent>
                    </ODCard>
                    <ODCard>
                        <SubdirectoryArrowRight />
                        <TextContent>
                            <b>Change: </b>
                            {value.percentage
                                ? value.percentage + '%'
                                : '$' + parseFloat(value.amount || 0).toFixed(2)}
                        </TextContent>
                    </ODCard>
                </>
            )}

            {type === HISTORY_CHANGES_TYPES.ADD && (
                <ODCard>
                    <AttachMoney />
                    <TextContent>
                        <b>Load Count:</b> {value.load_count}
                        <span>
                            ({(value.amount || value.percentage) > 0 ? '+' : ''}
                            {parseInt(value.amount || value.percentage)}
                            {value.percentage && '%'})
                        </span>
                    </TextContent>
                </ODCard>
            )}
        </StyledPaper>
    );
};

export default HistoryHandleChangeRatesCard;
