//Bid
export const UPDATE_BID_REDUCER = 'UPDATE_BID_REDUCER';
export const RESET_MODAL_MODE = 'RESET_MODAL_MODE';
export const RESET_GROUP_SLIDE_OUT = 'RESET_GROUP_SLIDE_OUT';
export const BID_CONFIG_SUCCESS = 'BID_CONFIG_SUCCESS';
export const BID_CONFIG_FAIL = 'BID_CONFIG_FAIL';
export const RESET_BID_MANAGER_TABLE = 'RESET_BID_MANAGER_TABLE';
export const ADD_BID_CONFIG_TABLE_ERROR = 'ADD_BID_CONFIG_TABLE_ERROR';
export const CLEAR_BID_CONFIG_TABLE_ERROR = 'CLEAR_BID_CONFIG_TABLE_ERROR';
export const OPEN_DUP_BID_FILE = 'OPEN_DUP_BID_FILE';
//SignIn
export const TOKEN_EXPIRY = 'TOKEN_EXPIRY';
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const SIGNOUT = 'SIGNOUT';
export const SIGNIN_CLEAR = 'SIGNIN_CLEAR';
export const USER_ROLES = 'USER_ROLES';
export const ORGANIZATION = 'ORGANIZATION';
export const LAUNCH_DARKLY_USER_IDENTIFIED = 'LAUNCH_DARKLY_USER_IDENTIFIED';

//Reports
export const REPORT_TYPE_SUCCESS = 'REPORT_TYPE_SUCCESS';
export const REPORT_TYPE_FAILURE = 'REPORT_TYPE_FAILURE';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE';
export const RESET_REPORT_ID = 'RESET_REPORT_ID';
export const GET_MAP_KEY = 'GET_MAP_KEY';
export const GET_MAP_BOX_KEY = 'GET_MAP_BOX_KEY';
export const REPORT_PARAM_VERSION_SUCCESS = 'REPORT_PARAM_VERSION_';
export const REPORT_PARAM_VERSION_FAILURE = 'REPORT_PARAM_VERSION_FAILURE';
export const REPORT_DETAIL_BY_ID_FAILURE = 'REPORT_DETAIL_BY_ID_FAILURE';
export const REPORT_DETAIL_BY_ID_SUCCESS = 'REPORT_DETAIL_BY_ID_SUCCESS';
export const REPORT_NOTIFICATION_SUCCESS = 'REPORT_NOTIFICATION_SUCCESS';
export const REPORT_PARAMETER_SUCCESS = 'REPORT_PARAMETER_SUCCESS';
export const UPDATE_REPORTS_REDUCER = 'UPDATE_REPORTS_REDUCER';
export const REPORT_DRIVER_TYPES_SUCCESS = 'REPORT_DRIVER_TYPES_SUCCESS';
export const REPORT_FILTER_SUCCESS = 'REPORT_FILTER_SUCCESS';
export const LOAD_FILTER_SUCCESS = 'LOAD_FILTER_SUCCESS';
export const UPDATE_LOAD_SOURCE = 'UPDATE_LOAD_SOURCE';
export const SET_SELECTED_DRIVER_TYPES = 'SET_SELECTED_DRIVER_TYPES';
export const SET_SELECTED_PICKUP_LOCATIONS = 'SET_SELECTED_PICKUP_LOCATIONS';
export const SET_SELECTED_DROPOFF_LOCATIONS = 'SET_SELECTED_DROPOFF_LOCATIONS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const GET_BIDS_APPLIED_TO_BASE_REPORT_SUCCESS = 'GET_BIDS_APPLIED_TO_BASE_REPORT_SUCCESS';
export const GET_BIDS_APPLIED_TO_BASE_REPORT_FAILURE = 'GET_BIDS_APPLIED_TO_BASE_REPORT_FAILURE';
export const UPDATE_FREIGHT_BONUS_APPLIED = 'UPDATE_FREIGHT_BONUS_APPLIED';
export const UPDATE_APPLIED_FILTERS_COUNT = 'UPDATE_APPLIED_FILTERS_COUNT';

//Report KPI
export const INITIAL_REPORT_RUN_SUCCESS = 'INITIAL_REPORT_RUN_SUCCESS';
export const INITIAL_REPORT_RUN_FAILURE = 'INITIAL_REPORT_RUN_FAILURE';
export const INITIAL_REPORT_LOAD_SUCCESS = 'INITIAL_REPORT_LOAD_SUCCESS';
export const INITIAL_REPORT_LOAD_FAILURE = 'INITIAL_REPORT_LOAD_FAILURE';
export const CALCULATE_REVENUE = 'CALCULATE_REVENUE';
export const REPORT_CALCULATED_KPI_SUCCESS = 'REPORT_CALCULATED_KPI_SUCCESS';
export const REPORT_CALCULATED_KPI_CLEAR = 'REPORT_CALCULATED_KPI_CLEAR';
export const REPORT_CALCULATED_KPI_FAILURE = 'REPORT_CALCULATED_KPI_FAILURE';
export const GET_REPORT_FLOW_SUCCESS = 'GET_REPORT_FLOW_SUCCESS';
export const GET_REPORT_FLOW_FAILURE = 'GET_REPORT_FLOW_FAILURE';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';
export const CLEAR_REPORT_DATA_PRESERVE_CONTEXT = 'CLEAR_REPORT_DATA_PRESERVE_CONTEXT';

//Files Action
export const GET_CUSTOMER_FILES_SUCCESS = 'GET_CUSTOMER_FILES_SUCCESS';
export const GET_CUSTOMER_FILES_FAILURE = 'GET_CUSTOMER_FILES_FAILURE';
export const GET_FILE_ENTITY_TYPES_SUCCESS = 'GET_FILE_ENTITY_TYPES_SUCCESS';
export const GET_FILE_ENTITY_TYPES_FAILURE = 'GET_FILE_ENTITY_TYPES_FAILURE';
export const GET_CUSTOMER_FILES_MAPPING_SUCCESS = 'GET_CUSTOMER_FILES_MAPPING_SUCCESS';
export const GET_CUSTOMER_FILES_MAPPING_FAILURE = 'GET_CUSTOMER_FILES_MAPPING_FAILURE';
export const UPDATE_CUSTOMER_FILES_SUCCESS = 'UPDATE_CUSTOMER_FILES_SUCCESS';
export const UPDATE_CUSTOMER_FILES_FAILURE = 'UPDATE_CUSTOMER_FILES_FAILURE';

//Report Location
export const REPORT_LOCATIONS_SUCCESS = 'REPORT_LOCATIONS_SUCCESS';
export const REPORT_LOCATIONS_CLEAR = 'REPORT_LOCATIONS_CLEAR';
export const REPORT_LOCATIONS_FAILURE = 'REPORT_LOCATIONS_FAILURE';

//Configuration
export const CONFIGURATIONS_START = 'CONFIGURATIONS_START';
export const CONFIGURATIONS_SUCCESS = 'CONFIGURATIONS_SUCCESS';
export const CONFIGURATIONS_FAIL = 'CONFIGURATIONS_FAIL';
export const CONFIGURATIONS_CLEAR = 'CONFIGURATIONS_CLEAR';
export const SET_CONFIGURATION_SUCCESS = 'SET_CONFIGURATION_SUCCESS';
export const CONFIGURATION_ACTION_SUCCESS = 'CONFIGURATION_ACTION_SUCCESS';
export const SET_INITIAL_ACTION_SUCCESS = 'SET_INITIAL_ACTION_SUCCESS';
export const SET_APPLIED_BIDS = 'SET_APPLIED_BIDS';
export const UPDATE_CONFIGURATION_STATE = 'UPDATE_CONFIGURATION_STATE';

//Setting
export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';
export const SETTINGS_RESET = 'SETTINGS_RESET';

// TABLE COLUMNS
export const UPDATE_HIDDEN_COLUMNS_ARRAYS = 'UPDATE_HIDDEN_COLUMNS_ARRAYS';
export const UPDATE_TABLE_IDS = 'UPDATE_TABLE_IDS';
export const RESET_HIDDEN_COLUMNS_ARRAYS = 'RESET_HIDDEN_COLUMNS_ARRAYS';

//Network
export const NETWORK_KPI_SUCCESS = 'NETWORK_KPI_SUCCESS';
export const NETWORK_KPI_CLEAR = 'NETWORK_KPI_CLEAR';
export const UPDATE_DOMICILE = 'UPDATE_DOMICILE';
export const UPDATE_DOMICILE_META = 'UPDATE_DOMICILE_META';
export const UPDATE_NETWORK_VIEW = 'UPDATE_NETWORK_VIEW';

// Drivers
export const SET_SORT = 'SET_SORT';
export const UPDATE_SELECTED_DOMICILE = 'UPDATE_SELECTED_DOMICILE';
export const UPDATE_DRIVER_TYPE = 'UPDATE_DRIVER_TYPE';
export const UPDATE_DRIVER_TYPES = 'UPDATE_DRIVER_TYPES';
export const UPDATE_DRIVER_MOMENTS = 'UPDATE_DRIVER_MOMENTS';
export const UPDATE_REPORT_DOMICILES = 'UPDATE_REPORT_DOMICILES';
export const UPDATE_DRIVERS = 'UPDATE_DRIVERS';
export const UPDATE_DRIVER_REDUCER = 'UPDATE_DRIVER_REDUCER';
export const UPDATE_EDIT_DRIVER = 'UPDATE_EDIT_DRIVER';
export const UPDATE_ADD_DRIVERS_COUNT = 'UPDATE_ADD_DRIVERS_COUNT';

// Loader
export const UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE';

//Bid analysis
export const UPDATE_BID_ANALYSIS_REDUCER = 'UPDATE_BID_ANALYSIS_REDUCER';
export const CLEAR_BID_ANALYSIS = 'CLEAR_BID_ANALYSIS';
export const CLEAR_BID_GOALS = 'CLEAR_BID_GOALS';
export const ADD_TABLE_ERROR = 'ADD_TABLE_ERROR';
export const CLEAR_TABLE_ERROR = 'CLEAR_TABLE_ERROR';
export const SET_BID_ANALYSIS_APPLIED_BID_AND_DEFAULT_RATE_TYPE =
    'SET_BID_ANALYSIS_APPLIED_BID_AND_DEFAULT_RATE_TYPE';
export const BID_CONFIG_LOCATIONS_SUCCESS = 'BID_CONFIG_LOCATIONS_SUCCESS';
export const BID_CONFIG_BASE_NETWORK_REPORTS_SUCCESS = 'BID_CONFIG_BASE_NETWORK_REPORTS_SUCCESS';

// Modals
export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';
