import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import MuiIconButton from '@mui/material/IconButton';
import theme from 'theme';
import PropTypes from 'prop-types';

const IconButton = ({ title, icon, className = {}, styles = {}, backgroundColor, ...props }) => {
    const { disabled } = props;

    const content = (
        <MuiIconButton
            {...props}
            sx={{
                ...className,
                ...styles,
                minWidth: '0px',
                height: '40px',
                width: '40px',
                margin: '0 16px 0 0 !important',
                backgroundColor: `${
                    disabled
                        ? theme.palette.neutral.neutral4
                        : backgroundColor
                        ? backgroundColor
                        : theme.palette.ODBlue.odBlue9
                } !important`,
                transition: theme.transitions.create(['transform'], {
                    duration: theme.transitions.duration.short
                }),
                '&:hover': {
                    backgroundColor: `${theme.palette.ODBlue.odBlue7} !important`
                }
            }}
            size="large">
            {icon}
        </MuiIconButton>
    );

    return title && !disabled ? <Tooltip title={title}>{content}</Tooltip> : content;
};

IconButton.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.object.isRequired
};

export default IconButton;
