import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { CommonInput, CommonHeaderContainer } from 'shared';
import makeStyles from '@mui/styles/makeStyles';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import AddIcon from '@mui/icons-material/Add';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
    pointer: { cursor: 'pointer' }
}));

const AddTotalDriverType = ({ type, updateTotal }) => {
    const classes = useStyles();

    const onUpdate = (count) => {
        let total = 0;

        if (count || count.length > 0) {
            total = parseInt(count);
        }

        updateTotal(type, total);
    };
    const handleIncrement = () => {
        let count = Number(type.total);
        count += 1;
        onUpdate(count);
    };

    const handleDecrement = () => {
        if (type.total <= 0) return;
        let count = Number(type.total);
        count -= 1;
        onUpdate(count);
    };

    return (
        <div style={{ marginBottom: 16 }}>
            <CommonHeaderContainer
                dark={false}
                noPadding={true}
                noBorder={true}
                header={type.name}
                headerStyle={{
                    height: 40,
                    marginBottom: 16,
                    padding: '0 16px',
                    backgroundColor: '#F2F2F2'
                }}
                endComponent={<ClearIcon sx={{ color: theme.palette.black }} />}
            />

            <CommonInput
                id={`total-drivers-${type?.name}`}
                name={`total-drivers-${type?.name}`}
                variant="standard"
                placeholder="0"
                label={`Total Drivers for ${type.name}`}
                textAlign="center"
                dark={false}
                startIcon={
                    <RemoveSharpIcon
                        sx={{ color: theme.palette.neutral.black }}
                        className={classes.pointer}
                        onClick={handleDecrement}
                    />
                }
                endIcon={<AddIcon className={classes.pointer} onClick={handleIncrement} />}
                value={type.total}
                onChange={({ target: { value } }) => onUpdate(value)}
            />
        </div>
    );
};

export default AddTotalDriverType;
