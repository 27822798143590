import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import theme from 'theme';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { updateBidAnalysis } from 'redux/actions';
import { DataRow } from 'shared/ODTable/ODTableTypes';
import useBidAnalysisHook from 'utils/hooks/useBidAnalysisHook';

const BreadcrumbsContainer = styled(Box)(() => ({
    borderBottom: `1px solid ${theme.palette.neutral.neutral1}`,
    padding: '9px 16px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 13,
    backgroundColor: theme.palette.white
}));

const BreadcrumbsHeaderText = styled(Typography)(() => ({
    textTransform: 'capitalize',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.ODBlue.odBlue7
}));

const BreadcrumbsText = styled(Typography)(() => ({
    color: theme.palette.black
}));

const BidAnalysisBreadcrumbs = () => {
    const { currBidAnalysisLanes, selectedLaneId, selectedLaneBreadcrumb } = useSelector(
        (state: RootState) => state.BidAnalysisReducer
    );
    const { navigateToBidAnalysisPage } = useBidAnalysisHook();

    useEffect(() => {
        if (selectedLaneId === null) dispatch(updateBidAnalysis({ selectedLaneBreadcrumb: null }));
        else {
            const selectedLane = currBidAnalysisLanes.find(
                (lane: DataRow) => lane.id === selectedLaneId
            );
            const origin = selectedLane?.origin;
            const destination = selectedLane?.destination;
            const breadcrumbText = `${origin}-${destination}`;
            dispatch(updateBidAnalysis({ selectedLaneBreadcrumb: breadcrumbText }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLaneId]);

    const dispatch = useDispatch();
    const history = useHistory();
    const { currBidAnalysisData } = useSelector((state: RootState) => state.BidAnalysisReducer);

    return (
        <BreadcrumbsContainer>
            <Breadcrumbs
                aria-label="breadcrumb"
                sx={{
                    color: theme.palette.black
                }}
                separator=">">
                <BreadcrumbsHeaderText onClick={() => history.push('/')}>
                    Network Manager
                </BreadcrumbsHeaderText>
                <BreadcrumbsHeaderText
                    onClick={() => {
                        navigateToBidAnalysisPage();
                        dispatch(updateBidAnalysis({ selectedLaneId: null }));
                    }}>
                    {currBidAnalysisData?.name}
                </BreadcrumbsHeaderText>

                {selectedLaneBreadcrumb && (
                    <BreadcrumbsText>{selectedLaneBreadcrumb}</BreadcrumbsText>
                )}
            </Breadcrumbs>
        </BreadcrumbsContainer>
    );
};

export default BidAnalysisBreadcrumbs;
