import React, { useEffect, useState } from 'react';
import { Drawer, Box, IconButton } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { Label, ODFooterActionBar, SearchContainer } from 'shared';
import { styled } from '@mui/material/styles';
import {
    updateAppliedBids,
    updateNetworkView,
    getConfigurationActions,
    getAllBids
} from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSimulationHook, useLoadingHook } from 'utils/hooks';
import { useParamsProps } from 'types/hooks';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltSharpIcon from '@mui/icons-material/SaveAltSharp';
import LibraryAddCheckSharpIcon from '@mui/icons-material/LibraryAddCheckSharp';
import { RootState } from 'redux/reducers';
import { InsertDriveFile } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BidFileListTable from './BidFileListTable';
import EditBidConfigurationTable from 'components/Bidding/EditBidConfigTable/EditBidConfigTable';
import API from 'utils/axios';
import { BidGroup, BidFile } from 'components/Bidding/BiddingTypes';
import SelectedBidsAccordion from './SelectedBidsAccordion';
import BidGroupList from './BidGroupList';
import { REPORT_STATUS } from 'constants/network';
import * as FS from '@fullstory/browser';
import { useFlags } from 'launchdarkly-react-client-sdk';

const SlideoutContainer = styled(Box)(() => ({
    width: '456px'
}));
const StyledHeader = styled(Box)(() => ({
    height: '56px',
    display: 'flex',
    padding: '0px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px ${theme.palette.neutral.neutral1} solid`
}));
const StyledBody = styled(Box)(() => ({
    padding: '16px'
}));
const StyledHeaderActions = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));
const FooterWrapper = styled(Box)(() => ({
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0px 8px',
    bottom: '7px'
}));
interface BidFilesSlideoutProps {
    showBidFilesSlideout: boolean;
    noSimulationSelected: () => boolean;
    appliedBidFiles: BidFile[];
}
const BidFilesSlideout = ({ showBidFilesSlideout, appliedBidFiles }: BidFilesSlideoutProps) => {
    const dispatch = useDispatch();
    const { odpt4185BidConfigUpdate } = useFlags();
    const [searchText, updateSearchText] = useState<string | null>(null);
    const [selectedBidFiles, setSelectedBidFiles] = useState<BidFile[]>([]);
    const { createSimulationOnEdit } = useSimulationHook();
    const { showLoader } = useLoadingHook();

    const { appliedBidFilesIds, allBidFiles } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );
    const { base_run, compare_run } = useParams<useParamsProps>();
    const [bidsNoChecks, setBidsNoChecks] = useState([]); // the array of bid objects from the backend
    const [bids, setBids] = useState<BidFile[]>([]); // array of bid objects with an additional parameter "isChecked" that helps with handling the checkboxes
    const [bidGroups, setBidGroups] = useState<BidGroup[]>([]);
    const [expanded, onExpand] = useState('all');
    const history = useHistory();
    const [openBidConfig, setOpenBidConfig] = useState(false);

    const currReportId = base_run || compare_run;
    useEffect(() => {
        setSelectedBidFiles(appliedBidFiles.map((bf: BidFile) => bf));
    }, [appliedBidFiles, showBidFilesSlideout]);

    useEffect(() => {
        setBidsNoChecks(
            allBidFiles.filter((bidConfig: BidFile) => bidConfig.status === REPORT_STATUS.COMPLETED)
        );
    }, [allBidFiles]);
    const getBidsNoChecks = async () => {
        dispatch(getAllBids());
    };
    const getBidGroups = async () => {
        dispatch(updateNetworkView({ loadingGetBidGroups: true }));
        try {
            const {
                data: { results }
            } = await API.get(`bidding/groups/`);
            setBidGroups(results);
        } catch (error) {
            console.warn('Could not fetch bid groups ' + error);
        }
        dispatch(updateNetworkView({ loadingGetBidGroups: false }));
    };

    const toggleCheckboxInList = (checked: boolean, id: number) => {
        const _bids = bids.map((bid) => {
            if (id === bid.id) {
                bid.isChecked = checked;
            }
            return bid;
        });
        setBids(_bids);
    };
    const handleCheckedSelectedBid = (bidFileId: number, event: any) => {
        if (!event.target.checked) {
            setSelectedBidFiles(
                selectedBidFiles.filter((bidFile: any) => bidFile.id !== bidFileId)
            );
            toggleCheckboxInList(event.target.checked, bidFileId);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await getBidsNoChecks();
            await getBidGroups();
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setBids([...bidsNoChecks]);
    }, [bidsNoChecks]);

    useEffect(() => {
        setBids((bids) =>
            bids.map((bid: BidFile) => {
                if (appliedBidFilesIds.includes(bid.id)) {
                    return { ...bid, isChecked: true };
                } else {
                    return { ...bid, isChecked: false };
                }
            })
        );
    }, [appliedBidFilesIds, bidsNoChecks, showBidFilesSlideout]);

    let filteredBids: any[] = [];
    if (searchText) {
        const search = (searchText || '').toLowerCase();
        // TODO simplify the group name search
        filteredBids = bids.filter(
            ({ file_name, group }) =>
                (file_name || '').toLowerCase().includes(search) ||
                bidGroups
                    .find((g) => g.id === group?.id)
                    ?.name.toLowerCase()
                    .includes(search)
        );
    } else {
        filteredBids = bids.filter(({ group }) => ['all', group?.id].includes(expanded));
    }

    const onViewGroup: (groupId: any) => void = (groupId = 'all') => {
        updateSearchText(null);
        onExpand(groupId);
    };

    const onChecked = (row: BidFile, event: any) => {
        if (event.target.checked) {
            setSelectedBidFiles([...selectedBidFiles, row]);
        } else {
            setSelectedBidFiles(selectedBidFiles.filter((bid: any) => bid.id !== row.id));
        }
        toggleCheckboxInList(event.target.checked, row.id);
    };
    const selectAllItemsInGroup = (groupId: any) => {
        let bidsToAddToSelection: BidFile[] = [];
        if (groupId === 'all') {
            bids.forEach((bid) => {
                if (!selectedBidFiles.map((selectedBid: any) => selectedBid.id).includes(bid.id)) {
                    bidsToAddToSelection.push(bid);
                }
            });
            setSelectedBidFiles([...selectedBidFiles, ...bidsToAddToSelection]);
        } else {
            bids.filter((bid) => bid?.group?.id === groupId).forEach((bid) => {
                if (!selectedBidFiles.map((selectedBid: any) => selectedBid.id).includes(bid.id)) {
                    bidsToAddToSelection.push(bid);
                }
            });
            setSelectedBidFiles([...selectedBidFiles, ...bidsToAddToSelection]);
        }
    };
    const deselectAllItemsInGroup = (groupId: any) => {
        if (groupId === 'all') setSelectedBidFiles([]);
        else
            setSelectedBidFiles(
                selectedBidFiles.filter((bidFile: any) => bidFile.group?.id !== groupId)
            );
    };
    const toggleAllItemsInGroup = (e: React.ChangeEvent<HTMLInputElement>, groupId: any) => {
        if (e.target.checked) {
            selectAllItemsInGroup(groupId);
        } else {
            deselectAllItemsInGroup(groupId);
        }
        const _bids = bids;
        _bids.forEach((bid) => {
            if (bid?.group?.id === groupId) bid.isChecked = e.target.checked;
            else if (groupId === 'all') bid.isChecked = e.target.checked;
        });
        setBids(_bids);
    };

    const getFooterRibbonText = (selectedBidFiles: BidFile[]) => {
        switch (selectedBidFiles?.length) {
            case 1:
                const bidFile = bids.find((b) => b.id === selectedBidFiles[0]?.id);
                return bidFile?.file_name || '';
            case 0:
                return 'No Bids Applied';
            default:
                return `(${selectedBidFiles?.length}) Selected`;
        }
    };

    const renderFooter = () => {
        const handleApplyButton = async () => {
            FS.event('Network View_Apply Bids Slideout_Click Save Button', {
                reportLens: selectedBidFiles.length
            });
            dispatch(updateNetworkView({ showBidFilesSlideout: false }));
            showLoader(true);
            const simulationId = await createSimulationOnEdit();
            if (!simulationId) return;

            await dispatch(
                updateAppliedBids(
                    selectedBidFiles.map((file: any) => file.id),
                    simulationId
                )
            );
            await dispatch(getConfigurationActions(currReportId, simulationId));
            showLoader(false);
        };

        return (
            <ODFooterActionBar
                primaryButtonText="Save Changes"
                handlePrimaryButton={handleApplyButton}
                primaryButtonEndIcon={
                    <SaveAltSharpIcon sx={{ color: theme.palette.neutral.white }} />
                }
                primaryButtonStyle={selectedBidFiles?.length > 0 ? { width: '196px' } : null}
                secondaryButtonText={selectedBidFiles?.length === 1 ? 'View File' : undefined}
                handleSecondaryButton={
                    selectedBidFiles?.length === 1
                        ? () =>
                              odpt4185BidConfigUpdate
                                  ? history.push(`/bidding/${selectedBidFiles[0]?.id}`)
                                  : setOpenBidConfig(true)
                        : undefined
                }
                secondaryButtonEndIcon={
                    selectedBidFiles?.length === 1 ? (
                        <VisibilityIcon fill={theme.palette.neutral.white} />
                    ) : undefined
                }
                secondaryButtonStyle={selectedBidFiles?.length === 1 ? { width: '196px' } : null}
                sx={selectedBidFiles?.length === 1 ? { paddingY: '16px' } : null}
                ribbonIcon={
                    selectedBidFiles?.length > 1 ? (
                        <LibraryAddCheckSharpIcon sx={{ color: theme.palette.neutral.white }} />
                    ) : (
                        <InsertDriveFile
                            sx={{
                                opacity: selectedBidFiles?.length === 0 ? 0.5 : 1
                            }}
                            fill={theme.palette.neutral.white}
                        />
                    )
                }
                ribbonText={getFooterRibbonText(selectedBidFiles)}
            />
        );
    };

    return (
        <Drawer
            anchor={'right'}
            open={showBidFilesSlideout}
            style={{ zIndex: 1200 }}
            onClose={() => dispatch(updateNetworkView({ showBidFilesSlideout: false }))}
            sx={{ width: '336px' }}>
            <SlideoutContainer>
                <StyledHeader>
                    <Label startIcon={<InsertDriveFile />} text="Apply Bid(s)" fontWeight={600} />
                    <StyledHeaderActions>
                        <Box>
                            <IconButton
                                size="large"
                                onClick={() =>
                                    dispatch(updateNetworkView({ showBidFilesSlideout: false }))
                                }>
                                <CloseIcon sx={{ color: theme.palette.neutral.black }} />
                            </IconButton>
                        </Box>
                    </StyledHeaderActions>
                </StyledHeader>
                <StyledBody>
                    <SearchContainer searchText={searchText} updateSearchText={updateSearchText} />
                    <div
                        style={{
                            padding: 0,
                            margin: `0 -16px 0 0px`,
                            height: `calc(100vh - ${selectedBidFiles?.length === 1 ? 270 : 220}px)`,
                            overflow: 'auto'
                        }}>
                        {searchText ? (
                            <BidFileListTable
                                groupId={0}
                                rows={filteredBids}
                                onChecked={onChecked}
                                style={{ maxHeight: `calc(100vh - 270px)` }}
                                canSelectAll={false}
                                displayGroupLabels={true}
                            />
                        ) : (
                            <>
                                <SelectedBidsAccordion
                                    id="selected-bids-info"
                                    name="Selected Bid File(s)"
                                    onExpand={() => {}}
                                    onCheckedSelection={handleCheckedSelectedBid}
                                    selectedBidFiles={selectedBidFiles}
                                />
                                <BidGroupList
                                    bids={bids}
                                    groups={bidGroups}
                                    items={filteredBids}
                                    onExpand={onViewGroup}
                                    onChecked={onChecked}
                                    expanded={expanded}
                                    showFooter={true}
                                    canSelectAll={true}
                                    toggleAllItemsInGroup={toggleAllItemsInGroup}
                                />
                            </>
                        )}
                    </div>
                    <FooterWrapper>{renderFooter()}</FooterWrapper>
                </StyledBody>
            </SlideoutContainer>
            {openBidConfig && (
                <EditBidConfigurationTable
                    open={openBidConfig}
                    setOpenBidConfig={setOpenBidConfig}
                    bidConfigId={selectedBidFiles[0].id}
                />
            )}
        </Drawer>
    );
};

export default BidFilesSlideout;
