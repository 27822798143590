import { CellError, DataRow, TableErrors } from 'shared/ODTable/ODTableTypes';
import {
    ADD_BID_CONFIG_TABLE_ERROR,
    CLEAR_BID_CONFIG_TABLE_ERROR,
    BID_CONFIG_SUCCESS,
    BID_CONFIG_FAIL,
    UPDATE_BID_REDUCER,
    RESET_MODAL_MODE,
    RESET_GROUP_SLIDE_OUT,
    RESET_BID_MANAGER_TABLE,
    BID_CONFIG_LOCATIONS_SUCCESS,
    OPEN_DUP_BID_FILE,
    BID_CONFIG_BASE_NETWORK_REPORTS_SUCCESS
} from '../constants/actionTypes';
import { MODAL_TYPE, SLIDE_OUT_TYPE } from 'constants/bidding';
import { CellEdit } from 'shared/ODTable/ODTableTypes';
import { PREFERRED_RATE_TYPE } from 'constants/settings';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';

interface BiddingState {
    actionsAwaitingConfigurationCreation: CellEdit[];
    showTableSettings: boolean;
    visibleColumnIdsAssociatedReports: string[];
    currUploadingBidConfigId: string;
    allBidFiles: DataRow[];
    allBidAssociatedReports: DataRow[];
    allGroups: DataRow[];
    currSelectedGroups: (number | string | object)[];
    error: any;
    slideoutMode: { mode: string; data: any };
    modalMode: {
        mode: MODAL_TYPE;
        data: any;
    };
    bidFileManagerSelectedConfigIds: any[];
    bidConfigColumnFormatSelections: {
        [dataCategory: string]: string;
    };
    bidConfigTableDataErrors: TableErrors;
    showTableDataErrorAlert: boolean;
    defaultRateType: PREFERRED_RATE_TYPE | any;
    openBidConfigSettingSlideout: boolean;
    openRunBidAnalysesSlideout: boolean;
    bidConfigSetting: {
        time_aggregation: string;
        zip_code_aggregation: number;
    };
    bidConfigLocations: any[];
    openDupBidFile: boolean;
    baseAndNetworkReports: any[];
    bidAnalysesRuns: any[];
    columnFilters: ColumnFiltersState;
    sortingState: SortingState;
}
interface BiddingAction {
    type: string;
    payload: {
        bidLanes?: [];
        showTableSettings?: boolean;
        visibleColumnIdsAssociatedReports: string[];
        currUploadingBidConfigId: string;
        errors: CellError[];
        bidConfigLocations?: any[];
        baseAndNetworkReports?: [];
    };
    error?: any;
}
const initialState: BiddingState = {
    openDupBidFile: false,
    actionsAwaitingConfigurationCreation: [],
    bidConfigTableDataErrors: {},
    error: null,
    showTableSettings: false,
    visibleColumnIdsAssociatedReports: [],
    currUploadingBidConfigId: '',
    allBidFiles: [],
    allBidAssociatedReports: [],
    allGroups: [],
    currSelectedGroups: [],
    slideoutMode: { mode: SLIDE_OUT_TYPE.NOSHOW, data: {} },
    modalMode: {
        mode: MODAL_TYPE.NOSHOW,
        data: []
    },
    bidFileManagerSelectedConfigIds: [],
    bidConfigColumnFormatSelections: {
        rate: 'rate_per_mile'
    },
    showTableDataErrorAlert: false,
    defaultRateType: null,
    openBidConfigSettingSlideout: false,
    openRunBidAnalysesSlideout: false,
    bidConfigSetting: {
        time_aggregation: 'city-state',
        zip_code_aggregation: 5
    },
    bidConfigLocations: [],
    baseAndNetworkReports: [],
    bidAnalysesRuns: [],
    columnFilters: [],
    sortingState: []
};

const addTableErrors = (state: BiddingState, action: BiddingAction) => {
    const { errors } = action.payload;
    const updatedErrorsObject = { ...state.bidConfigTableDataErrors };
    errors.forEach((errorEntry: CellError) => {
        const { rowId, columnId } = errorEntry;
        if (!updatedErrorsObject[rowId]) {
            updatedErrorsObject[rowId] = [];
        }
        updatedErrorsObject[rowId].push({ rowId, columnId });
    });
    return {
        ...state,
        bidConfigTableDataErrors: updatedErrorsObject
    };
};

const clearTableErrors = (state: BiddingState, action: BiddingAction) => {
    const { errors } = action.payload;
    const updatedTableErrors = { ...state.bidConfigTableDataErrors };

    errors.forEach((error: CellError) => {
        const { rowId, columnId } = error;
        if (updatedTableErrors[rowId]) {
            updatedTableErrors[rowId] = updatedTableErrors[rowId].filter(
                (err) => err.columnId !== columnId
            );
            if (updatedTableErrors[rowId].length === 0) {
                delete updatedTableErrors[rowId];
            }
        }
    });

    return {
        ...state,
        bidConfigTableDataErrors: updatedTableErrors
    };
};

const updateBiddingState = (state: BiddingState, action: BiddingAction) => {
    return {
        ...state,
        ...action.payload
    };
};

const bidConfigFail = (state: BiddingState, action: BiddingAction) => {
    return {
        ...state,
        error: action.error
    };
};

const bidConfigSuccess = (state: BiddingState, action: BiddingAction) => {
    return { ...state, ...action };
};

const updateLocations = (state: BiddingState, action: BiddingAction) => {
    return {
        ...state,
        ...action
    };
};
const updateBaseAndNetworkReports = (state: BiddingState, action: BiddingAction) => {
    return {
        ...state,
        ...action
    };
};

const biddingReducer = (state = initialState, action: BiddingAction) => {
    switch (action.type) {
        case UPDATE_BID_REDUCER:
        case RESET_MODAL_MODE:
        case RESET_BID_MANAGER_TABLE:
        case RESET_GROUP_SLIDE_OUT: {
            return updateBiddingState(state, action);
        }
        case BID_CONFIG_SUCCESS:
            return bidConfigSuccess(state, action);
        case BID_CONFIG_FAIL:
            return bidConfigFail(state, action);
        case ADD_BID_CONFIG_TABLE_ERROR:
            return addTableErrors(state, action);
        case CLEAR_BID_CONFIG_TABLE_ERROR:
            return clearTableErrors(state, action);
        case BID_CONFIG_LOCATIONS_SUCCESS:
            return updateLocations(state, action);
        case OPEN_DUP_BID_FILE:
            return updateBiddingState(state, action);
        case BID_CONFIG_BASE_NETWORK_REPORTS_SUCCESS:
            return updateBaseAndNetworkReports(state, action);
        default:
            return state;
    }
};

export default biddingReducer;
