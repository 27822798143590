import React, { CSSProperties } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Theme,
    FilledInput,
    Box,
    InputAdornment,
    Input,
    Typography,
    FormHelperText
} from '@mui/material';
import { SxProps } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import palette from 'theme/palette';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Remove, Add } from '@mui/icons-material';
import theme from 'theme';

interface ODDatePickerProps {
    value: string | number | null | undefined;
    label?: string;
    name?: string;
    onChange: (value: string | null, keyboardInputValue?: string | undefined) => void;
    props?: any;
    sx?: SxProps<Theme>;
    dataTestId?: string;
}

export const ODDatePicker = ({
    value,
    name,
    label = '',
    dataTestId = '',
    sx,
    onChange,
    ...props
}: ODDatePickerProps) => {
    return (
        <DatePicker
            components={{
                OpenPickerIcon: CalendarTodayIcon
            }}
            {...props}
            onChange={onChange}
            value={value}
            renderInput={(params) => (
                <>
                    <Box
                        component={Typography}
                        sx={{ fontSize: '12px', color: palette.neutral.neutral6 }}>
                        {label}
                    </Box>
                    <TextField
                        id={name}
                        data-testid={dataTestId}
                        variant="filled"
                        sx={{
                            height: '40px',
                            input: {
                                margin: '4px 0 0',
                                padding: '10px 16px 10px 16px',
                                backgroundColor: 'none',
                                textOverflow: 'ellipsis'
                            },
                            backgroundColor: 'white',
                            borderRadius: '0px',
                            svg: {
                                color: palette.neutral.black,
                                fontSize: '16px'
                            },
                            paddingBottom: '16px',
                            width: '100%',
                            '& .MuiInputBase-input': {
                                padding: '10px 16px',
                                fontSize: '14px'
                            },
                            '& .MuiButtonBase-root': {
                                margin: 0,
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: palette.neutral.black
                            },
                            '& .MuiFilledInput-root': {
                                backgroundColor: 'transparent',
                                borderBottom: palette.neutral.neutral2,
                                paddingRight: 0,
                                borderRadius: '0px',
                                '&:hover': {
                                    backgroundColor: palette.neutral.neutral2
                                },
                                '&:hover:not(.Mui-disabled):before': {
                                    borderBottom: palette.neutral.neutral2
                                },
                                '&.Mui-focused': {
                                    background: palette.ODLightBlueNeutral.lightBlue1
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: palette.neutral.black
                                }
                            },
                            ...sx
                        }}
                        name={name}
                        fullWidth
                        {...params}
                    />
                </>
            )}
        />
    );
};

export type SelectChangeEvent<T = string> =
    | (Event & { target: { value: T; name: string } })
    | React.ChangeEvent<HTMLInputElement>;

interface ODSelectProps {
    title?: string;
    value: any;
    name?: string;
    itemList: Array<{
        value: any;
        name: string | number | object | null;
        disabled?: boolean;
        id?: number | string;
    }>;
    onChange:
        | ((event: SelectChangeEvent<string | number | null>, child: React.ReactNode) => void)
        | undefined;
    onBlur?: React.FocusEventHandler<any>;
    onClose?: (event: React.SyntheticEvent) => void;
    onFocus?: React.FocusEventHandler<any>;
    onOpen?: (event: React.SyntheticEvent) => void;
    props?: any;
    disabled?: boolean;
    dataTestId?: string;
    sx?: SxProps<Theme>;
    displayEmpty?: boolean;
    placeholder?: string;
    maxHeight?: number | string;
    listItemExtras?: {
        menuStyleExtrasFn: (id: string | number | undefined) => CSSProperties;
        iconFn: (id: string | number | undefined) => JSX.Element | null;
    };
}

export const ODSelect = ({
    title,
    name,
    value,
    onChange,
    dataTestId = '',
    itemList = [],
    displayEmpty,
    placeholder,
    maxHeight,
    listItemExtras,
    sx,
    disabled,
    ...props
}: ODSelectProps) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            sx: {
                maxHeight: maxHeight ?? ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 392,
                borderRadius: 0
            }
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
        },
        variant: 'menu',
        sx: {
            maxHeight: '16rem',
            fontSize: '14px',
            '&& .Mui-selected': {
                backgroundColor: palette.ODLightBlueNeutral.lightBlue1
            }
        },
        MenuListProps: {
            style: {
                boxShadow: 'none'
            },
            sx: {
                '&.MuiList-root.MuiMenu-list': {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        }
    };

    return (
        <FormControl variant="standard" sx={{ m: 0, width: '100%', ...sx }}>
            <Box
                component={Typography}
                id={`${name}-od-select-inputlabel`}
                sx={{ fontSize: '12px', color: palette.neutral.neutral6 }}>
                {title}
            </Box>
            <Select
                disabled={disabled}
                displayEmpty={displayEmpty}
                variant="filled"
                labelId={`${name}-od-select-label`}
                id={`${name}-od-select`}
                {...props}
                IconComponent={KeyboardArrowDownIcon}
                onChange={onChange}
                name={name}
                inputProps={{ 'data-testid': dataTestId }}
                MenuProps={MenuProps as object}
                renderValue={(value) => {
                    let displayGroupName = itemList.filter((item) => item.value === value);

                    return (
                        <Box
                            sx={{
                                color: palette.neutral.black,
                                fontSize: '14px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>
                            {displayGroupName[0]?.name as any}
                        </Box>
                    );
                }}
                sx={{
                    marginTop: '4px',
                    height: '40px',
                    borderRadius: '0px',
                    fontSize: '14px',
                    backgroundColor: palette.white,
                    '& .MuiInputBase-input': {
                        padding: '10px 16px',
                        fontSize: '14px',
                        width: '100%',
                        color: palette.neutral.neutral6,
                        '&:hover': {
                            color: palette.neutral.black
                        },
                        '&:active': {
                            color: palette.neutral.black,
                            '.MuiSvgIcon-root': {
                                color: palette.neutral.black
                            }
                        }
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        border: 'none'
                    },
                    '&:hover .MuiSvgIcon-root': {
                        color: palette.neutral.black
                    },
                    '&:valid .MuiSvgIcon-root': {
                        color: palette.neutral.black
                    },
                    '&.Mui-focused': {
                        '& .MuiInputBase-input': {
                            color: palette.neutral.black
                        },
                        '& .MuiSvgIcon-root': {
                            color: palette.neutral.black
                        }
                    },
                    '& .MuiSvgIcon-root': {
                        right: 0,
                        marginRight: '12px',
                        '&:active': {
                            color: palette.neutral.black
                        }
                    }
                }}
                value={value}>
                {placeholder && (
                    <MenuItem
                        disabled
                        value=""
                        sx={{ height: '40px', backgroundColor: palette.neutral.neutral1 }}>
                        <Box
                            sx={{
                                fontSize: '14px',
                                color: palette.neutral.neutral6
                            }}>
                            {placeholder}
                        </Box>
                    </MenuItem>
                )}

                {itemList?.map(({ value, name, disabled = false, id }) => {
                    return (
                        <MenuItem
                            disabled={disabled}
                            key={value}
                            value={value}
                            sx={{
                                width: '392px',
                                height: '40px',
                                fontWeight: 400,
                                backgroundColor: palette.neutral.neutral1,
                                '&:hover': {
                                    backgroundColor: palette.neutral.neutral2
                                },
                                ...(listItemExtras?.menuStyleExtrasFn?.(id) || {})
                            }}>
                            {listItemExtras?.iconFn?.(id)}
                            <Box
                                sx={{
                                    fontSize: '14px',
                                    width: '390px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}>
                                {name as any}
                            </Box>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

interface ODTextFieldProps {
    label: string;
    dataTestId?: string;
    value?: string | number | null;
    defaultValue?: string | number | null;
    name?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<any>;
    onClose?: (event: React.SyntheticEvent) => void;
    onFocus?: React.FocusEventHandler<any>;
    onOpen?: (event: React.SyntheticEvent) => void;
    placeholder?: string;
    errorText?: string | null;
    infoText?: string | null;
    helperText?: string | null;
    props?: any;
    autoFocus?: boolean;
    sx?: SxProps<Theme>;
    hasAsterisk?: boolean;
    disabled?: boolean;
    customInputProps?: any;
}

export const ODTextField = ({
    value,
    onChange,
    name,
    label,
    placeholder,
    dataTestId = '',
    errorText,
    infoText,
    helperText,
    autoFocus = false,
    defaultValue,
    hasAsterisk,
    customInputProps,
    disabled,
    sx,
    ...props
}: ODTextFieldProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...sx }}>
            <InputLabel
                id={name}
                aria-label={name}
                aria-labelledby={name}
                sx={{ fontSize: '12px', color: palette.neutral.neutral6, display: 'flex' }}>
                {label}
                {hasAsterisk && (
                    <Typography sx={{ color: palette.semantic.semanticRed, marginLeft: '4px' }}>
                        *
                    </Typography>
                )}
            </InputLabel>
            <FilledInput
                id={name}
                {...props}
                disabled={disabled}
                error={Boolean(errorText)}
                autoFocus={autoFocus}
                inputProps={{ 'data-testid': dataTestId, ...customInputProps }}
                sx={{
                    height: '40px',
                    borderBottom: '1px solid var(--neutral-100)',
                    fontSize: '14px',
                    input: {
                        margin: '4px 0 0',
                        padding: '10px 16px 10px 16px',
                        backgroundColor: 'none',
                        textOverflow: 'ellipsis'
                    },
                    backgroundColor: 'white',
                    borderRadius: '0px',
                    '&.Mui-error': {
                        color: palette.semantic.semanticRed,
                        borderBottom: palette.semantic.semanticRed
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        border: 'none'
                    },
                    '&.Mui-focused': {
                        background: palette.ODLightBlueNeutral.lightBlue1
                    }
                }}
                placeholder={placeholder}
                fullWidth
                name={name}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
            />
            {errorText && (
                <FormHelperText
                    sx={{ color: theme.palette.semantic.semanticRed, fontStyle: 'italic' }}
                    id="component-error-text">
                    {errorText}
                </FormHelperText>
            )}
            {infoText && (
                <FormHelperText
                    sx={{ color: theme.palette.semantic.semanticRed, fontStyle: 'italic' }}
                    id="component-error-text">
                    {infoText}
                </FormHelperText>
            )}
            {helperText && (
                <FormHelperText sx={{ textAlign: 'right' }} id="helper-text">
                    {helperText}
                </FormHelperText>
            )}
        </Box>
    );
};

interface ODInputWithAdornmentsProps {
    id?: string;
    value: string | number | null;
    inputLabel?: string;
    handleRemoveOnClick?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    handleAddOnClick?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    disabled?: boolean;
    disabledMessage?: string;
    sx?: SxProps<Theme>;
    inputComponent?: any;
    errorText?: string | null;
}

export const ODInputWithAdornments = ({
    id,
    inputLabel,
    handleRemoveOnClick,
    handleAddOnClick,
    onChange,
    value,
    sx,
    inputComponent = 'input',
    disabled = false,
    disabledMessage,
    errorText
}: ODInputWithAdornmentsProps) => {
    return (
        <>
            <InputLabel
                id={`id-${inputLabel}`}
                sx={{ fontSize: '12px', fontWeight: 400, marginBottom: '2px' }}>
                {inputLabel}
            </InputLabel>
            <FormControl>
                <Input
                    id={id}
                    error={Boolean(errorText)}
                    onKeyDown={(e) => {
                        ['-', '_', '+'].includes(e?.key) && e?.preventDefault();
                    }}
                    placeholder="0"
                    type="number"
                    sx={{
                        minWidth: '392px',
                        height: '40px',
                        marginRight: '32px',
                        '&.Mui-error': {
                            color: palette.semantic.semanticRed,
                            borderBottom: palette.semantic.semanticRed
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            border: 'none'
                        },
                        '&:hover': {
                            backgroundColor: !onChange ? 'transparent' : palette.neutral.neutral2
                        },
                        '&.Mui-focused': {
                            background: palette.ODLightBlueNeutral.lightBlue1
                        },
                        '& .MuiFilledInput-root': {
                            border: 'none',
                            overflow: 'hidden',
                            borderRadius: 4,
                            backgroundColor: theme.palette.neutral.neutral1,
                            transition: theme.transitions.create([
                                'border-color',
                                'background-color',
                                'box-shadow'
                            ])
                        },

                        input: {
                            fontSize: '14px',
                            '&[type=number]': {
                                MozAppearance: 'textfield'
                            },
                            '&::-webkit-outer-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            },
                            '&::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            }
                        },
                        ...sx
                    }}
                    startAdornment={
                        <InputAdornment
                            position="start"
                            disablePointerEvents={!handleRemoveOnClick}>
                            <Remove
                                sx={{ cursor: 'pointer', marginLeft: '16px' }}
                                onClick={(e) =>
                                    !disabled &&
                                    handleRemoveOnClick &&
                                    handleRemoveOnClick(e as any)
                                }
                            />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="start" disablePointerEvents={!handleAddOnClick}>
                            <Add
                                sx={{ cursor: 'pointer', marginRight: '8px' }}
                                onClick={(e) =>
                                    !disabled && handleAddOnClick && handleAddOnClick(e as any)
                                }
                            />
                        </InputAdornment>
                    }
                    onChange={(e) => !disabled && onChange && onChange(e)}
                    value={value}
                    inputProps={{
                        'data-testid': `${inputLabel?.split(' ').join('-')}`,
                        maxLength: 5,
                        step: '1',
                        style: {
                            padding: '10px',
                            textAlign: 'center'
                        }
                    }}
                    disabled={disabled}
                    inputComponent={inputComponent}
                />
                {errorText && (
                    <FormHelperText
                        sx={{
                            color: theme.palette.semantic.semanticRed,
                            fontStyle: 'italic',
                            marginLeft: '0 !important'
                        }}
                        id="component-error-text">
                        {errorText}
                    </FormHelperText>
                )}
            </FormControl>
            {disabled && <Typography>{disabledMessage}</Typography>}
        </>
    );
};
