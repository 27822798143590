import React from 'react';
import {
    Avatar,
    IconButton,
    Dialog,
    Box,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@mui/material';
import theme from 'theme';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles/';
import ClearIcon from '@mui/icons-material/Clear';

//TODO dialog to include transition update with new ODDialog

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        minWidth: '456px'
    },
    iconClose: {
        width: 40,
        height: 40,
        color: theme.palette.neutral.black,
        backgroundColor: theme.palette.neutral.neutral1,
        '&:hover': {
            backgroundColor: theme.palette.neutral.neutral2
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const AlertModal = ({
    open = true,
    title = 'Editing & Simulation Management Disabled in Compare Mode',
    description = 'To use editing functionality and manage new or available simulations close out selected compared report. ',
    onAction
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{ className: classes.alertContainer }}
            TransitionProps={{ tabIndex: '', tabindex: '' }}
            onClose={() => onAction(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <Box sx={{ padding: '32px' }}>
                <DialogTitle id="alert-dialog-slide-title" sx={{ padding: '0 0 32px 0' }}>
                    <Box
                        sx={{ display: 'flex' }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Box
                            sx={{
                                color: theme.palette.neutral.black,
                                fontSize: '18px',
                                maxWidth: '278px'
                            }}>
                            {title}
                        </Box>

                        <Avatar className={clsx(classes.iconClose, 'network-manager-close')}>
                            <IconButton
                                color="inherit"
                                size="large"
                                onClick={() => onAction(false)}>
                                <ClearIcon
                                    style={{
                                        color: theme.palette.neutral.black,
                                        cursor: 'pointer'
                                    }}
                                />
                            </IconButton>
                        </Avatar>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ padding: 0 }}>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        sx={{
                            color: theme.palette.neutral.black,
                            fontSize: '14px'
                        }}>
                        {description}
                    </DialogContentText>
                </DialogContent>
            </Box>
        </Dialog>
    );
};
