import React from 'react';
import clsx from 'clsx';
import {
    Grid,
    Avatar,
    IconButton,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import theme from 'theme';
import { makeStyles } from '@mui/styles/';

//TODO dialog to include transition update with new ODDialog

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        minWidth: '350px'
    },
    iconClose: {
        width: 40,
        height: 40,
        color: theme.palette.neutral.black,
        backgroundColor: theme.palette.neutral.neutral1,
        '&:hover': {
            backgroundColor: theme.palette.neutral.neutral2
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const ConfirmationDialog = ({
    open = true,
    title = 'Comparing Disabled',
    description = 'Comparing has been disabled. Only Reports from the same Group can be compared. ',
    onAction
}) => {
    const classes = useStyles();
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{ className: classes.alertContainer }}
                TransitionProps={{ tabIndex: '', tabindex: '' }}
                onClose={() => onAction(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle
                    id="alert-dialog-slide-title"
                    style={{ padding: '36px 36px 36px 32px' }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item xs={11}>
                            <div style={{ color: theme.palette.neutral.black }}> {title}</div>
                        </Grid>
                        <Grid item xs={1}>
                            <Avatar
                                className={clsx(classes.iconClose, 'network-manager-close')}
                                onClick={() => onAction(false)}>
                                <IconButton color="inherit" size="large">
                                    <ClearIcon
                                        sx={{
                                            color: theme.palette.neutral.black,
                                            cursor: 'pointer'
                                        }}
                                    />
                                </IconButton>
                            </Avatar>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: '8px 32px' }}>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ color: theme.palette.neutral.black }}>
                        {description}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};
