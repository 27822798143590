/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Avatar, Checkbox } from '@mui/material';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import InfoMessage from 'shared/ODTable/InfoMessage';
import InsightsIcon from '@mui/icons-material/Insights';
import ClearIcon from '@mui/icons-material/Clear';
import theme from 'theme';

import {
    AccordionHeader,
    AvatarText,
    CustomAccordionSummary,
    CustomAccordion,
    CustomCollapse
} from './UploadedBidDataAccordion.styles';
import { ODIconButton, ODTextField } from 'shared';
import { useEffect, useState } from 'react';
import { BID_ANALYSIS_TOOLTIPS, BID_CONFIG_INFO_MESSAGES } from 'constants/bidding';
import { BUTTON_TYPE } from 'constants/colors';
import RateAdjustmentTabs from './RateAdjusmentTabs';
import {
    isDuplicateBidAnalysisRunName,
    isUniqueBidAnalysisRunName,
    isNullRatePerMile
} from 'utils/common.util';

const RateAdjustmentStudyAccordion = ({
    cardNumber,
    cardID,
    rateAdjAccordions,
    setRateAdjAccordions,
    setBidAnalysesRuns,
    bidAnalysesRuns,
    minRate,
    manualOpenOverride
}: {
    cardNumber: number;
    cardID: string;
    rateAdjAccordions: any;
    setRateAdjAccordions: any;
    setBidAnalysesRuns: any;
    bidAnalysesRuns: any;
    minRate: any;
    manualOpenOverride: { value: boolean };
}) => {
    const [inputErrorRun, setInputErrorRun] = useState<string | null>(null);
    const [inputErrorFinalizedRun, setInputErrorFinalized] = useState<string | null>(null);
    const [inputErrorAdjustments, setInputErrorAdjustments] = useState<string | null>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [bidAnalysisRunName, setBidAnalysisRunName] = useState<string>('');
    const [finalizedBidAnalysisRunName, setFinalizedBidAnalysisRunName] = useState<string>('');
    const [adjustments, setAdjustments] = useState<any>(null);
    const [tabValue, setTabValue] = useState(0);
    const [editOptions, setEditOptions] = useState([
        {
            id: 'percentage',
            displayedValue: ''
        },
        {
            id: 'fixed',
            displayedValue: ''
        }
    ]);

    const [openDrawer, setOpenDrawer] = useState({
        upload_bid: true,
        finalized_bid: true
    }) as any;
    const [manualOpen, setManualOpen] = useState<boolean>(manualOpenOverride.value);
    const uploadBidAccordionState = openDrawer['upload_bid'] as any;
    const finalizedBidAccordionState = openDrawer['finalized_bid'] as any;

    useEffect(() => {
        setManualOpen(manualOpenOverride.value);
    }, [manualOpenOverride]);

    const handleRemoveRateAdjItem = (cardID: string) => {
        const filteredItems = rateAdjAccordions
            .filter((el: any) => {
                return el.cardID !== cardID;
            })
            .map((card: any, idx: number) => {
                return {
                    ...card,
                    cardNumber: idx + 2
                };
            });
        setBidAnalysesRuns((current: any) => {
            const { [cardID]: _, ...rest } = current;
            return rest;
        });

        setRateAdjAccordions([...filteredItems]);
    };
    const updateApiData = (
        bidAnalysisRunName: string,
        checked: boolean,
        finalizedBidAnalysisRunName: string,
        adjustments: any
    ) => {
        setBidAnalysesRuns({
            ...bidAnalysesRuns,
            [cardID]: { bidAnalysisRunName, checked, finalizedBidAnalysisRunName, adjustments }
        });
    };

    useEffect(() => {
        // Handle disable Run button
        if (!checked && !inputErrorRun && !inputErrorAdjustments)
            setRateAdjAccordions((as: any) =>
                as.map((a: any) => (a.cardID === cardID ? { ...a, noErrors: true } : a))
            );
        else if (checked && !inputErrorRun && !inputErrorFinalizedRun && !inputErrorAdjustments)
            setRateAdjAccordions((as: any) =>
                as.map((a: any) => (a.cardID === cardID ? { ...a, noErrors: true } : a))
            );
        else
            setRateAdjAccordions((as: any) =>
                as.map((a: any) => (a.cardID === cardID ? { ...a, noErrors: false } : a))
            );
        if (adjustments) {
            if (adjustments.value === '')
                setRateAdjAccordions((as: any) =>
                    as.map((a: any) => (a.cardID === cardID ? { ...a, noErrors: false } : a))
                );
            else if (isNullRatePerMile(minRate, adjustments))
                setRateAdjAccordions((as: any) =>
                    as.map((a: any) => (a.cardID === cardID ? { ...a, noErrors: false } : a))
                );
        }
        // Auto Select checkbox if user enter a name
        if (finalizedBidAnalysisRunName) setChecked(true);

        // Handle field required
        if (!adjustments?.value) setInputErrorAdjustments('*Field Required');
        if (!bidAnalysisRunName) setInputErrorRun('*Field Required');
        if (checked && !finalizedBidAnalysisRunName) setInputErrorFinalized('*Field Required');
        else if (!checked) setInputErrorFinalized(null);
        updateApiData(bidAnalysisRunName, checked, finalizedBidAnalysisRunName, adjustments);
    }, [
        bidAnalysisRunName,
        checked,
        finalizedBidAnalysisRunName,
        inputErrorRun,
        inputErrorFinalizedRun,
        inputErrorAdjustments,
        adjustments
    ]);
    useEffect(() => {
        const isOpen =
            manualOpen ||
            rateAdjAccordions?.[rateAdjAccordions.length - 1]?.cardNumber === cardNumber;
        setOpenDrawer({ ...openDrawer, upload_bid: isOpen, finalized_bid: isOpen });
    }, [rateAdjAccordions, manualOpen]);
    useEffect(() => {
        setAdjustments((a: any) => ({
            ...a,
            type: editOptions[tabValue]?.id,
            value: editOptions[tabValue]?.displayedValue
        }));
        // NOTE: adding editOptions and tabValue to this useEffect dep list sets the adjustments to the wrong type
        // eslint-disable-next-line
    }, [tabValue]);

    // Handle Duplicate run names
    useEffect(() => {
        if (
            bidAnalysisRunName &&
            isDuplicateBidAnalysisRunName(bidAnalysesRuns, bidAnalysisRunName)
        )
            setInputErrorRun('File name already exists.');
        else if (bidAnalysisRunName) setInputErrorRun(null);
        if (
            (bidAnalysisRunName && bidAnalysisRunName === finalizedBidAnalysisRunName) ||
            isUniqueBidAnalysisRunName(bidAnalysesRuns, finalizedBidAnalysisRunName) > 1
        )
            setInputErrorFinalized('File name already exists.');
        else if (finalizedBidAnalysisRunName) setInputErrorFinalized(null);
    }, [bidAnalysisRunName]);
    useEffect(() => {
        if (
            finalizedBidAnalysisRunName &&
            isDuplicateBidAnalysisRunName(bidAnalysesRuns, finalizedBidAnalysisRunName)
        )
            setInputErrorFinalized('File name already exists.');
        else if (finalizedBidAnalysisRunName) setInputErrorFinalized(null);
        if (
            (bidAnalysisRunName && bidAnalysisRunName === finalizedBidAnalysisRunName) ||
            isUniqueBidAnalysisRunName(bidAnalysesRuns, bidAnalysisRunName) > 1
        )
            setInputErrorRun('File name already exists.');
        else if (bidAnalysisRunName) setInputErrorRun(null);
    }, [finalizedBidAnalysisRunName]);

    return (
        <Box sx={{ margin: '16px' }}>
            <CustomAccordion
                key={`rate-adjustment-study-accordion`}
                expanded={uploadBidAccordionState}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_e) => {
                    _e.stopPropagation();
                    setOpenDrawer({
                        ...openDrawer,
                        upload_bid: !uploadBidAccordionState,
                        finalized_bid: !uploadBidAccordionState
                    });
                    setManualOpen(!openDrawer.upload_bid);
                }}>
                <CustomAccordionSummary
                    expandIcon={
                        <KeyboardArrowDownSharp sx={{ color: theme.palette.neutral.black }} />
                    }
                    aria-controls="accordion-content"
                    id={`accordion`}>
                    <AccordionHeader>
                        <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                            <Avatar
                                sx={{
                                    bgcolor:
                                        inputErrorRun ||
                                        inputErrorFinalizedRun ||
                                        inputErrorAdjustments
                                            ? theme.palette.semantic.semanticRed
                                            : theme.palette.semantic.focusedBlue,
                                    width: 24,
                                    height: 24,
                                    marginRight: '16px'
                                }}>
                                <AvatarText>{cardNumber}</AvatarText>
                            </Avatar>
                            <Typography
                                style={{
                                    color: 'black',
                                    fontSize: '14px'
                                }}>
                                <b>Rate Adjustment Study</b>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <InfoMessage
                                sx={{
                                    marginRight: '8px',
                                    padding: '1.5px 0 0 0',
                                    color: theme.palette.black
                                }}
                                message={BID_ANALYSIS_TOOLTIPS.RATE_ADJUSTMENT_TOOLTIP}
                            />

                            <ODIconButton
                                sx={{ marginRight: 0, padding: '0 1px 0 0' }}
                                disableHover={true}
                                id={'delete'}
                                onClick={(_e) => {
                                    _e.stopPropagation();
                                    handleRemoveRateAdjItem(cardID);
                                }}
                                buttonType={BUTTON_TYPE.TRANSPARENT}
                                icon={<ClearIcon sx={{ fill: theme.palette.neutral.black }} />}
                            />
                        </Box>
                    </AccordionHeader>
                </CustomAccordionSummary>
            </CustomAccordion>
            <CustomCollapse
                in={uploadBidAccordionState}
                timeout="auto"
                unmountOnExit
                key={`select-base-network`}>
                <RateAdjustmentTabs
                    adjustments={adjustments}
                    setAdjustments={setAdjustments}
                    inputErrorAdjustments={inputErrorAdjustments}
                    setInputErrorAdjustments={setInputErrorAdjustments}
                    minRate={minRate}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    editOptions={editOptions}
                    setEditOptions={setEditOptions}
                />
                <Box sx={{ margin: '16px' }}>
                    <ODTextField
                        value={bidAnalysisRunName}
                        helperText={`${bidAnalysisRunName?.length}/50 Character Limit`}
                        errorText={inputErrorRun}
                        infoText={
                            bidAnalysisRunName.length >= 50 ? '*Maximum characters reached' : null
                        }
                        autoFocus={true}
                        // defaultValue={}
                        dataTestId="enter-bid-analysis-run-name"
                        label="Name Bid Analysis Run*"
                        onChange={(event) => {
                            setBidAnalysisRunName(event?.target?.value);
                            if (inputErrorRun) setInputErrorRun(null);
                        }}
                        name="saveBidAnalysisRun"
                        customInputProps={{ maxLength: 50 }}
                    />
                </Box>
                <Box sx={{ margin: '16px' }}>
                    <CustomAccordion
                        key={`finalized-bid-analysis-accordion`}
                        expanded={finalizedBidAccordionState}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e) => {
                            _e.stopPropagation();
                            setOpenDrawer({
                                ...openDrawer,
                                finalized_bid: !finalizedBidAccordionState
                            });
                        }}>
                        <CustomAccordionSummary
                            expandIcon={
                                <KeyboardArrowDownSharp
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`accordion-finalized-bid-analysis`}>
                            <AccordionHeader>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={checked}
                                        onChange={(e: any) => {
                                            setOpenDrawer({
                                                ...openDrawer
                                            });
                                            if (checked) setFinalizedBidAnalysisRunName('');
                                            setChecked(!checked);
                                        }}
                                        sx={{
                                            marginRight: '8px',
                                            marginLeft: '-8px'
                                        }}
                                    />
                                    <InsightsIcon
                                        fontSize="small"
                                        sx={{
                                            marginRight: '16px',
                                            color: theme.palette.black
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: 'black',
                                            fontSize: '14px'
                                        }}>
                                        <b>Generate Finalized Bid Analysis</b>
                                    </Typography>
                                </Box>
                            </AccordionHeader>
                        </CustomAccordionSummary>
                    </CustomAccordion>
                    <CustomCollapse
                        in={finalizedBidAccordionState}
                        timeout="auto"
                        unmountOnExit
                        key={`select-min-finalized-bid-analysis`}>
                        <Box sx={{ margin: '24px 16px 16px' }}>
                            <span>{BID_CONFIG_INFO_MESSAGES.FINALIZED_BID_ANALYSIS_MESSAGE}</span>
                        </Box>

                        <Box sx={{ margin: '16px' }}>
                            <ODTextField
                                value={finalizedBidAnalysisRunName}
                                errorText={inputErrorFinalizedRun}
                                infoText={
                                    finalizedBidAnalysisRunName.length >= 50
                                        ? '*Maximum characters reached'
                                        : null
                                }
                                autoFocus={false}
                                helperText={`${finalizedBidAnalysisRunName?.length}/50 Character Limit`}
                                dataTestId="enter-finalized-bid-analysis"
                                label="Name Finalized Bid Analysis Run*"
                                onChange={(event) => {
                                    setFinalizedBidAnalysisRunName(event?.target?.value);
                                    if (inputErrorFinalizedRun) setInputErrorFinalized(null);
                                }}
                                name="generateFinalizedBidAnalysis"
                                customInputProps={{ maxLength: 50 }}
                            />
                        </Box>
                    </CustomCollapse>
                </Box>
            </CustomCollapse>
        </Box>
    );
};

export default RateAdjustmentStudyAccordion;
