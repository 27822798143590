import * as Cluster from '@flowmap.gl/cluster';
import { isFeatureCollection } from '@flowmap.gl/core';
import React from 'react';
import FlowMapLayer from './flowmap';
import { isEqual } from 'lodash';

class ClusterLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clusteredLocations: null,
            aggregateFlows: null,
            clusterIndex: null,
            aggregateFlowsByZoom: null,
            customZoomLevel: 3,
            defaultLocation: [],
            viewState: null,
            flows: []
        };
        this.state = this.calculateClusterLevel(props);
        this.handleViewStateChange = this.handleViewStateChange.bind(this);
        this.calculateClusterLevel = this.calculateClusterLevel.bind(this);
        this.createCustomCluster = this.createCustomCluster.bind(this);
        this.getMyLocationCentroid = this.getMyLocationCentroid.bind(this);
        this.getMyFlowOriginId = this.getMyFlowOriginId.bind(this);
        this.getMyFlowDestId = this.getMyFlowDestId.bind(this);
        this.getMyFlowMagnitude = this.getMyFlowMagnitude.bind(this);
    }

    calculateClusterLevel(props) {
        const {
            flows,
            getLocationId,
            getLocationCentroid,
            getFlowOriginId,
            getFlowDestId,
            getFlowMagnitude
        } = props;

        let clusterLevels;
        const locations = isFeatureCollection(props.locations)
            ? props.locations.features
            : props.locations;
        const defaultLocation = locations.length > 0 ? locations[0].properties.centroid : [];

        if (props.clusterLevels) {
            clusterLevels = props.clusterLevels;
        } else {
            const getLocationWeight = Cluster.makeLocationWeightGetter(flows, {
                getFlowOriginId,
                getFlowDestId,
                getFlowMagnitude
            });
            clusterLevels = Cluster.clusterLocations(
                locations,
                { getLocationId, getLocationCentroid },
                getLocationWeight,
                {
                    makeClusterName: (id, numPoints) => `Cluster #${id} of ${numPoints} locations`
                }
            );
        }
        const clusterIndex = Cluster.buildIndex(clusterLevels);
        const aggregateFlowsByZoom = new Map();
        for (const zoom of clusterIndex.availableZoomLevels) {
            aggregateFlowsByZoom.set(
                zoom,
                clusterIndex.aggregateFlows(flows, zoom, {
                    getFlowOriginId,
                    getFlowDestId,
                    getFlowMagnitude
                })
            );
        }
        const maxZoom = Math.max.apply(null, clusterIndex.availableZoomLevels);
        const clusteredLocations = clusterIndex.getClusterNodesFor(maxZoom);
        const aggregateFlows = aggregateFlowsByZoom.get(maxZoom);

        return {
            clusteredLocations,
            aggregateFlows,
            clusterIndex,
            aggregateFlowsByZoom,
            defaultLocation,
            flows
        };
    }

    handleViewStateChange(viewState, autoCluster = false) {
        this.setState({ viewState });
        if (this.props.clusterStatus || autoCluster) {
            const { availableZoomLevels } = this.state.clusterIndex;
            const { zoom } = viewState;
            const clusterZoom = Cluster.findAppropriateZoomLevel(availableZoomLevels, zoom);
            const clusteredLocations = this.state.clusterIndex.getClusterNodesFor(clusterZoom);
            const aggregateFlows = this.state.aggregateFlowsByZoom.get(clusterZoom);
            this.setState({
                clusteredLocations,
                aggregateFlows
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.clusterStatus) {
            this.createCustomCluster();
        } else {
            if (this.state.viewState) {
                this.handleViewStateChange(this.state.viewState, true);
            }
        }
        if (nextProps.flows && this.state.flows && !isEqual(nextProps.flows, this.state.flows)) {
            this.setState(this.calculateClusterLevel(nextProps), () => {
                this.handleViewStateChange(this.state.viewState);
            });
        }
        if (
            this.state.customZoomLevel !== nextProps.customZoomLevel &&
            nextProps.customZoomLevel < 10 &&
            nextProps.customZoomLevel >= 0
        ) {
            this.createCustomCluster();
        }
    }

    createCustomCluster() {
        const { availableZoomLevels } = this.state.clusterIndex;
        if (availableZoomLevels.indexOf(this.props.customZoomLevel) === -1) {
            return;
        }
        const clusteredLocations = this.state.clusterIndex.getClusterNodesFor(
            this.props.customZoomLevel
        );
        const aggregateFlows = this.state.aggregateFlowsByZoom.get(this.props.customZoomLevel);
        this.setState({
            clusteredLocations,
            aggregateFlows,
            customZoomLevel: this.props.customZoomLevel
        });
    }

    getMyLocationCentroid(loc) {
        return loc ? loc.centroid : [];
    }
    getMyFlowOriginId(flow) {
        return flow.aggregate ? flow.origin : this.props.getFlowOriginId(flow);
    }
    getMyFlowDestId(flow) {
        return flow.aggregate ? flow.dest : this.props.getFlowDestId(flow);
    }

    getMyFlowMagnitude(flow) {
        return Cluster.isAggregateFlow(flow) ? flow.count : this.props.getFlowMagnitude(flow);
    }

    render() {
        const { animate, type, isAdmin, driversConfig, onSave } = this.props;
        const { clusteredLocations, aggregateFlows } = this.state;
        if (!clusteredLocations || !aggregateFlows) {
            return null;
        }
        return (
            <FlowMapLayer
                odpt4932ShowDriverIconMap={this.props.odpt4932ShowDriverIconMap}
                isReportDraft={this.props.isReportDraft}
                isAnimated={animate}
                locations={clusteredLocations}
                flows={aggregateFlows}
                createSimulationOnEdit={this.props.createSimulationOnEdit}
                getLocationId={(loc) => loc.id}
                getLocationCentroid={(loc) => this.getMyLocationCentroid(loc)}
                getFlowOriginId={(flow) => this.getMyFlowOriginId(flow)}
                getFlowDestId={(flow) => this.getMyFlowDestId(flow)}
                getFlowMagnitude={(flow) => this.getMyFlowMagnitude(flow)}
                type={type}
                isAdmin={isAdmin}
                onViewStateChange={this.handleViewStateChange}
                mapboxAccessToken={this.props.token}
                markerData={this.props.markerData || []}
                classes={this.props.classes}
                onAddDomicile={this.props.onAddDomicile}
                clearMap={this.props.clearMap}
                otherLayers={this.props.otherLayers}
                loadType={this.props.loadType}
                driversConfig={driversConfig}
                onSave={onSave}
                isReadOnly={this.props.isReadOnly}
                isAggregated={this.props.isAggregated}
                showSidebar={this.props.showSidebar}
                domicile_length={this.props.domicile_length}
                multiDriverToursToggle={this.props.multiDriverToursToggle}
                toggleDriverTours={this.props.toggleDriverTours}
                openEditDriverSlideout={this.props.openEditDriverSlideout}
                newDomicileSlideout={this.props.newDomicileSlideout}
            />
        );
    }
}

export default ClusterLayer;
