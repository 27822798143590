import { Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateLoadSource, updateFreightBonusApplied } from 'redux/actions';
import { RootState } from 'redux/reducers';
import {
    Accordion,
    AccordionSummary,
    Collapse,
    Typography,
    Box,
    Button,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DropdownOption from 'shared/DropdownOption/DropdownOption';
import { LOAD_SOURCE } from 'constants/network';
import { BONUS_TABLE_FILTER_TYPE } from 'constants/reports';
import { StyledInstructions } from 'shared/Styles';

//TODO hiding freight bonus until we consolidate the filters handling on both front and backends (this to be moved to filter slide out (3/1))

const SlideoutContainer = styled(Box)(() => ({
    width: '392px',
    padding: '0px 32px'
}));

const StyledHeader = styled(Box)(() => ({
    height: '112px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const StyledBody = styled(Box)(() => ({
    paddingTop: '16px'
}));

const StyledHeaderActions = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

interface DropdownOptionDetail {
    key: string;
    handleCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selected: boolean;
    locked: boolean;
    required: boolean;
    name: string;
}

interface NetworkTableSettingsSlideoutProps {
    columns: any[];
    lockedColumnsIds: string[];
    showTableSettingsSlideout: boolean;
    setShowTableSettingsSlideout: (state: boolean) => void;
    resetColumnHeaderSelections: any;
    updateColumnHeaderSelections: any;
    columnLimit: number | null;
    hiddenColumns: string[];
    tableDataType: string;
}
const NetworkTableSettingsSlideout = ({
    columns,
    lockedColumnsIds,
    showTableSettingsSlideout,
    setShowTableSettingsSlideout,
    resetColumnHeaderSelections,
    updateColumnHeaderSelections,
    columnLimit,
    hiddenColumns,
    tableDataType
}: NetworkTableSettingsSlideoutProps) => {
    const [expandedSections, setExpandedSections] = useState<string[]>([]);
    const { loadSource } = useSelector((state: RootState) => state.ReportsReducers);
    const dispatch = useDispatch();

    useEffect(() => {
        if (showTableSettingsSlideout)
            setExpandedSections(['loadSource', 'columnHeaders', 'freightBonus']);
    }, [showTableSettingsSlideout]);
    const reset = () => {
        resetColumnHeaderSelections(tableDataType);
        dispatch(updateLoadSource(LOAD_SOURCE.ALL));
    };

    const handleCheckboxByType = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        let newValue;

        if (e.target.checked) {
            newValue = LOAD_SOURCE.ALL;
        } else {
            if (type === 'bonus') {
                newValue =
                    e.target.value === BONUS_TABLE_FILTER_TYPE.BONUS_NOT_APPLIED
                        ? BONUS_TABLE_FILTER_TYPE.BONUS_APPLIED
                        : BONUS_TABLE_FILTER_TYPE.BONUS_NOT_APPLIED;
            } else {
                newValue =
                    e.target.value === LOAD_SOURCE.BID ? LOAD_SOURCE.NETWORK : LOAD_SOURCE.BID;
            }
        }

        type === 'bonus'
            ? dispatch(updateFreightBonusApplied(newValue))
            : dispatch(updateLoadSource(newValue));
    };

    const isHidden = (column: any) => {
        return hiddenColumns.includes(column.visibilityKey);
    };
    const handleColumnHeaderCheckbox = (e: any, column: any) => {
        if (isHidden(column) && e.target.checked) {
            hiddenColumns.splice(hiddenColumns.indexOf(column.visibilityKey), 1);
        } else if (!isHidden(column) && !e.target.checked) {
            hiddenColumns.push(column.visibilityKey);
        }
        updateColumnHeaderSelections(hiddenColumns, tableDataType);
    };

    const handleClose = () => {
        setShowTableSettingsSlideout(false);
    };

    const sortByHeaderText = (a: DropdownOptionDetail, b: DropdownOptionDetail) => {
        const nameA = a.name;
        const nameB = b.name;
        if (nameA && nameB && nameA.constructor.name === 'String' && nameB.constructor.name === 'String') {
            return nameA.localeCompare(nameB);
        }
        return 0;
    };

    const renderColumnOptions = () => {
        let dropdownOptions: JSX.Element[] = [];
        let dropdownOptionDetails: DropdownOptionDetail[] = [];
        let seenVisibilityKeys: string[] = [];
        columns.forEach((column) => {
            const visKey = column.visibilityKey;
            if (!seenVisibilityKeys.includes(visKey)) {
                seenVisibilityKeys.push(visKey);
                if (!['checkbox', 'etc'].includes(column.id)) {
                    dropdownOptionDetails.push({
                        key: `column-header-dropdown-option-${visKey}`,
                        handleCheckbox: (e) => handleColumnHeaderCheckbox(e, column),
                        selected: !isHidden(column),
                        locked:
                            Boolean(columnLimit) &&
                            columns.length - hiddenColumns.length ===
                                Number(columnLimit) + ['checkbox', 'etc'].length &&
                            !isHidden(column),
                        name: column.headerText || column.Header?.props?.text || column.Header,
                        required: lockedColumnsIds.includes(visKey)
                    });
                }
            }
        });
        const allColumns = dropdownOptionDetails.sort(sortByHeaderText);
        const lockedColumns = allColumns.filter((c) => c.required);
        const unlockedColumns = allColumns.filter((c) => !c.required);
        const visibleColumns = unlockedColumns.filter((c) => c.selected);
        const invisibleColumns = unlockedColumns.filter((c) => !c.selected);
        const sortedColumns = [...lockedColumns, ...visibleColumns, ...invisibleColumns];

        sortedColumns.forEach((detail) => {
            dropdownOptions.push(
                <DropdownOption
                    key={detail.key}
                    handleCheckbox={detail.handleCheckbox}
                    selected={detail.selected}
                    locked={detail.locked}
                    option={{
                        id: '1',
                        name: detail.name,
                        required: detail.required
                    }}
                />
            );
        });
        return dropdownOptions;
    };

    return (
        <Drawer
            anchor={'right'}
            open={showTableSettingsSlideout}
            style={{ zIndex: 1400 }}
            onClose={handleClose}>
            <SlideoutContainer>
                <StyledHeader>
                    <Box sx={{ fontSize: '18px' }}>Table Settings</Box>
                    <StyledHeaderActions>
                        <Box>
                            <Button
                                onClick={reset}
                                sx={{
                                    textDecoration: 'underline',
                                    textTransform: 'none',
                                    color: theme.palette.neutral.neutral6
                                }}>
                                Reset
                            </Button>
                        </Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <CloseIcon sx={{ color: theme.palette.neutral.black }} />
                            </IconButton>
                        </Box>
                    </StyledHeaderActions>
                </StyledHeader>
                <StyledBody>
                    <>
                        <Accordion
                            key={`load-source-list`}
                            expanded={expandedSections.includes('loadSource')}
                            style={{ fontSize: 14 }}
                            TransitionProps={{ unmountOnExit: true }}
                            onChange={(_e, expanded) =>
                                setExpandedSections((prevArray) =>
                                    expanded
                                        ? [...prevArray, 'loadSource']
                                        : prevArray.filter(
                                              (label: string) => label !== 'loadSource'
                                          )
                                )
                            }>
                            <AccordionSummary
                                sx={{
                                    backgroundColor: theme.palette.neutral.neutral1,
                                    borderRadius: '4px',
                                    marginBottom: '2px',
                                    marginTop: '2px'
                                }}
                                expandIcon={
                                    <KeyboardArrowDownSharpIcon
                                        sx={{ color: theme.palette.neutral.black }}
                                    />
                                }
                                aria-controls="accordion-content"
                                id={`load-source-accordion`}>
                                <Typography style={{ fontWeight: 600, color: theme.palette.black }}>
                                    Load Source
                                </Typography>
                            </AccordionSummary>
                        </Accordion>
                        <Collapse
                            in={expandedSections.includes('loadSource')}
                            timeout="auto"
                            unmountOnExit
                            key={`load-source-selected`}>
                            <Box sx={{ margin: '16px 0' }}>
                                <StyledInstructions>Select Load Sources</StyledInstructions>
                                <DropdownOption
                                    key={`dropdown-option-network-loads`}
                                    handleCheckbox={(e) => handleCheckboxByType(e, 'loadSource')}
                                    selected={[LOAD_SOURCE.NETWORK, LOAD_SOURCE.ALL].includes(
                                        loadSource
                                    )}
                                    locked={loadSource === LOAD_SOURCE.NETWORK} // update disabled state to locked and then in DropDownOption component, use locked state design. See bryan's slack message.
                                    option={{ id: LOAD_SOURCE.NETWORK, name: 'Network Loads' }}
                                />
                                <DropdownOption
                                    key={`dropdown-option-bid-loads`}
                                    handleCheckbox={(e) => handleCheckboxByType(e, 'loadSource')}
                                    selected={[LOAD_SOURCE.BID, LOAD_SOURCE.ALL].includes(
                                        loadSource
                                    )}
                                    locked={loadSource === LOAD_SOURCE.BID}
                                    option={{ id: LOAD_SOURCE.BID, name: 'Bid Loads' }}
                                />
                            </Box>
                        </Collapse>
                    </>

                    {/* {freightBonus && (
                        <Accordion
                            key={`freight-bonus-options`}
                            expanded={expandedSections.includes('freightBonus')}
                            style={{ fontSize: 14 }}
                            sx={{ '&:before': { display: 'none' } }}
                            TransitionProps={{ unmountOnExit: true }}
                            onChange={(_e, expanded) =>
                                setExpandedSections((prevArray) =>
                                    expanded
                                        ? [...prevArray, 'freightBonus']
                                        : prevArray.filter(
                                              (label: string) => label !== 'freightBonus'
                                          )
                                )
                            }>
                            <AccordionSummary
                                sx={{
                                    backgroundColor: theme.palette.neutral.neutral1,
                                    borderRadius: '4px',
                                    marginBottom: '16px'
                                }}
                                expandIcon={
                                    <KeyboardArrowDownSharpIcon
                                        sx={{ color: theme.palette.neutral.black }}
                                    />
                                }
                                aria-controls="accordion-content"
                                id={`columns-accordion`}>
                                <Typography style={{ fontWeight: 600, color: 'black' }}>
                                    Freight Bonus
                                </Typography>
                            </AccordionSummary>
                        </Accordion>
                    )} */}

                    {/* {freightBonus && (
                        <Collapse
                            sx={{ margin: '16px 0' }}
                            in={expandedSections.includes('freightBonus')}
                            timeout="auto"
                            unmountOnExit
                            key={`freight-bonus-selected`}>
                            <StyledInstructions>Select Freight Bonus Options</StyledInstructions>
                            <DropdownOption
                                key={`dropdown-option-freight-bonus-applied`}
                                handleCheckbox={(e) => handleCheckboxByType(e, 'bonus')}
                                selected={[
                                    BONUS_TABLE_FILTER_TYPE.ALL,
                                    BONUS_TABLE_FILTER_TYPE.BONUS_APPLIED
                                ].includes(isFreightBonusApplied)}
                                locked={
                                    isFreightBonusApplied === BONUS_TABLE_FILTER_TYPE.BONUS_APPLIED
                                }
                                option={{
                                    id: BONUS_TABLE_FILTER_TYPE.BONUS_APPLIED,
                                    name: 'Freight Bonus Applied'
                                }}
                            />
                            <DropdownOption
                                key={`dropdown-option-freight-bonus-not-applied`}
                                handleCheckbox={(e) => handleCheckboxByType(e, 'bonus')}
                                selected={[
                                    BONUS_TABLE_FILTER_TYPE.ALL,
                                    BONUS_TABLE_FILTER_TYPE.BONUS_NOT_APPLIED
                                ].includes(isFreightBonusApplied)}
                                locked={
                                    isFreightBonusApplied ===
                                    BONUS_TABLE_FILTER_TYPE.BONUS_NOT_APPLIED
                                }
                                option={{
                                    id: BONUS_TABLE_FILTER_TYPE.BONUS_NOT_APPLIED,
                                    name: 'Freight Bonus Not Applied'
                                }}
                            />
                        </Collapse>
                    )} */}

                    <Accordion
                        key={`table-columns-list`}
                        expanded={expandedSections.includes('columnHeaders')}
                        style={{ fontSize: 14 }}
                        sx={{ '&:before': { display: 'none' }, marginBottom: '2px' }}
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={(_e, expanded) =>
                            setExpandedSections((prevArray) =>
                                expanded
                                    ? [...prevArray, 'columnHeaders']
                                    : prevArray.filter((label: string) => label !== 'columnHeaders')
                            )
                        }>
                        <AccordionSummary
                            sx={{
                                backgroundColor: theme.palette.neutral.neutral1,
                                borderRadius: '4px'
                            }}
                            expandIcon={
                                <KeyboardArrowDownSharpIcon
                                    sx={{ color: theme.palette.neutral.black }}
                                />
                            }
                            aria-controls="accordion-content"
                            id={`columns-accordion`}>
                            <Typography style={{ fontWeight: 600, color: 'black' }}>
                                Column Headers
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Collapse
                        in={expandedSections.includes('columnHeaders')}
                        timeout="auto"
                        unmountOnExit
                        key={`column-headers-selected`}>
                        <Box sx={{ marginTop: '16px' }}>
                            <StyledInstructions>Select Table Column Headers</StyledInstructions>
                            {renderColumnOptions()}
                        </Box>
                    </Collapse>
                </StyledBody>
            </SlideoutContainer>
        </Drawer>
    );
};

export default NetworkTableSettingsSlideout;
