import { styled } from '@mui/material/styles';
import { Checkbox, Box } from '@mui/material';
import theme from 'theme';

export const StyledCheckbox = styled(Checkbox)(({ flag }: { flag: boolean }) => ({
    fontSize: '20px',
    padding: 0,
    color: flag ? theme.palette.white : theme.palette.neutral.black,
    '&.Mui-checked': {
        color: flag ? theme.palette.white : theme.palette.neutral.black
    },
    '&.Mui-disabled': {
        color: theme.palette.neutral.neutral6
    }
}));

export const NetworkTopNavbarWrapper = styled(Box)(({ flag }: { flag: boolean }) => ({
    height: '56px',
    zIndex: 13,
    backgroundColor: flag ? theme.palette.white : theme.palette.ODBlue.odBlue10,
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between'
}));

export const NetworkTopNavbarPageHeader = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const NetworkFlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));
