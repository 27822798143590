import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import theme from 'theme';
import {
    getConfigurationActions,
    setDraftConfigurationActions,
    updateConfiguration
} from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import CloseIcon from '@mui/icons-material/Close';
import { updateNetworkView } from 'redux/actions/networkActions';
import { AlertModal } from '../AlertModal';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { bulkDeleteBidLanes } from 'redux/actions';
import { isActionAllowed } from 'utils/network.util';
import { useShipperHook } from 'utils/hooks';
import { useSimulationHook } from 'utils/hooks';
import { NETWORK_TABLE_TYPE } from 'constants/network';

function Advisories({ row, reportFilters, readOnly }) {
    const dispatch = useDispatch();
    const { base_run, compare_run } = useParams();
    const { handleShipperLaneEdit } = useShipperHook();
    const { createSimulationOnEdit } = useSimulationHook();
    const { newSimulationFlow } = useFlags();
    const [show, setShow] = useState(false);
    const [actionDisabled, showActionDisabled] = useState(false);
    const { currTab } = useSelector((state) => state.NetworkReducer);
    const { filterBy, isFilterSet = false } = useSelector(
        (state) => state.ReportsReducers.loadFilters
    );
    const { configurationActions, configuration } = useSelector(
        (state) => state.ConfigurationReducer
    );

    const currReportId = base_run || compare_run;
    const selectedShipper = reportFilters?.shippers?.find((x) => x.id === row?.original?.id) || {};

    const selectedLane = reportFilters?.lanes?.find((x) => x.id === row?.original?.id) || {};
    const alertsContext = useAlerts();

    //TODO refactor this logic

    let isShipper = currTab === NETWORK_TABLE_TYPE.SHIPPERS;
    if (isFilterSet) isShipper = !isShipper;

    //TODO toggle

    const handleDelete = async () => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;
        let actions = [...configurationActions];
        const action = {
            type: 'remove',
            item_type: 'shipper-lane',
            value: {
                lane: (filterBy?.type === 'lane' ? filterBy?.id : selectedLane?.id) || null,
                shipper: (filterBy?.type === 'shipper' ? filterBy?.id : selectedShipper?.id) || null
            }
        };

        actions.push(action);
        if (selectedLane?.is_bid)
            await bulkDeleteBidLanes(selectedLane?.bid_config_id, selectedLane?.bid_id);
        await dispatch(setDraftConfigurationActions(actions));
        await dispatch(updateConfiguration(currReportId, simulationId, [action]));
        await dispatch(getConfigurationActions(currReportId, simulationId));
    };

    const undoConfigAction = async () => {
        if (!newSimulationFlow && !isActionAllowed(configuration, alertsContext)) return;
        const simulationId = await createSimulationOnEdit();
        if (!simulationId) return;
        const action = configurationActions.find(
            ({ item_type, id, type, value: { shipper, lane } }) =>
                id && type === 'remove' && item_type === 'shipper-lane' && lane
                    ? lane === (filterBy?.type === 'lane' ? filterBy?.id : selectedLane?.id)
                    : shipper ===
                          (filterBy?.type === 'shipper' ? filterBy?.id : selectedShipper?.id) &&
                      type === 'remove'
        );

        if (!action) return;
        action.type = 'restore';

        const actions = configurationActions.filter(({ id }) => id !== action.id);
        dispatch(setDraftConfigurationActions(actions));
        await dispatch(updateConfiguration(currReportId, simulationId, [action]));
        dispatch(getConfigurationActions(currReportId, simulationId));
    };

    let actions = [
        {
            name: 'delete',
            action: handleDelete,
            icon: (
                <RemoveSharpIcon
                    sx={{
                        color: theme.palette.black
                    }}
                />
            )
        },
        {
            name: 'edit',
            action: () => handleShipperLaneEdit(isShipper ? selectedShipper : selectedLane),
            icon: (
                <EditSharpIcon
                    sx={{
                        color: theme.palette.black
                    }}
                />
            )
        },
        {
            name: 'notShow',
            action: () => setShow(false),
            closeIcon: true,
            icon: (
                <CloseIcon
                    style={{
                        fill: theme.palette.white
                    }}
                />
            )
        }
    ];

    const actionLists = actions.map((option, i) => {
        const { name, action, icon } = option;
        const isDisabled = compare_run && name !== 'notShow';

        return (
            <IconButton
                key={`${name}+${i}`}
                id={name}
                sx={{
                    borderRadius: '4px',
                    backgroundColor: option?.closeIcon
                        ? theme.palette.ODBlue.odBlue8
                        : theme.palette.neutral.neutral1,
                    minWidth: 0,
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '8px',
                    '&:hover': {
                        backgroundColor: option?.closeIcon
                            ? theme.palette.ODBlue.odBlue7
                            : theme.palette.neutral.neutral2,
                        '& > svg': {
                            color: theme.palette.black
                        }
                    }
                }}
                onClick={() => {
                    isDisabled
                        ? dispatch(
                              updateNetworkView({
                                  showAlertModal: true
                              })
                          )
                        : action();
                }}>
                {icon}
            </IconButton>
        );
    });

    return (
        <Box sx={{ position: 'relative' }}>
            {actionDisabled && <AlertModal onAction={() => showActionDisabled(false)} />}
            {show ? (
                <Box
                    onClick={() => setShow(false)}
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        right: -32,
                        top: -16
                    }}>
                    {actionLists}
                </Box>
            ) : row?.original?.isRemoved ? (
                <IconButton
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: theme.palette.white,
                        minWidth: 0,
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral1
                        }
                    }}
                    onClick={undoConfigAction}>
                    <UndoSharpIcon
                        sx={{
                            color: theme.palette.black
                        }}
                    />
                </IconButton>
            ) : (
                <IconButton
                    disabled={readOnly}
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: theme.palette.white,
                        minWidth: 0,
                        margin: '0 16px 0 0',
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral2
                        },
                        '&:disabled': {
                            backgroundColor: theme.palette.neutral.neutral4
                        }
                    }}
                    onClick={() => setShow(true)}>
                    <MoreVertIcon
                        sx={{
                            color: readOnly ? '#00000042' : theme.palette.black
                        }}
                    />
                </IconButton>
            )}
        </Box>
    );
}

export default Advisories;
