import React, { useState } from 'react';
import { ListItemIcon, MenuItem, Select, SelectProps, Checkbox, Box } from '@mui/material';
import palette from 'theme/palette';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import { Close } from '@mui/icons-material';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import IndeterminateCheckBoxSharpIcon from '@mui/icons-material/IndeterminateCheckBoxSharp';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const Label = styled(Box)(() => ({
    fontSize: '12px',
    marginBottom: '4px',
    color: palette.neutral.neutral6
}));
const StyledSelect = styled(Select)<SelectProps<any>>(() => ({
    height: '40px',
    borderRadius: '0px',
    fontSize: '14px',
    backgroundColor: palette.white,
    '&:hover': {
        backgroundColor: palette.neutral.neutral1
    },
    '& .MuiInputBase-input': {
        padding: '10px 16px',
        fontSize: '14px',
        width: '100%',
        color: palette.neutral.neutral6,
        '&:hover': {
            color: `${palette.neutral.black} !important`
        },
        '&:target': {
            color: `${palette.neutral.black} !important`
        },
        '&:active': {
            color: palette.neutral.black,
            '.MuiSvgIcon-root': {
                color: palette.neutral.black
            }
        }
    },
    '&:hover:not(.Mui-disabled):before': {
        border: 'none'
    },
    '&:hover .MuiSvgIcon-root': {
        color: palette.neutral.black
    },
    '&.Mui-focused': {
        '& .MuiInputBase-input': {
            color: palette.neutral.black
        },
        '& .MuiSvgIcon-root': {
            color: palette.neutral.black
        }
    },
    '& .MuiSvgIcon-root': {
        right: 0,
        marginRight: '12px'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        sx: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 392,
            borderRadius: 0
        }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    },
    variant: 'menu',
    sx: {
        maxHeight: '16rem',
        fontSize: '14px',
        '&& .Mui-selected': {
            backgroundColor: palette.ODLightBlueNeutral.lightBlue1
        }
    },
    MenuListProps: {
        style: {
            boxShadow: 'none'
        },
        sx: {
            '&.MuiList-root.MuiMenu-list': {
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    }
};
const useStyles = makeStyles(() => ({
    selectAllText: {
        fontWeight: 400
    }
}));

interface MultiSelectPureProps {
    fieldName: string;
    options: any[];
    label: string;
    placeholder?: string;
    handleChange: (selectedValues: string | (string | number)[]) => void;
    selectedOptions: any[];
    clearSelectedOptions?: () => void;
    getObjectValueFromId: (option: string | number) => any;
}
const ODMultiSelectCore = ({
    fieldName,
    options,
    label,
    handleChange,
    selectedOptions,
    clearSelectedOptions,
    getObjectValueFromId
}: MultiSelectPureProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const classes = useStyles();

    const isAllSelected = options.length > 0 && selectedOptions.length === options.length;

    return (
        <>
            <Label id={`${label}-multi-select-label`}>{label}</Label>
            <StyledSelect
                id={`${label}-multi-select`}
                labelId="mutiple-select-label"
                multiple
                variant="filled"
                name={fieldName}
                open={isOpen}
                IconComponent={() => (
                    <>
                        {selectedOptions.length > 0 && (
                            <Close
                                sx={{
                                    color: palette.black,
                                    cursor: 'pointer',
                                    mr: '16px'
                                }}
                                aria-label="clear-all-location-selections"
                                id="clear-all-driver-rule-location-selections"
                                onClick={clearSelectedOptions}
                            />
                        )}
                        {isOpen ? (
                            <KeyboardArrowUpIcon onClick={() => setIsOpen(!isOpen)} />
                        ) : (
                            <KeyboardArrowDownIcon onClick={() => setIsOpen(!isOpen)} />
                        )}
                    </>
                )}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                value={selectedOptions.length > 0 ? selectedOptions : [null]}
                onChange={(e) => {
                    handleChange(e.target.value);
                }}
                renderValue={(option) => {
                    if (option?.length === 1 && option[0] === null) {
                        return 'Select Options';
                    } else
                        return (
                            <Box
                                sx={{
                                    color: palette.neutral.black
                                }}>{`(${option?.length}) Selected`}</Box>
                        );
                }}
                MenuProps={MenuProps as object}
                fullWidth>
                <MenuItem
                    disabled={options?.length === 0}
                    id={'menuitem-for-all'}
                    value="all"
                    sx={{ height: '40px', backgroundColor: palette.neutral.neutral1 }}>
                    <ListItemIcon>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankSharpIcon />}
                            checkedIcon={<CheckBoxSharpIcon />}
                            indeterminateIcon={<IndeterminateCheckBoxSharpIcon />}
                            sx={{
                                width: '14px',
                                padding: 0,
                                color: palette.neutral.neutral6,
                                '&.Mui-checked': {
                                    color: palette.black
                                },
                                '&.MuiCheckbox-indeterminate': {
                                    color: palette.black
                                }
                            }}
                            checked={isAllSelected}
                            indeterminate={
                                selectedOptions.length > 0 &&
                                selectedOptions.length < options.length
                            }
                        />
                    </ListItemIcon>

                    <Box
                        className={classes.selectAllText}
                        sx={{
                            fontSize: '14px'
                        }}>
                        Select All
                    </Box>
                </MenuItem>
                {options?.map((option: string | number) => {
                    const isChecked = selectedOptions.indexOf(option) > -1;

                    return (
                        <MenuItem
                            key={option}
                            value={option}
                            id={`menuitem-for-${option}`}
                            sx={{
                                width: '392px',
                                height: '40px',
                                backgroundColor: palette.neutral.neutral1,
                                '&:hover': {
                                    backgroundColor: palette.neutral.neutral2
                                }
                            }}>
                            <ListItemIcon>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankSharpIcon />}
                                    checkedIcon={<CheckBoxSharpIcon />}
                                    indeterminateIcon={<IndeterminateCheckBoxSharpIcon />}
                                    sx={{
                                        width: '14px',
                                        padding: 0,
                                        color: palette.neutral.neutral6,
                                        '&.Mui-checked': {
                                            color: palette.black
                                        }
                                    }}
                                    checked={isChecked}
                                />
                            </ListItemIcon>
                            <Box sx={{ fontSize: '14px' }}>{getObjectValueFromId(option)}</Box>
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </>
    );
};

export default ODMultiSelectCore;
