import { Box } from '@mui/material';
const Filter = ({ column }) => {
    return (
        <Box id="filter" sx={{ marginTop: '10px' }}>
            {column.canFilter && column.render('Filter')}
        </Box>
    );
};

export default Filter;
