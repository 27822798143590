import { useEffect, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import theme from 'theme';
import { ODIconButton } from 'shared';
import { Check, Close } from '@mui/icons-material';
import { inputValid, VALIDATION_RULE } from 'utils/common.util';
import { styled } from '@mui/material/styles';
import { Input } from '@mui/material';
import NumericFormat from 'react-number-format';
import * as FS from '@fullstory/browser';

const StyledButton = styled(ODIconButton)(() => ({
    width: '30px',
    height: '30px',
    backgroundColor: 'inherit'
}));

const StyledStack = styled(Stack)(({ unallowed }: { unallowed: boolean }) => ({
    height: '30px',
    width: '184px',
    border: `1px solid ${theme.palette.neutral.neutral2}`,
    borderRadius: '2px',
    ...(unallowed ? { borderColor: theme.palette.semantic.semanticRed } : {})
}));

const StyledCloseIcon = styled(Close)(
    ({ disableIcon, value }: { disableIcon: boolean; value: number | null }) => ({
        color:
            value === null || disableIcon
                ? theme.palette.neutral.neutral6
                : theme.palette.neutral.black
    })
);
const StyledCheckIcon = styled(Check)(
    ({
        disableIcon,
        value,
        unallowed
    }: {
        disableIcon: boolean;
        value: number | null;
        unallowed: boolean;
    }) => ({
        color:
            value === null || unallowed || disableIcon
                ? theme.palette.neutral.neutral6
                : theme.palette.success.main
    })
);
const CustomInput = (props: any) => {
    return (
        <Input
            {...props}
            sx={{
                fontSize: '14px',
                height: '100%',
                borderRadius: '1px',
                '& input::placeholder': {
                    color: theme.palette.neutral.neutral6,
                    opacity: 1 // otherwise firefox shows a lighter color
                },

                backgroundColor: props.disabled ? theme.palette.neutral.neutral1 : '',

                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                },
                '& .MuiInputBase-input': { padding: '7px 10px' }
            }}
            disableUnderline={true}
        />
    );
};

const NumericInputWithDeleteAndConfirmButtons = ({
    disabled = false,
    targetName,
    exceedsMaxCallback,
    belowMinCallback,
    maxAllowable,
    minAllowable,
    updateValue,
    validationRule,
    format,
    setIsInputEmpty
}: {
    targetName: string;
    updateValue: (value: number | null) => void;
    exceedsMaxCallback: () => void;
    belowMinCallback: () => void;
    validationRule: VALIDATION_RULE;
    maxAllowable?: number;
    minAllowable?: number;
    disabled: boolean;
    format?: 'dollars' | 'number';
    setIsInputEmpty: (isEmpty: boolean) => void;
}) => {
    const [value, setValue] = useState<number | null>(null);
    const [unallowed, setUnallowed] = useState<boolean>(false);

    const handleConfirm = () => {
        FS.event('Bid Analysis_Bid Goals_Click Accept Button', {
            targetFieldName: targetName
        });
        if (minAllowable && !(Number(value) > minAllowable)) {
            setUnallowed(true);
            belowMinCallback();
        } else updateValue(value);
    };
    const clearValue = () => {
        setValue(null);
        updateValue(null);
        setIsInputEmpty(true);
    };

    const onChange = (newValue: any) => {
        if (newValue === null && value !== null) {
            setValue(newValue);
            setIsInputEmpty(true);
        } else if (inputValid(validationRule, newValue)) {
            if (value === null) {
                setIsInputEmpty(false);
            }
            setValue(newValue);
        }
    };

    useEffect(() => {
        if (
            Boolean(
                value !== null &&
                    maxAllowable !== null &&
                    maxAllowable !== undefined &&
                    Number(value) > maxAllowable
            )
        ) {
            setUnallowed(true);
            exceedsMaxCallback();
        } else {
            setUnallowed(false);
        }
    }, [exceedsMaxCallback, value, maxAllowable]);

    return (
        <StyledStack
            unallowed={unallowed}
            direction="row"
            divider={
                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: unallowed ? theme.palette.semantic.semanticRed : '' }}
                />
            }>
            <NumericFormat
                disabled={disabled}
                customInput={CustomInput}
                prefix={format === 'dollars' ? '$' : ''}
                thousandSeparator={true}
                displayType="input"
                value={value ?? ''}
                decimalScale={format === 'dollars' ? 2 : 0}
                fixedDecimalScale={true}
                onValueChange={(values, sourceInfo) => {
                    if (sourceInfo.source === 'event') onChange(values.floatValue ?? null);
                }}
                isNumericString={true}
                onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter' && !unallowed) handleConfirm();
                }}
            />
            <StyledButton
                disableRipple={false}
                disabled={disabled}
                locked={!disabled && value === null}
                icon={<StyledCloseIcon disableIcon={disabled} value={value} />}
                onClick={() => {
                    FS.event('Bid Analysis_Bid Goals_Clear Target Field', {
                        targetFieldName: targetName
                    });
                    clearValue();
                }}
                sx={{
                    borderRadius: '0px'
                }}
            />
            <StyledButton
                disableRipple={false}
                disabled={disabled || unallowed}
                locked={!disabled && value === null}
                icon={
                    <StyledCheckIcon disableIcon={disabled} value={value} unallowed={unallowed} />
                }
                onClick={handleConfirm}
                sx={{
                    borderRadius: '0 1px 1px 0'
                }}
            />
        </StyledStack>
    );
};
export default NumericInputWithDeleteAndConfirmButtons;
