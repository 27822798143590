import moment, { MomentInput } from 'moment';

export const convertDateObjIntoUSFormat = (date: MomentInput) => {
    return moment(date).format('MM/DD/YYYY');
};

export const convertDateObjIntoUSFormatWithDash = (date: MomentInput) => {
    return moment(date).format('YYYY-MM-DD');
};

export const convertDateTimeObjIntoUSFormatWithDash = (date: MomentInput) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
};
export const convertDateTimeObjIntoUSFormat = (date: MomentInput) => {
    return moment(date).format('MMDDYYHHmm');
};
