import React from 'react';
import { Box } from '@mui/material';
import { Person, Close, Add, SubdirectoryArrowRight } from '@mui/icons-material';
import { AnyObject } from 'yup/lib/types';
import palette from 'theme/palette';
import { ODCard, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import { StyledPaper } from './HistorySlideout.styles';

interface historyDriverCardProps {
    key: string;
    handleRestore: (e: any, data: AnyObject) => any;
    id: string;
    name: string;
    count: string | number;
    driverType: any;
    quantity: string | number;
    readOnly?: boolean;
}

const HistoryDriverCard = ({
    key,
    id,
    name,
    count,
    driverType,
    handleRestore,
    quantity,
    readOnly
}: historyDriverCardProps) => {
    return (
        <StyledPaper readOnly={readOnly} key={key}>
            <ODCard sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex">
                    <Person sx={{ marginRight: '16px' }} />
                    <b>Domicile</b>
                </Box>
                {!readOnly && (
                    <ODIconButton
                        disableHover={true}
                        sx={{ padding: 0 }}
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        icon={<Close sx={{ color: palette.neutral.black }} />}
                        onClick={(e) =>
                            handleRestore(e, {
                                id,
                                domicile: name,
                                quantity,
                                driver_type: driverType
                            })
                        }
                    />
                )}
            </ODCard>
            <ODCard>
                <SubdirectoryArrowRight sx={{ marginRight: '16px' }} />
                {name}
            </ODCard>
            <ODCard>
                <Add sx={{ marginRight: '16px' }} />
                <b>{driverType}</b>: {count}
            </ODCard>
        </StyledPaper>
    );
};

export default HistoryDriverCard;
