import { Box, Input, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { max, min } from 'lodash';
import { abbreviateNumber } from 'utils/number.util';
import { useFilterStyles, FooterLabel } from './FilterStyles';
import clsx from 'clsx';
import theme from 'theme';

const NumberSelectionArrows = ({ increment, decrement }) => {
    const classes = useFilterStyles();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                right: '9px'
            }}>
            <IconButton
                onClick={(e) => {
                    increment();
                }}
                size="medium"
                sx={{ padding: 0, bottom: '-2px' }}
                className={classes.lightModeArrowButton}>
                <KeyboardArrowUp sx={{ width: '10px', height: '10px' }} />
            </IconButton>

            <IconButton
                onClick={(e) => {
                    decrement();
                }}
                size="small"
                sx={{ padding: 0, top: '-2px' }}
                className={classes.lightModeArrowButton}>
                <KeyboardArrowDown sx={{ width: '10px', height: '10px' }} />
            </IconButton>
        </Box>
    );
};
/* It is not clear from react table documentation
 * what the 2nd parameter of the filter function contains. On inspection,
 * this second parameter is always an empty object in our usage.
 */
const NumberRangeFilter = (tableData) => {
    const { column, globalFilteredRows, initialRows } = tableData;
    let columnId = column.id;
    const [focused, setFocused] = useState('');
    const [hovered, setHovered] = useState('');
    const { setFilter, filterValue } = column;
    const [minVal, setMinVal] = useState(min(globalFilteredRows.map((r) => r.original[columnId])));
    const [maxVal, setMaxVal] = useState(max(globalFilteredRows.map((r) => r.original[columnId])));
    const [unfilteredMax, setUnfilteredMax] = useState();
    const [unfilteredMin, setUnfilteredMin] = useState();

    const initialColumnValues = initialRows.map((r) => r.original[columnId]);
    const stringifiedInitialValues = JSON.stringify(initialColumnValues);
    const classes = useFilterStyles();

    useEffect(() => {
        setUnfilteredMin(min(initialColumnValues));
        setUnfilteredMax(max(initialColumnValues));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedInitialValues]);

    useEffect(() => {
        setFilter([null, null]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => {
            setMinVal(min(globalFilteredRows.map((r) => r.original[columnId])));
            setMaxVal(max(globalFilteredRows.map((r) => r.original[columnId])));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalFilteredRows.map((r) => r.original[columnId])]
    );

    const onUpdate = (count, type) => {
        if (count !== '' && column.formattingFunction) count = column.formattingFunction(count);
        setFilter(
            type === 'min'
                ? [count === '' ? null : Number(count), filterValue[1]]
                : [filterValue[0], count === '' ? null : Number(count)]
        );
    };
    const handleIncrement = (type) => {
        let count = filterValue[type === 'min' ? 0 : 1];
        const globalFilteredRowsAsFloats = globalFilteredRows.map((r) => r.original[columnId]);
        if (count === null) {
            count =
                type === 'min' ? min(globalFilteredRowsAsFloats) : max(globalFilteredRowsAsFloats);
        }
        if (count + 1 > unfilteredMax) return;
        count = count + 1;
        onUpdate(count, type);
    };
    const handleDecrement = (type) => {
        let count = filterValue[type === 'min' ? 0 : 1];
        const globalFilteredRowsAsFloats = globalFilteredRows.map((r) => r.original[columnId]);
        if (count === null) {
            count =
                type === 'min' ? min(globalFilteredRowsAsFloats) : max(globalFilteredRowsAsFloats);
        }
        if (count - 1 < unfilteredMin) return;
        count -= 1;
        onUpdate(count, type);
    };
    const validateInput = (value, type) => {
        if (value !== null && value > unfilteredMax) onUpdate(unfilteredMax, type);
        if (value !== null && value < unfilteredMin) onUpdate(unfilteredMin, type);
    };
    const minFieldEnabled = () => {
        return focused === 'min' || hovered === 'min' || filterValue[0] !== null;
    };
    const maxFieldEnabled = () => {
        return focused === 'max' || hovered === 'max' || filterValue[1] !== null;
    };

    return (
        <Box
            sx={{ display: 'flex' }}
            onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget)) setFocused('');
            }}>
            {filterValue && (
                <>
                    <Box>
                        <Input
                            id={`${columnId}-min-filter-input`}
                            onFocus={() => setFocused('min')}
                            onMouseEnter={() => setHovered('min')}
                            onMouseLeave={() => setHovered('')}
                            onBlur={() => {
                                validateInput(filterValue[0], 'min');
                                setHovered('');
                            }}
                            disableUnderline
                            className={clsx(classes.numberInput, classes.lightModeNumberInput)}
                            sx={{
                                marginRight: '2px',
                                backgroundColor:
                                    (focused === 'min' || hovered === 'min') &&
                                    theme.palette.neutral.neutral2,
                                color: minFieldEnabled()
                                    ? theme.palette.black
                                    : theme.palette.ODBlue.odBlue7
                            }}
                            placeholder={
                                filterValue[0] ?? column.abbreviated
                                    ? abbreviateNumber(minVal)
                                    : minVal
                            }
                            value={filterValue[0] ?? ''}
                            type="number"
                            onChange={(e) => {
                                onUpdate(e.target.value, 'min');
                            }}
                            endAdornment={
                                focused === 'min' || hovered === 'min' ? (
                                    <NumberSelectionArrows
                                        increment={() => handleIncrement('min')}
                                        decrement={() => handleDecrement('min')}
                                    />
                                ) : undefined
                            }
                        />
                        <FooterLabel sx={{ fontSize: '8px', marginTop: '2px', fontWeight: '400' }}>
                            Min
                        </FooterLabel>
                    </Box>
                    <Box>
                        <Input
                            id={`${columnId}-max-filter-input`}
                            onFocus={() => setFocused('max')}
                            onMouseEnter={() => setHovered('max')}
                            onMouseLeave={() => setHovered('')}
                            disableUnderline
                            className={clsx(classes.numberInput, classes.lightModeNumberInput)}
                            sx={{
                                backgroundColor:
                                    (focused === 'max' || hovered === 'max') &&
                                    theme.palette.neutral.neutral2,
                                color: maxFieldEnabled()
                                    ? theme.palette.black
                                    : theme.palette.ODBlue.odBlue7
                            }}
                            onBlur={() => {
                                validateInput(filterValue[1], 'max');
                                setHovered('');
                            }}
                            placeholder={
                                filterValue[1] ?? column.abbreviated
                                    ? abbreviateNumber(maxVal)
                                    : maxVal
                            }
                            value={filterValue[1] ?? ''}
                            type="number"
                            onChange={(e) => {
                                onUpdate(e.target.value, 'max');
                            }}
                            endAdornment={
                                focused === 'max' || hovered === 'max' ? (
                                    <NumberSelectionArrows
                                        increment={() => handleIncrement('max')}
                                        decrement={() => handleDecrement('max')}
                                    />
                                ) : undefined
                            }
                        />
                        <FooterLabel>Max</FooterLabel>
                    </Box>
                </>
            )}
        </Box>
    );
};
export default NumberRangeFilter;
