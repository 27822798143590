import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles/';
import { indicatorLinesWidth } from 'constants/network';
import { defaultColumnWidth } from 'utils/table.util';
import theme from 'theme';

export const networkTableUseStyles = makeStyles(() => ({
    sticky: {
        position: 'sticky',
        backgroundColor: 'inherit'
    },
    stickyHeader: {
        zIndex: 3
    },
    stickyLeft: {
        left: 0,
        borderRight: `1px solid ${theme.palette.ODBlue.odBlue10}`
    },
    stickyRight: {
        right: 0,
        borderLeft: `1px solid ${theme.palette.ODBlue.odBlue10}`
    }
}));

export const CustomizedHeaderCell = styled('td', {
    shouldForwardProp: (prop) => !['column', 'leftOffset', 'lastLeftStickyColumn'].includes(prop)
})(({ theme, column, leftOffset, lastLeftStickyColumn, isFirstDataColumn }) => ({
    backgroundColor: theme.palette.neutral.search,
    fontWeight: 600,
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.ODBlue.odBlue10,

    '& .sortIcons > .MuiSvgIcon-root': {
        height: '16px',
        width: '16px',
        top: 2,
        position: 'relative',
        marginLeft: '4px'
    },
    zIndex: 2,
    alignItems: 'left',
    right: column.sticky && column.identifier === 'etc' ? 0 : null,
    left: leftOffset && column.sticky && column.identifier !== 'etc' ? leftOffset : null,
    borderLeft:
        column.identifier === 'etc' ? `1px solid ${theme.palette.neutral.neutral2}` : 'none',
    borderRight:
        column.identifier === lastLeftStickyColumn
            ? `1px solid ${theme.palette.neutral.neutral2}`
            : 'none',
    width: column.columnWidth ? column.columnWidth : defaultColumnWidth,
    padding:
        column.identifier === 'checkbox'
            ? '10px 0 0 0'
            : column.identifier === 'etc'
            ? '10px 16px 10px 8px'
            : isFirstDataColumn
            ? '10px 8px 10px 0'
            : '10px 8px 10px 15px',
    boxSizing: column.identifier === 'etc' ? 'border-box' : 'content-box',
    '& .MuiCheckbox-root': {
        padding: 0
    }
}));

export const IndicatorLinesHeaderCell = styled('td')(({ theme }) => ({
    zIndex: 3,
    backgroundColor: theme.palette.neutral.search,
    fontWeight: 600,
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.ODBlue.odBlue10,
    '& .sortIcons > .MuiSvgIcon-root': {
        height: '16px',
        width: '16px',
        top: 2,
        position: 'relative',
        marginLeft: '4px'
    },
    left: 0,
    padding: 0,
    width: indicatorLinesWidth
}));

export const IndicatorLinesCell = styled('td')(({ theme }) => ({
    height: '43px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.ODBlue.odBlue10,
    width: indicatorLinesWidth
}));

export const CustomizedCell = styled('td', {
    shouldForwardProp: (prop) =>
        !['cell', 'width', 'index', 'leftOffset', 'lastLeftStickyColumn'].includes(prop)
})(({ theme, cell, index, leftOffset, lastLeftStickyColumn, hiddenColumns, compare_run }) => ({
    height: '43px',
    display:
        hiddenColumns.includes(cell.column.visibilityKey) ||
        (cell.column.isDelta && !Boolean(compare_run))
            ? 'none'
            : 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.black,
    width: cell.column.columnWidth ? cell.column.columnWidth : defaultColumnWidth,
    flex: ['checkbox', 'etc'].includes(cell.column.identifier) ? '0 0 auto' : '1 0 auto',
    padding:
        cell.column.identifier === 'checkbox'
            ? 0
            : cell.column.identifier === 'etc'
            ? '0px 8px 0px 8px'
            : index === 1
            ? '0px 8px 0px 0'
            : '0px 8px 0px 15px',
    boxSizing: ['etc'].includes(cell.column.identifier) ? 'border-box' : 'content-box',
    left: cell.column.sticky && cell.column.identifier !== 'etc' ? leftOffset : null,
    right: cell.column.sticky && cell.column.identifier === 'etc' ? 0 : null,
    borderRight:
        cell.column.identifier === lastLeftStickyColumn
            ? `1px solid ${theme.palette.neutral.neutral2}`
            : 'none',
    borderLeft:
        cell.column.identifier === 'etc' ? `1px solid ${theme.palette.neutral.neutral2}` : 'none'
}));

export const EmptyTableSettingsColumnHeader = styled(Box)(({ theme }) => ({
    visibility: 'hidden',
    borderRadius: '50%',
    backgroundColor: theme.palette.neutral.neutral2,
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '16px'
}));

export const bottomTableHeaderHeight = '86px';

export const EmptyTableBody = styled(Box)(({ theme }) => ({
    color: theme.palette.black,
    position: 'absolute',
    bottom: 0,
    top: bottomTableHeaderHeight,
    left: 0,
    right: 0,
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const TableBodyRow = styled(Box)(({ theme, isSelected, hover }) => {
    const hoverEffect = hover
        ? {
              '&:hover': {
                  backgroundColor: `${theme.palette.neutral.neutral2} !important`
              }
          }
        : {};

    return {
        padding: '0',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.neutral.neutral1}`,
        alignItems: 'center',
        backgroundColor: isSelected
            ? theme.palette.ODLightBlueNeutral.lightBlue1
            : theme.palette.white,
        ...hoverEffect,
        position: 'relative'
    };
});

export const PaginationContainer = styled(Box)(({ theme, isListView }) => ({
    minHeight: !isListView ? '56px' : null,
    height: '56px',
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 16px',
    marginX: !isListView ? 0 : '-16px',
    fontSize: '14px',
    color: theme.palette.ODBlue.odBlue7,
    marginTop: 'auto',
    borderTop: `1px solid ${theme.palette.neutral.neutral2}`
}));

export const PaginationButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.ODBlue.odBlue7,
    minWidth: 0,
    width: '40px',
    height: '40px',
    margin: '0 8px',
    '&:disabled': {
        color: theme.palette.neutral.neutral6,
        backgroundColor: theme.palette.neutral.neutral4
    }
}));
