/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import ClearIcon from '@mui/icons-material/Clear';
import { Avatar, Badge, Box } from '@mui/material';
import { GroupIcon } from 'assets/images';
import { useSimulationHook } from 'utils/hooks';
import { useNetworkViewHook } from 'utils/hooks';
import { updateNetworkView } from 'redux/actions';
import { useParamsProps } from 'types/hooks';
import theme from 'theme';
import { ODButton, ODIconButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import * as FS from '@fullstory/browser';

const ManageBidsBtn = ({ iconButton }: { iconButton?: boolean }) => {
    const dispatch = useDispatch();
    const { compare_run }: useParamsProps = useParams();
    const { handleRemoveAllAppliedBidFiles } = useSimulationHook();
    const { currReport } = useNetworkViewHook();
    const { appliedBidFilesIds } = useSelector((state: RootState) => state.ConfigurationReducer);

    return (
        <Box sx={{ margin: '8px' }}>
            {iconButton ? (
                <ODIconButton
                    sx={{
                        backgroundColor:
                            appliedBidFilesIds?.length === 0
                                ? theme.palette.white
                                : theme.palette.neutral.neutral1,
                        height: '40px',
                        width: '40px',
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral2
                        }
                    }}
                    buttonType={BUTTON_TYPE.TRANSPARENT}
                    disabled={currReport?.read_only || compare_run}
                    enableTooltip
                    icon={
                        <Badge
                            variant="dot"
                            color="primary"
                            invisible={appliedBidFilesIds?.length === 0}>
                            <GroupIcon
                                fill={
                                    currReport?.read_only || compare_run
                                        ? theme.palette.neutral.neutral6
                                        : appliedBidFilesIds?.length > 0
                                        ? theme.palette.neutral.black
                                        : theme.palette.neutral.black
                                }
                            />
                        </Badge>
                    }
                    title={
                        appliedBidFilesIds?.length === 0
                            ? 'Apply Bid Files'
                            : `(${appliedBidFilesIds?.length}) Applied Bid Files`
                    }
                    onClick={() => {
                        FS.init({ orgId: '18AJTW' });
                        FS.event('Network_View_Click_Apply_Bids_Button', {});
                        dispatch(updateNetworkView({ showBidFilesSlideout: true }));
                    }}
                />
            ) : (
                <ODButton
                    disabled={currReport?.read_only || compare_run}
                    buttonType={BUTTON_TYPE.TRANSPARENT}
                    startIcon={
                        appliedBidFilesIds?.length === 0 ? (
                            <GroupIcon
                                fill={
                                    currReport?.read_only || compare_run
                                        ? theme.palette.neutral.neutral6
                                        : appliedBidFilesIds?.length > 0
                                        ? theme.palette.neutral.black
                                        : theme.palette.neutral.black
                                }
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    backgroundColor: theme.palette.semantic.focusedBlue,
                                    width: '24px',
                                    height: '24px',
                                    fontWeight: 600,
                                    color: theme.palette.white,
                                    fontSize: 14
                                }}>
                                {appliedBidFilesIds?.length}
                            </Avatar>
                        )
                    }
                    sx={{
                        backgroundColor:
                            appliedBidFilesIds?.length === 0
                                ? theme.palette.white
                                : theme.palette.neutral.neutral1,
                        textTransform: 'none',
                        color: theme.palette.black,
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral2
                        }
                    }}
                    buttonText={
                        appliedBidFilesIds?.length === 0 ? 'Apply Bid Files' : `Applied Bid Files`
                    }
                    onClick={() => dispatch(updateNetworkView({ showBidFilesSlideout: true }))}
                    endIcon={
                        appliedBidFilesIds?.length > 0 ? (
                            <ClearIcon
                                sx={{
                                    color: theme.palette.neutral.black
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveAllAppliedBidFiles();
                                }}
                            />
                        ) : (
                            false
                        )
                    }
                />
            )}
        </Box>
    );
};

export default ManageBidsBtn;
