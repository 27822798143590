import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BID_CONFIG_INFO_MESSAGES } from 'constants/bidding';
import { StyledRateTab } from './UploadedBidDataAccordion.styles';
import { ODInputWithAdornments } from 'shared';
import FormattedInputs from 'components/NetworkView/TopNavbar/components/MaskedInput';
import { isNullRatePerMile } from 'utils/common.util';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`rate-adj-tabpanel-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const RateAdjustmentTabs = ({
    setAdjustments,
    inputErrorAdjustments,
    setInputErrorAdjustments,
    adjustments,
    minRate,
    tabValue,
    setTabValue,
    editOptions,
    setEditOptions
}: {
    setAdjustments: any;
    inputErrorAdjustments: string | null;
    setInputErrorAdjustments: any;
    adjustments: any;
    minRate: number;
    tabValue: number;
    setTabValue: (tabValue: number) => void;
    editOptions: any[];
    setEditOptions: (editOptions: any[]) => void;
}) => {
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleCalculation = (
        value: number | string,
        currIndex: any,
        calcType: string | null = null
    ) => {
        const options = [...editOptions] as any;
        const nonCurrIndex = currIndex === 0 ? 1 : 0;
        if (calcType === 'add') {
            options[currIndex] = {
                ...options[currIndex],
                displayedValue: (Number(value) + 1).toFixed(2)
            };
            options[nonCurrIndex] = {
                ...options[nonCurrIndex],
                displayedValue: ''
            };
        } else if (calcType === 'subtract') {
            options[currIndex] = {
                ...options[currIndex],
                displayedValue: (Number(value) - 1).toFixed(2)
            };
            options[nonCurrIndex] = {
                ...options[nonCurrIndex],
                displayedValue: ''
            };
        } else {
            options[currIndex] = {
                ...options[currIndex],
                displayedValue: value ? Number(value).toFixed(2) : ''
            };
            options[nonCurrIndex] = {
                ...options[nonCurrIndex],
                displayedValue: ''
            };
        }
        setEditOptions(options);
        if (Number(options[currIndex]?.displayedValue) === 0) {
            setAdjustments(null);
        } else {
            const adjustmentType = currIndex === 0 ? 'percentage' : 'fixed';
            setAdjustments({
                type: adjustmentType,
                value: options[currIndex]?.displayedValue
            });
        }
    };

    useEffect(() => {
        // Check against minimum upload value
        const currTab = tabValue === 0 ? 'percentage' : 'fixed';
        if (adjustments && adjustments.type === currTab) {
            if (isNullRatePerMile(minRate, adjustments))
                setInputErrorAdjustments(
                    'The value is either null or results in a null Rate Per Mile'
                );
            else setInputErrorAdjustments(null);
        } else setInputErrorAdjustments(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adjustments, minRate, tabValue]);

    return (
        <Box sx={{ width: '100%', marginTop: '8px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} centered>
                    <StyledRateTab label="Percentage" id="percent" />
                    <StyledRateTab label="Fixed Rate" id="fixed" />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <span>{BID_CONFIG_INFO_MESSAGES.RATE_ADJUSTMENT_MESSAGE}</span>
                <Box sx={{ marginTop: 2 }}>
                    <ODInputWithAdornments
                        errorText={inputErrorAdjustments}
                        sx={{ width: '100%', marginRight: 0, minWidth: '375px' }}
                        inputComponent={FormattedInputs}
                        value={editOptions[0]?.displayedValue}
                        inputLabel={'Enter Rate Adjustment'}
                        handleRemoveOnClick={(e) => {
                            handleCalculation(editOptions[0]?.displayedValue, 0, 'subtract');
                        }}
                        handleAddOnClick={(e) => {
                            handleCalculation(editOptions[0]?.displayedValue, 0, 'add');
                        }}
                        onChange={(e: any) => {
                            handleCalculation(e?.floatValue, 0, null);
                            if (inputErrorAdjustments) setInputErrorAdjustments(null);
                        }}
                        id={'percentage'}
                    />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <span>{BID_CONFIG_INFO_MESSAGES.RATE_ADJUSTMENT_MESSAGE}</span>
                <Box sx={{ marginTop: 2 }}>
                    <ODInputWithAdornments
                        errorText={inputErrorAdjustments}
                        sx={{ width: '100%', marginRight: 0, minWidth: '375px' }}
                        inputComponent={FormattedInputs}
                        value={editOptions[1]?.displayedValue}
                        inputLabel={'Enter Rate Adjustment'}
                        handleRemoveOnClick={(e) => {
                            handleCalculation(editOptions[1]?.displayedValue, 1, 'subtract');
                        }}
                        handleAddOnClick={(e) => {
                            handleCalculation(editOptions[1]?.displayedValue, 1, 'add');
                        }}
                        onChange={(e: any) => {
                            handleCalculation(e?.floatValue, 1, null);
                            if (inputErrorAdjustments) setInputErrorAdjustments(null);
                        }}
                        id={'fixed'}
                    />
                </Box>
            </CustomTabPanel>
        </Box>
    );
};

export default RateAdjustmentTabs;
