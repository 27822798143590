import { Box, Tooltip } from '@mui/material';
import { DoNotDisturbAlt } from '@mui/icons-material';

const NoMappingMessage = ({
    value,
    size,
    triggerFn
}: {
    value: string;
    size: number;
    triggerFn: () => void;
}) => {
    return (
        <Tooltip title={value as string}>
            <Box
                onClick={() => triggerFn()}
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: size - 16,
                    textDecoration: 'underline'
                }}>
                <DoNotDisturbAlt sx={{ marginRight: '8px' }} /> N/A
            </Box>
        </Tooltip>
    );
};

export default NoMappingMessage;
